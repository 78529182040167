import { Component, ElementRef, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core'
import { AppLayer } from '@core/models/layer'
import { AppFeature } from '@core/models/layer/ol-extend/app-feature'
import { IQueryWRef } from '@core/models/query-object'
import { CommonUtil } from '@core/utils'
import OlUtil from '@core/utils/ol/ol.util'
import { DashboardApiService } from '@services/dashboard/dashboard-api.service'
import { LayerService, WorkspaceService } from '@services/workspace'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { ResizeSensor } from 'css-element-queries'
import Feature from 'ol/Feature'
import * as olGeom from 'ol/geom'
import { Db } from 'vip-shared/dist/models/db-definitions'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { DashboardLayoutService } from '@services/dashboard/dashboard-layout.service'
import * as moment from 'moment'

// NOTE: Interface of json key value types stored in the DB for this widget type.
interface widgetOptions {
  threshold: number
  query_type: string
}

interface NgxTableRow {
  [Columns.VectorFid]?: number
  geom_id?: string
  _originalIndex: number
  _feature: AppFeature
  _geom?: olGeom.Geometry
  _selected: boolean
  _style: {
    fill: string
    stroke: string
  }
  [key: string]: any
}

@Component({
  selector: 'app-alert-query-widget',
  templateUrl: './alert-query-widget.component.html',
  styleUrls: ['./alert-query-widget.component.scss']
})
export class AlertQueryWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('alertQuery', { static: true }) alertDiv!: ElementRef
  widgetId: string = 'default'
  layerId: string = 'default'
  options: { [key: string]: any; } | any[] = { threshold: 0, query_type: 'filter' }
  queryId: string = 'default'
  innerWidth: number = 1920
  fontSize: string = '1vw'
  targetLayer?: AppLayer
  query?: IQueryWRef
  featureCount: number = 0
  widgetOptions: widgetOptions = {} as widgetOptions
  threshold: number = 0
  overThreshold: boolean = false
  sampled: boolean = false
  queryTitle: string = ''
  infoTooltip: string = 'Time information not available'
  private _interval: any = undefined
  private _resizeSensor?: ResizeSensor

  get geomId() {
    return (this.targetLayer && this.targetLayer.idKey) || ''
  }

  private get _queriesApi() {
    return this._workspaceService.viewOrm.Queries()
  }

  constructor(
    private _dashboardApiService: DashboardApiService,
    private _layerQueriesService: LayerQueriesService,
    private _workspaceService: WorkspaceService,
    private _layerService: LayerService,
    private _dashboardLayoutService: DashboardLayoutService
  ) { }

  ngOnInit(): void {
    this.widgetOptions = this.options as widgetOptions
    this._layerQueriesService.init()
    this.innerWidth = window.innerWidth
    this._resizeSensor = new ResizeSensor(
      this.alertDiv.nativeElement,
      () => this.resizefonts()
    )
    this.resetSensor(this._resizeSensor)
    this.threshold = this.widgetOptions.threshold ? this.widgetOptions.threshold : 0
  }

  async ngAfterViewInit() {
    await this.setUp()
  }

  async setUp() {
    await this.setLayerTarget()
    await this.getLayerQuery()
  }

  async setLayerTarget() {
    const wsId = this._dashboardApiService.workspaceId
    if (!this._workspaceService.workspaceId) await this._workspaceService.loadWorkspace(wsId)
    this.targetLayer = await this._layerService.getById(this.layerId)
    if (this.targetLayer && this.targetLayer.isTimeSeries) {
      const from = moment(this.targetLayer.renderedTimeSeriesSelection?.date_range.from).format('YYYY-MM-DD HH:mm:ss')
      const to = moment(this.targetLayer.renderedTimeSeriesSelection?.date_range.to).format('YYYY-MM-DD HH:mm:ss')
      this.infoTooltip = `Time range: ${from} - ${to}`
    }
    this.checkSampled ()
  }

  async getLayerQuery() {
    const queries = await this._queriesApi.get().run() || []

    // Match every query with a queryId
    this.query = queries.map((x, i) => ({
      ...x,
      targetRef: x.layer_group_id ? this._layerService.groups.find(y => y.id === x.layer_group_id) :
        this._layerService.allLayers.find(y => y.id === x.layer_id),
      index: i,
      geometry: x.geometry ?
        OlUtil.wktToFeature(x.geometry).getGeometry() as olGeom.Polygon | olGeom.MultiPolygon :
        undefined
    } as IQueryWRef)).find(q => q.query_id === this.queryId)

    if (this.query && this.targetLayer) {
      this.queryTitle = this.query.name
      this.featureCount = this.widgetOptions.query_type === 'filter' ?
        await this._layerQueriesService.getFeatureCountDashBoardQuery(this.query, this.targetLayer) :
        await this.updateStatisticQueryTable(this.query)
      this.overThreshold = this.threshold < this.featureCount
    }
  }

  private FeatureToRow(f: Feature, i: number, changes: { [key: string]: any } = {}): NgxTableRow {
    return {
      ...f.getProperties(),
      ...changes,
      _originalIndex: i,
      _feature: f,
      _geom: f.getGeometry(),
      _selected: false,
      _style: undefined as any
    }
  }

  checkSampled() {
    if (this.targetLayer) {
      this.sampled = this.targetLayer.preset ?
        [
          Db.Vip.LayerPreset.FLOODRE_CLAIMS,
          Db.Vip.LayerPreset.FLOODRE_EXPOSURE,
          Db.Vip.LayerPreset.PROPERTY_DATA_HUB
        ].includes(this.targetLayer.preset) :
        this.targetLayer.sampled ? true : false
    } else {
      this.sampled = false
    }
  }

  async updateStatisticQueryTable(query: IQueryWRef) {
    let result = 0

    if (!this.targetLayer) return result

    if (!this.sampled) {
      if (!this.targetLayer.notFilteredOutFts.length) {
        await this.targetLayer.reload()
      }
      const rows = this.targetLayer.notFilteredOutFts.map((f, i) => {
        const row = this.FeatureToRow(f, i)
        return row
      })

      const statQuery = query.query.statisticQuery
      if (!statQuery || !rows.length) return result
      const row: number[] = []

      rows.forEach(dsRow => {
        (dsRow[statQuery.attribute] || dsRow[statQuery.attribute] === 0) && row.push(+dsRow[statQuery.attribute])
      })


      const precision = () => Math.max(
        ...CommonUtil.randomArraySample(row, 100)
          .map(value => CommonUtil.countDecimals(value))
      )

      switch (statQuery.operator) {
        case Db.Helper.Prj.Aggregation.MAX:
          result = Math.max(...row)
          break
        case Db.Helper.Prj.Aggregation.MIN:
          result = Math.min(...row)
          break
        case Db.Helper.Prj.Aggregation.AVG:
          result = Number((row.reduce((a, b) => a + b, 0) / row.length).toFixed(precision()))
          break
        case Db.Helper.Prj.Aggregation.SUM:
          result = Number(row.reduce((a, b) => a + b, 0).toFixed(precision()))
          break
      }
    }

    if (this.sampled && this.targetLayer) {
      result = await this.targetLayer.calculateSampledLayerQueryResult(query.query_id)
    }

    return result
  }

  resetSensor(sensor: ResizeSensor) {
    const sen = sensor
    const reset = () => {
      if (this.alertDiv.nativeElement) sen.reset()
    }
    if (sen) {
      this._interval = setInterval(reset, 2000)
    }
  }

  changeThreshold() {
    this.setUp()
    this._dashboardLayoutService.changeWidgetOptions(this.widgetId,'threshold',this.threshold)
  }

  resizefonts() {
    this.innerWidth = window.innerWidth
    const minFontSize = 1
    const tableWidth = this.alertDiv.nativeElement.clientWidth


    const ratio = tableWidth / this.innerWidth
    //NOTE: multiply by 10 to bring to whole number
    let boxtext = Math.round(minFontSize * (ratio * 10))
    boxtext = boxtext < minFontSize ? minFontSize : boxtext
    this.fontSize = boxtext + 'vw'
  }

  ngOnDestroy() {
    if (this._interval) clearInterval(this._interval)
    if (this._resizeSensor) this._resizeSensor.detach()
  }
}
