<h2 mat-dialog-title>{{data.originalId ? 'Update' : 'New'}} Row</h2>

<div mat-dialog-content [class.min-size]="fetchingData">
  <!-- If control '1' exists means there is more than one form, therefore render tab group -->
  <mat-tab-group *ngIf="forms.length > 1" (selectedTabChange)="updateMaps()">
    <mat-tab *ngFor="let form of forms; let i = index" label="Geometry {{i + 1}} of {{forms.length}}">
      <ng-container *ngIf="form"
      [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{form: form}">
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="forms.length <= 1 && forms[0]"
  [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{form: forms[0]}">
  </ng-container>

  <ng-template #formTemplate let-form="form">
    <div class="definitions-header" *ngIf="form.columnMetadata?.length">
      <div>
        <p>Column</p>
      </div>
      <div>
        <p>Type</p>
      </div>
      <div>
        <p>Value</p>
      </div>
    </div>
    <div id="column-list" *ngIf="form.columnMetadata?.length">
        <div class="column-definition-row" *ngFor="let col of form.columnMetadata">
            <mat-form-field>
                <input matInput [disabled]="true" [value]="col.name + (col.nullable ? '' : '*')">
            </mat-form-field>
            <mat-form-field>
                <input matInput [disabled]="true" [value]="col.type">
            </mat-form-field>
            <mat-form-field>
              <input matInput (input)="checkInputsValidity()" [formControl]="col.formControl">
            </mat-form-field>
        </div>
    </div>
    <div class="wkt-field" (rendered)="updateMaps()">
      <mat-form-field>
        <textarea matInput [formControl]="form.wktControl" cols="40" rows="4" placeholder="WGS84 'Well Known Text (WKT)' geometry"></textarea>
        <mat-error *ngIf="form.wktControl.hasError('wktValid')">
          {{form.wktControl['errors'] && form.wktControl['errors']['wktValid']['message']}}
        </mat-error>
      </mat-form-field>

      <app-wkt-preview #preview [wkt]="form.wktControl.value"></app-wkt-preview>
    </div>
  </ng-template>
</div>

<div class="st-button-row">
  <button color="primary" type="button" mat-button [mat-dialog-close] class="uppercase">
    cancel
  </button>
  <app-submit-button text="Save" [loading]="saving" [disabled]="!canCreate" (submit)="createRow()"></app-submit-button>
</div>
<app-loader-backdrop loadingText="Applying changes..." *ngIf="saving"></app-loader-backdrop>
