<mat-list>
  <mat-list-item class="no-action">
   <div class="item-content">
    <span>
      Layer Sequences
    </span>

    <div class="item-actions">
      <button mat-icon-button color="primary" (click)="openLayerSequences()">
        <mat-icon>table_chart</mat-icon>
      </button>
    </div>
   </div>
  </mat-list-item>
</mat-list>
