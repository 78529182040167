import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core'
import { UntypedFormGroup, Validators } from '@angular/forms'
import { Db } from '@vip-shared/models/db-definitions'
import { AlertService, VipApiService } from '@services/core'
import { handleError } from '@core/models/app-error'
import { Subscription, merge } from 'rxjs'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { auditTime, tap } from 'rxjs/operators'

type Form = TypedFormGroup<{
  presetName: TypedFormControl<string>
  filters: UntypedFormGroup
  mode?: TypedFormControl<Db.Vip.LayerMode>
  datasetsQuery: TypedFormControl<any>,
  datasetSelection: UntypedFormGroup,
  datetimeSelection: UntypedFormGroup
}>

@Component({
  selector: 'app-vector-preset-config',
  templateUrl: './vector-preset-config.component.html',
  styleUrls: ['./vector-preset-config.component.scss']
})
export class VectorPresetConfigComponent implements OnInit, OnDestroy, DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs(): any { return }

  private _subscriptions = new Subscription()

  formControlKeys = ['presetName', 'filters', 'mode', 'datasetsQuery', 'datasetSelection', 'scraperForm']

  @Output() formChange = new EventEmitter<Form>()
  @Output() selectedPreset = new EventEmitter<Db.Vip.Geo.ILayerPreset>()
  @Input() productId!: number
  @Input() allowModes = true
  @Input() onlyPresets: Db.Vip.LayerPreset[] = []

  form: Form
  datasetSelectionForm: UntypedFormGroup
  preset = Db.Vip.LayerPreset
  presetName: string = ''
  fetchingData = false
  layerPresets: Db.Vip.Geo.ILayerPreset[] = []
  selectedQuery?: any

  constructor(
    private _alertService: AlertService,
    private _api: VipApiService
  ) {
    this.form = new TypedFormGroup({
      presetName: new TypedFormControl<string>(undefined, [Validators.required]),
      filters: new UntypedFormGroup({}),
      datasetsQuery: new TypedFormControl(undefined),
      datasetSelection: new UntypedFormGroup({}),
      datetimeSelection: new UntypedFormGroup({})
    })

    this.datasetSelectionForm = this.form._controls.datasetSelection

    this._subscriptions.add(
      merge(
        this.form.statusChanges,
        this.form.valueChanges,
        this.datasetSelectionForm.valueChanges
      ).pipe(auditTime(1000)).subscribe(() =>
        this.formChange.next(this.form)
      )
    )
  }

  ngOnInit() {
    if (this.allowModes) {
      this.form.addControl('mode', new TypedFormControl<Db.Vip.LayerMode>(
        Db.Vip.LayerMode.READ_ONLY,
        Validators.required
      ))
    }
    this.LoadPresets()
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
    this._destroyDialogs()
  }

  placeholder(title: string, items: number) {
    return `${title}${items ? '' : ' (None Available)'}`
  }

  private async LoadPresets() {
    this.fetchingData = true
    try {
      let presets = await this._api.orm.Products().Product(this.productId)
        .LayerPresets().get().run()

      this.layerPresets = (this.onlyPresets && this.onlyPresets.length) ?
        presets.filter(p => this.onlyPresets.includes(p.layer_preset_tag as any)) :
        presets

      if (this.layerPresets.length === 1) {
        this.form._controls.presetName.setValue(this.layerPresets[0].layer_preset_tag)
        this.form._controls.presetName.disable()
        this.presetChange(this.layerPresets[0].layer_preset_tag as Db.Vip.LayerPreset)
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    this.fetchingData = false
  }

  async presetChange(presetTag: Db.Vip.LayerPreset) {
    this.presetName = presetTag
    const selectedPreset = this.layerPresets.find(p => p.layer_preset_tag === presetTag)
    this.selectedPreset.next(selectedPreset as Db.Vip.Geo.ILayerPreset)
    const triggerLoad = !this.fetchingData
    if (triggerLoad) this.fetchingData = true
    try {
      for (const key in this.datasetSelectionForm.controls) {
        this.datasetSelectionForm.removeControl(key)
      }
      this.formChange.next(this.form)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    if (triggerLoad) this.fetchingData = false
  }
}

applyMixins(VectorPresetConfigComponent, [DialogCleanup])

