import { Component, OnDestroy, ViewChild } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { VipApiService, AlertService } from '@services/core'
import { Db } from '@vip-shared/models/db-definitions'
import { MatStepper } from '@angular/material/stepper'

type Product = Db.Vip.Gsi.IProduct | {
  product_id: string
  product_name: string
}
@Component({
  selector: 'app-dataset-manager-dialog',
  templateUrl: './dataset-manager-dialog.component.html',
  styleUrls: ['./dataset-manager-dialog.component.scss']
})
export class DatasetManagerDialogComponent implements DialogCleanup, OnDestroy {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  @ViewChild('stepper', { static: false }) stepper!: MatStepper
  errorMessage = ''
  loading = false
  products: Db.Vip.Gsi.IProduct[] = []
  selectedProduct?: Product

  generalProduct: Product = {
    product_id: 'general',
    product_name: 'General'
  }
  private _allowedProducts = [
    7 // 	Property View
  ]

  constructor (
    private _api: VipApiService,
    private _alertService: AlertService,
    private _dialogRef: MatDialogRef<DatasetManagerDialogComponent>
  ) {
    this.GetProducts()
  }

  ngOnDestroy () {
    this._destroyDialogs()
  }

  async GetProducts () {
    try {
      this.loading = true
      this.products = await this._api.orm.Products().get().run()
      this.products = this.products.filter(x => this._allowedProducts.includes(+x.product_id))
    } catch (error: any) {
      this._alertService.log(error.message)
      this._dialogRef.close()
    }
    this.loading = false
  }

  selectProduct (product: Product) {
    this.selectedProduct = product
    setTimeout(() => this.stepper.next(), 100)
  }
}

applyMixins(DatasetManagerDialogComponent, [DialogCleanup])
