import { Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogCleanup } from "@core/utils/ng-mixin/mixins/dialog-cleanup";
import { DashboardApiService } from "@services/dashboard/dashboard-api.service";
import { AlertService } from "@services/core";
import { Subscription } from "rxjs";
import FormValidators from "@core/utils/form-validators/form-validators";
import { ITimeRange } from "vip-shared/dist/interfaces";

@Component({
  selector: 'app-one-click-dialog',
  templateUrl: './one-click-dialog.component.html',
  styleUrls: ['./one-click-dialog.component.scss']
})
export class OneClickDialogComponent implements DialogCleanup, OnDestroy {
  private _subscriptions = new Subscription()
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs(): any { return }
  timeseriesForm?: FormGroup
  layerContentForm?: FormGroup
  loading: boolean = false
  readonly applyFormConfig = FormValidators.cloneFormConfig

  constructor(
    private _dialogRef: MatDialogRef<OneClickDialogComponent, any>,
    private _formBuilder: FormBuilder,
    private dashboardApiService: DashboardApiService,
    private _alertService: AlertService
  ) {
    this.layerContentForm = this._formBuilder.group({datetimeSelection: new FormGroup({})})
  }

  updateDateCheck(to: FormGroup, from: FormGroup, controlKeys: string[]) {
    this.applyFormConfig(to, from, controlKeys)
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
    this._destroyDialogs()
  }

  async createLayers() {
    const timerange: ITimeRange = {
      valid_from: this.layerContentForm?.controls.datetimeSelection?.value.valid_from,
      valid_to: this.layerContentForm?.controls.datetimeSelection?.value.valid_to,
      focal_point: this.layerContentForm?.controls.datetimeSelection?.value.focal_point,
    }
    this.loading = true
    await this.dashboardApiService.configureOneClickSetup(timerange)
    this.loading = false
    this._dialogRef.close()
  }
}
