<h2 mat-dialog-title>Bug Reporting Form</h2>

<div mat-dialog-content>
<form #formComponent enctype="multipart/form-data" [formGroup]="form" class="st-single-row-form">
  <div class="form-row">
    <p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>
  <mat-form-field>
    <input matInput type="text" formControlName="name" placeholder="Name" >
  </mat-form-field>
</div>
<div class="form-row">
  <mat-form-field>
    <input matInput type="text" formControlName="email" placeholder="Email" >
  </mat-form-field>
  </div>
  <div class="form-row">
  <mat-form-field>
    <input matInput type="text" formControlName="message" placeholder="What Happened?" >
  </mat-form-field>
  </div>
  <div class="form-row">
    <p>Optional screenshot upload </p>
    <mat-form-field>
      <input matInput type="file" name="screenshot" (change)="onFileSelected($event)">
    </mat-form-field>
    </div>
  <div class="form-row">
  <app-submit-button mat-dialog-close text="Submit" *ngIf="!submitted" [disabled]="!form?.valid" [loading]="saving" (submit)="submit()">
  </app-submit-button>
</div>
</form>
</div>
