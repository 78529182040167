import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import moment from 'moment';
import * as olGeom from 'ol/geom'
import zoomPlugin from 'chartjs-plugin-zoom'
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js'
import * as Papa from 'papaparse'

import { Db } from 'vip-shared/dist/models/db-definitions'
import { DateRangeChooserDialogComponent } from '@pages/workspace/time-bar/time-series-slider/date-range-chooser-dialog/date-range-chooser-dialog.component';
import { IPNumEntriesCheck, IPRasterChartAPI } from '@vip-shared/interfaces'
import { VipApiService, AlertService, PromptService } from '@services/core'
import { handleError } from '@core/models/app-error'
import OlUtil from '@core/utils/ol/ol.util'
import { IQueryWRef } from '@core/models/query-object';
import { IRasterChartOptions } from '@core/types/workspace/raster-chart-options';
import { WorkspaceService } from '@services/workspace'
import { ThemeService } from '@services/core/theme/theme.service'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { rasterEmissionThreshold } from '@vip-shared/models/const/data-limits'

interface LayerTimeseriesInfo {
  name: string,
  dateRange: Db.Helper.Geo.DateRange
}

interface DataRef {
  selectedRange: moment.Moment[],
  option: string,
  layerList?: LayerTimeseriesInfo[]
  locked?: boolean,
  constraint?: moment.Moment[],
  focalPoint?: moment.Moment
}

@Component({
  selector: 'app-raster-chart',
  templateUrl: './raster-chart.component.html',
  styleUrls: ['./raster-chart.component.scss']
})
export class RasterChartComponent implements OnInit {
  _trackDialog(dialog: MatDialogRef<any>): any { return }

  @Input() options!: IRasterChartOptions

  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>
  protected _chart?: Chart = undefined
  private _dateRangeChooserRef?: MatDialogRef<any>
  totalTimeRange: moment.Moment[] = []
  selectedTimeRange: moment.Moment[] = []
  locked = false
  staticLayer: boolean = false
  geomsMatch: Boolean = false
  focalPoint = moment()
  query?: IQueryWRef = undefined
  title: string = 'Raster chart'
  presets: Db.Vip.Geo.ILayerPreset[] = []
  dateChanged: boolean = false
  labelIndex: any[] = []
  chartLabels: string[] = []
  chartData: ChartDataset[] = [
    { borderWidth: 0, borderColor: 'rgb(168, 212, 0)', backgroundColor: 'rgba(168, 212, 0,0.4)', fill: '+2', label: 'StdDev', data: [], yAxisID: 'y' },
    { borderColor: 'rgb(168, 212, 0)', backgroundColor: 'rgb(168, 212, 0)', fill: false, label: 'Avg', data: [], yAxisID: 'y', },
    { borderWidth: 0, borderColor: 'rgb(168, 212, 0)', backgroundColor: 'rgb(168, 212, 0)', fill: false, label: 'StdDev2', data: [], yAxisID: 'y' },
    { borderColor: 'rgb(255, 165, 0)', backgroundColor: 'rgb(255, 165, 0)', fill: false, label: 'Min', data: [], yAxisID: 'y', },
    { borderColor: 'rgb(255, 0 , 0)', backgroundColor: 'rgb(255, 0 , 0)', fill: false, label: 'Max', data: [], yAxisID: 'y', }
  ]
  rasterData = {} as Db.Helper.Prj.RasterLayersData
  polygonMetaData? = [] as Db.Helper.Prj.Polygon[]
  maxDataPoints: number = 0
  numEntries: number = 0
  loading: boolean = false
  continueSelected: boolean = false
  chartType: 'line' | 'bar' = 'line'
  logEnabled = false
  yScaleType = 'linear'

  get isDashboardChart(): boolean {
    return !this.options?.isDialogMode
  }

  private get _queriesApi() {
    return this._workspaceService.viewOrm.Queries()
  }

  constructor(
    protected _api: VipApiService,
    private _dialog: MatDialog,
    private _workspaceService: WorkspaceService,
    private _themeService: ThemeService,
    private _layerQueriesService: LayerQueriesService,
    private _alertService: AlertService,
    private _promptService: PromptService,
  ) { }

  ngOnInit(): void {
    this.setUp()
  }

  async setUp() {
    this.setVariables()
    await this.fetchData()
  }

  setVariables() {
    this.staticLayer = this.options.layerRaster?.preset ? this.options.layerRaster.preset.includes('static') : false
    if (!this.options.layerRaster || !this.options.layerRaster.renderedTimeSeriesSelection) return
    if (!this.staticLayer) {
      this.selectedTimeRange[0] = moment(this.options.layerRaster.renderedTimeSeriesSelection.date_range.from)
      this.selectedTimeRange[1] = moment(this.options.layerRaster.renderedTimeSeriesSelection.date_range.to)
      this.focalPoint = moment(this.options.layerRaster.renderedTimeSeriesSelection.date_range.focal)
      this.totalTimeRange = [
        moment(this.selectedTimeRange[0]).add(-7, 'days'),
        moment().toDate().getTime() < moment(this.selectedTimeRange[1]).add(+3, 'days').toDate().getTime() ?
          moment().add(30, 'seconds') : moment(this.selectedTimeRange[1]).add(+3, 'days')
      ]
    }
  }

  async fetchData() {
    if (!this.options.layerRaster) return
    try {
      this.query = await this.getQuery()
      this.geomsMatch = this.checkGeomMatch()
      if (this.query && this.query.raster_data) {
        if (!this.staticLayer) {
          this.setTimeRanges()
        }
        await this.LoadData()
      } else if (this.query && !this.query.raster_data) {
        const title = await this.setTitle()
        this.title = title
        this.continueSelected = !this.staticLayer ? await this.checkEntries(this.selectedTimeRange) : true
        if (this.continueSelected) {
          await this.LoadData()
        }
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    if (!this.query) this._alertService.log('Missing Query')
  }

  async checkEntries(dates: moment.Moment[]): Promise<boolean> {
    if (!this.options.layerRaster || !this.options.layerRaster.renderedTimeSeriesSelection) return false
    let upload: IPNumEntriesCheck
    upload = {
      valid_from: dates[0].toISOString(),
      valid_to: dates[1].toISOString(),
      layer_preset: this.options.layerRaster.preset as Db.Vip.LayerPreset
    }
    try {
      this.numEntries = Number((await this._api.orm.Products().Fred().getNumEntries(upload).run()).count)
      if (this.numEntries < rasterEmissionThreshold) return true
      else this._trackDialog(
        this._promptService.prompt(`Data points  over threshold: ${this.numEntries - 20}.
          Please adjust the time selection`, {
          ok: () => { this.openDateSelection('selected') }
        })
      )
      return false
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
      return false
    }
  }

  checkGeomMatch(): boolean {
    if (this.options.prevGeom && this.query?.geometry) {
      const oldgeomFt = this.options.prevGeom as olGeom.MultiPolygon | olGeom.Polygon
      const newGeomFt = this.query.geometry as olGeom.MultiPolygon | olGeom.Polygon
      if (oldgeomFt && newGeomFt) {
        return OlUtil.areGeometriesEqual(oldgeomFt, newGeomFt)
      }
    }
    return true
  }

  setChartType() {
    if (!this.rasterData) return
    // Check the number if entries for each layer, if there are two (Multiploygon) set to bar chart
    const keys = Object.keys(this.rasterData)
    const dataNumEnteries = this.rasterData[keys[0]].length
    if (dataNumEnteries > 1 || keys.length === 1) {
      this.chartType = 'bar'
    }
  }

  async getQuery(): Promise<IQueryWRef | undefined> {
    if (!this.options.layerRaster || !this.options.layerRaster.renderedTimeSeriesSelection) return
    await this._layerQueriesService.updateQueries()
    const queries = this._layerQueriesService.getTargetQueries(this.options.layerRaster, ['spatial'])
    const query = queries.find(q => q.query_id === this.options.queryId)
    if (query) {
      return query
    } else {
      this._alertService.log('Failed to retrieve Query')
      return undefined
    }
  }

  setTimeRanges() {
    if (!this.query || !this.query.raster_data || !this.options.layerRaster?.renderedTimeSeriesSelection) return
    this.selectedTimeRange[0] = moment(this.query.raster_data['selected_dates'].from_date)
    this.selectedTimeRange[1] = moment(this.query.raster_data['selected_dates'].to_date)
    this.focalPoint = moment(this.options.layerRaster.renderedTimeSeriesSelection.date_range.focal)
    this.totalTimeRange = [
      moment(this.selectedTimeRange[0]).add(-7, 'days'),
      moment().toDate().getTime() < moment(this.selectedTimeRange[1]).add(+3, 'days').toDate().getTime() ?
        moment().add(30, 'seconds') : moment(this.selectedTimeRange[1]).add(+3, 'days')
    ]
    this.continueSelected = true
  }

  onScaleTypeChanged(e) {
    this.yScaleType = this.logEnabled ? 'logarithmic' : 'linear'
    this.LoadData()
  }

  async setTitle(): Promise<string> {
    if (!this.options.layerRaster || !this.options.layerRaster.renderedTimeSeriesSelection || !this.query) return this.title
    return `${this.query.name} : ${this.options.layerRaster.preset ? await this.getLayerPreset(this.options.layerRaster.preset) : this.options.layerRaster.title}`
  }

  setIsoforLables(dates: string[]): string[] {
    let datesIso: string[] = []
    for (const date of dates) {
      const iso = moment.utc(date).toISOString()
      const formattedDateTime = moment(iso).format('YYYY-MM-DD HH:mm')
      datesIso.push(formattedDateTime)
    }
    return datesIso
  }

  private setChartLabels() {
    if (!this.query?.raster_data) return
    if (this.chartType === 'line') {
      this.chartLabels = this.setIsoforLables(this.query.raster_data.dates)
    } else {
      const key = Object.keys(this.rasterData)[0]
      if (this.polygonMetaData && this.polygonMetaData.length) {
        this.chartLabels = this.rasterData[key].map((p: Db.Helper.Prj.RasterDataMetrics) => this.polygonMetaData ? this.polygonMetaData[`${Number(p.z_id)}`].name : p.z_id)
      } else {
        this.chartLabels = this.rasterData[key].map((p: Db.Helper.Prj.RasterDataMetrics) => p.z_id)
        if (this.chartLabels.length === 1 && this.chartLabels[0] === '0') {
          this.chartLabels[0] = 'Metrics'
        }
      }
    }
  }

  private async LoadData() {
    try {
      this.loading = true
      if (!this.options.layerRaster) return
      if (this.query && this.query.raster_data && this.geomsMatch) {
        if (!this.staticLayer) {
          this.selectedTimeRange[0] = moment(this.query.raster_data.selected_dates.from_date)
          this.selectedTimeRange[1] = moment(this.query.raster_data.selected_dates.to_date)
        }
        this.rasterData = this.query.raster_data.data
        this.polygonMetaData = this.query.query_metadata ? this.query.query_metadata.polygons : undefined
        this.title = await this.setTitle()
        this.setChartType()
        this.setChartLabels()
        this.ChartConfig()
        this.SetChartData()
        await this.updateQueries()
        this.loading = false
      } else if (this.continueSelected) {
        await this.generateChartjson()
      } else {
        this.loading = false
      }
    } catch (error: any) {
      this.loading = false
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  private async getLayerPreset(preset: string) {
    if (!this.options.layerRaster) return this.title
    let displayName: string = this.title

    const getDisplayName = (): string => {
      if (!this.options.layerRaster) return this.title
      const presetRow = this.presets.find(x => x.layer_preset_tag === preset)
      return displayName = presetRow ? presetRow.display_name : this.options.layerRaster.title
    }

    if (this.presets.length) {
      displayName = getDisplayName()
    } else {
      try {
        this.presets = await this._api.orm.Products().Product(this._workspaceService.product as number)
          .LayerPresets().get().run()
        if (this.presets.length) {
          displayName = getDisplayName()
        }
      } catch (error: any) {
        handleError(error)
        this._alertService.log(error.message)
      }
    }
    return displayName
  }

  private ChartConfig() {
    this.maxDataPoints = this.chartLabels.length
    if (this._chart) this._chart.destroy()
    this._chart = new Chart(this.canvasRef.nativeElement, {
      type: this.chartType,
      data: {
        labels: [],
        datasets: []
      },
      plugins: [zoomPlugin],
      options: {
        responsive: true,
        spanGaps: true,
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0
          }
        },
        maintainAspectRatio: this.isDashboardChart ? false : true,
        aspectRatio: 3,
        scales: {
          y: {
            ticks: {
              color: 'white',
            },
            title: {
              color: 'white',
              display: true,
              text: 'µg/m³',
              font: {
                size: 14,
                weight: '500'
              }
            },
            grid: {
              color: this._themeService.getColor('foreground-20')
            },
            type: this.yScaleType
          },
          x: {
            beginAtZero: false,
            ticks: {
              color: 'white',
              stepSize: 1,
              callback: (label: number) => {
                return this.chartLabels && this.chartLabels[label]
              }
            },
            grid: {
              color: this._themeService.getColor('foreground-20')
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: 'white',
              font: {
                size: 14
              },
              filter: function (item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('StdDev2');
              }
            },
            position: this.isDashboardChart ? 'right' : 'bottom',
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.05
              },
              mode: 'x',
            }
          }
        },
        tooltips: {
          enabled: false
        }
      }
    } as ChartConfiguration)
  }

  getChartData(): ChartDataset[] {
    // Create new chartDataset to not affect the source
    let chartData: ChartDataset[] = []
    chartData = this.chartData.map((obj: ChartDataset) => ({ ...obj }))
    //If Chart type is bar remove stdDev
    if (this.chartType === 'bar') {
      chartData.splice(chartData.findIndex(item => item.label === 'StdDev'), 1)
      chartData.splice(chartData.findIndex(item => item.label === 'StdDev2'), 1)
    }
    return chartData
  }

  private SetChartData() {
    if (!this._chart) return
    const chartData = this.getChartData()
    this.labelIndex = this.chartLabels.map(x => this.chartLabels.indexOf(x).toString())
    if (!this._chart || !chartData || !this._chart.data.labels || !this._chart.data.datasets) return

    this._chart.data.labels.length = 0
    this._chart.data.labels.push(...this.labelIndex)
    this._chart.data.datasets.push(...chartData)

    for (let i = 0; chartData.length > i; i++) {
      if (!chartData[i].data || !chartData) return
      const label = chartData[i].label
      switch (label) {
        case 'Avg':
          chartData[i].data.length = 0
          chartData[i].data.push(...this.GetRasterData('avg'))
          break
        case 'Min':
          chartData[i].data.length = 0
          chartData[i].data.push(...this.GetRasterData('min'))
          break
        case 'Max':
          chartData[i].data.length = 0
          chartData[i].data.push(...this.GetRasterData('max'))
          break
        case 'Sum':
          chartData[i].data.length = 0
          chartData[i].data.push(...this.GetRasterData('sum'))
          break
        case 'StdDev':
          chartData[i].data.length = 0
          chartData[i].data.push(...this.GetRasterData('stddev'))
          break
        case 'StdDev2':
          chartData[i].data.length = 0
          chartData[i].data.push(...this.GetRasterData('stddev2'))
          break
        default:
          break
      }
    }
    this._chart.update()
    this.loading = false
  }

  private GetRasterData(metric: string): number[] {
    let data: number[] = []
    if (!this.rasterData) return data
    if (this.chartType === 'line') {
      data = this.GetLineChartData(metric)
    } else {
      data = this.GetBarChartData(metric)
    }
    return data
  }

  private GetBarChartData(metric: string): number[] {
    let data: number[] = []
    if (!this.rasterData) return data
    const entryArr: number[][] = []
    for (const key in this.rasterData) {
      const dataEntry: Db.Helper.Prj.RasterDataMetrics[] = this.rasterData[key]
      const entryMetricArr: number[] = []
      for (const entry of dataEntry) {
        if (metric === 'stddev') {
          const dataStdDev = entry.stddev
          const dataAvg = entry.avg
          dataAvg < 0 ? entryMetricArr.push((dataAvg + dataStdDev)) : entryMetricArr.push((dataAvg - dataStdDev))
        } else if (metric === 'stddev2') {
          const dataStdDev = entry.stddev
          const dataAvg = entry.avg
          entryMetricArr.push((dataAvg + dataStdDev))
        } else {
          const dataValue = entry[metric]
          entryMetricArr.push(dataValue)
        }
      }
      entryArr.push(entryMetricArr)
    }
    // Sums the different metrics for each part of the multipolygon
    let result = entryArr[0].map((_, i) => entryArr.reduce((sum, arr) => sum + arr[i], 0))
    data = [...result]
    return data
  }

  private GetLineChartData(metric: string): number[] {
    const data: number[] = []
    if (!this.rasterData) return data
    for (const key in this.rasterData) {
      const dataEntry: Db.Helper.Prj.RasterDataMetrics[] = this.rasterData[key]
      for (let i = 0; i < dataEntry.length; i++) {
        if (metric === 'stddev') {
          const dataStdDev = dataEntry[i].stddev
          const dataAvg = dataEntry[i].avg
          dataAvg < 0 ? data.push((dataAvg + dataStdDev)) : data.push((dataAvg - dataStdDev))
        } else if (metric === 'stddev2') {
          const dataStdDev = dataEntry[i].stddev
          const dataAvg = dataEntry[i].avg
          data.push((dataAvg + dataStdDev))
        } else {
          const dataValue = dataEntry[i][metric]
          data.push(dataValue)
        }
      }
    }
    return data
  }

  openDateSelection(option: string) {
    if (!this.options.layerRaster || !this.options.layerRaster.timeSeriesSelection) return
    let range: moment.Moment[] = this.totalTimeRange
    let leftAdjust: string = '60px'
    let constraint = this.selectedTimeRange
    const layerList: LayerTimeseriesInfo[] = []
    if (option === 'selected') {
      range = this.selectedTimeRange
      leftAdjust = '360px'
      constraint = []
      layerList.push({ name: this.options.layerRaster.title, dateRange: this.options.layerRaster.timeSeriesSelection.date_range })
    }

    this._dateRangeChooserRef = this._dialog.open(DateRangeChooserDialogComponent, {
      position: {
        top: '80px'
      },
      data: {
        selectedRange: range,
        focalPoint: this.focalPoint,
        option,
        locked: this.locked && option === 'selected',
        constraint,
        layerList
      }
    })

    const sub = this._dateRangeChooserRef.componentInstance.selectedRangeChange.subscribe(async (data: DataRef) => {
      if (!data) return
      if (data.option === 'selected') {
        this.continueSelected = await this.checkEntries(data.selectedRange)
        if (!this.continueSelected) return
        this.dateChanged = this.selectedTimeRange !== data.selectedRange
        this.selectedTimeRange = data.selectedRange
        if (this.dateChanged) await this.generateChartjson()
      }
    })

    this._dateRangeChooserRef.afterClosed().subscribe((applyTimeseries: boolean) => {
      sub.unsubscribe()
    })
  }

  async updateQueries() {
    await this._layerQueriesService.updateQueries()
    this.query = await this.getQuery()
  }

  async generateChartjson() {
    this.loading = true
    try {
      if (!this.options.layerRaster || !this.options.geojson) return
      const upload: IPRasterChartAPI = {
        valid_from: !this.staticLayer ? this.selectedTimeRange[0].toISOString() : undefined,
        valid_to: !this.staticLayer ? this.selectedTimeRange[1].toISOString() : undefined,
        layer_id: this.options.layerRaster.id,
        query_id: this.options.queryId,
        fileFormat: 'json'
      }
      const data = await this.options.layerRaster.getRasterChartData(upload)
      await this.SaveQuery(data)
      await this.updateQueries()
      this.continueSelected = false
      this.geomsMatch = true
      await this.LoadData()
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
      this.loading = false
    }
  }

  async SaveQuery(data: Db.Helper.Prj.RasterDataObj) {
    if (!this.query) return
    await this._queriesApi.Query(this.options.queryId).update({
      name: this.query.name,
      applied: this.query.applied,
      query: this.query.query,
      raster_data: data,
      query_metadata: this.query.query_metadata
    }).run()
  }

  async downloadChart() {
    if (!this.options.layerRaster || !this.options.geojson) return
    const upload: IPRasterChartAPI = {
      valid_from: this.selectedTimeRange[0].toISOString(),
      valid_to: this.selectedTimeRange[1].toISOString(),
      layer_id: this.options.layerRaster.id,
      query_id: this.options.queryId,
      fileFormat: 'png'
    }
    await this.options.layerRaster.downloadRasterEmissionTimeSeriesChart(upload)
  }

  downloadCanvas() {
    const canvasElement = this.canvasRef.nativeElement
    const dataUrl = canvasElement.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = dataUrl;
    link.download = `${this.title}.png`

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  convertChartDataToCSV(chartData: ChartDataset[]) {
    const csvData: any[] = [];
    const rowHeader = ['Label', ...this.chartLabels]
    csvData.push(rowHeader)

    chartData.forEach(dataset => {
      const row = [dataset.label, ...dataset.data]
      csvData.push(row)
    })

    return Papa.unparse(csvData);
  }

  downloadCSV() {
    const chartData = this.getChartData()
    let csv = this.convertChartDataToCSV(chartData)
    const filename = 'chart-data.csv';
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }

    const data = encodeURI(csv)
    const link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
