import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { AlertService, VipApiService } from '@services/core'
import { WorkspaceService } from '@services/workspace'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { FormTemplate } from '@core/models/form-template'
import { AppLayer } from '@core/models/layer'
import { IQueryWRef } from '@core/models/query-object'
import { TypedFormControl, TypedFormGroup } from '@core/models/typed-form-control'
import FormValidators from '@core/utils/form-validators/form-validators'
import OlUtil from '@core/utils/ol/ol.util'
import { Db } from '@vip-shared/models/db-definitions'
import { handleError } from '../../models/app-error'
import { IEntries, IPNumEntriesCheck } from '@vip-shared/interfaces'
import { clientDataThreshold, layerAlertThreshold } from '@vip-shared/models/const/data-limits'
import { DomUtil } from '@core/utils'
import { Subscription, take } from 'rxjs'
import { RasterChartDialogComponent } from '../raster-chart-dialog/raster-chart-dialog.component'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { GeoJSON } from 'geojson'
import { MultiPolygon, Polygon } from 'ol/geom'
interface DialogData {
  layers: AppLayer[]
  floodGeomIds?: string[]
  geometry?: string
  query?: IQueryWRef
  compositeSelector?: {
    layer?: AppLayer,
    selector: Db.Helper.Prj.SpatialSelectorMeta[]
  }[]
  warning?: string
}

interface MappedWKTtoHex {
  id: number
  polygonName: string
  wkt: string
  geomHex?: string
}

type Form = TypedFormGroup<{
  name: TypedFormControl<string>
  wktGeometry: TypedFormControl<string>
  includeEdge: TypedFormControl<boolean>
}>


@Component({
  selector: 'app-new-spatial-query-dialog',
  templateUrl: './new-spatial-query-dialog.component.html',
  styleUrls: ['./new-spatial-query-dialog.component.scss']
})
export class NewSpatialQueryDialogComponent extends FormTemplate<Form> implements AfterViewChecked, OnInit {
  defaultTitle = 'Untitled Spatial Query'
  numEntriesLoaded: boolean = false
  numEntriesLoading: boolean = false
  dataCapWarning: boolean = false
  layerAlert: boolean = false
  numEntries: IEntries = { count: 0, sampled_count: undefined }
  entriesMsg: string = ''
  existingEntries: number = 0
  floodAreaQueryIds: Db.Helper.Prj.SpatialSelectorMeta[] = []
  singleLayerFlag: boolean = false
  isMultiPolygon: boolean = false
  splitPolygons: MappedWKTtoHex[] = []
  previousGeometry: MultiPolygon | Polygon = {} as MultiPolygon | Polygon


  get rasterSpatialQuery(): boolean {
    return (
      this.singleLayerFlag &&
      !!(this.data.layers[0] instanceof AppRasterLayer) &&
      !!this.data.layers[0].preset &&
      this._workspaceService.isEmissions()
    )
  }

  get spatialQueryInnerHtml(): string {
    if (this.rasterSpatialQuery) return 'Spatial Query Charts'
    return 'Spatial Queries'
  }

  get submitButtonInnerHtml(): string {
    let buttonHtml = this.data.query?.query_id ? 'SAVE' : 'CREATE'
    if (this.rasterSpatialQuery) buttonHtml += ' CHART'
    return buttonHtml
  }

  static setup(data: DialogData) {
    return {
      data
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected _dialogRef: MatDialogRef<NewSpatialQueryDialogComponent>,
    private _layerQueriesService: LayerQueriesService,
    private _workspaceService: WorkspaceService,
    private _alertService: AlertService,
    private _apiService: VipApiService,
    private cdRef: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {
    super(new TypedFormGroup({
      name: new TypedFormControl<string>(undefined, Validators.required),
      wktGeometry: new TypedFormControl<string>(undefined, [
        Validators.required,
        FormValidators.wktValid(['Polygon', 'MultiPolygon'])
      ]),
      includeEdge: new TypedFormControl<boolean>(false)
    }))

    this.singleLayerFlag = this.data.layers.length === 1
    this.form.addControl(
      'entries',
      new TypedFormControl<number[]>([], [Validators.required, FormValidators.numberArrayValid(this.data.layers.length)]))

    if (this.data.compositeSelector) {
      const name = Array.from(
        this.data.compositeSelector.reduce((set, val) => {
          for (const sel of val.selector) set.add(sel.label)
          return set
        }, new Set<string>())
      ).join(', ')
      this.form._controls.name.setValue(name)
      this.defaultTitle = name
    }

    if (this.data.query) {
      this.form._controls.name.setValue(this.data.query.name)
      this.form._controls.includeEdge.setValue(!!this.data.query.query.includeEdge)
    } else {
      if (this.singleLayerFlag) {
        const layer = this.data.layers[0]
        const spatialQueriesLength = this._layerQueriesService.getTargetQueries(layer, ['spatial']).length
        this.existingEntries = spatialQueriesLength ? layer.features.length : 0
      }
    }

    this.form._controls.wktGeometry.setValue(this.data.geometry)
    this.form._controls.wktGeometry.markAllAsTouched()
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges()
  }

  async ngOnInit() {
    const spatialQueriesLength = this.singleLayerFlag && this._layerQueriesService.getTargetQueries(this.data.layers[0], ['spatial']).length
    if (this.data.query && spatialQueriesLength !== 1) this.existingEntries = await this._layerQueriesService.getFeatureCountWithQuery(this.data.query)
    if (this.data.floodGeomIds && this.data.floodGeomIds.length) {
      const floodIds = this.data.floodGeomIds
      for (let i = 0; floodIds.length > i; i++) {
        let obj: Db.Helper.Prj.SpatialSelectorMeta = { sourceId: 'Flood warnings', featureId: floodIds[i], label: 'Flood area ids' }
        this.floodAreaQueryIds.push(obj)
      }
    }
    this.numEntriesLoading = true
    if (this.singleLayerFlag) {
      await this.getNumEntriesInArea(this.data.layers[0])
    } else {
      await Promise.all(this.data.layers.map(async (l) => {
        await this.getNumEntriesInArea(l)
      }))
    }
    this.isMultiPolygon = (OlUtil.wktToFeature(this.form.controls.wktGeometry.value).getGeometry() instanceof MultiPolygon)
    this.numEntriesLoaded = true
    this.numEntriesLoading = false
    this.previousGeometry = this.data.query?.geometry
    if (this.rasterSpatialQuery)this.addInputsMultiPolygons()
  }

  onTitleFocus() {
    if (this.form._controls.name.value === this.defaultTitle) {
      this.form._controls.name.setValue('')
    }
  }

  onTitleBlur() {
    if (!this.form._controls.name.value) {
      this.form._controls.name.setValue(this.defaultTitle)
    }
  }

  addInputsMultiPolygons() {
    const editedGeom = this.geometeryChanged()
    if (this.isMultiPolygon && this.form) {
      this.splitPolygons = this.mapWKTtoHex()
      if (this.splitPolygons.length && !editedGeom) {
        for (let i = 0; i < this.splitPolygons.length; i++) {
            const controlName = `polygon_name:${i}`
            this.form.addControl(
              controlName,
              new TypedFormControl<string>(undefined, Validators.required)
            )
            this.form._controls[controlName].setValue(this.splitPolygons[i].polygonName)
        }
      } else {
        // If geometry has been edited clean form
        for (let i = 0; i < this.splitPolygons.length; i++) {
          const polyname = `polygon_name:${i}`
          this.form.addControl(
            polyname,
            new TypedFormControl<string>(undefined, Validators.required))
        }
      }
    }
  }

  async getNumEntriesInArea(layer: AppLayer) {
    const isSampledLayer = layer.sampled
    const timeSeries = layer.timeSeriesSelection && layer.timeSeriesSelection.date_range ? layer.timeSeriesSelection : layer.renderedTimeSeriesSelection
    let upload: IPNumEntriesCheck
    if (timeSeries && layer.vectorTimeSeriesConf) {
      upload = {
        valid_from: timeSeries.date_range.from,
        valid_to: timeSeries.date_range.to,
        layer_preset: layer.preset as Db.Vip.LayerPreset,
        wkt_geometry: this.data.geometry as any,
        flood_area_ids: this.data.floodGeomIds
      }
    } else {
      upload = {
        layer_id: layer.id,
        layer_preset: layer.preset as Db.Vip.LayerPreset,
        wkt_geometry: this.data.geometry as any,
        flood_area_ids: this.data.floodGeomIds
      }
    }

    try {
      this.numEntries = await this._apiService.orm.Products().Fred().getNumEntries(upload).run()
      this.entriesMsg = DomUtil.entriesDialogMessage(this.numEntries, isSampledLayer)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
      return false
    }

    const entries = +this.numEntries.count + this.existingEntries
    this.dataCapWarning = entries > clientDataThreshold
    this.layerAlert = (entries < clientDataThreshold && entries > layerAlertThreshold)

    if (!this.dataCapWarning) this.form.controls.entries.setValue([...this.form.controls.entries.value, entries])
    return true
  }

  async save() {
    if (this.saving) return
    this.saving = true
    if (this.singleLayerFlag) {
      await this.SaveLayerQuery(this.data.layers[0])
    } else {
      this.data.layers.forEach(async (l) => {
        await this.SaveLayerQuery(l)
      })
    }
    this.saving = false
    this._dialogRef.close(true)
  }

  private async SaveLayerQuery(layer: AppLayer) {
    try {
      let query: IQueryWRef
      let previousGeom: any
      if (this.data.query) {
        query = this.data.query
        previousGeom = this.data.query.geometry
      } else {
        query = {
          applied: true,
          query: {
            blocks: [],
            operator: '',
            spatialSelector: this.floodAreaQueryIds.length ? this.floodAreaQueryIds : []
          },
          targetRef: layer,
          geometry: this.data.geometry as any,
          view_id: this._workspaceService.viewId as number,
          workspace_id: this._workspaceService.workspaceId as number,
          type: 'spatial',
          layer_id: layer.id
        } as Partial<IQueryWRef> as IQueryWRef
      }

      query.geometry = OlUtil.wktToFeature(this.form.controls.wktGeometry.value).getGeometry()
      query.name = this.form.controls.name.value
      query.query.includeEdge = this.form.controls.includeEdge.value
      query.query_metadata = this.isMultiPolygon ? this.setPolygonMetaData() : undefined

      const queryId = await this._layerQueriesService.toggleQuery(query, !!query.applied, true)
      const geojson = OlUtil.featureToGeoJSON(OlUtil.wktToFeature(this.form.controls.wktGeometry.value)) as GeoJSON
      if (this.rasterSpatialQuery && geojson) this.OpenRasterChartDialog(queryId, geojson, previousGeom)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  geometeryChanged(): boolean {
    let geomChanged: boolean = false
    const currentGeom = OlUtil.wktToFeature(this.form.controls.wktGeometry.value).getGeometry() as MultiPolygon | Polygon
    if (this.previousGeometry && currentGeom) {
      geomChanged = !OlUtil.areGeometriesEqual(this.previousGeometry, currentGeom)
    }
    return geomChanged
  }

  private setPolygonMetaData() {
    const polygonsMetaData = {} as Db.Helper.Prj.QueryMetaData
    if (!this.form && !this.splitPolygons.length) return
    const polygonControls = Object.keys(this.form.controls).filter(control => control.includes('polygon_name:'))
    const polygons: any[] = []
    for (let i = 0; i < this.splitPolygons.length; i++) {
      const polygonData  = {} as Db.Helper.Prj.Polygon
      polygonData.id = i
      polygonData.name = this.form.controls[polygonControls[i]].value
      polygonData.wkbhex = OlUtil.wkbHexFromWkt(this.splitPolygons[i].wkt) as string
      polygons.push(polygonData)
    }
    polygonsMetaData['polygons'] = polygons
    return polygonsMetaData
  }

  mapWKTtoHex(): MappedWKTtoHex[] {
    const mappedWKTtoHex: MappedWKTtoHex[] = []
    if (this.isMultiPolygon && this.form) {
      const splitPolygonsWKT: string[] = OlUtil.splitMultiPolygonWKT(this.form.controls.wktGeometry.value)
      //if polygons have been mapped
      if (this.data.query?.query_metadata && this.data.query?.query_metadata['polygons'] && splitPolygonsWKT.length) {
        const polygons = this.data.query?.query_metadata['polygons']
        for (let i = 0; i < splitPolygonsWKT.length; i++) {
          let mappedPoly = {} as MappedWKTtoHex
          const wktGeometry = OlUtil.wktToFeature(splitPolygonsWKT[i]).getGeometry() as MultiPolygon | Polygon
          const matchingPolygon = polygons.find(p => OlUtil.areGeometriesEqual(wktGeometry, OlUtil.wkbAsGeomtery(p['wkbhex'])))
          if (matchingPolygon) {
            mappedPoly.id = matchingPolygon.id
            mappedPoly.polygonName = matchingPolygon.name
            mappedPoly.wkt = splitPolygonsWKT[i]
            mappedPoly.geomHex = matchingPolygon.wkbhex
            mappedWKTtoHex.push(mappedPoly)
          } else {
            mappedPoly.id = i
            mappedPoly.polygonName = ''
            mappedPoly.wkt = splitPolygonsWKT[i]
            mappedPoly.geomHex = undefined
            mappedWKTtoHex.push(mappedPoly)
          }
        }
      } else {
        if (splitPolygonsWKT.length) {
          for (let i = 0; i < splitPolygonsWKT.length; i++) {
            let mappedPoly = {} as MappedWKTtoHex
            mappedPoly.id = i
            mappedPoly.polygonName = ''
            mappedPoly.wkt = splitPolygonsWKT[i]
            mappedPoly.geomHex = undefined
            mappedWKTtoHex.push(mappedPoly)
          }
        }
      }
    }
    mappedWKTtoHex.sort((a, b) => a.id - b.id)
    return mappedWKTtoHex
  }

  OpenRasterChartDialog(queryId: string, geojsonOb: GeoJSON, previousGeom?: any): void {
    let workspaceLeave: Subscription
    const dialog = this._dialog
      .open(RasterChartDialogComponent, RasterChartDialogComponent.setOptions({
        queryId,
        layerRaster: this.data.layers[0] as AppRasterLayer,
        geojson: geojsonOb,
        prevGeom: previousGeom,
        isDialogMode: true
      }))

    dialog.afterClosed()
      .subscribe(() => workspaceLeave && workspaceLeave.unsubscribe())

    workspaceLeave = this._workspaceService.onExit
      .pipe(take(1))
      .subscribe(() => dialog.close())
  }

}
