import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import * as moment from 'moment'
import { AlertDialogComponent } from '@components/alert-dialog/alert-dialog.component'
import { Subject, Observable } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private _alerts: {
    text: string
    time: moment.Moment
    keep: boolean
    keepOnLogin: boolean
  } [] = []
  private _dialogRef ?: MatDialogRef < AlertDialogComponent >
  private _alertClosed = new Subject()

  get alertClosed (): Observable<any> {
    return this._alertClosed.asObservable()
  }

  constructor (
    private _dialog: MatDialog
  ) {}

  log (text: string, keep?: boolean, keepOnLogin?: boolean) {
    this._alerts.push({
      text,
      time: moment(),
      keep: !!keep,
      keepOnLogin: !!keepOnLogin
    })

    if (!this._dialogRef) {
      this._dialogRef = this._dialog.open(AlertDialogComponent, AlertDialogComponent.setOptions(this._alerts))
      this._dialogRef.afterClosed().subscribe(() => {
        this._alertClosed.next({})
        this._dialogRef = undefined
        this._alerts = []
      })
    } else {
      this._dialogRef.componentInstance.updateAlert()
    }
  }

  closeAlertsOnLogin () {
    let idxArr: number[] = []
    for (let i = 0; i < this._alerts.length; i++) {
      !this._alerts[i].keepOnLogin && idxArr.push(i)
    }
    for (let j = idxArr.length - 1; j > -1; j--) {
      this._alerts.splice(idxArr[j], 1)
    }

    if (this._dialogRef) {
      if (this._alerts.length) this._dialogRef.componentInstance.updateAlert()
      else this._dialogRef.close()
    }
  }
}
