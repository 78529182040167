<div class="icons-container" [class.rotated]="transform3D">
  <div class="icon" *ngFor="let type of types; let i = index" [matTooltip]="type.type"
  [style.top]="transform3D?.typeSpacing[i].top || 0"
  [style.left]="transform3D?.typeSpacing[i].left || 0">

    <ng-container [ngSwitch]="type.type">
      <ng-container *ngSwitchCase="'Point'" [ngTemplateOutlet]="point"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
      </ng-container>

      <ng-container *ngSwitchCase="'MultiPoint'" [ngTemplateOutlet]="multiPoint"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
      </ng-container>

      <ng-container *ngSwitchCase="'LineString'" [ngTemplateOutlet]="lineString"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
      </ng-container>

      <ng-container *ngSwitchCase="'MultiLineString'" [ngTemplateOutlet]="multiLineString"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
      </ng-container>

      <ng-container *ngSwitchCase="'Polygon'" [ngTemplateOutlet]="polygon"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
      </ng-container>

      <ng-container *ngSwitchCase="'MultiPolygon'" [ngTemplateOutlet]="multiPolygon"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
      </ng-container>

      <ng-container *ngSwitchCase="'Unknown'" [ngTemplateOutlet]="point"
      [ngTemplateOutletContext]="{fill: type.fill, stroke: type.stroke}">
        <!-- For now default to point -->
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #point let-stroke="stroke" let-fill="fill">
  <svg viewBox="0 0 15 15" width="15" height="15">
    <circle cx="50%" cy="50%" r="45%" [attr.fill]="fill || 'transparent'" stroke-width="2"
      [attr.stroke]="stroke || 'transparent'" />
  </svg>
</ng-template>

<ng-template #multiPoint let-stroke="stroke" let-fill="fill">
  <svg viewBox="0 0 15 15" width="15" height="15">
    <circle cx="50%" cy="50%" r="45%" [attr.fill]="fill || 'transparent'" stroke-width="2"
      [attr.stroke]="stroke || 'transparent'" />

    <g>
      <line class="bottom-cross" x1="75%" y1="50%" x2="75%" y2="100%"/>
      <line class="bottom-cross" x1="7.6" y1="75%" x2="100%" y2="75%"/>
      <line class="top-cross" x1="75%" y1="53%" x2="75%" y2="96%"/>
      <line class="top-cross" x1="54%" y1="75%" x2="96%" y2="75%"/>
    </g>
  </svg>
</ng-template>

<ng-template #lineString let-stroke="stroke" let-fill="fill">
  <svg viewBox="0 0 15 15" width="15" height="15">
    <line x1="50%" y1="0" x2="50%" y2="100%" stroke-width="4" [attr.stroke]="fill || 'transparent'" />
  </svg>
</ng-template>

<ng-template #multiLineString let-stroke="stroke" let-fill="fill">
  <svg viewBox="0 0 15 15" width="15" height="15">
    <line x1="50%" y1="0" x2="50%" y2="100%" stroke-width="4" [attr.stroke]="fill || 'transparent'" />

    <g>
      <line class="bottom-cross" x1="75%" y1="50%" x2="75%" y2="100%"/>
      <line class="bottom-cross" x1="7.6" y1="75%" x2="100%" y2="75%"/>
      <line class="top-cross" x1="75%" y1="53%" x2="75%" y2="96%"/>
      <line class="top-cross" x1="54%" y1="75%" x2="96%" y2="75%"/>
    </g>
  </svg>
</ng-template>

<ng-template #polygon let-stroke="stroke" let-fill="fill">
  <svg viewBox="0 0 15 15" width="17" height="17">
    <polygon points="7.5,2 13,5.25 11.85,13 3.15,13 2,5.25" [attr.fill]="fill || 'transparent'" stroke-width="2"
      [attr.stroke]="stroke || 'transparent'" />
  </svg>
</ng-template>

<ng-template #multiPolygon let-stroke="stroke" let-fill="fill">
  <svg viewBox="0 0 15 15" width="17" height="17">
    <polygon points="7.5,2 13,5.25 11.85,13 3.15,13 2,5.25" [attr.fill]="fill || 'transparent'" stroke-width="2"
      [attr.stroke]="stroke || 'transparent'" />

      <g>
        <line class="bottom-cross" x1="75%" y1="50%" x2="75%" y2="100%"/>
        <line class="bottom-cross" x1="7.6" y1="75%" x2="100%" y2="75%"/>
        <line class="top-cross" x1="75%" y1="53%" x2="75%" y2="96%"/>
        <line class="top-cross" x1="54%" y1="75%" x2="96%" y2="75%"/>
      </g>
  </svg>
</ng-template>
