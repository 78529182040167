import { Component, OnDestroy } from '@angular/core'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { VipApiService, SettingsService, AlertService, PromptService, AuthService } from '@services/core'
import { ZipService } from '@services/core/zip/zip.service'
import { CommonUtil, ConvertUtil } from '@core/utils/index'
import { JSZipObject } from 'jszip'
import * as shapefile from 'shapefile'
import { FeatureCollection } from 'geojson'
import { IJson } from '@vip-shared/interfaces'
import AppError, { handleError } from '@core/models/app-error'
import { UploadListDialogComponent } from '@core/page-components/upload-list-dialog/upload-list-dialog.component'
import { UploadInfoDialogComponent } from '../upload-info-dialog/upload-info-dialog.component'
import { IUploadFileInfo } from '@core/types/upload-file-info'
import { DatasetTableManagerDialogComponent } from '../dataset-table-manager-dialog/dataset-table-manager-dialog.component'
import { Db } from '@vip-shared/models/db-definitions'

type UploadType = 'geom' | 'roof-type' | 'roof-material' | 'constr-class' | 'project-tag' | 'guide'
type TableManager = UploadType | 'ml-dataset'
@Component({
  selector: 'app-property-view-dataset-manager',
  templateUrl: './property-view-dataset-manager.component.html',
  styleUrls: ['./property-view-dataset-manager.component.scss']
})
export class PropertyViewDatasetManagerComponent implements OnDestroy, DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  accept = {
    geometry: ['.zip', '.rar', '.dbf', '.prj', '.shp', '.json', '.geojson'],
    csv: ['.csv']
  }
  uploading = false
  parsing = false
  uploads: {
    file: File
    done: boolean
  }[] = []

  geojsonsUploaded: string[] = []
  shapefiles: {[key: string]: {
    dbf?: File | JSZipObject
    shp?: File | JSZipObject
    prj?: File | JSZipObject
    uploaded?: boolean
  }} = {}

  updateExisting = false

  infoOnGuide: IUploadFileInfo = {
    title: 'Guide list - CSV',
    description: 'Add/update guide ID definitions',
    sideEffect: `If 'Update existing entries' is selected - this will update existing ID display names. Otherwise it will error on duplicate entry.`,
    columns: [{
      name: 'id',
      type: 'string'
    }, {
      name: 'display_name',
      type: 'string'
    }, {
      name: 'location_url',
      type: 'string',
      optional: true,
      description: 'Location, preferably URL link, to the guide document.'
    }, {
      name: 'version',
      type: 'string'
    }, {
      name: 'date',
      type: 'date string',
      optional: true,
      description: `Date in format 'YYYY-MM-DD HH:MM:SS'`
    }]
  }
  infoOnGeometry: IUploadFileInfo = {
    title: 'Building geometries (with annotation) - GeoJson or Shapefile',
    description: 'Add/update geometry definitions and related annotation/QA of annotation',
    sideEffect: `If 'Update existing entries' is selected - this will update existing geometries and their annotation matched by geometry ID.` +
    ` Otherwise it will error on duplicate geometry ID entry.\n\n` +
    `Empty values, other than for building geometry, will be ignored.`,
    columns: [{
      name: 'uuid',
      description: 'Building geometry unique ID',
      type: 'string'
    }, {
      name: 'address',
      description: 'Building address',
      type: 'string'
    }, {
      name: 'city',
      description: 'Building city',
      type: 'string'
    }, {
      name: 'country',
      description: 'Building country',
      type: 'string'
    }, {
      name: 'num_storey',
      description: 'Number of storeys in building',
      type: 'integer',
      optional: true
    }, {
      name: 'proj_tag',
      description: 'Project tag, to which geometry should be linked. (eg: \'chubb\' for Chubb project)',
      type: 'string',
      optional: true
    }, {
      name: 'height_m',
      description: 'Building height annotation in meters',
      type: 'number',
      optional: true
    }, {
      name: 'roof_type1',
      description: 'Roof type ID - First estimate for roof type annotation',
      type: 'number',
      optional: true
    }, {
      name: 'roof_type2',
      description: 'Roof type ID - Second estimate for roof type annotation',
      type: 'number',
      optional: true
    }, {
      name: 'roof_type3',
      description: 'Roof type ID - Third estimate for roof type annotation',
      type: 'number',
      optional: true
    }, {
      name: 'qa_roof_t1',
      description: 'Roof type ID - QA for roof type annotation',
      type: 'number',
      optional: true
    }, {
      name: 'area_m2',
      description: 'Area annotation in square meters',
      type: 'number',
      optional: true
    }, {
      name: 'roof_mat',
      description: 'Roof material ID - Roof material annotation',
      type: 'number',
      optional: true
    }, {
      name: 'const_cl',
      description: 'Construction class ID - Construction class annotation',
      type: 'number',
      optional: true
    }, {
      name: 'guide',
      description: 'Guide ID - Guide which was used for annotation',
      type: 'number',
      optional: true
    }]
  }

  infoOnRoofTypes: IUploadFileInfo = {
    title: 'Roof Type List - CSV',
    description: 'Add/update roof material ID definitions',
    sideEffect: `If 'Update existing entries' is selected - this will update existing ID display names. Otherwise it will error on duplicate entry.`,
    columns: [{
      name: 'id',
      type: 'number'
    }, {
      name: 'display_name',
      type: 'string'
    }]
  }

  infoOnRoofMaterial: IUploadFileInfo = {
    title: 'Roof Material List - CSV',
    description: 'Add/update roof material ID definitions',
    sideEffect: `If 'Update existing entries' is selected - this will update existing ID display names. Otherwise it will error on duplicate entry.`,
    columns: [{
      name: 'id',
      type: 'number'
    }, {
      name: 'display_name',
      type: 'string'
    }]
  }

  infoOnConstrClass: IUploadFileInfo = {
    title: 'Construction Class List - CSV',
    description: 'Add/update construction class ID definitions',
    sideEffect: `If 'Update existing entries' is selected - this will update existing ID display names. Otherwise it will error on duplicate entry.`,
    columns: [{
      name: 'id',
      type: 'number'
    }, {
      name: 'display_name',
      type: 'string'
    }]
  }
  infoOnProjectTag: IUploadFileInfo = {
    title: 'Project Tag List - CSV',
    description: 'Add/update project tag definitions',
    sideEffect: `If 'Update existing entries' is selected - this will update existing tag display names. Otherwise it will error on duplicate entry.`,
    columns: [{
      name: 'proj_tag',
      type: 'string',
      description: `Project tag in lower case with underscore for space (eg: chubb)`
    }, {
      name: 'display_name',
      type: 'string',
      description: `Name that will be shown to end user (eg: Chubb)`
    }]
  }

  get uploadsCount (): number {
    return Object.keys(this.shapefiles).length + this.geojsonsUploaded.length
  }

  get pendingCount (): number {
    return Object.keys(this.shapefiles).map(x =>
      this.shapefiles[x].uploaded ? 0 : ['dbf', 'shp', 'prj'].filter(k => !this.shapefiles[x][k]).length
    ).reduce((sum, x) => sum + x, 0)
  }

  get uploadText () {
    const max = this.uploads.length
    return `Uploading ${
      Math.min(this.uploads.reduce((sum, val) => val.done ? sum + 1 : sum, 0)
      + 1, max)
    } out of ${max}...`
  }

  constructor (
    private _api: VipApiService,
    private _settingsService: SettingsService,
    private _alertService: AlertService,
    private _zipService: ZipService,
    private _dialog: MatDialog,
    private _authService: AuthService
  ) { }

  ngOnDestroy () {
    this._destroyDialogs()
  }

  showTooltip (e: MouseEvent, info: IUploadFileInfo) {
    e.stopPropagation()
    this._trackDialog(
      this._dialog.open(UploadInfoDialogComponent, UploadInfoDialogComponent.setup(info))
    )
  }

  listUploadedFiles (e: MouseEvent) {
    e.stopPropagation()
    const keys = Object.keys(this.shapefiles)
    const uploaded = keys.filter(x => this.shapefiles[x].uploaded)
    const pending = keys.filter(x => !this.shapefiles[x].uploaded)

    this._trackDialog(
      this._dialog.open(UploadListDialogComponent, {
        data: {
          uploaded: [...uploaded.map(x => ({
            name: x
          })), ...this.geojsonsUploaded.map(x => ({
            name: x
          }))],
          pending: pending.map(x => ({
            name: x,
            missing: ['dbf', 'shp', 'prj'].filter(k => !this.shapefiles[x][k]),
            uploaded: ['dbf', 'shp', 'prj'].filter(k => this.shapefiles[x][k])
          }))
        }
      })
    )
  }

  async openTableManager (e: Event, type: TableManager) {
    e.stopPropagation()
    let options: any
    const api = this._api.orm.Products().PropertyView()
    const canDelete = this._authService.isSysAdmin

    const layerMeasuresAffected = `It will affect layers using the dataset by rendering measures blank.`
    const layerRowsAffected = `It will affect layers using the dataset by reducing or clearing all rendered geometries.`
    const proceed = `Are you sure you want to continue?`

    const measureDisablePrompt = (count: number) => `This will also hide all related measures (${count}). ${layerMeasuresAffected} ${proceed}`
    const measureDeletePrompt = (count: number) => `This will also permanently delete all related measures (${count}). ${layerMeasuresAffected} ${proceed}`

    try {
      switch (type) {
        case 'ml-dataset':
          options = DatasetTableManagerDialogComponent.setOptions<Db.Vip.PV.IMlDataset>({
            displayColumns: [
              Db.Vip.PV.MlDataset.ML_DATASET_ID, Db.Vip.PV.MlDataset.NAME,
              Db.Vip.PV.MlDataset.NAME_MODEL, Db.Vip.PV.MlDataset.UPDATED_AT
            ],
            rows: await api.MlDatasets().get(true).run(),
            deletePrompt: canDelete ? async row => {
              const count = await api.MlDatasets().Dataset(row.ml_dataset_id).usage().run()
              if (!count) return
              return measureDeletePrompt(count)
            } : undefined,
            delete: canDelete ? async (row) => {
              await api.MlDatasets().Dataset(row.ml_dataset_id).delete().run()
            } : undefined,
            disablePrompt: async row => {
              const count = await api.MlDatasets().Dataset(row.ml_dataset_id).usage().run()
              if (!count) return
              return measureDisablePrompt(count)
            },
            toggleArchived: async (row) => {
              const disabled = !row.disabled
              const endpoint = api.MlDatasets().Dataset(row.ml_dataset_id)
              if (disabled) await endpoint.disable().run()
              else await endpoint.enable().run()

              row.disabled = disabled
              return row
            }
          })
          break
        case 'project-tag':
          options = DatasetTableManagerDialogComponent.setOptions<Db.Vip.PV.IProject>({
            displayColumns: [
              Db.Vip.PV.Project.PROJECT_TAG, Db.Vip.PV.Project.DISPLAY_NAME,
              Db.Vip.PV.Project.UPDATED_AT
            ],
            rows: await api.Projects().get(true).run(),
            deletePrompt: canDelete ? async row => {
              const count = await api.Projects().Project(row.project_tag).usage().run()
              if (!count) return

              return `This will also permanently remove project reference to related geometries (${count}). ${layerRowsAffected} ${proceed}`
            } : undefined,
            delete: canDelete ? async row => {
              await api.Projects().Project(row.project_tag).delete().run()
            } : undefined,
            disablePrompt: async row => {
              const count = await api.Projects().Project(row.project_tag).usage().run()
              if (!count) return

              return `${layerRowsAffected} ${proceed}`
            },
            toggleArchived: async (row) => {
              const disabled = !row.disabled
              const endpoint = api.Projects().Project(row.project_tag)
              if (disabled) await endpoint.disable().run()
              else await endpoint.enable().run()

              row.disabled = disabled
              return row
            }
          })
          break
        case 'guide':
          options = DatasetTableManagerDialogComponent.setOptions<Db.Vip.PV.IGuide>({
            displayColumns: [
              Db.Vip.PV.Guide.NAME, Db.Vip.PV.Guide.VERSION,
              Db.Vip.PV.Guide.LOCATION_URL, Db.Vip.PV.Guide.UPDATED_AT
            ],
            rows: await api.Guides().get(true).run(),
            deletePrompt: canDelete ? async row => {
              const count = await api.Guides().Guide(row.guide_id).usage().run()
              if (!count) return
              return measureDeletePrompt(count)
            } : undefined,
            delete: canDelete ? async row => {
              await api.Guides().Guide(row.guide_id).delete().run()
            } : undefined,
            disablePrompt: async row => {
              const count = await api.Guides().Guide(row.guide_id).usage().run()
              if (!count) return
              return measureDisablePrompt(count)
            },
            toggleArchived: async (row) => {
              const disabled = !row.disabled
              const endpoint = api.Guides().Guide(row.guide_id)
              if (disabled) await endpoint.disable().run()
              else await endpoint.enable().run()

              row.disabled = disabled
              return row
            }
          })
          break
        case 'constr-class':
          options = DatasetTableManagerDialogComponent.setOptions<Db.Vip.PV.IConstrClass>({
            displayColumns: [
              Db.Vip.PV.ConstrClass.CONSTR_CLASS_ID,
              Db.Vip.PV.ConstrClass.NAME, Db.Vip.PV.ConstrClass.UPDATED_AT
            ],
            rows: await api.ConstructionClasses().get(true).run(),
            deletePrompt: canDelete ? async row => {
              const count = await api.ConstructionClasses().Class(row.constr_class_id).usage().run()
              if (!count) return
              return measureDeletePrompt(count)
            } : undefined,
            delete: canDelete ? async row => {
              await api.ConstructionClasses().Class(row.constr_class_id).delete().run()
            } : undefined,
            disablePrompt: async row => {
              const count = await api.ConstructionClasses().Class(row.constr_class_id).usage().run()
              if (!count) return
              return measureDisablePrompt(count)
            },
            toggleArchived: async (row) => {
              const disabled = !row.disabled
              const endpoint = api.ConstructionClasses().Class(row.constr_class_id)
              if (disabled) await endpoint.disable().run()
              else await endpoint.enable().run()

              row.disabled = disabled
              return row
            }
          })
          break
        case 'roof-material':
          options = DatasetTableManagerDialogComponent.setOptions<Db.Vip.PV.IRoofMaterial>({
            displayColumns: [
              Db.Vip.PV.RoofMaterial.ROOF_MATERIAL_ID,
              Db.Vip.PV.RoofMaterial.NAME, Db.Vip.PV.RoofMaterial.UPDATED_AT
            ],
            rows: await api.RoofMaterials().get(true).run(),
            deletePrompt: canDelete ? async row => {
              const count = await api.RoofMaterials().Material(row.roof_material_id).usage().run()
              if (!count) return
              return measureDeletePrompt(count)
            } : undefined,
            delete: canDelete ? async row => {
              await api.RoofMaterials().Material(row.roof_material_id).delete().run()
            } : undefined,
            disablePrompt: async row => {
              const count = await api.RoofMaterials().Material(row.roof_material_id).usage().run()
              if (!count) return
              return measureDisablePrompt(count)
            },
            toggleArchived: async (row) => {
              const disabled = !row.disabled
              const endpoint = api.RoofMaterials().Material(row.roof_material_id)
              if (disabled) await endpoint.disable().run()
              else await endpoint.enable().run()

              row.disabled = disabled
              return row
            }
          })
          break
        case 'roof-type':
          options = DatasetTableManagerDialogComponent.setOptions<Db.Vip.PV.IRoofType>({
            displayColumns: [
              Db.Vip.PV.RoofType.ROOF_TYPE_ID,
              Db.Vip.PV.RoofType.NAME, Db.Vip.PV.RoofType.UPDATED_AT
            ],
            rows: await api.RoofTypes().get(true).run(),
            deletePrompt: canDelete ? async row => {
              const count = await api.RoofTypes().Type(row.roof_type_id).usage().run()
              if (!count) return
              return measureDeletePrompt(count)
            } : undefined,
            delete: canDelete ? async row => {
              await api.RoofTypes().Type(row.roof_type_id).delete().run()
            } : undefined,
            disablePrompt: async row => {
              const count = await api.RoofTypes().Type(row.roof_type_id).usage().run()
              if (!count) return
              return measureDisablePrompt(count)
            },
            toggleArchived: async (row) => {
              const disabled = !row.disabled
              const endpoint = api.RoofTypes().Type(row.roof_type_id)
              if (disabled) await endpoint.disable().run()
              else await endpoint.enable().run()

              row.disabled = disabled
              return row
            }
          })
          break
        default:
          throw new AppError(`Table not implemented for type '${type}'.`)
      }

      this._trackDialog(
        this._dialog.open(
          DatasetTableManagerDialogComponent,
          options
        )
      )
    } catch (error: any) {
      this._alertService.log(error.message)
    }
  }

  async uploadFiles (type: UploadType, files?: File[], parseCb?: (files: File[]) => Promise<File[]>) {
    if (this.uploading || this.parsing) return

    if (files) {
      this.parsing = true
      let fileInProgress: File | undefined
      try {
        if (parseCb) files = await parseCb(files)

        this.uploads = files.map(x => ({
          file: x,
          done: false
        }))

        const endpoint = this._api.orm.Products().PropertyView()
        for (const file of files) {
          fileInProgress = file

          switch (type) {
            case 'geom':
              const shp = this.shapefiles[file.name.split('.').slice(0, -1).join('.')]
              try {
                await endpoint.Measures().uploadAnnotation(file, this.updateExisting).run()
                if (shp) shp.uploaded = true
                else this.geojsonsUploaded.push(file.name)
              } catch (error: any) {
                delete this.shapefiles[file.name.split('.').slice(0, -1).join('.')]
                throw error
              }
              break
            case 'constr-class':
              await endpoint.ConstructionClasses().upload(file, this.updateExisting).run()
              break
            case 'project-tag':
              await endpoint.Projects().upload(file, this.updateExisting).run()
              break
            case 'roof-material':
              await endpoint.RoofMaterials().upload(file, this.updateExisting).run()
              break
            case 'roof-type':
              await endpoint.RoofTypes().upload(file, this.updateExisting).run()
              break
            case 'guide':
              await endpoint.Guides().upload(file, this.updateExisting).run()
              break
          }

          (this.uploads.find(x => x.file === file) as any).done = true
        }
      } catch (error: any) {
        this._alertService.log(`${
          fileInProgress ?
          `Failed to upload '${fileInProgress.name}'. ` : ''
        }${error.message}`)
      }
      this.resetState()
    }
  }

  async uploadGeometry (files?: File[]) {
    await this.uploadFiles('geom', files, this.InterpretVectorFiles.bind(this))
  }

  resetState () {
    this.parsing = false
    this.uploading = false
    this.uploads = []
  }

  private async InterpretVectorFiles (files: File[]): Promise<File[]> {
    const geojsonFiles: File[] = []

    try {
      for (const file of files) {
        CommonUtil.validateFile(file, this.accept.geometry, this._settingsService.maxApiFileSize)
        const name = file.name.split('.').slice(0, -1).join('.')

        if (this._zipService.isZipped(file)) {
          const { files: zipFilesObj } = await this._zipService.getEntries(file)
          const zipFiles = Object.values(zipFilesObj)
          const dbf = zipFiles.find(x => x.name.toLowerCase().endsWith('.dbf'))
          const shp = zipFiles.find(x => x.name.toLowerCase().endsWith('.shp'))
          const prj = zipFiles.find(x => x.name.toLowerCase().endsWith('.prj'))

          if (this.shapefiles[name]) throw new AppError(`Shapefile '${name}' data already selected from another file with same name.`)

          this.shapefiles[name] = { dbf, shp, prj }
        } else if (this.IsGeojson(file)) {
          geojsonFiles.push(file)
        } else if (this.IsPartOfShapefile(file)) {
          if (!this.shapefiles[name]) this.shapefiles[name] = {}
          if (this.shapefiles[name].uploaded) throw new AppError(`Shapefile '${name}' already uploaded.`)
          const ext = file.name.toLowerCase().split('.').pop()

          if (ext === 'dbf') {
            if (this.shapefiles[name].dbf) throw new AppError(`File '.dbf' for shapefile '${name}' already selected.`)
            this.shapefiles[name].dbf = file
          }
          if (ext === 'shp') {
            if (this.shapefiles[name].shp) throw new AppError(`File '.shp' for shapefile '${name}' already selected.`)
            this.shapefiles[name].shp = file
          }
          if (ext === 'prj') {
            if (this.shapefiles[name].prj) throw new AppError(`File '.prj' for shapefile '${name}' already selected.`)
            this.shapefiles[name].prj = file
          }
        }
      }
    } catch (error: any) {
      handleError(error)
      throw new AppError(`Failed to read selected files. ${error.message}`)
    }

    try {
      const keys = Object.keys(this.shapefiles)
      geojsonFiles.push(
        ...(await this.ConvertShapefilesToGeojson()).map((x, i) => ConvertUtil.geojsonToFile(x, `${keys[i]}.json`))
      )
    } catch (error: any) {
      handleError(error)
      throw new AppError(`Failed to transform shapefiles to geojson. ${error.message}`)
    }

    return geojsonFiles
  }

  private async ConvertShapefilesToGeojson (): Promise<FeatureCollection[]> {
    const geojsons: FeatureCollection[] = []

    for (const k of Object.keys(this.shapefiles)) {
      try {
        const { dbf, prj, shp } = this.shapefiles[k]
        if (!dbf || !prj || !shp) {
          continue
        }

        const dbfArr = await this.GetArrayBuffer(dbf)
        const prjArr = await this.GetArrayBuffer(prj)
        const shpArr = await this.GetArrayBuffer(shp)

        const features: any[] = []
        await shapefile.open(shpArr, dbfArr)
        .then(source => source.read()
          .then(function log (result) {
            if (result.done) return
            features.push(result.value)
            return source.read().then(log)
          })
        )

        geojsons.push({
          crs: {
            type: 'name',
            properties: { name: CommonUtil.arrayBuffer2String(prjArr) }
          },
          type: 'FeatureCollection',
          features
        } as any)
      } catch (error: any) {
        delete this.shapefiles[k]
        throw error
      }
    }

    return geojsons
  }

  private async GetArrayBuffer (file: File | JSZipObject): Promise<ArrayBuffer> {
    return file instanceof File ? this.ReadFileAsArrayBuffer(file) : file.async('arraybuffer')
  }

  private ReadFileAsArrayBuffer (file: File): Promise<ArrayBuffer> {
    return new Promise((res, rej) => {
      const reader = new FileReader()
      reader.addEventListener('loadend', function (e) {
        if (!reader.result) {
          rej(new AppError(`Web browser was unable to load the file '${file.name}'.`))
        } else {
          res(reader.result as ArrayBuffer)
        }
      })
      reader.readAsArrayBuffer(file)
    })
  }

  private IsGeojson (file: File) {
    if (!file.type || file.type === 'application/octet-stream') {
      const name = file.name.toLowerCase()
      return name.endsWith('.json') || name.endsWith('.geojson')
    }
    return ['application/json'].includes(file.type)
  }

  private IsPartOfShapefile (file: File) {
    const name = file.name.toLowerCase()
    return name.endsWith('.dbf') || name.endsWith('.prj') || name.endsWith('.shp')
  }
}

applyMixins(PropertyViewDatasetManagerComponent, [DialogCleanup])
