import { Component, Inject, OnInit, ViewChild, Optional } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Db } from '@vip-shared/models/db-definitions'
import { WorkspaceService } from '@services/workspace'
import { AdminService } from '@services/admin'
import { AuthService, SettingsService, VipApiService } from '@services/core'
import { IRCustomerUser } from '@vip-shared/interfaces'

@Component({
  selector: 'app-user-access-manager',
  templateUrl: './user-access-manager.component.html',
  styleUrls: ['./user-access-manager.component.scss']
})
export class UserAccessManagerComponent implements OnInit {
  accounts: WorkspaceUser[] = []
  dataSource?: MatTableDataSource<WorkspaceUser>
  type?: 'workspace' | 'view'
  displayedColumns = ['user_forename', 'user_surname', 'user_email', 'customer_name', 'actions']
  roles?: Db.Vip.Cst.IRole[]
  editorPermissions = Infinity
  @ViewChild(MatSort, { static: false }) sort?: MatSort

  stickyHeader = true
  constructor (
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {workspaceId: number, type: 'workspace' | 'view'},
    private _workspaceService: WorkspaceService,
    private _adminService: AdminService,
    private _authService: AuthService,
    private _settingsService: SettingsService,
    private _api: VipApiService
  ) {
    this.stickyHeader = !this._settingsService.isIEorEdge(false)
  }

  ngOnInit () {
    this.GetRoles()
    if (this.data.type === 'workspace') this.LoadWorkspaceUsers()
    else if (this.data.type === 'view') this.LoadViewUsers()
  }

  private async LoadWorkspaceUsers () {
    this.type = 'workspace'
    this.displayedColumns.push('role') // only allow managing roles from the workspace view
    await this.GetWorkspaceAccounts()
    this.GenerateEditorPermissions()
  }

  private async LoadViewUsers () {
    this.type = 'view'
    await this.GetViewUsers()
    this.GenerateEditorPermissions()
    this.data.workspaceId = this._workspaceService.workspaceId as number
  }

  async toggleUserAccess (e: MatSlideToggleChange, user: WorkspaceUser) {
    if (e.checked !== user.access_enabled) {
      if (this.type === 'workspace') {
        if (e.checked) {
          await this._api.orm.Workspaces().Workspace(this.data.workspaceId).Users()
          .changePermissions({
            user_id: user.user_id,
            permissions_level: Db.Vip.Role.WS_EDITOR
          }).run()
        } else {
          this._workspaceService.removeWorkspaceUser(this.data.workspaceId, user.user_id)
        }
        this.GetWorkspaceAccounts()
      } else if (this.type === 'view') {
        if (e.checked) {
          this._workspaceService.addViewUser(user.user_id)
        } else {
          this._workspaceService.removeViewUser(user.user_id)
        }
        this.GetViewUsers()
      }
      user.access_enabled = e.checked
    }
  }

  async updatePermissions (account: WorkspaceUser) {
    if (this.type === 'workspace') {
      await this._api.orm.Workspaces().Workspace(this.data.workspaceId).Users()
      .changePermissions({
        user_id: account.user_id,
        permissions_level: account.workspace_permissions_level as number
      }).run()
      this.GetWorkspaceAccounts()
    }
  }

  accessEditingAllowed (account: WorkspaceUser) {
    // MSF TODO: the UI will still need some work to represent the permissions more accurately,
    // eg. don't allow to edit if the permission level comes from being a system / customer
    // admin as you can't remove access from those accounts, might need input from Salvo to
    // figure out how we want to communicate that best
    return this._authService.isSysMaintainer
      ? true
      : account.workspace_permissions_level
        ? this.editorPermissions <= account.workspace_permissions_level
        : this.editorPermissions === 200
  }

  roleEditingAllowed (account: WorkspaceUser) {
    return account.access_enabled
      ? this.accessEditingAllowed(account)
      : false
  }

  private async GetWorkspaceAccounts () {
    const accounts = await this._adminService.getUsers()
    const workspaceUsers = await this._workspaceService.getWorkspaceUsers(this.data.workspaceId)
    this.accounts = accounts.map(x => {
      const n = { ...x } as WorkspaceUser
      const match = workspaceUsers.find(y => y.user_id === x.user_id)
      if (match) {
        if (match.workspace_permissions_level) {
          n.access_enabled = true
          n.role_name = match.workspace_role_display_name
          n.workspace_permissions_level = match.workspace_permissions_level
        }
      } else {
        console.warn(`No access data found for user ${x.user_forename} ${x.user_surname}`)
      }
      return x
    })

    this.dataSource = new MatTableDataSource(this.accounts)

    if (this.sort) this.dataSource.sort = this.sort
  }

  private async GetViewUsers () {
    // MSF TODO: views will still need to be reworked in this component and relevant services
    // we will probably need to use a psql view like we use for workspaces but parking it for
    // now as the concept of views is not fully implemented anyway
    const workspaceUsers = await this._workspaceService.getWorkspaceUsers()
    const viewUsers = await this._workspaceService.getViewUsers()
    this.accounts = workspaceUsers.map((x: any) => {
      const match = viewUsers.find(y => y.user_id === x.user_id)
      x.access_enabled = match && (x.workspace_permissions || x.workspace_role_permissions || x.workspace_permissions_level) ? true : false
      x.role_name = match && (x.workspace_permissions || x.workspace_role_permissions || x.workspace_permissions_level) ? match.role_display_name : undefined

      return x
    })

    this.dataSource = new MatTableDataSource(this.accounts)

    if (this.sort !== undefined) {
      this.dataSource.sort = this.sort
    }
  }

  private async GetRoles () {
    this.roles = await this._adminService.getRoles('workspace')
  }

  private GenerateEditorPermissions () {
    const editor = this.accounts.find(account => +account.user_id === +this._authService.userId)
    if (editor && editor.workspace_permissions_level) {
      this.editorPermissions = editor.workspace_permissions_level
    }
  }
}

type WorkspaceUser = IRCustomerUser & {
  access_enabled?: boolean
  workspace_permissions_level?: number
  role_name?: string
}
