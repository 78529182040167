import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

import { Subject, Subscription } from 'rxjs'
import Feature from 'ol/Feature'
import VectorLayer from 'ol/layer/Vector'
import { AppLayerGroup, AppLayerGeneric } from '@core/models/layer'
import {
  IQueryWRef, IQueryGroup
} from '@core/models/query-object'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { LayerService } from '../layer/layer.service'
import { WorkspaceService } from '../workspace.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AppLayer } from '@core/models/layer/app-layer'
import { handleError } from '@core/models/app-error'
import { AlertService, PromptService } from '@services/core'
import { IFeatureSystemProperties, IFeatureCustomColor } from '@core/types'
import { IPNewQuery } from '@vip-shared/interfaces'
import * as turf from '@turf/turf'
import GeometryCollection from 'ol/geom/GeometryCollection'
import * as olGeom from 'ol/geom'
import OlUtil from '@core/utils/ol/ol.util'
import { WidgetLayerService } from './widget-layer.service'

type QueryableLayer = AppLayer | {
  groupName: string
  targets: (AppLayer | AppLayerGroup)[]
}

@Injectable({
  providedIn: 'root'
})

export class WidgetQueryLayerService implements DialogCleanup {
  private _subscriptions = new Subscription()
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog (dialog: MatDialogRef<any>): any { return }
  _untrackDialog (dialog: MatDialogRef<any>): any { return }
  _destroyDialogs (): any { return }

  queryableLayers?: QueryableLayer[]

  private _queryableLayersSubscriptions = new Subscription()

  private _groupedQueryList: IQueryGroup[] = []
  get groupedQueries (): IQueryGroup[] {
    return this._groupedQueryList
  }

  private _queryListChanged = new Subject<IQueryGroup[]>()
  get queryListChanged () {
    return this._queryListChanged.asObservable()
  }

  private _queryList: IQueryWRef[] = []
  get queries (): IQueryWRef[] {
    return this._queryList
  }

  editingChanged = new Subject<boolean>()
  editing = false

  private _filterChange = new Subject<AppLayer>()
  get filterChange () {
    return this._filterChange.asObservable()
  }

  private get _queriesApi () {
    return this._workspaceService.viewOrm.Queries()
  }

  constructor (
    private _layerService: LayerService | WidgetLayerService,
    private _workspaceService: WorkspaceService,
    private _api: VipApiService,
    private _alertService: AlertService,
    private _promptService: PromptService
  ) {
    this._workspaceService.onExit.subscribe(() => this.CleanupService())
  }

  private CleanupService () {
    this._destroyDialogs()

    this._subscriptions.unsubscribe()
    this._subscriptions = new Subscription()

    this.queryableLayers = []
    this._queryList = []
  }

  updateQueries () {
    this.GetQueries()
  }

  private async GetQueries () {
    const queries = await this._queriesApi.get().run() || []

    // Match every query with a layer
    this._queryList = queries.map((x, i) => ({
      ...x,
      targetRef: x.layer_group_id ? this._layerService.groups.find(y => y.id === x.layer_group_id) :
        this._layerService.allLayers.find(y => y.id === x.layer_id),
      index: i,
      geometry: x.geometry ?
        OlUtil.wktToFeature(x.geometry).getGeometry() as olGeom.Polygon | olGeom.MultiPolygon :
        undefined
    } as IQueryWRef))
    // Discard queries without any layer reference
    .filter(i => !!i.targetRef)

    this.OnQueryArrayChange()
    this.ApplyActiveQueries()
  }

  private OnQueryArrayChange () {
    // Recalculate/regroup layer query groups
    const layerGroups: IQueryGroup[] = []
    for (const query of this.queries) {
      let group = layerGroups.find(x => x.id === query.targetRef.id)

      if (!group) {
        group = {
          id: query.targetRef.id,
          name: query.targetRef.title,
          queries: []
        }

        layerGroups.push(group)
      }

      group.queries.push(query)
    }
    this._groupedQueryList = layerGroups
    // Emit event for query array change
    this._queryListChanged.next(this._groupedQueryList)
  }

  private async ApplyActiveQueries (type?: 'style' | 'filter' | 'spatial', target?: AppLayerGeneric) {
    for (const query of this._queryList) {
      if (!query.applied || (type && type !== query.type) || (target && query.targetRef !== target)) continue
      this.toggleQuery(query, true)
    }
  }

  async toggleQuery (queryObj: IQueryWRef, active: boolean, forceSave = false) {
    if (active) this.ParseQueryNumbers(queryObj)
    const save = queryObj.applied !== active || !queryObj.query_id || forceSave
    queryObj.applied = active

    if (save) {
      queryObj = await this.saveQuery(queryObj)
      if (queryObj.type === 'spatial') await queryObj.targetRef.reload()
    }

    if (queryObj.type === 'style') {
      await this.ToggleStyleQuery(queryObj, active)
    } else if (queryObj.type === 'filter') {
      await this.ToggleFilterQuery(queryObj, active)
    } else if (queryObj.type === 'spatial') {
      this.ToggleSpatialQuery(queryObj)
    } else if (queryObj.type === 'enhanced') {
      this.ToggleAdvancedQuery(queryObj)
    }
  }

  private UpdateLayerFilteredFlag (layer: AppLayer | AppLayerGroup) {
    layer.filtered = this._queryList.some(q =>
      ['spatial', 'filter'].includes(q.type) && q.targetRef === layer && q.applied
    )
  }

  private ToggleSpatialQuery (queryObj: IQueryWRef) {
    const ref = queryObj.targetRef
    if (!ref) return
    this.UpdateLayerFilteredFlag(ref)
  }

  private ToggleAdvancedQuery (queryObj: IQueryWRef) {
    const ref = queryObj.targetRef
    if (!ref) return
    this.UpdateLayerFilteredFlag(ref)
    const layers = this.GetLayersFromGeneric(queryObj.targetRef)

    const triggerVectorsUpdate = () => {
      for (const layer of layers) {
        if (this._layerService.selected) {
          if (this._layerService.selected.id === layer.id) this._filterChange.next(layer)
        }
        const vector = layer.layer as VectorLayer<any>
        // TODO: This might now remove features refresh()
        if (vector) vector.getSource().changed()
      }
    }
    triggerVectorsUpdate()

  }

  private async ToggleFilterQuery (queryObj: IQueryWRef, active: boolean) {
    const ref = queryObj.targetRef
    if (!ref) return
    this.UpdateLayerFilteredFlag(ref)
    if (ref instanceof AppLayer && !ref.layer) return

    const removeQueryOn = (features: Feature[]) => {
      for (const f of features) {
        const props = f.getProperties() as IFeatureSystemProperties
        let sysProps = props._system
        if (!sysProps) sysProps = { filteredOutBy: [] }
        if (!sysProps.filteredOutBy) sysProps.filteredOutBy = []
        const index = sysProps.filteredOutBy.indexOf(queryObj.query_id)
        if (index >= 0) {
          sysProps.filteredOutBy.splice(index, 1)
          f.set('_system', sysProps)
        }
      }
    }

    const layers = this.GetLayersFromGeneric(queryObj.targetRef)
    const features = queryObj.targetRef.features

    const triggerVectorsUpdate = () => {
      for (const layer of layers) {
        if (this._layerService.selected) {
          if (this._layerService.selected.id === layer.id) this._filterChange.next(layer)
        }
        const vector = layer.layer as VectorLayer<any>
        // TODO: This might now remove features refresh()
        vector.getSource().changed()
      }
    }
    if (!active) {
      removeQueryOn(features)
      triggerVectorsUpdate()
      return
    }

    const { passing, failing } = this.getFilteredFeatures(queryObj, features)

    removeQueryOn(passing)

    for (const f of failing) {
      const props = f.getProperties() as IFeatureSystemProperties
      let sysProps = props._system
      if (!sysProps) sysProps = { filteredOutBy: [] }
      if (!sysProps.filteredOutBy) sysProps.filteredOutBy = []

      const index = sysProps.filteredOutBy.indexOf(queryObj.query_id)
      if (index < 0) {
        sysProps.filteredOutBy.push(queryObj.query_id)
      }

      f.set('_system', sysProps)
    }

    triggerVectorsUpdate()
  }

  private GetLayersFromGeneric (source: AppLayerGeneric) {
    return source instanceof AppLayer ? [source] : source.timeSeriesLayers
  }

  private async ToggleStyleQuery (queryObj: IQueryWRef, active: boolean) {
    const ref = queryObj.targetRef
    if (!ref || (ref instanceof AppLayer && !ref.layer)) return

    const layers = this.GetLayersFromGeneric(queryObj.targetRef)

    const removeStyleOn = (features: Feature[]) => {
      for (const f of features) {
        const props = f.getProperties() as IFeatureSystemProperties
        if (!props.customColors) {
          props.customColors = {
            fill: [],
            border: []
          }
        }

        for (const key in props.customColors) {
          const styleWithId: undefined | IFeatureCustomColor = props.customColors[key].find(
            (cC: any) => cC.id === queryObj.query_id
          )

          if (styleWithId) styleWithId.active = false
        }

      }
      for (const l of layers) l.syncStyle(true, true)
    }
    const features = queryObj.targetRef.features

    if (!active) {
      removeStyleOn(features)
      return
    }

    const { passing, failing } = this.getFilteredFeatures(queryObj, features)

    removeStyleOn(failing)

    for (const key in queryObj.query.customColor) {
      const gradient = queryObj.query.customColor[key].gradient
      const color = queryObj.query.customColor[key].color

      if (gradient && gradient.active) {
        try {
          for (const l of layers) {
            l.applyGradient(
              gradient.column,
              gradient.steps,
              key as 'fill' | 'border',
              passing,
              // TODO: Check if this is possibly undefined
              queryObj.query_id,
              queryObj.index,
              queryObj.query.customColor[key].opacity,
              gradient.range
            )
          }
        } catch (error: any) {
          handleError(error)
          this._alertService.log(error.message)
          return
        }

      } else if (color && color.active) {
        for (const f of passing) {
          const properties = f.getProperties()
          if (!properties.customColors) {
            properties.customColors = {
              fill: [],
              border: []
            }
          }

          const styleWithId: IFeatureCustomColor | undefined = properties.customColors[key].find(
            (cC: any) => cC.id === queryObj.query_id
          )

          if (!styleWithId) {
            (properties.customColors[key] as IFeatureCustomColor[]).push({
              id: queryObj.query_id,
              color: color.value,
              active: true,
              order: queryObj.index
            })
            properties.customColors[key] = properties.customColors[key].sort((a, b) => a.order > b.order ? 1 : -1)
          } else {
            styleWithId.active = true
            styleWithId.color = color.value
          }
          f.set('customColors', properties.customColors)
        }

        for (const l of layers) l.syncStyle(true, true)
      }
    }
  }

  getFilteredFeatures (queryObj: IQueryWRef, features?: Feature[]) {
    const passing: Feature[] = []
    const failing: Feature[] = []

    if (!features) features = queryObj.targetRef.features
    for (const f of features) {
      let pass = true
      if (queryObj.type === 'spatial') {
        pass = this.FeatureInsideGeometry(queryObj.geometry, f, !!queryObj.query.includeEdge)
      } else {
        pass = this.FeaturePassesQuery('block', queryObj.query.operator, queryObj.query.blocks, f)
      }

      if (pass) passing.push(f)
      else failing.push(f)
    }

    return { passing, failing }
  }

  private FeatureInsideGeometry (geometry: olGeom.MultiPolygon | olGeom.Polygon, f: Feature, edgeInclude: boolean) {
    const boundary = turf.geometry(geometry.getType(), geometry.getCoordinates()) as turf.Polygon | turf.MultiPolygon

    const polygons = boundary.type === 'Polygon' ? [boundary] : boundary.coordinates.map(pol => turf.geometry('Polygon', pol))
    const featureGeom = f.getGeometry()
    if (!featureGeom) return false

    const overlaps = (
      pol: turf.Polygon,
      geom: olGeom.Point | olGeom.LineString | olGeom.Polygon
    ) => {
      const geojsonGeom: any = turf.geometry(geom.getType(), geom.getCoordinates())
      let pass = turf.booleanContains(pol, geojsonGeom)
      if (!pass && edgeInclude) {
        if (geojsonGeom.type.includes('Polygon')) {
          pass = turf.booleanOverlap(pol, geojsonGeom)

        } else if (geojsonGeom.type.includes('Line')) {
          // For some reason line overlap/cross do not work as expected,
          // therefore try to create polygon out of line
          let lineToPol: turf.Polygon | turf.MultiPolygon
          const lineCoordsToPol = (coords: [number, number][]) => (turf.lineToPolygon(
            turf.lineString([...coords, ...([...coords].reverse())])
          ).geometry as turf.Polygon)

          if (geojsonGeom.type === 'MultiLineString') {
            console.warn(`Spatial Query on MultiLineString detected. It has not been properly tested and can possibly break.`)
            lineToPol = turf.multiPolygon(geojsonGeom.coordinates.map(coords =>
              lineCoordsToPol(coords).coordinates
            )).geometry as turf.MultiPolygon

          } else {
            lineToPol = lineCoordsToPol(geojsonGeom.coordinates)
          }

          pass = turf.booleanOverlap(pol, lineToPol)

        } else if (geojsonGeom.type.includes('Point')) {
          const line = turf.polygonToLineString(pol).geometry as turf.LineString
          pass = turf.booleanPointOnLine(geojsonGeom, line)
        }

      }
      return pass
    }

    const oneGeomOverlaps = (pol: turf.Polygon, geom: olGeom.Geometry) => {
      if (geom instanceof GeometryCollection) {
        return geom.getGeometries().some(g => oneGeomOverlaps(pol, g))
      } else if (geom instanceof olGeom.MultiPolygon) {
        return geom.getPolygons().some(g => oneGeomOverlaps(pol, g))
      } else if (geom instanceof olGeom.MultiLineString) {
        return geom.getLineStrings().some(g => oneGeomOverlaps(pol, g))
      } else if (geom instanceof olGeom.MultiPoint) {
        return geom.getPoints().some(g => oneGeomOverlaps(pol, g))
      }

      return overlaps(pol, geom as any)
    }

    return polygons.some(pol => oneGeomOverlaps(pol, featureGeom))
  }

  private FeaturePassesQuery (level: 'block' | 'statement', operator: 'AND' | 'OR' | string, items: any[], feature: Feature) {
    if (items.length === 0) return true
    return items[operator === 'OR' ? 'some' : 'every'](item => {
      if (level === 'block') {
        return this.FeaturePassesQuery('statement', item.operator, item.statements, feature)
      } else {
        const featureAttr = this.GetAttributeFromFeature(
          feature,
          item.attribute
        )
        return this.Comparator(
          item.operator,
          featureAttr,
          item.value
        )
      }
    })
  }

  private GetAttributeFromFeature (feature: Feature, attribute: string) {
    if (attribute) {
      return feature.getProperties()[attribute]
    }
  }

  private Comparator (operator: string, toCompare: any, compareTo: any) {
    let returnVal: any
    compareTo = (operator === 'equal' || operator === 'not') && typeof toCompare === 'boolean' ?
    compareTo === 'true' ? true : false
    : compareTo
    switch (operator) {
      /* eslint-disable eqeqeq */
      case 'equal': {
        returnVal = toCompare == compareTo
        break
      }
      case 'greater': {
        returnVal = toCompare > compareTo
        break
      }
      case 'less': {
        returnVal = toCompare < compareTo
        break
      }
      case 'not': {
        returnVal = !(toCompare == compareTo)
        break
      }
      case 'notEmpty': {
        returnVal = toCompare !== undefined && toCompare !== null
        break
      }
      /* eslint-enable */
    }

    return returnVal
  }

  // This is a quick fix to initial implementation where strings were compared,
  // so "'2' > '19'" was true, which if using numbers will result in false like it should.
  // NOTE: If there is time, query builder logic should be refactored.
  private ParseQueryNumbers (query: IQueryWRef) {
    for (const block of query.query.blocks) {
      for (const s of block.statements) {
        if (s.value !== '' && !isNaN(+s.value)) {
          s.value = +s.value
        }
      }
    }
  }

  async saveQuery (query: IQueryWRef) {
    this.ParseQueryNumbers(query)

    let queryEntry: IQueryWRef | undefined = this._queryList.find(x => x === query)
    if (!queryEntry) {
      const body: IPNewQuery = {
        name: query.name,
        applied: !!query.applied,
        query: query.query,
        type: query.type,
        geometry: query.geometry ? OlUtil.featureToWkt(query.geometry) : undefined
      } as any
      if (query.layer_id) body.layer_id = query.layer_id
      else if (query.layer_group_id) body.layer_group_id = query.layer_group_id

      const res = await this._queriesApi.create(body).run()

      queryEntry = {
        ...res,
        geometry: res.geometry ?
          OlUtil.wktToFeature(res.geometry).getGeometry() as olGeom.Polygon | olGeom.MultiPolygon :
          undefined,
        targetRef: query.targetRef,
        index: this._queryList.length
      }

      this._queryList.push(queryEntry)
    } else {
      await this._queriesApi.Query(query.query_id).update({
        name: query.name,
        applied: !!query.applied,
        query: query.query,
        geometry: query.geometry ? OlUtil.featureToWkt(query.geometry) : undefined
      }).run()
    }
    // TODO: Separate array change and single query change to two events?
    this.OnQueryArrayChange()
    return queryEntry
  }

}

applyMixins(WidgetQueryLayerService, [DialogCleanup])
