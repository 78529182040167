import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core'
import { UntypedFormGroup, Validators } from '@angular/forms'
import { Db } from '@vip-shared/models/db-definitions'
import { AlertService, VipApiService } from '@services/core'
import { handleError } from '@core/models/app-error'
import { Subscription, merge } from 'rxjs'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { auditTime, tap } from 'rxjs/operators'
import { WorkspaceService } from '@services/workspace'

type Form = TypedFormGroup<{
  presetName: TypedFormControl<string>
  filters: UntypedFormGroup
  mode?: TypedFormControl<Db.Vip.LayerMode>
  datasetsQuery: TypedFormControl<any>,
  datasetSelection: UntypedFormGroup,
  datetimeSelection: UntypedFormGroup
}>

interface GeoServerLayer {
  name: string
  href: string
}

interface IWorkspaceNames {
  workspaceName: string
  workspaceDisplayName: string
}

interface GeoServerLayers {
  layers: {
    layer: GeoServerLayer[]
  }
}
@Component({
  selector: 'app-raster-preset-config',
  templateUrl: './raster-preset-config.component.html',
  styleUrls: ['./raster-preset-config.component.scss']
})
export class RasterPresetConfigComponent implements OnInit {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs(): any { return }

  private _subscriptions = new Subscription()

  formControlKeys = ['presetName', 'filters', 'mode', 'datasetsQuery', 'datasetSelection', 'scraperForm']

  @Output() formChange = new EventEmitter<Form>()
  @Output() selectedPreset = new EventEmitter<Db.Vip.Geo.ILayerPreset>()
  @Output() selectedWorkspace = new EventEmitter<string>()
  @Output() selectedLayer = new EventEmitter<string>()
  @Input() productId!: number
  @Input() allowModes = true
  @Input() onlyPresets: Db.Vip.LayerPreset[] = []

  form: Form
  datasetSelectionForm: UntypedFormGroup
  preset = Db.Vip.LayerPreset
  presetName: string = ''
  fetchingData = false
  layerPresets: Db.Vip.Geo.ILayerPreset[] = []
  selectedQuery?: any
  presets: Db.Vip.Geo.ILayerPreset[] = []
  workSpaces: IWorkspaceNames[] = []
  workspace?: string
  staticPreset: boolean = false
  layers: string[] = []

  constructor(
    private _alertService: AlertService,
    private _api: VipApiService,
    private _workspaceService: WorkspaceService,
  ) {
    this.form = new TypedFormGroup({
      workspaceName: new TypedFormControl<string>(undefined, [Validators.required]),
      presetName: new TypedFormControl<string>(undefined, [Validators.required]),
      layerName: new TypedFormControl<string>(undefined, [Validators.required]),
      filters: new UntypedFormGroup({}),
      datasetsQuery: new TypedFormControl(undefined),
      datasetSelection: new UntypedFormGroup({}),
      datetimeSelection: new UntypedFormGroup({})
    })

    this.datasetSelectionForm = this.form._controls.datasetSelection

    this._subscriptions.add(
      merge(
        this.form.valueChanges,
        this.datasetSelectionForm.valueChanges
      ).pipe(auditTime(1000)).subscribe(() => {
        // this.formChange.next(this.form)
      })
    )
  }

  ngOnInit() {
    if (this.allowModes) {
      this.form.addControl('mode', new TypedFormControl<Db.Vip.LayerMode>(
        Db.Vip.LayerMode.READ_ONLY,
        Validators.required
      ))
    }
    this.formChange.next(this.form)
    this.GetWorkspaces()
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
    this._destroyDialogs()
  }

  placeholder(title: string, items: number) {
    return `${title}${items ? '' : ' (None Available)'}`
  }

  private async GetWorkspaces(): Promise<(void)> {
    try {
      this.presets = await this._api.orm.Products().Product(this.productId)
        .LayerPresets().get().run()
      if (!this.presets.length) throw new Error('No Emission data for Product.')
      const workspaceNames: IWorkspaceNames[] = []
      for (const p of this.presets) {
        if (p.raster_meta && p.raster_meta.workspaces) {
          const enabledWorkspaces = p.raster_meta.workspaces.filter(item => ((p.workspaces as string[]) || []).includes(item))
          if (enabledWorkspaces.length) {
            if (p.layer_preset_tag.includes('static')) {
              let wkSpaceNames = {} as IWorkspaceNames
              wkSpaceNames.workspaceDisplayName = `${p.display_name}`
              wkSpaceNames.workspaceName = enabledWorkspaces[0]
              workspaceNames.push(wkSpaceNames)
            } else {
              for (const wk of enabledWorkspaces) {
                let wkSpaceNames = {} as IWorkspaceNames
                wkSpaceNames.workspaceDisplayName = `${wk} Hourly data`
                wkSpaceNames.workspaceName = wk
                workspaceNames.push(wkSpaceNames)
              }
            }
          }
        }
      }
      const workspaces = [...new Map(workspaceNames.map(item =>
        [item.workspaceName, item])).values()].sort()
      this.workSpaces = workspaces.length ? workspaces : []
      this.formChange.next(this.form)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  private LoadPresets() {
    this.layers = []
    this.fetchingData = true
    if (!this.workspace) return
      try {
        this.layerPresets = (this.onlyPresets && this.onlyPresets.length) ?
          this.presets.filter(p => this.onlyPresets.includes(p.layer_preset_tag as any) && p.raster_meta?.workspaces.find(w => w === this.workspace)) :
          this.presets

        if (this.layerPresets.length === 1) {
          this.form._controls.presetName.setValue(this.layerPresets[0].layer_preset_tag)
          this.form._controls.presetName.disable()
          this.presetChange(this.layerPresets[0].layer_preset_tag as Db.Vip.LayerPreset)
        }
      } catch (error: any) {
        handleError(error)
        this._alertService.log(error.message)
      }
    this.fetchingData = false
  }

  protected async getLayers() {
    if (this.presetName) {
      const presetSource = this.layerPresets.find(p => p.layer_preset_tag === this.presetName)
      const baseUrl = presetSource && presetSource.raster_meta ? presetSource.raster_meta.base_url : undefined
      if (baseUrl && this.workspace) {
        try {
          const getLayersUrl = `${baseUrl}/rest/workspaces/${this.workspace}/layers.json`
          const geoServerlayers: GeoServerLayers = await this._api.orm.ProxyEmissions(
            this._workspaceService.proxyAppendWsQuery(
              Base64.encodeURI(this._api.appendJwtQuery(`${getLayersUrl}`))
            )
          ).get().run()
          if (geoServerlayers && geoServerlayers.layers) {
            this.layers = geoServerlayers.layers.layer.map(l => l.name)
          } else {
            throw new Error('No Layers available in workspace')
          }
        } catch (error: any) {
          handleError(error)
          this._alertService.log(error.message)
        }
      }
    }
  }

  async workspaceChange(workspace: string) {
    this.resetFormValues()
    this.workspace = workspace
    this.selectedWorkspace.next(this.workspace)
    this.form.controls['workspaceName'].setValue(workspace)
    this.LoadPresets()
  }

  resetFormValues() {
    this.staticPreset = false
    this.form.controls['workspaceName'].reset()
    this.form.controls['presetName'].reset()
    this.form.controls['presetName'].enable()
    this.form.controls['layerName'].reset()
    this.formChange.next(this.form)
  }

  async layerChange(layer: string) {
    this.formChange.next(this.form)
    this.selectedLayer.next(layer)
  }

  async presetChange(presetTag: Db.Vip.LayerPreset) {
    this.staticPreset = false
    this.presetName = presetTag
    const selectedPreset = this.layerPresets.find(p => p.layer_preset_tag === presetTag)
    this.selectedPreset.next(selectedPreset as Db.Vip.Geo.ILayerPreset)
    const triggerLoad = !this.fetchingData
    if (triggerLoad) this.fetchingData = true
    try {
      for (const key in this.datasetSelectionForm.controls) {
        this.datasetSelectionForm.removeControl(key)
      }
      if (this.presetName.includes('static')) {
        this.staticPreset = true
        await this.getLayers()
      } else {
        this.formChange.next(this.form)
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    if (triggerLoad) this.fetchingData = false
  }
}

applyMixins(RasterPresetConfigComponent, [DialogCleanup])
