import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Db } from '@vip-shared/models/db-definitions'
import { AppScraperLayer } from '@core/models/layer/app-scraper-layer'
import AppError, { handleError } from '@core/models/app-error'
import { AlertService } from '@services/core'
import FormValidators from '@core/utils/form-validators/form-validators'
import { UntypedFormGroup, Validators } from '@angular/forms'
import { WorkspaceService } from '@services/workspace'
import { TypedFormControl, TypedFormGroup } from '@core/models/typed-form-control'
import * as moment from 'moment'
import { API } from '@vip-shared/interfaces/api-helper'
import { IPNewScraperConf, IPScraperConf } from '@vip-shared/interfaces'
import { ScraperDateRange, ScraperForm } from '@core/types/forms'
import { ScraperPresets } from '@vip-shared/models/const/scraper-presets'

interface DialogData {
  conf?: API.Res.ScraperConf
  layer: AppScraperLayer
}

@Component({
  selector: 'app-scraper-dialog',
  templateUrl: './scraper-dialog.component.html',
  styleUrls: ['./scraper-dialog.component.scss']
})
export class ScraperDialogComponent {
  private _saving = false

  readonly applyFormConfig = FormValidators.cloneFormConfig

  static setOptions (data: DialogData) {
    const options: MatDialogConfig = {
      hasBackdrop: true,
      autoFocus: false,
      data
    }
    return options
  }

  form = new UntypedFormGroup({})
  productId: Db.Vip.Product
  readonly scraperPresets = ScraperPresets
  scraperForm: ScraperForm = new TypedFormGroup({
    name: new TypedFormControl<string>(undefined, [
      Validators.required
    ]),
    where_exact: new TypedFormControl<string[]>(undefined),
    where_has: new TypedFormControl<string[]>(undefined),
    where_exclude: new TypedFormControl<string[]>(undefined),
    locations: new TypedFormControl<Db.Helper.Ext.Location[]>(undefined, [
      Validators.required,
      FormValidators.minLength(1)
    ]),
    dateRanges: new TypedFormControl<{
      from: moment.Moment
      to: moment.Moment
    }[]>(undefined, [
      Validators.required,
      FormValidators.minLength(1)
    ])
  }, [
    FormValidators.atLeastOneOf(['where_has', 'where_exact'])
  ])

  get valid () {
    return this.form.valid && this.scraperForm.valid
  }

  constructor (
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private _dialogRef: MatDialogRef<ScraperDialogComponent, any>,
    private _alertService: AlertService,
    private _workspaceService: WorkspaceService
  ) {
    if (!this._workspaceService.product) {
      this._alertService.log('Internal client error. Workspace configuration is missing.')
      this._dialogRef.close()
    }
    this.productId = this._workspaceService.product as Db.Vip.Product

    if (data.conf) {
      this.scraperForm.controls.name.setValue(data.conf.name)
      this.scraperForm.controls.where_exact.setValue(data.conf.where_exact)
      this.scraperForm.controls.where_has.setValue(data.conf.where_has)
      this.scraperForm.controls.where_exclude.setValue(data.conf.where_exclude)
      for (const entry of data.conf.locations) {
        for (const key in entry) {
          // API return null values from database, but API optional parameters
          // only accept 'undefined', null will cause a joi validation error
          if (entry[key] === null) entry[key] = undefined
        }
      }
      this.scraperForm.controls.locations.setValue(data.conf.locations)
      this.scraperForm.controls.dateRanges.setValue(data.conf.date_ranges.map(r => ({
        from: moment(r.from),
        to: moment(r.to)
      })))

      if (data.conf.interval) {
        this.scraperForm.addControl(
          'intervalValue', new TypedFormControl<number>(data.conf.interval_value, Validators.required)
        )
        this.scraperForm.addControl(
          'interval', new TypedFormControl<Db.Helper.Geo.TimeInterval>(
            data.conf.interval as Db.Helper.Geo.TimeInterval, Validators.required
          )
        )
      }
    }
  }

  async save () {
    if (this._saving) return

    this._saving = true

    try {
      if (!this.form.valid || !this.scraperForm.valid) throw new AppError('Form invalid.')
      const dateRanges = this.scraperForm._controls.dateRanges.value as ScraperDateRange[]
      const interval = this.scraperForm._controls.interval && this.scraperForm._controls.interval.value
        const intervalValue = this.scraperForm._controls.intervalValue && this.scraperForm._controls.intervalValue.value
        const reducedIntervalValue = this.form.controls.presetName.value === Db.Vip.LayerPreset.TWITTER &&
        interval === 'minute' && intervalValue && intervalValue < 120 ? 120 : intervalValue
      if (this.data.conf) {
        const conf: IPScraperConf = {
          name: this.scraperForm._controls.name.value as string,
          interval: (interval && intervalValue) ? {
            value: reducedIntervalValue as number,
            interval: interval as Db.Helper.Geo.TimeInterval
          }: undefined,
          dateRanges: dateRanges.map(r => ({
            from: r.from.toISOString(),
            to: r.to.toISOString()
          })),
          locations: this.scraperForm._controls.locations.value as Db.Helper.Ext.Location[],
          where_exact: this.scraperForm.controls.where_exact.value || undefined,
          where_exclude: this.scraperForm.controls.where_exclude.value || undefined,
          where_has: this.scraperForm.controls.where_has.value || undefined
        }
        await this.data.layer.updateScraper(this.data.conf.scraper_conf_id, conf)
      } else {
        const conf: IPNewScraperConf = {
          name: this.scraperForm._controls.name.value as string,
          interval: {
            value: reducedIntervalValue as number,
            interval: interval as Db.Helper.Geo.TimeInterval
          },
          dateRanges: dateRanges.map(r => ({
            from: r.from.toISOString(),
            to: r.to.toISOString()
          })),
          locations: this.scraperForm._controls.locations.value as Db.Helper.Ext.Location[],
          where_exact: this.scraperForm._controls.where_exact.value || undefined,
          where_exclude: this.scraperForm._controls.where_exclude.value || undefined,
          where_has: this.scraperForm._controls.where_has.value || undefined
        }

        await this.data.layer.createScraper(conf)
      }
      this._dialogRef.close()
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }

    this._saving = false
  }

}
