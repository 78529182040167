import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Subject, BehaviorSubject, Subscription, Observable, fromEvent, merge } from 'rxjs'
import { ConvertUtil, CommonUtil } from '@core/utils/index'

import { AppLayerGroup, AppLayerGeneric } from '@core/models/layer'
import { Db } from '@vip-shared/models/db-definitions'
import * as olExtent from 'ol/extent'
import * as olProj from 'ol/proj'
import { PromptService } from '@services/core/prompt/prompt.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AlertService } from '@services/core/alert/alert.service'
import { WorkspaceService } from '../workspace.service'
import { ViewerService } from '../viewer/viewer.service'
import { WorkspacesService } from '@services/explorer'
import { IPNewLayer, IPNewTimeSeriesSource, IPNumEntriesCheck, IPUpdateLayer } from '@vip-shared/interfaces/api/api-body-types'
import { IRWorkspaceLayer, IRWorkspaceMetadata } from '@vip-shared/interfaces/api/api-payloads'
import { handleError } from '@core/models/app-error'
import { AppLayer } from '@core/models/layer/app-layer'
import { AppDroneLayer } from '@core/models/layer/app-drone-layer'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { LayerUploadStateService } from './upload/layer-upload-state.service'
import { distinctUntilChanged, skip, auditTime } from 'rxjs/operators'
import { DocumentService } from '@services/core/document/document.service'
import { IEurotempestUploadOptions, ISepaFloodAreasUploadOptions } from '@vip-shared/models/fred'
import { MomentPipe } from '@core/pipes/moment.pipe'
import { AppScraperLayer } from '@core/models/layer/app-scraper-layer'
import { ScraperPresets } from '@vip-shared/models/const/scraper-presets'
import OlUtil from '@core/utils/ol/ol.util'
import * as olGeom from 'ol/geom'
import { IQueryWRef } from '@core/models/query-object'
import { clientDataThreshold } from '@vip-shared/models/const/data-limits'
import moment from 'moment'
import { IJouneyProp, IVesselJson } from '@vip-shared/interfaces/emissions/IJsonVessel'
import { v4 as uuidv4 } from 'uuid'
import { IJson } from '@vip-shared/interfaces'

@Injectable({
  providedIn: 'root'
})
export class LayerService implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef < any > []
  private _queryList: IQueryWRef[] = []
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  private _subscriptions = new Subscription()

  private _totalLayerCount = 0

  private _reloadWorkspaceId?: number
  private _originalLayerCount?: number

  private _previousSelected?: AppLayer
  private _layerSelectionChange = new BehaviorSubject<AppLayer | undefined>(undefined)
  get layerSelectionChange () {
    return this._layerSelectionChange.pipe(
      skip(1),
      auditTime(100),
      distinctUntilChanged((a, b) => a === b)
    )
  }

  private _layerVisibilityChange = new Subject<AppLayer>()
  get layerVisibilityChange () {
    return this._layerVisibilityChange.asObservable()
  }

  focusedLayer?: AppLayer
  private _layerFocusedChange = new BehaviorSubject<AppLayer | undefined>(undefined)
  get layerFocusedChange () {
    return this._layerFocusedChange.pipe(
      skip(1),
      auditTime(100),
      distinctUntilChanged((a, b) => a === b)
    )
  }

  private _groupSelectionChange = new BehaviorSubject<AppLayerGroup | undefined>(undefined)
  get groupSelectionChange () {
    return this._groupSelectionChange.pipe(
      skip(1),
      auditTime(100),
      distinctUntilChanged((a, b) => a === b)
    )
  }

  private _newLayer = new Subject<AppLayer>()
  get newLayer () {
    return this._newLayer.asObservable()
  }

  private _groupAdded = new Subject<AppLayerGroup>()
  get groupAdded () {
    return this._groupAdded.asObservable()
  }

  private _layerArrayChanged = new BehaviorSubject<AppLayerGeneric[]>([])
  get layerArrayChanged () {
    return this._layerArrayChanged.asObservable()
  }

  private _layerRemoved = new Subject<AppLayer>()
  get layerRemoved () {
    return this._layerRemoved.asObservable()
  }

  private _appLayerElements: AppLayerGeneric[] = []
  get appLayerElements () {
    return this._appLayerElements
  }

  private _extentLoaded = new Subject<[number, number, number, number]>()
  get extentLoaded () {
    return this._extentLoaded.asObservable()
  }

  private _totalLayerCountSubject = new BehaviorSubject<number>(this._totalLayerCount)
  get totalLayerCount (): Observable<number> {
    return this._totalLayerCountSubject.asObservable()
  }

  get allLayers (): AppLayer[] {
    return [...this._appLayerElements].reduce((all, el) => {
      if (el instanceof AppLayer) all.push(el)
      else all.push(...el.layers)
      return all
    }, [] as AppLayer[])
  }

  get groups (): AppLayerGroup[] {
    return [...this._appLayerElements].filter(el => el instanceof AppLayerGroup) as AppLayerGroup[]
  }

  get selected (): AppLayer | undefined {
    return this.allLayers.find(l => l.selected)
  }

  get hasPolygons (): boolean {
    return this.allLayers.some(l =>
      l.isPropertyView || l.geometryTypes.some(t =>
        t.toLowerCase().includes('polygon')
      )
    )
  }

  get allQueries (): IQueryWRef[] {
    return this._queryList
  }

  constructor (
    private _api: VipApiService,
    private _workspaceService: WorkspaceService,
    private _alertService: AlertService,
    private _dialog: MatDialog,
    private _layerUploadStateService: LayerUploadStateService,
    private _promptService: PromptService,
    private _documentService: DocumentService,
    private _workspacesService: WorkspacesService,
    private _momentPipe: MomentPipe
  ) {

    this._workspaceService.workspaceLoaded.subscribe((workspaceLoaded) => {
      if (!workspaceLoaded || !this._workspaceService.selectedWorkspace) return
      this._originalLayerCount = +this._workspaceService.selectedWorkspace.layer_count
      this.getQueries()
    })

    this._subscriptions.add(
      merge(
        this._layerRemoved,
        this._newLayer
      ).subscribe(() => {
        if (!this._workspaceService.selectedWorkspace) return
        if (this._reloadWorkspaceId) return
        if (this._originalLayerCount !== this.allLayers.length) this._reloadWorkspaceId = this._workspaceService.workspaceId
      }))

    this._layerUploadStateService.layerUploaded.subscribe(sourceId => {
      if (this._appLayerElements.findIndex(x => x.id === sourceId) < 0) {
        this.FetchSource(sourceId)
      }
    })

    this._workspaceService.onExit.subscribe(() => {
      this.updateWorkspaceExtent()
      this._destroyDialogs()
      this.clearLayersCollection()
      this.ClearQueryList()
    })

    this._layerSelectionChange.subscribe(selected => {
      if (this._previousSelected && this._previousSelected !== selected) {
        this._previousSelected.selected = false
      }
      this._previousSelected = selected
    })

    let keyIsUp = false
    fromEvent(window, 'keydown')
    .pipe().subscribe(e => {
      if (keyIsUp) return
      keyIsUp = true
      if (this._documentService.attributeTableFocused) return
      if (CommonUtil.isKey(e as KeyboardEvent, 'ctrl')) {
        const selected = this.selected
        if (selected) selected.focused = true
      }
    })

    fromEvent(window, 'keyup')
    .pipe().subscribe(e => {
      if (!keyIsUp) return
      keyIsUp = false
      const selected = this.selected
      if (selected && selected.focused) selected.focused = false
    })

    let focusLost: Subscription | undefined
    this.layerFocusedChange.subscribe(l => {
      if (focusLost) {
        focusLost.unsubscribe()
        focusLost = undefined
        return
      }

      if (!l) return
      this.focusedLayer = l
      focusLost = l.focusedChange.subscribe(focused => {
        if (focused || this.focusedLayer !== l) return
        this.focusedLayer = undefined
        if (focusLost) {
          focusLost.unsubscribe()
          focusLost = undefined
        }
      })
    })
  }

  private UpdateLayerCount (count: number) {
    this._totalLayerCount = count
    this._totalLayerCountSubject.next(this._totalLayerCount)
  }

  async getById (sourceId: string): Promise<AppLayer | undefined> {
    let layer = this.allLayers.find(x => x.id === sourceId)
    if (!layer) layer = await this.loadSource(sourceId)
    return layer
  }

  getSelected (): AppLayer | undefined {
    return this.allLayers.find(x => x.selected)
  }

  async getSourceTypes (): Promise<Db.Vip.Geo.ISourceType[]> {
    return this._api.orm.Layers().getTypes().run()
  }

  clearLayersCollection () {
    for (const layer of this.allLayers) {
      layer.destroy()
      this._layerRemoved.next(layer)
    }
    this._appLayerElements.splice(0)
    this._layerArrayChanged.next(this._appLayerElements)
    this.UpdateLayerCount(0)
  }

  async uploadSource (source: IPNewLayer, files?: File[]) {
    let uploaded = ''
    const uploadState = this._layerUploadStateService.monitorWorkspaceSource(
      {
        workspaceId: source.workspace_id,
        productId: source.product_id
      },
      source.name,
      () => uploaded
    )

    try {
      const newId = await this._api.orm.Layers().upload(source, files).run({
        progressCb: (e) => {
          if (!e.total) return
          uploaded = e.loaded > e.total * 0.99 ? '100%' : `${Math.round(e.loaded * 100 / e.total)}%`
        }
      })
      if (newId && +newId >= 0) {
        this._layerUploadStateService.localUploads.push({
          upload: uploadState,
          id: newId
        })
      }
    } catch (error: any) {
      uploadState.error = typeof error.message === 'string' ? error.message : 'Upload interrupted or unknown failure.'
      uploadState.seen = false
      this._layerUploadStateService.fetchUploadStates()
    }
  }

  async uploadTimeseriesSources (group: IPNewTimeSeriesSource, files: File[]) {
    let uploaded = ''
    const uploadState = this._layerUploadStateService.monitorWorkspaceSource(
      {
        workspaceId: group.workspace_id,
        productId: group.product_id
      },
      group.group_name,
      () => uploaded
    )

    try {
      const newGroup = await this._api.orm.Layers().uploadTimeseries(group, files).run({
        progressCb: (e) => {
          if (!e.total) return
          uploaded = e.loaded > e.total * 0.99 ? '100%' : `${Math.round(e.loaded * 100 / e.total)}%`
        }
      })

      await this.loadGroup(newGroup.group_id)

      newGroup.layer_ids.forEach(id => this.FetchSource(id))

      this._layerUploadStateService.localUploads.push({
        upload: uploadState,
        id: newGroup.group_id
      })
    } catch (error: any) {
      uploadState.error = typeof error.message === 'string' ? error.message : 'Upload interrupted or unknown failure.'
      uploadState.seen = false
      this._layerUploadStateService.fetchUploadStates()
    }
  }

  async saveLayerExtent (targetLayer: AppLayer, extent: Db.Helper.Geo.Extent) {
    try {
      const obj = {
        extent: extent,
        projection: 'EPSG:3857'
      }

      await targetLayer.updateLayerExtent(obj)
    } catch (error: any) {
      handleError(error)
    }
  }

  async uploadDroneSource (
    layer: IPNewLayer,
    video: File[],
    telemetry: File[]
  ) {
    let uploaded = ''
    const uploadState = this._layerUploadStateService.monitorWorkspaceSource(
      {
        workspaceId: layer.workspace_id,
        productId: layer.product_id
      },
      layer.name,
      () => uploaded
    )

    try {
      await this._api.orm.Layers().DroneWatch()
      .upload(layer, video, telemetry).run({
        progressCb: (e) => {
          if (!e.total) return
          uploaded = e.loaded > e.total * 0.99 ? '100%' : `${Math.round(e.loaded * 100 / e.total)}%`
        }
      })

    } catch (error: any) {
      uploadState.error = typeof error.message === 'string' ? error.message : 'Upload interrupted or unknown failure.'
      uploadState.seen = false
      this._layerUploadStateService.fetchUploadStates()
    }
  }

  async uploadDatasetSource (
    source: IPNewLayer,
    dataset: File,
    eurotempestOptions: IEurotempestUploadOptions
  ) {
    let uploaded = ''
    try {
      await this._api.orm.Layers().Eurotempest()
      .upload(source, dataset, eurotempestOptions).run({
        progressCb: (e) => {
          if (!e.total) return
          uploaded = e.loaded > e.total * 0.99 ? '100%' : `${Math.round(e.loaded * 100 / e.total)}%`
        }
      })

    } catch (error: any) {
      handleError(error)
    }
  }

  async uploadSEPAFloodAreaSource (
    source: IPNewLayer,
    dataset: File,
    sepaFloodAreasOptions: ISepaFloodAreasUploadOptions
  ) {
    let uploaded = ''
    try {
      await this._api.orm.Layers().SepaFloodAreas()
      .upload(source, dataset, sepaFloodAreasOptions).run({
        progressCb: (e) => {
          if (!e.total) return
          uploaded = e.loaded > e.total * 0.99 ? '100%' : `${Math.round(e.loaded * 100 / e.total)}%`
        }
      })

    } catch (error: any) {
      handleError(error)
    }
  }

  async uploadVesselData (vesselJson:IVesselJson) {
    const keys = Object.keys(vesselJson)
    const vesselRows: Db.Vip.Emissions.IVessel[] = []
    for (let item of keys) {
      const jsonitem = vesselJson[item]
      const yearBuilt = jsonitem['Year of Built'] ? moment([jsonitem['Year of Built']]).format() : moment().format()
      const row:Db.Vip.Emissions.IVessel = {
        vessel_id: parseInt(item),
        name: jsonitem.Name,
        LOA: jsonitem['LOA (m)'],
        gross_tonnage: jsonitem['Gross Tonnage'],
        no_main_engines: jsonitem['No. Main Engines'],
        no_aux_engines: jsonitem['No. Aux Engines'],
        aux_engine_power: jsonitem['Aux Engine Power'],
        main_engine_power: jsonitem['Main Engine Power'],
        designed_speed: jsonitem['Designed Speed (knots)'],
        year_build: yearBuilt,
        vessel_type: jsonitem['Vessel Class'],
        enabled: true,
        deleted: undefined,
        create_user: undefined,
        update_user: undefined,
        created_at: undefined,
        updated_at: undefined,
      }
      vesselRows.push(row)
    }
    try {
      let callResult: any
      const chunks = CommonUtil.generalArrayChunk(vesselRows, 5000)
      await Promise.all(chunks.map(async (chunkedArray :Db.Vip.Emissions.IVessel[]) => {
         callResult = await this._api.orm.Microservice().uploadVessels(chunkedArray).run()
         if(callResult.payload !== "Inserted rows"){
          const error:string = callResult.payload.detail
          throw new Error(error)
        }
      }))
    } catch (error: any) {
      handleError(error)
      this._alertService.log(`${error}`)
    }
  }

  async uploadVesselJourneyData(journeyJson:IJson) {
    const journeyRows: Db.Vip.Emissions.IJourney[] = []
    Object.keys(journeyJson).forEach((date) => {
      Object.keys(journeyJson[date]).forEach((vessel_id) =>{
        const journeyVals:IJouneyProp = journeyJson[date][vessel_id]
        const row: Db.Vip.Emissions.IJourney = {
          journey_id: uuidv4(),
          vessel_id: parseInt(vessel_id),
          speed: journeyVals.Speed,
          power: journeyVals.Power,
          energy: journeyVals.Energy,
          fc: journeyVals.FC,
          nox: journeyVals.NOx,
          pm: journeyVals.PM,
          co2: journeyVals.CO2,
          co2_from_fc: journeyVals['CO2 From FC'],
          heading: journeyVals.Heading,
          lat: journeyVals.Lat,
          lon: journeyVals.Lon,
          status: journeyVals.Status,
          journey_date: date,
          enabled: true
        }
        journeyRows.push(row)
      })
    })
    try {
      let callResult: any
      const chunks = CommonUtil.generalArrayChunk(journeyRows, 5000)
      await Promise.all(chunks.map(async (chunkedArray :Db.Vip.Emissions.IJourney[]) => {
         callResult = await this._api.orm.Microservice().uploadVesselJourney(chunkedArray).run()
         if(callResult.payload !== "Inserted rows") {
          const error:string = callResult.payload.detail
          throw new Error(error)
        }
      }))
    } catch (error: any) {
      handleError(error)
      this._alertService.log(`${error}`)
    }
  }

  private HandleLayerLoadError (error: any, failedSource: IRWorkspaceLayer) {
    handleError(error, { description: `Failed to load layer ${failedSource.layer_id} ${failedSource.name}` })
    this._alertService.log(`${failedSource.name ? `${failedSource.name } layer` : 'one of the layers'} failed to load.`)
  }

  private async LoadLayer (source: IRWorkspaceLayer, existingTheme?: Db.Vip.Geo.ILayerAttribute): Promise<AppLayer> {
    let appLayer: AppLayer

    let LayerClass = AppLayer
    switch (source.source_type_id) {
      case Db.Vip.SourceType.DRONE_VECTOR:
        LayerClass = AppDroneLayer
        break
      case Db.Vip.SourceType.RASTER:
        LayerClass = AppRasterLayer
        break
    }
    if (ScraperPresets.includes(source.layer_preset_tag as any)) {
      LayerClass = AppScraperLayer
    }

    if (existingTheme) {
      if (!await this.safetyCheck(source, existingTheme)) {
        const { sources } = await this._api.orm.Workspaces().Workspace(source.workspace_id).getMetadata().run()
        source = sources.find(s => s.layer_id === source.layer_id) || source
      }

    }

    const allElements = () => this.appLayerElements
    const allLayers = () => this.allLayers
    const maxIndex = () => this._totalLayerCount
    const canSelect = () => {
      const selected = this.selected
      if (selected && selected.fEditingGeometry) {
        return false
      }
      return true
    }
    const canDeselect = () => {
      if (appLayer.fEditingGeometry) {
        return false
      }
      return true
    }

    try {
      appLayer = new LayerClass(
        allElements, allLayers, maxIndex, canSelect, canDeselect,
        this._momentPipe, this._dialog, this._promptService, this._api,
        this._workspaceService,
        source, this._workspaceService.viewId as number, existingTheme
      )
    } catch (error: any) {
      this.HandleLayerLoadError(error, source)
      appLayer = new LayerClass(
        allElements, allLayers, maxIndex, () => false, () => true,
        this._momentPipe, this._dialog, this._promptService, this._api,
        this._workspaceService,
        source, this._workspaceService.viewId as number, existingTheme, undefined,
        error.message || 'Unknown error.'
      )
    }

    appLayer.trackSubscription(
      appLayer.selectChanged.subscribe(selected => {
        this._layerSelectionChange.next(
          selected ? appLayer : this.allLayers.find(l => l.selected)
        )
      })
    )

    appLayer.trackSubscription(
      appLayer.visibleChange.subscribe(_ => {
        this._layerVisibilityChange.next(appLayer)
      })
    )

    appLayer.trackSubscription(
      appLayer.focusedChange.subscribe(focused => {
        this._layerFocusedChange.next(
          focused ? appLayer : this.allLayers.find(l => l.focused)
        )
      })
    )

    this.RegisterAppLayerMethods(appLayer)
    this.InsertLayerToLocalArray(appLayer)
    this._layerArrayChanged.next(this._appLayerElements)

    appLayer.waitForLoaded(0)
    .then(() => {
      if (appLayer.layer) this._newLayer.next(appLayer)
      appLayer.syncStyle(true, true)

      // If layer is in a mode (for now we have only PropView editing modes) and is
      // first layer in array with editing mode, then select to open attribute table
      // this will make it instantly available for editing
      if (appLayer.layerMode && this.allLayers.find(l => !!l.layerMode) === appLayer) {
        appLayer.selected = true
      }
    })

    return appLayer
  }

  private async safetyCheck (source: IRWorkspaceLayer, existingTheme: Db.Vip.Geo.ILayerAttribute) {
    if (!existingTheme.time_series) return true
    if (!(this._workspaceService.safeMode) && !existingTheme.time_series.real_time) return true
    const from = existingTheme.time_series.date_range ? existingTheme.time_series.date_range.from : undefined
    const to = existingTheme.time_series.date_range && existingTheme.time_series.real_time ? moment().add(1, 'minutes').toISOString() :
      existingTheme.time_series.date_range ? existingTheme.time_series.date_range.to : undefined

    if (!this._queryList.length) await this.getQueries()
    let numFeaturesAfterDelete = 0
    for (const q of this._queryList) {
      if (q.applied && q.layer_id === source.layer_id && q.type === 'spatial') {
        const wkt = OlUtil.featureToWkt(q.geometry, 6)
        const upload: IPNumEntriesCheck = {
          valid_from: from,
          valid_to: to,
          layer_preset: source.layer_preset_tag as Db.Vip.LayerPreset,
          wkt_geometry: wkt,
          layer_id: source.layer_id
        }
        const numEntries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
        numFeaturesAfterDelete += numEntries.count
      }
    }
    if (numFeaturesAfterDelete === 0) {
      const upload: IPNumEntriesCheck = {
        valid_from: from,
        valid_to: to,
        layer_preset: source.layer_preset_tag as Db.Vip.LayerPreset,
        layer_id: source.layer_id
      }
      const numEntries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
      numFeaturesAfterDelete += numEntries.count
    }
    if (numFeaturesAfterDelete > clientDataThreshold) {
      const body: IPUpdateLayer = {
        spatial_query_required: true
      }
      await this._api.orm.Workspaces().Workspace(source.workspace_id).Layer(source.layer_id)
      .updateLayer(body).run()
      return false
    } else {
      return true
    }
  }

  async getQueries () {
    const queries = await this._workspaceService.viewOrm.Queries().get().run() || []

    // Match every query with a layer
    this._queryList = queries.map((x, i) => ({
      ...x,
      targetRef: x.layer_group_id ? this.groups.find(y => y.id === x.layer_group_id) :
        this.allLayers.find(y => y.id === x.layer_id),
      index: i,
      geometry: x.geometry ?
        OlUtil.wktToFeature(x.geometry).getGeometry() as olGeom.Polygon | olGeom.MultiPolygon :
        undefined
    } as IQueryWRef))
  }

  private ClearQueryList () {
    this._queryList = []
  }

  private RegisterAppLayerMethods (appLayer: AppLayer) {
    appLayer.register.delete(async () => {
      await this._workspaceService.orm
      .Layer(appLayer.id)
      .delete().run()
      this.localLayerRemove(appLayer, true)

      this._layerRemoved.next(appLayer)
      this.sortLayersOnMap()
      // Trigger de-select event
      if (appLayer.selected) appLayer.selected = false
    })
  }

  private RegisterGroupMethods (group: AppLayerGroup) {
    group.register.delete(async (deleteLayers?: boolean) => {
      await group.api.delete(deleteLayers).run()

      if (this._appLayerElements.includes(group)) {
        this._appLayerElements.splice(this._appLayerElements.indexOf(group), 1)

        if (!deleteLayers) {
          this._appLayerElements.push(...group.layers)
          this.sortAndNormalizeLayers('order', false)

          if (group.styleApplied) {
            for (const l of group.layers) {
              l.syncStyle(true)
            }
          }
        } else {
          group.layers.forEach(l => {
            l.selected = false
            this._layerRemoved.next(l)
          })
        }

        this._layerArrayChanged.next(this._appLayerElements)
        this.UpdateLayerCount(this.allLayers.length)
      }
    })
  }

  private InsertLayerToLocalArray (layer: AppLayer) {
    for (const el of this._appLayerElements) {
      if (el instanceof AppLayerGroup) {
        if (el.containsLayer(layer.id)) {
          el.addExistingLayer(layer)
          return
        }
      }
    }
    this._appLayerElements.push(layer)
  }

  localLayerRemove (appLayer: AppLayer, unRender = true) {
    const layerLocation = this._appLayerElements.includes(appLayer) ?
      this._appLayerElements :
      ((this._appLayerElements.find(x =>
        x instanceof AppLayerGroup && x.layers.includes(appLayer)
      ) || {}) as AppLayerGroup)

    if (layerLocation) {
      Array.isArray(layerLocation) ?
        layerLocation.splice(layerLocation.indexOf(appLayer), 1) :
          layerLocation.removeLayer(appLayer)
    }

    if (unRender) {
      appLayer.destroy()
      this._layerRemoved.next(appLayer)
    }

    this.UpdateLayerCount(this.allLayers.length)
    this._layerArrayChanged.next(this.appLayerElements)
  }

  layerArrayUpdate () {
    this._layerArrayChanged.next(this.appLayerElements)
  }

  private async FetchSource (sourceId: string) {
    let layer: AppLayer | undefined
    try {
      const wsApi = this._workspaceService.orm
      const source = await wsApi.Layer(sourceId).get().run()
      this.UpdateLayerCount(this._totalLayerCount + 1)

      const existingTheme: Db.Vip.Geo.ILayerAttribute = await wsApi.Views().View(this._workspaceService.viewId as number)
        .Layer(sourceId).getAttributes().run()

      layer = await this.LoadLayer(source, existingTheme)
      this.sortAndNormalizeLayers('order')
    } catch (error: any) {
      handleError(error)
    }

    this._layerArrayChanged.next(this._appLayerElements)
    return layer
  }

  async loadSource (sourceId: string): Promise<AppLayer | undefined> {
    const existing = this.allLayers.find(x => x.id === sourceId)

    let layer
    if (existing) {
      await existing.reload()
      layer = existing
    } else {
      layer = await this.FetchSource(sourceId)
    }

    return layer
  }

  async loadGroup (id: string): Promise<AppLayerGroup | undefined> {
    const existing = this._appLayerElements.find(x => x.id === id && x instanceof AppLayerGroup) as AppLayerGroup | undefined

    let group: AppLayerGroup
    if (existing) {
      await existing.reload()
      group = existing
    } else {
      const groupMeta = await this._workspaceService.orm.Views().View(
        this._workspaceService.viewId as number
      )
      .LayerGroups().Group(id).get().run()
      group = new AppLayerGroup(this._dialog, this._promptService, this._api, this._workspaceService, groupMeta)

      group.trackSubscription(
        group.selectChanged.subscribe(selected => {
          this._groupSelectionChange.next(
            selected ? group : this.groups.find(g => g.selected)
          )
        })
      )

      this.RegisterGroupMethods(group)

      this._groupAdded.next(group)
      this._appLayerElements.splice(0, 0, group)
      this.sortAndNormalizeLayers('order', false)
      this.UpdateLayerCount(this.allLayers.length)
      this._layerArrayChanged.next(this._appLayerElements)
    }

    return group
  }

  async loadWorkspaceLayers () {
    this.clearLayersCollection()
    const payload = this._workspaceService.workspacePayload as IRWorkspaceMetadata
    const sourceAttributes = payload.default_view ? payload.default_view.attributes : []
    const groups = payload.default_view ? payload.default_view.layerGroups : []

    const loadedGroups: AppLayerGroup[] = groups.map(x => {
      const group = new AppLayerGroup(this._dialog, this._promptService, this._api, this._workspaceService, x)

      group.trackSubscription(
        group.selectChanged.subscribe(selected => {
          this._groupSelectionChange.next(
            selected ? group : this.groups.find(g => g.selected)
          )
        })
      )

      return group
    })

    for (const group of loadedGroups) {
      this.RegisterGroupMethods(group)
      this._appLayerElements.push(group)
    }

    this._layerArrayChanged.next(this._appLayerElements)

    if (payload.sources.length) {
      this.UpdateLayerCount(payload.sources.length)

      for (let i = 0; i < this._totalLayerCount; i++) {
        if (!this._workspaceService.active) break
        const source: IRWorkspaceLayer = payload.sources[i]

        try {
          const existingTheme = sourceAttributes.find(so => so.layer_id === source.layer_id)
          await this.LoadLayer(source, existingTheme)
          this.sortLayersOnMap(

          )
        } finally {
          this._layerArrayChanged.next(this._appLayerElements)
          this._workspaceService.loadWorkspaceData()
        }
      }

      if (this._workspaceService.active) this.sortAndNormalizeLayers('order')
    }
  }

  // TODO: Add function switchWorkspaceView (viewId: number) when multiple views are used

  private async ZoomToAllLayers (sources: IRWorkspaceLayer[]) {
    let extent
    for (const details of sources) {
      if (!details.extent || !details.extent.extent) {
        continue
      }

      try {
        const sourceExtent =
          details.extent.projection === 'EPSG:3857'
            ? details.extent.extent
            : olProj.transformExtent(details.extent.extent, details.extent.projection || 'EPSG:4326', 'EPSG:3857')
        extent = extent ? olExtent.extend(extent, sourceExtent) : sourceExtent
      } catch (error: any) {
        handleError(error, { description: `Failed to transform extent of ${details.layer_id}:${details.name} from` +
            ` '${details.extent.projection}' to 'EPSG:3857'` })
      }
    }
    // Emit event only if extent is defined, undefined
    // breaks observable, subscriptions do not get triggered
    // until page is refreshed
    if (extent) this._extentLoaded.next(extent)
  }

  private SortElementsArray () {
    const sortOrder = (arr: AppLayerGeneric[]) => arr.sort((a: AppLayerGeneric, b: AppLayerGeneric) => a.order > b.order ? 1 : -1)
    sortOrder(this._appLayerElements)
    for (const group of this._appLayerElements) {
      if (group instanceof AppLayerGroup) group.sortLayers()
    }
  }

  sortAndNormalizeLayers (by: 'order' | 'index', save = true) {
    if (by === 'order') this.SortElementsArray()
    const updateOrder = (el: AppLayerGeneric, order: number) => {
      if (el.order !== order) el.order = order
      if (el instanceof AppLayer) {
        el.layer && el.layer.setZIndex(ConvertUtil.invertIndex(maxIndex, el.order))
      }
    }

    const maxIndex = this._appLayerElements.reduce((max, val) =>
      val instanceof AppLayer ? max + 1 : max + val.layers.length, 0
    ) - 1

    let i = 1
    for (const el of this._appLayerElements) {
      let order = i
      updateOrder(el, order)

      if (el instanceof AppLayerGroup) {
        for (const l of el.layers) {
          order = i
          updateOrder(l, order)
          i++
        }
      }
      if (order === i) i++
    }
  }

  sortLayersOnMap () {
    this.SortElementsArray()
    const maxIndex = this._appLayerElements.reduce((max, val) =>
      val instanceof AppLayer ? max + 1 : max + (val.layers.length || 1), 0
    ) - 1

    let i = 0
    for (const el of this._appLayerElements) {
      const loopIn = i
      if (el instanceof AppLayer) {
        el.layer && el.layer.setZIndex(ConvertUtil.invertIndex(maxIndex, i))
      } else if (el instanceof AppLayerGroup) {
        for (const l of el.layers) {
          l.layer && l.layer.setZIndex(ConvertUtil.invertIndex(maxIndex, i))
          i++
        }
      }
      if (loopIn === i) i++
    }
  }

  getLayerParentArray (layer: AppLayer): AppLayerGeneric[] | undefined {
    if (this._appLayerElements.includes(layer)) return this._appLayerElements
    for (const el of this._appLayerElements) {
      if (el instanceof AppLayer) continue
      if (el.containsLayer(layer.id)) return el.layers
    }
  }

  async setLayerElementStyle (target: AppLayerGeneric): Promise<boolean> {
    if (!target) return true
    if (target instanceof AppLayer) {
      return target.applyStyle()
    } else {
      return target.styleApplied ? target.applyStyle() : true
    }
  }

  async duplicateLayer (layer: AppLayer, destWorkspaces: number[]) {
    if (!layer) return

    try {
      await this._workspaceService.viewOrm.Layer(layer.id).duplicate(destWorkspaces).run()
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  updateWorkspaceExtent () {
    this._originalLayerCount = undefined
    if (this._reloadWorkspaceId) {
      this._workspacesService.reloadWorkspace(this._reloadWorkspaceId, false , true)
      this._reloadWorkspaceId = undefined
    } else if (this.allLayers) {
      const layerUpdated = this.allLayers.find(layer => layer.extentUpdated)
      if (layerUpdated) this._workspacesService.reloadWorkspace(layerUpdated.workspaceId, false , true)
    }
  }
}

applyMixins(LayerService, [DialogCleanup])
