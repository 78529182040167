<div class="st-customer-background">
  <div class="auth-card-wrapper">
    <mat-card class="mat-elevation-z5">
      <app-powered-by>
        <app-logo logoSelector="auth_page"></app-logo>
      </app-powered-by>

      <mat-card-content>
        <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>

        <form [formGroup]="form">
          <mat-form-field [hideRequiredMarker]="true">
            <input matInput type="email" formControlName="email" placeholder="Email" (keydown.enter)="submit()">
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true">
            <input matInput type="password" formControlName="password" placeholder="Password" (keydown.enter)="submit()">
          </mat-form-field>

        </form>

        <div class="st-button-row">
          <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid"
            (click)="submit()">Login</button>
        </div>
      </mat-card-content>

      <mat-card-footer class="st-wide">
        <ul>
          <li>
            <a routerLink="/password-recovery">Forgot password?</a>
          </li>
        </ul>
      </mat-card-footer>

    </mat-card>

    <app-note *ngIf="isIEOrEdge">
      <p>
        We notice you're using Edge or IE, we recommend using
        <span class="st-highlight">Chrome</span>
        as performance will be much improved.<br>
        Using Edge or IE may result in unexpected component behavior.
      </p>
    </app-note>

    <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
  </div>
</div>
