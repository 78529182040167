import { IRLayerGroupMeta, IRVectorColumn } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { AppLayerBase } from './app-layer-base'
import { AppLayer } from './app-layer'
import { VipApiService, PromptService } from '@services/core'
import { IGeometryType } from '@vip-shared/interfaces/geometry-types'
import VectorSource from 'ol/source/Vector'
import * as olProj from 'ol/proj'
import * as olExtent from 'ol/extent'
import Feature from 'ol/Feature'
import { Subject, Subscription } from 'rxjs'
import { cloneDeep } from 'lodash'
import { CommonUtil, ConvertUtil } from '@core/utils/index'
import { MatDialog } from '@angular/material/dialog'
import { GroupStyleParameters } from '@core/types/workspace/layers/style/style-parameters'
import { DefaultStyleParameters } from '@vip-shared/models/layer-config/default-style-parameters'
import { debounceTime } from 'rxjs/operators'
import { IFeatureCustomColor, IFeatureSystemProperties } from '@core/types'
import { LayerUtil, LayerStyleUtil } from './utils'
import { WorkspaceService } from '../../../services/workspace'

interface LayerReference {
  layer: AppLayer
  subscriptions: Subscription
}
export class AppLayerGroup extends AppLayerBase {
  readonly type = 'group'
  private _viewId: number
  private _workspaceId: number
  private _styleApplied: boolean
  private _layers: LayerReference[] = []
  private _sourceIds: string[]
  private _collapsed = false

  private _timeSeries = false

  private _style: GroupStyleParameters = DefaultStyleParameters.GroupStyle

  get api () {
    return this._api.orm.Workspaces().Workspace(this._workspaceId).Views().View(this._viewId)
    .LayerGroups().Group(this.id)
  }

  get timeSeries () {
    return this._timeSeries
  }

  get features () {
    if (!this.timeSeries) return []
    const features: Feature[] = []
    for (const l of this.timeSeriesLayers) {
      features.push(...l.features)
    }
    return features
  }

  get droneStyle (): GroupStyleParameters['droneColor'] | 'auto' {
    if (
      CommonUtil.isUndefined(this.style.droneAuto) &&
      CommonUtil.isUndefined(this.style.droneColor)
    ) return

    if (this.style.droneAuto) return 'auto'

    return this.style.droneColor ?
      { ...this.style.droneColor } :
      { R: 0, G: 0, B: 0 }
  }

  get style (): GroupStyleParameters {
    return cloneDeep(this._style)
  }
  set style (val: GroupStyleParameters) {
    this._style = val
    this._attributeChange.next({})
    this._styleChanged.next({})
  }

  get collapsed (): boolean {
    return this._collapsed
  }
  set collapsed (val: boolean) {
    this._collapsed = val
    this._attributeChange.next({})
  }

  get layers (): AppLayer[] {
    return this._layers.map(l => l.layer)
  }

  get timeSeriesLayers () {
    return this.timeSeries ? this.layers.filter(l => l.timeSeriesGroup === this.id) : []
  }

  get styleApplied (): boolean {
    return this._styleApplied
  }
  set styleApplied (val: boolean) {
    this._styleApplied = val
    this._attributeChange.next({})
    this._styleChanged.next({})
  }

  get geometryTypes (): IGeometryType[] {
    return Array.from(
      this.layers.reduce((set, l) => {
        for (const type of l.geometryTypes) set.add(type)
        return set
      }, new Set<IGeometryType>())
    )
  }

  get extent (): Db.Vip.Geo.ILayer['extent'] | undefined {
    let extent: olExtent.Extent | undefined
    for (const layer of this.layers) {
      let lExtent: Db.Vip.Geo.ILayer['extent']
      if (layer.baseSource instanceof VectorSource) {
        lExtent = {
          projection: 'EPSG:3857',
          extent: layer.baseSource.getExtent() as [number, number, number, number]
        }
      } else if (layer.extent) lExtent = layer.extent

      if (lExtent && lExtent.extent) {
        const e = lExtent.projection === 'EPSG:3857' ?
          lExtent.extent :
          olProj.transformExtent(lExtent.extent, lExtent.projection || 'EPSG:4326', 'EPSG:3857')
        extent = extent ? olExtent.extend(extent, e) : e
      }
    }
    return !extent ? undefined : {
      extent: extent as [number, number, number, number],
      projection: 'EPSG:3857'
    }
  }

  private _layerRemoved = new Subject<AppLayer>()
  get layerRemoved () {
    return this._layerRemoved.asObservable()
  }

  private _layersChanged = new Subject()
  get layersChanged () {
    return this._layersChanged.asObservable()
  }

  protected _selectChanged = new Subject<boolean>()
  get selectChanged () {
    return this._selectChanged.asObservable()
  }

  protected _selected = false
  get selected (): boolean {
    return this._selected
  }
  set selected (select: boolean) {
    if (this._selected !== select) {
      this._selected = select
      this._selectChanged.next(this._selected)
    }
  }

  protected _filtered = false
  get filtered (): boolean {
    return this._filtered
  }
  set filtered (val: boolean) {
    this._filtered = val
  }

  private _attributeColumns?: Db.Helper.Geo.AttributeColumn[]
  get attributeColumns (): Db.Helper.Geo.AttributeColumn[] {
    return (this._attributeColumns && cloneDeep(this._attributeColumns)) || []
  }
  set attributeColumns (val: Db.Helper.Geo.AttributeColumn[]) {
    this._attributeColumns = val
  }

  get tableColumns (): IRVectorColumn[] {
    // Only layers with matching attribute columns will be in layers list,
    // therefore it's safe to assume that table columns will be the same
    return this.layers.length ? this.layers[0].tableColumns : []
  }

  protected _register = {
    delete: (cb: (deleteLayers?: boolean) => Promise<void>) => {
      if (this._deleteFn) throw new Error('Element delete() already registered.')
      this._deleteFn = cb
    }
  }

  register = {
    delete: (cb: (deleteLayers?: boolean) => Promise<void>) => this._register.delete(cb)
  }

  constructor (
    dialog: MatDialog,
    prompt: PromptService,
    api: VipApiService,
    workspaceService: WorkspaceService,
    object: IRLayerGroupMeta
  ) {
    super(dialog, prompt, api, workspaceService, {
      id: object.layer_group_id,
      title: object.name,
      visible: !!object.visible
    })

    this._viewId = object.view_id
    this._workspaceId = object.workspace_id
    this._styleApplied = !!object.style_applied
    this._order = object.source_order || 1
    this._sourceIds = object.sources
    this._collapsed = !!object['collapsed']
    this._timeSeries = !!object.timeseries
    this._attributeColumns = object.columns_schema
    if (object.style_parameters) this.style = object.style_parameters

    this._loaded = true
  }

  destroy () {
    this._subscriptions.unsubscribe()
  }

  trackSubscription (s: Subscription) {
    this._subscriptions.add(s)
  }

  async waitForLayersLoaded () {
    const loaded: boolean[] = await Promise.all([
      ...this.layers.map(x => x.waitForLoaded(0))
    ])
    return !loaded.includes(false)
  }

  saveConfiguration () {
    this._attributeChange.next({})
  }

  updateStyle (style: Partial<GroupStyleParameters>) {
    this.style = CommonUtil.mergeDeep(this.style, style)
  }

  sortLayers () {
    this._layers.sort((a, b) => a.layer.order > b.layer.order ? 1 : -1)
  }

  protected async SaveAttributes () {
    await this.api.update({
      collapsed: this.collapsed,
      source_order: this.order,
      sources: this._sourceIds,
      style_applied: this.styleApplied,
      style_parameters: this._style,
      visible: this.visible
    }).run()
  }

  protected async SaveName () {
    await this.api.update({
      name: this.title
    }).run()
  }

  async reload () {
    const self = await this.api.get().run()

    this._viewId = self.view_id
    this._workspaceId = self.workspace_id
    this._styleApplied = !!self.style_applied
    this._order = self.source_order || 1
    this._sourceIds = self.sources
    const deprecatedLayers = this._layers.filter(x => this._sourceIds.indexOf(x.layer.id) < 0)
    for (const layer of deprecatedLayers) {
      this.removeLayer(layer.layer)
    }
    this._collapsed = !!self.collapsed
    if (self.style_parameters) this._style = CommonUtil.mergeDeep(this.style, self.style_parameters)
  }

  async delete (deleteLayers?: boolean) {
    if (!this._deleteFn) console.warn(`Delete() for element ${this.id} is not registered.`)
    return this._deleteFn && (this._deleteFn as any)(deleteLayers)
  }

  containsLayer (id: string): boolean {
    return this._sourceIds.includes(id)
  }

  getTimeSeriesLayer (dateTime: NonNullable<NonNullable<Db.Vip.Geo.ILayer['parameters']>['datetime']>) {
    return this.timeSeriesLayers.find(l =>
      l.parameters.datetime && (
        l.parameters.datetime.date === dateTime.date &&
        l.parameters.datetime.time === dateTime.time
      )
    )
  }

  addExistingLayer (layer: AppLayer) {
    if (this.containsLayer(layer.id)) {
      if (this.timeSeries) this.SetLayerTimeSeriesContext(layer)

      if (!this._layers.find(x => x.layer === layer)) {
        this._layers.push({
          layer, subscriptions: this.RegisterLayerSubscriptions(layer)
        })
        this._layersChanged.next({})
      }

      if (this.timeSeries && !this._sourceIds.some(id => !this.layers.find(l => l.id === id))) {
        this.checkBaseGradient()
      }
    }
  }

  private async RefreshColumnSchema () {
    this._attributeColumns = (await this.api.get().run()).columns_schema
  }

  private RegisterLayerSubscriptions (layer: AppLayer) {
    const sub = new Subscription()

    sub.add(
      layer.selectChanged.subscribe(_ => {
        this.selected = this.layers.some(l => l.selected)
      })
    )

    if (this.timeSeries) {
      sub.add(
        layer.visibleChange.subscribe(visible => {
          if (layer.timeSeriesGroup !== this.id || !visible) return

          this.timeSeriesLayers.forEach(l => {
            if (l !== layer && l.visible) l.visible = false
          })
        })
      )

      sub.add(
        layer.columnsChanged.pipe(
          debounceTime(100)
        ).subscribe(async () => {
          await this.RefreshColumnSchema()
          this.timeSeriesLayers.forEach(l => {
            if (l !== layer) l.refreshColumns()
          })
        })
      )
    }

    return sub
  }

  removeLayer (layer: AppLayer | number) {
    const id = layer instanceof AppLayer ? +layer.id : layer
    const ref = this._layers.splice(this._layers.findIndex(x => +x.layer.id === id), 1)[0]
    ref.subscriptions.unsubscribe()

    this._sourceIds.splice(this._sourceIds.findIndex(x => +x === id), 1)
    this._attributeChange.next({})

    if (ref.layer.timeSeriesGroup === this.id) {
      ref.layer.timeSeriesGroup = undefined
      ref.layer.getLinkedTimeSeries = undefined
    }
    ref.layer.removeStyleQueries([`base_${this.id}`])

    this._layerRemoved.next(ref.layer)
    this._layersChanged.next({})

    this.selected = this.layers.some(l => l.selected)
  }

  async addNewLayer (layer: AppLayer, index: number = 0) {
    if (this.timeSeries) this.SetLayerTimeSeriesContext(layer)

    this._layers.splice(index, 0, {
      layer, subscriptions: this.RegisterLayerSubscriptions(layer)
    })
    this._sourceIds.splice(index, 0, layer.id)

    this._layersChanged.next({})
    this._attributeChange.next({})

    this.selected = this.layers.some(l => l.selected)
    this.checkBaseGradient()
  }

  private SetLayerTimeSeriesContext (layer: AppLayer) {
    if (!layer.parameters.datetime || !this.timeSeries) {
      layer.timeSeriesGroup = undefined
      layer.getLinkedTimeSeries = undefined
      return
    }

    const layerAttributes = layer.attributeColumns.filter(col => (col.prop !== 'update_user' && col.prop !== 'updated_at'))
    const hasDate = this.getTimeSeriesLayer(layer.parameters.datetime)
    const matchingTimeSeries = JSON.stringify(layerAttributes) === JSON.stringify(this._attributeColumns)

    // TODO: Edge case - layer has been added to group, columns do not matched, however user adjusts them and makes them match,
    // time-series should be reapplied if matching
    if (!hasDate && matchingTimeSeries) {
      if (layer.visible && this.timeSeriesLayers.find(x => x.visible)) layer.visible = false
      layer.timeSeriesGroup = this.id
      layer.getLinkedTimeSeries = () => this.timeSeriesLayers.filter(l => layer.timeSeriesGroup === this.id && l !== layer)
    } else {
      layer.timeSeriesGroup = undefined
      layer.getLinkedTimeSeries = undefined
    }
  }

  async applyStyle (): Promise<boolean> {
    let allUpdated = true
    for (const layer of this.layers) {
      allUpdated = allUpdated && await layer.applyStyle()
    }
    return allUpdated
  }

  applyGradient (
    selectedColumn: string,
    handles: {
      percentage: number
      color: string
    }[],
    styleAttribute: string,
    features: Feature[],
    queryId: string,
    order?: number,
    opacity = 1,
    range?: any
  ) {
    if (!features || !features.length) return

    let { distinctClasses, sorted } = LayerUtil.getDistinctFeatures(features, selectedColumn)
      // TODO: Requires handles and gradient style refactor. Handles should contain a label
      // and map value between labels, not by percentage alone

    sorted.forEach((el, index) => distinctClasses[el].color = LayerStyleUtil.getColorFromGradient(
        ConvertUtil.mapRange(index + 1, 1, sorted.length, 1, 100), handles
    ))

    for (const f of features) {
      const properties = f.getProperties() as IFeatureSystemProperties
      if (!properties.customColors) {
        properties.customColors = {
          fill: [],
          border: []
        }
      }
      if (range) {
        const min = !range.min ? sorted[0] : range.min.value ? range.min.value : properties[range.min.columnName]
        const max = !range.max ? sorted.filter(x => x !== 'null').slice(-1)[0] : range.max.value ? range.max.value : properties[range.max.columnName]
        let percent = ConvertUtil.mapRange(parseInt(properties[selectedColumn], 10), min, max, 1, 100)

        if (isNaN(percent)) {
          percent = (
            !CommonUtil.isUndefined(max) && CommonUtil.isUndefined(min)
          ) ? 100 : 1
        }
        distinctClasses[properties[selectedColumn]].color = LayerStyleUtil.getColorFromGradient(
          percent, handles
        )
      }

      if (!this._styleApplied) {
        if (properties.customColors.fill) {
          properties.customColors.fill.splice(
            properties.customColors.fill.findIndex(cc => cc.id === queryId), 1
          )
        }
        if (properties.customColors.border) {
          properties.customColors.border.splice(
            properties.customColors.border.findIndex(cc => cc.id === queryId), 1
          )
        }
        continue
      }
      const mappedStyle = distinctClasses[properties[selectedColumn]] || distinctClasses[Object.keys(distinctClasses)[0]]
      const styleWithId: IFeatureCustomColor | undefined = properties.customColors[styleAttribute].find((cC: any) => `${cC.id}` === queryId)

      if (!styleWithId) {
        const styleObj: IFeatureCustomColor = {
          id: queryId,
          color: mappedStyle.color,
          active: true,
          order
        }

        styleObj.color.A = opacity

        properties.customColors[styleAttribute].push(styleObj)
        properties.customColors[styleAttribute] = properties.customColors[styleAttribute].sort((a, b) => a.order > b.order ? 1 : -1)
      } else {
        styleWithId.color = mappedStyle.color
        styleWithId.color.A = opacity
        styleWithId.active = true
      }
      f.set('customColors', properties.customColors)
    }

    setTimeout(() => this.timeSeriesLayers.forEach(l => l.syncStyle()), 0)
  }

  async checkBaseGradient () {
    await this.waitForLayersLoaded()

    if (this._style.gradient && this.timeSeries) {
      for (const attribute in this._style.gradient) {
        const gradient = this._style.gradient[attribute]
        if (gradient.active) {
          this.applyGradient(
            gradient.column,
            gradient.steps,
            attribute,
            this.features,
            `base_${this.id}`,
            -1,
            this._style[attribute].A,
            gradient.range
          )
        }
      }
    }
  }
}
