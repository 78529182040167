import { Component, Inject, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})

export class PromptDialogComponent implements OnDestroy {
  private _subscriptions = new Subscription()
  buttonNames: string[]
  selections: {
    key: string
    displayName: string
    options: (string | number)[]
    value?: string | number
  }[] = []

  constructor (
        @Inject(MAT_DIALOG_DATA) public dataRef: {content: string, actions: any, dismissEnabled: boolean, onDismiss?: () => void},
        private _dialogRef: MatDialogRef<PromptDialogComponent>
      ) {
    this.buttonNames = Object.keys(dataRef.actions).filter(k => dataRef.actions[k] instanceof Function || dataRef.actions[k] === null)
    this.selections = Object.keys(dataRef.actions).filter(k => Array.isArray(dataRef.actions[k]))
    .map(k => {
      let displayName = k.replace(/([A-Z])/g, ' $1')
      displayName = `${displayName[0].toUpperCase()}${displayName.slice(1)}`
      return {
        key: k,
        displayName,
        options: dataRef.actions[k]
      }
    })

    this._subscriptions.add(
      this._dialogRef.beforeClosed().subscribe(action => {
        if (!action && dataRef.onDismiss) dataRef.onDismiss()
      })
    )
  }

  static setOptions (content: string, actions: any, onDismiss?: () => void): MatDialogConfig {
    const options: MatDialogConfig = {
      data: {
        content,
        actions,
        dismissEnabled: Object.keys(actions).length === 0,
        onDismiss
      },
      hasBackdrop: true,
      disableClose: !onDismiss && actions
    }

    return options
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  callAction (action: string) {
    const cb = this.dataRef.actions[action]
    if (cb) {
      if (this.selections.length <= 0) {
        cb()
      } else {
        cb(this.selections.reduce((obj, x) => {
          obj[x.key] = x.value
          return obj
        }, {} as any))
      }
    }
    this._dialogRef.close(true)
  }
}
