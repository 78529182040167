import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Db } from '@vip-shared/models/db-definitions'
import { FormTemplate } from '@core/models/form-template'
import { IPNewWorkspace, IPEditWorkspace } from '@vip-shared/interfaces/api/api-body-types'
import { VipApiService, AuthService, AlertService } from '@services/core'
import { IWorkspaceEditDialogBody } from '@core/types/workspace/workspace-edit-mode'
import { WorkspacesService } from '@services/explorer'
import AppError, { handleError } from '@core/models/app-error'

@Component({
  selector: 'app-workspace-edit-dialog',
  templateUrl: './workspace-edit-dialog.component.html',
  styleUrls: ['./workspace-edit-dialog.component.scss']
})
export class WorkspaceEditDialogComponent extends FormTemplate<UntypedFormGroup> implements OnInit {
  errorMessage?: string
  products: Db.Vip.Gsi.IProduct[] = []
  title = ''

  get sysMaintainer () {
    return this._authService.isSysMaintainer
  }

  constructor (
        @Inject(MAT_DIALOG_DATA) public data: IWorkspaceEditDialogBody,
        protected _dialogRef: MatDialogRef < WorkspaceEditDialogComponent >,
        private _api: VipApiService,
        private _workspacesService: WorkspacesService,
        private _authService: AuthService,
        private _alertService: AlertService
    ) {
    super(
      new UntypedFormGroup({
        name: new UntypedFormControl(undefined, Validators.required),
        product: new UntypedFormControl(undefined, Validators.required),
        tags: new UntypedFormControl(undefined),
        demo: new UntypedFormControl(undefined),
        has_dashboard: new UntypedFormControl(undefined)
      }),
      _dialogRef
    )
    if (data.mode === 'clone') this.title = `Clone workspace "${data.workspace.workspace_name}"`
    else if (data.mode === 'edit') this.title = `Editing workspace "${data.workspace.workspace_name}"`
    else if (data.mode === 'create') this.title = `Create new workspace`
  }

  ngOnInit () {
    if ((this.data.mode === 'clone' || this.data.mode === 'edit') && this.data.workspace) {
      this.form.controls.name.setValue(`${
        this.data.mode === 'clone' ? 'Clone of ' : ''
      }${
        this.data.workspace.workspace_name
      }`)
      this.form.controls.tags.setValue(this.data.workspace.workspace_tags)
      this.form.controls.product.setValue(this.data.workspace.product_id)
      this.form.controls.demo.setValue(this.data.workspace.demo)
      this.form.controls.has_dashboard.setValue(this.data.workspace.has_dashboard)
    }
    this.loadData()
  }

  async loadData () {
    this.products = this.sysMaintainer ? await this._api.orm.Products().get().run() :
      await this._api.orm.Customers().getProducts().run()

    if (this.products.length === 0) {
      this._alertService.log(`Sorry but you do not have permissions enough permissions to create a workspace.`)
      this._dialogRef.close()
    }
    if (this.data.mode === 'create') {
      // System admin default selection: VIP
      this.form.controls.product.setValue(this.sysMaintainer ? 1 : this.products[0].product_id)

    }
    if (this.products.length === 1) {
      this.form.controls.product.disable()
    }

    if (this.data.mode === 'clone' && !this.products.map(x => x.product_id).includes(this.data.workspace.product_id)) {
      this._alertService.log(`Sorry but you cannot clone this workspace. You do not have permissions to create workspaces for this product.`)
      this._dialogRef.close()
    }
  }

  async submit () {
    if (this.saving) return
    this.errorMessage = undefined

    try {
      if (this.form.valid) {
        this.saving = true
        this.toggleForm(false)
        let newId: number | undefined
        const controls = this.form.controls
        const workspaceData: IPEditWorkspace = {
          workspace_name: controls.name.value,
          tags: controls.tags.value || undefined,
          demo: controls.demo.value !== null ? controls.demo.value : undefined,
          has_dashboard: controls.has_dashboard.value !== null ? controls.has_dashboard.value : undefined
        }
        if (this.data.mode === 'create' || this.data.mode === 'clone') {
          const newWorkspace: IPNewWorkspace = {
            ...workspaceData,
            product_id: controls.product.value
          }

          if (this.data.mode === 'clone') newWorkspace.clone_workspace_id = this.data.workspace.workspace_id
          newId = await this._api.orm.Workspaces().add(newWorkspace).run()
          // TODO: fetch only inserted workspace
          // On creation, clear filters
          await this._workspacesService.loadWorkspaces({}, true)
        } else {
          await this._api.orm.Workspaces().Workspace(this.data.workspace.workspace_id).update(workspaceData).run()
          Object.assign(this.data.workspace, workspaceData)
        }
        this._dialogRef.close({
          workspaceId: newId,
          productId: this.form.controls.product.value
        } || true)
      } else {
        throw new AppError('Form invalid.')
      }
    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    }
    this.saving = false
    this.toggleForm(true)
  }
}
