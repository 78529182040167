<div #alertQuery [ngClass]="overThreshold ? 'query over' : 'query under'">
  <div class="widget-controls">
    <mat-icon class="icon-align" [matTooltip]="infoTooltip">info</mat-icon>
    <mat-icon class="icon-align" [matMenuTriggerFor]="menu">tune</mat-icon>
  </div>
  <div *ngIf="threshold" class="st-ellipsis widget-title">
     <span appOverflowAnimate>{{queryTitle}}</span>
  </div>
  <mat-menu #menu="matMenu">
    <div class="menu-content">
      <mat-form-field (click)="$event.stopPropagation();">
        <mat-label>Threshold</mat-label>
        <input matInput placeholder="Threshold" [(ngModel)]="threshold"/>
      </mat-form-field>
      <div class="control-wrap">
        <div class="content">
          Refresh
        </div>
        <div class="action">
          <mat-icon class="clickable" (click)="changeThreshold()">refresh</mat-icon>
        </div>
      </div>
    </div>
  </mat-menu>
  <h4 [ngStyle]="{'font-size': fontSize}">{{featureCount.toLocaleString()}}</h4>
</div>
