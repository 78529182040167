import { Polygon, LineString, Point } from 'ol/geom'
import Feature from 'ol/Feature'

import { MeasurementType } from '@core/enum/measurement-type'
import { ConvertUtil } from '../convert/convert.util'

export class ShapesUtil {

  static newPolygon (coords: [number, number][][]): Feature {

    coords.forEach((coord: [number, number][]) => {

      coord.forEach((point: [number, number]) => {

        const converted = ConvertUtil.lngLatToE3857(point)
        point[0] = converted[0]
        point[1] = converted[1]
      })

    })

    const feature = new Feature({
      type:  MeasurementType.Polygon,
      geometry: new Polygon(coords)
    })

    return feature
  }

  static newLine (coords: [number, number][]): Feature {
    coords.forEach((point: [number, number]) => {

      const converted = ConvertUtil.lngLatToE3857(point)
      point[0] = converted[0]
      point[1] = converted[1]
    })

    const feature = new Feature({
      type:  MeasurementType.LineString,
      geometry: new LineString(coords)
    })

    return feature
  }

  static newPoint (attribute: any): Feature {
    const converted = ConvertUtil.lngLatToE3857([attribute.lng, attribute.lat])
    const point = new Point(converted)
    const feature = new Feature({
      type: MeasurementType.Point,
      geometry: point
    })

    this.applyFeatureProperties(feature, attribute)
    return feature
  }

  static applyFeatureProperties (feature: Feature, allProperties: any) {
    const filteredProps: any = {}
    const ignoredProperties = ['lat', 'lng']
    for (const key of Object.keys(allProperties)) {
      if (!ignoredProperties.includes(key)) {
        filteredProps[key] = allProperties[key]
      }
    }
    if (Object.keys(filteredProps).length > 0) {
      feature.setProperties({ attributes: filteredProps })
    }
  }
}
