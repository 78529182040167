import { Injectable } from '@angular/core'

import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { Subject } from 'rxjs'
import { AuthService } from '@services/core'
import { handleError } from '@core/models/app-error'
import { API } from '@vip-shared/interfaces/api-helper'
import * as olProj from 'ol/proj'

@Injectable({
  providedIn: 'root'
})
export class WorkspacesService {
  private _updated = new Subject()
  private _pageUpdated = new Subject()
  workspaces: API.Res.UserWorkspace[] = []
  pageWorkspaces: API.Res.UserWorkspace[] = []

  lastFilter?: API.Qry.WorkspaceFilters
  loading = true

  pagination = {
    count: {
      curr: 0,
      latest: 0
    },
    query: {
      offset: 0,
      limit:  12
    },
    pages: {
      curr: 0
    }
  }

  get updated () {
    return this._updated.asObservable()
  }

  get pageUpdated () {
    return this._pageUpdated.asObservable()
  }

  get length () {
    return this.workspaces.length
  }

  constructor (
      private _api: VipApiService,
      private _authService: AuthService
  ) {
    this._authService.sessionActive.subscribe(active => {
      if (!active) {
        this.workspaces = []
        this.pageWorkspaces = []
      }
    })
  }

  async loadWorkspaces (filters?: API.Qry.WorkspaceFilters, force = false) {
    this.loading = true
    
    try {
      const filter = filters || this.lastFilter || {}

      if (!force && this.workspaces.length && JSON.stringify(filter) === JSON.stringify(this.lastFilter)) {
        this.loading = false
        return
      }

      this.lastFilter = filter

      this.workspaces = await this._api.orm.Workspaces().get(this.lastFilter).run()
      this.workspaces.forEach(workspace => {
        // api returns extent in EPSG:4326 format. transform to EPSG:3857
        workspace.extent = workspace.extent ?
          olProj.transformExtent(workspace.extent, 'EPSG:4326', 'EPSG:3857') as [number, number, number, number] :
          undefined
      })

      this.updatePage()
    } catch (error: any) {
      handleError(error)
    }

    this._updated.next({})
    this.loading = false
  }

  async updatePage () {
    this.pagination.count.latest = this.workspaces.length

    if (!this.pagination.pages.curr) this.pagination.count.curr = this.pagination.count.latest
    // If there are less rows than retrieved initially, reset paging
    if (this.pagination.count.latest < this.pagination.pages.curr) {
      this.pagination.pages.curr = 0
      this.pagination.count.curr = this.pagination.count.latest
    }

    // Calculate query offset
    this.pagination.query.offset = this.pagination.pages.curr * this.pagination.query.limit +
    (this.pagination.count.latest - this.pagination.count.curr)

    this.pageWorkspaces = this.workspaces.slice(this.pagination.query.offset, this.pagination.query.offset + this.pagination.query.limit)
    this._pageUpdated.next({})
  }

  async deleteWorkspace (workspaceId: number) {
    this.spliceLocalWorkspace(workspaceId)
    await this._api.orm.Workspaces().Workspace(workspaceId).delete().run()
  }

  spliceLocalWorkspace (workspaceId: number) {
    this.workspaces.splice(
      this.workspaces.findIndex(x => x.workspace_id === workspaceId),
      1
    )
    this.updatePage()
    this._updated.next({})
  }

  async reloadWorkspace (workspaceId: number, reloadAll?: boolean, updateExtent: boolean = false) {
    const index = this.workspaces.findIndex(x => x.workspace_id === workspaceId)
    if (index > -1) {
      try {
        const workspace = await this._api.orm.Workspaces()
        .Workspace(workspaceId).get().run()

        workspace.extent = workspace.extent ? olProj.transformExtent(
        workspace.extent, 'EPSG:4326', 'EPSG:3857') as [number, number, number, number] :
        undefined

        this.workspaces[index] = workspace
        // NOTE: Reload if workspace has been edited to reapply filters
        // now it's not too much of performance issue, but if it will be
        // might consider applying filter to Workspace get - so that workspace
        // would be returned only if filter matches
        if (updateExtent) this.updatePage()
        if (reloadAll) this.loadWorkspaces(undefined, true)
      } catch (error: any) {
        this.workspaces.slice(index, 1)
      }
    }
  }
}
