import { Injectable } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { Router } from '@angular/router'
import { Subject, BehaviorSubject } from 'rxjs'
import { AlertService } from '@services/core/alert/alert.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { IRWorkspaceMetadata } from '@vip-shared/interfaces/api/api-payloads'
import { IPEditSourceAttribute, IPEditView, IPNewViewUser } from '@vip-shared/interfaces/api/api-body-types'
import AppError, { handleError } from '@core/models/app-error'
import { API } from '@vip-shared/interfaces/api-helper'
import { WorkspacesService } from '@services/explorer'
import * as olProj from 'ol/proj'
import { reqQueryParam } from '@vip-shared/models/static-defs'

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  private _selectedWorkspace?: API.Res.UserWorkspace
  get selectedWorkspace () {
    return this._selectedWorkspace
  }

  workspacePayload?: IRWorkspaceMetadata

  private _viewLoaded = new Subject<boolean>()
  private _viewAndWorkspaceLoaded = new Subject<boolean>()

  private _exit = new Subject<void>()
  get onExit () {
    return this._exit.asObservable()
  }

  get hasDashboard () {
    return this._selectedWorkspace && this._selectedWorkspace.has_dashboard
  }

  private _baseMap = new BehaviorSubject<string>('')
  get activeBaseMap () {
    return this._baseMap.asObservable()
  }

  private _safeMode: boolean = false
  get safeMode (): boolean {
    return this._safeMode
  }

  get viewExtent () {
    if (!this._selectedWorkspace) return
    return this._selectedWorkspace.extent
  }

  get orm () {
    if (!this.workspaceId) {
      throw new AppError(`Cannot make request to server. Failed to retrieve workspace ID.`)
    }
    return this._api.orm.Workspaces().Workspace(this.workspaceId)
  }

  get viewOrm () {
    if (!this.viewId) throw new AppError(`Cannot make request to server. Failed to retrieve workspace view ID.`)
    return this.orm.Views().View(this.viewId)
  }

  get active () {
    return !!this._selectedWorkspace
  }

  get workspaceName () {
    if (!this._selectedWorkspace) {
      return '-'
    }
    return this._selectedWorkspace.workspace_name
  }

  get workspaceId (): number | undefined {

    if (!this._selectedWorkspace) {
      return undefined
    }
    return this._selectedWorkspace.workspace_id
  }

  get isDashboard (): boolean | undefined {
    if (!this._selectedWorkspace) return
    const details = this._selectedWorkspace.product_interface_specifics
    if (details) {
      return details.type === 'dashboard'
    }
  }

  get permissionsLevel (): number | undefined {
    if (!this._selectedWorkspace) return
    return this._selectedWorkspace.workspace_permissions_level
  }

  get readOnly (): boolean {
    return !this.permissionsLevel || this.permissionsLevel === Db.Vip.Role.WS_VIEWER || this.isDemo
  }

  get product (): Db.Vip.Product | undefined {
    return this._selectedWorkspace && this._selectedWorkspace.product_id
  }

  get isDemo (): boolean {
    return !!(this._selectedWorkspace && this._selectedWorkspace.demo)
  }

  private _viewId?: number
  get viewId (): number | undefined {

    if (!this._viewId) {
      console.error('viewId, Workspace view not selected.')
      return undefined
    }
    return this._viewId
  }

  private _pollutionLayerRequire = new BehaviorSubject<boolean>(false)
  get pollutionLayerRequire () {
    return this._pollutionLayerRequire.asObservable()
  }

  private _workspaceLoaded = new BehaviorSubject<boolean>(false)
  get workspaceLoaded () {
    return this._workspaceLoaded.asObservable()
  }

  constructor (
    private _api: VipApiService,
    private _router: Router,
    private _alertService: AlertService,
    private _workspacesService: WorkspacesService
  ) { }

  proxyAppendWsQuery (url: string, sourceId?: string | number) {
    const endUrl = url.split('/').pop()
    const join = !endUrl ? '' :
      !endUrl.includes('?') ? '?' :
      !endUrl.endsWith('&') ? '&' : ''

    const queries = [`${reqQueryParam.WORKSPACE_ID}=${this.workspaceId}`]
    if (sourceId) queries.push(`${reqQueryParam.LAYER_ID}=${sourceId}`)

    return `${url}${join}${queries.join('&')}`
  }

  isAir () {
    return this.product === Db.Vip.Product.AIR_POLLUTION
  }

  isTankwatch () {
    return this.product === Db.Vip.Product.TANKWATCH
  }

  isSolar () {
    return this.product === Db.Vip.Product.SOLAR_PV
  }

  isEV () {
    return this.product === Db.Vip.Product.ELECTRIC_VEHICLES
  }

  isVIP () {
    return this.product === Db.Vip.Product.VIP
  }

  isPropertyView () {
    return this.product === Db.Vip.Product.PROPERTY_VIEW
  }

  isDatapitch () {
    return this.product === Db.Vip.Product.DATAPITCH
  }

  isCoastalMonitor () {
    return this.product === Db.Vip.Product.COASTAL_MONITOR
  }

  isFRED () {
    return this.product === Db.Vip.Product.FRED
  }

  isEmissions () {
    return this.product === Db.Vip.Product.EMISSIONS
  }

  isOneOf (projectList: Db.Vip.Product[]) {
    return projectList.includes(this.product as any)
  }

  get regionName (): string {
    if (!this._selectedWorkspace || !this._selectedWorkspace.workspace_layout ||
      !(this._selectedWorkspace.workspace_layout as any).region) {
      return ''
    }

    return `${(this._selectedWorkspace.workspace_layout as any).region}`.toLowerCase()
  }

  async updateView (options: IPEditView) {
    const viewId = this.viewId
    if (viewId) {
      try {
        await this.orm
          .Views().View(viewId).update(options).run()

        if (options.base_map) this._baseMap.next(options.base_map)
      } catch (error: any) {
        handleError(error)
        this._alertService.log('Failed to update the base layer')
      }
    }
  }

  async loadWorkspace (workspaceId: number) {
    let workspace = this._workspacesService.workspaces.find(w => w.workspace_id === workspaceId)
    if (!workspace) {
      workspace = await this._api.orm.Workspaces().Workspace(workspaceId).get().run()
      workspace.extent = workspace.extent ? olProj.transformExtent(workspace.extent, 'EPSG:4326', 'EPSG:3857') as [number, number, number, number] :
      undefined
    }

    if (!workspace) {
      this._router.navigate(['/error/not-found'])
      throw new AppError(`Failed to find workspace ${workspaceId}`)
    }

    this._selectedWorkspace = workspace

    this._pollutionLayerRequire.next(this.isAir())

    await this.loadWorkspaceData()
    this._workspaceLoaded.next(true)
    if (this._viewId) {
      this._viewAndWorkspaceLoaded.next(true)
    }
  }

  async loadWorkspaceData () {
    this.workspacePayload = await this.orm.getMetadata().run()
    if (!this.workspacePayload) throw new AppError(`Failed to fetch data for workspace ${this.workspaceId as number}`)
    this.SelectDefaultView(this.workspacePayload.views)
  }

  private SelectDefaultView (views: Db.Vip.Prj.IView[]) {
    if (views.length > 0) {
      let defaultView = views.find(x => !!x.view_is_default)
      if (!defaultView) {
        defaultView = views[0]
      }
      if (defaultView.base_map) {
        this._baseMap.next(defaultView.base_map)
      }
      this.selectWorkspaceView(defaultView.view_id)
    } else {
      this._router.navigate(['explorer'])
      this._alertService.log(`Workspace does not have any views or you don't have access to existing views.`)
    }
  }

  selectWorkspaceView (viewId: number) {
    this._viewId = viewId
    this._viewLoaded.next(true)
    if (this._selectedWorkspace) {
      this._viewAndWorkspaceLoaded.next(true)
    }
  }

  async hideAllLayers (workspaceId: number) {
    const wsApi = this._api.orm.Workspaces().Workspace(workspaceId)
    const wsPayload = await wsApi.getMetadata().run()
    const views = wsPayload.views
    const sources = wsPayload.sources
    for (const view of views) {
      for (const source of sources) {
        const updatedLayer: IPEditSourceAttribute = { visible: false }
        const layerApi = wsApi.Views().View(view.view_id).Layer(source.layer_id)
        await layerApi.update(updatedLayer).run()
      }
    }
  }

  setSafeMode (mode: boolean) {
    this._safeMode = mode
  }

  waitForWorkspace (): Promise<boolean> {
    return new Promise((resolve) => {
      if (this._selectedWorkspace) {
        resolve(true)
      } else {
        this._workspaceLoaded.subscribe(loaded => {
          if (loaded) {
            resolve(true)
          }
        })
      }
    })
  }

  waitForView (): Promise<boolean> {
    return new Promise((resolve) => {
      if (this._viewId) {
        resolve(true)
      } else {
        this._viewLoaded.subscribe(loaded => {
          if (loaded) {
            resolve(true)
          }
        })
      }
    })
  }

  async waitForWorkspaceAndView (): Promise<boolean> {
    await this.waitForWorkspace()
    await this.waitForView()
    return true
  }

  clearWorkspaceOnExit () {
    this._selectedWorkspace = undefined
    this._viewId = undefined
    this._exit.next()
  }

  async getViewUsers (): Promise<(Db.Vip.Prj.IViewUser & Db.Vip.Cst.IUser)[]> {
    if (!this.viewId || !this.workspaceId) { return [] }

    return this.orm.Views().View(this.viewId)
      .Users().get().run()
  }

  async removeViewUser (userId: number) {
    if (!this.viewId || !this.workspaceId) { return }

    await this.orm.Views().View(this.viewId)
      .Users().delete(userId).run()
  }

  async addViewUser (userId: number) {
    if (!this.viewId || !this.workspaceId) { return }

    const body: IPNewViewUser = {
      user_id: userId
    }

    await this.orm.Views().View(this.viewId)
      .Users().add(body).run()
  }

  async getWorkspaceUsers (workspaceId?: number): Promise<Db.Vip.Prj.IVWorkspacePermission[]> {
    if (!workspaceId && !this.workspaceId) { return [] }

    return this._api.orm.Workspaces().Workspace((workspaceId || this.workspaceId) as number)
      .Users().get().run()
  }

  async removeWorkspaceUser (workspaceId: number, userId: number) {

    await this._api.orm.Workspaces().Workspace(workspaceId).Users()
      .delete(userId).run()
  }

  async appNameIsWorkspaceProductName (appName: string): Promise<boolean> {

    let valid = false

    await this.waitForWorkspace()

    if (this.product &&
      this._selectedWorkspace) {
      valid = this._selectedWorkspace.product_name.toLowerCase().includes(appName)
    }

    return valid
  }
}
