<div class="st-customer-background">
  <div class="auth-card-wrapper">
    <mat-card class="mat-elevation-z5">
      <app-powered-by>
        <app-logo logoSelector="auth_page"></app-logo>
      </app-powered-by>

      <mat-card-content>
        <ng-container [ngTemplateOutlet]="statusTemplate"></ng-container>

        <ng-template #idle>
          <h3>Waiting</h3>
          <div class="message">Waiting to verify</div>
        </ng-template>

        <ng-template #verifying>
          <h3>Verifying</h3>
          <div class="message">Please wait while we verify your email address</div>
        </ng-template>

        <ng-template #error>
          <h3 clas="st-error">Error</h3>
          <div class="message st-error">{{ errorMessage || "Unknown error"}}</div>
        </ng-template>

        <ng-template #success>
          <h3>Thank you</h3>
          <div class="message">Your email has been verified.</div>
        </ng-template>

      </mat-card-content>

      <mat-card-footer class="st-wide">
        <ul>
          <li>
            <a routerLink="/login">Back</a>
          </li>
        </ul>
      </mat-card-footer>

    </mat-card>
    <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
  </div>
</div>
