<ng-container [ngSwitch]="productType">
  <ng-container *ngSwitchCase="workspaceType.AIR_POLLUTION">
    <app-ws-placeholder-uhl [workspaceId]="workspaceId"></app-ws-placeholder-uhl>
  </ng-container>

  <ng-container *ngSwitchCase="workspaceType.DATAPITCH">
    <app-ws-placeholder-solar [extent]="extent" [workspaceId]="workspaceId"></app-ws-placeholder-solar>
  </ng-container>
  <ng-container *ngSwitchCase="workspaceType.SOLAR_PV">
    <app-ws-placeholder-solar [extent]="extent" [workspaceId]="workspaceId"></app-ws-placeholder-solar>
  </ng-container>

  <ng-container *ngSwitchCase="workspaceType.TANKWATCH">
    <app-ws-placeholder-tankwatch></app-ws-placeholder-tankwatch>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-ws-placeholder-default [extent]="extent" [workspaceId]="workspaceId"></app-ws-placeholder-default>
  </ng-container>
</ng-container>
