import { Component, Inject, ViewChildren, QueryList } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AuthService, SettingsService } from '@services/core'
import { WorkspaceUserTableComponent } from './workspace-user-table/workspace-user-table.component'

@Component({
  selector: 'app-workspace-access-dialog',
  templateUrl: './workspace-access-dialog.component.html'
})
export class WorkspaceAccessDialogComponent {
  @ViewChildren('userTable') userTableRef?: QueryList<WorkspaceUserTableComponent>
  selectedIndex = 0
  get sysMaintainer (): boolean {
    return this._authService.isSysMaintainer
  }

  get animate (): boolean {
    return !this._settingsService.isIEorEdge(false)
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: {
      workspaceId: number
      productId: number
    },
    private _authService: AuthService,
    private _dialogRef: MatDialogRef<WorkspaceAccessDialogComponent>,
    private _settingsService: SettingsService
  ) { }

  showCustomerUsers (customerName: string) {
    if (this.userTableRef && this.userTableRef.length === 1) {
      this.userTableRef.first.filterByCustomer(customerName)
    } else {
      console.warn('Workspace user table is undefined or consists of multiple elements.')
    }
    this.selectedIndex = 1
  }

  reloadUsers () {
    if (this.userTableRef && this.userTableRef.length === 1) {
      this.userTableRef.first.loadData()
    } else {
      console.warn('Workspace user table is undefined or consists of multiple elements.')
    }
  }

  close () {
    this._dialogRef.close()
  }
}
