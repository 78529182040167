<h2 mat-dialog-title>Annotation Guide Set</h2>

<div mat-dialog-content>
  <p *ngIf="errorMessage" class="st-error">
    {{errorMessage}}
  </p>

      <form [formGroup]="form">
        <mat-form-field>
          <input matInput formControlName="name" placeholder="Set Name" cdkFocusInitial>
        </mat-form-field>

        <app-group-header>Guides</app-group-header>

        <div *ngIf="guides">

          <mat-form-field>
            <mat-select disableOptionCentering formControlName="constr_class" [placeholder]="placeholder('Construction Class', guides?.constr_class.length)">
              <mat-option class="new-option" (click)="createNewGuide('constr_class')">
                + Create new
              </mat-option>
              <mat-option [value]="undefined">
                None
              </mat-option>

              <mat-optgroup label="Measured">
                <ng-container *ngFor="let guide of guides?.constr_class">
                  <mat-option *ngIf="guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup label="Not Measured">
                <ng-container *ngFor="let guide of guides?.constr_class">
                  <mat-option *ngIf="!guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select disableOptionCentering formControlName="roof_material" [placeholder]="placeholder('Roof Material', guides?.roof_material.length)">
              <mat-option class="new-option" (click)="createNewGuide('roof_material')">
                + Create new
              </mat-option>
              <mat-option [value]="undefined">
                None
              </mat-option>

              <mat-optgroup label="Measured">
                <ng-container *ngFor="let guide of guides?.roof_material">
                  <mat-option *ngIf="guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup label="Not Measured">
                <ng-container *ngFor="let guide of guides?.roof_material">
                  <mat-option *ngIf="!guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select disableOptionCentering formControlName="roof_type" [placeholder]="placeholder('Roof Type', guides?.roof_type.length)">
              <mat-option class="new-option" (click)="createNewGuide('roof_type')">
                + Create new
              </mat-option>
              <mat-option [value]="undefined">
                None
              </mat-option>

              <mat-optgroup label="Measured">
                <ng-container *ngFor="let guide of guides?.roof_type">
                  <mat-option *ngIf="guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup label="Not Measured">
                <ng-container *ngFor="let guide of guides?.roof_type">
                  <mat-option *ngIf="!guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select disableOptionCentering formControlName="storey" [placeholder]="placeholder('Storey', guides?.storey.length)">
              <mat-option class="new-option" (click)="createNewGuide('storey')">
                + Create new
              </mat-option>
              <mat-option [value]="undefined">
                None
              </mat-option>

              <mat-optgroup label="Measured">
                <ng-container *ngFor="let guide of guides?.storey">
                  <mat-option *ngIf="guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup label="Not Measured">
                <ng-container *ngFor="let guide of guides?.storey">
                  <mat-option *ngIf="!guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select disableOptionCentering formControlName="footprint" [placeholder]="placeholder('Footprint', guides?.footprint.length)">
              <mat-option class="new-option" (click)="createNewGuide('footprint')">
                + Create new
              </mat-option>
              <mat-option [value]="undefined">
                None
              </mat-option>

              <mat-optgroup label="Measured">
                <ng-container *ngFor="let guide of guides?.footprint">
                  <mat-option *ngIf="guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup label="Not Measured">
                <ng-container *ngFor="let guide of guides?.footprint">
                  <mat-option *ngIf="!guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select disableOptionCentering formControlName="building_height" [placeholder]="placeholder('Building Height', guides?.building_height.length)">
              <mat-option class="new-option" (click)="createNewGuide('building_height')">
                + Create new
              </mat-option>
              <mat-option [value]="undefined">
                None
              </mat-option>

              <mat-optgroup label="Measured">
                <ng-container *ngFor="let guide of guides?.building_height">
                  <mat-option *ngIf="guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

              <mat-optgroup label="Not Measured">
                <ng-container *ngFor="let guide of guides?.building_height">
                  <mat-option *ngIf="!guide.hasMeasures" [value]="guide.guide_id" class="st-capitalize" [matTooltip]="guide.date">
                    <ng-container *ngIf="guide.guide_id === 'no_guide'">Guide not assigned</ng-container>
                    <ng-container *ngIf="guide.guide_id !== 'no_guide'">{{guide.name}} v{{guide.version}}</ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>

            </mat-select>
          </mat-form-field>
        </div>
      </form>
</div>

<div class="st-button-row">
  <button mat-button mat-dialog-close color="primary">Cancel</button>
  <app-delete-button *ngIf="data?.anot_guide_set_id" text="Delete" (delete)="delete()"></app-delete-button>
  <app-submit-button text="Save" (submit)="saveSet()"
        [disabled]="!form?.valid" [loading]="saving"></app-submit-button>
</div>
