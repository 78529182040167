<div class="st-customer-background">
  <div class="auth-card-wrapper wide">
    <mat-card class="mat-elevation-z5">
      <app-powered-by>
        <app-logo logoSelector="auth_page"></app-logo>
      </app-powered-by>

      <mat-card-content>
        <p>
          Please open the two-factor authentication app on your device, and enter your code.
        </p>

        <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>

        <app-otp-input (onOtpChange)="onOtpChange($event)"></app-otp-input>

        <div class="st-button-row">
          <button
            mat-raised-button
            color="primary"
            [disabled]="!canSubmit || submitting"
            (click)="submit()"
          >Confirm</button>
        </div>
      </mat-card-content>

    </mat-card>

    <app-note *ngIf="isIEOrEdge()">
      <p>
        We notice you're using Edge or IE, we recommend using
        <span class="st-highlight">Chrome</span>
        as performance will be much improved.<br>
        Using Edge or IE may result in unexpected component behavior.
      </p>
    </app-note>

    <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
  </div>
</div>
