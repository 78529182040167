import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'
import * as moment from 'moment'
import * as wkt from 'terraformer-wkt-parser'
import { flattenDeep } from 'lodash'
import AppError, { handleError } from '@core/models/app-error'
import { Geometry } from 'geojson'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { TypedFormControl, TypedFormGroup } from '@core/models/typed-form-control'

export default class FormValidators {
  static cloneFormConfig (to: UntypedFormGroup, from: UntypedFormGroup, controlKeys?: string[]) {
    if (!to || !from) return
    try {
      if (!controlKeys || !controlKeys.length) controlKeys = Object.keys(from.controls)
      for (const key of controlKeys) {
        const control = from.controls[key]
        if (!to.controls[key]) {
          if (control) {
            let newControl: UntypedFormGroup | TypedFormGroup<any> | UntypedFormControl | TypedFormControl<any>
            if (control instanceof TypedFormGroup) newControl = new TypedFormGroup({})
            else if (control instanceof TypedFormControl) newControl = new TypedFormControl()
            else if (control instanceof UntypedFormGroup) newControl = new UntypedFormGroup({})
            else if (control instanceof UntypedFormControl) newControl = new UntypedFormControl()
            else throw new AppError(`Unsupported type of AbstractControl '${control}'`)

            if (control instanceof UntypedFormGroup) {
              FormValidators.cloneFormConfig(newControl as UntypedFormGroup, control, Object.keys(control.controls))
            } else {
              newControl.setValue(control.value)
              newControl.updateValueAndValidity()
            }

            newControl.setValidators(control.validator)
            newControl.setAsyncValidators(control.asyncValidator)

            to.addControl(key, newControl)
          }
        } else {
          if (control) {
            if (control instanceof UntypedFormGroup) {
              FormValidators.cloneFormConfig(to.controls[key] as UntypedFormGroup, control, Object.keys(control.controls))
            } else {
              to.controls[key].setValue(control.value)
              to.controls[key].updateValueAndValidity()
            }

            to.controls[key].setValidators(control.validator)
            to.controls[key].setAsyncValidators(control.asyncValidator)
          } else {
            to.removeControl(key)
          }
        }
      }
    } catch (error: any) {
      console.warn(error)
    }
  }

  static minLength (length: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value && !FormValidators.ControlIsRequired(control)) {
        return null
      }

      if (Array.isArray(control.value) && control.value.length >= length) {
        return null
      }

      if (typeof control.value === 'string' && control.value.length >= length) {
        return null
      }

      return {
        minLength: {
          valid: false
        }
      }
    }
  }

  static dateValid (control: AbstractControl): ValidationErrors | null {
    return moment(control.value).isValid() ? null : {
      dateValid: {
        valid: false
      }
    }
  }

  static numberValid (control: AbstractControl): ValidationErrors | null {
    return /[0-9]*.?[0-9]*/.test(control.value) ? null : {
      numberValid: {
        valid: false
      }
    }
  }

  static notSystemColumn (control: AbstractControl): ValidationErrors | null {
    return !Columns.System.includes(control.value) ? null : {
      notSystemColumn: {
        valid: false
      }
    }
  }

  static matSelectInput = Symbol('matSelectInput')
  static customSelectOption (controlName: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectControl = control.parent && control.parent.controls[controlName] as AbstractControl
      if (!selectControl) {
        return {
          customSelectOption: {
            valid: false,
            message: `Select control is not defined.`
          }
        }
      }

      // Select control must have option with property 'value'
      if (!selectControl.value || !selectControl.value[FormValidators.matSelectInput]) return null

      if (!control.value && FormValidators.ControlIsRequired(selectControl)) {
        return {
          customSelectOption: {
            valid: false,
            message: `This field is required.`
          }
        }
      }

      return null
    }
  }

  static numberArrayValid(numberOfLayers: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!control.value) return null
      try {
        if (control.value.length === numberOfLayers){
          return null
        } else {
          return {
            entries: {
              valid: false,
              message: 'Record count results do not match the number of layers counted.'
            }
          }
        }
      } catch (error: any) {
        return {
          entries: {
            valid: false,
            message: 'Record count cannot determine platform stability.'
          }
        }
      }
    }
  }

  static wktValid (allowedGeom?: string | string[]) {
    const allowedGeoms = !allowedGeom ? undefined : (
      Array.isArray(allowedGeom) ? allowedGeom : [allowedGeom]
    )
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null
      const value = control.value.startsWith('SRID') ? control.value.split(';')[1] : control.value
      let coordinates
      try {
        const geom: Geometry | any = wkt.parse(value)
        if (allowedGeoms && !allowedGeoms.includes(geom.type)) {
          return {
            wktValid: {
              valid: false,
              message: `Invalid WKT Geometry. Only '${allowedGeoms.join(', ')}' allowed.`
            }
          }
        }
        coordinates = geom.coordinates
      } catch (error: any) {
        return {
          wktValid: {
            valid: false,
            message: 'Invalid WKT format. Geometries should contain valid coordinates and geometry names have to be uppercase.'
          }
        }
      }

      try {
        if (!Array.isArray(coordinates)) throw new AppError('Wkt contains 0 coordinates.')
        const flatCoords: number[] = flattenDeep(coordinates)
        for (let i = 0; i < flatCoords.length; i++) {
          const coord = flatCoords[i]
          if (i % 2 === 0) {
            if (coord < -180 || coord > 180) throw new AppError(`Invalid longitude ${coord}.`)
          } else {
            if (coord < -90 || coord > 90) throw new AppError(`Invalid latitude ${coord}.`)
          }
        }
        return null
      } catch (error: any) {
        handleError(error)
        return {
          wktValid: {
            valid: false,
            message: error.message
          }
        }
      }
    }
  }

  // Only this control, or one of others in list can be defined at same time
  static onlyOneOf (mainControl: string, otherControls: string[], requiredIfOtherUndefined: boolean | (() => boolean)): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let error: boolean = false

      const required = requiredIfOtherUndefined instanceof Function ? requiredIfOtherUndefined() : requiredIfOtherUndefined
      const mainC = control.get(mainControl)
      const mainDefined = mainC && mainC.value
      const otherDefined = otherControls.some(x => {
        const c = control.get(x)
        return c && c.value
      })

      if (mainDefined && otherDefined) {
        error = true
      } else if (!mainDefined && required && !otherDefined) {
        error = true
      }

      return !error ? null : {
        onlyOneOf: {
          valid: false
        }
      }
    }
  }

  static atLeastOneOf (controls: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const defined = controls.some(x => {
        const c = control.get(x)
        return !!(c && c.value)
      })

      return defined ? null : {
        atLeastOneOf: {
          valid: false
        }
      }
    }
  }

  static allOrNone (controls: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const some = controls.some(x => {
        const c = control.get(x)
        return !!(c && c.value)
      })
      const all = some && controls.every(x => {
        const c = control.get(x)
        return !!(c && c.value)
      })

      return !some ? null :
       all ? null : {
         atLeastOneOf: {
           valid: false
         }
       }
    }
  }

  static specialcharValidator(control: AbstractControl):  ValidationErrors | null {
    const Regexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      return control.value && Regexp.test(control.value) ? { invalid: true } : null
  }

  private static ControlIsRequired (control: AbstractControl) {
    const validator = control.validator && control.validator({} as AbstractControl)
    return !validator || !!validator['required']
  }
}

