import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import * as moment from 'moment'
import { debounceTime } from 'rxjs/operators'
import { AuthService, SettingsService, VipApiService } from '@services/core'
import { IRCustomerExtended } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { Subscription } from 'rxjs'
import { BugReportComponent} from '@pages/workspace/menu/submenu/bug-report/bug-report.component'
import { ViewportService } from '@services/core/viewport/viewport.service'
import { WorkspaceEditDialogComponent } from '@pages/explorer/route/workspace-edit-dialog/workspace-edit-dialog.component'
import { IWorkspaceEditDialogBody } from '@core/types'
import { WorkspaceAccessDialogComponent } from '@pages/explorer/route/workspace-grid/workspace-access-dialog/workspace-access-dialog.component'
import { DatasetManagerDialogComponent } from '@pages/explorer/route/dataset-manager-dialog/dataset-manager-dialog.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { WorkspaceFilterDialogComponent } from '@pages/explorer/route/workspace-filter-dialog/workspace-filter-dialog.component'
import { FormTemplate } from '@core/models/form-template'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { API } from 'vip-shared/dist/interfaces/api-helper'
import { WorkspacesService } from '@services/explorer'
import { HighlightService } from '@services/core/highlight/highlight.service'

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})

export class UserHeaderComponent extends FormTemplate<UntypedFormGroup> implements OnInit, OnDestroy, DialogCleanup {
    // DialogCleanup mixins
    _dialogs?: MatDialogRef<any>[]
    _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
    _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
    _destroyDialogs (): any { return }
    canCreateWorkspace = false
  private _subscriptions = new Subscription()
  @Input() layout: 'default' | 'user-panel' = 'default'
  @Output() menuClosed = new EventEmitter<boolean>()
  companyName: string | undefined
  horizontalHeader = false

  warnings: {
    name: string
    tooltip?: string
  }[] = []
  get fullName (): string {
    return this._authService.fullName
  }

  get staging () {
    return this._settingsService.staging
  }

  get logoHeight () {
    return `${!this.horizontalHeader ? 50 : (this.compact ? 30 : 20)}%`
  }

  get sysMaintainer (): boolean {
    return this._authService.isSysMaintainer
  }

  get filters (): API.Qry.WorkspaceFilters {
    const filters: API.Qry.WorkspaceFilters = {}
    if (this.form.value.searchText) filters.search = this.form.value.searchText

    return filters
  }

  menuExpanded = false
  compact = false

  constructor (
        private _authService: AuthService,
        private _settingsService: SettingsService,
        private _viewportService: ViewportService,
        private _apiService: VipApiService,
        private _dialog: MatDialog,
        private _workspacesService: WorkspacesService,
        private _highlightService: HighlightService
    ) {
      super(new UntypedFormGroup({
        searchText: new UntypedFormControl('')
      }))
      this._subscriptions.add(
        this.form.valueChanges.pipe(debounceTime(100)).subscribe((e) => {
          this._workspacesService.loadWorkspaces(this.filters)
          this._highlightService.highlight(this.form.value.searchText)
        })
      )
      this.CheckWorkspaceCreateFeature()
    }

  ngOnInit () {
    this._subscriptions.add(
      this._authService.sessionActive
        .pipe(debounceTime(200))
        .subscribe(active => active && this.updateHeader())
    )

    this._subscriptions.add(
      this._viewportService.sizeChange.subscribe(() => {
        this.compact = this._viewportService.viewportIs('lt', 'm')
        if (!this.compact) this.menuExpanded = false
      })
    )
  }
  openReportForm () {
    const dialog = this._trackDialog(
      this._dialog.open(BugReportComponent)
    )
    this._subscriptions.add(dialog.afterClosed().subscribe(() => this.menuClosed.emit(true)))
  }

  ngOnDestroy () {
    this._destroyDialogs()
    this._subscriptions.unsubscribe()
  }

  async updateHeader () {
    this.warnings = []
    this.horizontalHeader = false
    const customer = this._authService.customer
    if (customer) {
      if (customer.theme && customer.theme.header_logo) {
        this.horizontalHeader = !!customer.theme.header_logo.height
      }

      const extended: IRCustomerExtended = customer
      if (extended.auto_email_state === Db.Helper.Cst.DomainState.ACTIVE) {
        if (extended.auto_email_daily_limit && (extended.auto_email_daily_usage || 0) >= extended.auto_email_daily_limit) {
          this.warnings.push({
            name: 'Daily email limit reached.',
            tooltip: 'All attempts to send emails from this subdomain will fail, preventing password resets and new account/API token creation. Wait till email usage is reset at midnight UTC time or ask Geospatial Insight about increasing the daily limit.'
          })
        }
      }

    }
    this.companyName = this._authService.companyName
  }

  onLogout () {
    this._authService.logout()
  }

  createNewWorkspace () {
    this._trackDialog(
      this._dialog.open(WorkspaceEditDialogComponent, {
        data: {
          mode: 'create'
        } as IWorkspaceEditDialogBody
      })
    )
    .afterClosed().subscribe((data: number | Object) => {
      if (data instanceof Object) {
        // For system admin open access editing tool straight away
        if (this._authService.isSysAdmin) {
          this._trackDialog(
            this._dialog.open(WorkspaceAccessDialogComponent, {
              data
            })
          )
        }
      }
    })
  }

  openDatasetManager () {
    this._trackDialog(
      this._dialog.open(DatasetManagerDialogComponent)
    )
  }

  openFilterDialog () {
    this._trackDialog(
      this._dialog.open(WorkspaceFilterDialogComponent, {
        data: {
          mode: 'create'
        } as IWorkspaceEditDialogBody
      })
    )
  }

  private async CheckWorkspaceCreateFeature () {
    this.canCreateWorkspace = this.sysMaintainer ||
    (
      this._authService.permissionsLevel <= Db.Vip.Role.CUST_ADMIN_BASIC ?
        (await this._apiService.orm.Customers().getProducts().run()).length > 0 :
        false
    )
  }

}
