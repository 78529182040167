import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { PromptDialogComponent } from '@components/prompt-dialog/prompt-dialog.component'
@Injectable({
  providedIn: 'root'
})
export class PromptService {
  private _dialogRef ?: MatDialogRef < PromptDialogComponent >
  constructor (
        private _dialog: MatDialog
    ) {}

  prompt (content: string, actions: any, onDismiss?: () => void): MatDialogRef< PromptDialogComponent > {
    return this._dialog.open(PromptDialogComponent, PromptDialogComponent.setOptions(content, actions, onDismiss))
  }

}
