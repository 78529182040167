<div class="toggle-wrap">
    <div class="slider" [@sliderToggle]="enabled ? 'on' : 'off'"></div>
    <div class="label-wrap">
        <span>
            <ng-content></ng-content>
        </span>:
    </div>
    <div class="input-wrap" (click)="enabled && $event.stopPropagation()">
      <input *ngIf="enabled" [value]="value" (input)="changeValue($event.target.value)" type="number" [min]="min" [max]="max" [step]="step">
      <input *ngIf="!enabled" value="OFF" [disabled]="true">
    </div>
</div>
