<h2 mat-dialog-title>New Annotation Guide</h2>

<div mat-dialog-content>
  <form #formComponent [formGroup]="form" class="st-single-row-form">

    <p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>

    <mat-form-field>
      <input matInput type="text" formControlName="name" placeholder="Name" cdkFocusInitial/>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="url" formControlName="location_url" placeholder="Location URL" />
    </mat-form-field>

    <mat-form-field>
      <input matInput type="number" formControlName="version" placeholder="Version" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Date" [value]="date" (dateChange)="dateChange($event)" [matDatepicker]="picker" [max]="today" (click)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</div>

<div class="st-button-row">
  <button type="button" mat-button [mat-dialog-close]>Close</button>
  <app-submit-button text="Create" [disabled]="!form?.valid" [loading]="saving" (submit)="save()"></app-submit-button>
</div>
