import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HighlightService {
  private _textChange = new BehaviorSubject<string | undefined>(undefined)
  expression?: string
  get textChanged (): Observable<string | undefined> {
    return this._textChange.asObservable()
  }

  highlight (text?: string) {
    if (text === this.expression) return
    this.expression = text
    this._textChange.next(text)
  }
}
