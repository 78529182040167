import {Component,Inject,Input,OnDestroy,OnInit} from '@angular/core'
import * as Croppie from 'croppie'
import { Subscription, fromEvent } from 'rxjs'
import { auditTime } from 'rxjs/operators'
import { AlertService, VipApiService } from '@services/core'
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { environment } from 'environments/environment'

export interface DialogData {
  assetUrl?: string
}

@Component({
  selector: 'app-zoom-panel',
  templateUrl: './zoom-panel.component.html',
  styleUrls: ['./zoom-panel.component.scss']
})

export class ZoomPanelComponent implements OnDestroy, OnInit {
  private _subscriptions = new Subscription()

  @Input() source?: string

  urlToLoad?: string
  croppieObj: Croppie

  zoomedIn = false

  boundaryW = 700
  boundaryH = 500

  indicatorWrapperWidth = 0
  indicatorWrapperHeight = 0

  indicatorWidth = 0
  indicatorHeight = 0
  indicatorPt = 0
  indicatorPl = 0

  loaded = false

  static setup (data: DialogData): MatDialogConfig {
    return {
      data
    }
  }

  constructor (
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private _alertService: AlertService,
    private _dialogRef: MatDialogRef<ZoomPanelComponent>,
    private _api: VipApiService
  ) {}

  ngOnInit () {

    if(this.data && this.data.assetUrl) {
      this.source = this.data.assetUrl
    }

    if (!this.source) {
      this._alertService.log('Image not found.')
      this._dialogRef.close()
      return
    }

    if (this.source.startsWith(environment.VIPServer)) {
      this.urlToLoad = this.source
    } else if (this.source.startsWith('assets')) {
      this.urlToLoad = this.source
    } else {
      this.urlToLoad = `${environment.VIPServer}${
        this._api.orm.Proxy(
          encodeURI(
            Base64.encodeURI(this.source)
          )
        ).get().endpoint
      }`
    }
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  onError () {
    this.loaded = true
    this._alertService.log('Failed to load image from URL.')
    this._dialogRef.close()
  }

  async renderCroppie (img: HTMLImageElement) {
    const imageRatio = (img.naturalHeight / img.naturalWidth)
    let viewportW: number
    let viewportH: number

    if ((this.boundaryW * imageRatio > this.boundaryH)) {
      viewportW = this.boundaryH / imageRatio
      viewportH = this.boundaryH
    } else {
      viewportW = this.boundaryW
      viewportH = this.boundaryW * imageRatio
    }

    this.croppieObj = new Croppie(img, {
      viewport: {
        width: viewportW,
        height: viewportH
      },
      boundary: {
        width: this.boundaryW,
        height: this.boundaryH
      },
      enableOrientation: true,
      enforceBoundary: true
    })

    this.croppieObj.bind({
      url: img.src,
      zoom: 0
    })

    this._subscriptions.add(
      fromEvent(this.croppieObj.element, 'update')
      .pipe(
        auditTime(50)
      )
      .subscribe(this.RedrawIndicator.bind(this))
    )

    this._subscriptions.add(
      fromEvent(this.croppieObj.element, 'error')
      .subscribe(this.onError.bind(this))
    )
  }

  private RedrawIndicator (e: any) {
    if (!this.loaded) this.loaded = true
    const cropData = e.detail.points

    // Ignore if cropData is less than 0.5% of size because croppie gets stuck on ~0.5%
    // if image has been moved, but should have 0
    const minX = Math.max(1, this.croppieObj._originalImageWidth * 0.005)
    const minY = Math.max(1, this.croppieObj._originalImageHeight * 0.005)
    const maxX = this.croppieObj._originalImageWidth - minX
    const maxY = this.croppieObj._originalImageHeight - minY

    const [fromX, fromY, toX, toY] = cropData.map((d: string) => +d)

    if (fromX <= minX && fromY <= minY && toX >= maxX && toY >= maxY) {
      this.zoomedIn = false
      return
    }

    this.zoomedIn = true

    const boundaryEl = this.croppieObj.elements.boundary as HTMLElement
    const imageEl = this.croppieObj.elements.preview as HTMLElement

    const indicatorWrapper = {
      width: 700 * 0.3,
      height: 500 * 0.3
    }

    const imageBox = this.CloneBox(imageEl.getBoundingClientRect())
    const boundary = this.CloneBox(boundaryEl.getBoundingClientRect())

    // Get ratio of expected indicator wrapper size
    const ratio = indicatorWrapper.width / indicatorWrapper.height

    // Adjust image boundary box reference to match the expected ratio
    // this will account for empty edges in viewport
    const stepSize = 1
    let imageRatio = imageBox.width / imageBox.height
    const middleX = (box) => box.left + box.width / 2
    const middleY = (box) => box.top + box.height / 2
    while (Math.abs(ratio - imageRatio) > 0.001) {
      if (imageRatio < ratio) {
        imageBox.width += stepSize * 2
        imageBox.left -= stepSize
        imageBox.right += stepSize
        const mid = middleX(imageBox)
        const targetMid = middleX(boundary)
        if (targetMid < mid) {
          boundary.left -= stepSize / 2
        } else if (targetMid > mid) {
          boundary.left += stepSize / 2
        }
      } else {
        imageBox.height += stepSize * 2
        imageBox.top -= stepSize
        imageBox.bottom += stepSize
        const mid = middleY(imageBox)
        const targetMid = middleY(boundary)
        if (targetMid < mid) {
          boundary.top -= stepSize / 2
        } else if (targetMid > mid) {
          boundary.top += stepSize / 2
        }
      }
      imageRatio = imageBox.width / imageBox.height
    }

    const relativeBox = this.GetRelativeClientRect(imageBox, boundary)

    // Scale down reference size of image html element and visible boundary
    // to get preview indicator/inner rectangle size and position
    const divider = {
      x: imageBox.width / indicatorWrapper.width,
      y: imageBox.height / indicatorWrapper.height
    }

    this.indicatorWrapperWidth = indicatorWrapper.width
    this.indicatorWrapperHeight = indicatorWrapper.height

    this.indicatorWidth = relativeBox.width / divider.x
    this.indicatorHeight = relativeBox.height / divider.y
    this.indicatorPl = relativeBox.left / divider.x
    this.indicatorPt = relativeBox.top / divider.y
  }

  private CloneBox (rect: ClientRect) {
    // Clone it to allow modifying the attributes
    return {
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height,
      bottom: rect.bottom,
      right: rect.right
    }
  }

  private GetRelativeClientRect (parent, target): any {
    return {
      width: target.width,
      height: target.height,
      top: target.top - parent.top,
      bottom: target.bottom - parent.bottom,
      left: target.left - parent.left,
      right: target.right - parent.right
    }
  }
}
