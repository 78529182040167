import { Component, OnInit, Optional, Inject, ChangeDetectorRef } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'
import { ColorPickerService } from 'ngx-color-picker'
import { StyleHistoryService } from '@services/workspace/style-history/style-history.service'
import { IStyleHistoryElement } from '@core/types'
import * as Color from 'color'

@Component({
  selector: 'app-solid-color-picker-dialog',
  templateUrl: './solid-color-picker-dialog.component.html',
  styleUrls: ['./solid-color-picker-dialog.component.scss']
})
export class SolidColorPickerDialogComponent implements OnInit {

  styleHistory: IStyleHistoryElement[] = []
  rgb?: {
    r: number,
    g: number,
    b: number
  }

  get hexColor () {
    return this.color
  }
  set hexColor (val: string) {
    if (val.startsWith('#')) {
      try {
        const toHex = Color(val).hex().toLowerCase()
        if (
          toHex.startsWith(val) &&
          (val.length === 4 || val.length === 7)
        ) {
          this.color = val
        }
      } catch (error: any) {
        // silent fail
      }
    }
  }
  constructor (
    @Optional() @Inject(MAT_DIALOG_DATA) public color: string,
    private _dialogRef: MatDialogRef<SolidColorPickerDialogComponent, any>,
    private _cps: ColorPickerService,
    private _styleHistoryService: StyleHistoryService
  ) {}

  static setOptions (color: string): MatDialogConfig {
    return {
      hasBackdrop: false,
      panelClass: 'color-picker-dialog',
      data: Color(color).hex()
    }
  }

  ngOnInit () {
    this.styleHistory = this._styleHistoryService.getSolids().reverse()
  }

  historyStyleToStyle (el: IStyleHistoryElement) {
    if (el.type === 'solid') {
      return Color(`rgb(${el.color.R}, ${el.color.G}, ${el.color.B})`).hex()
    }
  }

  applyHistoricalStyle (el: IStyleHistoryElement) {
    if (el.type === 'solid') {
      this.color = this._cps.rgbaToHex({
        r: el.color.R,
        g: el.color.G,
        b: el.color.B,
        a: el.color.A
      })
    }
  }

  close () {
    const rgb = Color(this.color).rgb()
    this._styleHistoryService.add({
      type: 'solid',
      color: {
        A: 1,
        R: rgb.red(),
        G: rgb.green(),
        B: rgb.blue()
      }
    })

    this._dialogRef.close(this.color)
  }
}
