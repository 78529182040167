import { Component, Inject, Optional } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Db } from '@vip-shared/models/db-definitions'
import { ThemeService } from '@services/core/theme/theme.service'
import { CommonUtil } from '@core/utils/index'
import * as Color from 'color'

interface AppColor {
  key: string
  name: string
  opacities?: number[]
  value?: string
}

interface AppComponentColor {
  key: string,
  name: string,
  description: string,
  default: string,
  defaultName: string,
  value?: string
}
@Component({
  selector: 'app-theme-editor-dialog',
  templateUrl: './theme-editor-dialog.component.html',
  styleUrls: ['./theme-editor-dialog.component.scss']
})
export class ThemeEditorDialogComponent {
  theme!: NonNullable<Required<Db.Vip.Cst.ICustomer['theme']>>

  defaultColors: AppColor[] = this._themeService.colorKeys

  colors: AppColor[] = this._themeService.colorKeys

  componentColors: AppComponentColor[] = this._themeService.componentColorKeys

  get previewTheme () {
    return this._themeService.previewTheme
  }

  static setup (theme: Db.Vip.Cst.ICustomer['theme'], allowPreviewApply = true) {
    return {
      data: {
        theme,
        allowPreviewApply
      }
    }
  }

  constructor (
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {theme: Db.Vip.Cst.ICustomer['theme'], allowPreviewApply: boolean},
    private _dialogRef: MatDialogRef<ThemeEditorDialogComponent>,
    private _themeService: ThemeService
  ) {
    this.SetTheme(
      CommonUtil.mergeDeep(
        this._themeService.defaultTheme,
        this.data.theme || {}
      )
    )
  }

  previewIsDifferent () {
    const { colors, component_colors } = this.theme
    const previewTheme: NonNullable<Db.Vip.Cst.ICustomer['theme']> = this._themeService.previewTheme || {}
    const { colors: prColors, component_colors: prCmpColors } = previewTheme

    return JSON.stringify(colors) !== JSON.stringify(prColors) ||
      JSON.stringify(component_colors) !== JSON.stringify(prCmpColors)
  }

  private SetTheme (theme: NonNullable<Db.Vip.Cst.ICustomer['theme']>) {
    this.theme = theme as any
    for (const el of this.defaultColors) {
      el.value = this._themeService.defaultTheme.colors[this.GetMainColorKey(el)]
    }

    for (const el of this.colors) {
      const key = this.GetMainColorKey(el)
      el.value = this.theme.colors[key]
      // Fallback to system default if theme color is undefined
      if (!el.value) {
        el.value = this.getSystemDefault(el.key)
        this.theme.colors[key] = el.value
      }
    }

    for (const el of this.componentColors) {
      if (!this.theme.component_colors[el.key]) continue

      const colorA = Color(this.theme.component_colors[el.key])
      const colorB = Color(this.theme.colors[el.default])
      if (colorA.hex() !== colorB.hex()) {
        el.value = this.theme.component_colors[el.key]
      }
    }
  }

  async delete () {
    this._dialogRef.close(null)
  }

  async save () {
    this.generateTheme()
    this._dialogRef.close(this.theme)
  }

  getDefaultFromGeneral (key: string) {
    const match = this.colors.find(x => {
      for (const opacity of x.opacities || [1]) {
        const xKey = `${x.key}${opacity === 1 ? '' : `-${Math.round(opacity * 100)}`}`
        if (xKey === key) return true
      }
    })
    return match && match.value
  }

  getSystemDefault (key: string) {
    const match = this.defaultColors.find(x => x.key === key)
    return match && match.value
  }

  applyPreview () {
    if (this.previewTheme) {
      this.SetTheme(
        Object.assign(this.theme, this.previewTheme)
      )
    }
  }

  private GetMainColorKey (el: AppColor) {
    let key = el.key
    if (el.opacities) {
      const lastOpacity = el.opacities.slice(-1)[0]
      if (lastOpacity !== 1) key += `-${lastOpacity * 100}`
    }
    return key
  }

  notSystemDefault (el: AppComponentColor | AppColor) {
    let log = false
    if (el.key === 'paragraph-highlight') log = true
    let defaultColor: undefined | string
    if (el['default']) {
      const color = el as AppComponentColor

      if (!color.value) return false
      defaultColor = this.getDefaultFromGeneral(color.default)
    } else {
      // Default to system default
      const color = el as AppColor
      defaultColor = this.getSystemDefault(
        this.GetMainColorKey(color)
      )
    }

    if (!el.value || (defaultColor && Color(defaultColor).hex() !== Color(el.value).hex())) {
      return true
    }
  }

  preview () {
    this.generateTheme()
    this._themeService.applyTheme(this.theme, true)
  }

  generateTheme () {
    for (const el of this.colors) {
      if (el.value) {
        const color = Color(el.value)
        for (const opacity of el.opacities || [1]) {
          if (opacity === 1) {
            this.theme.colors[el.key] = `rgb(${color.red()}, ${color.green()}, ${color.blue()})`
          } else {
            const suffix = `${Math.round(opacity * 100)}`
            this.theme.colors[`${el.key}-${suffix}`] = `rgba(${color.red()}, ${color.green()}, ${color.blue()}, ${opacity})`
          }
        }
      }
    }

    for (const el of this.componentColors) {
      if (el.value) {
        const color = Color(el.value)
        this.theme.component_colors[el.key] = `rgba(${color.red()}, ${color.green()}, ${color.blue()}, ${color.alpha()})`
      } else {
        this.theme.component_colors[el.key] = this.theme.colors[el.default]
      }
    }
  }
}
