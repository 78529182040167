<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 240" width="100%">
  <rect class="header-bar" x="0" y="0" width="10" height="100%"></rect>
  <rect class="header-bar" x="10" y="0" width="300" height="10"></rect>

  <foreignObject x="10" y="10" width="300" height="240">
    <xhtml:app-ws-placeholder-map  xmlns="http://www.w3.org/1999/xhtml" [workspaceId]="workspaceId">

    </xhtml:app-ws-placeholder-map>
  </foreignObject>

  <rect class="map-control" x="17" y="173" width="8" height="8" rx="10" ry="10"></rect>
  <rect class="map-control" x="14" y="184" width="15" height="15" rx="10" ry="10"></rect>
  <rect class="map-control" x="16" y="201" width="10" height="5" rx="3" ry="3"></rect>
  <rect class="map-control" x="19" y="208" width="5" height="30" rx="3" ry="3"></rect>
  <rect class="map-control" x="30" y="233" width="30" height="5" rx="3" ry="3"></rect>

  <rect class="header-bar" x="310" y="0" width="70" height="15"></rect>
  <rect class="default-section" x="310" y="20" width="70" height="105"></rect>

  <!--  -->
  <circle class="active-mode" cx="325" cy="30" r="6"></circle>
  <circle cx="325" cy="30" r="3" fill="white"></circle>

  <circle class="inactive-mode" cx="340" cy="30" r="6"></circle>
  <circle cx="340" cy="30" r="3" fill="white"></circle>

  <circle class="inactive-mode" cx="355" cy="30" r="6"></circle>
  <circle cx="355" cy="30" r="3" fill="white"></circle>

  <circle class="inactive-mode" cx="370" cy="30" r="6"></circle>
  <circle cx="370" cy="30" r="3" fill="white"></circle>

  <circle class="start-point" cx="325" cy="60" r="3"></circle>
  <circle class="point" cx="325" cy="70" r="1" fill="white"></circle>
  <circle class="point" cx="325" cy="80" r="3" fill="white"></circle>

  <rect class="foreground" x="335" y="57" width="45" height="5"></rect>
  <rect class="foreground" x="335" y="77" width="45" height="5"></rect>

  <rect class="primary" x="352" y="107" width="25" height="6"></rect>
  <!--  -->

  <rect class="header-bar" x="310" y="125" width="70" height="70"></rect>
  <rect class="header-bar" x="310" y="195" width="70" height="40"></rect>

  <circle class="primary-stroke" cx="347" cy="160" r="12"></circle>
  <circle class="primary" cx="347" cy="170" r="6"></circle>
  <rect class="default-section" x="315" y="157" width="15" height="5"></rect>
  <rect class="default-section" x="363" y="157" width="15" height="5"></rect>
</svg>
