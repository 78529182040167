import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'
import { IFeatureTooltip } from '@core/types/'

@Component({
  selector: 'app-table-tooltip',
  templateUrl: './table-tooltip.component.html',
  styleUrls: ['./table-tooltip.component.scss']
})

// 'Table' Tooltip, because contents must displayed be using grid layout
// and single entry should be in a single cell with cell name
// and cell value

export class TableTooltipComponent {
  maxWidth = '200px'
  cellWidth = 'fit-contents'
  constructor (
    @Inject(MAT_DIALOG_DATA) public tooltip: IFeatureTooltip,
    private _dialogRef: MatDialogRef<TableTooltipComponent>
  ) {
    if (tooltip.grid) {
      // Add 10px for cell padding
      this.maxWidth = `${(tooltip.grid.cellWidth + 10) * Math.round(tooltip.grid.columns)}px`
      this.cellWidth = `${tooltip.grid.cellWidth}px`
    }
  }

  static setOptions (data: IFeatureTooltip, position: {top: number, left: number, anchor: 'middle' | 'left'}, cssClass?: string): MatDialogConfig {
    position.top += 25
    if (position.anchor === 'middle') {
      // Add pixels 10 and 20 for padding
      const maxWidth = (data.grid ? ((data.grid.cellWidth + 10) * data.grid.columns) : 200) + 20
      position.left -= (maxWidth / 2)
    }
    const options: MatDialogConfig = {
      data,
      hasBackdrop: false,
      position: {
        top: `${position.top}px`,
        left: `${position.left}px`
      },
      panelClass: cssClass ? ['table-tooltip', position.anchor, cssClass] : ['table-tooltip', position.anchor]
    }

    return options
  }

}
