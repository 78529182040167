import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core'
import { AttributeTableService, WorkspaceService, LayerService } from '@services/workspace'
import { Subscription, Subject } from 'rxjs'
import { CommonUtil } from '@core/utils/index'
import Feature from 'ol/Feature'
import * as olGeom from 'ol/geom'
import { debounceTime, auditTime } from 'rxjs/operators'
import { IRgba } from '@core/types'
import { AppLayer } from '@core/models/layer'
import { DatatableComponent } from '@swimlane/ngx-datatable'
import { ObjectPlaceholderPipe } from '@core/pipes/object-placeholder.pipe'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { PromptService, AlertService, VipApiService, AuthService } from '@services/core'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import OlUtil from '@core/utils/ol/ol.util'
import { IRVectorColumn, IPVectorTableRow, IPNewVectorColumn, IRVectorColumnType } from '@vip-shared/interfaces'
import { NewAttributeColumnDialogComponent } from '@core/page-components/columns-dialog/new-attribute-column-dialog/new-attribute-column-dialog.component'
import { ContextMenuService } from '@services/core/context-menu/context-menu.service'
import { NewAttributesRowDialogComponent } from '@core/page-components/new-attributes-row-dialog/new-attributes-row-dialog.component'
import AppError, { handleError } from '@core/models/app-error'
import { Db } from '@vip-shared/models/db-definitions'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { NgxTableColumn } from '@core/types/ngx-table-column'
import DevUtil from '@core/utils/dev/dev.util'
import { PVGeojsonProperties } from '@vip-shared/models/layer-config/property-view/pv-geojson-properties'
import { IUpdatePvMl, IUpdatePvAnot } from '@vip-shared/interfaces/property-view/edit-properties'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { AttributeChartComponent } from '@core/page-components/attribute-chart/attribute-chart.component'
import { MatSelectChange, MatSelect } from '@angular/material/select'
import { StatisticQueryBuilderDialogComponent } from '@core/page-components/statistic-query-builder-dialog/statistic-query-builder-dialog.component'
import { EnhancedQueryContainer, EnhancedQueryStatement, IQueryWRef } from '@core/models/query-object'
import { UnreachableCaseError } from '@vip-shared/generics/exhaustive-switch'
import { CtxUIMenuItem } from '@core/types/workspace/map/ws-ctx-menu-conf'
import { AppFeature } from '@core/models/layer/ol-extend/app-feature'
import { cloneDeep } from 'lodash'
import BaseLayer from 'ol/layer/Base'
import { DashboardApiService } from '@services/dashboard/dashboard-api.service'
import { DashboardLayoutService } from '@services/dashboard/dashboard-layout.service'
import { ResizeSensor } from 'css-element-queries'
import * as moment from 'moment'

enum TableViewMode {
  DEFAULT = 'Default',
  SELECTED_ONLY = 'Selected Only'
}

interface NgxTableRow {
  [Columns.VectorFid]?: number
  geom_id?: string
  _originalIndex: number
  _feature: AppFeature
  _geom?: olGeom.Geometry
  _selected: boolean
  _style: {
    fill: string
    stroke: string
  }
  [key: string]: any
}
interface RowStyleMap {
  fill: string
  stroke: string
}

// TODO: create types for layer properties
interface ICustomColour {
  fill?: {
    active: boolean
    color: IRgba
    id: any
  }[]
  border?: {
    active: boolean
    color: IRgba
    id: any
  }[]
}

interface NgxStatTableRow {
  query: string,
  column: string,
  operator: string,
  result: string,
  details: IQueryWRef
}

enum Tab {
  Attributes = 'attributesTab',
  Statistics = 'statisticsTab'
}

@Component({
  selector: 'app-attr-table-widget',
  templateUrl: './attr-table-widget.component.html',
  styleUrls: ['./attr-table-widget.component.scss']
})
export class AttributeTableWidgetComponent implements OnInit, OnDestroy, DialogCleanup, AfterViewInit {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  layerId: string = 'default'
  _trackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs(): any { return }

  @ViewChild('cellTemplate', { static: true }) cellTemplate!: TemplateRef<any>
  @ViewChild('colorCellTemplate', { static: true }) colorCellTemplate!: TemplateRef<any>
  @ViewChild('checkboxCellTemplate', { static: true }) checkboxCellTemplate!: TemplateRef<any>
  @ViewChild('checkboxHeaderTemplate', { static: true }) checkboxHeaderTemplate!: TemplateRef<any>

  @ViewChild('table', { static: true }) ngxTable!: DatatableComponent
  @ViewChild('attributeTable', { static: false }) attributeTable!: ElementRef<HTMLElement>

  sideNavOpened: boolean = false
  selectedTab?: Tab

  viewModes = Object.values(TableViewMode)
  selectedViewMode = TableViewMode.DEFAULT
  infoTooltip: string = 'Time information not available'
  readonly _selectedOnlyFilterId = 'attribute-table-selected-only'

  readonly availableTab = Tab
  get availableTabs() {
    return Object.keys(Tab)
  }

  readonly _indicatorCol = '_indicator'
  readonly _checkboxCol = '_selected'
  private _subscriptions = new Subscription()
  private _filterColumns = new Subject<boolean>()
  private _featureList?: Feature[]
  private readonly _defaultColWidth = 150
  private readonly _minColWidth = 35
  private _layerSub?: Subscription
  private _resizeSensor?: ResizeSensor
  private _resizeDebouncer = new Subject()
  private _interval: any

  private _reRenderTableTimeout?: ReturnType<typeof setTimeout>
  private _reRenderQueryTableTimeout?: ReturnType<typeof setTimeout>

  get geomId() {
    return (this.targetLayer && this.targetLayer.idKey) || ''
  }

  private _lastSelectedRow?: NgxTableRow

  private _tableReloaded = new Subject()

  private _unsavedEdits: {
    [key: string]: Set<string>
  } = {}
  cellEditState: {
    [key: string]: boolean
  } = {}

  targetLayer?: AppLayer
  reloading = false
  someChecked: boolean | null = false
  applyingChanges = false

  headerColumns?: any[]
  columnMetadata?: IRVectorColumn[]
  displayedColumns?: string[]
  availableColumns?: NgxTableColumn[]

  dataSource?: {
    columns: NgxTableColumn[]
    rows: NgxTableRow[]
    selected: NgxTableRow[]
  }
  // For quicker access
  private _rowMap: {
    [idKey: string]: NgxTableRow
  } = {}

  displayedQueryColumns?: NgxTableColumn[]
  querySource?: {
    columns: NgxTableColumn[]
    rows: NgxStatTableRow[]
  }

  styles?: RowStyleMap[]
  statQueryModel: NgxStatTableRow[] = []
  data: any[] = [{}]
  renderStatisticTable: boolean = true
  orderChanged: boolean = false

  _enhancedQueryList: IQueryWRef[] = []

  dropdownOptions: {
    [key: string]: {
      multiple?: boolean
      required?: boolean
      options: string[]
    }
  } = {}

  // This is used to stop table re-selecting a dropdown after save
  noReselect?: boolean
  tableWidth?: number
  sampled: boolean = false
  downloadingCsv: boolean = false
  countingCsv: boolean = false
  downLoadLayer?: AppLayer = undefined

  get visible() {
    return this._attributeTableService.visible && this._attributeTableService.enabled
  }

  get tableCollapsed(): boolean {
    return this._attributeTableService.collapsed
  }

  get hasData(): boolean {
    return !!(this.dataSource && this.dataSource.rows && this.dataSource.rows.length > 0)
  }

  get renderedRowCount(): number {
    return this.dataSource ? this.dataSource.rows.filter(r => r._feature.rendered).length : 0
  }

  get hasVisibleColumns(): boolean {
    return !!this.dataSource && this.dataSource.columns.filter(x => !x.prop.startsWith('_')).length > 0
  }

  get sysMaintainer(): boolean {
    return this._authService.isSysMaintainer
  }

  get selectPlaceholder() {
    return (this.availableColumns && this.availableColumns.length) ? 'Click to select' : 'No columns available'
  }

  get hasEdits(): boolean {
    return Object.keys(this._unsavedEdits).length > 0
  }

  get multiSelect(): boolean {
    return !!this.dataSource && this.dataSource.selected.length > 1
  }

  get canEdit(): boolean {
    const presetLayer = this.targetLayer && this.targetLayer.preset
    return !presetLayer
  }

  get readOnly() {
    return !this.sysMaintainer && this._workspaceService.readOnly
  }

  get hasMutableColumns(): boolean {
    const columns = this.targetLayer && this.targetLayer.attributeColumns
    return !!columns && columns.some(x => x.display_attribute_table && !Columns.System.includes(x.prop))
  }

  get locked(): boolean {
    return !!this.targetLayer && this._attributeTableService.locked
  }

  get editMode(): boolean {
    return this._attributeTableService.editMode || this.presetEditLock
  }

  get lockTable(): boolean {
    // Do not allow editing table if it's preset and dataset is being loaded
    const presetLock = this.targetLayer && this.targetLayer.preset && this.targetLayer.loading

    return !!presetLock
  }

  get presetEditLock(): boolean {
    return !!this.targetLayer && this.targetLayer.inEditMode
  }

  get presetEditModeCanEditGeometry(): boolean {
    return this.presetEditLock && !!this.targetLayer &&
      [Db.Vip.LayerMode.ANOT_QA, Db.Vip.LayerMode.ML_HE, Db.Vip.LayerMode.ML_QA]
        .includes(this.targetLayer.layerMode as Db.Vip.LayerMode)
  }

  get renderedRows(): NgxTableRow[] {
    // Use this.ngxTable.bodyComponent.rows - because only they are sorted if sorting is enabled
    return this.ngxTable.bodyComponent.rows
  }

  get renderedRowsCount(): boolean {
    return this.ngxTable.bodyComponent.rows.length ? true : false
  }

  get showAnnotationSelection() {
    return this.targetLayer &&
      [Db.Vip.LayerMode.ANOT, Db.Vip.LayerMode.ANOT_QA].includes(this.targetLayer.layerMode as any)
  }

  get isAttributeTab() {
    return this.selectedTab === this.availableTab.Attributes
  }

  get isStatisticsTab() {
    return this.selectedTab === this.availableTab.Statistics
  }

  constructor(
    private _attributeTableService: AttributeTableService,
    private _dashboardApiService: DashboardApiService,
    private _layerQueriesService: LayerQueriesService,
    private _objectPlaceholderPipe: ObjectPlaceholderPipe,
    private _promptService: PromptService,
    private _alertService: AlertService,
    private _workspaceService: WorkspaceService,
    private _dialog: MatDialog,
    private _contextMenu: ContextMenuService,
    private _changeDet: ChangeDetectorRef,
    private _api: VipApiService,
    private _authService: AuthService,
    private _layerService: LayerService,
    private _dashboardLayoutService: DashboardLayoutService

  ) {
    this._layerService.newLayer.subscribe(async layer => {
      const targetId = this.layerId.toString()
      if (layer.layer instanceof BaseLayer && layer.id === targetId) {
        this.targetLayer = layer
        if (this.targetLayer.isTimeSeries) {
          const from = moment(this.targetLayer.renderedTimeSeriesSelection?.date_range.from).format('YYYY-MM-DD HH:mm:ss')
          const to = moment(this.targetLayer.renderedTimeSeriesSelection?.date_range.to).format('YYYY-MM-DD HH:mm:ss')
          this.infoTooltip = `Time range: ${from} - ${to}`
        }
        this.LoadData(true)
      }
    })
  }

  ngAfterViewInit() {
    this.tableWidth = this.ngxTable.element.clientWidth
    this.setLayerTarget()
    this._changeDet.detectChanges()
  }

  async setLayerTarget() {
    const wsId = this._dashboardApiService.workspaceId
    if (!this._workspaceService.workspaceId) await this._workspaceService.loadWorkspace(wsId)
    this.targetLayer = await this._layerService.getById(this.layerId)
    this.LoadData(true)
  }

  ngOnInit() {
    this._layerQueriesService.init()
    this.LoadData(true)

    this._subscriptions.add(
      this._filterColumns
        .asObservable()
        .pipe(debounceTime(750))
        .subscribe(updateSize => this.UpdateColumns(updateSize))
    )

    this._subscriptions.add(
      this._attributeTableService.featuresColorChanged
        .pipe(auditTime(100))
        .subscribe(this.MapRowStyles.bind(this))
    )

    this._subscriptions.add(
      this._layerQueriesService.filterChange.subscribe(layer => {
        if (this.targetLayer === layer) this.LoadData(true)
      })
    )

    // this._subscriptions.add(
    //   this._attributeTableService.tableRefresh.subscribe(() =>
    //     // this.ReloadDataset()
    //   )
    // )

    this._subscriptions.add(
      this._attributeTableService.rowsRefresh.subscribe(e => {
        if (e.layer !== this.targetLayer) return
        this.ReloadRows(e.features)
      })
    )

    this._subscriptions.add(
      this._attributeTableService.rowsRemoved.subscribe(e => {
        if (e.layer !== this.targetLayer) return
        this.RemoveRows(e.features)
      })
    )

    this._subscriptions.add(
      this._attributeTableService.selectItemsInTable.subscribe(f =>
        this.FindTableItems(f)
      )
    )

    this._subscriptions.add(
      this._attributeTableService.visibleChange.subscribe(visible => {
        if (visible) this.ReloadDataset()
        else this.ToggleSelectedOnlyMode(false, true)
      })
    )


    this.switchToDefaultTab()

    this._subscriptions.add(
      this._layerQueriesService.queryListChanged.subscribe(() => {
        this.updateStatisticQueries()
      })
    )

    this._subscriptions.add(
      this._dashboardLayoutService.mapExentFts.pipe(auditTime(100)).subscribe(async (o) => {
        if (!this.targetLayer) return
        if ((o.layer.id !== this.targetLayer.id) || !o.extent) return
        const layerSub = this.targetLayer.reloaded.subscribe(async fts => {
          if (fts) {
            await this.ReloadRows(fts)
            this.ReloadDataset()
          }
          layerSub.unsubscribe()
        })
        if (o.reload) {
          await this.targetLayer.reload()
          await this.LoadData(false)
        } else {
          await this.targetLayer.getFeaturesInExtent(o.extent)
        }
      })
    )

    this._resizeSensor = new ResizeSensor(
      this.ngxTable.element,
      () => this._resizeDebouncer.next({})
    )
    this.resetSensor(this._resizeSensor)
    this._subscriptions.add(
      this._resizeDebouncer
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.ngxTable.recalculateDims()
      })
    )
  }

  ngOnDestroy() {
    this.ToggleSelectedOnlyMode(false)
    this._subscriptions.unsubscribe()
    if (this._resizeSensor) this._resizeSensor.detach()
    if (this._interval) clearInterval(this._interval)
    this._destroyDialogs()
  }

  viewModeChange(event: MatSelectChange) {
    this.ToggleSelectedOnlyMode(event.value === TableViewMode.SELECTED_ONLY)
  }

  private ToggleSelectedOnlyMode(enabled: boolean, resetMode = false) {
    if (!this.targetLayer) return
    if (!enabled && resetMode) this.selectedViewMode = TableViewMode.DEFAULT
    const features = this.targetLayer.features

    this.FilterSelectedOnly(enabled ? undefined : { selected: features })
  }

  private FilterSelectedOnly(options: {
    deselected?: Feature[]
    selected?: Feature[]
  } = {}) {
    if (!this.targetLayer) return

    const filterOut = (f: Feature, filterOut: boolean) => {
      const sysProps = OlUtil.getFeatureSysProps(f)

      if (filterOut) {
        if (!sysProps.filteredOutBy) sysProps.filteredOutBy = []
        if (sysProps.filteredOutBy.includes(this._selectedOnlyFilterId)) return

        sysProps.filteredOutBy.push(this._selectedOnlyFilterId)
      } else {
        if (sysProps.filteredOutBy) {
          sysProps.filteredOutBy.splice(
            sysProps.filteredOutBy.indexOf(this._selectedOnlyFilterId, 1)
          )
        }
      }

      OlUtil.setFeatureSysProps(f, sysProps)
    }

    if (!options.deselected && !options.selected) {
      if (!this.dataSource) return
      const [selected, deselected] = this.dataSource.rows.reduce(([selected, deselected], row) => {
        if (row._selected) selected.push(row._feature)
        else deselected.push(row._feature)

        return [selected, deselected]
      }, [[], []] as Feature[][])
      options.selected = selected
      options.deselected = deselected
    }

    if (options.deselected) options.deselected.forEach(f => filterOut(f, true))
    if (options.selected) options.selected.forEach(f => filterOut(f, false))

    this.targetLayer.syncStyle()
  }

  onColumnResize(valueEmitted: { newValue: number, column: NgxTableColumn }) {
    if (!this.targetLayer) return
    const colIndex = this.getResizedColumnIndex(valueEmitted.column)
    if (colIndex === -1) return

    const modifiedAttributeColumns = this.targetLayer.attributeColumns
    const colObj = this.targetLayer.attributeColumns[colIndex]
    colObj.display_width = valueEmitted.newValue
    modifiedAttributeColumns[colIndex] = colObj
    this.targetLayer.attributeColumns = modifiedAttributeColumns

    // TODO : Test on staging below code was causing a double refresh of the table
    // this now is handled in the attributetable service - this. selectedLayer.columnsChanged subscription
    // remove once complete
    // this.ReloadDataset()
  }

  getResizedColumnIndex(column: NgxTableColumn): number {
    if (!this.targetLayer) return -1
    const index = this.targetLayer.attributeColumns.findIndex(resizedCol =>
      resizedCol.prop === column.prop
    )
    return index
  }

  getSumColumnsWidth(visibleTable: NgxTableColumn[]): number {
    const sum = visibleTable.reduce((sum, val) =>
      val.width ? sum + val.width : sum
      , 0)
    return sum
  }

  resetColumnWidths(availableColumns: NgxTableColumn[], visibleColumns: NgxTableColumn[]): NgxTableColumn[] | undefined {
    if (!availableColumns || !this.tableWidth) return
    const resizeableTableWidth = this.tableWidth
    const columnsWidth = this.getSumColumnsWidth(visibleColumns)
    if (columnsWidth > resizeableTableWidth) return

    const resizedTable = visibleColumns
    const extraWidth = (resizeableTableWidth - columnsWidth)
    let lstColWidth = resizedTable[resizedTable.length - 1].width
    resizedTable[resizedTable.length - 1].width = lstColWidth ? lstColWidth += extraWidth : this._defaultColWidth

    return resizedTable
  }

  toggleLock() {
    this._attributeTableService.toggleSelectionLock()
  }

  toggleEditing(enable?: boolean) {
    const editing = this._attributeTableService.editMode
    if (enable === undefined) enable = !editing
    if (editing !== enable) {
      if (editing) {
        this._unsavedEdits = {}
        this.cellEditState = {}
      }
      this._attributeTableService.editMode = enable
    }
  }

  editCell(key: string) {
    if (!this.editMode) return

    if (!this.cellEditState[key]) {
      this.cellEditState = {
        [key]: true
      }
    }
  }

  canEditColumn(prop: string) {
    if (prop.startsWith('_') || Columns.System.includes(prop)) return false
    if (this.presetEditLock && this.targetLayer && this.targetLayer.isPropertyView) {
      // Shared columns from all of the property view generated datasets
      // which CAN be edited
      const columns: string[] = []

      if (this._authService.isSysMaintainer) {
        columns.push(
          PVGeojsonProperties.COUNTRY,
          PVGeojsonProperties.CITY,
          PVGeojsonProperties.ADDRESS
        )
      }

      if (this.targetLayer.layerMode && this.targetLayer.layerMode.includes('ml')) {
        // Allow editing columns only if ml algorithm is selected for column
        const mlDataset = this.targetLayer && this.targetLayer.mlDataset

        if (mlDataset) {
          if (mlDataset.height_model) columns.push(PVGeojsonProperties.HEIGHT)
          if (mlDataset.storey_model) columns.push(PVGeojsonProperties.STOREYS)
          if (mlDataset.constr_class_model) columns.push(PVGeojsonProperties.CONSTRUCTION_CLASS)
          if (mlDataset.roof_material_model) columns.push(PVGeojsonProperties.ROOF_MATERIAL)
          if (mlDataset.roof_type_model) columns.push(PVGeojsonProperties.ROOF_TYPE)
        }
      } else if (this.targetLayer.layerMode && this.targetLayer.layerMode.includes('anot')) {
        // Allow editing columns only if guide is selected for column
        const anotGuideSet = this.targetLayer && this.targetLayer.anotGuideSet

        if (anotGuideSet) {
          if (anotGuideSet.height) columns.push(PVGeojsonProperties.HEIGHT)
          if (anotGuideSet.storey) columns.push(PVGeojsonProperties.STOREYS)
          if (anotGuideSet.constr_class) columns.push(PVGeojsonProperties.CONSTRUCTION_CLASS)
          if (anotGuideSet.roof_material) columns.push(PVGeojsonProperties.ROOF_MATERIAL)
          if (anotGuideSet.roof_type) columns.push(PVGeojsonProperties.ROOF_TYPE)
        }
      } else {
        columns.push(
          PVGeojsonProperties.HEIGHT,
          PVGeojsonProperties.STOREYS,
          PVGeojsonProperties.CONSTRUCTION_CLASS,
          PVGeojsonProperties.ROOF_MATERIAL,
          PVGeojsonProperties.ROOF_TYPE
        )
      }
      return columns.includes(prop)
    }

    return true
  }

  async saveEditing(singleRow?: NgxTableRow) {
    if (!this.dataSource || !this.targetLayer) return
    try {
      this.applyingChanges = true
      this.noReselect = true

      if (this.targetLayer.isPropertyView) {
        await this.SavePropertyViewTableRows(singleRow)
      } else {
        await this.SaveSimpleVectorTableRows(singleRow)
      }

      if (!singleRow) {
        this.UpdateLayerFilters()
        this.toggleEditing(false)
      }
      // TODO: Add more permanent solution, this is temp
      setTimeout(() => this.noReselect = false, 10000)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    } finally {
      this.applyingChanges = false
    }
  }

  private async SaveSimpleVectorTableRows(singleRow?: NgxTableRow) {
    if (!this.dataSource || !this.targetLayer) return
    const rows = this.dataSource.rows
    const deleteKeys: any[] = []
    const updatedRows: IPVectorTableRow[] = Object.keys(this._unsavedEdits).reduce((arr, x) => {
      const editedColumns: Set<string> = this._unsavedEdits[x]

      const row = rows.find(y => `${y[this.geomId]}` === x) as NgxTableRow
      if (singleRow && row[this.geomId] !== singleRow[this.geomId]) {
        return arr
      }
      deleteKeys.push(x)

      const columns = [this.geomId, ...Array.from(editedColumns)]
      arr.push(columns.reduce((obj, column) => {
        obj[column] = row[column]
        return obj
      }, {} as IPVectorTableRow))

      return arr
    }, [] as IPVectorTableRow[])
    await this.targetLayer.updateVectorRows(updatedRows, false)
    for (const k of deleteKeys) {
      delete this._unsavedEdits[k]
    }
  }

  private async SavePropertyViewTableRows(singleRow?: NgxTableRow, reset = false) {
    if (!this.dataSource || !this.targetLayer) return
    const rows = this.dataSource.rows
    const deleteKeys: any[] = []

    if (!singleRow && reset) {
      throw new AppError(`Reset of whole dataset is not allowed.`)
    }

    const columns = [
      PVGeojsonProperties.ROOF_MATERIAL,
      PVGeojsonProperties.ROOF_TYPE,
      PVGeojsonProperties.CONSTRUCTION_CLASS,
      PVGeojsonProperties.HEIGHT,
      PVGeojsonProperties.STOREYS,
      PVGeojsonProperties.AREA
    ]
    if (this._authService.isSysMaintainer) {
      columns.push(
        PVGeojsonProperties.CITY,
        PVGeojsonProperties.COUNTRY,
        PVGeojsonProperties.ADDRESS
      )
    }

    const unsavedRows = Object.keys(this._unsavedEdits)
      .map(x => rows.find(y => `${y[this.geomId]}` === x) as NgxTableRow)
      .filter(x => !!x)

    const rowsForModification = this.targetLayer.preparePvGeomsForModification(
      (singleRow ? [singleRow] : unsavedRows).map(x => ({
        geom_id: x.geom_id as string,
        image_configuration_id: x[PVGeojsonProperties.IMAGE_CONFIGURATION_ID]
      }))
    )

    if (reset) {
      const currentValues = rowsForModification.map(x => {
        const match = rows.find(r => r.geom_id === x.geom_id)
        return match && { ...match }
      }).filter(x => !!x) as NgxTableRow[]

      await this.targetLayer.resetPropertyViewRows(rowsForModification)
      const sub = this._tableReloaded.subscribe(() => {
        sub.unsubscribe()
        if (!this.targetLayer || !this.dataSource) return

        for (const val of currentValues) {
          const id = val[this.geomId]
          const match = this.dataSource.rows.find(r => r[this.geomId] === id)

          for (const col of columns) {
            if (match && match[col] !== val[col]) {
              if (!this._unsavedEdits[id]) this._unsavedEdits[id] = new Set()
              this._unsavedEdits[id].add(col)
              match[col] = val[col]
            }
          }
        }
      })
    } else {
      const updatedRows: (IUpdatePvMl | IUpdatePvAnot)[] = rowsForModification.map(r => {
        deleteKeys.push(r.geom_id)

        // If there are no edits,
        // save row with no measures which will mark it as reviewed
        const changes = unsavedRows.find(x => x.geom_id === r.geom_id)
        if (changes) {
          for (const col of columns) {
            if (changes[col] !== undefined) r[col] = changes[col]
          }
        }

        return r as IUpdatePvMl | IUpdatePvAnot
      })

      await this.targetLayer.savePropertyViewRows(updatedRows)
      for (const k of deleteKeys) {
        delete this._unsavedEdits[k]
      }

      return
    }

  }

  private UpdateLayerFilters() {
    if (!this.targetLayer) return
    this._layerQueriesService.refreshLayerQuery(this.targetLayer)
  }

  filterColumnsTrigger(updateSize = false) {
    this._filterColumns.next(updateSize)
  }

  toggleRowsSelection(e: MatCheckboxChange) {
    if (!this.dataSource) return
    this.dataSource.rows.forEach(x => this.MarkRowSelected(x, e.checked))
    this.dataSource.selected = e.checked ? Object.assign([], this.dataSource.rows) : []
    this.onRowsSelectionChange({ selected: this.dataSource.selected })
  }

  toggleRowSelection(row: NgxTableRow, e: MatCheckboxChange) {
    if (!this.dataSource) return
    const indexMatch = this.dataSource.selected.findIndex(x => x._originalIndex === row._originalIndex)
    if (e.checked) {
      if (indexMatch === -1) this.dataSource.selected.push(row)
    } else {
      this.dataSource.selected.splice(indexMatch, 1)
    }
    this.MarkRowSelected(row, e.checked)
    this.onRowsSelectionChange({ selected: this.dataSource.selected })
  }

  async toggleRowReviewed(row: NgxTableRow, e: MatCheckboxChange) {
    await this.SavePropertyViewTableRows(row, !e.checked)
  }

  onTableContextMenu(e:
    { type: 'body', content: NgxTableRow, event: MouseEvent } |
    { type: 'header', event: MouseEvent, content: NgxTableColumn }
  ) {
    let column = e.type === 'header' ? e.content : undefined
    if (!column) {
      const eventPath = e.event['path'] || (e.event.composedPath && e.event.composedPath())
      const cell = eventPath.find((e: HTMLElement) => e.localName === 'datatable-body-cell')
      const row = eventPath.find((e: HTMLElement) => e.localName === 'datatable-body-row')
      if (row && cell) {
        const i = [...row.children[0].children, ...row.children[1].children].indexOf(cell)
        column = this.dataSource && this.dataSource.columns[i]
      }
    }
    if (!column) {
      throw new AppError(`Failed to detect column of triggered row.`)
    }

    if (!DevUtil.noContextMenu()) e.event.preventDefault()

    if (!this.targetLayer) return

    const columnMeta = this.targetLayer.getColumnMetadata(column.prop)

    const layer = this.targetLayer
    const coords = {
      x: e.event.x,
      y: e.event.y
    }
    const menu: CtxUIMenuItem = {}

    const open = () => {
      this._trackDialog(
        this._contextMenu.openMenu([menu], coords)
      )
    }

    const canViewEditOptions = this.presetEditModeCanEditGeometry || (this.canEdit && !this.editMode)
    const cantEditOrPreset = !canViewEditOptions || this.targetLayer.preset
    if (e.type === 'header') {
      if (['integer', 'float'].includes(e.content.type as IRVectorColumnType)) {
        const data = {
          target: this.targetLayer,
          columnName: e.content.name,
          column: e.content.prop
        }
        menu['Create Statistic Query'] = () => {
          this._trackDialog(
            this._dialog.open(StatisticQueryBuilderDialogComponent, StatisticQueryBuilderDialogComponent.setOptions(data))
          )
        }
      }
      if (cantEditOrPreset || this.readOnly) {
        if (['integer', 'float'].includes(e.content.type as IRVectorColumnType)) open()
        return
      }

      menu['Edit columns'] = () => {
        layer.editColumnNames()
      }

      if (
        e.content.prop &&
        !e.content.prop.startsWith('_') &&
        !Columns.System.includes(e.content.prop) &&
        !columnMeta.uniqueRowKey
      ) {
        menu[`Delete column '${e.content.name}'`] = () => {
          const otherLayers = layer.getLinkedTimeSeries && layer.getLinkedTimeSeries()
          this._trackDialog(
            this._promptService.prompt(
              `Are you sure you want to permanently delete column '${e.content.name}' from the layer '${layer.title}'${(otherLayers && otherLayers.length) ? ` and other ${otherLayers.length} linked layer(s)` : ''
              }?`, {
              yes: () => layer.deleteColumn(e.content.prop),
              no: null
            })
          )
        }
      }

      open()
      return
    }

    const selected = this.dataSource ? this.dataSource.selected : []

    const row = selected.length > 0 ? selected[0] : e.content
    const f = row._feature
    const geom = f && f.getGeometry()

    if (!cantEditOrPreset && this.hasMutableColumns && this.hasVisibleColumns && !this.readOnly) {
      menu['Edit table'] = () => this.toggleEditing(true)
    }

    const multiSelect = selected.length > 1
    const deleteGeom = `Remove ${multiSelect ? `rows geometries` : `row's geometry`}`
    const deleteRow = `Delete ${multiSelect ? 'rows' : 'row'}`
    const openCharts = `Open ${multiSelect ? 'rows' : ''} chart`

    if (!canViewEditOptions && selected.length === 1) {
      if (columnMeta.chartAvailable) {
        menu[openCharts] = () => this.openCharts([row], [(column as NgxTableColumn).prop])
        open()
      }
      return
    }

    if (selected.length > 1) {

      if (columnMeta.chartAvailable) {
        menu[openCharts] = () => this.openCharts(selected, [(column as NgxTableColumn).prop])
      }

      if (!cantEditOrPreset) menu[deleteRow] = () => this.deleteSelectedRows()

      if (!cantEditOrPreset) menu[deleteGeom] = () => this.deleteSelectedRowsGeometries()
      open()
      return
    }

    if (!(selected.length === 1 || (f && selected.length === 0))) return

    if (columnMeta.chartAvailable) {
      menu[openCharts] = () => this.openCharts([row], [(column as NgxTableColumn).prop])
    }

    if (!this.presetEditModeCanEditGeometry || this.targetLayer.layerMode) {
      Object.assign(menu, {
        [deleteRow]: () => this.deleteSelectedRows([row])
      })
    }
    if (this.readOnly) {
      open()
      return
    }
    if (geom) {
      if (!this.targetLayer.preset) menu[deleteGeom] = () => this.deleteSelectedRowsGeometries([row])
    } else {
      open()
      return
    }

    if (geom.getType().startsWith('Multi')) {
      Object.assign(menu, {
        'Separate nested geometries': () => layer.separateMultiGeom(
          row._feature
        )
      })
    }

    open()
  }

  openCharts(rows: NgxTableRow[], column: string[]) {
    const layer = this.targetLayer as AppLayer
    this._trackDialog(
      this._dialog.open(AttributeChartComponent, AttributeChartComponent.setup({
        layer,
        column,
        rowId: rows.length === 1 ? `${rows[0][layer.idKey]}` : undefined,
        rowIds: rows.length > 1 ? rows.map(x => `${x[layer.idKey]}`) : undefined
      }))
    )
  }

  getRowClass(row: NgxTableRow) {
    let rowClass: undefined | string
    if (!row._feature || !row._feature.rendered) {
      rowClass = 'not-rendered'
    }
    return rowClass
  }

  onRowActivate(e:
    { type: 'click', row: NgxTableRow, event: MouseEvent, rowElement: HTMLElement } |
    { type: 'keydown', row: NgxTableRow, event: KeyboardEvent, rowElement: HTMLElement }
  ) {
    if (e.type === 'click') {
      this._lastSelectedRow = e.row
      const classList = e.event.target && (e.event.target as HTMLElement).classList
      const ignoreCheckbox = classList && Array.from(classList).some(x => x.includes('checkbox'))
      if (!ignoreCheckbox && this.dataSource) {
        const checked = !e.row._selected
        // Ignore deselecting row on click
        if (!checked) return
        // If marking as selected from click (but not checkbox, and shift is not pressed) - clear other rows
        // otherwise just deselect item
        if (checked && !e.event.shiftKey) {
          this.dataSource.selected
            .splice(0)
            .forEach(x => { if (x !== e.row) this.MarkRowSelected(x, false) })
        }

        this.toggleRowSelection(e.row, { checked } as any)
        this.MoveEditingToSelectedRow()
      }
    } else if (e.type === 'keydown') {
      if (this.dataSource && this._lastSelectedRow) {
        if (CommonUtil.isKey(e.event, 'down')) this.SelectRow(this._lastSelectedRow, 1)
        else if (CommonUtil.isKey(e.event, 'up')) this.SelectRow(this._lastSelectedRow, -1)

        // ngx table consumes some keyboard events so we need to emit them again
        if (CommonUtil.isKey(e.event, 'enter')) {
          // Bug fix: This seems to happen in our case, but not in online ngx-datatable demo:
          // If you click on a row, and keep pressing enter - it adds and removes element to
          // selected rows array, but it's triggered internally in ngx-datatable.
          // This is a temporary solution to reselect last selected row, so that user would not see
          // that row got deselected, and ctrl+enter would work in order to save a row
          if (this._lastSelectedRow && this.dataSource && !this.dataSource.selected.length) {
            this.dataSource.selected = [this._lastSelectedRow]
          }

          window.dispatchEvent(new KeyboardEvent('keydown', {
            key: e.event.key,
            ctrlKey: e.event.ctrlKey,
            shiftKey: e.event.shiftKey
          }))
        }
      }
    }
  }

  private MoveEditingToSelectedRow() {
    const editingKey = Object.keys(this.cellEditState).find(x => !!this.cellEditState[x])
    const selectedRow = this.dataSource && this.dataSource.selected[0]
    if (!selectedRow || !editingKey) return

    if (editingKey) {
      const [id, prop] = editingKey.split(':=')
      if (id && id !== 'undefined' && id !== selectedRow[this.geomId]) {
        this.cellEditState[editingKey] = false
        this.cellEditState[`${selectedRow[this.geomId]}:=${prop}`] = true
      }
    }
  }

  private SelectRow(currRow: NgxTableRow, direction: 1 | 0 | -1) {
    const i = this.renderedRows.findIndex(x => x[this.geomId] === currRow[this.geomId])
    const nextI = i + direction

    const nextRow = this.renderedRows[nextI] as NgxTableRow | undefined
    if (this.dataSource && nextRow) {
      const checked = true
      // If marking as selected from click (but not checkbox, and shift is not pressed) - clear other rows
      // otherwise just deselect item
      if (checked) {
        this.dataSource.selected
          .splice(0)
          .forEach(x => { if (x !== nextRow) this.MarkRowSelected(x, false) })
        this._changeDet.detectChanges()
      }

      this.toggleRowSelection(nextRow, { checked } as any)
      this._lastSelectedRow = nextRow
      this._changeDet.detectChanges()

      this.ScrollToRow(nextRow)
    }
  }

  onRowsSelectionChange(event: { selected: NgxTableRow[] }, highlightFeature = true, centre = true) {
    if (!this.targetLayer) return
    const { selected } = event
    if (!this.dataSource) return
    this.someChecked = selected.length > 0

    if (selected.length > 0) {
      const features = selected.map(x => x._feature).filter(x => !CommonUtil.isUndefined(x && x.getGeometry()))
      const renderedFeatures: Feature[] = []
      for (const feature of features) {
        const rendered = this.targetLayer.getRenderedFeature(feature)
        if (!renderedFeatures.includes(rendered)) renderedFeatures.push(rendered)
      }
    }
  }

  async downloadCsv (filteredTable: boolean) {
    if (this.availableColumns && this._featureList && this.targetLayer) {
      const columns = this.availableColumns.filter(x => !x.prop.startsWith('_') && (filteredTable ? x.display : true))
      const columnsNames = columns.map(column => column.prop)
      this.downLoadLayer = this.targetLayer

      try {
        this.countingCsv = true
        const safe = await this._layerQueriesService.getFeatureCountCsv(this.downLoadLayer)
        if (safe) {
          this.countingCsv = false
          this.downloadingCsv = true

          const res: string = await this.downLoadLayer.createLayerCsv(columnsNames)
          const download = await this.downLoadLayer.downloadCsvFile(res)
          CommonUtil.downloadBlob(download, `${this.downLoadLayer.title}.csv`)

          this.downloadingCsv = false
        } else {
          this.countingCsv = false
        }
      } catch (error: any) {
        handleError(error)
        this._alertService.log(error.message)
        this.countingCsv = false
        this.downloadingCsv = false
      }

    }
  }

  export(filteredTable: boolean) {
    let csvContent = ''
    if (this.availableColumns && this._featureList && this.targetLayer) {
      const layer = this.targetLayer
      const columns = this.availableColumns.filter(x => !x.prop.startsWith('_') && (filteredTable ? x.display : true))

      const columnsNames = columns.map(column => column.name)
      const columnOgs = columns.map(column => column.prop)
      const dataToSave = this._featureList.map((el: Feature) => {
        const props = el.getProperties()
        return columnOgs.map(x => {
          const pipe = layer.mergedColumnMeta[x] && layer.mergedColumnMeta[x].transformPipe
          let data = pipe ? pipe.transform(props[x]) : props[x]
          if (typeof data === 'string' && data.includes(',')) data = `\"${data}\"`
          return data
        })
      })
        ;[columnsNames, ...dataToSave].forEach((rowArray: any) => {
          const row = rowArray.join(',')
          csvContent += row + '\r\n'
        })

      let queryCsvContent = ''
      if (this.querySource && this.querySource.rows.length) {
        const queryColumnNames = this.querySource.columns.map(column => column.name).filter(columnName => columnName !== '')
        const queryColumnOgs = this.querySource.columns.map(column => column.prop).filter(columnName => columnName !== 'buttons')
        const queryDataToSave = this.querySource.rows.map(row => {
          return queryColumnOgs.map(x => row[x])
        })
          ;[queryColumnNames, ...queryDataToSave].forEach((rowArray: any) => {
            const row = rowArray.join(',')
            queryCsvContent += row + '\r\n'
          })
        if (this._workspaceService.isFRED()) {
          queryCsvContent += '\r\n' + csvContent
          csvContent = queryCsvContent
        } else {
          csvContent += '\r\n' + queryCsvContent
        }
      }

      CommonUtil.downloadBlob(csvContent, `${this._attributeTableService.layerTitle} - ${new Date().toLocaleDateString()}.csv`, 'csv')
    }
  }

  toggleCollapse() {
    this._attributeTableService.toggleCollapse()
  }

  close() {
    this._attributeTableService.toggleTable(false)
  }

  private UpdateColumns(updateSizing = false) {
    if (!this.targetLayer) return
    if (
      this.dataSource && this.availableColumns && this.targetLayer.attributeColumns
    ) {
      for (const col of this.availableColumns) {
        if (this.displayedColumns) col.display = this.displayedColumns.includes(col.prop) || col.prop === this._indicatorCol
      }

      const visibleColumns = this.availableColumns.filter(col => col.display)
      this.dataSource.columns = visibleColumns

      const columns = this.targetLayer.attributeColumns
      let unsaved = false
      for (const col of columns) {
        if (this.displayedColumns) {
          const includes = this.displayedColumns.includes(col.prop)
          if (col.display_attribute_table !== includes) {
            col.display_attribute_table = includes
            unsaved = true
          }
        }
      }

      if (unsaved) this.targetLayer.attributeColumns = columns
    }

    this._attributeTableService.updateColumnsVisibility(this.targetLayer)
    // Reload dataset, because if column is set from hidden to visible, then it's width might be too small
    // comparing to the rest of the table

    // TODO : Test on staging below code was causing a double refresh of the table
    // this now is handled in the attributetable service - this. selectedLayer.columnsChanged subscription
    // remove once complete

    // if (updateSizing) this.ReloadDataset()
  }

  private FeatureToRow(f: Feature, i: number, changes: { [key: string]: any } = {}): NgxTableRow {
    return {
      ...f.getProperties(),
      ...changes,
      _originalIndex: i,
      _feature: f,
      _geom: f.getGeometry(),
      _selected: false,
      _style: undefined as any
    }
  }

  private async LoadData(filterTrigger = false, resetScroll = true) {
    this.ReRenderTable({
      stop: true
    })
    this.ReRenderQueryTable({ stop: true })

    let selected: any[] = []
    if (this.targetLayer === this._attributeTableService.selectedLayer && this.dataSource) {
      selected = this.dataSource.selected.map(x => x[this.geomId]).filter(x => x !== undefined)
    }

    // this.targetLayer = this._attributeTableService.selectedLayer

    // Preserve unsaved edits
    const unsavedValues: {
      id: string,
      col: string,
      val: any
    }[] = []

    // Before clearing data, deselect all rows
    if (this.dataSource) {
      for (const row of this.dataSource.selected) {
        this.MarkRowSelected(row, false)
      }
    }
    // TEMP: Need to provide empty dataset, to allow attribute table to render
    // then we wait for split (map/table) to initialize, and only then we populate
    // data.
    // This is required as sometimes on load the table would size to full height, *possibly*
    // because data was loaded before split is initialized, larger list requires more rendering time - causing
    // interface to lag. Only when data was loaded - the split initialized adjusting the view
    this.dataSource = {
      rows: [],
      columns: [],
      selected: []
    }
    // Allow for some time to de-render previous data from table.
    // This also seems to update table faster by second or few, for example if we had 300 rows and now have 40 -
    // if we leave 300 rows, it takes some time to change to 40 rows. But if we first let table
    // clear itself, the 40 rows load pretty quick (Tested using FRED time series claim data)
    await CommonUtil.delay(10)
    this._rowMap = {}

    if (!this.targetLayer) return

    this.columnMetadata = this.targetLayer.tableColumns

    this.LoadColumnDropdownOptions()
    await this._attributeTableService.attributeTableRendered(3)

    this._featureList = this.targetLayer.notFilteredOutFts

    const rows = this._featureList.map((f, i) => {
      const id = f.get(this.geomId)
      const unsaved = unsavedValues.filter(x => x.id === `${id}`).reduce((o, val) => {
        o[val.col] = val.val
      }, {} as any)

      const row = this.FeatureToRow(f, i, unsaved)

      this.MarkRowSelected(row, selected.includes(id))
      this._rowMap[id] = row
      return row
    })

    let columns: any[] = []

    const floatLeft = (col: string) => {
      if (this.targetLayer && this.targetLayer.isPropertyView) {
        if (col === PVGeojsonProperties.REVIEWED) return true
      }
      return
    }

    if (this.targetLayer.attributeColumns) {
      this.availableColumns = [
        ...this.CreateMandatoryColumns(),
        ...this.targetLayer.attributeColumns
          .map(col => {
            const colMeta = this.columnMetadata && this.columnMetadata.find(x => x.name === col.prop)
            const type = colMeta && colMeta.type
            return {
              name: col.override,
              prop: col.prop,
              display: col.display_attribute_table,
              pipe: this.GetColumnPipe(col.prop, type as IRVectorColumnType),
              cellTemplate: this.cellTemplate,
              type,
              minWidth: 75,
              width: col.display_width || 150,
              frozenLeft: floatLeft(col.prop),
              resizeable: true
            } as NgxTableColumn & { display: boolean }
          })
      ]

      this.addEnhancedQueryColumns()
      const visibleColumns = this.availableColumns.filter(col => col.display)
      const reSizedColumns = this.resetColumnWidths(this.availableColumns, visibleColumns)
      this.displayedColumns = visibleColumns.map(col => col.prop)

      this.someChecked = false
      columns = reSizedColumns ? reSizedColumns : visibleColumns
    }

    this.dataSource = {
      rows,
      columns,
      selected: rows.filter(x => x._selected)
    }

    this.updateEnhancedQueryRows()
    this.updateStatisticQueries()

    this.ngxTable.recalculatePages()
    if (this.ngxTable.bodyComponent && resetScroll) {
      this.ngxTable.bodyComponent.updateOffsetY(0)

      setTimeout(() => {
        // Needed because after addition of arrow key/tab navigation on table
        // the table failed to render correct transform:translate3d on every row
        // when switching to new layers, so we need to force transform update
        // by using fake scroll
        if (this.ngxTable.bodyComponent.scroller) {
          this.ngxTable.bodyComponent.offsetY = 1
          this.ngxTable.bodyComponent.scroller.updateOffset()
        }
      }, 10)

    }
    this.MapRowStyles()
    if (filterTrigger) this.filterColumnsTrigger()

    // if (!sameLayer) {
    //   if (this.selectedViewMode === TableViewMode.SELECTED_ONLY) this.ToggleSelectedOnlyMode(true)
    // }
    if (this.selectedViewMode === TableViewMode.SELECTED_ONLY) this.ToggleSelectedOnlyMode(true)
    this.ReRenderTable()
    this.downloadCsvVisible()
  }


  private GetColumnPipe(column: string, type: IRVectorColumnType) {
    if (!this.targetLayer) return

    if (this.targetLayer.isPropertyView) {
      if (column.includes('date') || column === PVGeojsonProperties.CONSTRUCTION_CLASS) return
    } else if (type === 'any') {
      return this._objectPlaceholderPipe
    } else if (type === 'date') {
      const meta = this.targetLayer.mergedColumnMeta[column]
      return meta && meta.transformPipe
    }

  }

  private MarkRowSelected(row: NgxTableRow, selected: boolean) {
    if (row._selected === selected) return
    row._selected = selected
    if (row._feature) {
      const sysProps = OlUtil.getFeatureSysProps(row._feature)
      sysProps.selected = selected
      OlUtil.setFeatureSysProps(row._feature, sysProps)
      if (this.selectedViewMode === TableViewMode.SELECTED_ONLY) {
        this.FilterSelectedOnly(selected ? { selected: [row._feature] } : { deselected: [row._feature] })
      }
    }
  }

  private async ReloadRows(features: Feature[]) {
    if (!this.dataSource || !this.targetLayer) return

    const idKey = this.targetLayer.idKey
    const matchedRows: {
      feature: Feature;
      row: NgxTableRow | undefined;
    }[] = []

    for (let y = 0; y < features.length; y++) {
      const feature = features[y]
      const row = this._rowMap[feature.get(idKey)]

      matchedRows.push({ feature, row })
    }

    const hasNew = matchedRows.some(r => !r.row)
    if (hasNew) {
      // NOTE: For now if table has new rows,
      // reload whole table to preserve correct ordering of rows.
      // Searching for index where to insert new row can be expensive
      // performance wise
      await this.ReloadDataset()
    } else {
      for (const match of matchedRows) {
        if (!match.row) continue
        Object.assign(match.row, match.feature.getProperties())
        match.row._geom = match.feature.getGeometry()
        match.row._feature = match.feature
      }
      // NOTE : If there are no new features rendered but there are features
      // this will check the table body and reload the dataset if nothing is
      // appearing in the attribute table
      if (features.length && !this.renderedRowsCount) {
        await this.ReloadDataset()
      }
    }

    this.ngxTable.recalculatePages()
    this.MapRowStyles()
    this.ReRenderTable()
    this.ReRenderQueryTable()
  }

  private async RemoveRows(features: Feature[]) {
    if (!this.dataSource || !this.targetLayer) return
    // TODO: Optimise performance
    this.dataSource.rows = this.dataSource.rows.filter(r => !features.includes(r._feature))

    this.ngxTable.recalculatePages()
    this.ReRenderTable()
    this.ReRenderQueryTable()
  }

  private CreateMandatoryColumns() {
    // Must be created on every table reload, otherwise if created in class root
    // it does not render on table
    const indicator: NgxTableColumn = {
      name: '',
      prop: this._indicatorCol,
      display: true,
      cellTemplate: this.colorCellTemplate,
      maxWidth: this._minColWidth,
      minWidth: this._minColWidth,
      width: this._minColWidth,
      frozenLeft: true,
      canAutoResize: false,
      resizeable: false
    }
    const checkbox: NgxTableColumn = {
      name: '',
      prop: this._checkboxCol,
      display: true,
      cellTemplate: this.checkboxCellTemplate,
      headerTemplate: this.checkboxHeaderTemplate,
      maxWidth: this._minColWidth,
      minWidth: this._minColWidth,
      sortable: false,
      width: this._minColWidth,
      frozenLeft: true,
      canAutoResize: false,
      draggable: false,
      resizeable: false,
      headerCheckboxable: true,
      checkboxable: true
    }
    return [indicator, checkbox]
  }

  updateValue(row: NgxTableRow, column: string, value: any, input?: MatSelect | HTMLElement) {
    if (!row[this.geomId]) {
      console.warn(`Row has missing ${this.geomId} indicator:`, row)
      return row[column]
    }

    if ((input && (input as any).checkValidity) && !(input as any).checkValidity()) return row[column]

    const dropdown = this.dropdownOptions[column]
    const requiredAndHasNone = dropdown && dropdown.multiple && dropdown.required && Array.isArray(value) && value.length < 1
    if (requiredAndHasNone && Array.isArray(row[column]) && row[column].length > 0) {
      input instanceof MatSelect && input.writeValue(row[column])
      return row[column]
    }

    if (this.columnMetadata) {
      const meta = this.columnMetadata.find(x => x.name === column) as IRVectorColumn
      if (meta && !meta.nullable && CommonUtil.isUndefined(value)) {
        // TODO: Move to save logic
        // this._alertService.log(`Column '${column}' cannot be empty.`)
        return row[column]
      }
    }
    row[column] = value
    const item = this._unsavedEdits[`${row[this.geomId]}`]
    if (!item) this._unsavedEdits[`${row[this.geomId]}`] = new Set([column])
    else item.add(column)

    return value
  }

  private MapRowStyles() {
    if (!this.targetLayer) return

    if (this.dataSource) {
      let { style: { fill, border } } = this.targetLayer.getAppliedStyle()
      if (!border) border = { R: 0, G: 0, B: 0, A: 0 }
      if (!fill) fill = { R: 0, G: 0, B: 0, A: 0 }

      for (const row of this.dataSource.rows) {
        const style: RowStyleMap = {} as any

        const props: ICustomColour = row._feature.get('customColors')
        if (props) {
          if (props.fill && props.fill.length > 0) {
            const fFill = CommonUtil.findLast(props.fill, x => x.active)
            if (fFill) style.fill = this.GetRgba(fFill.color)
          }

          if (props.border && props.border.length > 0) {
            const fBorder = CommonUtil.findLast(props.border, x => x.active)
            if (fBorder) style.stroke = this.GetRgba(fBorder.color)
          }
        }

        if (!style.stroke) style.stroke = this.GetRgba(border)
        if (!style.fill) style.fill = this.GetRgba(fill)

        // TODO: Extend Feature, and allow storing this logic in Feature itself
        if (row._feature.invalidateFill) {
          style.fill = `rgb(0, 0, 0)`
        }
        if (row._feature.invalidateBorder) {
          style.stroke = `rgb(0, 0, 0)`
        }

        row._style = style
      }
      this.ReRenderTable()
      this.ReRenderQueryTable()
    }
  }

  private GetRgba(color: IRgba) {
    return `rgba(${color.R}, ${color.G}, ${color.B}, ${color.A})`
  }

  private FindTableItems(features: Feature[], highlight = false) {
    if (this.dataSource) {
      let found = false
      let scrollToFirst: NgxTableRow | undefined
      const selected: NgxTableRow[] = []
      const key = (this.targetLayer && this.targetLayer.idKey) as string
      for (const f of features) {
        const match = this.ngxTable.bodyComponent.rows.find(r => r._feature.get(key) === f.get(key))
        if (!match) continue

        selected.push(match)
        if (!scrollToFirst) scrollToFirst = match
      }

      if (!found) this.ClearSelected()
      else this.MoveEditingToSelectedRow()
    }
  }

  private ScrollToRow(row: NgxTableRow) {
    const rowIndex = this.renderedRows.indexOf(row)
    const pageOffset = Math.max(rowIndex - Math.floor(this.ngxTable.pageSize / 2), 0) / this.ngxTable.pageSize
    this.ngxTable.bodyComponent.updateOffsetY(pageOffset)
  }

  private ClearSelected(notIn: NgxTableRow[] = []) {
    if (this.dataSource && this.dataSource.selected) {
      for (const row of this.dataSource.selected) {
        if (!notIn.includes(row)) this.MarkRowSelected(row, false)
      }
      this.dataSource.selected.splice(0)
      this.onRowsSelectionChange({ selected: this.dataSource.selected })
    }
  }

  shouldDisplayCheckbox(row: NgxTableRow) {
    return row[this.geomId] !== undefined
  }

  deleteSelectedRows(rows?: NgxTableRow[]) {
    if (!this.dataSource || !this.targetLayer) return
    if (!rows) rows = this.dataSource.selected
    this._trackDialog(
      this._promptService.prompt(
        `Are you sure you want to permanently delete ${rows.length} ${rows.length === 1 ? 'row' : 'rows'}\
        from the layer '${this.targetLayer.title}'?`, {
        yes: () => this.DeleteSelectedRows(rows as NgxTableRow[]),
        no: null
      })
    )
  }

  deleteSelectedRowsGeometries(rows?: NgxTableRow[]) {
    if (!this.dataSource || !this.targetLayer) return
    if (!rows) rows = this.dataSource.selected
    this._trackDialog(
      this._promptService.prompt(
        `Are you sure you want to permanently clear ${rows.length} ${rows.length === 1 ? 'geometry' : 'geometries'}\
        from the layer '${this.targetLayer.title}'?`, {
        yes: () => this.DeleteSelectedRowsGeometries(rows as NgxTableRow[]),
        no: null
      })
    )
  }

  private async DeleteSelectedRowsGeometries(rows: NgxTableRow[]) {
    if (rows.length === 0 || !this.targetLayer) return
    try {
      this.applyingChanges = true
      const ids = rows.map(x => x[this.geomId])
        .filter(x => typeof x === 'number' || typeof x === 'string')

      if (!this.targetLayer.preset) {
        await this.targetLayer.removeFeatures(ids, true)
      }

    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    } finally {
      this.applyingChanges = false
    }
  }

  private async DeleteSelectedRows(rows: NgxTableRow[]) {
    if (rows.length === 0 || !this.targetLayer) return
    try {
      this.applyingChanges = true
      const ids = rows.map(x => x[this.geomId])
        .filter(x => typeof x === 'number' || typeof x === 'string')

      if (this.targetLayer.isPropertyView) {
        await Promise.all(
          ids.map(id => {
            (this.targetLayer as AppLayer).deletePVGeometry(id)
          })
        )
      } else {
        await this.targetLayer.removeFeatures(ids)
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    } finally {
      this.applyingChanges = false
    }
  }

  cellUnsaved(ogcFid: number, column: string) {
    const item = this._unsavedEdits[`${ogcFid}`]
    return !!item && item.has(column)
  }

  async addColumn() {
    if (!this.targetLayer) return
    const layer = this.targetLayer

    if (!await layer.alterIfLinked()) return

    this._trackDialog(
      this._dialog.open(NewAttributeColumnDialogComponent, NewAttributeColumnDialogComponent.setup({
        columns: layer.tableColumns
      }))
    )
      .afterClosed().subscribe(async (columns: IPNewVectorColumn[]) => {
        if (columns) {
          try {
            await layer.createColumns(columns)
          } catch (error: any) {
            handleError(error)
            this._alertService.log(error.message)
          }
        }
      })
  }

  addRow() {
    if (!this.targetLayer) return
    this._trackDialog(
      this._dialog.open(NewAttributesRowDialogComponent, {
        data: {
          layer: this.targetLayer
        }
      })
    )
  }

  private async ReloadDataset() {
    try {
      if (!this.targetLayer) return
      this.reloading = true

      await this.LoadData(false)
      this._tableReloaded.next({})
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    this.reloading = false
  }

  private async LoadColumnDropdownOptions() {
    if (!this.targetLayer) return
    this.dropdownOptions = {}

    if (this.targetLayer.preset) {
      const api = this._api.orm.Products().PropertyView()

      switch (this.targetLayer.preset) {
        case Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS:
          this.dropdownOptions = {
            [PVGeojsonProperties.CONSTRUCTION_CLASS]: {
              multiple: true,
              required: true,
              options: (await api.ConstructionClasses().get().run())
                .map(x => x.name)
            },
            [PVGeojsonProperties.ROOF_MATERIAL]: {
              options: (await api.RoofMaterials().get().run())
                .map(x => x.name)
            },
            [PVGeojsonProperties.ROOF_TYPE]: {
              options: (await api.RoofTypes().get().run())
                .map(x => x.name)
            }
          }
          break
      }
    }
  }

  private ReRenderTable(options: {
    stop?: boolean
  } = {}) {
    if (this._reRenderTableTimeout) {
      clearTimeout(this._reRenderTableTimeout)
      this._reRenderTableTimeout = undefined
    }

    if (options.stop) return

    this._reRenderTableTimeout = setTimeout(
      () => {
        if (!this.dataSource) return
        this.dataSource.rows = [...this.dataSource.rows]
      }, 200
    )
  }

  private ReRenderQueryTable(options: {
    stop?: boolean
  } = {}) {
    if (this._reRenderQueryTableTimeout) {
      clearTimeout(this._reRenderQueryTableTimeout)
      this._reRenderQueryTableTimeout = undefined
    }

    if (options.stop) return

    this._reRenderQueryTableTimeout = setTimeout(
      () => {
        if (!this.querySource) return
        this.querySource.rows = [...this.querySource.rows]
        this.data = [...this.data]
      }, 200
    )
  }

  addEnhancedQueryColumns() {
    if (!this.targetLayer) return
    const addColumnNames = (container: EnhancedQueryContainer, name?: string) => {
      if (container.createAttrColumn) this.addEnhancedQueryColumn(name || container.attrColumnName || 'untitledC')
      for (const statement of container.statements) {
        if (statement instanceof EnhancedQueryContainer) {
          addColumnNames(statement)
        } else {
          if (statement.createAttrColumn) this.addEnhancedQueryColumn(statement.attrColumnName || 'untitled')
        }
      }

      for (const caseBlock of container.caseBlocks) {
        addColumnNames(caseBlock.block as EnhancedQueryContainer)
      }
      if (container.caseBlocks.length) {
        this.addEnhancedQueryColumn(container.caseColumnName || 'result')
      }
    }

    this._enhancedQueryList = this._layerQueriesService.getTargetQueries(this.targetLayer, ['enhanced'])

    this._enhancedQueryList.forEach(query => {
      if (!this.dataSource || !this.availableColumns || !query.applied || !query.query.enhancedQuery) return
      addColumnNames(query.query.enhancedQuery as EnhancedQueryContainer)
    })
  }

  addEnhancedQueryColumn(name: string, type?: string) {
    if (!this.availableColumns) return
    this.availableColumns.push({
      name: name,
      prop: name,
      display: true,
      type: 'string',
      minWidth: 75,
      width: 150,
      resizeable: true
    } as NgxTableColumn & { display: boolean })
  }

  updateEnhancedQueryRows() {
    const getContainerResult = (container: EnhancedQueryContainer, row) => {
      const results: any[] = []
      if (!this.targetLayer) return
      const statQueries = this._layerQueriesService.getTargetQueries(this.targetLayer, ['statistic'])

      for (const statement of container.statements) {
        if (statement instanceof EnhancedQueryContainer) {
          const contResults = getContainerResult(statement, row)
          const endResult = this.calculateOps(contResults, statement.operators)
          results.push(endResult)
          if (statement.createAttrColumn) {
            row[statement.attrColumnName || ''] = endResult
          }
        } else if (statement instanceof EnhancedQueryStatement) {
          let stResult
          switch (statement.statementType) {
            case 'default': {
              const a = row[`${statement.attributeA}`]
              const b = statement.attributeBType === 'value' ? statement.attributeB : row[`${statement.attributeB}`]
              const precision = CommonUtil.countDecimals(+a)
              if (isNaN(+a) || isNaN(+b)) {
                stResult = this.getOpResult(a, b, statement.operator.operation, precision)
              } else {
                stResult = this.getOpResult(+a, +b, statement.operator.operation, precision)
              }
              break
            }
            case 'statistic': {
              const statQuery = statQueries.find(q => q.name === statement.attributeA)
              const statResult = statQuery ? this.updateStatisticQueryTable(statQuery) : 0
              const a = statResult
              const b = statement.attributeBType === 'value' ? statement.attributeB : row[`${statement.attributeB}`]
              const precision = CommonUtil.countDecimals(+a)
              if (isNaN(+a) || isNaN(+b)) {
                stResult = this.getOpResult(a, b, statement.operator.operation, precision)
              } else {
                stResult = this.getOpResult(+a, +b, statement.operator.operation, precision)
              }
              break
            }
            case 'attribute':
              stResult = row[`${statement.attributeB}`]
              break
            case 'value':
              stResult = statement.attributeB
              break
            default:
              break
          }

          results.push(stResult)

          if (statement.createAttrColumn) {
            row[statement.attrColumnName || ''] = stResult
          }
        }
      }

      let final = this.calculateOps(results, container.operators)
      if (container.caseColumnName) {
        for (const caseBlock of container.caseBlocks) {
          const caseQueryResults: string[] = []
          for (const condStmnt of caseBlock.conditionStatements) {
            let value
            if (typeof final === 'boolean') {
              value = condStmnt.value === 'true' ? true : false
            } else if (typeof final === 'number') {
              value = +condStmnt.value
            } else if (typeof final === 'string') {
              final = final.toLowerCase()
              value = `${condStmnt.value}`.toLowerCase()
            }
            caseQueryResults.push(this.getOpResult(final, value, condStmnt.operator.operation))
          }
          if (this.calculateOps(caseQueryResults, caseBlock.operators)) {
            const result = getContainerResult(caseBlock.block as EnhancedQueryContainer, row)
            row[container.caseColumnName] = result
            if (caseBlock.block.createAttrColumn && caseBlock.block.attrColumnName) {
              row[caseBlock.block.attrColumnName] = result
            }
          }
        }
      }

      return final
    }

    if (!this.dataSource) return
    this.dataSource.rows.forEach(row => {
      this._enhancedQueryList.forEach(query => {
        if (!query.applied || !query.query.enhancedQuery) return
        const { operators, statements, createAttrColumn, attrColumnName, caseColumnName, caseBlocks } = query.query.enhancedQuery
        query.query.enhancedQuery = new EnhancedQueryContainer(operators, statements, createAttrColumn, attrColumnName, caseColumnName, caseBlocks)
        const result = getContainerResult(query.query.enhancedQuery as EnhancedQueryContainer, row)
        if (query.query.enhancedQuery.createAttrColumn) row[query.query.enhancedQuery.attrColumnName || query.name] = result
      })
    })
  }

  calculateOps(results, operations) {
    let x = results[0]
    if (results.length > 1) {
      for (const [i, op] of operations.entries()) {
        const precision = CommonUtil.countDecimals(+results[1])
        if (isNaN(+x) || isNaN(+results[i + 1])) {
          x = this.getOpResult(x, results[i + 1], op.operation, precision)
        } else {
          x = this.getOpResult(+x, +results[i + 1], op.operation, precision)
        }
      }
    }
    return x
  }

  getOpResult(a, b, operation, precision?) {
    let result
    switch (operation) {
      case Db.Helper.Prj.EnhancedMathOperator.MAX.operation:
        result = Math.max(a, b)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedMathOperator.MIN.operation:
        result = Math.min(a, b)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedMathOperator.AVERAGE.operation:
        result = ((a + b) / 2).toFixed(precision)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedMathOperator.SUM.operation:
        result = (a + b).toFixed(precision)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedMathOperator.DIFFERANCE.operation:
        result = (a - b).toFixed(precision)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedMathOperator.MULTIPLY.operation:
        result = (a * b).toFixed(precision)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedMathOperator.DIVIDE.operation:
        result = (a / b).toFixed(precision)
        result = isNaN(result) ? '-' : result
        break
      case Db.Helper.Prj.EnhancedOperator.GREATER.operation:
        result = a > b
        break
      case Db.Helper.Prj.EnhancedOperator.LESS.operation:
        result = a < b
        break
      case Db.Helper.Prj.EnhancedOperator.EQUAL.operation:
        result = (a === b)
        break
      case Db.Helper.Prj.EnhancedOperator.EXISTS.operation:
        result = !!a
        break
      case Db.Helper.Prj.EnhancedLogicalOperator.AND.operation:
        result = !!(a && b)
        break
      case Db.Helper.Prj.EnhancedLogicalOperator.OR.operation:
        result = !!(a || b)
        break
      default:
        result = '-'
    }
    return result
  }

  async updateStatisticQueries() {
    if (!this.targetLayer) return
    this.querySource = {
      rows: [],
      columns: [
        { name: 'Query Name', prop: 'query', type: 'string' },
        { name: 'Column', prop: 'column', type: 'string' },
        { name: 'Operator', prop: 'operator', type: 'string' },
        { name: 'Result', prop: 'result', type: 'float' }
      ]
    }
    const statQueries = this._layerQueriesService.getTargetQueries(this.targetLayer, ['statistic'])
    if (!statQueries.length && this.querySource) {
      this.querySource.rows = []
    }

    statQueries.sort((x, y) => {
      return x.query.statisticQuery &&
        y.query.statisticQuery &&
        x.query.statisticQuery.order &&
        y.query.statisticQuery.order &&
        (x.query.statisticQuery.order < y.query.statisticQuery.order) ? 1 : -1
    }).reverse()

    for (const [i, query] of statQueries.entries()) {
      const statQuery = query.query.statisticQuery
      if (statQuery) {
        await this.updateStatisticQueryTable(query)
      }
    }

    this.data = this.querySource ? [...this.querySource.rows] : []
    this.statQueryModel = [...this.data]
    this.ReRenderQueryTable()
  }

  async updateStatisticQueryTable(query: IQueryWRef) {
    const statQuery = query.query.statisticQuery
    if (!statQuery || !this.dataSource) return
    const row: number[] = []
    this.dataSource.rows.forEach(dsRow => {
      (dsRow[statQuery.attribute] || dsRow[statQuery.attribute] === 0) && row.push(+dsRow[statQuery.attribute])
    })
    if (!row.length && this.querySource) {
      this.querySource.rows.push({
        query: query.name,
        column: statQuery.attribute,
        operator: statQuery.operator,
        result: '-',
        details: query
      })
      return
    }

    const precision = () => Math.max(
      ...CommonUtil.randomArraySample(row, 100)
        .map(value => CommonUtil.countDecimals(value))
    )

    let result
    if (this.sampled && this.targetLayer) {
      result = await this.targetLayer.calculateSampledLayerQueryResult(query.query_id)
    } else {
      switch (statQuery.operator) {
        case Db.Helper.Prj.Aggregation.MAX:
          result = Math.max(...row)
          break
        case Db.Helper.Prj.Aggregation.MIN:
          result = Math.min(...row)
          break
        case Db.Helper.Prj.Aggregation.AVG:
          result = (row.reduce((a, b) => a + b, 0) / row.length).toFixed(precision())
          break
        case Db.Helper.Prj.Aggregation.SUM:
          result = row.reduce((a, b) => a + b, 0).toFixed(precision())
          break
      }
    }
    if (this.querySource) {
      this.querySource.rows.push({
        query: query.name,
        column: statQuery.attribute,
        operator: statQuery.operator,
        result,
        details: query
      })
    }

  }

  editQuery(query: IQueryWRef) {
    const data = {
      query: query,
      target: this.targetLayer as AppLayer
    }
    this._trackDialog(
      this._dialog.open(StatisticQueryBuilderDialogComponent, StatisticQueryBuilderDialogComponent.setOptions(data))
    )
  }

  deleteQuery(query: IQueryWRef) {
    this._layerQueriesService.deleteQuery(query)
  }

  async onDrop(event) {
    this.orderChanged = true
    this.renderStatisticTable = false

    this.data = [...event]
    setTimeout(async () => {
      this.statQueryModel = [...this.data]
      this.renderStatisticTable = true
    }, 0)
    this.ReRenderQueryTable()

  }

  async saveStatisticQueryOrder() {
    if (!this.targetLayer) return
    const statQueries = this._layerQueriesService.getTargetQueries(this.targetLayer, ['statistic'])
    if (!statQueries.length) return
    this.renderStatisticTable = false
    const dataModel = cloneDeep(this.data)
    for (const [i, query] of statQueries.entries()) {
      if (query.query.statisticQuery) {
        // set order
        const idx = dataModel.findIndex(x => x.details.query_id === query.query_id)
        query.query.statisticQuery.order = idx + 1
        await this._layerQueriesService.toggleQuery(query, true, true)
      }
    }
    this.updateStatisticQueries()
    this.orderChanged = false
    this.renderStatisticTable = true
  }

  switchToDefaultTab() {
    this.selectedTab = Tab.Attributes
  }

  onTabChange(tab: Tab) {
    this.selectedTab = tab
    switch (tab) {
      case Tab.Attributes:
        this.ReRenderTable()
        break
      case Tab.Statistics:
        this.ReRenderQueryTable()
        break
      default:
        throw new UnreachableCaseError(tab)
    }
  }

  downloadCsvVisible() {
    if (this.targetLayer) {
      this.sampled = this.targetLayer.preset ?
        [
          Db.Vip.LayerPreset.FLOODRE_CLAIMS,
          Db.Vip.LayerPreset.FLOODRE_EXPOSURE,
          Db.Vip.LayerPreset.PROPERTY_DATA_HUB
        ].includes(this.targetLayer.preset) :
        this.targetLayer.sampled ? true : false
    } else {
      this.sampled = false
    }
  }
  // NOTE: Reset the sensor ever 2s in case of DOM changes
  resetSensor (sensor: ResizeSensor) {
    const sen = sensor
    const reset = () => {
      if (this.ngxTable.element) sen.reset()
    }
    if (sen) {
      this._interval = setInterval(reset, 2000)
    }
  }
}

applyMixins(AttributeTableWidgetComponent, [DialogCleanup])
