import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { HttpClient } from '@angular/common/http'
import { environment } from 'environments/environment'
import { IExposureInfo } from '@core/types/'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AuthService } from '@services/core/auth/auth.service'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { IApiResponse } from '@vip-shared/interfaces/api/response'
import { IJson } from '@vip-shared/interfaces/json'
import AppError, { handleError } from '@core/models/app-error'
import { AlertService } from '@services/core'

@Injectable({
  providedIn: 'root'
})
export class RoutingMiddlewareService {
  private _timeFormat = 'DDMMYYYY_HHmm'
  private _serverPort?: number
  private _sessionGUID?: number
  private _basePort?: number
  private _serversDate?: moment.Moment
  private _lastUsedDate = moment()
  private _keepActiveInterval = 0
  private _lastUsedRegion = ''
  private _gettingNewPorts = false
  get serverPort (): number | undefined {
    return this._serverPort
  }
  get basePort (): number | undefined {
    return this._basePort
  }

  get serversRunning (): boolean {
    return !this._gettingNewPorts && ![this._serverPort, this._serversDate].includes(undefined)
  }

  constructor (
        private _http: HttpClient,
        private _authService: AuthService,
        private _api: VipApiService,
        private _workspaceService: WorkspaceService,
        private _alertService: AlertService
    ) {}

  async startServers (date = this._lastUsedDate) {
    try {
      this._gettingNewPorts = true
      this._lastUsedDate = date
      const region = this._workspaceService.regionName
      this._lastUsedRegion = region
      if (!region) {
        throw new AppError('Cannot start servers for unspecified workspace region.')
      }
      await this.stopServers()
      const url = `${(environment.UHL.routingMiddlewareApi as any)[region]}/start?` +
            `date=${date.format(this._timeFormat)}&userId=${this._authService.userId}&region=${region}&guid=${this._sessionGUID}`

      const response = await this._http.get(url, { headers: this._api.getHeaders() }).toPromise() as IApiResponse & {payload: IJson}

      if (!response.payload) {
        throw new AppError('Failed to get a valid response from the routing engine.')
      }
      this._serverPort = response.payload.port as number
      this._basePort = this._serverPort - 17
      this._sessionGUID = response.payload.guid
      this._serversDate = moment(date)
      this.KeepPortsActive()
      this._gettingNewPorts = false
      this._keepActiveInterval = window.setInterval(async () => {
        this.KeepPortsActive()
      }, 30000)
    } catch (error: any) {
      handleError(error)
      console.error(`Failed to start routing engine: ${error.message}`)
    }
  }

  async stopServers () {
    try {
      const region = this._workspaceService.regionName
      const url = `${(environment.UHL.routingMiddlewareApi as any)[region]}/stop?uid=${this._authService.userId}`
                      + `&guid=${this._sessionGUID || ''}`
      await this._http.get(url, { headers: this._api.getHeaders() }).toPromise()
      this.MarkAsClosed()
      this.ClearServerReference()
    } catch (error: any) {
      console.error(`Failed to stop routing engine: ${error.message}`)
    }
  }

  async getExposure (waypoints: [number, number][], portUsed: number): Promise<IExposureInfo | undefined> {
    try {
      if (!this._serversDate) {
        throw new AppError('Servers not initialized.')
      }
      const mode = portUsed - (this._basePort || 0)
      const body = { mode, waypoints }
      const region = this._workspaceService.regionName
      const url = `${(environment.UHL.routingMiddlewareApi as any)[region]}/exposure?` +
            `date=${this._serversDate.format(this._timeFormat)}&guid=${this._sessionGUID}`
      const response = await this._http.post(url, body, { headers: this._api.getHeaders() }).toPromise() as IApiResponse

      return response.payload as IExposureInfo
    } catch (error: any) {
      console.error(`Failed to get exposure from routing engine: ${error.message}`)
    }
  }

  private async KeepPortsActive () {
    if (this._basePort) {
      const region = this._workspaceService.regionName
      try {
        const url = `${(environment.UHL.routingMiddlewareApi as any)[region]}/keepalive?pid=${this._basePort}`
        await this._http.post(url, { headers: this._api.getHeaders() }).toPromise()
      } catch (error: any) {
        handleError(error, { action: 'UHL - mark ports as active' })
      }
    }
  }

  private async MarkAsClosed () {
    window.clearInterval(this._keepActiveInterval)
    if (this._basePort) {
      try {
        const url = `${(environment.UHL.routingMiddlewareApi as any)[this._lastUsedRegion]}/markasclosed?pid=${this._basePort}`
        await this._http.post(url, { headers: this._api.getHeaders() }).toPromise()
      } catch (error: any) {
        handleError(error, { action: 'UHL - mark ports as closed' })
      }
    }
  }

  private ClearServerReference () {
    this._serverPort = undefined
    this._serversDate = undefined
    this._basePort = undefined
  }
}
