import { RouteMeansOfTransportType } from '@core/enum/air'
import { ICoordinates } from '@core/types'

export class AirRouteFinder {

  constructor (
        public id: number = 0,
        public meansOfTransport: RouteMeansOfTransportType = RouteMeansOfTransportType.Car,
        public calculating: boolean = false,
        public dateTime: Date = new Date(),
        public waypoints: ICoordinates[] = [],
        public balance: number = 0) {
  }
}
