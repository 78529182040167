import { Component, HostBinding } from '@angular/core'
import { ThemeService } from '@services/core/theme/theme.service'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { ThemeEditorDialogComponent } from '@core/page-components/theme-editor-dialog/theme-editor-dialog.component'

@Component({
  selector: 'app-theme-preview-toolbox',
  templateUrl: './theme-preview-toolbox.component.html',
  styleUrls: ['./theme-preview-toolbox.component.scss']
})
export class ThemePreviewToolboxComponent implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  @HostBinding('class') private _class = 'mat-elevation-z5'

  get previewTheme () {
    return this._themeService.previewTheme
  }
  constructor (
    private _themeService: ThemeService,
    private _dialog: MatDialog
  ) { }

  cancelPreview () {
    this._themeService.applyTheme('applied')
  }

  editTheme () {
    this._trackDialog(
      this._dialog.open(
        ThemeEditorDialogComponent, ThemeEditorDialogComponent
        .setup(this.previewTheme, false)
      )
    ).afterClosed().subscribe(theme => {
      if (theme !== undefined) this._themeService.applyTheme(theme, true)
    })
  }
}

applyMixins(ThemePreviewToolboxComponent, [DialogCleanup])
