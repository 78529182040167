import { AppLayer } from '.'
import { Db } from '@vip-shared/models/db-definitions'
import { WorkspaceService } from '@services/workspace'
import { PromptService, VipApiService } from '@services/core'
import { MatDialog } from '@angular/material/dialog'
import { MomentPipe } from '@core/pipes/moment.pipe'
import { IPNewScraperConf, IPScraperConf, IRWorkspaceLayer } from '@vip-shared/interfaces'
import { AppLayerGeneric } from './app-layer-generic'
import AppError from '../app-error'
import { CommonUtil } from '@core/utils/index'
import { cloneDeep } from 'lodash'
import { BehaviorSubject, interval, Subscription } from 'rxjs'
import { API } from '@vip-shared/interfaces/api-helper'
import { ScraperPresets } from '@vip-shared/models/const/scraper-presets'

export class AppScraperLayer extends AppLayer {
  static scraperPresets = [Db.Vip.LayerPreset.GOOGLE_NEWS,Db.Vip.LayerPreset.TWITTER]
  readonly preset!: Db.Vip.LayerPreset

  private _scrapers: API.Res.ScraperConf[] = []
  get scrapers () {
    return cloneDeep(this._scrapers)
  }

  private _scraperListChanged = new BehaviorSubject<API.Res.ScraperConf[]>(this._scrapers)
  get scrapersChanged () {
    return this._scraperListChanged.asObservable()
  }

  // Used to keep checking for scraped data if no data is present yet
  private _scraperCheckAllData?: Subscription

  constructor (
    allElements: () => AppLayerGeneric[],
    allLayers: () => AppLayer[],
    maxIndex: () => number,
    canSelect: () => boolean,
    canDeselect: () => boolean,
    momentPipe: MomentPipe,
    dialog: MatDialog,
    prompt: PromptService,
    api: VipApiService,
    workspaceService: WorkspaceService,
    source: IRWorkspaceLayer,
    viewId: number,
    theme?: Db.Vip.Geo.ILayerAttribute
  ) {
    super(
      allElements, allLayers, maxIndex, canSelect, canDeselect,
      momentPipe , dialog, prompt, api,
      workspaceService,
      source, viewId, theme, true
    )

    if (!ScraperPresets.includes(this.preset)) {
      throw new AppError(`Invalid scraper layer configuration.`)
    }
    this.LoadScrapers()
  }

  private async LoadScrapers () {
    this._scrapers = await this._sourceApi.Scrapers().get().run()
    this._scraperListChanged.next(this._scrapers)
    this.CheckScraperData()
  }

  async createScraper (conf: IPNewScraperConf) {
    const scraper = await this._sourceApi.Scrapers().add(conf).run()
    this._scrapers.push(scraper)
    this._scraperListChanged.next(this._scrapers)
    this.CheckScraperData()
  }

  async updateScraper (id: string, changes: IPScraperConf) {
    await this._sourceApi.Scrapers().Scraper(id).update(changes).run()
    const scraper = this._scrapers.find(s => s.scraper_conf_id === id)
    if (scraper) CommonUtil.mergeDeep(scraper, changes)
    this.CheckScraperData()
  }

  async deleteScraper (id: string) {
    await this._sourceApi.Scrapers().Scraper(id).delete().run()
    this._scrapers.splice(
      this._scrapers.findIndex(s => s.scraper_conf_id === id), 1
    )
    this._scraperListChanged.next(this._scrapers)
    if (!this.scrapers.length && this._scraperCheckAllData) {
      this._subscriptions.remove(this._scraperCheckAllData)
      this._scraperCheckAllData.unsubscribe()
      this._scraperCheckAllData = undefined
    }
  }

  protected CheckScraperData () {
    const hasData = this.features.length > 0

    const clearSub = () => {
      if (!this._scraperCheckAllData) return
      this._subscriptions.remove(this._scraperCheckAllData)
      this._scraperCheckAllData.unsubscribe()
      this._scraperCheckAllData = undefined
    }

    if (!hasData && !this._scraperCheckAllData) {
      this._scraperCheckAllData = interval(60_000).subscribe(() => {
        if (this.features.length > 0) {
          clearSub()
          return
        }

        this.reload()
      })

      this._subscriptions.add(this._scraperCheckAllData)
    } else if (hasData && this._scraperCheckAllData) {
      clearSub()
    }
  }
}
