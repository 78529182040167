import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-loader-backdrop',
  templateUrl: './loader-backdrop.component.html',
  styleUrls: ['./loader-backdrop.component.scss']
})
export class ComponentLoaderComponent {
  @Input() loadingText?: string
  @Input() diameter: number = 40
}
