import { Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { isEqual } from 'lodash'
import { AlertService } from '@services/core'
import { GoogleApiService } from '@services/workspace'
import AppError, { handleError } from '../../models/app-error'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { Db } from '@vip-shared/models/db-definitions'
import {} from "googlemaps"

@Component({
  selector: 'app-locations-input',
  templateUrl: './locations-input.component.html',
  styleUrls: ['./locations-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationsInputComponent),
      multi: true
    }
  ]
})
export class LocationsInputComponent implements ControlValueAccessor, OnDestroy {
  private _subscriptions = new Subscription()

  @Input() placeholder = 'Location'
  @Input() disabled = false

  @HostBinding('style.opacity')
  get opacity () {
    return this.disabled ? 0.25 : 1
  }

  @ViewChild('input', { static: true }) inputRef!: ElementRef<HTMLInputElement>

  searchText: string = ''
  private _searchTextChange = new Subject()

  options: google.maps.places.AutocompletePrediction[] = []
  locations?: Db.Helper.Ext.Location[]
  // eslint-disable-next-line
  onChange = (locations?: Db.Helper.Ext.Location[]) => {}
  // eslint-disable-next-line
  onTouched = () => {}

  get value (): Db.Helper.Ext.Location[] | undefined {
    return this.locations
  }

  constructor (
    private _alertService: AlertService,
    private _googleApiService: GoogleApiService
  ) {
    this._subscriptions.add(
      this._searchTextChange.asObservable()
      .pipe(debounceTime(500))
      .subscribe(this.AddressLookup.bind(this))
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  formatAutocomplete () {
    return (val: google.maps.places.AutocompletePrediction) => val ? val.description : ''
  }

  onTextChange () {
    this._searchTextChange.next({})
  }

  writeValue (value?: Db.Helper.Ext.Location | Db.Helper.Ext.Location[]) {
    if (!value) {
      this.locations = undefined
    } else {
      const insertValue = (value: Db.Helper.Ext.Location) => {
        if (!this.locations || !this.locations.find(l => isEqual(l, value))) {
          if (!this.locations) this.locations = []
          this.locations.push(value)
        }
      }

      if (Array.isArray(value)) value.forEach(v => insertValue(v))
      else insertValue(value)
    }
    this.onChange(this.value)
  }

  registerOnChange (fn: (locations?: Db.Helper.Ext.Location[]) => void) {
    this.onChange = fn
  }

  registerOnTouched (fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState (isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  async add (event: MatAutocompleteSelectedEvent) {
    if (this.disabled) return
    const val = this.searchText
    try {
      const value = event.option.value as google.maps.places.AutocompletePrediction

      const place = await this._googleApiService.getPlace(value.place_id)
      if (!place) throw new AppError(`Failed to get details for selected location.`)
      if (!place.geometry) throw new AppError(`Failed to get geometry for selected location.`)

      const country = place.address_components && place.address_components.find(c => c.types.includes('country'))
      if (!country) throw new AppError(`Location does not contain a country.`)

      if (!this.disabled) {
        const value = (type: string) => {
          const comp = place.address_components && place.address_components.find(c => c.types.includes(type))
          return comp && comp.long_name
        }

        const location: Db.Helper.Ext.Location = {
          country: country.long_name,
          postal_code: value('postal_code'),
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng(),
          administrative_area_level_1: value('administrative_area_level_1'),
          administrative_area_level_2: value('administrative_area_level_2'),
          locality: value('locality'),
          sublocality: value('sublocality'),
          neighborhood: value('neighborhood'),
          address: [
            value('route'),
            value('street_address'),
            value('street_number')
          ].filter(v => !!v).join(' ').trim() || undefined,
          formatted_address: place.formatted_address
        }
        this.writeValue(location)
      }

      if (val === this.searchText) {
        this.searchText = ''
        this.options = []
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  remove (place: Db.Helper.Ext.Location) {
    if (this.locations) {
      this.locations.splice(this.locations.indexOf(place), 1)
      if (!this.locations.length) this.locations = undefined

      this.onChange(this.value)
    }
  }

  private async AddressLookup () {
    try {
      this.options = this.searchText ? await this._googleApiService.findPlaces(
        this.searchText
      ) : []
    } catch (error: any) {
      this.options = []
      handleError(error)
      this._alertService.log(error.message)
    }
  }
}
