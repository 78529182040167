import { Injectable } from '@angular/core'
import { Subscription, Subject, Observable } from 'rxjs'
import { AppLayer } from '@core/models/layer'
import { VipApiService } from '@services/core'
import { MapControl } from '@core/models/service-templates/map-control'
import { debounceTime } from 'rxjs/operators'
import { Db } from '@vip-shared/models/db-definitions'
import OlUtil from '@core/utils/ol/ol.util'
import { PVGeojsonProperties } from '@vip-shared/models/layer-config/property-view/pv-geojson-properties'
import { environment } from 'environments/environment'
import { GsiGeoserver } from '@vip-shared/models/const/geoserver'
import * as olSource from 'ol/source'
import * as olLayer from 'ol/layer'
import * as moment from 'moment'
import Feature from 'ol/Feature'
import { WorkspaceMapService } from '@services/workspace/workspace-map/workspace-map.service'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { LayerService } from '@services/workspace/layer/layer.service'

interface PvImagery {
  title: string
  layer: olLayer.Tile<any>
  // This depends on fact that every type of imagery in features will have same workspace name
  workspace: string
  features: {
    id: string
    store: string
    workspace: string
  }[]
  type: 'panchromatic' | 'multispectral'
  // multispectral will always be 0 as there is one image, wheres panchromatic
  // has array of images
  position: number
  zIndex: number
  visible: boolean
  opacity: number
  acquisitionDate?: string
}

interface PvImagerySetup {
  type: 'panchromatic' | 'multispectral'
  // multispectral will always be 0 as there is one image, wheres panchromatic
  // has array of images
  position: number
  zIndex: number
  visible: boolean
  opacity: number
}

@Injectable({
  providedIn: 'root'
})
export class PvImageryService extends MapControl {
  private _layerSubscriptions = new Subscription()

  private readonly _zOffset = 0.01
  private readonly _emptyTile = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`
  private readonly _maxResolution = 2
  private readonly _layersPlaceholder = '_layers_'

  selectedLayer?: AppLayer
  private _layerChange = new Subject<boolean>()
  get layerChanged (): Observable<any> {
    return this._layerChange.asObservable()
  }

  private _setActiveLayer = new Subject<{layer: AppLayer, force: boolean}>()
  private _lastSetup: PvImagerySetup[] = []
  imagery: PvImagery[] = []

  private _sharedOpacity = 1
  get sharedOpacity () {
    return this._sharedOpacity
  }
  set sharedOpacity (val: number) {
    this._sharedOpacity = val
    for (const image of this.imagery) {
      image.layer.setOpacity(Math.max(0, image.opacity - (1 - val)))
    }
  }

  private _renderWarning?: string
  get renderWarning () {
    return this._renderWarning
  }

  lastSelectedFeatures: Feature[] = []

  constructor (
    private _api: VipApiService,
    private _workspaceMapService: WorkspaceMapService,
    private _workspaceService: WorkspaceService,
    layerService: LayerService
  ) {
    super()
    _workspaceService.onExit.subscribe(() => {
      this.toggleEnabled(false)
      this._collapsed = false
      this.ClearLayerSelection()
    })

    this._setActiveLayer.pipe(
      debounceTime(500)
    ).subscribe(async ev => this.SetActiveLayer(ev.layer, ev.force))

    layerService.layerSelectionChange.subscribe(l => this.SetActiveLayer(l))

    layerService.layerRemoved.subscribe(l => {
      if (l === this.selectedLayer) this.SetActiveLayer(undefined)
    })

    this._workspaceMapService.viewportChanged.subscribe(l => this.loadVisibleFeatureImagery())

    this._workspaceMapService.selectedFeatures.subscribe(this.RetrieveDatesFromFeatures.bind(this))
  }

  private RetrieveDatesFromFeatures (fs: Feature[]) {
    if (!fs.length || !this.selectedLayer || !this.selectedLayer.features.includes(fs[0])) {
      this.imagery.forEach(i => i.acquisitionDate = undefined)
      this.lastSelectedFeatures = []
      return
    }
    this.lastSelectedFeatures = fs

    const imageryDates = this.imagery.map(x => ({
      type: x.type,
      position: x.position,
      dates: [] as moment.Moment[],
      source: x
    }))

    for (const feature of fs) {
      for (const imDate of imageryDates) {
        if (imDate.type === 'multispectral') {
          const date = moment(feature.get(PVGeojsonProperties.MULTISPECTRAL_ACQUISITION_DATE))
          if (date.isValid()) imDate.dates.push(date)

        } else if (imDate.type === 'panchromatic') {
          const dates = feature.get(PVGeojsonProperties.PANCHROMATIC_ACQUISITION_DATES)
          const date = moment(dates && dates[imDate.position])
          if (date.isValid()) imDate.dates.push(date)
        }
      }
    }

    for (const imDate of imageryDates) {
      if (!imDate.dates.length) {
        imDate.source.acquisitionDate = undefined
        continue
      }

      const min = moment.min(imDate.dates).startOf('day')
      const max = moment.max(imDate.dates).startOf('day')

      if (min.isSame(max)) {
        imDate.source.acquisitionDate = min.format('DD MMM YY')
      } else {
        imDate.source.acquisitionDate = `${min.format('DD MMM YY')} - ${max.format('DD MMM YY')}`
      }
    }
  }

  private ClearLayerSelection () {
    this._renderWarning = undefined
    this._layerSubscriptions.unsubscribe()
    this._layerSubscriptions = new Subscription()
    this.selectedLayer = undefined
    this.lastSelectedFeatures = []
    this.toggleEnabled(false)
    this.ClearImagery()
  }

  private ClearImagery () {
    for (const image of this.imagery) {
      this._workspaceMapService.removeMapLayer(image.layer)
    }
    this.PreserveLayerSetup()
    this.imagery.splice(0)
  }

  private PreserveLayerSetup () {
    this._lastSetup = this.imagery.map(x => ({
      opacity: x.opacity,
      position: x.position,
      type: x.type,
      visible: x.visible,
      zIndex: x.zIndex
    }))
  }

  setActiveLayer (layer: AppLayer, force: boolean = false) {
    this._setActiveLayer.next({ layer, force })
  }

  private async SetActiveLayer (layer?: AppLayer, force: boolean = false) {
    if (this.selectedLayer === layer) return
    if (
      !layer ||
      layer.preset !== Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS ||
      ![Db.Vip.LayerMode.ML_HE, Db.Vip.LayerMode.ML_QA].includes(layer.layerMode as any)
    ) {
      this.ClearLayerSelection()
      return
    }

    if (this.selectedLayer) this.ClearLayerSelection()
    this.selectedLayer = layer

    this.loadVisibleFeatureImagery()
    this.toggleEnabled(true)

    this._layerSubscriptions.add(
      this.selectedLayer.reloaded.subscribe(() => this.loadVisibleFeatureImagery())
    )

    this._layerSubscriptions.add(
      this.selectedLayer.orderChange.subscribe(() => this.adjustOrder())
    )

    this._layerChange.next(false)

    if (this.enabled || force) {
      if (!this.visible) {
        this.toggleCollapse(true, true)
        setTimeout(() => {
          this.toggleEnabled(true, true)
        }, 500)
      } else {
        this.toggleEnabled(true)
      }
    }
  }

  // NOTE: tile urls can get long, so it's important to only load those in view.
  // Browsers allow only ~2000 character long urls, however node has limit
  // is 'headers + URI should not be more than 80 kb' which should be more than enough
  loadVisibleFeatureImagery () {
    if (!this.selectedLayer || !this.selectedLayer.baseSource) return

    const visibleInViewport = this.selectedLayer.baseSource.getFeaturesInExtent(
      this._workspaceMapService.extent
    ).filter(f => !OlUtil.featureFilteredOut(f))
    const anchorZ = this.selectedLayer.layer ? this.selectedLayer.layer.getZIndex() : 0

    const hadImagery = !!this.imagery.length
    this.PreserveLayerSetup()
    for (const image of this.imagery) {
      image.features = []
    }

    const imagery: PvImagery[] = [...this.imagery]
    let hasImagery = false
    const createImagery = (type: PvImagery['type'], position: number, id: string, gsLocation: string) => {
      hasImagery = true
      const { workspace, store } = this.GetImageLocation(gsLocation)

      let match = imagery.find(x => x.type === type && x.position === position)
      const zIndex = Math.min(anchorZ, ...imagery.map(x => x.zIndex)) - this._zOffset

      if (!match) {
        const source = new olSource.TileWMS({
          url: `${GsiGeoserver.Placeholder}/${workspace}/wms`,
          params: {
            LAYERS: '',
            TILED: true,
            VERSION: '1.1.0'
          },
          serverType: 'geoserver',
          projection: 'string',
          crossOrigin: 'anonymous'
        })

        match = {
          type,
          position,
          title: `${type[0].toUpperCase() + type.slice(1)}${position > 0 ? ` ${position + 1}` : ''}`,
          workspace,
          layer: new olLayer.Tile({
            zIndex,
            source
          }),
          features: [],
          zIndex,
          visible: true,
          opacity: 1
        }

        source.setTileLoadFunction((tile: any, src) => {
          tile.getImage().src = !(match as PvImagery).features.length ? this._emptyTile : `${environment.VIPServer}` +
          `${this._api.orm.Proxy(
            this._workspaceService.proxyAppendWsQuery(
              Base64.encodeURI(this._api.appendJwtQuery(`${src}&`)),
              this.selectedLayer && this.selectedLayer.id
            )
          ).endpoint}`
        })

        imagery.push(match)
      }

      if (!match.features.find(xf => xf.id === id)) {
        match.features.push({ id, store, workspace })
      }
    }

    for (const f of visibleInViewport) {
      const multispectral: undefined | string = f.get(PVGeojsonProperties.MULTISPECTRAL_STORE)
      const panchromatic: undefined | string[] = f.get(PVGeojsonProperties.PANCHROMATIC_STORES)
      const id = f.get(this.selectedLayer.idKey)

      if (multispectral) createImagery('multispectral', 0, id, multispectral)

      if (Array.isArray(panchromatic)) {
        for (let i = 0; i < panchromatic.length; i++) {
          if (panchromatic[i]) createImagery('panchromatic', i, id, panchromatic[i])
        }
      }
    }

    if (!hasImagery) {
      this.ClearImagery()
      return
    }

    this.imagery = this.ApplyPreviousSetup(imagery)
    const mapLayers = this._workspaceMapService.map.getLayers().getArray()

    const render = this._workspaceMapService.resolution <= this._maxResolution
    this._renderWarning = undefined
    if (!render) {
      this._renderWarning = 'Zoom level is too low. Please zoom in to view imagery.'
    }

    for (const image of this.imagery) {
      const src: any = image.layer.getSource()
      if (!src.updateParams) return

      src.updateParams({
        LAYERS: image.features.map(f => `${f.workspace}:${f.store}`).join(','),
        TILED: true,
        VERSION: '1.1.0'
      })

      if (!render) {
        this._workspaceMapService.removeMapLayer(image.layer)
        continue
      }

      if (!mapLayers.includes(image.layer)) {
        this._workspaceMapService.addMapLayer(image.layer)
      }
    }

    if (!hadImagery) this.RetrieveDatesFromFeatures(this.lastSelectedFeatures)
  }

  private ApplyPreviousSetup (imagery: PvImagery[]): PvImagery[] {
    const reordered: PvImagery[] = []
    for (const prev of this._lastSetup) {
      const [match] = imagery.splice(imagery.findIndex(x => x.position === prev.position && x.type === prev.type), 1)
      if (!match) continue

      const item = { ...match, ...prev }
      item.layer.setVisible(item.visible)
      item.layer.setOpacity(item.opacity - (1 - this._sharedOpacity))
      reordered.push(item)
    }

    reordered.push(...imagery)
    return reordered
  }

  adjustOrder () {
    if (!this.selectedLayer || !this.selectedLayer.layer) return

    const anchorZ = this.selectedLayer.layer.getZIndex()
    for (let i = 0; i < this.imagery.length; i++) {
      const item = this.imagery[i]
      item.zIndex = anchorZ - ((i + 1) * this._zOffset)
      item.layer.setZIndex(item.zIndex)
    }
  }

  toggleImageVisibility (image: PvImagery, visible?: boolean) {
    if (visible === undefined) visible = !image.visible
    image.visible = visible
    image.layer.setVisible(visible)
  }

  setImageOpacity (image: PvImagery, opacity: number) {
    image.opacity = opacity
    image.layer.setOpacity(image.opacity)
  }

  zoomToMinZoom () {
    if (!this.selectedLayer) return
    const feature = this.selectedLayer.features.find(x => OlUtil.getFeatureSysProps(x).selected) ||
      this.selectedLayer.features[0]

    if (!feature) return

    const extent = new olSource.Vector({
      features: [feature]
    }).getExtent()

    this._workspaceMapService.zoomToExtent(extent, {
      minResolution: this._maxResolution - 0.01,
      padding: Array(4).fill(0) as any
    })
  }

  private GetImageLocation (gsLocation: string) {
    let workspace: string = GsiGeoserver.Workspace.PROPERTY_VIEW
    let store = gsLocation
    if (store && store.includes(':')) {
      const [ws, str] = store.split(':')
      workspace = ws
      store = str
    }

    return { workspace, store }
  }
}
