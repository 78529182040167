import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { DatasetJoinQueryEditorComponent } from './dataset-join-query-editor/dataset-join-query-editor.component'

@Component({
  selector: 'app-dataset-join-query-preview',
  templateUrl: './dataset-join-query-preview.component.html',
  styleUrls: ['./dataset-join-query-preview.component.scss']
})
export class DatasetJoinQueryPreviewComponent implements DialogCleanup, OnDestroy {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs () { return }

  @Input() query?: NonNullable<Db.Vip.Geo.ILayer['parameters']>['preset_dataset_sequence']
  @Input() datasets: (Db.Vip.Geo.IPresetDataset & { disabled?: boolean, disabledReason?: string })[] = []
  @Output() queryChange = new EventEmitter<NonNullable<Db.Vip.Geo.ILayer['parameters']>['preset_dataset_sequence']>()

  constructor (
    private _dialog: MatDialog
  ) { }

  ngOnDestroy () {
    this._destroyDialogs()
  }

  getDatasetName (tag: string) {
    const el = this.datasets.find(x => x.preset_dataset_tag === tag)
    return el && el.display_name
  }

  openDatasetQueryEditor () {
    this._trackDialog(
      DatasetJoinQueryEditorComponent.open(
        this._dialog, {
          datasets: this.datasets,
          query: this.query
        }
      )
    ).afterClosed().subscribe(query => {
      if (query) {
        this.query = query
        this.queryChange.next(this.query)
      }
    })
  }
}

applyMixins(DatasetJoinQueryPreviewComponent, [DialogCleanup])
