import { IColourPalette, IRgba } from '@core/types'
import { ConvertUtil } from '@core/utils/convert/convert.util'
import AppError from '@core/models/app-error'
import * as Color from 'color'
import * as olStyle from 'ol/style'
import * as olColor from 'ol/color'

export class ColourUtil {
  static defaultDrawStyle = new olStyle.Style({
    fill: new olStyle.Fill({
      color: 'rgba(255, 255, 255, 0.4)'
    }),
    stroke: new olStyle.Stroke({
      color: '#ffcc33',
      width: 2
    }),
    image: new olStyle.Circle({
      radius: 7,
      fill: new olStyle.Fill({
        color: '#ffcc33'
      })
    })
  })

  static defaultOldPreviewStyle = [
    new olStyle.Style({
      fill: new olStyle.Fill({
        color: 'rgba(255, 255, 255, 0.4)'
      }),
      stroke: new olStyle.Stroke({
        color: 'rgba(110, 110, 110, 0.7)',
        width: 2
      }),
      image: new olStyle.Circle({
        radius: 7,
        fill: new olStyle.Fill({
          color: 'rgba(255, 255, 255, 0.4)'
        }),
        stroke: new olStyle.Stroke({
          color: 'rgba(110, 110, 110, 0.7)',
          width: 2
        })
      })
    }),
    new olStyle.Style({
      stroke: new olStyle.Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 2,
        lineDash: [5, 10]
      }),
      image: new olStyle.Circle({
        radius: 7,
        stroke: new olStyle.Stroke({
          color: 'rgba(255, 255, 255, 0.7)',
          lineDash: [5, 10],
          width: 2
        })
      })
    })
  ]

  static defaultEditStyle = {
    active: [
      new olStyle.Style({
        fill: new olStyle.Fill({
          color: [255, 255, 255, 0.6]
        }),
        stroke: new olStyle.Stroke({
          color: 'white',
          width: 8
        }),
        image: new olStyle.Circle({
          fill: new olStyle.Fill({
            color: [255, 255, 255, 0.6]
          }),
          stroke: new olStyle.Stroke({
            color: 'white',
            width: 8
          }),
          radius: 8
        })
      }),
      new olStyle.Style({
        stroke: new olStyle.Stroke({
          color: '#3399CC',
          width: 3
        }),
        image: new olStyle.Circle({
          stroke: new olStyle.Stroke({
            color: '#3399CC',
            width: 3
          }),
          radius: 8
        })
      }),
      new olStyle.Style({
        stroke: new olStyle.Stroke({
          color: '#3399CC',
          lineDash: [5, 10],
          width: 3
        }),
        image: new olStyle.Circle({
          stroke: new olStyle.Stroke({
            color: '#3399CC',
            lineDash: [5, 10],
            width: 3
          }),
          radius: 8
        })
      })
    ],
    inactive: [
      new olStyle.Style({
        fill: new olStyle.Fill({
          color: [255, 255, 255, 0.6]
        }),
        stroke: new olStyle.Stroke({
          color: 'white',
          width: 8
        }),
        image: new olStyle.Circle({
          fill: new olStyle.Fill({
            color: [255, 255, 255, 0.6]
          }),
          stroke: new olStyle.Stroke({
            color: 'white',
            width: 8
          }),
          radius: 8
        })
      }),
      new olStyle.Style({
        stroke: new olStyle.Stroke({
          color: '#a6b3ba',
          width: 3
        }),
        image: new olStyle.Circle({
          stroke: new olStyle.Stroke({
            color: '#a6b3ba',
            width: 3
          }),
          radius: 8
        })
      }),
      new olStyle.Style({
        stroke: new olStyle.Stroke({
          color: '#a6b3ba',
          lineDash: [5, 10],
          width: 3
        }),
        image: new olStyle.Circle({
          stroke: new olStyle.Stroke({
            color: '#a6b3ba',
            lineDash: [5, 10],
            width: 3
          }),
          radius: 8
        })
      })
    ]
  }

  static colourPalette: IColourPalette[] = [
      // dark red
    {
      hex: '#800000',
      rgba: { R: 128, G: 0, B: 0, A: 1 }
    },
      // red
    {
      hex: '#ff0000',
      rgba: { R: 255, G: 0, B: 0, A: 1 }
    },
      // green/yellow
    {
      hex: '#808000',
      rgba: { R: 128, G: 128, B: 0, A: 1 }
    },
      // yellow
    {
      hex: '#FFFF00',
      rgba: { R: 255, G: 255, B: 0, A: 1 }
    },
      // dark green
    {
      hex: '#008000',
      rgba: { R: 0, G: 128, B: 0, A: 1 }
    },
      // green
    {
      hex: '#00FF00',
      rgba: { R: 0, G: 255, B: 0, A: 1 }
    },
      // dark blue
    {
      hex: '#008080',
      rgba: { R: 0, G: 128, B: 128, A: 1 }
    },
      // cyan
    {
      hex: '#00FFFF',
      rgba: { R: 0, G: 255, B: 255, A: 1 }
    },
      // dark purple
    {
      hex: '#800000',
      rgba: { R: 0, G: 0, B: 128, A: 1 }
    },
      // blue
    {
      hex: '#0000FF',
      rgba: { R: 0, G: 0, B: 255, A: 1 }
    },
      // purple
    {
      hex: '#800080',
      rgba: { R: 128, G: 0, B: 128, A: 1 }
    },
      // pink
    {
      hex: '#FF00FF',
      rgba: { R: 255, G: 0, B: 255, A: 1 }
    },
      // black
    {
      hex: '#000000',
      rgba: { R: 0, G: 0, B: 0, A: 1 }
    },
      // dark grey
    {
      hex: '#808080',
      rgba: { R: 128, G: 128, B: 128, A: 1 }
    },
      // grey
    {
      hex: '#C0C0C0',
      rgba: { R: 192, G: 192, B: 192, A: 1 }
    },
      // white
    {
      hex: '#FFFFFF',
      rgba: { R: 255, G: 255, B: 255, A: 1 }
    }
  ]

  static defaultTransportColour: {[key: string]: olColor.Color} = {
    car: Object.values(ColourUtil.colourPalette[0].rgba) as olColor.Color,
    foot: Object.values(ColourUtil.colourPalette[1].rgba) as olColor.Color,
    cycle: Object.values(ColourUtil.colourPalette[2].rgba) as olColor.Color
  }

  private static nextColour (type: 'string'): IterableIterator<string>
  private static nextColour (type: 'rgba'): IterableIterator<IRgba>
  private static nextColour (type: 'arr'): IterableIterator<olColor.Color>
  private static *nextColour (type: 'string' | 'rgba' | 'arr'): IterableIterator<any> {
    let i = -1

    while (true) {
      i++
      if (i === ColourUtil.colourPalette.length) i = 0
      switch (type) {
        case 'string':
          yield ColourUtil.colourPalette[i].hex
          break
        case 'rgba':
          yield ColourUtil.colourPalette[i].rgba
          break
        case 'arr':
          yield Object.values(ColourUtil.colourPalette[i].rgba)
          break
        default:
          yield
          break
      }
    }
  }

  static getAsNumberArray (paletteIndex: number): [number, number, number, number] {

    const colorPalette = this.colourPalette[paletteIndex]

    if (!colorPalette) {
      throw new AppError(`Colour with index ${paletteIndex} does not exist in palette.`)
    }
    const color: [number, number, number, number] = [colorPalette.rgba.R, colorPalette.rgba.G, colorPalette.rgba.B, colorPalette.rgba.A]

    return color
  }

  static getContrastBWTextHex (hex?: string | number[]) {
    if (Array.isArray(hex)) {
      hex = ConvertUtil.rgbToHex(hex)
    }
    if (!hex) return '#000'
    // about half of 256. Lower threshold equals more dark text on dark background
    const threshold = 130

    const cutHex = (h: string) => h.charAt(0) === '#' ? h.substring(1, 7) : h
    const hexPart = (h: string, start: number, end: number) => parseInt((cutHex(h)).substring(start, end), 16)

    const hRed = hexPart(hex, 0, 2)
    const	hGreen = hexPart(hex, 2, 4)
    const	hBlue = hexPart(hex, 4, 6)

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000

    return cBrightness > threshold ? '#000' : '#fff'
  }

  static invertRgb (rgb: {R: number, G: number, B: number}, bw = false) {
    const inv = {
      R: 255 - rgb.R,
      G: 255 - rgb.G,
      B: 255 - rgb.B
    }

    if (bw) {
      return this.getBw(rgb)
    }
    return inv
  }

  static getBrightness (rgb: {R: number, G: number, B: number}, alg: 'hsp' | 'w3c' = 'hsp') {
    if (alg === 'hsp') {
      return Math.sqrt(rgb.R * rgb.R * .241 + rgb.G * rgb.G * .691 + rgb.B * rgb.B * .068)
    } else {
      return (rgb.R * 299 + rgb.G * 587 + rgb.B * 114) / 1000
    }
  }

  static getBw (rgb: {R: number, G: number, B: number}, alg: 'hsp' | 'w3c' = 'hsp') {
    if (alg === 'hsp') {
      return ColourUtil.getBrightness(rgb, alg) < 130 ? { R: 255, G: 255, B: 255 } : { R: 0, G: 0, B: 0 }
    } else {
      return ColourUtil.getBrightness(rgb, alg) <= 125 ? { R: 255, G: 255, B: 255 } : { R: 0, G: 0, B: 0 }
    }
  }

  static colourDistance (c1: [number, number, number], c2: [number, number, number]) {
    let d = 0

    for (let i = 0; i < c1.length; i++) {
      d += (c1[i] - c2[i]) * (c1[i] - c2[i])
    }

    return Math.sqrt(d)
  }

  static increaseBrightness (rgb: {R: number, G: number, B: number}, percent) {
    return {
      R: (0 | (1 << 8) + rgb.R + (256 - rgb.R) * percent / 100),
      G: (0 | (1 << 8) + rgb.G + (256 - rgb.G) * percent / 100),
      B: (0 | (1 << 8) + rgb.B + (256 - rgb.B) * percent / 100)
    }
  }

  static colorLuminance ({ R, G, B }: {R: number, G: number, B: number}, lum = 0) {
    return {
      R: Math.round(Math.min(Math.max(0, R + (R * lum)), 255)),
      G: Math.round(Math.min(Math.max(0, G + (G * lum)), 255)),
      B: Math.round(Math.min(Math.max(0, B + (B * lum)), 255))
    }
  }

  static getContrastRating (rgb1: {R: number, G: number, B: number}, rgb2: {R: number, G: number, B: number}) {
    // minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes
    return Color.rgb(rgb1.R, rgb1.G, rgb1.B).contrast(Color.rgb(rgb2.R, rgb2.G, rgb2.B))
  }

  static changeHue (rgb: {R: number, G: number, B: number}, degree: number) {
    const arr = Color.rgb(rgb.R, rgb.G, rgb.B).rotate(degree).array()
    return {
      R: arr[0],
      G: arr[1],
      B: arr[2]
    }
  }

  static rgbToHSL ({ R, G, B }: {R: number, G: number, B: number}) {
    const [h, s, l] = Color.rgb(R, G, B).hsl().array()
    return { h, s, l }
  }

  static hslToRGB ({ h, s, l }: {h: number, s: number, l: number}) {
    const [R, G, B] = Color.hsl(h, s, l).rgb().array()
    return { R, G, B }
  }

  static colorsAverage (colors: ([number, number, number] | [number, number, number, number])[], alpha = true) {
    const values = colors
    .filter(c => !c.includes(NaN))
    .map(c => Color(c).hsl().array())

    let h = 0, s = 0, l = 0

    for (const [h1, s1, l1] of values) {
      h += h1
      s += s1
      l += l1
    }

    h /= values.length
    s /= values.length
    l /= values.length

    const arr = Color({ h, s, l }).rgb().array()
    if (alpha) {
      let alphaAv = colors.reduce((sum, c) => sum + (c[3] || 1), 0) / colors.length
      if (colors.length > 1) alphaAv = Math.max(alphaAv, 0.1)
      arr.push(alphaAv)
    }
    return arr
  }
}
