import { Directive, HostListener, Output, EventEmitter, Input, OnDestroy, ElementRef } from '@angular/core'
import { Subscription, fromEvent } from 'rxjs'

@Directive({
  selector: '[appHoverTime]'
})
export class HoverTimeDirective implements OnDestroy {

  @Input() hoverTimeS: number = 1
  @Input() condition?: () => boolean
  @Output() hovered = new EventEmitter()
  @Output() hoverReset = new EventEmitter()

  private _mouseDown = false
  private _mouseIsDown = false
  private _subscriptions = new Subscription()
  private _timeout?: ReturnType<typeof setTimeout>

  @Input() get mouseDown () {
    return this._mouseDown
  }
  set mouseDown (val: boolean | string) {
    this._mouseDown = val === '' ? true : !!val
  }

  constructor (private _el: ElementRef<HTMLElement>) {
    this._subscriptions.add(
      fromEvent(document, 'mousedown').subscribe(() => this._mouseIsDown = true)
    )
    this._subscriptions.add(
      fromEvent(document, 'mouseup').subscribe(() => this._mouseIsDown = false)
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  @HostListener('mouseleave') onMouseLeave () {
    this.ClearTimeout()
  }

  @HostListener('mouseenter') onMouseEnter () {
    if (this.Condition() && this.MouseCondition()) this.SetTimeout()
  }

  @HostListener('mousedown') onMouseDown () {
    if (this.Condition()) this.SetTimeout()
  }

  private Condition () {
    return this.condition ? this.condition() : true
  }

  private MouseCondition () {
    return !this._mouseDown || this._mouseIsDown
  }

  private ClearTimeout () {
    this._el.nativeElement.classList.remove('app-hover-active')
    if (this._timeout) {
      clearTimeout(this._timeout)
    }
  }
  private SetTimeout () {
    this.ClearTimeout()
    this._el.nativeElement.classList.add('app-hover-active')
    this._timeout = setTimeout(() => {
      if (this.Condition()) this.hovered.next({})
      this.ClearTimeout()
    }, this.hoverTimeS * 1000)
  }
}
