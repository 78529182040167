import { Component, Inject, ElementRef, HostListener } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'

@Component({
  selector: 'app-option-list-dialog',
  templateUrl: './option-list-dialog.component.html',
  styleUrls: ['./option-list-dialog.component.scss']
})
export class OptionListDialogComponent {
  @HostListener('window:click', ['$event'])
  onclick (e: MouseEvent) {
    if (!e.composedPath().includes(this._el.nativeElement)) this._dialogRef.close()
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public options: string[],
    private _el: ElementRef,
    private _dialogRef: MatDialogRef<OptionListDialogComponent>
  ) {}

  static setOptions (opts: any, click: {x: number, y: number}): MatDialogConfig {
    const items = Object.keys(opts).length
    const height = Math.min(items * 32, 200)
    const width = 90
    if (click.y + height > window.innerHeight) {
      click.y = window.innerHeight - height
    }
    if (click.x + width > window.innerWidth) {
      click.x = window.innerWidth - width
    }

    const options: MatDialogConfig = {
      data: opts,
      hasBackdrop: false,
      autoFocus: false,
      position: {
        top: `${click.y}px`,
        left: `${click.x}px`
      }
    }

    return options
  }

  selectOption (option: string) {
    this._dialogRef.close(option)
  }
}
