import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core'
import { VipApiService, AlertService, AuthService, SettingsService } from '@services/core'
import { MatTableDataSource, MatTable } from '@angular/material/table'
import { Db } from '@vip-shared/models/db-definitions'
import { MatSort } from '@angular/material/sort'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { WorkspacesService } from '@services/explorer'
import { tableRowHighlight } from '@core/animations'
import * as moment from 'moment'
import { Subscription, Subject } from 'rxjs'
import { API } from '@vip-shared/interfaces/api-helper'

interface TableFilter {
  customer?: string
  name?: string
  surname?: string
  immutable: boolean
}

type TableRow = Required<Db.Vip.Prj.IVWorkspacePermission> & { highlight?: boolean }

@Component({
  selector: 'app-workspace-user-table',
  templateUrl: './workspace-user-table.component.html',
  styleUrls: ['./workspace-user-table.component.scss'],
  animations: [tableRowHighlight]
})
export class WorkspaceUserTableComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription()
  private _initSort = new Subject()
  @Input() workspaceId!: number
  @Output() close = new EventEmitter()
  @ViewChild(MatSort, { static: false }) sort?: MatSort
  @ViewChild('table', { static: false }) table?: MatTable<any>
  filter: TableFilter = {
    customer: '',
    name: '',
    surname: '',
    immutable: false
  }
  permissions: Required<Db.Vip.Prj.IVWorkspacePermission>[] = []
  roles?: Db.Vip.Cst.IRole[]
  dataSource?: MatTableDataSource<TableRow>
  displayedColumns = ['user_forename', 'user_surname', 'user_email', 'customer_name', 'updated_at', 'role', 'valid_from', 'valid_to', 'actions']
  loading = true
  disableActions = false
  editorPermissions: number = -1

  workspace!: API.Res.UserWorkspace

  stickyHeader = true

  today = moment().startOf('day')

  get sysMaintainer (): boolean {
    return this._authService.isSysMaintainer
  }

  get permissionLevel (): number {
    return this._authService.permissionsLevel || 999
  }

  constructor (
    private _api: VipApiService,
    private _alertService: AlertService,
    private _authService: AuthService,
    private _workspacesService: WorkspacesService,
    private _change: ChangeDetectorRef,
    private _settingsService: SettingsService
  ) {
    this.stickyHeader = !this._settingsService.isIEorEdge(false)
    this._subscriptions.add(
      this._initSort.subscribe(() => {
        if (!this.dataSource || !this.sort) {
          setTimeout(() => this._initSort.next({}), 100)
          return
        }
        this.dataSource.sort = this.sort
      })
    )
  }

  ngOnInit () {
    this.loadData()
    this.workspace = this._workspacesService.workspaces
      .find(ws => ws.workspace_id === this.workspaceId) as API.Res.UserWorkspace
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  cantModifyAccess (user: Db.Vip.Prj.IVWorkspacePermission) {
    return this.disableActions || !user.workspace_permissions_level || user.permission_override || this.workspace.demo
  }

  cantToggleAccess (user: TableRow) {
    const systemScopeOverride = user.permission_override && !user.demo_ws_permission_override
    return this.disableActions || systemScopeOverride
  }

  async updatePermissions (user: TableRow) {
    try {
      this.disableActions = true
      const permissions = user.workspace_permissions_level || Db.Vip.Role.WS_VIEWER
      const updated = await this._api.orm.Workspaces().Workspace(this.workspaceId).Users()
      .changePermissions({
        user_id: user.user_id,
        permissions_level: permissions,
        valid_from: user.valid_from || null as any,
        valid_to: user.valid_to || null as any
      }).run()

      if (!this._authService.isSysAdmin && +user.user_id === +this._authService.userId && permissions > Db.Vip.Role.WS_ADMIN) {
        this._workspacesService.reloadWorkspace(this.workspaceId, true)
        this._alertService.log('You no longer have permissions to edit access to this workspace.')
        this.close.emit()
      }

      // Remove valid_from/to dates because reapplying them
      // would trigger infinite loop of updates
      delete updated.valid_from
      delete updated.valid_to

      Object.assign(user, updated)
      this.disableActions = false
      this.AnimateTo(user)

    } catch (error: any) {
      this._alertService.log(error.message)
      this.loadData()
    } finally {
      this.disableActions = false
    }
  }

  toggleImmutable () {
    this.filter.immutable = !this.filter.immutable
    this.ReloadFilter()
  }

  async removePermissions (user: TableRow) {
    try {
      this.disableActions = true
      const updated = await this._api.orm.Workspaces().Workspace(this.workspaceId).Users()
      .delete(user.user_id).run()
      if (!this._authService.isSysAdmin && user.user_id === this._authService.userId) {
        this._workspacesService.spliceLocalWorkspace(this.workspaceId)
        this._alertService.log('You no longer have access to this workspace.')
        this.close.emit()
      }

      Object.assign(user, updated)
      this.disableActions = false
      this.AnimateTo(user)
    } catch (error: any) {
      this._alertService.log(error.message)
      this.loadData()
    } finally {
      this.disableActions = false
    }
  }

  async filterByCustomer (customerName: string) {
    this.filter.customer = customerName
    this.filter.name = ''
    this.filter.surname = ''
    this.ReloadFilter()
  }

  applyFilter (key: 'name' | 'surname' | 'customer', value: string) {
    this.filter[key] = value
    this.ReloadFilter()
  }

  async toggleUserAccess (e: MatSlideToggleChange, user: TableRow) {
    if (e.checked !== (!!user.workspace_permissions_level)) {
      if (e.checked) await this.updatePermissions(user)
      else await this.removePermissions(user)
    }
  }

  async loadData () {
    try {
      if (!this.roles) this.roles = await this._api.orm.Workspaces().getRoles().run()
      const permissions = await this._api.orm.Workspaces().Workspace(this.workspaceId)
        .Users().get().run()

      for (const perm of permissions) {
        // Map values to moment objects, so that we can limit every users range of dates for
        // active access selection
        perm.customer_valid_from = perm.customer_valid_from ? moment(perm.customer_valid_from) as any : this.today
        if (perm.customer_valid_to) perm.customer_valid_to = moment(perm.customer_valid_to) as any
      }

      permissions.splice(permissions.findIndex(x => +x.user_id === this._authService.userId), 1)
      this.permissions = permissions

      this.dataSource = new MatTableDataSource(this.permissions)
      this.dataSource.filterPredicate = this.FilterTable.bind(this)
      this._initSort.next({})

      // Get editor permissions
      const editor = this.permissions.find(account => +account.user_id === +this._authService.userId)
      this.editorPermissions = editor && editor.workspace_permissions_level || Db.Vip.Role.WS_VIEWER
      this.ReloadFilter()
    } catch (error: any) {
      this._alertService.log(error.message)
    } finally {
      this.loading = false
    }
  }

  private AnimateTo (row: TableRow) {
    // NOTE: Can find precise element with:
    // ._elementRef.nativeElement.children[._renderRows.find(x => x.data.user_id === userId)]
    // But row will always *should* to top so extra calculation is not necessary
    if (this.dataSource) {
      const index = this.dataSource.filteredData.findIndex(x => x.user_id === row.user_id)
      const updatedRow: TableRow | undefined = this.dataSource.filteredData[index]
      if (!updatedRow) return

      if (this.table) {
        // NOTE: smooth scroll not enabled because it's not supported in edge
        // smooth scroll must be implemented manually
        const el = ((this.table as any)._elementRef as ElementRef).nativeElement.parentElement as HTMLElement
        const childEl = el.children[0].children[index + 1] as HTMLElement
        if (childEl) {
          childEl.scrollIntoView({
            block: 'nearest'
          })
        }
      }

       // NOTE: Hacky solution to: On access toggle, row does not get highlighted, because
      // change detection does not link change to row with actual row in the table
      setTimeout(() => {
        updatedRow.highlight = true
        setTimeout(() => this._change.detectChanges())
        setTimeout(() => {
          updatedRow.highlight = false
          setTimeout(() => this._change.detectChanges())
        }, 500)
      }, 100)
    }
  }

  private ReloadFilter () {
    if (this.dataSource) this.dataSource.filter = JSON.stringify(this.filter)
  }

  private FilterTable (data: Required<Db.Vip.Prj.IVWorkspacePermission>, filter: string): boolean {
    const f = JSON.parse(filter) as TableFilter
    // Don't show session user, it's own row
    let match = data.user_id !== this._authService.userId
    if (f) {
      if (match && f.customer) match = data.customer_name.toLowerCase().includes(f.customer.toLowerCase())
      if (match && f.name) match = data.user_forename.toLowerCase().includes(f.name.toLowerCase())
      if (match && f.surname) match = data.user_surname.toLowerCase().includes(f.surname.toLowerCase())
      if (match && data.permission_override && !data.demo_ws_permission_override) match = f.immutable
    }
    return match
  }
}
