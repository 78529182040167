import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoginComponent } from '@pages/auth/login/login.component'
import { FormsModule } from '@angular/forms'
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component'
import { VerifyEmailComponent } from './verify-email/verify-email.component'
import { RouterModule } from '@angular/router'

import { LogoutComponent } from './logout/logout.component'
import { PasswordResetComponent } from '@pages/auth/password-reset/password-reset.component'
import { CoreModule } from '@core/core.module';
import { SetupMfaComponent } from './setup-mfa/setup-mfa.component';
import { EnterOtpComponent } from './enter-otp/enter-otp.component'

@NgModule({
  declarations: [
    LoginComponent,
    ForgottenPasswordComponent,
    LogoutComponent,
    PasswordResetComponent,
    VerifyEmailComponent,
    SetupMfaComponent,
    EnterOtpComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CoreModule
  ]
})

export class AuthModule {

}
