import { Subject, Observable } from 'rxjs'

export class MapControl {
  protected _enabled = false
  protected _enabledChange = new Subject<boolean>()
  get enabled (): boolean {
    return this._enabled
  }
  get enabledChange (): Observable<boolean> {
    return this._enabledChange.asObservable()
  }

  protected _visible = false
  protected _visibleChange = new Subject<boolean>()
  get visible (): boolean {
    return this._visible
  }
  get visibleChange (): Observable<boolean> {
    return this._visibleChange.asObservable()
  }

  protected _collapsed = true
  protected _collapsedChange = new Subject<boolean>()
  get collapsed (): boolean {
    return this._collapsed
  }
  get collapsedChange (): Observable<boolean> {
    return this._collapsedChange.asObservable()
  }

  toggleEnabled (enabled?: boolean, collapse = false) {
    if (this._enabled !== enabled) {
      this._enabled = enabled !== undefined ? enabled : !this._enabled
      if (this._enabled && !collapse) this.toggleCollapse(false)
      this._enabledChange.next(this._enabled)
    }
    this.toggleVisibility(this._enabled)
  }

  toggleVisibility (visible?: boolean) {
    if ((this._enabled || visible === false) && visible !== this._visible) {
      this._visible = visible !== undefined ? visible : !this._visible
      this._visibleChange.next(this._visible)
    }
  }

  toggleCollapse (collapsed?: boolean, force = false) {
    if (force || (this._enabled && this._collapsed !== collapsed)) {
      this._collapsed = collapsed !== undefined ? collapsed : !this._collapsed
      this._collapsedChange.next(this._collapsed)
    }
  }
}
