import { Directive, Input, ElementRef, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { OptionListDialogComponent } from '@core/components/option-list-dialog/option-list-dialog.component'
import { UntypedFormControl } from '@angular/forms'

@Directive({
  selector: '[appAutoComplete]'
})
export class AutoCompleteDirective implements OnDestroy, OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appAutoComplete') sourceList?: string[]
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('autoCompleteControl') formControl?: UntypedFormControl
  private _dialogRef?: MatDialogRef<OptionListDialogComponent>
  private readonly _maxResult = 50
  _focusInterval?: any
  _focusTimeout?: any
  constructor (
    private _el: ElementRef<HTMLInputElement>,
    private _dialog: MatDialog
  ) {}

  ngOnInit () {
    const el = this._el.nativeElement
    if (['TEXTAREA', 'INPUT'].includes(el.tagName) && this.sourceList) {
      el.oninput = () => this.inputChange()
    }
  }

  ngOnDestroy () {
    if (this._dialogRef) this._dialogRef.close()
  }

  inputChange () {
    if (!this.sourceList) return

    const val = this._el.nativeElement.value.toLowerCase()
    const filtered = this.sourceList.filter(x => x.toLowerCase().includes(val)).slice(0, this._maxResult)
    if (this._dialogRef) this._dialogRef.close()

    if (filtered.length < 2) return

    const { bottom: y, left: x } = this._el.nativeElement.getBoundingClientRect()
    this._dialogRef = this._dialog.open(
      OptionListDialogComponent, OptionListDialogComponent.setOptions(filtered, { x, y })
    )
    this._dialogRef.afterClosed().subscribe((option?: string) => {
      if (option) {
        if (this.formControl) this.formControl.setValue(option)
        else this._el.nativeElement.value = option
      }
    })

    this.forceFocus()
  }

  forceFocus () {
    if (this._focusTimeout) clearTimeout(this._focusTimeout)
    if (this._focusInterval) clearInterval(this._focusInterval)

    this._focusInterval = setInterval(() =>
      this._el.nativeElement.focus(),
      1
    )

    this._focusTimeout = setTimeout(() => clearInterval(this._focusInterval), 500)
  }
}
