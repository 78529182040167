import { Directive, ElementRef, HostListener, Input } from '@angular/core'

@Directive({
  selector: '[appOverflowAnimate]'
})
export class OverflowAnimateDirective {
  constructor (private _el: ElementRef) {}

  @Input() minWidth?: number = 100
  @HostListener('mouseover') onMouseOver () {
    this.CalculateOffset()
  }

  @HostListener('mouseleave') onMouseLeave () {
    this.ResetOffset()
  }

  private CalculateOffset () {
    const pixelSpeed = 0.07
    const element = this._el.nativeElement
    if (element.parentElement && element.parentElement.clientWidth < element.offsetWidth) {
      const margin = element.parentElement.clientWidth - element.offsetWidth - 10
      let speed = Math.abs(margin * pixelSpeed)
      const speedReduce = Math.floor(speed / 10) * 6
      speed -= speedReduce

      element.style.transition = `${speed}s`
      element.style.marginLeft = `${margin}px`
      element.parentElement.style.minWidth = `${this.minWidth}px`
    }
  }

  private ResetOffset () {
    const pixelSpeed = 0.01
    const element = this._el.nativeElement
    if (element.parentElement && element.parentElement.clientWidth < element.offsetWidth) {
      const margin = element.parentElement.clientWidth - element.offsetWidth - 10
      const speed = Math.abs(margin * pixelSpeed)
      element.style.transition = `${speed}s`
    }
    element.style.marginLeft = `0`
  }
}
