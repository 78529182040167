import { Component, Input, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core'
import { VipApiService, AlertService, AuthService, SettingsService } from '@services/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { WorkspacesService } from '@services/explorer'
import { IRCustomerExtended } from '@vip-shared/interfaces'
import * as moment from 'moment'
import { Subject, Subscription } from 'rxjs'

type ICustomerAccess = IRCustomerExtended & {
  allowed: boolean
  productAccess: boolean
  hasTriggeredProductAccess?: boolean
  valid_from?: string
  valid_to?: string
}
interface TableFilter {
  name?: string
  all: boolean
}
@Component({
  selector: 'app-workspace-customer-table',
  templateUrl: './workspace-customer-table.component.html',
  styleUrls: ['./workspace-customer-table.component.scss']
})
export class WorkspaceCustomerTableComponent implements AfterViewInit, OnDestroy {
  private _subscriptions = new Subscription()
  private _initSort = new Subject()
  @Input() workspaceId!: number
  @Input() productId!: number
  @Output() usersFilter = new EventEmitter<string>()
  @ViewChild(MatSort, { static: false }) sort?: MatSort
  @Output() accessChanged = new EventEmitter()
  @Output() close = new EventEmitter()

  today = moment().startOf('day')
  private _customers?: IRCustomerExtended[]

  dataSource?: MatTableDataSource<ICustomerAccess>
  displayedColumns = ['customer_name', 'logo', 'product_access', 'valid_from', 'valid_to', 'allowed', 'actions']
  loading = true
  disableActions = false

  filter: TableFilter = {
    all: false,
    name: ''
  }

  stickyHeader = true

  constructor (
    private _api: VipApiService,
    private _alertService: AlertService,
    private _authService: AuthService,
    private _workspacesService: WorkspacesService,
    private _settingsService: SettingsService
  ) {
    this.stickyHeader = !this._settingsService.isIEorEdge(false)
    this._subscriptions.add(
      this._initSort.subscribe(() => {
        if (!this.dataSource || !this.sort) {
          setTimeout(() => this._initSort.next({}), 100)
          return
        }
        this.dataSource.sort = this.sort
      })
    )
  }

  ngAfterViewInit () {
    this.LoadCustomers()
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  toggleWProductAccess (e: MatSlideToggleChange) {
    this.filter.all = e.checked
    this.ReloadFilter()
  }

  applyFilter (key: 'name' , value: string) {
    this.filter[key] = value
    this.ReloadFilter()
  }

  private ReloadFilter () {
    if (this.dataSource) this.dataSource.filter = JSON.stringify(this.filter)
  }

  private FilterTable (data: ICustomerAccess, filter: string): boolean {
    const f = JSON.parse(filter) as TableFilter
    let match = true
    if (f) {
      if (match) match = f.all || data.productAccess
      if (match && f.name) {
        match = data.customer_name.toLowerCase().includes(f.name.toLowerCase()) ||
        (!!data.customer_short_name && data.customer_short_name.toLowerCase().includes(f.name.toLowerCase())) ||
        (!!data.customer_formal_name && data.customer_formal_name.toLowerCase().includes(f.name.toLowerCase()))
      }
    }
    return match
  }

  async toggleCustomerProductAccess (customer: ICustomerAccess, grant: boolean) {
    let ids: number[] = []
    if (grant) {
      ids = Array.from(new Set([...customer.product_ids || [], this.productId]))
      await this._api.orm.Customers().Customer(customer.customer_id)
        .Products().Product(this.productId).add().run()
    } else if (customer.product_ids && customer.product_ids.includes(this.productId)) {
      ids = customer.product_ids.filter(x => x !== this.productId)
      await this._api.orm.Customers().Customer(customer.customer_id)
        .Products().Product(this.productId).delete().run()
    }

    customer.product_ids = ids
    customer.productAccess = grant
    customer.hasTriggeredProductAccess = grant
  }

  async updatePermissions (customer: ICustomerAccess) {
    try {
      this.disableActions = true
      const baseRoute = this._api.orm.Workspaces()
        .Workspace(this.workspaceId).Customers()
        .Customer(customer.customer_id)

      if (customer.allowed) {
        await baseRoute.upsert({
          valid_from: customer.valid_from || null as any,
          valid_to: customer.valid_to || null as any
        }).run()
        this.accessChanged.emit()
      }
    } catch (error: any) {
      this._alertService.log(error.message)
    }

    this.disableActions = false
  }

  isDisabled (customer: ICustomerAccess) {
    return this.disableActions || !customer.productAccess || !customer.allowed
  }

  async toggleCustomerAccess (e: MatSlideToggleChange, customer: ICustomerAccess) {
    try {
      this.disableActions = true
      const baseRoute = this._api.orm.Workspaces()
        .Workspace(this.workspaceId).Customers()
        .Customer(customer.customer_id)

      if (e.checked) await baseRoute.upsert().run()
      else await baseRoute.delete().run()

      customer.allowed = e.checked
      this.accessChanged.emit()
      if (!customer.allowed && !this._authService.isSysMaintainer && customer.customer_id === this._authService.customerId) {
        this._workspacesService.spliceLocalWorkspace(this.workspaceId)
        this._alertService.log('You no longer have access to this workspace.')
        this.close.emit()
      }
    } catch (error: any) {
      this._alertService.log(error.message)
    }

    this.disableActions = false
  }

  private async LoadCustomers () {
    try {
      if (!this._customers) this._customers = await this._api.orm.Customers().get(true).run()
      const workspaceCustomers = await this._api.orm.Workspaces()
        .Workspace(this.workspaceId).Customers().get().run()

      const customers: any[] = []
      for (const c of this._customers) {
        const match = workspaceCustomers.find(x => x.customer_id === c.customer_id)
        customers.push({
          ...c,
          allowed: !!match,
          valid_from: match && match.valid_from,
          valid_to: match && match.valid_to,
          productAccess: !!c.product_ids && c.product_ids.includes(this.productId)
        })
      }
      customers.sort((a, b) => b.productAccess ? 1 : -1)
      this.dataSource = new MatTableDataSource(customers)
      this.dataSource.filterPredicate = this.FilterTable.bind(this)
      this._initSort.next({})

      this.ReloadFilter()
    } catch (error: any) {
      this._alertService.log(error.message)
    } finally {
      this.loading = false
    }
  }
}
