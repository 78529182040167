import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, Subject, interval } from 'rxjs'

import {
  ILayerLoadingState
} from '@core/types'
import { LayerService } from '../layer.service'
import { debounceTime, skip } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LayerLoadingService {
  private _layersLoading = new BehaviorSubject<ILayerLoadingState>({
    isLoading: false,
    totalLayersCount: 0,
    loadedCount: 0
  })
  private _wasLoading = false

  get layersLoading (): Observable<ILayerLoadingState> {
    return this._layersLoading.asObservable()
  }

  private _updateState = new Subject()
  private _totalCount = 0

  get loadedCount () {
    return this._layerService.allLayers.reduce((sum, x) => (!x.loading || x.error) ? sum + 1 : sum, 0)
  }

  constructor (
    private _layerService: LayerService
  ) {
    this._updateState.pipe(
      debounceTime(100)
    ).subscribe(() => {
      this.ReportProgress()
    })

    this._layerService.totalLayerCount.subscribe(count => {
      this._totalCount = count
      this._updateState.next({})
    })

    this._layerService.layerArrayChanged.subscribe(() => {
      this._updateState.next({})
    })

    this._layerService.newLayer
      .pipe(skip(this._layerService.allLayers.length - 1))
      .subscribe(() => {
        this._updateState.next({})
      })

    // TEMP: Quick fix to layer loading get stuck
    // TODO: Add permanent fix
    interval(1000).subscribe(() => {
      const loadedCount = this.loadedCount
      const totalLayersCount = this._totalCount
      const isLoading = loadedCount < totalLayersCount
      if (isLoading !== this._wasLoading) this._updateState.next({})
    })
  }

  private ReportProgress () {
    const loadedCount = this.loadedCount
    const totalLayersCount = this._totalCount
    this._wasLoading = loadedCount < totalLayersCount
    this._layersLoading.next({
      isLoading: this._wasLoading,
      totalLayersCount,
      loadedCount
    })

    if (this._wasLoading) this._updateState.next({})
  }
}
