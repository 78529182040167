import { Component, Input, HostBinding } from '@angular/core'
import { DomainService } from '@services/core/domain/domain.service'
import { CustomerService } from '@services/admin'

@Component({
  selector: 'app-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss']
})
export class PoweredByComponent {
  @HostBinding('style.align-items')
  @Input() align: 'flex-start' | 'center' | 'flex-end' = 'center'

  get showPoweredBy (): boolean {
    const customer = this._customerService.activeCustomer || this._domainService.domainCustomer
    return customer ? customer.gsi_branding : true
  }

  constructor (
    private _domainService: DomainService,
    private _customerService: CustomerService
  ) {}
}
