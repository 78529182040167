<h2 mat-dialog-title>Theme editor</h2>

<div mat-dialog-content>
  <section>
    <h3>General Colours</h3>
    <section class="color-list">
      <div *ngFor="let el of colors">
          <h4>{{el.name}}</h4>
          <div class="color-panel">
            <mat-icon *ngIf="notSystemDefault(el)" (click)="el.value = getSystemDefault(el.key)"
            matTooltip="Restore to default system colour">settings_backup_restore</mat-icon>
            <app-solid-color-picker [color]="el.value" (colorChange)="el.value = $event"></app-solid-color-picker>
          </div>
      </div>
    </section>
  </section>

  <section>
    <h3>Component Colours</h3>
    <section class="color-list">
        <div *ngFor="let el of componentColors">
            <h4>{{el.name}}<p>
              {{el.description || ''}}
            </p></h4>
            <div class="color-panel">
              <mat-icon *ngIf="notSystemDefault(el)" (click)="el.value = undefined"
              matTooltip="Restore to '{{el.defaultName}}'">settings_backup_restore</mat-icon>
              <app-solid-color-picker matTooltip="Defaults to '{{el.defaultName}}'"
               [color]="el.value || getDefaultFromGeneral(el.default)" (colorChange)="el.value = $event"></app-solid-color-picker>
            </div>
        </div>
    </section>
  </section>
</div>

<div class="st-button-row">
  <app-delete-button *ngIf="theme" text="Delete" (delete)="delete()"></app-delete-button>
  <button mat-stroked-button color="primary" *ngIf="data.allowPreviewApply" (click)="preview()">Preview</button>
  <button mat-stroked-button color="primary" *ngIf="data.allowPreviewApply && previewTheme && previewIsDifferent()" (click)="applyPreview()">Apply Preview</button>
  <app-submit-button text="Save" (submit)="save()"></app-submit-button>
</div>
