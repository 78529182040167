import { Injectable } from '@angular/core'
import { IJwt } from '@vip-shared/interfaces/api/jwt'
import { JwtHelperService } from '@auth0/angular-jwt'
import { handleError } from '@core/models/app-error'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  private _helper = new JwtHelperService()
  private _jwtStorageKey = 'JWTKEY'
  private _tokenChange = new Subject<string | undefined>()

  get tokenChange () {
    return this._tokenChange.asObservable()
  }

  readJWT (): IJwt | undefined {
    let res: IJwt | undefined
    try {
      const tokenValue = this.GetRawToken()

      if (tokenValue !== null) {
        res = this._helper.decodeToken(tokenValue)
      }
    } catch (error: any) {
      handleError(error)
    } finally {
      return res
    }
  }

  setJWT (token: string) {
    this._tokenChange.next(this._jwtStorageKey)
    return localStorage.setItem(this._jwtStorageKey, token)
  }

  clearJWT () {
    this._tokenChange.next(undefined)
    return localStorage.removeItem(this._jwtStorageKey)
  }

  isJWTSet (): boolean {
    let res = false
    try {
      res = localStorage.getItem(this._jwtStorageKey) === null ? false : true
    } catch (error: any) {
            // Log this?
    } finally {
      return res
    }
  }

  getBearerToken (): string | null {
    const token = this.GetRawToken()
    return token ? `Bearer ${token}` : null
  }

  private GetRawToken (): string | null {
    return localStorage.getItem(this._jwtStorageKey)
  }
}
