<h2 mat-dialog-title *ngIf="data.title">
  <mat-icon [class.warn]="data.titleIcon === 'warning'" *ngIf="data.titleIcon">{{data.titleIcon}}</mat-icon>
  {{data.title}}
</h2>

<div mat-dialog-content>
  <p class="st-large" [innerHTML]="data.content"></p>
</div>

<div class="st-button-row">
  <div>
      <button color="primary" mat-button [mat-dialog-close] *ngIf="data.dismissEnabled">Dismiss</button>
  </div>
  <div>
      <button color="primary" mat-button *ngFor="let action of buttonNames" (click)="callAction(action)">{{action}}</button>
  </div>
</div>
