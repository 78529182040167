<h2 mat-dialog-title class="st-single-row-form">{{ title }}</h2>

<div mat-dialog-content>
  <form [formGroup]="form" class="st-single-row-form">
    <p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>

    <mat-form-field>
      <input matInput type="text" formControlName="name" placeholder="Workspace name" cdkFocusInitial />
    </mat-form-field>

    <mat-form-field>
      <mat-select disableOptionCentering [disabled]="data.mode === 'edit'" formControlName="product" placeholder="Product name">
        <mat-option *ngFor="let product of products" [value]="product.product_id">
          {{ product.product_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-chips-input formControlName="tags" placeholder="Workspace tags"></app-chips-input>

    <div id="demo-option">
      <mat-checkbox formControlName="has_dashboard"> Dashboard </mat-checkbox>
    </div>
    <div id="demo-option" *ngIf="sysMaintainer">
      <mat-checkbox formControlName="demo"> Demo Mode </mat-checkbox>
      <mat-icon
        matTooltip="Demonstration workspace access can only be managed by System Admins,
                                compared to normal workspace, which access can also be managed by customer admins for their own users."
        >help_outline</mat-icon
      >
    </div>
  </form>
</div>

<div class="st-button-row">
  <button color="primary" type="button" mat-button [mat-dialog-close] [disabled]="saving">Close</button>
  <app-submit-button
    (submit)="submit()"
    [loading]="saving"
    [disabled]="form?.invalid || saving"
    [text]="data.mode === 'edit' ? 'Save' : 'Create'"
  ></app-submit-button>
</div>

