<h2 mat-dialog-title>
  <mat-icon>find_in_page</mat-icon>
  {{ data.conf ? 'Update' : 'New' }} Scraper
</h2>

<app-note *ngIf="data.conf">
  Please note that modifying query will not remove already retrieved results. Data can only be appended.
</app-note>

<div mat-dialog-content>
  <app-scraper-preset-config [productId]="productId" #presetForm
  (formChange)="applyFormConfig(form, $event)"
  [onlyPresets]="scraperPresets"
  [scraperForm]="scraperForm"></app-scraper-preset-config>
</div>

<div class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>CANCEL</button>
  </div>
  <div class="right">
    <button color="primary" [disabled]="!valid" mat-raised-button (click)="save()">SAVE</button>
  </div>
</div>
