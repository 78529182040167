<form [formGroup]="form">
  <div id="widget-menu-container">
    <mat-form-field class="st-capitalize">
      <mat-select (selectionChange)="updateWidgetSource()" formControlName="widgetType" placeholder="Widget Type">
        <mat-select-trigger>
          <mat-icon (click)="$event.stopPropagation(); openWidgetExampleDialog()"  matTooltip="Click to view an example image." >info</mat-icon>&nbsp;{{widgetType}}
        </mat-select-trigger>
        <mat-option *ngFor="let widget of dynamicComponents | keyvalue" [value]="widget.key">
          {{widget.key}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="st-capitalize" *ngIf="usesLayerDataSource">
      <mat-select *ngIf="canSelectMultiple" placeholder="Layer Data Source" formControlName="layerId" multiple>
        <mat-option *ngFor="let layer of availableLayers" [value]="layer.layer_id">
          {{layer.name}}
        </mat-option>
      </mat-select>
      <mat-select  (selectionChange)="updateAvailableLayerColumns()" *ngIf="!canSelectMultiple" placeholder="Layer Data Source" formControlName="layerId">
        <mat-option *ngFor="let layer of availableLayers" [value]="layer.layer_id">
          {{layer.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="st-capitalize" *ngIf="usesChartDataSource">
      <mat-select placeholder="{{availableCharts.length ? 'Graph Data Source' : 'No available charts'}}"
        formControlName="chartId">
        <mat-option *ngFor="let chart of availableCharts" [value]="chart.chart_id">
          {{chart.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="widgetType === 'DynamicChartingWidget'" class="dynamic-chart-selectors">
      <mat-form-field class="st-capitalize">
        <mat-select placeholder="Chart Type" (selectionChange)="updateChartType()" formControlName="chartType">
          <mat-option *ngFor="let type of chartTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="st-capitalize" *ngIf="chartWithAxes === true">
        <mat-select placeholder="X Axis" formControlName="xAxis">
          <mat-option *ngFor="let column of availableLayerColumns" [value]="column.name">
            {{column.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="st-capitalize" *ngIf="chartWithAxes === true">
        <mat-select placeholder="Y Axis" formControlName="yAxis">
          <mat-option *ngFor="let column of numericLayerColumns" [value]="column.name">
            {{column.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="st-capitalize" *ngIf="chartWithAxes === false">
        <mat-select placeholder="Aggregate column" formControlName="aggColumn">
          <mat-option *ngFor="let column of availableLayerColumns" [value]="column.name">
            {{column.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="usesRasterSpacialQueries" class="row header">
      <mat-form-field class="st-capitalize">
        <mat-select placeholder="Raster Spacial Query"
          formControlName="queryId">
          <mat-option *ngFor="let query of availableRasterSpacialQueries" [value]="query.query_id">
            {{query.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="usesQueryBuilder" class="row header">
      <mat-form-field class="st-capitalize">
        <mat-select (selectionChange)="loadLayer($event.value)" placeholder="Layer Data Source"
          formControlName="queryLayer">
          <mat-option *ngFor="let layer of availableLayers" [value]="layer.layer_id">
            {{layer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="st-capitalize">
        <mat-select [(ngModel)]="queryTypeSelected" placeholder="Query type" formControlName="queryType">
          <mat-option *ngFor="let query of queryTypes" [value]="query">
            {{query}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="st-capitalize">
        <input matInput placeholder="Threshold" [(ngModel)]="threshold" formControlName="thresholdInput" />
      </mat-form-field>
    </div>
    <app-loader-backdrop *ngIf="loadingLayer" [loadingText]="'Loading Layer Data'">
    </app-loader-backdrop>
    <ng-container *ngIf="queryTypeSelected && threshold">
      <ng-container *ngIf="queryTypeSelected === 'filter' && targetLayer">
        <app-query-builder-dialog [layerId]="layerId" [isDashboardWidget]="true"></app-query-builder-dialog>
      </ng-container>
      <ng-container *ngIf="queryTypeSelected === 'statistic' && targetLayer">
        <app-statistic-query-builder-dialog [layerId]="layerId" [isDashboardWidget]="true">
        </app-statistic-query-builder-dialog>
      </ng-container>
    </ng-container>
    <div class="st-button-row">
      <button color="primary" mat-button [mat-dialog-close]>Cancel</button>
      <ng-container *ngIf="!usesQueryBuilder">
        <app-submit-button mat-button (submit)="createWidget()" [disabled]="!form.valid">
          Create Widget
        </app-submit-button>
      </ng-container>
      <ng-container *ngIf="usesQueryBuilder && (queryBuilder || statQueryBuilder)">
        <app-submit-button *ngIf="usesQueryBuilder" text="Create Widget With Query" mat-button
          (submit)="createWidgetQuery()"
          [disabled]="statQueryBuilder ? !statQueryBuilder.form.valid : queryBuilder.isCreateSaveDisabled(queryBuilder.queryObj)">
          Create Widget With Query
        </app-submit-button>
      </ng-container>
    </div>
  </div>
</form>
