<form [formGroup]="form">
  <mat-form-field>
    <mat-select disableOptionCentering formControlName="presetName" placeholder="Existing Dataset" (selectionChange)="presetChange($event.value)">
      <mat-option *ngFor="let preset of layerPresets" [value]="preset.layer_preset_tag" class="st-capitalize" [matTooltip]="preset.description">
        {{preset.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngFor="let fc of filterControls">
    <mat-form-field *ngIf="fc.visible()">
      <mat-select disableOptionCentering [formControl]="fc.control" [placeholder]="fc.name" [multiple]="fc.multiple" >
        <mat-option *ngFor="let option of fc.filteredOptions" [value]="option.value">
          {{option.display_name || option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <mat-form-field *ngIf="modes.length">
    <mat-select disableOptionCentering formControlName="mode" placeholder="View Mode" (selectionChange)="modeChanged($event.value)">
      <mat-option *ngFor="let mode of modes" [value]="mode.layer_mode_tag" class="st-capitalize" [disabled]="mode.disabled"
      [matTooltip]="mode.description">
        {{mode.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="selectedMode && form._controls.presetName.value === preset.PROP_VIEW_BUILDINGS">
    <div *ngIf="presetDatasets.length && selectedMode === 'read' && form._controls.presetName.value">
      <app-dataset-join-query-preview [query]="form._controls.datasetsQuery.value"
      [datasets]="presetDatasets" (queryChange)="form._controls.datasetsQuery.setValue($event); checkDatasetSelection()"></app-dataset-join-query-preview>
    </div>

    <ng-container *ngIf="requireMlDataset || requireAnnotationGuide">
      <form [formGroup]="datasetSelectionForm">

        <!-- Use css class to disable feature because we still need it to alter forms valid state -->
        <mat-form-field *ngIf="requireMlDataset" [class.read-only]="filteredMlDatasets?.length < 2">
          <mat-select disableOptionCentering formControlName="ml_dataset_id" [placeholder]="placeholder('ML Dataset', filteredMlDatasets?.length)">
            <mat-option *ngFor="let ml of filteredMlDatasets" [value]="ml.ml_dataset_id" class="st-capitalize">
              {{ml.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="requireAnnotationGuide">
          <mat-select disableOptionCentering formControlName="anot_guide_set_id" [placeholder]="guideSetCount && placeholder('Annotation Guide Set', guideSetCount)">
            <mat-select-trigger>
              <span>
                {{guideSetName(datasetSelectionForm.controls.anot_guide_set_id.value)}}
              </span>
            </mat-select-trigger>
            <mat-option class="new-option" (click)="createNewGuideSet()">
              + Create new
            </mat-option>

            <ng-container *ngIf="!filteredAnotGuideSets?.withoutMeasures?.length">
               <mat-option *ngFor="let set of filteredAnotGuideSets?.withMeasures" [value]="set.anot_guide_set_id" class="st-capitalize space-between">
                {{set.name}}

                <button mat-icon-button (click)="editSet(set); $event.stopPropagation()">
                  <mat-icon>edit</mat-icon>
                </button>
              </mat-option>
            </ng-container>

            <ng-container *ngIf="filteredAnotGuideSets?.withoutMeasures?.length">
              <mat-optgroup label="Containing Measures">
                <mat-option *ngFor="let set of filteredAnotGuideSets?.withMeasures" [value]="set.anot_guide_set_id" class="st-capitalize space-between">
                  {{set.name}}

                  <button mat-icon-button (click)="editSet(set); $event.stopPropagation()">
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-option>
              </mat-optgroup>

              <mat-optgroup label="Without Measures">
                <mat-option *ngFor="let set of filteredAnotGuideSets?.withoutMeasures" [value]="set.anot_guide_set_id" class="st-capitalize space-between">
                  {{set.name}}

                  <button mat-icon-button (click)="editSet(set); $event.stopPropagation()">
                    <mat-icon>edit</mat-icon>
                  </button>
                </mat-option>
              </mat-optgroup>
            </ng-container>

          </mat-select>
        </mat-form-field>

      </form>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="scraperForm">
    <app-note>
      Please note that it will take around 5min to 15min for layer to be populated with data, if results are found. If there are no results for query, layer will stay empty.
    </app-note>
    <form [formGroup]="scraperForm">
      <mat-form-field>
        <input matInput formControlName="name" placeholder="Scraper Name">
      </mat-form-field>

      <app-chips-input formControlName="where_exact" placeholder="Must contain words/phrases">
      </app-chips-input>

      <app-chips-input formControlName="where_has" placeholder="Might contains words" type='word'>
      </app-chips-input>

      <app-chips-input formControlName="where_exclude" placeholder="Exclude words" type='word'>
      </app-chips-input>

      <app-note *ngIf="locationWarning" color="warn">{{locationWarning}}</app-note>
      <app-locations-input formControlName="locations">
      </app-locations-input>

      <app-date-ranges-input [backDateLimit]="backDateDays" formControlName="dateRanges">
      </app-date-ranges-input>

      <div class="st-mat-field-pair" *ngIf="scraperForm?._controls.intervalValue && scraperForm?._controls.interval">
        <mat-form-field>
          <input matInput type="number" formControlName="intervalValue" placeholder="Interval Value">
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="interval">
            <mat-option *ngFor="let interval of timeIntervals" [value]="interval">
                {{interval}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </ng-container>
</form>

<app-loader-backdrop *ngIf="fetchingData" loadingText="Fetching settings..."></app-loader-backdrop>
