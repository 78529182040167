import { UntypedFormGroup, AbstractControl } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { handleError } from './app-error'

export class FormTemplate<T extends UntypedFormGroup> {
  errorMessage?: string
  submitted = false
  saving = false
  clearing = false
  enabled = true

  constructor (
    public form: T,
    protected _dialogRef?: MatDialogRef<any>
  ) {}

  get formDisabled (): boolean {
    return this.submitted || this.clearing || this.saving
  }

  protected toggleForm (enable: boolean, excludeControls: AbstractControl[] = []) {
    if (enable !== this.enabled) {
      if (enable && !this.formDisabled) return
      this.enabled = enable
      if (this._dialogRef) {
        this._dialogRef.disableClose = !this.enabled
      }
      for (const key in this.form.controls) {
        const c = this.form.controls[key]
        if (excludeControls.includes(c)) continue
        if (this.enabled) c.enable()
        else c.disable()
      }
    }
  }

  protected async submit (cb: () => Promise<void>) {
    if (this.saving) return
    this.errorMessage = undefined
    this.saving = true

    try {
      await cb()
    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    }

    this.saving = false
  }
}
