import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core'
import { MatSliderChange } from '@angular/material/slider'
import { AirRouteFinderService } from '@services/workspace'

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @HostBinding('class.slider-min-value') sliderMinValueClass = false
  @HostBinding('class.vertical') verticalClass = false
  @Input() min = 0
  @Input() max = 100
  @Input() step = 1
  @Input() vertical = false
  @Input() tickInterval = 0
  @Input() value = 0
  @Input() format: 'value' | 'percentage' = 'percentage'
  prevValue = this.value
  @Input() previewPosition: 'END' | 'START' | null = null
  @Input() disabled = false
  @Output() valueChange = new EventEmitter<number>()
  @Output() valueChangeReleased = new EventEmitter<number>()
  constructor (
        private _airRouteFinderService: AirRouteFinderService
    ) {
    this._airRouteFinderService.balanceObservable.subscribe(newBalance => {
      this.value = newBalance
    })
  }

  private _releaseDebounce = 0
  private _initiated = false

  ngOnInit () {
    this.updateClass(this.value)
    this.verticalClass = this.vertical
    this.prevValue = this.value
  }

  changeOpacityEvent (e: MatSliderChange) {
    this.changeOpacity(e.value as number)
  }

  changeOpacity (val: number) {
    this._initiated = true
    window.clearTimeout(this._releaseDebounce)
    this.valueChange.emit(val)
    this.updateClass(val)
    this.prevValue = val
  }

  changeOpacityReleaseEvent () {
    if (this._initiated) {
      this._releaseDebounce = window.setTimeout(() => {
        this.valueChangeReleased.emit(this.value)
        this._initiated = false
      }, 500)
    }
  }

  updateClass (value: number) {
        // '+' converts Input() string value to number
    this.sliderMinValueClass = value === +this.min
  }

  getOpacityValue () {
    switch (this.format) {
      case 'percentage':
        const rangeCount = this.max - this.min
        const percentage = this.value * 100 / rangeCount
        return Math.round(percentage)
      case 'value':
        return this.value
    }
  }

  resetValue (element: HTMLInputElement) {
    this.value = this.prevValue
    switch (this.format) {
      case 'percentage':
        const valToPerc = this.map(this.prevValue, this.min, this.max, 0, 100)
        element.value = `${valToPerc}`
        break
      case 'value':
        element.value = `${this.prevValue}`
        break
    }
  }

  validateInput (event: any) {
    if (event.data && !/[0-9,.]/g.test(event.data)) {
      this.resetValue(event.target)
      return
    }
    const newValue = +event.target.value
    switch (this.format) {
      case 'percentage':
        const percToVal = this.map(newValue, 0, 100, this.min, this.max)
        if (percToVal < this.min || percToVal > this.max) {
          this.resetValue(event.target)
          break
        }
        this.changeOpacity(percToVal)
        this.valueChangeReleased.emit(percToVal)
        break
      case 'value':
        if (newValue < this.min || newValue > this.max) {
          this.resetValue(event.target)
          break
        }
        this.changeOpacity(newValue)
        this.valueChangeReleased.emit(newValue)
        break
    }
  }

  map (num: number, inMin: number, inMax: number, outMin: number, outMax: number) {
    num = +num
    inMin = +inMin
    inMax = +inMax
    outMin = +outMin
    outMax = +outMax
    const mappedValue = (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
    return mappedValue
  }
}
