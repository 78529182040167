
import { Component, OnInit, OnDestroy } from '@angular/core'
import { AppLayer } from '@core/models/layer'
import { AlertService, PromptService, VipApiService } from '@services/core'
import { WorkspaceService } from '@services/workspace'
import { WidgetQueryLayerService } from '@services/workspace/workspace-map/widget-layer-query.service'
import { WidgetLayerService } from '@services/workspace/workspace-map/widget-layer.service'
import { WidgetMapService } from '@services/workspace/workspace-map/widget-map.service'
import { ContextMenuService } from '@services/core/context-menu/context-menu.service'
import { DashboardLayoutService } from '@services/dashboard/dashboard-layout.service'
import BaseLayer from 'ol/layer/Base'
import { MatDialog } from '@angular/material/dialog'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss'],
  providers: [WidgetLayerService,WidgetQueryLayerService]
})

export class MapWidgetComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription()
  layerId: string = 'default'
  options: { [key: string]: any; } | any[] = { layers: [''] }
  targetLayer?: AppLayer
  queryService: WidgetQueryLayerService
  workspaceMapService?: WidgetMapService

  get showTimeSlider () {
    // add group layer condition here
    return !!this.targetLayer && this.targetLayer.isTimeSeries
  }

  constructor (
    public layerService: WidgetLayerService,
    private _workspaceService: WorkspaceService,
    private _api: VipApiService,
    private _alertService: AlertService,
    private _promptService: PromptService,
    private _contextMenu: ContextMenuService,
    private _dashboardLayoutService: DashboardLayoutService,
    private _dialog: MatDialog
    ) {
    this.queryService = new WidgetQueryLayerService(this.layerService, this._workspaceService, this._api, this._alertService,this._promptService)
    this.workspaceMapService = new WidgetMapService(this._alertService, this.layerService as any, this._workspaceService, this._contextMenu, this._dashboardLayoutService, this._dialog)
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this.layerService.newLayer.subscribe(async layer => {
      if (layer && layer.layer instanceof BaseLayer) {
        this.targetLayer = layer
        this.queryService.updateQueries()
      }
    }))
    this._subscriptions.add(
      this.layerService.layerSelectionChange.subscribe(async layer => {
        if (layer && layer.layer instanceof BaseLayer) {
          this.targetLayer =  layer
        }
      })
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }
}
