import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { AppLayer } from "@core/models/layer";
import { AlertService } from "@services/core";
import { DashboardApiService } from "@services/dashboard/dashboard-api.service";
import { LayerService, WorkspaceService } from "@services/workspace";
import BaseLayer from "ol/layer/Base";
import { Chart, ChartConfiguration } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import { ThemeService } from '@services/core/theme/theme.service'
import { CommonUtil } from "@core/utils";
import * as moment from 'moment'
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PieDataTableDialogComponent } from "./pie-data-table-dialog/pie-data-table-dialog.component";

// NOTE: Interface of json key value types stored in the DB for this widget type.
interface DynamicChartOptions {
  chartType?: string,
  xAxis?: string,
  yAxis?: string,
  aggColumn?: string
}

@Component({
  selector: 'app-dynamic-charting-widget',
  templateUrl: './dynamic-charting-widget.component.html',
  styleUrls: ['./dynamic-charting-widget.component.scss']
})
export class DynamicChartingWidgetComponent implements AfterViewInit {
  @ViewChild(BaseChartDirective, { static: true }) chart!: BaseChartDirective
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }
  layerId: string = 'default'
  options: DynamicChartOptions = {}
  targetLayer?: AppLayer
  chartData: any = []
  chartLabels: Array<any> = []
  chartOptions: any = {
    responsive: true
  }
  chartType: string = 'line'
  chartTitle: string = ''
  infoTooltip: string = 'Time information not available'
  tableRows: string[] = []
  tableValues: number[] = []

  constructor(
    private _dashboardService: DashboardApiService,
    private _layerService: LayerService,
    private _workspaceService: WorkspaceService,
    private _themeService: ThemeService,
    private _dialog: MatDialog,
    private _alertService: AlertService
  ) {
    this._layerService.newLayer.subscribe(async layer => {
      const targetId = this.layerId.toString()
      if (layer.layer instanceof BaseLayer && layer.id === targetId) {
        this.targetLayer = layer
        if (this.targetLayer.isTimeSeries) {
          const from = moment(this.targetLayer.renderedTimeSeriesSelection?.date_range.from).format('YYYY-MM-DD HH:mm:ss')
          const to = moment(this.targetLayer.renderedTimeSeriesSelection?.date_range.to).format('YYYY-MM-DD HH:mm:ss')
          this.infoTooltip = `Time range: ${from} - ${to}`
        }
        this.SetChartData()
      }
    })
  }

  ngAfterViewInit() {
    this.SetLayerTarget()
  }

  private GetAttributes () {
    if (!this.targetLayer) return
    return this.targetLayer.notFilteredOutFts.map(f => f.getProperties())
  }

  private GetLayerName () {
    if (!this.targetLayer || !this.targetLayer.layer) return
    return this.targetLayer.layer.getProperties()['name']
  }

  private GetChartOptions () {
    return this.options.chartType !== 'pie' ?
    {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 3,
        plugins: {
          legend: {
            labels: {
              color: this._themeService.getColor('foreground'),
              font: {
                size: 12
              }
            },
            position: 'top',
          }
        },
        scales : {
          y: {
            ticks: {
              color: this._themeService.getColor('foreground')
            },
            display: true,
            scaleLabel: {
              color: this._themeService.getColor('foreground'),
              display: true,
              labelString: this.options.yAxis
            },
            grid: {
              color: this._themeService.getColor('foreground-20')
            }
          },
          x: {
            display: true,
            ticks: {
              color: this._themeService.getColor('foreground')
            },
            scaleLabel: {
              color: this._themeService.getColor('foreground'),
              display: true,
              labelString: this.options.xAxis
            },
            grid: {
              color: this._themeService.getColor('foreground-20')
            }
          }
        },
        tooltips: {
          enabled: true
        }
    } :
    {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      plugins: {
        legend: {
          labels: {
            color: this._themeService.getColor('foreground'),
            font: {
              size: 12
            }
          },
          position: 'right',
          align: 'start'
        }
      },
      elements: {
        arc: {
            borderWidth: 0
        }
      },
      onClick: (e) => {
        this.openDatasetTable()
      }
    }
  }

  private SetChartData () {
    this.chartType = this.options.chartType as string
    const attributes = this.GetAttributes()
    if (!attributes) return
    if (this.options.chartType !== 'pie') {
      const xAxis = this.options.xAxis
      const yAxis = this.options.yAxis
      if (!xAxis || !yAxis) return
      // NOTE: to improve efficiency on array access the data structure will be:
      // A dictionary with the label as the key and array as value, [0] will be the count, [1] is the item sum
      // i.e. {'InsurerNameA': [3, 1000], 'InsurerNameB': [4,2000]}
      let data = {}
      attributes.forEach(attr => {
        if (data[attr[xAxis]]) {
          data[attr[xAxis]][0] = data[attr[xAxis]][0] + 1
          data[attr[xAxis]][1] = data[attr[xAxis]][1] + attr[yAxis]
        } else {
          data[attr[xAxis]] = [1, attr[yAxis]]
        }
      })

      // NOTE: Calculate mean for each key
      let labels: string[] = []
      let datapoints: any[] = []
      for (const key in data) {
        labels.push(key)
        const mean = (data[key][1]/data[key][0]).toFixed(2)
        datapoints.push(mean)
      }

      this.chartLabels = labels
      this.chartData = [{
        label: yAxis,
        data: datapoints
      }]
      this.chartOptions = this.GetChartOptions()
    } else {
      const aggregateCol = this.options.aggColumn
      if (!aggregateCol) return
      let data: any = {}
      attributes.forEach(attr => {
        if(data[attr[aggregateCol]]) {
          data[attr[aggregateCol]] = data[attr[aggregateCol]] + 1
        } else {
          data[attr[aggregateCol]] = 1
        }
      })

      const [labels, values] = CommonUtil.splitSortDictionary(data)
      // NOTE: only select the first 6 entries and then add others to a general slice
      const finalLabels = labels.length > 6 ? [...labels.slice(0,6), 'Others'] : labels
      const finalValues = values.length > 6 ? [...values.slice(0,6), values.slice(6).reduce((a: number,b: number) => a + b)] : values
      this.tableRows = labels
      this.tableValues = values
      this.chartLabels = finalLabels
      this.chartData = [{
        data: finalValues,
        label: 'Quantity',
      }]
      this.chartOptions = this.GetChartOptions()
    }
    this.chartTitle = this.chartType !== 'pie' ? `${this.GetLayerName()}` : `${this.GetLayerName()} aggregate by ${this.options.aggColumn}`
  }

  openDatasetTable() {
    const data = {
      columnTitleLabel: this.options.aggColumn || '',
      columnValueLabel: 'Count',
      titles: this.tableRows,
      values: this.tableValues
    }
    this._trackDialog(this._dialog.open(PieDataTableDialogComponent, PieDataTableDialogComponent.setOptions(data)))
  }

  async SetLayerTarget() {
    const wsId = this._dashboardService.workspaceId
    if (!this._workspaceService.workspaceId) await this._workspaceService.loadWorkspace(wsId)
    this.targetLayer = await this._layerService.getById(this.layerId)
    this.SetChartData()
  }
}
