import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from '@pages/auth/login/login.component'
import { ForgottenPasswordComponent } from '@pages/auth/forgotten-password/forgotten-password.component'
import { LogoutComponent } from '@pages/auth/logout/logout.component'
import { AuthGuard } from '@core/guards/index'
import { PasswordResetComponent } from '@pages/auth/password-reset/password-reset.component'
import { VerifyEmailComponent } from '@pages/auth/verify-email/verify-email.component'
import { EnterOtpComponent } from '@pages/auth/enter-otp/enter-otp.component'
import { SetupMfaComponent } from '@pages/auth/setup-mfa/setup-mfa.component'

const appRoutes: Routes = [
  { path: '', redirectTo: 'explorer', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'password-recovery', component: ForgottenPasswordComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'enter-otp', component: EnterOtpComponent },
  { path: 'setup-mfa', component: SetupMfaComponent },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
    data: {
      loadingScreen: {
        text: 'Logging out..'
      }
    }
  },
  {
    path: 'guide',
    loadChildren: () => import('./pages/guide/guide.module').then(m => m.GuideModule)
  },
  {
    path: 'explorer',
    loadChildren: () => import('./pages/explorer/explorer.module').then(m => m.ExplorerModule),
    canActivate: [AuthGuard],
    data: {
      loadingScreen: {
        text: 'Loading explorer...'
      }
    }
  },
  {
    path: 'workspace',
    loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkspaceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    resolve: { sessionLoaded: AuthGuard }
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
  },
  { path: '**', redirectTo: 'error/not-found', pathMatch: 'full' }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
