import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import AppError, { handleError } from '@core/models/app-error'
import { FormTemplate } from '@core/models/form-template'
import { AppLayer, AppLayerGroup } from '@core/models/layer'
import { EnhancedQueryContainer, EnhancedQueryStatement, IQueryWRef } from '@core/models/query-object'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { IRVectorColumn } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { StatisticQueryBuilderDialogComponent } from '../statistic-query-builder-dialog/statistic-query-builder-dialog.component'

interface DialogData {
  query: IQueryWRef | null
  target?: AppLayer | AppLayerGroup
  columnA?: string
  columnB?: string
  columnBType?: string | number
  operator?: Db.Helper.Prj.EnhancedOperatorInstance
}

@Component({
  selector: 'app-enhanced-query-builder-dialog',
  templateUrl: './enhanced-query-builder-dialog.component.html',
  styleUrls: ['./enhanced-query-builder-dialog.component.scss']
})
export class EnhancedQueryBuilderDialogComponent implements OnInit, OnDestroy {
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  columns: string[] = []
  mathOperators: Db.Helper.Prj.EnhancedOperatorInstance[] = Object.values(Db.Helper.Prj.EnhancedMathOperator)
  decisionOperators: Db.Helper.Prj.EnhancedOperatorInstance[] = Object.values(Db.Helper.Prj.EnhancedOperator)
  logicalOperators: Db.Helper.Prj.EnhancedOperatorInstance[] = Object.values(Db.Helper.Prj.EnhancedLogicalOperator)
  operators: Db.Helper.Prj.EnhancedOperatorInstance[] = this.mathOperators.concat(this.decisionOperators)
  availableColumns?: AppLayer['attributeColumns']
  statisticQueries?: IQueryWRef[] = []
  queryTarget?: IQueryWRef['targetRef']
  styleBuilder?: boolean
  queryObj?: IQueryWRef
  queryName: string = 'Untitled Query'
  summary: string = ''
  duplicateColumnNames: string[] = []
  enhancedQueryList: IQueryWRef[] = []
  public queryForm

  static setOptions (data: DialogData) {
    const options: MatDialogConfig = {
      width: '1000px',
      hasBackdrop: true,
      position: {
        top: '72px'
      },
      autoFocus: false,
      data
    }
    return options
  }

  get layers () {
    return this._layerQueriesService.queryableLayers
  }

  constructor (
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    public fb: UntypedFormBuilder,
    protected _dialogRef: MatDialogRef<EnhancedQueryBuilderDialogComponent, any>,
    private _dialog: MatDialog,
    private _layerQueriesService: LayerQueriesService
  ) {

    if (this.data.query) {
      if (this.data.query.query.enhancedQuery) {
        const {
          operators, statements, createAttrColumn, attrColumnName, caseColumnName, caseBlocks
        } = this.data.query.query.enhancedQuery
        this.data.query.query.enhancedQuery = new EnhancedQueryContainer(operators, statements, createAttrColumn, attrColumnName, caseColumnName, caseBlocks)

      }

      this.queryObj = this.data.query
      this.queryTarget = this.queryObj.targetRef
    }

    this.queryForm = this.fb.group({
      'name': ['Untitled Query', Validators.required],
      'enhancedQuery': this.fb.group({
        operators: this.fb.array(['']),
        statements: this.fb.array(['']),
        createAttrColumn: [false],
        attrColumnName: [''],
        caseColumnName: [''],
        caseBlocks: this.fb.array([])
      })

    })

    this.operators.concat(Object.values(Db.Helper.Prj.EnhancedMathOperator))
    this.operators.concat(Object.values(Db.Helper.Prj.EnhancedOperator))
  }

  ngOnInit () {

    if (this.data.target && this.data.target instanceof AppLayer) {
      this.queryTarget = this.data.target
      this.queryObj = this.data.query ? this.data.query : this.NewEmptyQuery('', this.data.target)
    }

    if (this.data.query && this.data.query.query.enhancedQuery) {
      this.queryName = this.data.query.name
    }
    if (this.queryTarget && (this.queryTarget instanceof AppLayer)) this.generateFilters(this.queryTarget)
    this.generateStatQueries()
  }

  ngOnDestroy () {
    this.queryObj = undefined
  }

  async saveQuery () {
    // save query
    try {
      if (this.queryObj) {
        this.queryObj.name = this.queryName
        await this._layerQueriesService.toggleQuery(this.queryObj, true, true)
      }
      this._dialogRef.close()
    } catch (error: any) {
      handleError(error)
    }
  }

  isCreateSaveDisabled (queryObj?: IQueryWRef): boolean {
    // validate
    return !!
    (
      !queryObj || !this.queryName ||
      (
        queryObj.query.enhancedQuery &&
        !this.IsContainerValid(queryObj.query.enhancedQuery as EnhancedQueryContainer)
      )
    )
  }

  private IsContainerValid (container: EnhancedQueryContainer) {
    for (const statement of container.statements) {
      if (statement instanceof EnhancedQueryStatement) {
        if (!this.AreStatementsValid(statement)) return false
      } else {
        if (!this.IsContainerValid(statement as EnhancedQueryContainer)) return false
      }
    }
    if (container.caseBlocks.length) {
      if (!container.caseColumnName) return false
      for (const block of container.caseBlocks) {
        if (!this.AreBlockconditionStatementsValid(block.conditionStatements)) return false
        if (!this.IsContainerValid(block.block as EnhancedQueryContainer)) return false
      }
    }
    if (container.createAttrColumn && !container.attrColumnName) return false
    return true
  }

  private AreBlockconditionStatementsValid (statements: Db.Helper.Prj.CaseConditionStatement[]) {
    for (const statement of statements) {
      if (!statement.operator || !statement.value) return
    }
    return true
  }

  private AreStatementsValid (statement: EnhancedQueryStatement) {

    switch (statement.statementType) {
      case 'default':
      case 'statistic':
        if (!statement.attributeA || !statement.operator || !statement.attributeB) return false
        break
      case 'attribute':
      case 'value':
        if (!statement.attributeB) return false
        break
    }
    if (statement.createAttrColumn && !statement.attrColumnName) return false
    return true
  }

  generateFilters (layer: AppLayer) {
    if (!this.queryObj) {
      this.queryObj = this.NewEmptyQuery(this.queryName, layer)
    } else {
      this.queryObj.targetRef = layer
    }
    this.columns = layer.tableColumns.map(column => column.name)
    this.enhancedQueryList = this._layerQueriesService.getTargetQueries(layer, ['enhanced'])
    for (const query of this.enhancedQueryList) {
      if (this.queryObj.query_id === query.query_id) continue
      this.columns.push(query.name)
    }

    if (this.queryObj && this.queryObj.query.enhancedQuery) {
      this.addColumnName(this.queryObj.query.enhancedQuery as EnhancedQueryContainer)
    }

    if (!this.columns || !this.columns.length) {
      throw new AppError('No columns available for this layer')
    }
  }

  private generateStatQueries () {
    if (!this.queryTarget) return
    const statQueries = this._layerQueriesService.getTargetQueries(this.queryTarget, ['statistic'])
    if (statQueries.length) {
      this.statisticQueries = statQueries
    }
  }

  addColumnName = (container: EnhancedQueryContainer) => {
    if (container.createAttrColumn) this.columns.push(container.attrColumnName || 'untitled')
    for (const statement of container.statements) {
      if (statement instanceof EnhancedQueryContainer) {
        this.addColumnName(statement)
      } else {
        if (statement.createAttrColumn) this.columns.push(statement.attrColumnName || 'untitled')
      }
    }
    if (container.caseColumnName) {
      this.columns.push(container.caseColumnName)
      for (const caseBlock of container.caseBlocks) {
        this.addColumnName(caseBlock.block as EnhancedQueryContainer)
      }
    }
  }

  getOptionName (target: AppLayerGroup | AppLayer) {
    // For now name 'time series' because we only have time series linked layers
    return target instanceof AppLayerGroup ? `Time Series of '${target.title}'` : target.title
  }

  isStatement (statement: EnhancedQueryStatement | EnhancedQueryContainer) {
    return statement instanceof EnhancedQueryStatement
  }

  isContainer (container: EnhancedQueryStatement | EnhancedQueryContainer) {
    return container instanceof EnhancedQueryContainer
  }

  addStatement (parent: EnhancedQueryContainer, operator?: Db.Helper.Prj.EnhancedOperatorInstance) {
    if (this.queryObj && this.queryObj.query.enhancedQuery) {
      if (operator) parent.operators.push(operator)
      parent.statements.push(new EnhancedQueryStatement({ operation: '', resultType: 'number', operands: 0 , symbol: '' }, '', '', 'attribute'))
    }

    if (this.queryTarget && this.queryTarget instanceof AppLayer) this.generateFilters(this.queryTarget)

  }

  addBlockQueryStatement (caseBlock, operator?: Db.Helper.Prj.EnhancedOperatorInstance) {
    caseBlock.operators.push(operator)
    caseBlock.conditionStatements.push({
      operator: Db.Helper.Prj.EnhancedOperator.EQUAL,
      value: ''
    })

  }

  deleteBlockQueryStmnt (caseBlock, condition: Db.Helper.Prj.CaseConditionStatement, operatorIndex: number) {
    caseBlock.conditionStatements = caseBlock.conditionStatements.filter((c) => c !== condition)
    caseBlock.operators.splice(operatorIndex - 1, 1)
  }

  deleteStatement (statement: EnhancedQueryContainer | EnhancedQueryStatement, parent: EnhancedQueryContainer, operatorIndex: number) {
    if (!this.queryObj) return
    if (!this.queryObj.query.enhancedQuery) return
    parent.statements = parent.statements.filter((s) => s !== statement)
    parent.operators.splice(operatorIndex - 1, 1)
  }

  deleteAllCases (container: EnhancedQueryContainer) {
    // delete all cases
    container.caseColumnName = ''
    container.caseBlocks = []
  }

  deleteCase (container: EnhancedQueryContainer, index: number) {
    container.caseBlocks.splice(index, 1)
  }

  createStatisticQuery (name: string) {
    if (!this.queryObj) return
    if (!this.queryObj.query.enhancedQuery) return
    const target = this.data.target ? this.data.target : this.data.query && this.data.query.targetRef ? this.data.query.targetRef : undefined
    if (!target) return
    const data = target instanceof AppLayer ? {
      target,
      columnName: name,
      column: name
    } : undefined
    if (data) {
      this._trackDialog(
        this._dialog.open(StatisticQueryBuilderDialogComponent, StatisticQueryBuilderDialogComponent.setOptions(data))
      )
    }

  }

  addResultBlock (parent: EnhancedQueryContainer) {
    if (!this.isContainer(parent)) return
    if (!this.queryObj) return
    if (!this.queryObj.query.enhancedQuery) return

    parent.caseBlocks.push({
      operators: [],
      conditionStatements: [{
        operator: Db.Helper.Prj.EnhancedOperator.EQUAL,
        value: ''
      }],
      block: new EnhancedQueryContainer([], [
        new EnhancedQueryStatement({ operation: '', resultType: 'number', operands: 0, symbol: '' }, '', '', 'attribute')
      ])
    })

    if (this.queryTarget && this.queryTarget instanceof AppLayer) this.generateFilters(this.queryTarget)
  }

  removeResultBlock (parent: EnhancedQueryContainer) {
    if (!this.isContainer(parent)) return
    if (!this.queryObj) return
    if (!this.queryObj.query.enhancedQuery) return

    parent.caseBlocks = []
  }

  addContainer (parent: EnhancedQueryContainer, operator?: Db.Helper.Prj.EnhancedOperatorInstance) {
    // add Block
    if (!this.queryObj) return
    if (!this.queryObj.query.enhancedQuery) return
    if (operator) parent.operators.push(operator)
    parent.statements.push(
      new EnhancedQueryContainer([], [
        new EnhancedQueryStatement({ operation: '', resultType: 'number', operands: 0, symbol: '' }, '', '', 'attribute')
      ])
    )
    if (this.queryTarget && this.queryTarget instanceof AppLayer) this.generateFilters(this.queryTarget)
  }

  deleteContainer (index: number) {
    if (!this.queryObj) return
    if (!this.queryObj.query.enhancedQuery) return
    this.queryObj.query.enhancedQuery.statements.splice(index, 1)
  }

  matchOperator (op1: Db.Helper.Prj.EnhancedOperatorInstance, op2: Db.Helper.Prj.EnhancedOperatorInstance) {
    if (!op1 || !op2) return false
    return op1.operation === op2.operation
  }

  isStatementType (statement: EnhancedQueryStatement, type: string) {
    return statement.statementType === type
  }

  querySummary () {
    if (!this.queryObj || !this.queryObj.query.enhancedQuery) return

    const getSummary = (container: EnhancedQueryContainer, name?: string) => {
      let result: string = container.caseBlocks.length ? ' <span class="case">switch( ' : ' '

      for (const [i, statement] of container.statements.entries()) {
        if (statement instanceof EnhancedQueryContainer) {
          result += getSummary(statement)
        } else if (statement instanceof EnhancedQueryStatement) {
          switch (statement.statementType) {
            case 'default':
              result += `<span class="attribute" title="Attribute Column ${statement.attributeA}">[${statement.attributeA}]</span>
                <span class="operator" title="${statement.operatorType} Operator ${statement.operator.operation}">
                  ${statement.operator.symbol}</span>
                <span class="${statement.attributeBType}"
                  title="${statement.attributeBType === 'attribute' ? 'Attribute Column' : 'Value'} ${statement.attributeB}">
                  [${statement.attributeB}]</span>`
              break
            case 'statistic':
              result += `<span class="statistic" title="Statistic Query ${statement.attributeA}">[${statement.attributeA}]</span>
                <span class="operator" title="${statement.operatorType} Operator ${statement.operator.operation}">
                  ${statement.operator.symbol}</span>
                <span class="${statement.attributeBType}"
                  title="${statement.attributeBType === 'attribute' ? 'Attribute Column' : 'Value'} ${statement.attributeB}">
                  [${statement.attributeB}]</span>`
              break
            case 'attribute':
              result += `<span class="attribute" title="Attribute Column ${statement.attributeB}">[${statement.attributeB}]</span>`
              break
            case 'value':
              result += `<span class="value" title="Value ${statement.attributeB}">[${statement.attributeB}]</span>`
              break
          }
        }
        if (container.operators.length > i) {
          result += `<span class="operator" title="Operator ${container.operators[i].operation}">
            ${container.operators[i].symbol} </span> `
        }
      }

      if (container.caseBlocks.length) {
        result += ' ) '
        for (const caseBlock of container.caseBlocks) {
          result += `<br>Case:`
          for (const [i, condStatement] of caseBlock.conditionStatements.entries()) {
            result += '<span class="case" title="If result of query statements equals to">'
            if (i > 0) result += `${caseBlock.operators[i - 1].operation}`
            result += ` ${condStatement.operator.operation} ${condStatement.value} </span>`
          }
          result += `${getSummary(caseBlock.block as EnhancedQueryContainer)}`
        }
      }

      return result
    }

    this.summary = getSummary(this.queryObj.query.enhancedQuery as EnhancedQueryContainer)
    return this.summary
  }

  getUniqueName () {
    if (!this.queryObj || !this.queryObj.query.enhancedQuery) return
    const getNames = (container: EnhancedQueryContainer, name?: string): string[] => {
      const names: string[] = []

      if (container.createAttrColumn && container.attrColumnName) names.push(container.attrColumnName)
      for (const statement of container.statements) {
        if (statement instanceof EnhancedQueryContainer) {
          getNames(statement).forEach(s => {
            names.push(s)
          })
        } else {
          if (statement.createAttrColumn && statement.attrColumnName) names.push(statement.attrColumnName)
        }
      }

      if (container.caseBlocks.length) {
        for (const caseBlock of container.caseBlocks) {
          getNames(caseBlock.block as EnhancedQueryContainer).forEach(s => {
            names.push(s)
          })
        }
        if (container.caseColumnName) names.push(container.caseColumnName)
      }

      return names
    }

    const nameList: string[] = []
    this.enhancedQueryList.forEach(query => {
      nameList.push(...getNames(query.query.enhancedQuery as EnhancedQueryContainer))
    })

    this.duplicateColumnNames = nameList.filter((e, i, a) => a.indexOf(e) !== i)
  }

  customCollapsedHeight (n: number): string {
    const offset = n === 1 ? 15 : 25
    return `${n * 40 + offset}px`
  }

  checkDuplicateName (name: string) {
    this.getUniqueName()
    return this.duplicateColumnNames.includes(name)
  }

  onStatementTypeChange (val, statement: EnhancedQueryStatement) {
    switch (val) {
      case 'attribute':
      case 'value':
        statement.attributeB = ''
        break
    }
  }

  onOperatorTypeGroupChange (statement: EnhancedQueryStatement) {
    statement.operator = {
      operation: '',
      resultType: 'number',
      operands: 0,
      symbol: ''
    }
  }

  onAttributeBTypeGroupChange (statement: EnhancedQueryStatement) {
    statement.attributeB = ''
  }

  onSubmit (ngForm) {
    console.log('ngForm.form.value', ngForm.form.value)
  }

  private NewEmptyQuery (name: string, layer: AppLayer) {
    const newQuery: IQueryWRef = {
      type: 'enhanced',
      applied: true,
      name,
      targetRef: layer,
      query: {
        blocks: [],
        enhancedQuery: new EnhancedQueryContainer([], [
          new EnhancedQueryStatement({ operation: '', resultType: 'number', operands: 0, symbol: '' }, '', '', 'attribute')
        ]),
        operator: ''
      },
      index: undefined as any,
      query_id: undefined as any,
      view_id: undefined as any,
      workspace_id: undefined as any,
      layer_id: layer.id
    }

    newQuery.index = this._layerQueriesService.queries.length
    return newQuery

  }
}
