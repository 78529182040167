import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core'

@Component({
  selector: 'app-file-drag-drop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss']
})
export class FileDragDropComponent {
  @HostBinding('style.opacity') private _opacity = '1'
  @HostBinding('style.pointer-events') private _pEvents = 'initial'
  @HostBinding('class') private _class = 'app-file-drag-drop'

  @Input() multiple: boolean = false
  @Input() buttonText = `Browse or drag file${this.multiple ? 's' : ''}`
  @Input() type: 'button' | 'area' = 'area'
  @Input() captureWindow: boolean = false
  @Input() accept?: string
  @Input() contentClass?: string
  private _disabled = false
  @Input()
  get disabled () {
    return this._disabled
  }
  set disabled (val: any) {
    this._disabled = !!val
    this._opacity = this._disabled ? '0.6' : '1'
    this._pEvents = this._disabled ? 'none' : 'initial'
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilesDropped = new EventEmitter<File[] | undefined>()

  filesSelected (event: Event) {
    const input = event.target as HTMLInputElement
    this.onFilesDropped.emit(input.files ? Array.from(input.files) : undefined)
    input.value = ''
  }
}
