import { Component, Inject, OnDestroy } from '@angular/core'
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { CommonUtil } from '@core/utils'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'

interface DialogData {
  columnTitleLabel: string,
  columnValueLabel: string,
  titles: string[],
  values: number[]
}

interface IPieChartValues {
  title: string,
  count: number
}

@Component({
  selector: 'app-pie-data-table-dialog',
  templateUrl: './pie-data-table-dialog.component.html',
  styleUrls: ['./pie-data-table-dialog.component.scss']
})
export class PieDataTableDialogComponent implements DialogCleanup, OnDestroy {

   // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs () { return }
  dataSource?: MatTableDataSource<IPieChartValues>
  displayedColumns: string[]
  stickyHeader = true

  static setOptions (data: DialogData): MatDialogConfig {
    const options: MatDialogConfig = {
      data
    }
    return options
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    const formattedDataSource = data.titles.map((t,i) => {
      return {
        title: t,
        count: data.values[i]
      }
    })
    this.displayedColumns = [
      'title',
      'count'
    ]
    this.dataSource = new MatTableDataSource(formattedDataSource)
  }

  downloadData() {
    let csvContent = ''
    if (this.data.titles.length && this.data.values.length) {
      const columnsNames = [this.data.columnTitleLabel, this.data.columnValueLabel]
      const dataToSave = this.data.titles.map((t,i) => {
        return [t, this.data.values[i]]
      })
      ; [columnsNames, ...dataToSave].forEach((rowArray: any) => {
        const row = rowArray.join(',')
        csvContent += row + '\r\n'
      })
      CommonUtil.downloadBlob(csvContent, `PieExport - ${new Date().toLocaleDateString()}.csv`, 'csv')
    }
  }

  ngOnDestroy () {
    this._destroyDialogs()
  }
}
