import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Subject, Observable } from 'rxjs'
import { IFeatureTooltip } from '@core/types/'
import { TableTooltipComponent } from '@core/components/table-tooltip/table-tooltip.component'
@Injectable({
  providedIn: 'root'
})
export class TableTooltipService {
  private _tooltip?: IFeatureTooltip
  private _dialogRef?: MatDialogRef<TableTooltipComponent>
  private _alertClosed = new Subject()

  get alertClosed (): Observable<any> {
    return this._alertClosed.asObservable()
  }

  constructor (private _dialog: MatDialog) {}

  showTooltip (tooltip: IFeatureTooltip, position: { top: number; left: number, anchor: 'middle' | 'left' }, cssClass?: string) {
    if (this._tooltip !== tooltip) {
      this.hideTooltip()
      this._tooltip = tooltip

      this._dialogRef = this._dialog.open(
        TableTooltipComponent,
        TableTooltipComponent.setOptions(tooltip, position, cssClass)
      )
    }
  }

  hideTooltip () {
    if (this._dialogRef && this._dialogRef.close) {
      this._dialogRef.close()
      this._tooltip = undefined
    }
  }
}
