import { Injectable } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { Observable, Subject, interval, BehaviorSubject } from 'rxjs'
import { JwtService } from '../jwt/jwt.service'
import { VipApiService } from '../vip-api/vip-api.service'
import { IPLogin, IPNewToken, IPNewPasswordReset, IPNewPassword, IPUserDetails } from '@vip-shared/interfaces/api/api-body-types'
import AppError from '@core/models/app-error'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { MatDialogRef } from '@angular/material/dialog'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DynamicAlertService } from '../dynamic-alert/dynamic-alert.service'
import { AlertService } from '../alert/alert.service'
import { API } from '@vip-shared/interfaces/api-helper'
import { DomainService } from '../domain/domain.service'
import { IJson, MFAStatus } from '@vip-shared/interfaces'

@Injectable({
  providedIn: 'root'
})
export class AuthService implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs (): any { return }

  private _activeSession?: API.Res.SessionUser
  private _accountsUpdated: Subject<void> = new Subject()

  get isSysAdmin (): boolean {
    return this.permissionsLevel <= Db.Vip.Role.SYS_ADMIN
  }

  get isSysMaintainer (): boolean {
    return this.permissionsLevel <= Db.Vip.Role.SYS_MAINTAINER
  }

  get customerId (): string {
    return this._activeSession ? this._activeSession.customer_id : ''
  }

  get permissionsLevel (): number {
    return this._activeSession ? +this._activeSession.permissions_level : -1
  }

  get userId (): number {
    return this._activeSession ? +this._activeSession.user_id : -1
  }

  get fullName (): string {
    if (!this._activeSession) return ''
    return `${this._activeSession.user_forename} ${this._activeSession.user_surname}`
  }

  get forename (): string {
    if (!this._activeSession) return ''
    return this._activeSession.user_forename
  }

  get surname (): string {
    if (!this._activeSession) return ''
    return this._activeSession.user_surname
  }

  get email (): string {
    if (!this._activeSession) return ''
    return this._activeSession.user_email
  }

  get mfa_optout (): boolean {
    return this._activeSession?.mfa_optout ?? false;
  }

  get roleName (): string {
    return this._activeSession ? this._activeSession.role_display_name : ''
  }

  get canManageUsers (): boolean {
    if (!this._activeSession) return false
    return this.isSysMaintainer || (
      this._activeSession.permissions_level <= Db.Vip.Role.CUST_ADMIN_BASIC &&
      this._activeSession.can_manage_users
    )
  }

  get companyName (): string {
    if (this._activeSession) {
      return this._activeSession.customer.customer_name
    } else {
      return 'Geospatial Insight'
    }
  }

  get customer (): undefined | API.Res.SessionUser['customer'] {
    return this._activeSession && this._activeSession.customer
  }

  get accountsUpdated (): Observable<void> {
    return this._accountsUpdated.asObservable()
  }

  get MFAStatus (): MFAStatus | undefined {
    const jwtValue = this._jwtService.readJWT();
    return jwtValue?.mfa_status;
  }

  get MFAQRCode (): string {
    const jwtValue = this._jwtService.readJWT();
    return jwtValue?.mfa_qrcode ?? '';
  }

  get canOptOutMFA (): boolean {
    const jwtValue = this._jwtService.readJWT();
    return !!jwtValue?.mfa_can_optout ?? false;
  }

  private _sessionActive = new BehaviorSubject<boolean>(false)
  get sessionActive () {
    return this._sessionActive.asObservable()
  }

  private _expiryWarning?: MatDialogRef<any>
  private _tokenExpiry?: moment.Moment
  get expireIn () {
    if (!this._tokenExpiry) return ''

    return `${this._tokenExpiry.diff(moment(), 'minute')}m ${this._tokenExpiry.diff(moment(), 's') % 60}s`
  }
  private get _expireThreshold () {
    return moment().add(10, 'm')
  }

  private _tokenMonitorJob?: ReturnType<typeof setTimeout>

  constructor (
        private _jwtService: JwtService,
        private _api: VipApiService,
        private _router: Router,
        private _dynamicAlertService: DynamicAlertService,
        private _alertService: AlertService,
        private _domainService: DomainService
    ) {
    this.Init()

    this._jwtService.tokenChange.subscribe(token => {
      if (!token) {
        this._activeSession = undefined
        this._sessionActive.next(false)
        if (this._expiryWarning) {
          this._expiryWarning.close()
          this._expiryWarning = undefined
        }
      }
    })
  }

  private async Init () {
    if (this.isLogged()) await this.getSessionInfo()
  }

  private async CheckSessionExpiry () {
    if (!this._jwtService.isJWTSet()) return

    if (this._tokenExpiry && this._tokenExpiry.isSameOrBefore(this._expireThreshold)) {
      if (!this._expiryWarning) {
        const getMessage = () => `Your session will expire in ${this.expireIn}. Do you want to extend the session?`
        const obj = {
          title: 'Session Expiration',
          titleIcon: 'warning',
          content: getMessage(),
          actions: {
            logout: () => this.logout(),
            yes: () => this.getSessionInfo()
          }
        }

        this._expiryWarning = this._trackDialog(
            this._dynamicAlertService.log(obj)
          )

        const updateText = interval(1000).subscribe(() => {
          if (!this._tokenExpiry || this._tokenExpiry.diff(moment(), 's') < 1) {
            this.logout(true)
          }
          obj.content = getMessage()
        })
        const onClose = this._expiryWarning.beforeClosed().subscribe(() => {
          updateText.unsubscribe()
          onClose.unsubscribe()
          this._expiryWarning = undefined
        })
      }
    }
  }

  private async UpdateTokenExpiry () {
    const expiry = await this._api.orm.Users().tokenExpiry().run()
    const time = expiry && moment(expiry)

    if (!time || !time.isValid() || time.isSameOrBefore(moment())) {
      this.logout(true)
      return
    }
    this._tokenExpiry = time

    if (this._tokenMonitorJob) clearTimeout(this._tokenMonitorJob)

    const checkAt = moment(this._tokenExpiry).subtract(10, 'm')
    if (this._tokenExpiry.isSameOrBefore(this._expireThreshold)) {
      this.CheckSessionExpiry()
      return
    }

    this._tokenMonitorJob = setTimeout(() =>
      this.UpdateTokenExpiry(), checkAt.diff(moment(), 'ms')
    )
  }

  async logout (onSessionExpire = false) {
    this._router.navigate([onSessionExpire ? '/login' : '/logout'])
    if (onSessionExpire) {
      this._alertService.log(
        `Your last session was automatically logged out after 2 hours of inactivity, please log in again to proceed.`
      )
    }
    await this._api.orm.Users().logout().run()

    this._jwtService.clearJWT()
    this._activeSession = undefined
    this._sessionActive.next(false)
  }

  async login (email: string, password: string) {
    const postData: IPLogin = {
      user_email: email,
      user_password: password
    }

    const jwt = await this._api.orm.Users().login(postData).run()

    if (!jwt) throw new AppError('Failed to get session token.')
    this._jwtService.setJWT(jwt.toString())
    this.getSessionInfo()
    this._sessionActive.next(true)
  }

  async optoutMFA() {
    const jwt = await this._api.orm.Users().MFAOptOut().run();

    if (!jwt) throw new AppError('Failed to get session token.');
    this._jwtService.setJWT(jwt.toString());
    this.getSessionInfo();
    this._sessionActive.next(true);
  }

  async verifyOTP(otp: string) {
    const jwt = await this._api.orm.Users().verifyOTP({ otp }).run();

    if (!jwt) throw new AppError('Failed to get session token.');

    this._jwtService.setJWT(jwt.toString());
    this.getSessionInfo();
    this._sessionActive.next(true);
  }

  async getSessionInfo () {
    try {
      this._activeSession = await this._api.orm.Users().getUser().run()
      this.UpdateTokenExpiry()
    } catch (error: any) {
      this._activeSession = undefined
      this._jwtService.clearJWT()
      this._router.navigate(['/login'])
    }

    this._sessionActive.next(!!this._activeSession)
  }

  isLogged (): boolean {
    return this._jwtService.isJWTSet()
      && !!this.MFAStatus
      && [MFAStatus.AUTHENTICATED, MFAStatus.OPT_OUT].includes(this.MFAStatus);
  }

  setToken (token: string) {
    this._jwtService.setJWT(token)
  }

  async getTokens () {
    return this._api.orm.Users().Tokens().get().run()
  }

  async deleteUserToken (tokenId: string) {
    await this._api.orm.Users().Tokens().delete(tokenId).run()
  }

  async deleteUser (userId: number) {
    await this._api.orm.Users().User(userId).delete().run()
  }

  async createToken (tokenConfig: IPNewToken) {
    return this._api.orm.Users().Tokens().issueToUser(tokenConfig).run()
  }

  async getPasswordReset (email: string) {
    await this._api.orm.Users().getPasswordReset(email, this._domainService.mockSubdomain).run()
  }

  async resetPassword (email: string, uuid: string, newPassword: string) {
    const passwordReset: IPNewPasswordReset = {
      new_password: newPassword,
      user_email: email,
      user_recovery_guid: uuid
    }
    await this._api.orm.Users().resetPassword(passwordReset).run()
  }

  async changePassword (newPassword: IPNewPassword) {
    await this._api.orm.Users().changePassword(newPassword).run()
  }

  async changeDetails (newDetails: IPUserDetails) {
    await this._api.orm.Users().changeDetails(newDetails).run()
  }

  async verifyEmail (token: string) {
    await this._api.orm.Users().verifyEmail(token).run();
  }

  createdEntry (entry: IJson) {
    const createCol = 'create_user'
    if (!this._activeSession || !Object.keys(entry).includes(createCol)) return false

    const createdBy: string = entry[createCol]
    if (!createdBy) return false

    if (
      createdBy === `${this._activeSession.user_id}` ||
      createdBy.startsWith(`${this._activeSession.user_id}:`)
    ) return true

    return false
  }

  updateAccountList () {
    this._accountsUpdated.next()
  }
}

applyMixins(AuthService, [DialogCleanup])
