import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { AuthService, AlertService } from '@services/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import AppError, { handleError } from '@core/models/app-error'
import { DomainService } from '@services/core/domain/domain.service'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { FormTemplate } from '@core/models/form-template'
import { take } from 'rxjs/operators'
import { ThemeService } from '@services/core/theme/theme.service'

type Form = TypedFormGroup<{
  password: TypedFormControl<string>
  repeatPassword: TypedFormControl<string>
}>

@Component({
  templateUrl: './password-reset.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class PasswordResetComponent extends FormTemplate<Form> implements OnInit {
  private _resetUuid!: string
  private _userEmail!: string

  get showCopyright () {
    return this._domainService.showGSICopyright
  }

  constructor (
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _domainService: DomainService,
    themeService: ThemeService,
    private _alertService: AlertService,
    private _router: Router
  ) {
    super(
      new TypedFormGroup({
        password: new TypedFormControl<string>(undefined, Validators.required),
        repeatPassword: new TypedFormControl<string>(undefined, Validators.required)
      })
    )

    themeService.setTitle('Reset password')
  }

  ngOnInit () {
    this._activatedRoute.queryParams
    .pipe(take(1)).subscribe((queryParams: any) => {
      this._resetUuid = queryParams['uuid']
      this._userEmail = queryParams['user_email']

      if (!this._userEmail || !this._resetUuid) {
        this._alertService.log('Password reset link invalid.')
        this._router.navigateByUrl('/')
      }
    })
  }

  async submit () {
    if (this.saving) return

    this.saving = true
    this.errorMessage = undefined

    try {
      if (!this.form.valid) throw new AppError('Form invalid.')

      const psw = this.form._controls.password.value as string

      if (psw.length < 8 || psw.length > 30) {
        throw new AppError('The password needs to be between 8 and 30 characters long.')
      }
      if (!psw.match('[a-z]')) {
        throw new AppError('The password needs to include at least one lowercase letter.')
      }
      if (!psw.match('[A-Z]')) {
        throw new AppError('The password needs to include at least one uppercase letter.')
      }
      if (!psw.match('[0-9]')) {
        throw new AppError('The password needs to include at least one digit.')
      }
      if (psw.includes(' ')) {
        throw new AppError('The password can\'t contain spaces.')
      }
      if (psw !== this.form._controls.repeatPassword.value) {
        throw new AppError('Passwords do not match.')
      }

      await this._authService.resetPassword(this._userEmail, this._resetUuid, psw)
      this.submitted = true
    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    } finally {
      this.saving = false
    }
  }

}
