import { Injectable } from '@angular/core'
import { cloneDeep } from 'lodash'
import { IStyleHistoryElement } from '@core/types'
import { handleError } from '@core/models/app-error'
import { ILayerStyleConfig, IStyleObject } from '@core/types'

@Injectable({
  providedIn: 'root'
})
export class StyleHistoryService {
  private readonly _maxHistory = 20
  private _history: IStyleHistoryElement[] = []
  private _storageKey = 'style_history'
  private _copiedStyle?:IStyleObject

  get copiedStyle() {
    return this._copiedStyle
  }

  set copiedStyle (val:IStyleObject | undefined) {
    this._copiedStyle = val
  }

  constructor () {
    const saved = localStorage.getItem(this._storageKey)
    if (saved) {
      try {
        this._history = JSON.parse(saved)
      } catch (error: any) {
        handleError(error)
      }
    }
  }
  add (style: IStyleHistoryElement) {
    const index = this._history.findIndex(x => JSON.stringify(x) === JSON.stringify(style))
    if (index === -1) {
      this._history.push(cloneDeep(style))
    } else {
      this._history.push(this._history.splice(index, 1)[0])
    }

    this._history.splice(0, this._history.length - this._maxHistory)

    localStorage.setItem(this._storageKey, JSON.stringify(this._history))
  }

  // @description Get list of previously used styles. If columns are defined, return
  // previously used gradients only if matching column is found
  getFiltered (columns: string[] = [], cap: number = 0) {
    const styles = [...this._history].filter(x =>
      x.type === 'gradient' ? columns.includes(x.column) : true
    )
    return styles.splice(-cap)
  }

  getSolids () {
    const styles = [...this._history].filter(x =>
      x.type !== 'gradient'
    )
    return styles
  }
}
