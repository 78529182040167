import { Injectable } from '@angular/core'
import { IWorkspaceUIElements } from '@core/types'
import { AlertService } from '@services/core/alert/alert.service'
import { environment } from 'environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  readonly maxApiFileSize = Math.pow(1024, 3) * 10
  readonly maxApiLayerSize = Math.pow(1024, 3) * 50
  // For files that will be used as buffer for get metadata
  readonly maxApiBufferSize = Math.pow(1024, 3) * 2
  readonly maxImageFileSize = Math.pow(1024, 2) * 10
  private _viewerElements: IWorkspaceUIElements
  private _measureControlsVisible = true
  private _mouseControlVisible = false

  get staging (): boolean {
    return environment.environment !== 'prod'
  }

  get measureControlsVisible (): boolean {
    return this._measureControlsVisible
  }

  get mouseControlVisible (): boolean {
    return this._mouseControlVisible
  }

  constructor (
    private _alertService: AlertService
  ) {
    this._viewerElements = {
      home: true,
      search: true,
      map: true,
      air_routes: true,
      info: true,
      layers: true,
      views: true,
      query_builder: true,
      date_picker: true,
      chart_list: true,
      logout: true,
      help: true,
      about: true
    }
  }

  isIEorEdge (dialog = true) {
    let ieOrEdge = false
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    if (msie > 0 && !ieOrEdge) {
      // IE 10 or older => return version number
      ieOrEdge = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) >= 0 ? true : false
    }

    const trident = ua.indexOf('Trident/')
    if (trident > 0 && !ieOrEdge) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:')
      ieOrEdge = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) >= 0 ? true : false
    }

    const edge = ua.indexOf('Edge/')
    if (edge > 0 && !ieOrEdge) {
      // Edge (IE 12+) => return version number
      ieOrEdge = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) >= 0 ? true : false
    }

    if (ieOrEdge) {
      if (dialog) {
        this._alertService.log('We notice you\'re using Edge or IE, ' +
          'we recommend using Chrome as performance will be much improved.\nUsing Edge or IE may result in unexpected component behavior.', true)
      } else {
        return true
      }
    }
  }

  isFirefox () {
    const ua = window.navigator.userAgent
    const firefoxIndex = ua.indexOf('Firefox/')
    if (firefoxIndex > 0) {
      return true
    }
    return false
  }

  hasField (str: string): boolean {
    if (str in this._viewerElements) {
      return true
    } else {
      return false
    }
  }

  toggleMeasureControlsVisible (visible?: boolean) {
    if (typeof visible === 'boolean') {
      this._measureControlsVisible = visible
    } else {
      this._measureControlsVisible = !this._measureControlsVisible
    }
  }

  toggleMousePanelVisible () {
    this._mouseControlVisible = !this._mouseControlVisible
  }
}
