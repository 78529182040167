<h2>One-Click Setup Menu</h2>
<app-note color="warn">
  The one-click setup function should only be used for a first draft of a new dashboard.<br>
  This function will create new data layers in the workspace and might affect loading times if used continuously.<br>
  If a user needs a new view we recommend using the copy function from the "+Create new view" option in the view
  selector.
</app-note>
<app-timeseries-selection-form #timeseriesForm
  [preFillFrom]="layerContentForm?.controls.datetimeSelection?.value.valid_from"
  [preFillTo]="layerContentForm?.controls.datetimeSelection?.value.valid_to"
  [preFillFocal]="layerContentForm?.controls.datetimeSelection?.value.focal_point"
  (formChange)="layerContentForm && updateDateCheck(layerContentForm, $event, timeseriesForm.formControlKeys)">
</app-timeseries-selection-form>
<div class="st-button-row">
  <button color="primary" mat-button [mat-dialog-close]>Cancel</button>
  <app-submit-button *ngIf="!loading" mat-button text="Setup" (submit)="createLayers()">
  </app-submit-button>
  <mat-spinner *ngIf="loading" [diameter]="25" color="primary" class="spinner"></mat-spinner>
</div>
