import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import AppError, { handleError } from '@core/models/app-error'
import { AlertService, VipApiService } from '@services/core'
import { ChartsService } from '@services/workspace/layer/charts/charts.service'
import { LayerService, WorkspaceService } from '@services/workspace'
import { Db } from '@vip-shared/models/db-definitions'
import { BehaviorSubject } from 'rxjs'
import { IChartWRef } from '@core/models/chart-object'
import { IOneClickConfig, IRVectorColumn, ITimeRange } from 'vip-shared/dist/interfaces'
import { IDashboardViewModel } from 'vip-shared/dist/interfaces/dashboard/IDashboardViewModel'
import { CommonUtil } from '@core/utils'

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {
  private dashboardPayload: IDashboardViewModel[] = []
  private _pageConfigs!: Db.Vip.Prj.IDashboardPage[]
  get pageConfigs () {
    return this._pageConfigs
  }
  get widgets () {
    return this.dashboardPayload
  }

  private _availableViews!: Db.Vip.Prj.IDashboardView[]
  get availableViews () {
    return this._availableViews
  }

  private _viewId!: number
  get viewId () {
    return this._viewId
  }

  private _workspaceId!: number
  get workspaceId () {
    return this._workspaceId
  }

  private _availableLayers!: Db.Vip.Geo.ILayer[]
  get availableLayers () {
    return this._availableLayers
  }

  private _dashboardLoaded = new BehaviorSubject<boolean>(false)
  get dashboardLoaded () {
    return this._dashboardLoaded.asObservable()
  }

  private _availableCharts!: Db.Vip.Prj.IChart[]
  get availableCharts () {
    return this._availableCharts
  }

  constructor (
    private _api: VipApiService,
    private _chartSerivce: ChartsService,
    private _workspaceService: WorkspaceService,
    private _layerService: LayerService,
    private _router: Router,
    private _alertService: AlertService
  ) { }

  isProductType (productTypes: Db.Vip.Product[]): boolean {
    return this._workspaceService.isOneOf(productTypes)
  }

  async availableColumnsForLayer (layerId: string, numericColumns?: boolean): Promise<IRVectorColumn[]> {
    const layer = await this._layerService.getById(layerId)
    return layer ?
    numericColumns ? layer.tableColumns.filter(x => ['integer', 'float'].includes(x.type)) : layer.tableColumns
    : []
  }

  async getDashboards (workspaceId: number) {
    try {
      this._workspaceId = workspaceId
      this._availableViews = await this._api.orm.Dashboards().get(workspaceId).run() as Db.Vip.Prj.IDashboardView[]
      for (const v of this._availableViews) {
        if (v.is_default) {
          this._viewId = v.view_id
          break
        }
      }

      await this.GetPages()

      // GET default view for user
      this.dashboardPayload = await this._api.orm.Dashboards().getView(workspaceId, this._viewId).run() as IDashboardViewModel[]
      if (!this.dashboardPayload) {
        this._router.navigate(['/error/not-found'])
        throw new AppError(`Failed to find Dashboard for workspace ${workspaceId}`)
      }
      this._dashboardLoaded.next(true)
    } catch (error: any) {
      handleError(error)
    }
  }

  async configureOneClickSetup (timerange: ITimeRange) {
    try {
      const body: IOneClickConfig = {
        workspace_id: this.workspaceId,
        timerange: timerange
      }
      await this._api.orm.Dashboards().saveOneClickSetup(body).run()
    } catch (error) {
      handleError(error)
    }
  }

  async saveDashboards (dashboards: Db.Vip.Prj.IWidget[]) {
    try {
      if (this._workspaceId) {
        dashboards.forEach(widget => {
          widget.workspace_id = this._workspaceId
        })
        await this._api.orm.Dashboards().save(this._workspaceId, this._viewId, dashboards).run()
        await this.GetPages()
      }
    } catch (error: any) {
      handleError(error)
    }
  }

  async deleteWidget (widgetId: string) {
    try {
      if (this._workspaceId && widgetId) {
        return await this._api.orm.Dashboards().deleteWidget(this._workspaceId, this._viewId, widgetId).run()
      }
    } catch (error: any) {
      handleError(error)
      return []
    }
  }

  private async GetPages () {
    this._pageConfigs = await this._api.orm.Dashboards().getPageConfigs(this._workspaceId, this._viewId).run()
  }

  async savePages(pageNumber: number, pageName: string) {
    this._pageConfigs.forEach(p => {
      if (p.page_number === pageNumber) p.page_name = pageName
    })
    await this._api.orm.Dashboards().savePageConfigs(this._pageConfigs).run()
  }

  async getLayers (workspaceId: number) {
    try {
      this._workspaceId = workspaceId
      this._availableLayers = await this._api.orm.Layers().getLayers(workspaceId).run()
    } catch (error: any) {
      handleError(error)
    }
  }

  async getCharts (workspaceId: number) {
    try {
      // NOTE: The viewId is sometimes cleared  when changing views and
      // if it's not loaded by a map or table widget we need to load it to get charts.
      if (!this._workspaceService.workspaceId) {
        await this._workspaceService.loadWorkspace(workspaceId)
        await this._workspaceService.waitForWorkspaceAndView()
      }
      const charts = await this._chartSerivce.orm.get().run()
      // Match every query with a layer
      this._availableCharts = charts.map((x, i) => ({
        ...x,
        target: this._availableLayers.find(y => y.layer_id === x.layer_id)
      }))
      // Discard queries without any layer reference
      .filter(i => !!i.target)
    } catch (error: any) {
      handleError(error)
    }
  }

  async switchView (view: Db.Vip.Prj.IDashboardView) {
    try {
      this._viewId = view.view_id
      await this.GetPages()
      this.dashboardPayload = await this._api.orm.Dashboards().getView(this.workspaceId, this._viewId).run() as IDashboardViewModel[]
      if (!this.dashboardPayload) {
        this._router.navigate(['/error/not-found'])
        throw new AppError(`Failed to find Dashboard for workspace ${this.workspaceId} and view ${this._viewId}`)
      }
      this._dashboardLoaded.next(true)
    } catch (error: any) {
      this._alertService.log(error.message)
      handleError(error)
    }
  }

  async createView (viewConfig: {
    view_name: string,
    copy_from_view?: number
  }) {
    try {
      const newView = await this._api.orm.Dashboards().saveView(this.workspaceId, viewConfig).run() as Db.Vip.Prj.IDashboardView
      if (newView) {
        this._availableViews.push(newView)
        this._viewId = newView.view_id
        this.dashboardPayload = await this._api.orm.Dashboards().getView(this.workspaceId, this._viewId).run() as IDashboardViewModel[]
          if (!this.dashboardPayload) {
            this._router.navigate(['/error/not-found'])
            throw new AppError(`Failed to find Dashboard for workspace ${this.workspaceId} and view ${this._viewId}`)
          }
        this._dashboardLoaded.next(true)
      } else {
        throw new AppError(`Cannot create dashboard view. Please contact admin.`)
      }
    } catch (error: any) {
      this._alertService.log(error.message)
      handleError(error)
    }
  }

  async setDefaultView () {
    try {
      const view = this._availableViews.filter(v => v.view_id === this.viewId)[0]
      if (view.is_default) {
        throw new AppError(`Current view is already the default one.`)
      } else {
        await this._api.orm.Dashboards().setDefaultView(this.workspaceId, this._viewId).run()
        this._availableViews.forEach(v => v.is_default = v.view_id === this._viewId)
      }
    } catch (error: any) {
      this._alertService.log(error.message)
      handleError(error)
    }
  }

  async deleteView () {
    try {
      const view = this.availableViews.filter(v => v.view_id === this.viewId)[0]
      if (view.is_default) {
        throw new AppError(`Cannot delete default view.`)
      } else {
        await this._api.orm.Dashboards().deleteView(this._workspaceId, this._viewId).run()
        this._availableViews = this._availableViews.filter(v => v.view_id !== this.viewId)
        for (const v of this._availableViews) {
          if (v.is_default) {
            this._viewId = v.view_id
            break
          }
        }
        this.dashboardPayload = await this._api.orm.Dashboards().getView(this.workspaceId, this._viewId).run() as IDashboardViewModel[]
        if (!this.dashboardPayload) {
          this._router.navigate(['/error/not-found'])
          throw new AppError(`Failed to find Dashboard for workspace ${this.workspaceId}`)
        }
        this._dashboardLoaded.next(true)
      }
    } catch (error: any) {
      this._alertService.log(error.message)
      handleError(error)
    }
  }
}
