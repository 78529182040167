import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core'
import { AlertService } from '@services/core'
import { WorkspaceService } from '@services/workspace'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IRVectorColumn, IPNewVectorRow, IPVectorTableRow } from '@vip-shared/interfaces'
import { UntypedFormControl, Validators } from '@angular/forms'
import FormValidators from '@core/utils/form-validators/form-validators'
import { AppLayer } from '@core/models/layer'
import { WktPreviewComponent } from '../wkt-preview/wkt-preview.component'
import { handleError } from '../../models/app-error'
import { Columns } from '@vip-shared/models/const/system-vector-cols'

type IVectorColumnExtended = IRVectorColumn & {formControl: UntypedFormControl}
type ValidatorCallback = typeof Validators.required

interface DialogData {
  layer: AppLayer
  rows?: Partial<IPVectorTableRow>[]
  originalId?: string | number
}
@Component({
  selector: 'app-new-attributes-row-dialog',
  templateUrl: './new-attributes-row-dialog.component.html',
  styleUrls: ['./new-attributes-row-dialog.component.scss']
})
export class NewAttributesRowDialogComponent implements OnInit {
  canCreate = false
  saving = false
  fetchingData = true
  forms: {
    columnMetadata: IVectorColumnExtended[]
    wktControl: UntypedFormControl
  }[] = []
  @ViewChildren('preview') previewEls?: QueryList<WktPreviewComponent>

  static setup (data: {layer: AppLayer, rows?: Partial<IPVectorTableRow>[], originalId?: string | number}) {
    return {
      data
    }
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: {layer: AppLayer, rows?: Partial<IPVectorTableRow>[], originalId?: string | number},
    private _dialogRef: MatDialogRef<NewAttributesRowDialogComponent>,
    private _workspaceService: WorkspaceService,
    private _alertService: AlertService
  ) {}

  ngOnInit () {
    this.LoadColumns()
  }

  private async LoadColumns () {
    try {
      if (!this.data.rows) this.data.rows = [{}]
      const columns = (await this._workspaceService.orm.Layer(this.data.layer.id).getColumns().run()).filter( c => !Columns.System.includes(c.name))

      for (const row of this.data.rows) {
        const wktControl = new UntypedFormControl(row.wkt_geometry, [
          FormValidators.wktValid()
        ])
        if (row.wkt_geometry) wktControl.markAsTouched()

        this.forms.push({
          columnMetadata: columns.map(x =>
            ({
              ...x,
              formControl: new UntypedFormControl(
                row[x.name] || (x.default !== null ? x.default : undefined), this.GetColumnValidators(x)
              )
            })
          ),
          wktControl
        })
      }

      this.checkInputsValidity()
    } catch (error: any) {
      this._alertService.log(error.message)
      this._dialogRef.close(false)
    } finally {
      this.fetchingData = false
    }
  }

  updateMaps () {
    if (!this.previewEls) return
    this.previewEls.forEach(e => e.updateMap())
  }

  private GetColumnValidators (col: IRVectorColumn): ValidatorCallback[] {
    const validators: ValidatorCallback[] = []
    if (!col.nullable) validators.push(Validators.required)

    switch (col.type) {
      case 'boolean':
        validators.push(Validators.pattern(/^true|false$/))
        break
      case 'date':
        validators.push(FormValidators.dateValid)
        break
      case 'integer':
      case 'float':
        validators.push(FormValidators.numberValid)
    }

    return validators
  }

  checkInputsValidity () {
    this.canCreate = this.forms.every(f =>
      f.columnMetadata.every(x => x.formControl.valid)
    )
  }

  async createRow () {
    if (this.saving || !this.canCreate) return
    try {
      this.saving = true
      const rows: IPNewVectorRow[] = []
      for (const f of this.forms) {
        const newRow: IPNewVectorRow = f.columnMetadata.reduce((obj, col) => {
          let val: any
          if (col.formControl.value) {
            if (col.type === 'boolean') {
              val = col.formControl.value === 'true' ? true : false
            } else if (['integer', 'float'].includes(col.type)) {
              val = +col.formControl.value
            } else {
              val = col.formControl.value
            }
          } else {
            val = col.formControl.value
          }
          obj[col.name] = val
          return obj
        }, {})

        if (f.wktControl.value && f.wktControl.valid) {
          const srid = (f.wktControl.value || '').startsWith('SRID') ? '' : 'SRID=4326;'
          newRow.wkt_geometry = `${srid}${f.wktControl.value}`
        }

        rows.push(newRow)
      }

      if (this.data.originalId !== undefined) {
        await this.data.layer.replaceFeaturesList(
          [this.data.originalId],
          rows
        )

      } else {
        await this.data.layer.insertFeatures(rows)
      }

      this._dialogRef.close(true)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }

    this.saving = false
  }
}
