<mat-form-field>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let tag of tags" [selectable]="selectable"
               [removable]="removable" (removed)="remove(tag)" >
               <div class="title-wrapper st-ellipsis">
                <span appOverflowAnimate [style.min-width]='100' >{{ tag }}</span>
              </div>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input [placeholder]="placeholder"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)"
             (click)="onTouched()">
    </mat-chip-list>
</mat-form-field>
