import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AuthService } from '@services/core';
import { DomainService } from '@services/core/domain/domain.service';
import { ThemeService } from '@services/core/theme/theme.service';
import { validate } from 'uuid';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./../auth.styles.scss'],
})
export class VerifyEmailComponent implements OnInit {

  token = "";
  errorMessage = "";
  _status: "idle" | "verifying" | "error" | "success" = "idle";

  @ViewChild("idle") idle!: TemplateRef<any>
  @ViewChild("verifying") verifying!: TemplateRef<any>
  @ViewChild("error") error!: TemplateRef<any>
  @ViewChild("success") success!: TemplateRef<any>

  constructor(
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _domainService: DomainService,
    themeService: ThemeService,
    private _alertService: AlertService,
  ) {
    themeService.setTitle('Verify Email');
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(async (params) => {
      this.token = params["token"];

      if (this.token) {
        if (!validate(this.token)) {
          this._status = "error";
          const message = "Invalid token";
          this.errorMessage = message;
          this._alertService.log(message);
        } else {
          this._status = "verifying";
          try {
            await this._authService.verifyEmail(this.token);
            this._status = "success";
          } catch (error) {
            this._status = "error";
            this.errorMessage = "Unknown error";
            if (error instanceof Error) this.errorMessage = error.message;
            if (typeof error === "string") this.errorMessage = error;
            console.error(error);
          }
        }
      } else {
        this._status = "error";
        const message = "No reset token";
        this.errorMessage = message;
        this._alertService.log(message);
      }
    })
  }

  get showCopyright () {
    return this._domainService.showGSICopyright
  }

  get statusTemplate () {
    return {
      "idle": this.idle,
      "verifying": this.verifying,
      "error": this.error,
      "success": this.success,
    }[this._status]
  }

}
