import { Component, Inject, Optional, ViewChildren, QueryList } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IPNewPVGeometry } from '@vip-shared/interfaces'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import FormValidators from '@core/utils/form-validators/form-validators'
import { FormTemplate } from '@core/models/form-template'
import { WktPreviewComponent } from '../wkt-preview/wkt-preview.component'

@Component({
  selector: 'app-new-pv-geometry-dialog',
  templateUrl: './new-pv-geometry-dialog.component.html',
  styleUrls: ['./new-pv-geometry-dialog.component.scss']
})
export class NewPvGeometryDialogComponent extends FormTemplate<UntypedFormGroup> {
  formKeys: string[] = []
  @ViewChildren('preview') previewEls?: QueryList<WktPreviewComponent>

  constructor (
    protected _dialogRef: MatDialogRef<NewPvGeometryDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: IPNewPVGeometry[]
  ) {
    super(new UntypedFormGroup({}), _dialogRef)

    if (data) {
      for (const index in data) {
        const entry = data[index]
        const indexKey = `${index}`
        this.formKeys.push(indexKey)
        const indexForm = new UntypedFormGroup({
          country: new UntypedFormControl(entry.country),
          city: new UntypedFormControl(entry.city),
          address: new UntypedFormControl(entry.address),
          wktPointFormControl: new UntypedFormControl(entry.point_geometry_wkt, FormValidators.wktValid('Point')),
          wktPolygonFormControl: new UntypedFormControl(entry.polygon_geometry_wkt, FormValidators.wktValid('Polygon'))
        })

        indexForm.controls.wktPointFormControl.markAsTouched()
        indexForm.controls.wktPolygonFormControl.markAsTouched()

        this.form.addControl(indexKey, indexForm)
      }
    } else {
      this.formKeys.push('0')
      this.form.addControl('0', new UntypedFormGroup({
        country: new UntypedFormControl(undefined),
        city: new UntypedFormControl(undefined),
        address: new UntypedFormControl(undefined),
        wktPointFormControl: new UntypedFormControl(undefined, FormValidators.wktValid('Point')),
        wktPolygonFormControl: new UntypedFormControl(undefined, FormValidators.wktValid('Polygon'))
      }))
    }

    this.form.controls.country && this.form.controls.country.disable()
    this.form.controls.city && this.form.controls.city.disable()
    this.form.controls.address && this.form.controls.address.disable()
  }

  updateMaps () {
    if (!this.previewEls) return
    this.previewEls.forEach(e => e.updateMap())
  }

  save () {
    if (!this.form.valid) return

    const forms: UntypedFormGroup[] = Object.keys(this.form.controls).map(k => this.form.controls[k] as UntypedFormGroup)

    const rows: IPNewPVGeometry[] = []
    for (const form of forms) {
      const controls = form.controls
      const row: IPNewPVGeometry = {
        country: controls.country.value,
        city: controls.city.value,
        address: controls.address.value,
        point_geometry_wkt: controls.wktPointFormControl.value,
        polygon_geometry_wkt: controls.wktPolygonFormControl.value
      }

      if (row.point_geometry_wkt && !row.point_geometry_wkt.startsWith('SRID')) {
        row.point_geometry_wkt = `SRID=4326;${row.point_geometry_wkt}`
      }

      if (row.polygon_geometry_wkt && !row.polygon_geometry_wkt.startsWith('SRID')) {
        row.polygon_geometry_wkt = `SRID=4326;${row.polygon_geometry_wkt}`
      }
      rows.push(row)
    }

    this._dialogRef.close(rows)
  }

}
