import { IPNewRasterSource, IRWorkspaceLayer, IPRasterPresetSources, IPRasterChartAPI, IVideoAnimation } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { AppLayer } from './app-layer'
import { VipApiService, PromptService } from '@services/core'
import { CommonUtil, ConvertUtil } from '@core/utils/index'
import { AppLayerGeneric } from './app-layer-generic'
import { MatDialog } from '@angular/material/dialog'
import NewLayerUtil from './utils/new-layer.util'
import TileLayer from 'ol/layer/Tile'
import * as olExtent from 'ol/extent'
import * as olProj from 'ol/proj'
import * as olSource from 'ol/source'
import AppError from '../app-error'
import { LayerUtil } from './utils'
import { environment } from 'environments/environment'
import { Base64 } from 'js-base64'
import { cloneDeep, isEqual } from 'lodash'
import * as moment from 'moment'
import { MomentPipe } from '../../pipes/moment.pipe'
import { Subject } from 'rxjs'
import { WorkspaceService } from '@services/workspace'
import { Coordinate } from 'ol/coordinate'
import { ServerType } from 'ol/source/wms'
import { Options } from 'ol/source/TileWMS'

type ImplementedLayer = TileLayer<any>
export class AppRasterLayer extends AppLayer {
  private readonly _emptyTile = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`

  layer?: ImplementedLayer
  private _sources!: Db.Vip.Geo.IRasterSource[]
  private _renderedSource?: Db.Vip.Geo.IRasterSource
  private _videoAnimationJob?: Db.Vip.Emissions.IJob
  protected _lastUpdatedSourceRange?: Db.Helper.Geo.DateRange
  get renderedSource() {
    return this._renderedSource
  }

  private _sourceChanged = new Subject<Db.Vip.Geo.IRasterSource | undefined>()
  get sourceChanged() {
    return this._sourceChanged.asObservable()
  }

  private _rangeSourcesUpdated = new Subject<void>()
  get rangeSourcesUpdate() {
    return this._rangeSourcesUpdated.asObservable()
  }

  get sources() {
    return this._sources
  }

  get geoserverMeta() {
    if (this.renderedSource) {
      return this.renderedSource.geoserver_meta
    } else {
      return this._sources[0].geoserver_meta
    }
  }

  get bandCount() {
    // TODO: For now get max band count, to not allow styling if there is
    // raster with too many bands
    return Math.max(0, ...this._sources.map(s => s.band_count).filter(c => !!c) as number[])
  }

  get bandMeta() {
    const count = this.bandCount
    const source = count && this._sources.find(s => s.band_count === count && s.band_meta)
    return source && source.band_meta
  }

  get videoAnimationJob() {
    return this._videoAnimationJob
  }

  constructor(
    allElements: () => AppLayerGeneric[],
    allLayers: () => AppLayer[],
    maxIndex: () => number,
    canSelect: () => boolean,
    canDeselect: () => boolean,
    momentPipe: MomentPipe,
    dialog: MatDialog,
    prompt: PromptService,
    api: VipApiService,
    workspaceService: WorkspaceService,
    source: IRWorkspaceLayer,
    viewId: number,
    theme?: Db.Vip.Geo.ILayerAttribute
  ) {
    super(
      allElements, allLayers, maxIndex, canSelect, canDeselect,
      momentPipe, dialog, prompt, api,
      workspaceService,
      source, viewId, theme, false
    )
    if (this.typeId !== Db.Vip.SourceType.RASTER) {
      this._loadingError = `Layer is not a Raster layer.`
    }

    this.LoadAsync(source)
  }

  protected async LoadAsync(source: IRWorkspaceLayer) {
    try {
      this._sources = await this._sourceApi.RasterSources().get().run()
      if (!this._sources.length) throw new AppError(`Raster source(s) is missing.`)
      if (this.preset && !this.preset.includes('static')) {
        const source = this._sources
        const upload: IPRasterPresetSources = {
          valid_from: this._renderedTimeSeriesSelection?.date_range.from,
          valid_to: this._renderedTimeSeriesSelection?.date_range.to,
          layer_preset: this.preset,
          workspace: source[0].geoserver_meta?.workspace,
          emission_type: source[0].geoserver_meta?.layer,
          emission_source: source[0].geoserver_meta?.data_folder,
          wms_version: source[0].wms_version
        }
        if (this._workspaceService.isEmissions() && this.preset) {
          this._sources = await this._api.orm.Products().Emissions().getPresetRasterSources(upload).run()
          if (this.isTimeSeries) {
            this.pollVideoJobStatus(this.checkVideoCreationJobStatus.bind(this))
          }
        }
      }
      this.sources.forEach(s => {
        this.zoomRange = s.geoserver_meta && s.geoserver_meta.zoom_range
      })

      const layer = await this.RenderLayer(source)
      if (this.layer) {
        this.layer.setSource(layer.getSource() as any)
      } else {
        this.layer = layer
      }
    } catch (error: any) {
      this._loadingError = error.message
    }

    this._loaded = true
  }

  private async GetSource(src?: Db.Vip.Geo.IRasterSource) {
    this._renderedSource = src
    let source: olSource.Tile | olSource.XYZ | olSource.TileWMS
    if (!src) {
      source = new olSource.TileWMS({
        params: {},
        tileLoadFunction: (tile: any, src) => {
          tile.getImage().src = this._emptyTile
        }
      })
      this._sourceChanged.next(undefined)
      return source
    }

    if (src.base_url.match(NewLayerUtil.knownLayers.noaa.wmts.regex)) {
      if (!src.tile_order) {
        throw new AppError('Url parameters missing.')
      }

      source = await this.NewTileSource(
        src.base_url,
        src.tile_order
      )
    } else if (
      src.base_url.match(NewLayerUtil.knownLayers.geoserver.wms.regex)
    ) {
      if (
        !src.wms_version ||
        !src.geoserver_meta ||
        !src.geoserver_meta.layer
      ) {
        throw new AppError('Url parameters missing.')
      }

      let styleName = src.geoserver_meta.style ? src.geoserver_meta.style : 'raster'

      if (
        this._style &&
        this._style.rasterGradient &&
        this._style.rasterGradient.active &&
        !src.geoserver_meta.style
      ) {
        styleName = `${this.workspaceId}_${this._viewId}_${styleName}`
      } else if (src.geoserver_meta.style) {
        styleName = src.geoserver_meta.style
      }

      source = await this.NewGeoserverWmsSource(
        src.base_url,
        src.geoserver_meta.layer,
        src.wms_version,
        styleName,
        this.id
      )
    } else if (
      src.base_url.match(NewLayerUtil.knownLayers.arcgis.tiles.regex)
    ) {
      if (!src.tile_order) {
        throw new AppError('Url parameters missing.')
      }

      source = await this.NewTileSource(
        src.base_url,
        src.tile_order,
        '/tile'
      )
    } else if (src.base_url.match(NewLayerUtil.knownLayers.external_wms.wms.regex)) {
      if (
        !src.wms_version ||
        !src.geoserver_meta ||
        !src.geoserver_meta.layer
      ) {
        throw new AppError('Url parameters missing.')
      }
      source = await this.NewExternalWmsSource(
        src.base_url,
        src.geoserver_meta?.layer,
        src.geoserver_meta?.api_key
      )
    } else {
      throw new AppError('Unimplemented layer location.')
    }

    this._sourceChanged.next(this._renderedSource)
    return source
  }

  protected async RenderLayer(layer: IRWorkspaceLayer): Promise<ImplementedLayer> {
    const mapLayer = new TileLayer({
      visible: this.visible
    })

    if (this.zoomRange) {
      if (this.zoomRange.min_zoom) mapLayer.setProperties({ minZoom: this.zoomRange.min_zoom })
      if (this.zoomRange.max_zoom) mapLayer.setProperties({ maxZoom: this.zoomRange.max_zoom })
    }

    mapLayer.setProperties({
      sourceId: layer.layer_id,
      name: layer.name,
      // NOTE: don't rename to 'extent', as that will stop layers from loading
      extentDetails: layer.extent,
      interactive: true
    })

    if (this.isTimeSeries) {
      mapLayer.setSource(await this.GetSource(this.GetFocalSource()))
    } else {
      mapLayer.setSource(await this.GetSource(this._sources && this._sources[0]))
    }

    return mapLayer
  }

  protected async NewGeoserverWmsSource(
    url: string,
    layerName: string,
    wmsVersion: Db.Helper.Geo.WmsVersion,
    styleName: string,
    sourceId: string
  ) {

    const match: RegExpMatchArray | null = url.match(
      NewLayerUtil.knownLayers.geoserver.wms.regex
    )
    if (!match) {
      throw new AppError('Geoserver WMS URL invalid.')
    }

    const workspace = match[1]

    if (this.preset && this._workspaceService.isEmissions()) {
      return new olSource.TileWMS({
        tileLoadFunction: async (tile, src) => {
          (tile as any).getImage().src = `${environment.VIPServer}` +
            `${this._api.orm.ProxyEmissions(
              this._workspaceService.proxyAppendWsQuery(
                Base64.encodeURI(this._api.appendJwtQuery(`${src}&`)),
                this.id
              )
            ).endpoint}`
        },
        url: url,
        params: {
          LAYERS: `${workspace}:${layerName}`,
          TILED: true,
          VERSION: wmsVersion,
          STYLES: styleName,
          SOURCE_ID: sourceId
        },
        serverType: 'geoserver',
        projection: 'string',
        crossOrigin: 'anonymous'
      })
    } else {
      return new olSource.TileWMS({
        tileLoadFunction: async (tile, src) => {
          (tile as any).getImage().src = `${environment.VIPServer}` +
            `${this._api.orm.Proxy(
              this._workspaceService.proxyAppendWsQuery(
                Base64.encodeURI(this._api.appendJwtQuery(`${src}&`)),
                this.id
              )
            ).endpoint}`
        },
        url: url,
        params: {
          LAYERS: `${workspace}:${layerName}`,
          TILED: true,
          VERSION: wmsVersion,
          STYLES: styleName,
          SOURCE_ID: sourceId
        },
        serverType: 'geoserver',
        projection: 'string',
        crossOrigin: 'anonymous'
      })
    }
  }

  protected async NewExternalWmsSource(
    url: string,
    layerName: string,
    apiKey?: string
  ) {
    const match: RegExpMatchArray | null = url.match(
      NewLayerUtil.knownLayers.external_wms.wms.regex
    )
    if (!match) {
      throw new AppError('External WMS URL invalid.')
    }

    const tileSourceOptions: Options = {
      url: url,
      params: { 'LAYERS': layerName, 'TILED': true },
      tileLoadFunction: async (tile, src) => {
        (tile as any).getImage().src = `${environment.VIPServer}` +
          `${this._api.orm.Proxy(
            this._workspaceService.proxyAppendWsQuery(
              Base64.encodeURI(this._api.appendJwtQuery(`${src}&`)),
              this.id
            )
          ).endpoint}`
      },
      serverType: 'geoserver' as ServerType,
      crossOrigin: 'anonymous'
    }
    if (apiKey) {
      tileSourceOptions.params['api_key'] = apiKey
    }
    return new olSource.TileWMS(tileSourceOptions)
  }

  protected async NewTileSource(url: string, tileOrder: string, prefix?: '/tile') {
    const order = tileOrder.split('')
    const tileUrl = `${url}${prefix || ''}/{${order[0]}}/{${order[1]}}/{${order[2]
      }}`

    return new olSource.XYZ({
      url: tileUrl,
      crossOrigin: 'anonymous'
    })
  }

  async getLayerLegend(): Promise<any> {
    let png: any
    const url = this.sources[0].base_url
    const layerName = this.sources[0].geoserver_meta?.layer
    const wms_version = this.sources[0].wms_version
    if (layerName && wms_version) {
      png = await this.GetRasterLegend(url, layerName, wms_version)
    }
    return png
  }

  async getLayerExtent(): Promise<olExtent.Extent> {
    let extent
    if (this.extent && !this.vector) {
      extent = this.extent.extent
      if (this.extent.projection !== 'EPSG:3857') {
        extent = olProj.transformExtent(
          extent,
          this.extent.projection || 'EPSG:4326',
          'EPSG:3857'
        )
      }
      return extent
    }

    // TODO: Zoom to full extent or just rendered one?
    const source = this._renderedSource || this._sources[0]
    if (!this._renderedSource) {
      throw new AppError('Cannot get extent from undefined source.')
    }

    if (this._renderedSource.base_url.match(NewLayerUtil.knownLayers.noaa.wmts.regex)) {
      if (!source.wms_capabilities_url) {
        throw new AppError(
          'Cannot get extent from undefined wmts capabilities url.'
        )
      }
      extent = await this.GetNoaaExtent(
        source.base_url,
        source.wms_capabilities_url
      )
    } else if (source.base_url.match(NewLayerUtil.knownLayers.geoserver.wms.regex)) {
      if (!source.geoserver_meta || !source.wms_version) {
        throw new AppError(`Geoserver metadata missing for geoserver raster.`)
      }
      extent = await this.GetGeoserverWmsExtent(
        source.base_url,
        source.geoserver_meta.layer,
        source.wms_version
      )
    } else if (source.base_url.match(NewLayerUtil.knownLayers.arcgis.tiles.regex)) {
      extent = await this.GetArcgisTilesExtent(source.base_url)
    } else {
      throw new AppError('Unimplemented source location.')
    }

    if (!LayerUtil.extentValid(extent)) throw new AppError('Layer extent is invalid.')

    // TODO: For timeseries only save extent when joint extent is calculated?
    // Or use only visible extent?
    if (!this.extent) {
      this.updateLayerExtent({ extent, projection: 'EPSG:3857' })
    }

    return extent
  }

  protected async GetNoaaExtent(
    url: string,
    capabilitiesUrl: string
  ): Promise<olExtent.Extent> {
    let match: RegExpMatchArray | null = capabilitiesUrl.match(
      NewLayerUtil.knownLayers.noaa.wmts.capabilitiesRegex
    )
    if (!match) {
      throw new AppError('Noaa capabilities URL invalid.')
    }
    match = url.match(NewLayerUtil.knownLayers.noaa.wmts.regex)
    if (!match) {
      throw new AppError('Noaa layer URL invalid.')
    }

    const layerName = match[1]
    const xmlText = await this._api.orm.Proxy(Base64.encodeURI(capabilitiesUrl)).get()
      .run({ responseType: 'text' })

    return this.GetWms100Extent(xmlText, layerName)
  }

  protected async GetGeoserverWmsExtent(
    url: string,
    layerName: string,
    wmsVersion: Db.Helper.Geo.WmsVersion
  ): Promise<olExtent.Extent> {
    const match: RegExpMatchArray | null = url.match(
      NewLayerUtil.knownLayers.geoserver.wms.regex
    )
    if (!match) {
      throw new AppError('Geoserver WMS URL invalid.')
    }
    const encodedTimeSeriesName = layerName.replace(/:/g, '%3A')
    const workspace = match[1]
    const wmsCapabilitiesUrl =
      `${url}?SERVICE=WMS&&VERSION=${wmsVersion}` +
      `&REQUEST=GetCapabilities` +
      `&LAYERS=${workspace}%3A${encodedTimeSeriesName}`

    let xmlText

    if (this.preset && this._workspaceService.isEmissions()) {
      xmlText = await this._api.orm.ProxyEmissions(
        this._workspaceService.proxyAppendWsQuery(
          Base64.encodeURI(this._api.appendJwtQuery(`${wmsCapabilitiesUrl}&`)),
          this.id
        )
      ).get().run({ responseType: 'text' })
    } else {
      xmlText = await this._api.orm.Proxy(
        this._workspaceService.proxyAppendWsQuery(
          Base64.encodeURI(this._api.appendJwtQuery(`${wmsCapabilitiesUrl}&`)),
          this.id
        )
      ).get().run({ responseType: 'text' })
    }

    switch (wmsVersion) {
      case '1.0.0':
        return this.GetWms100Extent(xmlText, layerName)
      case '1.1.0':
      case '1.1.1':
        return this.GetWms110Extent(xmlText)
      case '1.3.0':
        return this.GetWms130Extent(xmlText, layerName)
    }
  }

  protected async GetArcgisTilesExtent(url: string): Promise<olExtent.Extent> {
    const match: RegExpMatchArray | null = url.match(
      NewLayerUtil.knownLayers.arcgis.tiles.regex
    )
    if (!match) {
      throw new AppError('Arcgis URL invalid.')
    }

    let extent: olExtent.Extent | undefined
    const arcgisLayerInfo = `${url}/layers?f=pjson`
    const jsonResp = await this._api.orm.Proxy(
      Base64.encodeURI(arcgisLayerInfo)
    ).get().run()

    if (jsonResp.layers) {
      for (const jsonLayer of jsonResp.layers) {
        const newExtent: [number, number, number, number] = [
          jsonLayer.extent.xmin,
          jsonLayer.extent.ymin,
          jsonLayer.extent.xmax,
          jsonLayer.extent.ymax
        ]
        if (extent) {
          extent = olExtent.extend(extent, newExtent)
        } else {
          extent = newExtent
        }
      }
    }
    return extent as olExtent.Extent
  }

  // More on WMS
  // http://enterprise.arcgis.com/en/server/latest/publish-services/windows/communicating-with-a-wms-service-in-a-web-browser.htm
  protected async GetWms100Extent(xml: any, layerName: string): Promise<olExtent.Extent> {
    const xmlAsJson: any = await ConvertUtil.xmlToJson(xml)
    let extent: olExtent.Extent | undefined

    const matchingLayers = xmlAsJson.Capabilities.Contents[0].Layer.filter(
      (xmlLayer: any) => {
        const wmsLayerName = xmlLayer['ows:Identifier'][0]
        return wmsLayerName.toLowerCase() === layerName.toLowerCase()
      }
    )

    if (matchingLayers.length === 0) {
      throw new AppError(
        `Layer ${layerName} not found in WMS capabilities (v1.0.0).`
      )
    }

    for (const matchingLayer of matchingLayers) {
      const crs: any = matchingLayer['ows:WGS84BoundingBox'][0]
      const lowerCorner = (crs['ows:LowerCorner'][0] as string).split(' ')
      const upperCorner = (crs['ows:UpperCorner'][0] as string).split(' ')

      const xmlLayerExtent: olExtent.Extent = [
        +lowerCorner[0],
        +lowerCorner[1],
        +upperCorner[0],
        +upperCorner[1]
      ]

      const translatedExtent = olExtent.applyTransform(
        xmlLayerExtent,
        olProj.getTransform('EPSG:4326', 'EPSG:3857')
      )

      if (extent) {
        extent = olExtent.extend(extent, translatedExtent)
      } else {
        extent = translatedExtent
      }
    }
    return extent as olExtent.Extent
  }

  protected async GetWms110Extent(xml: any) {
    const xmlAsJson: any = await ConvertUtil.xmlToJson(xml)
    const xmlLayer =
      xmlAsJson.WMT_MS_Capabilities.Capability[0].Layer[0].Layer[0]

    const boundingBox = xmlLayer.BoundingBox[0].$

    if (boundingBox.SRS === 'EPSG:4326') {
      const boundingExtent: olExtent.Extent = [
        +boundingBox.minx,
        +boundingBox.miny,
        +boundingBox.maxx,
        +boundingBox.maxy
      ]

      return olExtent.applyTransform(
        boundingExtent,
        olProj.getTransform('EPSG:4326', 'EPSG:3857')
      )
    } else {
      throw new AppError('Unimplemented projection for capabilities extent.')
    }
  }

  protected async GetWms130Extent(xml: any, layerName: string) {
    const xmlAsJson: any = await ConvertUtil.xmlToJson(xml)
    const layersArray = xmlAsJson.WMS_Capabilities.Capability[0].Layer[0].Layer

    const matchingLayer = layersArray.filter(
      (x: any) => x.Title[0] === layerName
    )

    if (matchingLayer.length === 0) {
      throw new AppError(
        `Layer ${layerName} not found in WMS capabilities (v1.3.0).`
      )
    }

    const boundingBox = matchingLayer[0].BoundingBox[1].$

    if (boundingBox.CRS === 'EPSG:4326') {
      const boundingExtent: olExtent.Extent = [
        +boundingBox.miny,
        +boundingBox.minx,
        +boundingBox.maxy,
        +boundingBox.maxx
      ]

      return olExtent.applyTransform(
        boundingExtent,
        olProj.getTransform('EPSG:4326', 'EPSG:3857')
      )
    } else {
      throw new AppError('Unimplemented projection for capabilities extent.')
    }
  }

  async setRenderedTimeSeries(timeSeries: Db.Helper.Geo.Timeseries) {
    const prevRendered = this._renderedTimeSeriesSelection
    const prevRange = prevRendered && prevRendered.date_range

    this._renderedTimeSeriesSelection = cloneDeep(timeSeries)
    const renderedRange = this._renderedTimeSeriesSelection.date_range

    let rangeChanged = false
    let focalChanged = false
    let focalAccChange = false

    if (!prevRange) {
      rangeChanged = focalChanged = focalAccChange = true
    } else {
      focalChanged = renderedRange.focal !== prevRange.focal
      focalAccChange = !isEqual(prevRendered && prevRendered.focal_accuracy, timeSeries.focal_accuracy)
      rangeChanged = renderedRange.from !== prevRange.from || renderedRange.to !== prevRange.to
    }

    if (rangeChanged && prevRange) {
      // NOTE: As raster layers don't fetch much data, just few rows of data entries, we can fetch
      // them all, if this ever causes performance issues, we can pull only rows that match time range.
      // Date range matching entries will also need to be fetched if and when we will have any 'comparison'/'change'
      // data for rasters, as currently we don't have any
      // only update raster sources ever 10 mins and not ever min to save calls to the api when set to real time
      const toRangeDiff = Math.abs(moment(renderedRange.to).diff(moment(this._lastUpdatedSourceRange?.to), 'minutes'))
      const fromRangeDiff = Math.abs(moment(renderedRange.from).diff(moment(this._lastUpdatedSourceRange?.from), 'minutes'))
      if (this.preset && (toRangeDiff >= 10 || fromRangeDiff >= 10)) {
        try {
          this._sources = await this._sourceApi.RasterSources().get().run()
          if (!this._sources.length) throw new AppError(`Raster source(s) is missing.`)
          const source = this._sources
          const upload: IPRasterPresetSources = {
            valid_from: this._renderedTimeSeriesSelection?.date_range.from,
            valid_to: this._renderedTimeSeriesSelection?.date_range.to,
            layer_preset: this.preset,
            workspace: source[0].geoserver_meta?.workspace,
            emission_type: source[0].geoserver_meta?.layer,
            emission_source: source[0].geoserver_meta?.data_folder,
            wms_version: source[0].wms_version
          }
          if (this._workspaceService.isEmissions()) {
            this._sources = await this._api.orm.Products().Emissions().getPresetRasterSources(upload).run()
          }
        } catch (error: any) {
          throw new AppError(`Failed to update raster sources`)
        }
        this._lastUpdatedSourceRange = this._renderedTimeSeriesSelection.date_range
        this._rangeSourcesUpdated.next()
      }
      this.layer && this.layer.setSource(await this.GetSource(this.GetFocalSource()))
      this.syncStyle(true)
    } else if (focalChanged || focalAccChange) {
      this.layer && this.layer.setSource(await this.GetSource(this.GetFocalSource()))
      this.syncStyle(true)
    }

    this.CheckTimeSeriesState()
  }

  protected async GetRasterLegend(
    url: string,
    layerName: string,
    wmsVersion: Db.Helper.Geo.WmsVersion,
    legendOpts?: { width: string, height: string }
  ): Promise<Blob> {
    const match: RegExpMatchArray | null = url.match(
      NewLayerUtil.knownLayers.geoserver.wms.regex
    )
    if (!match) {
      throw new AppError('Geoserver WMS URL invalid.')
    }

    const encodedTimeSeriesName = layerName.replace(/:/g, '%3A')
    const workspace = match[1]
    const GetLegendGraphic =
      `${url}?REQUEST=GetLegendGraphic&VERSION=${wmsVersion}` +
      `&FORMAT=image/png&WIDTH=20&HEIGHT=20` +
      `&LAYER=${workspace}%3A${encodedTimeSeriesName}&LEGEND_OPTIONS` +
      `=fontName:SansSerif;fontSize:12;bgColor:%23282828;fontColor:%23FFFFFF`

    let png

    if (this.preset && this._workspaceService.isEmissions()) {
      png = await this._api.orm.ProxyEmissions(
        this._workspaceService.proxyAppendWsQuery(
          Base64.encodeURI(this._api.appendJwtQuery(`${GetLegendGraphic}&`)),
          this.id
        )
      ).get().run({ responseType: 'blob' })
    } else {
      png = await this._api.orm.Proxy(
        this._workspaceService.proxyAppendWsQuery(
          Base64.encodeURI(this._api.appendJwtQuery(`${GetLegendGraphic}&`)),
          this.id
        )
      ).get().run({ responseType: 'blob' })
    }

    return png
  }

  private GetFocalSource(): Db.Vip.Geo.IRasterSource | undefined {
    if (!this._renderedTimeSeriesSelection || !this._sources) return

    const focal = moment(this._renderedTimeSeriesSelection.date_range.focal)

    const minFocal = moment(focal)
    const maxFocal = moment(focal)

    const focalAccPeriod = this._renderedTimeSeriesSelection.focal_accuracy
    if (focalAccPeriod) {
      minFocal.subtract(focalAccPeriod.value, focalAccPeriod.interval)
      maxFocal.add(focalAccPeriod.value, focalAccPeriod.interval)
    } else {
      minFocal.subtract(15, 'm')
      maxFocal.add(15, 'm')
    }

    let nearestBefore: {
      date: moment.Moment
      distance: number
      s: Db.Vip.Geo.IRasterSource
    } | undefined

    let nearestAfter: {
      date: moment.Moment
      distance: number
      s: Db.Vip.Geo.IRasterSource
    } | undefined

    const setDate = (date: moment.Moment, s: Db.Vip.Geo.IRasterSource) => {
      const distance = date.diff(focal)
      if (distance === 0) {
        nearestBefore = { date, distance, s }
        nearestAfter = { date, distance, s }
      } else if (distance > 0 && (!nearestAfter || distance < nearestAfter.distance)) {
        nearestAfter = { date, distance, s }
      } else if (distance < 0 && (!nearestBefore || distance > nearestBefore.distance)) {
        nearestBefore = { date, distance, s }
      }
      return distance
    }

    // TODO: Move to util class, as this implementation appears in other layer files too
    const processArray = (sources: Db.Vip.Geo.IRasterSource[]) => {
      // Need to sort sources by date to chunk correctly
      sources.sort((a, b) => {
        if (a.date_time && b.date_time) {
          if (a.date_time < b.date_time) {
            return -1
          }
          if (a.date_time > b.date_time) {
            return 1
          }
        }
        return 0
      })

      const [firstHalf, secondHalf] = CommonUtil.arrayIntoChunks(sources, 2)
      const firstSource = firstHalf[firstHalf.length - 1]
      const secondSource = secondHalf[0]

      const firstDate = firstSource && firstSource.date_time && moment(firstSource.date_time)
      const secondDate = secondSource && secondSource.date_time && moment(secondSource.date_time)

      if (!firstDate && !secondDate) {
        return
      } else if (!firstDate) {
        if (secondHalf.length === 1) {
          setDate(secondDate as moment.Moment, secondSource)
          return
        }
        return processArray(secondHalf)
      } else if (!secondDate) {
        if (firstHalf.length === 1) {
          setDate(firstDate, firstSource)
          return
        }
        return processArray(firstHalf)
      } else if (firstHalf.length === 1 && secondHalf.length === 1) {
        setDate(firstDate, firstSource)
        setDate(secondDate, secondSource)
      }

      // For timeseries with active range per row - focal will match nearest start in change period
      const firstDiff = firstDate.diff(focal)
      const secondDiff = secondDate.diff(focal)

      const sameSign = CommonUtil.sameSign(firstDiff, secondDiff)
      if (sameSign) {
        // If positive
        if (firstDiff >= 0) {
          if (firstDiff < secondDiff) {
            return processArray(firstHalf)
          } else if (secondDiff < firstDiff) {
            return processArray(secondHalf)
          }
        } else {
          if (firstDiff > secondDiff) {
            return processArray(firstHalf)
          } else if (secondDiff > firstDiff) {
            return processArray(secondHalf)
          }
        }

        if (focal.isBefore(firstDate)) {
          return processArray(firstHalf)
        } else if (focal.isAfter(secondDate)) {
          return processArray(secondHalf)
        } else {
          setDate(firstDate, firstSource)
          setDate(secondDate, secondSource)
          return
        }
      }

      // NOTE: Can improve by checking if one of array had lower 'edge' distance, then
      // wont need to iterate 2 arrays
      processArray(firstHalf)
      processArray(secondHalf)
    }

    processArray(this._sources)

    const beforeInRange = !!nearestBefore && nearestBefore.date.isSameOrAfter(minFocal) &&
      nearestBefore.date.isSameOrBefore(maxFocal)

    const afterInRange = !!nearestAfter && nearestAfter.date.isSameOrAfter(minFocal) &&
      nearestAfter.date.isSameOrBefore(maxFocal)

    if (nearestBefore && beforeInRange && !afterInRange) {
      return nearestBefore.s
    } else if (nearestAfter && afterInRange && !beforeInRange) {
      return nearestAfter.s
    } else if (!afterInRange && !beforeInRange) {
      return
    }

    const beforeIsSame = nearestBefore && nearestBefore.date.isSame(focal, 'day')
    const afterIsSame = nearestAfter && nearestAfter.date.isSame(focal, 'day')

    if (nearestAfter && nearestBefore && beforeIsSame && afterIsSame) {
      return nearestAfter.distance <= nearestBefore.distance ? nearestAfter.s : nearestBefore.s
    } else if (nearestAfter && afterIsSame) {
      return nearestAfter.s
    } else if (nearestBefore && beforeIsSame) {
      return nearestBefore.s
    }
  }

  private async SaveGeoserverStyle(style: string) {
    let sourceMetaData: IPNewRasterSource = {}
    if (this._isTimeSeries) {
      this._sources.map(async (src) => {
        if (src.geoserver_meta) {
          sourceMetaData = {}
          let metaData = src.geoserver_meta
          if (metaData) metaData.style = style
          sourceMetaData.raster_source_id = src.raster_source_id
          sourceMetaData.geoserver_meta = metaData
          await this._sourceApi.RasterSources().geoServerMeta().update(sourceMetaData).run()
        }
      })
    } else {
      let metaData = this._sources[0].geoserver_meta
      if (metaData) metaData.style = style
      sourceMetaData.raster_source_id = this._sources[0].raster_source_id
      sourceMetaData.geoserver_meta = metaData
      await this._sourceApi.RasterSources().geoServerMeta().update(sourceMetaData).run()
    }
    setTimeout(() => this.refreshRasterLayerStyle(), 200)
  }

  async getPixelInfo(coordinates: Coordinate, resolution: number) {
    const source: any = (this.layer as any).getSource()
    const url: string = source.getFeatureInfoUrl(
      coordinates,
      resolution,
      'EPSG:4326',
      { 'INFO_FORMAT': 'application/json' }
    )

    let json = undefined

    if (this.preset && this._workspaceService.isEmissions()) {
      json = await this._api.orm.ProxyEmissions(
        this._workspaceService.proxyAppendWsQuery(
          Base64.encodeURI(this._api.appendJwtQuery(`${url}&`)),
          this.id
        )
      ).get().run({ responseType: 'text' })
    } else {
      json = await this._api.orm.Proxy(
        this._workspaceService.proxyAppendWsQuery(
          Base64.encodeURI(this._api.appendJwtQuery(`${url}&`)),
          this.id
        )
      ).get().run({ responseType: 'text' })
    }
    const data = json ? JSON.parse(json).features[0].properties.GRAY_INDEX : undefined
    return data
  }

  async getPixelTimeSeries(coordinates: Coordinate, resolution: number, geoServerlayers: string[]): Promise<string | undefined> {
    const source: any = (this.layer as any).getSource()
    const url: string = source.getFeatureInfoUrl(
      coordinates,
      resolution,
      'EPSG:4326',
      {
        'INFO_FORMAT': 'application/vnd.ogc.gml/3.1.1',
        'QUERY_LAYERS': geoServerlayers,
        'LAYERS': geoServerlayers,
        'FEATURE_COUNT': this._sources.length,
        'STYLES': null,
        'SOUCE_ID': null
      }
    )
    return this._api.orm.Proxy(
      this._workspaceService.proxyAppendWsQuery(
        Base64.encodeURI(this._api.appendJwtQuery(`${url}&`)),
        this.id
      )
    ).get().run({ responseType: 'text' })
  }

  async getPixelData(pixelCoords: Coordinate, resolution: number): Promise<Array<{
    label?: string
    value?: number
  }> | undefined> {
    const sourcesArr = this._sources
    const pixelItemArray: any = []
    const geoServerNames: any[] = this._sources.map((src) => {
      if (src.geoserver_meta) {
        return src.geoserver_meta.workspace + ':' + src.geoserver_meta.layer
      }
    })

    // Chunk geoserver layers
    let temparray: any[] = []
    const chunk: number = 10

    for (let i = 0; i < geoServerNames.length; i += chunk) {
      temparray = geoServerNames.slice(i, i + chunk)
      const gml = await this.getPixelTimeSeries(pixelCoords, resolution, temparray)
      if (!gml) continue

      const xmlAsJson: any = await ConvertUtil.xmlToJson(gml)

      const json = xmlAsJson['wfs:FeatureCollection']['gml:featureMembers'][0]

      if (json instanceof Object) {
        Object.keys(json).forEach(function (key) {
          let pixelValue: { label?: string, value?: number } = {}
          let value: string
          const source = sourcesArr.find((src) => {
            if (src.geoserver_meta) {
              const layerName = `${src.geoserver_meta.workspace}:${src.geoserver_meta.layer}`
              if (layerName === key) {
                return true
              }
            }
          })
          if (source && source.geoserver_meta) {
            value = json[key][0][`${source.geoserver_meta.workspace}:GRAY_INDEX`][0]
            pixelValue.label = source.date_time
            pixelValue.value = isNaN(parseFloat(value)) ? 0 : (Math.round(parseFloat(value) * 100) / 100)
            if (pixelValue.value !== 0) pixelItemArray.push(pixelValue)
          }
        })
      }
    }

    if (!pixelItemArray.length) return
    return pixelItemArray
  }

  setSourceStyle(style: string) {
    this._sources.map((src) => {
      if (src.geoserver_meta) src.geoserver_meta.style = style
    })
    this.SaveGeoserverStyle(style)
  }

  private ClearTileCache(source: any) {
    source.tileCache.clear()
  }

  refreshRasterLayerStyle() {
    if (this.layer && this.geoserverMeta) {
      this.ClearTileCache((this.layer as any).getSource())
      let styleName = `${this._workspaceService.workspaceId}_${this._workspaceService.workspaceId}_${this.id}`
      if (this.style.rasterGradient) {
        if (this.style.rasterGradient.active) {
          (this.layer as any).getSource().updateParams({ STYLES: styleName })
        } else {
          let defaultStyle = 'raster'
          if (this.geoserverMeta.style) {
            defaultStyle = this.geoserverMeta.style
          }
          (this.layer as any).getSource().updateParams({ STYLES: defaultStyle })
        }
      }

      (this.layer as any).getSource().updateParams({ time: Date.now() })
    }
  }

  async waitForLoad() {
    let timer = 0
    while (!this._loaded) {
      // Wait for two mins then break
      if (timer === 120000) break
      await CommonUtil.delay(100)
      timer += 100
    }
  }

  async getRasterChartData(upload: IPRasterChartAPI): Promise<Db.Helper.Prj.RasterDataObj> {
    try {
      const dbSource = await this._sourceApi.RasterSources().get().run()
      if (!dbSource.length) throw new AppError(`Raster source(s) is missing.`)
      const data = await this._api.orm.Products().Emissions().getChart(upload).run() as Db.Helper.Prj.RasterDataObj
      return data
    } catch (error: any) {
      let errorMsg = typeof error.message === 'string' ? error.message : `Failed to retrieve chart`
      throw new AppError(errorMsg)
    }
  }

  async downloadRasterEmissionTimeSeriesChart(upload: IPRasterChartAPI): Promise<void> {
    try {
      const dbSource = await this._sourceApi.RasterSources().get().run()
      if (!dbSource.length) throw new AppError(`Raster source(s) is missing.`)

      const chartpng = await this._api.orm.Products().Emissions().getChartPng(upload).run()
      CommonUtil.downloadBlob(chartpng, `${dbSource[0].geoserver_meta?.workspace}_.png`, 'png')
    } catch (error: any) {
      throw new AppError(`Failed to retrieve chart`)
    }
  }

  async getLayerPreset(preset: string): Promise<Db.Vip.Geo.ILayerPreset | undefined> {
    if (!this.layer && !this.sources) return
    let presetObj: Db.Vip.Geo.ILayerPreset | undefined

    const getPreset = async (): Promise<Db.Vip.Geo.ILayerPreset | undefined> => {
      const presetRow = presets.find(x => x.layer_preset_tag === preset)
      return presetRow
    }

    const presets = await this._api.orm.Products().Product(this._workspaceService.product as number)
      .LayerPresets().get().run()

    if (presets.length) {
      presetObj = await getPreset()
    }
    return presetObj
  }

  async createVideoAnimationJob(dateFrom: string, dateTo: string): Promise<void> {
    if (this.preset) {
      const url =this.sources[0].base_url
      const match: RegExpMatchArray | null = url.match(
        NewLayerUtil.knownLayers.geoserver.wms.regex
      )
      if (!match) {
        throw new AppError('Geoserver WMS URL invalid.')
      }
      const workspace = match[1]
      if (workspace) {
        try {
          const layerName = `${workspace}:${this.sources[0].geoserver_meta?.layer}`
          const videoParams = {} as Db.Helper.Emissions.VideoParameters
          const job = {} as IVideoAnimation
          job.layer_id = this.id
          job.workspace_id = this.workspaceId
          videoParams.from = dateFrom
          videoParams.to = dateTo
          videoParams.layer_name = layerName
          job.video_parameters = videoParams
          const row = await this._api.orm.Products().Emissions().createVideoJob(job).run() as Db.Vip.Emissions.IJob
          if(row){
            this.pollVideoJobStatus(this.checkVideoCreationJobStatus.bind(this))
          }
        } catch {
          throw new AppError(`Failed to create video job`)
        }
      }
    }
  }

  async pollVideoJobStatus(checkFunction, maxTime = 600000, interval = 10000) {
    const startTime = Date.now()
    let elapsedTime = 0

    while (elapsedTime < maxTime) {
      try {
        await checkFunction()
        if (!this._videoAnimationJob) {
          console.log('No response received. Stopping polling.')
          return
        }
        else if (
          this._videoAnimationJob &&
          this._videoAnimationJob.job_status === 'FINISHED'
        ) {
          console.log('Job finished successfully.')
          return
        } else if (
          this._videoAnimationJob &&
          this._videoAnimationJob.job_status === 'ERROR'
        ) {
          console.log('Job encountered an error.')
          return
        }
      } catch (error: any) {
        throw new AppError('Failed to fetch job status:', error.message)
      }
      await new Promise((resolve) => setTimeout(resolve, interval))
      elapsedTime = Date.now() - startTime
    }
    console.log('Max polling time exceeded. Stopping polling.')
  }

  

  async checkVideoCreationJobStatus(): Promise<void> {
    const layerId = parseInt(this.id)
    const workspaceId = this.workspaceId
    try {
      const response = await this._api.orm.Products().Emissions().getVideoJobStatus(layerId, workspaceId).run()
      if (response) {
        this._videoAnimationJob = response
      }
      return
    } catch (error) {
      throw new AppError(`Failed to fetch job status`)
    }
  }
}
