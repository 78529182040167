import Feature from 'ol/Feature'

export interface AppFeature extends Feature {
  // If set to true, fill colour will have NaN values for RGB,
  // it will be coloured black and excluded from style calculations
  // like 'average cluster fill colour'
  invalidateFill?: boolean
  // If set to true, border colour will have NaN values for RGB,
  // it will be coloured black and excluded from style calculations
  // like 'average cluster fill colour'
  invalidateBorder?: boolean

  zIndex?: number

  // Time series meta
  timeSeries?: {
    entity: string
    // Change of the group, every feature has copy of value
    change?: number
  }

  // Feature has been added by geometry toolset
  drawn?: boolean

  // Feature is rendered on map (does not identify if style is 'null' making it invisible)
  // this will always be true for default vectors. It can be false for time-series data
  rendered?: boolean
}

export class AppFeature extends Feature {}
