<h2 mat-dialog-title>Building properties</h2>

<div mat-dialog-content>
  <p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>

  <!-- If control '1' exists means there is more than one form, therefore render tab group -->
  <mat-tab-group *ngIf="form.controls['1']" (selectedTabChange)="updateMaps()">
    <mat-tab *ngFor="let indexKey of formKeys; let i = index" label="Geometry {{i + 1}} of {{formKeys.length}}">
      <ng-container
      [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{formControl: form.controls[indexKey]}">
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="!form.controls['1']"
  [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{formControl: form.controls['0']}">
  </ng-container>

  <ng-template #formTemplate let-formControl="formControl">
    <form [formGroup]="formControl" class="st-single-row-form" (rendered)="updateMaps()" *ngIf="formControl">
      <div class="wkt-field">
        <mat-form-field>
          <textarea matInput formControlName="wktPointFormControl" cols="40" rows="3" placeholder="WGS84 'Well Known Text (WKT)' point geometry"></textarea>
          <mat-error *ngIf="formControl.controls.wktPointFormControl.hasError('wktValid')">
            {{formControl.controls.wktPointFormControl['errors'] && formControl.controls.wktPointFormControl['errors']['wktValid']['message']}}
          </mat-error>
        </mat-form-field>

        <app-wkt-preview #preview [wkt]="formControl.controls.wktPointFormControl.value"
        [contextList]="[formControl.controls.wktPolygonFormControl.value]"></app-wkt-preview>
      </div>

      <div class="wkt-field">
        <mat-form-field>
          <textarea matInput formControlName="wktPolygonFormControl" cols="40" rows="4" placeholder="WGS84 'Well Known Text (WKT)' polygon geometry"></textarea>
          <mat-error *ngIf="formControl.controls.wktPolygonFormControl.hasError('wktValid')">
            {{formControl.controls.wktPolygonFormControl['errors'] && formControl.controls.wktPolygonFormControl['errors']['wktValid']['message']}}
          </mat-error>
        </mat-form-field>

        <app-wkt-preview #preview [wkt]="formControl.controls.wktPolygonFormControl.value"
        [contextList]="[formControl.controls.wktPointFormControl.value]"></app-wkt-preview>
      </div>

      </form>
  </ng-template>

</div>

<div class="st-button-row">
  <button color="primary" type="button" mat-button [mat-dialog-close] class="uppercase">
    cancel
  </button>
  <app-submit-button text="submit" [loading]="saving" [disabled]="!form.valid" (submit)="save()"></app-submit-button>
</div>
