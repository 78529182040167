import { AppLayer } from '@core/models/layer/app-layer'
import { Db } from '@vip-shared/models/db-definitions'
import { IQueryStatement, IQueryBlock } from '@vip-shared/interfaces/layer-query'
import { AppLayerGroup } from './layer'
import { MultiPolygon, Polygon } from 'ol/geom'

export class QueryStatement implements IQueryStatement {
  attribute: string
  operator: string
  value: string
  constructor (attribute: string, operator: string, value: string) {
    this.attribute = attribute
    this.operator = operator
    this.value = value
  }
}

export class QueryBlock implements IQueryBlock {
  operator: string
  statements: IQueryStatement[]
  constructor (operator?: string, statements?: IQueryStatement[]) {
    this.operator = operator || ''
    this.statements = statements || []
  }
  addStatement (statement: IQueryStatement): void {
    this.statements.push(statement)
  }
}

export class StatisticsQuery implements Db.Helper.Prj.StatisticQuery {
  operator: string
  attribute: string
  constructor (operator: string, attribute: string) {
    this.operator = operator || ''
    this.attribute = attribute || ''
  }
}

export class EnhancedQueryStatement implements Db.Helper.Prj.EnhancedQueryStatement {
  constructor (
    public operator: Db.Helper.Prj.EnhancedOperatorInstance,
    public attributeA: string = '',
    public attributeB: string | number = '',
    public attributeBType: 'value' | 'attribute' = 'attribute',
    public statementType: 'default' | 'value' | 'attribute' | 'statistic' = 'default',
    public operatorType: 'math' | 'comparator' = 'math',
    public createAttrColumn?: boolean,
    public attrColumnName?: string
    ) {
  }
}

export class EnhancedQueryContainer implements Db.Helper.Prj.EnhancedQueryContainer {
  constructor (
    public operators: Db.Helper.Prj.EnhancedOperatorInstance[] = [],
    public statements: (Db.Helper.Prj.EnhancedQueryStatement | Db.Helper.Prj.EnhancedQueryContainer)[] = [],
    public createAttrColumn?: boolean,
    public attrColumnName?: string,
    public caseColumnName?: string,
    public caseBlocks: {
      operators: Db.Helper.Prj.EnhancedOperatorInstance[],
      conditionStatements: Db.Helper.Prj.CaseConditionStatement[],
      block: Db.Helper.Prj.EnhancedQueryContainer
    }[] = []
    ) {
    this.statements = statements.map(statement => {
      if ('operators' in statement) {
        const { operators, statements, createAttrColumn, attrColumnName, caseColumnName, caseBlocks } = statement
        return new EnhancedQueryContainer(operators, statements, createAttrColumn, attrColumnName, caseColumnName, caseBlocks)
      } else {
        const { operator, attributeA, attributeB, attributeBType, statementType, operatorType, createAttrColumn, attrColumnName } = statement
        return new EnhancedQueryStatement(operator, attributeA, attributeB, attributeBType, statementType, operatorType, createAttrColumn, attrColumnName)
      }
    })

    if (caseBlocks.length) {
      this.caseBlocks = caseBlocks.map(caseBlock => {
        return {
          operators: caseBlock.operators,
          conditionStatements: caseBlock.conditionStatements,
          block: new EnhancedQueryContainer(caseBlock.block.operators, caseBlock.block.statements, caseBlock.block.createAttrColumn, caseBlock.block.attrColumnName, caseBlock.block.caseColumnName, caseBlock.block.caseBlocks)
        }
      })
    }
  }
}

export type IQueryWRef = Db.Vip.Prj.IQuery & {
  targetRef: AppLayer | AppLayerGroup
  query: Db.Helper.Prj.Query
  geometry?: Polygon | MultiPolygon
  index: number
}

export interface IQueryGroup {
  id: string
  name: string
  queries: IQueryWRef[]
}
