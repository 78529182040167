import { Injectable } from '@angular/core'
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs'

import { CommonUtil, ConvertUtil } from '@core/utils/index'
import { IZoom, ICoordinates, IFeatureKnownProperties, IFeatureProperties } from '@core/types'
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom'
import Map from 'ol/Map'
import View from 'ol/View'
import * as olProj from 'ol/proj'
import * as olSource from 'ol/source'
import * as olExtent from 'ol/extent'
import * as olEasing from 'ol/easing'
import * as olLayer from 'ol/layer'
import * as olGeom from 'ol/geom'
import * as olCoordinate from 'ol/coordinate'
import * as olStyle from 'ol/style'
import Geojson from 'ol/format/GeoJSON'
import BaseLayer from 'ol/layer/Base'
import { unByKey } from 'ol/Observable'
import * as olEvents from 'ol/events'
import { LayerService } from '../layer/layer.service'
import { AlertService } from '@services/core/alert/alert.service'
import { WorkspaceService } from '../workspace.service'
import { debounceTime, auditTime, distinctUntilChanged } from 'rxjs/operators'
import { IAttributeChangeEvent } from '@core/types/workspace/events'
import OlUtil from '@core/utils/ol/ol.util'
import AppError, { handleError } from '@core/models/app-error'
import { AppLayer } from '@core/models/layer/app-layer'
import { LayerStyleUtil, LayerUtil } from '@core/models/layer/utils'
import DevUtil from '@core/utils/dev/dev.util'
import { ContextMenuService } from '@services/core/context-menu/context-menu.service'
import { MapContextMenuEvent } from '@core/types/workspace/map/map-context-menu-event'
import GoogleUtil from '@core/utils/external/google/google.util'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import GeometryCollection from 'ol/geom/GeometryCollection'
import { WsCtxMenuConf, WsCtxMenuConfGroup, WsCtxMenuConfItem, WsCtxMenuConfOption, CtxUIMenu, CtxUIMenuItem } from '@core/types/workspace/map/ws-ctx-menu-conf'
import { ClickFilterFn } from '@core/types/workspace/map/click-filter-fn'
import { AppFeature } from '@core/models/layer/ol-extend/app-feature'
import { DashboardLayoutService } from '@services/dashboard/dashboard-layout.service'
import Overlay from 'ol/Overlay'
import { OverlayPositioning } from '@core/enum/ol/ol-overlay-positioning'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { AttributeChartComponent } from '@core/page-components/attribute-chart/attribute-chart.component'
import { FREDGaugeMeasureGeojsonProperties } from 'vip-shared/dist/models/layer-config/fred/fred-geojson-properties'
import { Db } from '@vip-shared/models/db-definitions'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { environment } from 'environments/environment.prod'

@Injectable()
export class WidgetMapService {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs(): any { return }
  availableBaseLayers = [
    {
      name: 'OSM (map)',
      icon: 'map'
    },
    {
      name: 'Bing (map)',
      icon: 'map',
      params: {
        bingType: 'road'
      }
    },
    {
      name: 'Bing (map - dark)',
      icon: 'map',
      params: {
        bingType: 'canvasDark'
      }
    },
    {
      name: 'Bing (satellite)',
      icon: 'terrain',
      params: {
        bingType: 'AerialWithLabels'
      }
    }
  ]

  // Note: the min and max must be inside bounds of resolution, going beyond bounds
  // will not have effect, min and max will be clipped to resolution
  readonly initZoom: IZoom = { default: 5, min: 2, max: 20, current: 5 }
  mapCenter: ICoordinates = { lon: -2, lat: 54.5 }
  private _zoom: IZoom = { ...this.initZoom }
  private _resetZoomDuration = 300
  private _defaultAnimationDuration = 200
  private _targetId!: string

  private _mapMouseMove = new Subject<any>()
  private _mapClicked = new Subject<{
    evt: MapBrowserEvent<any>
    layer?: BaseLayer
    appLayer?: AppLayer
    features?: AppFeature[]
    empty?: boolean
  }>()
  get mapClicked() {
    return this._mapClicked.asObservable()
  }

  private _drawFeaturesClicked = new Subject<AppFeature[]>()
  get drawFeaturesClicked() {
    return this._drawFeaturesClicked.asObservable()
  }

  private _selectedFeatures = new BehaviorSubject<AppFeature[]>([])
  get selectedFeatures() {
    return this._selectedFeatures.asObservable()
  }

  private _selectedSubstation = new BehaviorSubject<any>(null)
  private _selectedAttributesChange = new BehaviorSubject<IAttributeChangeEvent>({ attributes: [] })
  private _tileLayerBase = new olLayer.Tile({
    source: new olSource.OSM({
      crossOrigin: 'anonymous'
    })
  })
  private _highlightLayer = new olLayer.Tile({
    zIndex: 1000,
    source: new olSource.TileWMS()
  })

  private _map: Map
  private _popup?: Overlay

  private _zoomChanged = new Subject<number>()

  private _obliqueFootprint: olLayer.Vector<any> | undefined
  private _mostRecentlyClickedFeature?: AppFeature | null
  private _mapLoaded = new BehaviorSubject<boolean>(false)
  get mapLoaded(): Observable<boolean> {
    return this._mapLoaded.pipe(
      distinctUntilChanged((a, b) => a === b)
    )
  }

  private _mapLoadedDebounce?: NodeJS.Timer
  private _mapZoomSubscription?: Subscription
  private _overlayContent?: HTMLElement

  private readonly _viewFitDuration = 400

  contextMenuConfig: WsCtxMenuConf = []

  // Controlled in geometry-draw-service
  drawing = false

  // TODO: Add a more general property that stores current map 'mode'
  private _clickToCopyEnabled = false
  get clickToCopyEnabled() {
    return this._clickToCopyEnabled
  }
  set clickToCopyEnabled(val: boolean) {
    this._clickToCopyEnabled = val
    this.ChangeCursor(val ? 'crosshair' : 'unset')
  }

  private _clickFilter?: (e, options: {
    layer?: AppLayer
    feature?: AppFeature
  }) => boolean
  set clickFilter(cb: undefined | ClickFilterFn) {
    this._clickFilter = cb
    this._lastHoverFilterCheck = undefined
  }
  private _lastHoverFilterCheck?: AppFeature[]

  private _clickToMeasureEnabled = false
  get clickToMeasureEnabled() {
    return this._clickToMeasureEnabled
  }
  set clickToMeasureEnabled(val: boolean) {
    this._clickToMeasureEnabled = val
  }
  private _clickedForMeasure = new Subject<olGeom.Geometry>()
  get clickedForMeasure() {
    return this._clickedForMeasure.asObservable()
  }

  measurementInProgress = false

  get map(): Map {
    return this._map
  }

  get view(): View {
    return this._map.getView()
  }

  get resolution(): number {
    return this.view.getResolutionForZoom(this.zoom)
  }

  get extent() {
    return this.view.calculateExtent(this._map.getSize())
  }

  get zoomChanged(): Subject<number> {
    return this._zoomChanged
  }

  private _mapRightClicked = new Subject()
  get mapRightClicked() {
    return this._mapRightClicked.asObservable()
  }

  get selectedSubstation(): Observable<any> {
    return this._selectedSubstation.asObservable()
      .pipe(
        debounceTime(200)
      )
  }

  get selectedAttributesChange(): Observable<IAttributeChangeEvent> {
    return this._selectedAttributesChange.asObservable()
  }

  get mostRecentlyClickedFeature(): AppFeature | undefined | null {
    return this._mostRecentlyClickedFeature
  }

  private _lastMousePosition?: string
  get mousePositionOnMap() {
    return this._lastMousePosition || ''
  }

  private _viewportChanged = new Subject()
  get viewportChanged() {
    return this._viewportChanged.pipe(
      auditTime(500)
    )
  }

  get zoom(): number {
    return this.view.getZoom() as number
  }

  get center(): olCoordinate.Coordinate {
    return this.view.getCenter() as olCoordinate.Coordinate
  }

  get contextMenuActive(): boolean {
    return !this.drawing
  }

  constructor(private _alertService: AlertService,
    private _layerService: LayerService,
    private _workspaceService: WorkspaceService,
    private _contextMenu: ContextMenuService,
    private _dashboardLayoutService: DashboardLayoutService,
    private _dialog: MatDialog) {
    this._map = this.CreateMap()
    this.resetZoom()
    this._layerService.newLayer.subscribe(async layer => {
      if (layer && layer.layer instanceof BaseLayer) {
        await CommonUtil.delay(100)
        this.addMapLayer(layer.layer)
        this._map.setTarget(this._map.getTarget())
        // await this.centerLayer(layer)
      }
    })

    this._workspaceService.activeBaseMap
    .pipe(
      distinctUntilChanged((a, b) => a === b)
    )
    .subscribe(mapName => {
      this.changeBaseLayer(mapName)
    })

    this._workspaceService.onExit.subscribe(() => {
      this.cleanupService()
    })

    this._mapMouseMove.pipe(
      auditTime(100)
    ).subscribe(evt => this.OnMapMouseMove(evt))
  }

  cleanupService() {
    // Reset zoom center
    const coords = olProj.transform([0, 0], 'EPSG:4326', 'EPSG:3857')
    this.view.setCenter(coords)
    this.resetZoom()
    this.resetRotation()
    // TODO: clean up the rest of stuff: stylings, base map, layers etc
    const clearAttributes = () => {
      this._selectedAttributesChange.next({ attributes: [] })
    }
    clearAttributes()
    this.contextMenuConfig = []
    this._lastMousePosition = undefined
  }

  setTarget(target: string | null) {
    this._map.setTarget('force a refresh')
    this._map.setTarget(target as any)
  }

  changeBaseLayer (newBase?: string) {
    let params
    const match = this.availableBaseLayers.find(base => base.name === newBase)
    if (match) {
      params = match.params
    }
    const type = (newBase || '').toLowerCase()
    if (type && type.includes('osm')) {
      this._tileLayerBase.setSource(new olSource.OSM({
        crossOrigin: 'anonymous'
      }))
    } else if (type && type.includes('bing')) {
      this._tileLayerBase.setSource(
        new olSource.BingMaps({
          key: environment.bingKey,
          imagerySet: params && params.bingType ? params.bingType : 'road',
          // use maxZoom 19 to see stretched tiles instead of the olSource.BingMaps
          // "no photos at this zoom level" tiles
          maxZoom: 19,
        }) as any
      )
    } else {
      this._tileLayerBase.setSource(new olSource.OSM({
        crossOrigin: 'anonymous'
      }))
    }
    this._tileLayerBase.setZIndex(-1)
    const tileSource = this._tileLayerBase.getSource()
    if (!tileSource) return
    tileSource.on('tileloadstart', e => {
      if (this._mapLoadedDebounce) clearTimeout(this._mapLoadedDebounce)
      this._mapLoaded.next(false)
    })
    tileSource.on('tileloadend', e => {
      this._mapLoadedDebounce = setTimeout(() => {
        this._mapLoaded.next(true)
      }, 200)
    })
  }

  async setLayerTarget(target: string, wsId: number, center?: boolean) {
    this._targetId = target.toString()
    if (!this._workspaceService.workspaceId) await this._workspaceService.loadWorkspace(wsId)
    const targetLayer = await this._layerService.getById(target)
    if (!targetLayer) {
      await this._layerService.loadSource(target)
    } else {
      if (targetLayer.layer) {
        this.addMapLayer(targetLayer.layer)
        this._map.setTarget(this._map.getTarget())
        if (center) await this.centerLayer(targetLayer)
      } else {
        this._alertService.log('No layer information in target')
      }
    }
  }

  setOverlayTarget(target: HTMLElement, targetContent: HTMLElement) {
    this._popup = new Overlay({
      element: target,
      stopEvent: false,
      autoPan: {
        animation: {
          duration: 250
        }
      },
      positioning: OverlayPositioning.TOP_RIGHT
    })
    this._overlayContent = targetContent
    this._popup.setPosition(undefined)
    this._map.addOverlay(this._popup)

  }

  rotate(angle: number) {
    const radians = ConvertUtil.degreesToRadians(angle)
    const x = this.view.getRotation()

    this.view.animate({
      rotation: x + radians,
      duration: this._defaultAnimationDuration
    })
  }

  resetRotation() {
    this.view.animate({
      rotation: 0,
      duration: this._defaultAnimationDuration
    })
  }

  resetZoom() {
    this.view.animate({
      zoom: this._zoom.default,
      duration: this._resetZoomDuration
    })
    this._zoom.current = this.zoom
    this._zoomChanged.next(this._zoom.current)
  }

  moveTo(coordinates: ICoordinates) {
    this.view.animate({
      center: olProj.fromLonLat([coordinates.lon, coordinates.lat]),
      duration: 200
    })
  }

  async centerLayer(layer: AppLayer | olLayer.Vector<any>) {
    try {
      let extent: olExtent.Extent

      if (layer instanceof AppLayer) {
        extent = await layer.getLayerExtent()
      } else {
        extent = layer.getSource().getExtent()
      }

      if (!extent) throw new AppError('Failed to get layer extent.')
      if (extent.includes(NaN)) throw new AppError('Layer extent is invalid.')

      await this.zoomToExtent(extent as [number, number, number, number])
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  async zoomToExtent(extent: [number, number, number, number] | olExtent.Extent, options: {
    padding?: [number, number, number, number]
    minResolution?: number
    minDistance?: number
    maxDistance?: number
    maxZoomTo?: number
  } = {}) {
    const minDistance = options && options.minDistance || 100
    if (extent[2] - extent[0] < minDistance) {
      const cx = extent[2] - (extent[2] - extent[0]) / 2
      extent[0] = cx - minDistance
      extent[2] = cx + minDistance
    }
    if (extent[3] - extent[1] < minDistance) {
      const cx = extent[3] - (extent[3] - extent[1]) / 2
      extent[1] = cx - minDistance
      extent[3] = cx + minDistance
    }

    if (options && options.maxDistance) {
      if (extent[2] - extent[0] > options.maxDistance) {
        const cx = extent[2] - (extent[2] - extent[0]) / 2
        extent[0] = cx - options.maxDistance
        extent[2] = cx + options.maxDistance
      }
      if (extent[3] - extent[1] > options.maxDistance) {
        const cx = extent[3] - (extent[3] - extent[1]) / 2
        extent[1] = cx - options.maxDistance
        extent[3] = cx + options.maxDistance
      }
    }

    const oldExtent = this.view.calculateExtent().toString()

    if (this._mapZoomSubscription) this._mapZoomSubscription.unsubscribe()
    let lastUpdate: undefined | boolean
    this.view.fit(extent, {
      duration: !this._map.getTargetElement() ? undefined : this._viewFitDuration,
      padding: options && options.padding || Array(4).fill(20),
      maxZoom: options && options.maxZoomTo || OlUtil.maxZoomTo,
      minResolution: options && options.minResolution
    })

    if (this.zoom < this.initZoom.min) {
      this.view.setZoom(this.initZoom.min)
    }
    this._zoom.current = this.zoom
    this._zoomChanged.next(this._zoom.current)

    await CommonUtil.delay(10)
  }

  updateMapSize() {
    this.map && this.map.updateSize()
  }

  addMapLayer(vectorLayer: BaseLayer) {
    this._map.addLayer(vectorLayer)
  }

  hasMapLayer(layer: BaseLayer) {
    return this._map.getLayers().getArray().includes(layer)
  }

  highlight(features: AppFeature[], pointRadius: number = 10) {
    // For now we only use highlight when selecting features,
    // so can emit the event here
    this._selectedFeatures.next(features)
    if (features.length === 0) return
    const start = new Date().getTime()
    const duration = 3000 / 4

    let eventKey: olEvents.EventsKey

    const animate = (event: any) => {
      const vectorContext = event.vectorContext
      const elapsed = event.frameState.time - start
      const elapsedRatio = elapsed / duration

      let opacity = olEasing.easeOut(1 - elapsedRatio)
      let lastStyle: 'point' | 'polygon' | undefined

      for (const feature of features) {
        const getStyle = (geom: olGeom.Geometry) => {
          const type = geom.getType()
          if (type.includes('Point')) {
            const size = LayerUtil.getFeaturesSize(feature)
            const radius = LayerUtil.getClusterRadius(size, pointRadius)
            return LayerStyleUtil.getHighlightStyle('point', opacity, radius)
          } else if (type.includes('Polygon')) {
            return LayerStyleUtil.getHighlightStyle('polygon', opacity)
          } else if (type.includes('Line')) {
            return LayerStyleUtil.getHighlightStyle('polygon', opacity)
          }
        }

        // TODO: Make this recursive/more flexible
        const geom = feature.getGeometry() as olGeom.Geometry
        if (geom.getType().includes('Point')) {
          if (lastStyle !== 'point') {
            const style = getStyle(geom)
            vectorContext.setStyle(style as any)
            lastStyle = 'point'
          }
          vectorContext.drawGeometry(feature.getGeometry())
        } else if (geom instanceof GeometryCollection) {
          for (const subGeom of geom.getGeometries()) {
            const style = getStyle(subGeom)
            vectorContext.setStyle(style as any)
            vectorContext.drawGeometry(subGeom)
          }
          lastStyle = undefined
        } else {
          if (lastStyle !== 'polygon') {
            const style = getStyle(geom)
            vectorContext.setStyle(style)
            lastStyle = 'polygon'
          }
          vectorContext.drawGeometry(feature.getGeometry())
        }
      }

      if (elapsed > duration) {
        // stop the effect
        this._map.render()
        unByKey(eventKey)
        return
      }

      // tell OL3 to continue postrender animation
      this.map.render()
    }

    eventKey = this.map.on('postcompose', animate)
    this.map.render()
  }

  removeMapLayer(layer: BaseLayer) {
    this._map.removeLayer(layer)
  }

  private SetupView(): View {
    const view = new View({
      center: olProj.fromLonLat([this.mapCenter.lon, this.mapCenter.lat]),
      resolutions: OlUtil.getResolution(),
      zoom: this._zoom.current,
      minZoom: this._zoom.min,
      maxZoom: this._zoom.max,
      extent: olProj.transformExtent([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857')
    })

    view.on('change:resolution', () => {
      this._zoom.current = Math.trunc(this.zoom)
      this._zoomChanged.next(this._zoom.current)
    })

    view.on('change', () => this._viewportChanged.next({}))

    return view
  }

  private CreateMap(): Map {
    let map = new Map({
      layers: [this._tileLayerBase, this._highlightLayer],
      view: this.SetupView()
    })

    map.getViewport().addEventListener('contextmenu', async (evt: Event | any) => {
      if (DevUtil.noContextMenu()) return

      const e = evt as MouseEvent
      e.preventDefault()

      if (!this.contextMenuActive) {
        this._mapRightClicked.next(e)
        return
      }

      let layerMeta: AppLayer | undefined
      let features: AppFeature[] | undefined
      let pixelValue: number | undefined

      const coord = olProj.transform(
        this.map.getCoordinateFromPixel([evt.offsetX, evt.offsetY]),
        'EPSG:3857',
        'EPSG:4326'
      )

      const topMostLayer = this.GetLayerAtPixel([evt.offsetX, evt.offsetY])
      if (topMostLayer) {
        const layer = this._layerService.allLayers.find(x => x.layer === topMostLayer)
        this._mapClicked.next({
          evt,
          layer: topMostLayer
        })
        if (layer) layerMeta = layer
      }

      if (layerMeta) {
        if (layerMeta instanceof AppRasterLayer) {
          if (!this.view || !layerMeta.bandMeta) return
          // If raster with max and min data
          if (layerMeta.bandCount === 1) {
            pixelValue = await layerMeta.getPixelInfo(coord, this.getGeoServerResolution())
          }
        }
      }

      let feature = features ? features[0] : undefined
      if (feature) {
        if (!feature.get(Columns.VectorFid) && Array.isArray(feature.get('features'))) {
          // If cluster
          if (feature.get('features').length !== 1) feature = undefined
          else feature = feature.get('features')[0] as AppFeature
        }
      }

      const topMostFeature = ((this.map.getFeaturesAtPixel([e.offsetX, e.offsetY]) || []) as AppFeature[])[0]

      const selectedLayerId = this._layerService.selected ? [+this._layerService.selected.id] : [+this._targetId]

      let contextMenu: CtxUIMenu = [{
        'Open in Google Maps': () => GoogleUtil.openMapsUrl(coord[1], coord[0]),
        'Open in Google Earth': () => GoogleUtil.openEarthUrl(coord[1], coord[0]),
        'Create Table Widget' : () => { this._dashboardLayoutService.createWidgetofType('AttributeTableWidget', selectedLayerId) }
      }]

      if (pixelValue) {
        const layer = layerMeta as AppRasterLayer
        const keyValue: string = 'Pixel value: ' + (Math.round(pixelValue * 100) / 100).toFixed(2)
        const unit = (layer.renderedSource && layer.renderedSource.band_meta &&
          layer.renderedSource.band_meta[0].units) ? `${' ' + layer.renderedSource.band_meta[0].units}` : ''
        let mentItem: CtxUIMenuItem = {}
        mentItem[keyValue + unit] = () => {
          // do nothing.
        }
        contextMenu.unshift(mentItem)
      }

      const returnVisible = (item: WsCtxMenuConfItem | WsCtxMenuConfItem[], e: MapContextMenuEvent) => {
        if (Array.isArray(item)) {
          const group = item.map(i => returnVisible(i, e)).filter(x => !!x)
          if (!group.length) return
          return group
        } else if ((item as WsCtxMenuConfGroup).items) {
          const group = item as WsCtxMenuConfGroup
          const items = group.items.map(x => returnVisible(x, e)).filter(x => !!x)
          if (!items.length) return
          return { name: group.name, items } as WsCtxMenuConfGroup
        } else {
          const show = (item as WsCtxMenuConfOption).show
          if (show && !show(e)) return
          return item
        }
      }

      const appendToCtx = (item: WsCtxMenuConfItem | WsCtxMenuConfItem[], menu: CtxUIMenu, lastGroup: CtxUIMenuItem = {}) => {
        if (Array.isArray(item)) {
          lastGroup = {}
          for (const i of item) {
            lastGroup = appendToCtx(i, menu, lastGroup)
          }
          if (!menu.includes(lastGroup)) menu.push(lastGroup)
          lastGroup = {}
        } else if ((item as WsCtxMenuConfGroup).items) {
          const group = item as WsCtxMenuConfGroup
          const groupMenu = [] as CtxUIMenu
          let lastGroup2: CtxUIMenuItem = {}
          for (const i of group.items) {
            lastGroup2 = appendToCtx(i, groupMenu, lastGroup2)
          }
          lastGroup[group.name] = groupMenu
          if (!menu.includes(lastGroup)) menu.push(lastGroup)
        } else {
          const option = item as WsCtxMenuConfOption
          // eslint-disable-next-line
          lastGroup[option.name] = () => option.action(event)
          if (!menu.includes(lastGroup)) menu.push(lastGroup)
        }

        return lastGroup
      }

      let lastGroup: CtxUIMenuItem = {}

      if (!Object.keys(contextMenu).length) return
      this._contextMenu.openMenu(contextMenu, {
        x: e.x, y: e.y
      })
    })

    map.on('click', (evt: any) => {
      if (this.drawing) {
        const fts = (this.map.getFeaturesAtPixel(evt.pixel) || []) as AppFeature[]
        this._drawFeaturesClicked.next(fts.filter(f => f.drawn))
        return
      }
      if (this._clickToCopyEnabled) {
        const lngLat = olProj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326')
          .map(c => +c.toFixed(6))
        this._clickToCopyEnabled = false
        return
      }

      if (!this.measurementInProgress) {
        this._mapClicked.next({ evt })
      }
      if (this._popup) this._popup.setPosition(undefined)
      this.ProcessClickedFeatures(evt)
    })
    map.on('pointermove', evt => this._mapMouseMove.next(evt))

    return map
  }

  private OnMapMouseMove(evt: any) {
    if (this.drawing) {
      const fts = this.map.getFeaturesAtPixel(evt.pixel) as null | AppFeature[]
      const hoveringDrawnFeature = fts && fts.some(f => f.drawn)
      if (hoveringDrawnFeature) this.ChangeCursor('pointer')
      else this.ChangeCursor('unset')
      return
    }
    if (evt.dragging) {
      this.ChangeCursor('move')
    }
    if (this._clickToCopyEnabled || (this.measurementInProgress && !this.clickToMeasureEnabled) || evt.dragging) return

    // On top most feature hover, show tooltip if it is defined in props.tooltip
    let hasLayerAtPixel = false
    hasLayerAtPixel = this.map.hasFeatureAtPixel(evt.pixel)

    if (!hasLayerAtPixel) {
      this.ChangeCursor('unset')
      this.ResetOblique()
      this._lastHoverFilterCheck = undefined
    } else {
      const features = (this.map.getFeaturesAtPixel(evt.pixel) || []) as AppFeature[]

      if (this._clickFilter) {
        const fn = this._clickFilter
        if (this._lastHoverFilterCheck) {
          const arr = this._lastHoverFilterCheck
          const arrHasChanged = arr.length !== features.length ||
            arr.map((f, i) => features[i] === f).includes(false)
          if (!arrHasChanged) return
        }
        const compatibleFeatures = features.filter(feature => fn(evt, { feature }))
        this._lastHoverFilterCheck = features
        if (compatibleFeatures.length) this.ChangeCursor('pointer')
        else this.ChangeCursor('unset')
        return
      }

      if (!this._clickToMeasureEnabled && !this._clickToCopyEnabled) this.ChangeCursor('pointer')

      const measureGeom = this._clickToMeasureEnabled && this.getMeasurableGeometry(features)
      if (measureGeom) this.ChangeCursor('pointer')

      if (!features.length) {
        this.ResetOblique()
      } else {
        const topMost = features[0]
        const props: IFeatureProperties = topMost.getProperties()

        if (features.length === 1) {
          let properties = topMost.getProperties()
          if (OlUtil.isClusterFeature(properties)) {
            if (properties.features.length === 1) {
              properties = properties.features[0].getProperties()
            } else {
              properties = {}
            }
          }

          if (properties.elevation) {
            this.GenerateObliqueOutline(evt, topMost, properties as IFeatureKnownProperties)
          }
        } else {
          this.ResetOblique()
        }
      }
    }
  }

  getMeasurableGeometry(items: (AppFeature | olGeom.Geometry)[]) {
    return items.reduce((measurable, x) => {
      if (measurable) return measurable

      const geom = x instanceof olGeom.Geometry ? x : x.getGeometry() as olGeom.Geometry
      if (geom instanceof GeometryCollection) {
        measurable = this.getMeasurableGeometry(geom.getGeometries())
      } else {
        const type = geom.getType()
        if (type === 'LineString' || type === 'Polygon') measurable = geom
      }
      return measurable
    }, undefined as olGeom.Geometry | undefined)
  }

  toggleZoomOnDblClick(enable: boolean) {
    const interaction = this._map.getInteractions().getArray().find(x => x instanceof DoubleClickZoom)
    if (enable) {
      if (interaction) return

      this._map.addInteraction(new DoubleClickZoom())
    } else {
      if (interaction) this._map.removeInteraction(interaction)
    }
  }

  private ResetOblique() {
    if (this._obliqueFootprint) {
      this._map.removeLayer(this._obliqueFootprint)
      this._obliqueFootprint = undefined
    }
  }

  private GetLayerAtPixel(pixel: [number, number]): BaseLayer | undefined {
    let topMostLayer: BaseLayer | undefined
    let focusedLayer: BaseLayer | undefined

    this.map.getAllLayers().forEach((bareLayer) => {
      const layer = bareLayer as olLayer.Layer<any>
      const interactive = layer.get('interactive')
      const vectorLayerAtPixel = this.map.getFeaturesAtPixel(pixel, {
        layerFilter: (l) => l === layer
      })
      const rasterDataAtPixel = layer.getData(pixel)
      // Loop only layers that we see in the right hand panel or measures
      // as we don't want to interact with base layers.
      // The 'interactive' must be added to layer on creation
      if (interactive && (vectorLayerAtPixel.length || rasterDataAtPixel)) {
      const olDefaultLayer = topMostLayer && topMostLayer.getZIndex() === 999
      if (!topMostLayer || olDefaultLayer || topMostLayer.getZIndex() < layer.getZIndex()) {
        topMostLayer = layer
      }
      if (layer.get('focused')) focusedLayer = layer
      }
    })
    // If none of the clicked layers were focused, use the top most layer
    return focusedLayer || topMostLayer
  }

  private ProcessClickedFeatures(evt: MapBrowserEvent<any>) {
    if (this.clickToCopyEnabled) return

    const attributes: IFeatureKnownProperties[] = []
    const topMostLayer = this.GetLayerAtPixel(evt.pixel as [number, number])

    if (!topMostLayer) {
      if (!this.measurementInProgress) {
        this._mapClicked.next({ evt, empty: true })
        this._selectedFeatures.next([])
        this._selectedAttributesChange.next({ attributes: [] })
      }
      return
    }

    const sourceId = topMostLayer.get('sourceId')
    const appLayer = this._layerService.allLayers.find((layer) => layer.id === sourceId)

    let features = (this.map.getFeaturesAtPixel(evt.pixel, {
      layerFilter: (layer) => layer === topMostLayer
    }) || []) as AppFeature[]

    features = OlUtil.deClusterFeatures(features)

    if (this.measurementInProgress) {
      if (this.clickToMeasureEnabled) {
        const geom = this.getMeasurableGeometry(features)
        if (geom) this._clickedForMeasure.next(geom)
      }
      return
    }

    this._selectedAttributesChange.next({ attributes, sourceId })
    // TODO: Enable when clicking on sub-geometry of multi-geometry will be used
    // if (clickedFeature) {
    //   const clickedGeometries = OlUtil.getGeometriesAtCoordinate(clickedFeature, this.map.getCoordinateFromPixel(evt.pixel))
    //   if (clickedGeometries.length) clickedGeometry = clickedGeometries[0]
    // }

    if (features.length) {
      const topMostFeature: AppFeature | undefined = features[0]
      this._mostRecentlyClickedFeature = topMostFeature
      this._mapClicked.next({ evt, features, layer: topMostLayer, appLayer })
      const layer = appLayer as undefined | AppLayer
      const riverGauge = (layer && layer.preset === Db.Vip.LayerPreset.RIVER_GAUGE_READINGS)
      if (this._mostRecentlyClickedFeature && !riverGauge) {
        this.SetOverlayContent(this._mostRecentlyClickedFeature, evt.coordinate)
      }
      this.OpenAttributeChart({ evt, features, layer: topMostLayer, appLayer })
    }
  }

  private OpenAttributeChart(data) {
    const layer = data.appLayer as undefined | AppLayer
    if (!layer || layer.preset !== Db.Vip.LayerPreset.RIVER_GAUGE_READINGS) return
    const features = layer.getLinkedTimeSeriesFeatures(data.features[0])

    if (!features.length) throw new AppError(`Cannot render chart without features.`)
    this._trackDialog(
      this._dialog.open(AttributeChartComponent, AttributeChartComponent.setup({
        layer,
        column: [FREDGaugeMeasureGeojsonProperties.VALUE],
        rowIds: features.map(x => `${x.get(layer.idKey)}`)
      }))
    )
  }

  private SetOverlayContent(clickedFeature: AppFeature, overlayCoordinate: olCoordinate.Coordinate) {
    if (this._overlayContent && this._popup) {
      const properties = clickedFeature.getProperties()
      const keys = Object.keys(properties)
      const toPrint = keys.filter(k => {
        return (!(properties[k] instanceof Object) && properties[k])
      }).map(k => { return `${k}: ${properties[k]}` })
      this._overlayContent.innerHTML = `<table>${toPrint.map(elem => `<tr><td>${elem}</td></tr>`).join('')}</table>`
      this._popup.setPosition(overlayCoordinate)
    }
  }

  private ChangeCursor(type: 'move' | 'pointer' | 'unset' | 'crosshair') {
    // NOTE: When chrome developer tools are open, the cursor fails to change
    // on map move. Ignore it, seems like a chrome issue
    this.map.getViewport().style.cursor = type
  }

  private GenerateObliqueOutline(e: MapBrowserEvent<any>, feature: AppFeature, image: IFeatureKnownProperties) {
    if (
      image.elevation &&
      image.bearing !== null &&
      image.bearing !== undefined &&
      image.sensor_size_v &&
      image.sensor_size_h &&
      image.focal_length &&
      image.mount_angle
    ) {
      const altitude = image.elevation,
        bearing = image.bearing,
        bearingRadians = (image.bearing * Math.PI) / 180,
        sensorSizeV = image.sensor_size_v,
        sensorSizeH = image.sensor_size_h,
        focalLength = image.focal_length,
        mountAngle = image.mount_angle

      // Calculating FOV
      const fovV = 2 * Math.atan(sensorSizeV / (2 * focalLength)) * (180 / 3.14159265),
        fovH = 2 * Math.atan(sensorSizeH / (2 * focalLength)) * (180 / 3.14159265),
        // Distances along the ground to the start and end of the photograph (along-track)
        distToClosestPointAlongGround = altitude * Math.tan((mountAngle - fovV / 2.0) * (3.14159 / 180)),
        distToFurthestPointAlongGround = altitude * Math.tan((mountAngle + fovV / 2.0) * (3.14159 / 180)),
        // Along track distance checker (saving the variable to compare to manual measurements in GE)
        // MSF TODO: do we need this at all?
        groundDistForward = distToFurthestPointAlongGround - distToClosestPointAlongGround,
        // Calculating shortest distance between start of photograph and camera, and end of photograph and camera
        distToGroundDirectClosest = altitude / Math.cos((mountAngle - fovV / 2.0) * (3.14159 / 180)),
        distToGroundDirectFurthest = altitude / Math.cos((mountAngle + fovV / 2.0) * (3.14159 / 180)),
        // Calculating the across track near and far distances
        acrossTrackNear = 2 * distToGroundDirectClosest * Math.tan((fovH / 2.0) * (3.14159 / 180)),
        acrossTrackFar = 2 * distToGroundDirectFurthest * Math.tan((fovH / 2.0) * (3.14159 / 180)),
        fovHeight = distToFurthestPointAlongGround - distToClosestPointAlongGround,
        // MSF TODO: do we need this at all?
        fovCentreWidth = (acrossTrackFar + acrossTrackNear) / 2.0,
        halfFovHeight = fovHeight / 2.0,
        halfCentreWidthFar = acrossTrackFar / 2.0,
        halfCentreWidthNear = acrossTrackNear / 2.0

      // Function for calculating new coordinates from an origin and an offset in approximate spherical geometry
      const approxOffsetCal = (distance: number, bearingDeg: number, originWGS84: [number, number]) => {
        const yCentroidIncrement = (distance * Math.cos(bearingDeg * (3.14159265 / 180.0))) / 111111
        const xCentroidIncrement =
          (distance * Math.sin(bearingDeg * (3.14159265 / 180.0))) / Math.cos(originWGS84[1] * (3.14159265 / 180.0)) / 111111
        return [xCentroidIncrement + originWGS84[0], yCentroidIncrement + originWGS84[1]] as [number, number]
      } // End of approximate calculate offset function

      // Calculating centroid position along track, as this will be used to position the FOV
      const alongTrackCentroid = distToClosestPointAlongGround + (distToFurthestPointAlongGround - distToClosestPointAlongGround) / 2.0

      // To get the centroid of the footprint closer to correct, we use an approximation for spherical geometry
      // ideally we would consider this geometry for all measurements
      const planeCoordinates = feature
        .get('features')[0]
        .getGeometry()
        .getCoordinates()
      const planeCoordsWGS84 = olProj.transform(planeCoordinates, 'EPSG:3857', 'EPSG:4326') as [number, number]

      let centroid = approxOffsetCal(alongTrackCentroid, bearing, planeCoordsWGS84)
      centroid = olProj.transform(centroid, 'EPSG:4326', 'EPSG:3857') as [number, number]

      // Creating polygon shape
      const coordinates = [
        [0.0 - halfCentreWidthFar, 0.0 + halfFovHeight],
        [0.0 + halfCentreWidthFar, 0.0 + halfFovHeight],
        [0.0 + halfCentreWidthNear, 0.0 - halfFovHeight],
        [0.0 - halfCentreWidthNear, 0.0 - halfFovHeight],
        [0.0 - halfCentreWidthFar, 0.0 + halfFovHeight]
      ]

      // Rotating the polygon
      olCoordinate.rotate(coordinates[0] as [number, number], -bearingRadians)
      olCoordinate.rotate(coordinates[1] as [number, number], -bearingRadians)
      olCoordinate.rotate(coordinates[2] as [number, number], -bearingRadians)
      olCoordinate.rotate(coordinates[3] as [number, number], -bearingRadians)
      olCoordinate.rotate(coordinates[4] as [number, number], -bearingRadians)

      // Applying the footprint centroid offset so that the polygon is in the correct position
      coordinates[0][0] = coordinates[0][0] + centroid[0]
      coordinates[0][1] = coordinates[0][1] + centroid[1]
      coordinates[1][0] = coordinates[1][0] + centroid[0]
      coordinates[1][1] = coordinates[1][1] + centroid[1]
      coordinates[2][0] = coordinates[2][0] + centroid[0]
      coordinates[2][1] = coordinates[2][1] + centroid[1]
      coordinates[3][0] = coordinates[3][0] + centroid[0]
      coordinates[3][1] = coordinates[3][1] + centroid[1]
      coordinates[4][0] = coordinates[4][0] + centroid[0]
      coordinates[4][1] = coordinates[4][1] + centroid[1]

      const geojsonObject = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:3857'
          }
        },
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            }
          }
        ]
      }

      const geojsonPolygonFeature = new Geojson().readFeatures(geojsonObject)

      const source = new olSource.Vector({
        features: geojsonPolygonFeature
      })

      if (this._obliqueFootprint) {
        this._map.removeLayer(this._obliqueFootprint)
      }

      this._obliqueFootprint = new olLayer.Vector<any>({
        source: source,
        style: new olStyle.Style({
          stroke: new olStyle.Stroke({
            color: 'rgba(255, 0, 0, 0.6)',
            width: 2
          }),
          fill: new olStyle.Fill({
            color: 'rgba(170, 170, 255, 0.3)'
          })
        })
      })

      // Adding the photo footprint to the map
      this.addMapLayer(this._obliqueFootprint)
      this._obliqueFootprint.setZIndex(Infinity)
    }
  }

  getCanvas(): HTMLCanvasElement | undefined {
    const container = this._map.getTargetElement()
    if (container) {
      const canvasSel = container.getElementsByTagName('canvas')
      return (canvasSel && canvasSel.item(0)) || undefined
    }
  }

  getGeoServerResolution(): number {
    const center: any = this.view.getCenter()
    const view = new View({
      center: olProj.transform(center, 'EPSG:3857', 'EPSG:4326'),
      zoom: this.zoom,
      projection: 'EPSG:4326'
    })
    return view.getResolutionForZoom(this.zoom)
  }

  async zoomToMapLayers() {
    // For this to work layers must have property extent set
    const layers = this._layerService.allLayers
    let extent: [number, number, number, number] | undefined
    for (const l of layers) {
      let layerExtent

      if (l instanceof AppLayer) {
        layerExtent = await l.getLayerExtent()
      }
      if (layerExtent) {
        extent = (extent ? olExtent.extend(extent, layerExtent) : layerExtent) as [number, number, number, number]
      }
    }
    if (!extent) throw new AppError('Failed to get layer extent.')
    if (extent.includes(NaN)) throw new AppError('Layer extent is invalid.')
    this.zoomToExtent(extent)
  }
}
