import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'app-ratio-box',
  templateUrl: './ratio-box.component.html',
  styleUrls: ['./ratio-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RatioBoxComponent {
  @Input() paddingTop = '100%'
}
