<h2  *ngIf="!isDashboardWidget" mat-dialog-title>
  <mat-icon> build</mat-icon>
  Statistic Query Builder
</h2>
<p *ngIf="errorMessage" class="st-error">
  {{errorMessage}}
</p>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="query-builder-form">
      <div class="row header">
        <mat-form-field>
          <input matInput placeholder="Query name" name="query_name" #queryValue formControlName="name"/>
        </mat-form-field>

        <mat-form-field *ngIf="!isDashboardWidget">
          <mat-select disableOptionCentering placeholder="Layer" name="query_layer" formControlName="layer">
            <ng-container *ngFor="let layer of layers">
              <mat-optgroup *ngIf="layer.groupName; else optionTemp" [label]="layer.groupName">
                <mat-option *ngFor="let target of layer.targets" [value]="target">
                  {{getOptionName(target)}}
                </mat-option>
              </mat-optgroup>

              <ng-template #optionTemp>
                <mat-option [value]="layer">
                  {{ layer.title }}
                </mat-option>
              </ng-template>
            </ng-container>

          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="query-body">
      <div class="query-block">
        <mat-form-field class="block-content">
          <mat-select disableOptionCentering placeholder="Attribute" name="query_column" formControlName="column">
              <ng-container *ngFor="let column of columns">
                <mat-option [value]="column.name"> {{ column.name}}</mat-option>
              </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="block-content">
          <mat-select disableOptionCentering placeholder="Operator" name="query_operator" formControlName="operator">
              <ng-container *ngFor="let operator of operators">
                <mat-option [value]="operator"> {{operator}}</mat-option>
              </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

</div>

<div *ngIf="!isDashboardWidget" class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>CANCEL</button>
  </div>
  <div class="right">
    <app-submit-button color="primary" mat-raised-button (click)="saveQuery()"
      [loading]="saving" [disabled]="!form.valid" text="SAVE">
    </app-submit-button>
  </div>
</div>
