import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { FormTemplate } from '@core/models/form-template'
import { TypedFormGroup } from '@core/models/typed-form-control'
import { ViewerService } from '@services/workspace'
import * as moment from 'moment'
import { Subscription } from 'rxjs'

type DatetimeRange = {
  valid_from?: string
  valid_to?: string
  focal_point?: string
}

@Component({
  selector: 'app-timeseries-selection-form',
  templateUrl: './timeseries-selection-form.component.html',
  styleUrls: ['./timeseries-selection-form.component.scss']
})

export class TimeseriesSelectionFormComponent extends FormTemplate<UntypedFormGroup> implements OnInit, AfterViewChecked {
  @Output() formChange = new EventEmitter<UntypedFormGroup>()
  private _preFillFrom?: string
  @Input()
  get preFillFrom () {
    return this._preFillFrom
  }
  set preFillFrom (val: string | undefined) {
    if (this._preFillFrom !== val) {
      this._preFillFrom = val
      this.claimRange.valid_from = this._preFillFrom
    }
  }
  private _preFillTo?: string
  @Input()
  get preFillTo () {
    return this._preFillTo
  }
  set preFillTo (val: string | undefined) {
    if (this._preFillTo !== val) {
      this._preFillTo = val
      this.claimRange.valid_to = this._preFillTo
    }
  }
  private _preFillFocal?: string
  @Input()
  get preFillFocal () {
    return this._preFillFocal
  }
  set preFillFocal (val: string | undefined) {
    if (this._preFillFocal !== val) {
      this._preFillFocal = val
      this.claimRange.focal_point = this._preFillFocal
    }
  }

  claimRange: DatetimeRange = {
    valid_from: '',
    valid_to: '',
    focal_point: ''
  }
  // form: Form
  private _subscriptions = new Subscription()
  formControlKeys = ['datetimeSelection']

  get isWholeViewMode (): boolean {
    return this._viewerService.wholeViewMode
  }

  constructor (
    private _viewerService: ViewerService,
    private cdRef: ChangeDetectorRef
  ) {
    super(new UntypedFormGroup({
      datetimeSelection: new UntypedFormGroup({
        valid_from: new UntypedFormControl(undefined),
        valid_to: new UntypedFormControl(undefined),
        focal_point: new UntypedFormControl(undefined)
      })
    }))
  }

  ngOnInit () {
    this._subscriptions.add(
      this.form.controls.datetimeSelection.valueChanges.subscribe(() =>
        this.formChange.next(this.form)
      )
    )
    const dateForm = (this.form.get('datetimeSelection') as UntypedFormGroup).controls
    const now = moment()
    const wholeViewModeTo = this.isWholeViewMode && this._viewerService.wholeViewTimeSeries && this._viewerService.wholeViewTimeSeries.date_range.to
    const wholeViewModeFrom = this.isWholeViewMode && this._viewerService.wholeViewTimeSeries && this._viewerService.wholeViewTimeSeries.date_range.from
    const wholeViewModeFocal = this.isWholeViewMode && this._viewerService.wholeViewTimeSeries && this._viewerService.wholeViewTimeSeries.date_range.focal
    dateForm['valid_to'].setValue(wholeViewModeTo ? wholeViewModeTo : now.toISOString())
    dateForm['valid_from'].setValue(wholeViewModeFrom ? wholeViewModeFrom : now.startOf('day').toISOString())
    dateForm['focal_point'].setValue(wholeViewModeFocal ? wholeViewModeFocal : moment().startOf('day').add((moment().toDate().getTime() - moment().startOf('day').toDate().getTime()) / 2, 'ms').toISOString())
  }

  ngAfterViewChecked () {
    this.cdRef.detectChanges()
  }

  updateClaimDate (claim: DatetimeRange) {
    const datetimeSelectionForm = this.form.controls.datetimeSelection as UntypedFormGroup
    if (claim.valid_from || claim.valid_to) {
      datetimeSelectionForm.controls.valid_from.setValidators(Validators.required)
      datetimeSelectionForm.controls.valid_to.setValidators(Validators.required)
      datetimeSelectionForm.controls.focal_point.setValidators(Validators.required)
    } else {
      datetimeSelectionForm.controls.valid_from.clearValidators()
      datetimeSelectionForm.controls.valid_to.clearValidators()
      datetimeSelectionForm.controls.focal_point.clearValidators()
    }

    if (claim.valid_from) {
      const validFrom = moment(claim.valid_from).toISOString()
      datetimeSelectionForm.controls.valid_from.setValue(validFrom)
    }
    if (claim.valid_to) {
      const validTo = moment(claim.valid_to).toISOString()
      datetimeSelectionForm.controls.valid_to.setValue(validTo)
    }
    if (claim.focal_point) {
      const focalPoint = moment(claim.focal_point).toISOString()
      datetimeSelectionForm.controls.focal_point.setValue(focalPoint)
    }

    datetimeSelectionForm.controls.valid_from.updateValueAndValidity()
    datetimeSelectionForm.controls.valid_to.updateValueAndValidity()
    datetimeSelectionForm.controls.focal_point.updateValueAndValidity()
  }

}
