import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { LayerService } from '../layer.service'
import { LayerLoadingService } from '../loading/layer-loading.service'
import { IChartWRef, IChartGroup } from '@core/models/chart-object'
import { Db } from '@vip-shared/models/db-definitions'
import { cloneDeep } from 'lodash'
import { IPNewChart } from '@vip-shared/interfaces'
import { AppLayer } from '@core/models/layer/app-layer'
import { AppLayerGroup } from '@core/models/layer'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'

interface ChartConfiguration {
  comparisonType: Db.Helper.Prj.ChartComparisonType
  type: Db.Helper.Prj.ChartType
  selectedColumns: Db.Helper.Geo.AttributeColumn[]
  labelColumn?: Db.Helper.Geo.AttributeColumn
}

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  private _subscriptions = new Subscription()

  private _lastConfiguration?: ChartConfiguration

  get lastConfiguration () {
    return this._lastConfiguration && cloneDeep(this._lastConfiguration)
  }

  set lastConfiguration (val: ChartConfiguration | undefined) {
    this._lastConfiguration = cloneDeep(val)
  }

  private _charts: IChartWRef[] = []
  get charts (): IChartWRef[] {
    return this._charts
  }

  private _groupedCharts: IChartGroup[] = []
  get groupedCharts (): IChartGroup[] {
    return this._groupedCharts
  }

  private _chartListChanged = new Subject<IChartGroup[]>()
  get chartListChanged () {
    return this._chartListChanged.asObservable()
  }

  get orm () {
    return this._workspaceService.orm.Views().View(this._workspaceService.viewId as number)
    .Charts()
  }

  constructor (
    private _workspaceService: WorkspaceService,
    private _layerService: LayerService,
    private _layerLoadingService: LayerLoadingService
  ) {
    this._workspaceService.onExit.subscribe(() => this.CleanupService())
    this.Init()
  }

  private CleanupService () {
    this._subscriptions.unsubscribe()
    this._subscriptions = new Subscription()

    this._groupedCharts = []
    this._charts = []
    this.Init()
  }

  private Init () {
    // After layers have finished loading, load queries for same workspace
    this._subscriptions.add(
      this._layerLoadingService.layersLoading.subscribe(async loading => {
        if (loading.isLoading || !this._workspaceService.selectedWorkspace || !this._workspaceService.viewId) return
        // when finished loading
        await this.GetCharts()
      })
    )
  }

  private async GetCharts () {
    const charts = await this.orm.get().run()

    // Match every query with a layer
    this._charts = charts.map((x, i) => ({
      ...x,
      targetRef: this._layerService.allLayers.find(y => y.id === x.layer_id)
    } as IChartWRef))
    // Discard queries without any layer reference
    .filter(i => !!i.targetRef)

    this.GroupCharts()
  }

  private GroupCharts () {
    const chartGroups: IChartGroup[] = []
    for (const charts of this._charts) {
      let group = chartGroups.find(x => x.id === charts.targetRef.id)

      if (!group) {
        group = {
          id: charts.targetRef.id,
          name: charts.targetRef.title,
          charts: []
        }

        chartGroups.push(group)
      }

      group.charts.push(charts)
    }
    this._groupedCharts = chartGroups
    this._chartListChanged.next(this._groupedCharts)
  }

  updateCharts () {
    this.GetCharts()
  }

  async deleteChart (chart: IChartWRef) {
    await this.orm.Chart(chart.chart_id).delete().run()

    this._charts.splice(this._charts.indexOf(chart), 1)
    this.GroupCharts()
  }

  async createChart (newChart: IPNewChart) {
    const chart = await this.orm.create(newChart).run()
    const wRef = {
      ...chart,
      targetRef: this._layerService.allLayers.find(y => y.id === chart.layer_id)
    } as IChartWRef
    this._charts.push(wRef)

    this.GroupCharts()

    return wRef
  }

  getTargetChart (target: AppLayer | AppLayerGroup) {
    return this._charts.filter(chart => {
      return chart.targetRef.id === target.id
    })
  }

  async getChartPixelData (layer: AppLayer | AppRasterLayer, chart: IChartWRef) {
    if (layer instanceof AppRasterLayer && layer.sources.length > 1) {
      if (!chart.pixel) return
      return layer.getPixelData(chart.pixel.coordinates, chart.pixel.resolution)
    }
    return
  }

  returnPixelObject (values, chart: IChartWRef) {
    if (!chart.pixel) return
    return {
      coordinates: chart.pixel.coordinates,
      resolution: chart.pixel.resolution,
      focused_value: chart.pixel.focused_value,
      hue: chart.pixel.hue,
      pixel_values: values
    }
  }
}
