import { Component } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService, SettingsService } from '@services/core'
import { MatTableDataSource } from '@angular/material/table'

type Row = Pick<Db.Vip.Geo.ILayerSequence, 'layer_sequence_id' | 'name'> & { layer_sequence_id?: string }

@Component({
  selector: 'app-layer-sequences-dataset',
  templateUrl: './layer-sequences-dataset.component.html',
  styleUrls: ['./layer-sequences-dataset.component.scss']
})
export class LayerSequencesDatasetComponent {

  displayColumns: string[] = ['name', 'actions']
  dataSource?: MatTableDataSource<Row>
  stickyHeader = true

  sequences: Row[] = []

  constructor (
    private _api: VipApiService,
    private _settingsService: SettingsService
  ) {
    this.stickyHeader = !this._settingsService.isIEorEdge(false)
    this.GetSequences()
  }

  private async GetSequences () {
    this.sequences = await this._api.orm.Layers().Sequences().get().run()
    this.dataSource = new MatTableDataSource(this.sequences)
  }

  async deleteSequence (seq: Row) {
    if (!seq.layer_sequence_id) return
    await this._api.orm.Layers().Sequences().Sequence(seq.layer_sequence_id).delete().run()

    this.sequences.splice(this.sequences.indexOf(seq), 1)
    if (this.dataSource) this.dataSource.data = this.sequences
  }
}
