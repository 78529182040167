import { Injectable } from '@angular/core'
import { VipApiService } from '../vip-api/vip-api.service'
import { environment } from 'environments/environment'
import { Db } from '@vip-shared/models/db-definitions'
import { ThemeService } from '../theme/theme.service'
import { LogoSelector } from '@vip-shared/interfaces/logo-selector'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private _mockSubdomain?: string
  get mockSubdomain () {
    return this._mockSubdomain
  }

  domainCustomer?: Db.Vip.Cst.ICustomer

  private _backgroundUrl?: string
  get background () {
    return this._backgroundUrl
  }

  private _userGuideUrl!: string
  get userGuideUrl () {
    return this._userGuideUrl
  }

  private _subdomainMetaLoad = new Subject()
  get subdomainMetaLoad () {
    return this._subdomainMetaLoad.asObservable()
  }

  private _showGSICopyright = false
  get showGSICopyright () {
    return this._showGSICopyright
  }

  constructor (
    private _api: VipApiService,
    private _themeService: ThemeService
  ) { }

  init () {
    if (!environment.production) {
      this._showGSICopyright = true
      const matches = window.location.search.match(/(subdomain=)([^&]+)/)
      const query = Array.isArray(matches) ? matches[matches.indexOf('subdomain=') + 1] : undefined
      if (!query) return

      this._mockSubdomain = query
    }

    this._backgroundUrl = `${environment.VIPServer}${
      this._api.orm.Customers()
        .Subdomains()
        .getBackground(this._mockSubdomain).endpoint
    }`

    this._userGuideUrl = `${environment.VIPServer}${
      this._api.orm.Customers()
        .Subdomains()
        .getUserGuide(this._mockSubdomain).endpoint
    }`

    const style = document.createElement('style')
    style.innerHTML = `
      .st-customer-background {
        background-image: url('${this.background}')
      }
    `
    document.head.appendChild(style)

    this._api.orm.Customers()
      .Subdomains().getCustomer(this._mockSubdomain)
      .run().then(cst => {
        this.domainCustomer = cst
        this._showGSICopyright = this.domainCustomer ? this.domainCustomer.gsi_branding : true
        if (this.domainCustomer) {
          if (this.domainCustomer.theme) {
            this._themeService.setBaseTheme(this.domainCustomer.theme)
          }
          this._themeService.vipBranding = this.domainCustomer.vip_branding
          this._themeService.gsiBranding = this.domainCustomer.gsi_branding
          this._themeService.updateLogo(this.domainCustomer, this.logoUrl('browser_tab_icon'))
        }
        this._subdomainMetaLoad.next({})
      })
  }

  logoUrl (selector: LogoSelector = 'auth_page') {
    return `${environment.VIPServer}${
      this._api.orm.Customers()
        .Subdomains()
        .getLogo(selector, this._mockSubdomain).endpoint
    }`
  }
}
