import { Component, Input, ContentChild } from '@angular/core'

@Component({
  selector: 'app-ws-placeholder-default',
  templateUrl: './ws-placeholder-default.component.html',
  styleUrls: ['./ws-placeholder-default.component.scss', '../placeholders.scss']
})
export class WsPlaceholderDefaultComponent {
  @Input() extent?: [number, number, number, number]
  @Input() workspaceId?: number
}
