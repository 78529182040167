<div class="st-customer-background">
  <div class="auth-card-wrapper">
    <mat-card class="mat-elevation-z5">
      <app-powered-by>
        <app-logo logoSelector="auth_page"></app-logo>
      </app-powered-by>

      <mat-card-content>
        <div>
          <p>You have logged out from the VIP Platform</p>
        </div>
      </mat-card-content>

      <mat-card-footer class="st-wide">
        <ul>
          <li><a routerLink="/login">Back</a></li>
        </ul>
      </mat-card-footer>

    </mat-card>

    <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
  </div>
</div>
