import { Injectable, Inject } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { cloneDeep } from 'lodash'
import { Title } from '@angular/platform-browser'
import { DOCUMENT } from '@angular/common'
import { IRCustomerExtended } from '@vip-shared/interfaces'
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _colorKeys = [
    {
      key: 'primary',
      name: 'Primary',
      opacities: [0.5, 0.7, 1]
    }, {
      key: 'secondary',
      name: 'Secondary',
      opacities: [0.5, 0.7, 1]
    }, {
      key: 'warn',
      name: 'Warning',
      opacities: [0.07, 0.7, 1]
    }, {
      key: 'error',
      name: 'Error'
    }, {
      key: 'success',
      name: 'Success'
    }, {
      key: 'foreground',
      name: 'Foreground',
      opacities: [0.05, 0.1, 0.2, 0.5, 0.7, 0.9, 1]
    }, {
      key: 'background-primary',
      name: 'Background (Primary)',
      opacities: [0.5, 0.8, 1]
    }, {
      key: 'background-secondary',
      name: 'Background (Secondary)'
    }, {
      key: 'background-tertiary',
      name: 'Background (Tertiary)'
    }, {
      key: 'backdrop',
      name: 'Backdrop',
      opacities: [0.1, 0.5, 0.8]
    }
  ]
  get colorKeys () {
    return cloneDeep(this._colorKeys)
  }
  private _componentColorKeys = [
    {
      key: 'body-background',
      name: 'Body Background',
      description: 'Background colour for login page and explorer.',
      default: 'background-tertiary',
      defaultName: 'Background (Tertiary)'
    }, {
      key: 'header-background',
      name: 'Header background',
      description: 'Background colour for header bar in explorer and user panel.',
      default: 'background-primary',
      defaultName: 'Background (Primary)'
    }, {
      key: 'header-foreground',
      name: 'Header foreground',
      description: 'Text colour for header bar in explorer and user panel.',
      default: 'foreground',
      defaultName: 'Foreground'
    }, {
      key: 'paragraph-highlight',
      name: 'Paragraph highlight',
      description: 'Highlighted words colour in text.',
      default: 'primary',
      defaultName: 'Primary'
    }, {
      key: 'auth-card-background',
      name: 'Authentication card background',
      description: 'Background colour for card in login, password reset and password recovery pages.',
      default: 'background-primary',
      defaultName: 'Background (Primary)'
    }, {
      key: 'logo-placeholder-background',
      name: 'Logo placeholder background',
      description: 'Background colour for placeholder customer logo in workspace details.',
      default: 'foreground',
      defaultName: 'Foreground'
    }, {
      key: 'logo-placeholder-foreground',
      name: 'Logo placeholder foreground',
      description: 'Foreground colour for placeholder customer logo in workspace details.',
      default: 'background-secondary',
      defaultName: 'Background (Secondary)'
    }, {
      key: 'ws-header-bars-background',
      name: 'Workspace header/bar background',
      description: 'Background colour for panel headers, timeline bar and navigation menu in workspace.',
      default: 'background-primary',
      defaultName: 'Background (Primary)'
    }, {
      key: 'ws-header-bars-foreground',
      name: 'Workspace header/bar foreground',
      description: 'Foreground colour for panel headers, timeline bar and navigation menu in workspace.',
      default: 'foreground',
      defaultName: 'Foreground'
    }, {
      key: 'map-controls-foreground',
      name: 'Map controls foreground',
      description: '',
      default: 'foreground-50',
      defaultName: 'Foreground'
    }, {
      key: 'map-controls-hover',
      name: 'Map controls foreground highlight',
      description: 'Button colour of map controls when hovered or active.',
      default: 'foreground',
      defaultName: 'Foreground'
    }, {
      key: 'map-controls-background',
      name: 'Map controls background',
      description: '',
      default: 'background-primary',
      defaultName: 'Background (Primary)'
    }, {
      key: 'ws-controls-primary',
      name: 'Map controls primary',
      description: 'Colour for indicating values and selection in map controls and workspace panels.',
      default: 'primary',
      defaultName: 'Primary'
    }, {
      key: 'primary-button-foreground',
      name: 'Primary color button foreground',
      description: '',
      default: 'foreground',
      defaultName: 'Foreground'
    }, {
      key: 'secondary-button-foreground',
      name: 'Secondary color button foreground',
      description: '',
      default: 'foreground',
      defaultName: 'Foreground'
    }
  ]
  get componentColorKeys () {
    return cloneDeep(this._componentColorKeys)
  }
  private _defaultTheme: {
    colors: NonNullable<NonNullable<Db.Vip.Cst.ICustomer['theme']>['colors']>
    component_colors: NonNullable<NonNullable<Db.Vip.Cst.ICustomer['theme']>['component_colors']>
  } = {
    colors: {} as any,
    component_colors: {} as any
  }
  get defaultTheme () {
    return cloneDeep(this._defaultTheme)
  }
  private _baseTheme: Db.Vip.Cst.ICustomer['theme']
  private _appliedTheme: Db.Vip.Cst.ICustomer['theme']
  private _previewTheme: Db.Vip.Cst.ICustomer['theme']
  get previewTheme () {
    return this._previewTheme
  }

  private _vipBranding: boolean = true
  get vipBranding () {
    return this._vipBranding
  }
  set vipBranding (hasVipBranding: boolean) {
    this._vipBranding = hasVipBranding
    this.setTitle(this._currentTitle)
  }

  private _gsiBranding: boolean = true
  get gsiBranding () {
    return this._gsiBranding
  }
  set gsiBranding (hasGsiBranding: boolean) {
    this._gsiBranding = hasGsiBranding
  }

  private _currentTitle: string = 'Visual Intelligence Platform'

  constructor (
    private _titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument
    ) {
    for (const k of this._colorKeys) {
      for (const op of k.opacities || [1]) {
        const suffix = op === 1 ? '' : `-${Math.round(op * 100)}`
        this._defaultTheme.colors[k.key + suffix] = getComputedStyle(
          document.documentElement
        ).getPropertyValue(
          `--${k.key}${suffix}`
        ).trim()
      }
    }

    for (const k of this._componentColorKeys) {
      this._defaultTheme.component_colors[k.key] = getComputedStyle(
        document.documentElement
      ).getPropertyValue(
        `--${k.key}`
      ).trim()
    }
  }

  updateLogo (customer: Db.Vip.Cst.ICustomer | IRCustomerExtended, domainIconUrl?: string) {
    const headerIconElement = this._document.getElementById('appFavicon')
    if (headerIconElement) {
      if (customer) {
        if (this._gsiBranding) {
          headerIconElement.setAttribute('href', 'favicon.ico')
        } else {
          const theme = customer.theme
          const selection = theme && theme.logo_selection && theme.logo_selection['browser_tab_icon']
          let logo = customer.customer_logo_secondary
          if (!selection || selection !== 'secondary') logo = customer.customer_logo
          if (logo) {
            headerIconElement.setAttribute('href', logo)
          }
          if (domainIconUrl) {
            headerIconElement.setAttribute('href', domainIconUrl)
          }
        }
      }
    }
  }

  setTitle (title: string) {
    this._currentTitle = title
    if (this._vipBranding) {
      this._titleService.setTitle(`${this._currentTitle} - VIP`)
    } else {
      this._titleService.setTitle(this._currentTitle)
    }
  }

  getColor (key: string) {
    return getComputedStyle(
      document.documentElement
    ).getPropertyValue(
      `--${key}`
    ).trim()
  }
  setBaseTheme (theme: Db.Vip.Cst.ICustomer['theme']) {
    this._baseTheme = cloneDeep(theme)
    this.applyTheme(this._baseTheme)
  }

  applyTheme (theme: 'base' | 'applied' | Db.Vip.Cst.ICustomer['theme'], preview = false) {
    if (theme === 'base') theme = cloneDeep(this._baseTheme || this._defaultTheme)
    if (theme === 'applied') theme = cloneDeep(this._appliedTheme || this._baseTheme || this._defaultTheme)

    if (theme instanceof Object) {
      for (const k in theme.colors) {
        document.documentElement.style.setProperty(`--${k}`, theme.colors[k])
      }

      for (const k in theme.component_colors) {
        document.documentElement.style.setProperty(`--${k}`, theme.component_colors[k])
      }
    }

    if (!preview) {
      this._appliedTheme = cloneDeep(theme)
      this._previewTheme = undefined
    } else this._previewTheme = cloneDeep(theme)
  }

}
