<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 240" width="100%">
  <rect class="header-bar" x="0" y="0" width="10" height="100%"></rect>
  <rect class="header-bar" x="10" y="0" width="300" height="10"></rect>
  <foreignObject x="10" y="10" width="300" height="240">
    <xhtml:app-ws-placeholder-map  xmlns="http://www.w3.org/1999/xhtml" [extent]="extent" [workspaceId]="workspaceId">

    </xhtml:app-ws-placeholder-map>
  </foreignObject>
  <rect class="map-control" x="17" y="173" width="8" height="8" rx="10" ry="10"></rect>
  <rect class="map-control" x="14" y="184" width="15" height="15" rx="10" ry="10"></rect>
  <rect class="map-control" x="16" y="201" width="10" height="5" rx="3" ry="3"></rect>
  <rect class="map-control" x="19" y="208" width="5" height="30" rx="3" ry="3"></rect>
  <rect class="map-control" x="30" y="233" width="30" height="5" rx="3" ry="3"></rect>

  <rect class="header-bar" x="310" y="0" width="70" height="15"></rect>
  <rect class="default-section" x="310" y="15" width="70" height="40"></rect>

  <rect class="header-bar" x="310" y="55" width="70" height="15"></rect>
  <rect class="default-section" x="310" y="70" width="70" height="180"></rect>

  <line class="layer-control-underline" x1="310" y1="80" x2="380" y2="80" />
  <rect class="layer-control" x="310" y="80" width="70" height="20"></rect>
  <line class="layer-control-underline" x1="330" y1="100" x2="380" y2="100" />
  <rect class="style-preview" x="311" y="81" width="9" height="18"></rect>
  <rect class="foreground" x="330" y="84" width="20" height="5"></rect>
  <rect class="foreground" x="330" y="92" width="47" height="5"></rect>

  <rect class="layer-control" x="310" y="100" width="70" height="20"></rect>
  <line class="layer-control-underline" x1="330" y1="120" x2="380" y2="120" />
  <rect class="style-preview-secondary" x="311" y="101" width="9" height="18"></rect>
  <rect class="foreground" x="330" y="104" width="20" height="5"></rect>
  <rect class="foreground" x="330" y="112" width="47" height="5"></rect>

  <rect class="layer-control" x="310" y="120" width="70" height="20"></rect>
  <line class="layer-control-underline" x1="330" y1="140" x2="380" y2="140" />
  <rect class="style-preview" x="311" y="121" width="9" height="18"></rect>
  <rect class="foreground" x="330" y="124" width="20" height="5"></rect>
  <rect class="foreground" x="330" y="132" width="47" height="5"></rect>
</svg>
