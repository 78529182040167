import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
import AppError, { handleError } from '@core/models/app-error'
@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  transform (value: any, format: string): any {
    try {
      const formats = format.match(/^(.*)=>(.*)$/)
      if (!formats) throw new AppError(`Invalid format passed to 'moment' pipe. Only 'moment' date formats are allowed.`)
      // TODO: undefined from is deprecated, when mssql date retrieval is fixed, uncomment this line?
      const from = formats[1] // || moment.ISO_8601
      if (!formats[2]) throw new AppError(`Output format must be specified for 'moment' pipe.`)
      const to = formats[2] || 'YYYY'

      if (!value) return value

      if (moment.isMoment(value)) {
        return value.format(to)
      } else {
        return moment(value, from).format(to)
      }

    } catch (error: any) {
      handleError(error)
      return value
    }
  }
}
