<div class="dialog-wrapper">
  <div class="title-wrapper"><h1 mat-dialog-title>Users</h1></div>
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="user_forename">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let account">
          <span>{{ account.user_forename }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="user_surname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Lastname</mat-header-cell>
        <mat-cell *matCellDef="let account">
          <span>{{ account.user_surname }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="user_email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let account">
          <span> {{ account.user_email }} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customer_name">
        <mat-header-cell *matHeaderCellDef>Company</mat-header-cell>
        <mat-cell *matCellDef="let account">
          <span> {{ account.customer_name }} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
        <mat-cell *matCellDef="let account">
          <mat-select disableOptionCentering
            [disabled]="!roleEditingAllowed(account)"
            placeholder="Workspace role"
            [(value)]="account.workspace_permissions_level"
            (selectionChange)="updatePermissions(account)"
          >
            <mat-option
              [disabled]="!roleEditingAllowed(account)"
              *ngFor="let role of roles"
              [value]="role.permissions_level"
              [matTooltip]="role.role_desc"
            >
              {{ role.role_display_name }}
            </mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell class="actions" *matHeaderCellDef>Access</mat-header-cell>
        <mat-cell class="actions toRight" *matCellDef="let account">
          <mat-slide-toggle
            [disabled]="!accessEditingAllowed(account)"
            [checked]="account.access_enabled"
            color="primary"
            (change)="toggleUserAccess($event, account)"
          ></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <div mat-dialog-actions><button color="primary" type="button" mat-button [mat-dialog-close] >Close</button></div>
</div>
