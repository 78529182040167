<h2 *ngIf="!isDashboardWidget" mat-dialog-title>
  <mat-icon>{{ styleBuilder ? 'palette' : 'build' }}</mat-icon>
  {{ styleBuilder ? 'Style' : 'Query' }} Builder
</h2>

<div mat-dialog-content>
  <div class="query-builder-form">
    <div class="row header">
      <mat-form-field>
        <input matInput placeholder="{{ styleBuilder ? 'Style' : 'Query' }} name" [(ngModel)]="queryName" name="query_name" #queryValue/>
      </mat-form-field>
      <mat-form-field  *ngIf="!isDashboardWidget" >
        <mat-select disableOptionCentering placeholder="Layer" name="query_layer" [(ngModel)]="queryTarget" [disabled]="styleBuilder"
          (selectionChange)="generateFilters($event.value)">

          <ng-container *ngFor="let layer of layers">
            <mat-optgroup *ngIf="layer.groupName; else optionTemp" [label]="layer.groupName">
              <mat-option *ngFor="let target of layer.targets" [value]="target" [disabled]="target.expectingSpatialQuery"
                [matTooltip]="target.expectingSpatialQuery ?
                'The attribute table has no data to query on currently. Please select a spatial query to populate the attribute table' : ''">
                {{getOptionName(target)}}
              </mat-option>
            </mat-optgroup>

            <ng-template #optionTemp>
              <mat-option [value]="layer" [disabled]="layer.expectingSpatialQuery"
                [matTooltip]="layer.expectingSpatialQuery ?
                'The attribute table has no data to query on currently. Please select a spatial query to populate the attribute table' : ''">
                {{ layer.title }}
              </mat-option>
            </ng-template>
          </ng-container>

        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="queryObj?.targetRef">
    <div class="query-body">
      <div class="query-block" *ngFor="let block of queryObj?.query.blocks; let i = index">
        <div class="block-icon" [class.first]="i === 0">
          <mat-icon *ngIf="i === 0" class="icon" [matMenuTriggerFor]="blockCtx" matTooltip="Blocks">
            list_alt
          </mat-icon>
          <div *ngIf="i > 0 && queryObj?.query.operator === 'AND'" class="icon textual" [matMenuTriggerFor]="blockCtx" matTooltip="Remove block">
            AND
          </div>
          <div *ngIf="i > 0 && queryObj?.query.operator === 'OR'" class="icon textual" [matMenuTriggerFor]="blockCtx" matTooltip="Remove block">
            OR
          </div>
          <div class="connector"></div>
          <mat-menu #blockCtx="matMenu" xPosition="after">
            <button mat-menu-item (click)="deleteBlock(i)">
              Remove block
            </button>
          </mat-menu>
        </div>
        <div class="block-content">
          <div class="statement" *ngFor="let statement of block.statements; let k = index">
            <mat-icon *ngIf="k === 0" class="icon" [matMenuTriggerFor]="nodeCtx" matTooltip="Statements">
              search
            </mat-icon>
            <div *ngIf="k > 0 && block.operator === 'AND'" class="icon textual" [matMenuTriggerFor]="nodeCtx" matTooltip="Remove node">
              AND
            </div>
            <div *ngIf="k > 0 && block.operator === 'OR'" class="icon textual" [matMenuTriggerFor]="nodeCtx" matTooltip="Remove node">
              OR
            </div>
            <mat-menu #nodeCtx="matMenu" xPosition="after">
              <button mat-menu-item (click)="deleteStatement(i, k)">
                Remove node
              </button>
            </mat-menu>
            <div class="fields">
              <mat-form-field floatLabel="never">
                <mat-select disableOptionCentering [(ngModel)]="statement.attribute" placeholder="Attribute" (selectionChange)="checkAttributeType(statement.attribute)">
                  <mat-option *ngFor="let attribute of availableColumns" [value]="attribute.prop">
                    {{ attribute.override }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never">
                <mat-select disableOptionCentering [(ngModel)]="statement.operator" placeholder="Operator" (openedChange)="checkAttributeType(statement.attribute)">
                  <mat-option *ngFor="let operator of availableOperators" [value]="operator.value" [disabled]="operator.disabled">
                    {{operator.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never" *ngIf="statement.operator!=='notEmpty' && statement.operator!=='isEmpty'">
                <input matInput placeholder="Value" [(ngModel)]="statement.value" />
              </mat-form-field>
            </div>
          </div>
          <div class="statement add">
            <mat-icon *ngIf="block.statements.length <= 1" class="icon" [matMenuTriggerFor]="newNodeCtx" matTooltip="Add statement node">add</mat-icon>
            <mat-icon *ngIf="block.statements.length > 1" class="icon" (click)="addStatement(i)" matTooltip="Add statement node">add</mat-icon>
            <mat-menu #newNodeCtx="matMenu" xPosition="after">
              <button mat-menu-item (click)="addStatement(i, 'AND')">
                AND node
              </button>
              <button mat-menu-item (click)="addStatement(i, 'OR')">
                OR node
              </button>
            </mat-menu>
          </div>
        </div>
      </div>

      <div class="query-block add">
        <mat-icon *ngIf="queryObj?.query.blocks.length <= 1" class="icon" [matMenuTriggerFor]="newBlockCtx" matTooltip="Add query block">add</mat-icon>
        <mat-icon *ngIf="queryObj?.query.blocks.length > 1" class="icon" (click)="addBlock()" matTooltip="Add query block">add</mat-icon>
        <mat-menu #newBlockCtx="matMenu" xPosition="after">
          <button mat-menu-item (click)="addBlock('AND')">AND block</button>
          <button mat-menu-item (click)="addBlock('OR')">OR block</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isDashboardWidget" class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>CANCEL</button>
  </div>
  <div class="right">
    <button color="primary" mat-raised-button (click)="saveQuery()" [disabled]="isCreateSaveDisabled(queryObj)">{{queryObj?.query_id ? 'SAVE' : 'CREATE'}}</button>
  </div>
</div>
