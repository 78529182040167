import { Component } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { WorkspacesService } from '@services/explorer'

@Component({
  selector: 'app-user-footer',
  templateUrl: './user-footer.component.html',
  styleUrls: ['./user-footer.component.scss']
})

export class UserFooterComponent {

  get pagination () {
    return this._workspacesService.pagination
  }

  constructor (
    private _workspacesService: WorkspacesService
    ) { }

  onPageEvent (e: PageEvent) {
    this.pagination.pages.curr = e.pageIndex
    this.pagination.query.limit = e.pageSize
    this._workspacesService.updatePage()
  }
}
