<h2 mat-dialog-title>Composite Geometry Editor</h2>

<div mat-dialog-content>
  <app-note *ngIf="data.warning">{{data.warning}}</app-note>
  <div class="error-list">
    <p *ngFor="let error of errors" class="st-error">
      {{error}}
    </p>
  </div>

  <div class="table-actions">
    <button mat-icon-button color="primary" (click)="addColumn()" matTooltip="Add new composite column.">
      <mat-icon>add</mat-icon>
    </button>
  </div>


  <form [formGroup]="form">
    <mat-form-field>
      <mat-select disableOptionCentering placeholder="Destination Layer" formControlName="layer" [disabled]="!layers.length"
      (selectionChange)="setDestination($event)">
        <ng-container *ngFor="let layer of layers">
            <mat-option [value]="layer">
              {{ layer.title }}
            </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="form._controls.layer.value === inputLayer">
      <input matInput formControlName="newLayer" placeholder="Layer Name">
    </mat-form-field>

    <div class="wkt-field">
      <mat-form-field>
        <textarea matInput formControlName="geometry" cols="40" rows="3" placeholder="WGS84 'Well Known Text (WKT)' point geometry"></textarea>
        <mat-error *ngIf="form._controls.geometry.hasError('wktValid')">
          {{form._controls.geometry['errors'] && form._controls.geometry['errors']['wktValid']['message']}}
        </mat-error>
      </mat-form-field>

      <app-wkt-preview #preview [wkt]="form._controls.geometry.value"></app-wkt-preview>
    </div>

    <mat-form-field *ngIf="canReplace">
      <mat-select disableOptionCentering placeholder="Geometry Action" formControlName="replace">
        <mat-option [value]="true">
          Replace Selected Layer Geometries
        </mat-option>
        <mat-option [value]="false">
          Insert New Geometry
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="st-table-overflow" *ngIf="columnData?.data.length > 0; else nodata">

    <mat-table [dataSource]="columnData" matSort>
      <ng-container matColumnDef="name" sticky>
        <mat-header-cell *matHeaderCellDef >Display Name</mat-header-cell>
        <mat-cell *matCellDef="let column">
           <mat-form-field>
              <input matInput [formControl]="column.name">
            </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="prop" sticky>
        <mat-header-cell *matHeaderCellDef >Raw Name</mat-header-cell>
        <mat-cell *matCellDef="let column">
           <mat-form-field>
              <input matInput [formControl]="column.prop" (change)="compSelectionChange(column)">
            </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type" sticky>
        <mat-header-cell *matHeaderCellDef >Type</mat-header-cell>
        <mat-cell *matCellDef="let column">
          <mat-select disableOptionCentering [formControl]="column.type" (selectionChange)="typeChange(column)">
            <mat-option *ngFor="let type of availableTypes" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <ng-container *ngFor="let src of compositeSources" [matColumnDef]="src.prop">
        <mat-header-cell *matHeaderCellDef >{{src.title}}</mat-header-cell>
        <mat-cell *matCellDef="let column">
          <mat-select disableOptionCentering [formControl]="column[src.prop]" (selectionChange)="compSelectionChange(column)">
            <mat-option [value]="undefined">
              None
            </mat-option>
            <mat-option *ngFor="let attrCol of src.columns" [value]="attrCol.prop">
              {{ attrCol.override || attrCol.prop }}
            </mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="aggregation" stickyEnd>
        <mat-header-cell *matHeaderCellDef >Aggregation</mat-header-cell>
        <mat-cell *matCellDef="let column">
          <mat-select disableOptionCentering [formControl]="column.aggregation" *ngIf="column.aggregation"
          (selectionChange)="aggregationChange(column)">
            <mat-option *ngFor="let opt of aggregationOptions" [value]="opt" class="capitalize">
              {{ opt }}
            </mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="value" stickyEnd>
        <mat-header-cell *matHeaderCellDef >Value Preview</mat-header-cell>
        <mat-cell *matCellDef="let column">
           <mat-form-field class="value-input">
              <input matInput [formControl]="column.value">
              <ng-container *ngIf="column.prop.value && columnValueOptions[column.prop.value]?.length">
                <button mat-icon-button [matMenuTriggerFor]="valueOptions">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #valueOptions="matMenu">
                  <button mat-menu-item
                  *ngFor="let opt of columnValueOptions[column.prop.value]"
                  (click)="setColValue(column, opt)">
                   {{opt}}
                  </button>
                </mat-menu>
              </ng-container>
            </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell class="actions" *matHeaderCellDef ></mat-header-cell>
        <mat-cell class="actions" *matCellDef="let column">
          <button *ngIf="column.prop.enabled" mat-icon-button (click)="removeColumn(column)">
              <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnDataVisibleCols; sticky: stickyHeader"></mat-header-row>

      <mat-row *matRowDef="let row; columns: columnDataVisibleCols"></mat-row>
    </mat-table>
  </div>

  <ng-template #nodata>
    <app-note>
        <p>No columns defined.</p>
    </app-note>
  </ng-template>
</div>

<div class="st-button-row">
  <button color="primary" mat-button mat-dialog-close>CANCEL</button>
  <button color="primary" mat-button [disabled]="!valid" (click)="save()">
      CREATE
  </button>
</div>

<app-loader-backdrop *ngIf="saving" loadingText="Saving composite..."></app-loader-backdrop>
