import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { VipApiService } from '@services/core'
import { FormTemplate } from '@core/models/form-template'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import * as moment from 'moment'
import AppError, { handleError } from '@core/models/app-error'
import { IPNewPVGuide } from '@vip-shared/interfaces'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'

@Component({
  selector: 'app-new-pv-guide-dialog',
  templateUrl: './new-pv-guide-dialog.component.html',
  styleUrls: ['./new-pv-guide-dialog.component.scss']
})
export class NewPvGuideDialogComponent extends FormTemplate<UntypedFormGroup> {
  today = new Date()
  date = new Date()

  constructor (
    protected _dialogRef: MatDialogRef<NewPvGuideDialogComponent>,
    private _api: VipApiService
  ) {
    super(
      new UntypedFormGroup({
        name: new UntypedFormControl(undefined, Validators.required),
        location_url: new UntypedFormControl(undefined, [
          Validators.required,
          Validators.pattern(/^https?:\/\/(www\.)?/i)
        ]),
        version: new UntypedFormControl(undefined, [
          Validators.required,
          Validators.pattern(/^[0-9]+((.[0-9]+)+)?$/)
        ]),
        date: new UntypedFormControl({
          value: new Date()
        }, Validators.required)
      }),
      _dialogRef
    )
  }

  dateChange (e: MatDatepickerInputEvent<Date>) {
    this.date = e.value as Date
    this.form.controls.date.setValue(this.date)
  }

  async save () {
    if (this.saving) return
    this.saving = true

    try {
      this.errorMessage = undefined
      if (!this.form.valid) throw new AppError('Form invalid.')
      this.toggleForm(false)

      const controls = this.form.controls
      const body: IPNewPVGuide = {
        date: moment(controls.date.value).toISOString(),
        location_url: controls.location_url.value,
        name: controls.name.value,
        version: controls.version.value
      }

      const newGuide = await this._api.orm.Products().PropertyView()
      .Guides().create(body).run()

      this.saving = false
      this.submitted = true

      this._dialogRef.close(newGuide)
    } catch (error: any) {
      this.saving = false
      handleError(error)
      this.errorMessage = error.message
      this.toggleForm(true)
    }
  }

}
