<form [formGroup]="form">
  <mat-form-field>
    <mat-select disableOptionCentering formControlName="presetName" placeholder="Existing Dataset" (selectionChange)="presetChange($event.value)">
      <mat-option *ngFor="let preset of layerPresets" [value]="preset.layer_preset_tag" class="st-capitalize" [matTooltip]="preset.description">
        {{preset.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="scraperForm">
    <app-note>
      Please note that it will take around 5min to 15min for layer to be populated with data, if results are found. If there are no results for query, layer will stay empty.
    </app-note>
    <form [formGroup]="scraperForm">
      <mat-form-field>
        <input matInput formControlName="name" placeholder="Scraper Name">
      </mat-form-field>

      <app-chips-input formControlName="where_exact" placeholder="Must contain words/phrases">
      </app-chips-input>

      <app-chips-input formControlName="where_has" placeholder="Might contains words" type='word'>
      </app-chips-input>

      <app-chips-input formControlName="where_exclude" placeholder="Exclude words" type='word'>
      </app-chips-input>

      <app-note *ngIf="locationWarning" color="warn">{{locationWarning}}</app-note>
      <app-locations-input formControlName="locations">
      </app-locations-input>

      <app-date-ranges-input [backDateLimit]="backDateDays" formControlName="dateRanges">
      </app-date-ranges-input>

      <div class="st-mat-field-pair" *ngIf="scraperForm?._controls.intervalValue && scraperForm?._controls.interval">
        <mat-form-field>
          <input matInput type="number" formControlName="intervalValue" placeholder="Interval Value">
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="interval">
            <mat-option *ngFor="let interval of timeIntervals" [value]="interval">
                {{interval}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </ng-container>
</form>

<app-loader-backdrop *ngIf="fetchingData" loadingText="Fetching settings..."></app-loader-backdrop>
