import { DroneConvertUtil } from '@core/utils/convert/drone-convert.util'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import Geojson from 'ol/format/GeoJSON'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { IUrlLayerParams } from '@core/types/'
import { Validators } from '@angular/forms'


export default class NewLayerUtil {
  static knownLayers = {
    noaa: {
      wmts: {
        regex: new RegExp('^https://stormscdn\\.ngs.noaa\\.gov/([^/]*)$', 'i'),
        capabilitiesRegex: new RegExp(
          '^https://storms\\.ngs\\.noaa\\.gov/storms/tiles[^/]*/services/tileserver.php/wmts$|https://storms\\.ngs\\.noaa\\.gov/storms/[a-z,0-9]*/services/WMTSCapabilities\\.xml$',
          'i'
        ),
        defaults: {
          tile_order: 'zxy',
          wms_version: '1.0.0'
        } as IUrlLayerParams,
        fields: {
          tile_order: true,
          wms_version: true,
          capabilities_url: true
        },
        validators: {
          wms_version: [
            Validators.required,
            Validators.pattern(/^[1]\.[0,1,3]\.[0,1]$/)
          ],
          tile_order: [
            Validators.required,
            Validators.pattern(/^[z,y,x]{3}$/)
          ],
          capabilities_url: [
            Validators.required,
            Validators.pattern(new RegExp(
              '^https://storms\\.ngs\\.noaa\\.gov/storms/tiles[^/]*/services/tileserver.php/wmts$|https://storms\\.ngs\\.noaa\\.gov/storms/[a-z,0-9]*/services/WMTSCapabilities\\.xml$',
              'i'
            ))
          ]
        }
      }
    },
    geoserver: {
      wms: {
        regex: new RegExp('^https?:\/\/[^\/]*\/geoserver\/([^\/]*)\/wms$', 'i'),
        wmsDefVersion: '1.3.0',
        defaults: {
          wms_version: '1.3.0'
        } as IUrlLayerParams,
        fields: {
          wms_version: true,
          layer_name: true
        },
        validators: {
          wms_version: [
            Validators.required,
            Validators.pattern(/^[1]\.[0,1,3]\.[0,1]$/)
          ],
          layer_name: [
            Validators.required
          ]
        }
      }
    },
    arcgis: {
      tiles: {
        regex: new RegExp(
          '^https://tiles\\.arcgis\\.com/tiles/[a-z,0-9]*/arcgis/rest/services/([^/]*)/MapServer$',
          'i'
        ),
        defaults: {
          tile_order: 'zyx'
        } as IUrlLayerParams,
        fields: {
          tile_order: true
        },
        validators: {
          tile_order: [
            Validators.required,
            Validators.pattern(/^[z,y,x]{3}$/)
          ]
        }
      }
    },
    psql: {
      geojson: {
        defaults: {} as IUrlLayerParams,
        fields: {}
      }
    },
    external_wms: {
      wms: {
        regex: new RegExp('^https?://[-A-Za-z0-9+&@#/%?=~_|!:,.;]*/wms$', 'i'),
        wmsDefVersion: '1.3.0',
        defaults: {
          wms_version: '1.3.0'
        } as IUrlLayerParams,
        fields: {
          wms_version: true,
          layer_name: true,
          api_key: true,
          username: true,
          password: true
        },
        validators: {
          wms_version: [
            Validators.required,
            Validators.pattern(/^[1]\.[0,1,3]\.[0,1]$/)
          ],
          layer_name: [
            Validators.required
          ],
          api_key: [Validators.nullValidator],
          username: [Validators.nullValidator],
          password: [Validators.nullValidator]
        }
      }
    }
  }

  static createDroneVectorSource (
    lineGeojson: {[key: string]: any},
    pointsGeojson: {[key: string]: any},
    startIndex: number = 0,
    showTrimmedEnds: boolean = false
  ): VectorSource {
    const telemetry = DroneConvertUtil.readTelemetry(pointsGeojson)
    const { geojson, hiddenGeojson } = DroneConvertUtil.trimFeatures(telemetry, lineGeojson, startIndex)
    const layerSource = new VectorSource({})
    // 29/08/2018 NOTE: Some vector layers seem to call change only when it's added before
    // loading features, some only after adding features. So we have one event listener
    // in beginning, on in end. Cause of this - unknown, maybe a race condition?
    layerSource.once('change', e => {
      layerSource.setProperties({ loaded: true })
    })

    const trimmedPath = new Geojson().readFeatures(geojson, {
      dataProjection: undefined,
      featureProjection: 'EPSG:3857'
    })

    let hiddenPath: Feature[] | undefined
    if (showTrimmedEnds) {
      hiddenPath = new Geojson().readFeatures(hiddenGeojson, {
        dataProjection: undefined,
        featureProjection: 'EPSG:3857'
      })

      hiddenPath[0].setStyle(new Style({
        stroke: new Stroke({
          width: 3,
          lineDash: [1, 10]
        }),
        zIndex: -1
      }))
    }

    if (hiddenPath) layerSource.addFeatures(hiddenPath)
    layerSource.addFeatures(trimmedPath)

    return layerSource
  }

}
