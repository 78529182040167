import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

import { Subject, Subscription } from 'rxjs'
import Feature from 'ol/Feature'
import VectorLayer from 'ol/layer/Vector'
import { AppLayerGroup, AppLayerGeneric } from '@core/models/layer'
import {
  IQueryWRef, IQueryGroup
} from '@core/models/query-object'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { LayerService } from '../layer/layer.service'
import { LayerLoadingService } from '../layer/loading/layer-loading.service'
import { WorkspaceService } from '../workspace.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AppLayer } from '@core/models/layer/app-layer'
import { handleError } from '@core/models/app-error'
import { AlertService, PromptService} from '@services/core'
import { Db } from '@vip-shared/models/db-definitions'
import { IFeatureSystemProperties, IFeatureCustomColor } from '@core/types'
import { ICreditDetails, IPNewQuery, IPNumEntriesCheck } from '@vip-shared/interfaces'
import { debounceTime } from 'rxjs/operators'
import * as turf from '@turf/turf'
import GeometryCollection from 'ol/geom/GeometryCollection'
import * as olGeom from 'ol/geom'
import OlUtil from '@core/utils/ol/ol.util'
import { clientDataThreshold, csvRowThreshold, layerAlertThreshold } from '@vip-shared/models/const/data-limits'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { WorkspaceMapService } from '@services/workspace'

type QueryableLayer = AppLayer | {
  groupName: string
  targets: (AppLayer | AppLayerGroup)[]
}

@Injectable({
  providedIn: 'root'
})

export class LayerQueriesService implements DialogCleanup {
  private _subscriptions = new Subscription()
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog(dialog: MatDialogRef<any>): any { return }
  _untrackDialog(dialog: MatDialogRef<any>): any { return }
  _destroyDialogs(): any { return }

  queryableLayers?: QueryableLayer[]

  private _queryableLayersSubscriptions = new Subscription()

  private _groupedQueryList: IQueryGroup[] = []
  get groupedQueries(): IQueryGroup[] {
    return this._groupedQueryList
  }

  private _queryListChanged = new Subject<IQueryGroup[]>()
  get queryListChanged() {
    return this._queryListChanged.asObservable()
  }

  private _queryList: IQueryWRef[] = []
  get queries(): IQueryWRef[] {
    return this._queryList
  }

  editingChanged = new Subject<boolean>()
  editing = false

  private _filterChange = new Subject<AppLayer>()
  get filterChange() {
    return this._filterChange.asObservable()
  }

  private get _queriesApi() {
    return this._workspaceService.viewOrm.Queries()
  }

  constructor(
    private _layerService: LayerService,
    private _layerLoadingService: LayerLoadingService,
    private _workspaceService: WorkspaceService,
    private _api: VipApiService,
    private _alertService: AlertService,
    private _promptService: PromptService,
    private _workspaceMapService : WorkspaceMapService
  ) {
    this._workspaceService.onExit.subscribe(() => this.CleanupService())
  }

  private CleanupService() {
    this._destroyDialogs()
    this.editMode(false)

    this._subscriptions.unsubscribe()
    this._subscriptions = new Subscription()

    this.queryableLayers = []
    this._queryList = []
  }

  init() {
    // Update queryable layer list on layer list change
    this._subscriptions.add(
      this._layerService.layerArrayChanged.pipe(
        debounceTime(100)
      ).subscribe(layers => {
        this._queryableLayersSubscriptions.unsubscribe()
        this._queryableLayersSubscriptions = new Subscription()

        const queryable = (x: AppLayer) => x.vector && x.typeId !== Db.Vip.SourceType.DRONE_VECTOR

        this.queryableLayers = layers.reduce((arr, x) => {
          if (x instanceof AppLayerGroup) {
            const entry: QueryableLayer = {
              groupName: x.title,
              targets: x.layers.filter(queryable)
            }
            if (x.timeSeries) {
              entry.targets.splice(0, 0, x)
            }

            for (const target of entry.targets) {
              if (target instanceof AppLayerGroup) {
                this._queryableLayersSubscriptions.add(
                  target.layerRemoved.subscribe(l => l.removeStyleQueries(
                    this.getTargetQueries(target, ['style']).map(x => x.query_id)
                  ))
                )

                this._queryableLayersSubscriptions.add(
                  target.layersChanged.subscribe(l => this.ApplyActiveQueries(undefined, target))
                )
              }
            }
            arr.push(entry)
          } else if (queryable(x)) arr.push(x)

          return arr
        }, [] as QueryableLayer[])
      })
    )

    // After layers have finished loading, load queries for same workspace
    this._subscriptions.add(
      this._layerLoadingService.layersLoading.subscribe(async loading => {
        if (!loading.isLoading) {
          // when finished loading
          await this.GetQueries()
          this.ApplyActiveQueries('style')
          this.ApplyActiveQueries('filter')
        }
      })
    )
  }

  editMode(mode: boolean) {
    this.editing = mode
    this.editingChanged.next(this.editing)
  }

  async updateQueries() {
    await this.GetQueries()
  }

  private async GetQueries() {
    const queries = await this._queriesApi.get().run() || []

    // Match every query with a layer
    this._queryList = queries.map((x, i) => ({
      ...x,
      targetRef: x.layer_group_id ? this._layerService.groups.find(y => y.id === x.layer_group_id) :
        this._layerService.allLayers.find(y => y.id === x.layer_id),
      index: i,
      geometry: x.geometry ?
        OlUtil.wktToFeature(x.geometry).getGeometry() as olGeom.Polygon | olGeom.MultiPolygon :
        undefined
    } as IQueryWRef))
      // Discard queries without any layer reference
      .filter(i => !!i.targetRef)

    this.OnQueryArrayChange()
    this.ApplyActiveQueries()
  }

  private OnQueryArrayChange() {
    // Recalculate/regroup layer query groups
    const layerGroups: IQueryGroup[] = []
    for (const query of this.queries) {
      let group = layerGroups.find(x => x.id === query.targetRef.id)

      if (!group) {
        group = {
          id: query.targetRef.id,
          name: query.targetRef.title,
          queries: []
        }

        layerGroups.push(group)
      }

      group.queries.push(query)
    }
    this._groupedQueryList = layerGroups
    // Emit event for query array change
    this._queryListChanged.next(this._groupedQueryList)
  }

  private rasterSpatialQuery(query: IQueryWRef): boolean {
    return (
      !!(query.targetRef instanceof AppRasterLayer) &&
      !!query.targetRef.preset &&
      this._workspaceService.isEmissions()
    )
  }


  private async ApplyActiveQueries(type?: 'style' | 'filter' | 'spatial', target?: AppLayerGeneric) {
    for (const query of this._queryList) {
      if (!query.applied || (type && type !== query.type) || (target && query.targetRef !== target)) continue
      this.toggleQuery(query, true)
    }
  }

  async toggleQuery(queryObj: IQueryWRef, active: boolean, forceSave = false, safeQuery?: boolean) {
    if (active) this.ParseQueryNumbers(queryObj)
    const save = queryObj.applied !== active || !queryObj.query_id || forceSave
    queryObj.applied = active
    const emissionQuery = this.rasterSpatialQuery(queryObj)
    if (save) {
      queryObj = await this.saveQuery(queryObj, safeQuery)
      if ((queryObj.type === 'spatial' || queryObj.type === 'filter') && !emissionQuery) {
        await queryObj.targetRef.reload()
      }
    }

    if (queryObj.type === 'style') {
      await this.ToggleStyleQuery(queryObj, active)
    } else if (queryObj.type === 'filter' || queryObj.type === 'spatial' && !emissionQuery) {
      await this.ToggleQueryFlag(queryObj, active)
    } else if (queryObj.type === 'enhanced') {
      this.ToggleAdvancedQuery(queryObj)
    } else if (emissionQuery && queryObj.raster_data) {
      if(active && queryObj.targetRef.visible) {
        this._workspaceMapService.addRasterSpatialQueryFeature(queryObj)
      } else {
        this._workspaceMapService.removeRasterSpatialQueryFeature(queryObj)
      }
    }
    return queryObj.query_id
  }

  private UpdateLayerFilteredFlag(layer: AppLayer | AppLayerGroup) {
    layer.filtered = this._queryList.some(q =>
      ['spatial', 'filter'].includes(q.type) && q.targetRef === layer && q.applied
    )
  }

  private ToggleAdvancedQuery(queryObj: IQueryWRef) {
    const ref = queryObj.targetRef
    if (!ref) return
    this.UpdateLayerFilteredFlag(ref)
    const layers = this.GetLayersFromGeneric(queryObj.targetRef)

    const triggerVectorsUpdate = () => {
      for (const layer of layers) {
        if (this._layerService.selected) {
          if (this._layerService.selected.id === layer.id) this._filterChange.next(layer)
        }
        const vector = layer.layer as VectorLayer<any>
        // TODO: This might now remove features refresh()
        if (vector) vector.getSource().changed()
      }
    }
    triggerVectorsUpdate()

  }

  private async ToggleQueryFlag(queryObj: IQueryWRef, active: boolean) {
    const ref = queryObj.targetRef
    if (!ref) return
    this.UpdateLayerFilteredFlag(ref)
    if (this._workspaceService.isPropertyView()) this.FilterPropertiesLegacy(queryObj, active, ref)
  }

  // NOTE: only kept for property view layers
  private FilterPropertiesLegacy (queryObj: IQueryWRef, active: boolean, ref: AppLayer | AppLayerGroup) {
    if (ref instanceof AppLayer && !ref.layer) return

    const removeQueryOn = (features: Feature[]) => {
      for (const f of features) {
        const props = f.getProperties() as IFeatureSystemProperties
        let sysProps = props._system
        if (!sysProps) sysProps = { filteredOutBy: [] }
        if (!sysProps.filteredOutBy) sysProps.filteredOutBy = []
        const index = sysProps.filteredOutBy.indexOf(queryObj.query_id)
        if (index >= 0) {
          sysProps.filteredOutBy.splice(index, 1)
          f.set('_system', sysProps)
        }
      }
    }

    const layers = this.GetLayersFromGeneric(queryObj.targetRef)
    const features = queryObj.targetRef.features

    const triggerVectorsUpdate = () => {
      for (const layer of layers) {
        if (this._layerService.selected) {
          if (this._layerService.selected.id === layer.id) this._filterChange.next(layer)
        }
        const vector = layer.layer as VectorLayer<any>
        // TODO: This might now remove features refresh()
        vector.getSource().changed()
      }
    }
    if (!active) {
      removeQueryOn(features)
      triggerVectorsUpdate()
      return
    }

    const { passing, failing } = this.getFilteredFeatures(queryObj, features)

    removeQueryOn(passing)

    for (const f of failing) {
      const props = f.getProperties() as IFeatureSystemProperties
      let sysProps = props._system
      if (!sysProps) sysProps = { filteredOutBy: [] }
      if (!sysProps.filteredOutBy) sysProps.filteredOutBy = []

      const index = sysProps.filteredOutBy.indexOf(queryObj.query_id)
      if (index < 0) {
        sysProps.filteredOutBy.push(queryObj.query_id)
      }

      f.set('_system', sysProps)
    }

    triggerVectorsUpdate()
  }

  private GetLayersFromGeneric(source: AppLayerGeneric) {
    return source instanceof AppLayer ? [source] : source.timeSeriesLayers
  }

  private async ToggleStyleQuery(queryObj: IQueryWRef, active: boolean) {
    const ref = queryObj.targetRef
    if (!ref || (ref instanceof AppLayer && !ref.layer)) return

    const layers = this.GetLayersFromGeneric(queryObj.targetRef)

    const removeStyleOn = (features: Feature[]) => {
      for (const f of features) {
        const props = f.getProperties() as IFeatureSystemProperties
        if (!props.customColors) {
          props.customColors = {
            fill: [],
            border: []
          }
        }

        for (const key in props.customColors) {
          const styleWithId: undefined | IFeatureCustomColor = props.customColors[key].find(
            (cC: any) => cC.id === queryObj.query_id
          )

          if (styleWithId) styleWithId.active = false
        }

      }
      for (const l of layers) l.syncStyle(true, true)
    }
    const features = queryObj.targetRef.features

    if (!active) {
      removeStyleOn(features)
      return
    }

    const { passing, failing } = this.getFilteredFeatures(queryObj, features)

    removeStyleOn(failing)

    for (const key in queryObj.query.customColor) {
      const gradient = queryObj.query.customColor[key].gradient
      const color = queryObj.query.customColor[key].color

      if (gradient && gradient.active) {
        try {
          for (const l of layers) {
            l.applyGradient(
              gradient.column,
              gradient.steps,
              key as 'fill' | 'border',
              passing,
              // TODO: Check if this is possibly undefined
              queryObj.query_id,
              queryObj.index,
              queryObj.query.customColor[key].opacity,
              gradient.range
            )
          }
        } catch (error: any) {
          handleError(error)
          this._alertService.log(error.message)
          return
        }

      } else if (color && color.active) {
        for (const f of passing) {
          const properties = f.getProperties()
          if (!properties.customColors) {
            properties.customColors = {
              fill: [],
              border: []
            }
          }

          const styleWithId: IFeatureCustomColor | undefined = properties.customColors[key].find(
            (cC: any) => cC.id === queryObj.query_id
          )

          if (!styleWithId) {
            (properties.customColors[key] as IFeatureCustomColor[]).push({
              id: queryObj.query_id,
              color: color.value,
              active: true,
              order: queryObj.index
            })
            properties.customColors[key] = properties.customColors[key].sort((a, b) => a.order > b.order ? 1 : -1)
          } else {
            styleWithId.active = true
            styleWithId.color = color.value
          }
          f.set('customColors', properties.customColors)
        }

        for (const l of layers) l.syncStyle(true, true)
      }
    }
  }

  getFilteredFeatures(queryObj: IQueryWRef, features?: Feature[]) {
    const passing: Feature[] = []
    const failing: Feature[] = []

    if (!features) features = queryObj.targetRef.features
    for (const f of features) {
      let pass = true
      if (queryObj.type === 'spatial') {
        pass = this.FeatureInsideGeometry(queryObj.geometry, f, !!queryObj.query.includeEdge)
      } else {
        pass = this.FeaturePassesQuery('block', queryObj.query.operator, queryObj.query.blocks, f)
      }

      if (pass) passing.push(f)
      else failing.push(f)
    }

    return { passing, failing }
  }



  private FeatureInsideGeometry(geometry: olGeom.MultiPolygon | olGeom.Polygon, f: Feature, edgeInclude: boolean) {
    const boundary = turf.geometry(geometry.getType(), geometry.getCoordinates()) as turf.Polygon | turf.MultiPolygon

    const polygons = boundary.type === 'Polygon' ? [boundary] : boundary.coordinates.map(pol => turf.geometry('Polygon', pol))
    const featureGeom = f.getGeometry()
    if (!featureGeom) return false

    const overlaps = (
      pol: turf.Polygon,
      geom: olGeom.Point | olGeom.LineString | olGeom.Polygon
    ) => {
      const geojsonGeom: any = turf.geometry(geom.getType(), geom.getCoordinates())
      let pass = turf.booleanContains(pol, geojsonGeom)
      if (!pass && edgeInclude) {
        if (geojsonGeom.type.includes('Polygon')) {
          pass = turf.booleanOverlap(pol, geojsonGeom)

        } else if (geojsonGeom.type.includes('Line')) {
          // For some reason line overlap/cross do not work as expected,
          // therefore try to create polygon out of line
          let lineToPol: turf.Polygon | turf.MultiPolygon
          const lineCoordsToPol = (coords: [number, number][]) => (turf.lineToPolygon(
            turf.lineString([...coords, ...([...coords].reverse())])
          ).geometry as turf.Polygon)

          if (geojsonGeom.type === 'MultiLineString') {
            console.warn(`Spatial Query on MultiLineString detected. It has not been properly tested and can possibly break.`)
            lineToPol = turf.multiPolygon(geojsonGeom.coordinates.map(coords =>
              lineCoordsToPol(coords).coordinates
            )).geometry as turf.MultiPolygon

          } else {
            lineToPol = lineCoordsToPol(geojsonGeom.coordinates)
          }

          pass = turf.booleanOverlap(pol, lineToPol)

        } else if (geojsonGeom.type.includes('Point')) {
          const line = turf.polygonToLineString(pol).geometry as turf.LineString
          pass = turf.booleanPointOnLine(geojsonGeom, line)
        }

      }
      return pass
    }

    const oneGeomOverlaps = (pol: turf.Polygon, geom: olGeom.Geometry) => {
      if (geom instanceof GeometryCollection) {
        return geom.getGeometries().some(g => oneGeomOverlaps(pol, g))
      } else if (geom instanceof olGeom.MultiPolygon) {
        return geom.getPolygons().some(g => oneGeomOverlaps(pol, g))
      } else if (geom instanceof olGeom.MultiLineString) {
        return geom.getLineStrings().some(g => oneGeomOverlaps(pol, g))
      } else if (geom instanceof olGeom.MultiPoint) {
        return geom.getPoints().some(g => oneGeomOverlaps(pol, g))
      }

      return overlaps(pol, geom as any)
    }

    return polygons.some(pol => oneGeomOverlaps(pol, featureGeom))
  }

  private FeaturePassesQuery(level: 'block' | 'statement', operator: 'AND' | 'OR' | string, items: any[], feature: Feature) {
    if (items.length === 0) return true
    return items[operator === 'OR' ? 'some' : 'every'](item => {
      if (level === 'block') {
        return this.FeaturePassesQuery('statement', item.operator, item.statements, feature)
      } else {
        const featureAttr = this.GetAttributeFromFeature(
          feature,
          item.attribute
        )
        return this.Comparator(
          item.operator,
          featureAttr,
          item.value
        )
      }
    })
  }

  private GetAttributeFromFeature(feature: Feature, attribute: string) {
    if (attribute) {
      return feature.getProperties()[attribute]
    }
  }

  private Comparator(operator: string, toCompare: any, compareTo: any) {
    let returnVal: any
    compareTo = (operator === 'equal' || operator === 'not') && typeof toCompare === 'boolean' ?
      compareTo === 'true' ? true : false
      : compareTo
    switch (operator) {
      /* eslint-disable eqeqeq */
      case 'equal': {
        returnVal = toCompare == compareTo
        break
      }
      case 'greater': {
        returnVal = toCompare > compareTo
        break
      }
      case 'less': {
        returnVal = toCompare < compareTo
        break
      }
      case 'not': {
        returnVal = !(toCompare == compareTo)
        break
      }
      case 'notEmpty': {
        returnVal = toCompare !== undefined && toCompare !== null
        break
      }
      case 'isEmpty': {
        returnVal = toCompare === undefined || toCompare === null || toCompare === ''
        break
      }
      /* eslint-enable */
    }

    return returnVal
  }

  // This is a quick fix to initial implementation where strings were compared,
  // so "'2' > '19'" was true, which if using numbers will result in false like it should.
  // NOTE: If there is time, query builder logic should be refactored.
  private ParseQueryNumbers(query: IQueryWRef) {
    for (const block of query.query.blocks) {
      for (const s of block.statements) {
        if (s.value !== '' && !isNaN(+s.value)) {
          s.value = +s.value
        }
      }
    }
  }

  async getFeatureCountExtent(layer: AppLayer, extent: number[]): Promise<boolean> {
    const existingFeatures = layer.features.length
    const upload: IPNumEntriesCheck = {
      valid_from: layer.isTimeSeries ? layer.timeSeriesSelection?.date_range.from : undefined,
      valid_to: layer.isTimeSeries ? layer.timeSeriesSelection?.date_range.to : undefined,
      layer_id: layer.id,
      layer_preset: layer.preset as Db.Vip.LayerPreset,
      extent: extent
    }

    try {
      const detailViewLayers = [Db.Vip.LayerPreset.FLOODRE_CLAIMS, Db.Vip.LayerPreset.FLOODRE_EXPOSURE, Db.Vip.LayerPreset.PROPERTY_DATA_HUB]
      const numEntries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
      const totalFeatures = (layer.preset && detailViewLayers.includes(layer.preset)) ? existingFeatures + numEntries.count : numEntries.count
      if (totalFeatures > layerAlertThreshold) {
        this._trackDialog(
          this._promptService.prompt(`The number of features within the current extent is over the threshold of ${layerAlertThreshold} by: ${totalFeatures - layerAlertThreshold}.
          Please zoom in closer and click the extent again`, {
            ok: null
          })
        )
        return false
      }
    } catch (error: any) {
      handleError(error)
      return false
    }
    return true
  }

  async getFeatureCountCsv(layer: AppLayer): Promise<boolean> {
    let numFeatures = 0
    const appliedSpatialQueries: IQueryWRef[] = this._queryList.filter(q => {
      if (q.applied && q.layer_id === layer.id && q.type === 'spatial') {
        return q
      }
    })
    const appliedAttributeQueries: Db.Helper.Prj.Query[] = this._queryList.filter(q => {
      if (q.applied && q.layer_id === layer.id && q.type === 'filter') {
        return q
      }
    }).map((attQuery) => attQuery.query)

    if (appliedSpatialQueries.length) {
      for (const q of appliedSpatialQueries) {
        const wkt = q ? OlUtil.featureToWkt(q.geometry, 6) : undefined
        const floodIds: string[] = this.getAppliedAssociationQueries(q)
        const upload: IPNumEntriesCheck = {
          valid_from: layer.isTimeSeries ? layer.timeSeriesSelection?.date_range.from : undefined,
          valid_to: layer.isTimeSeries ? layer.timeSeriesSelection?.date_range.to : undefined,
          layer_id: layer.id,
          layer_preset: layer.preset as Db.Vip.LayerPreset,
          wkt_geometry: wkt,
          flood_area_ids: floodIds.length ? floodIds : undefined,
          attribute_query: appliedAttributeQueries,
          csv: true
        }
        const entries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
        numFeatures += +(entries.sampled_count ? entries.sampled_count : 0)
      }
    } else {
      const upload: IPNumEntriesCheck = {
        valid_from: layer.isTimeSeries ? layer.timeSeriesSelection?.date_range.from : undefined,
        valid_to: layer.isTimeSeries ? layer.timeSeriesSelection?.date_range.to : undefined,
        layer_id: layer.id,
        layer_preset: layer.preset as Db.Vip.LayerPreset,
        attribute_query: appliedAttributeQueries,
        csv: true
      }
      let entries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
      numFeatures += +(entries.sampled_count ? entries.sampled_count : 0)
    }

    if (numFeatures > csvRowThreshold) {
      this._trackDialog(
        this._promptService.prompt(`The number of rows within the csv download is over the threshold of ${csvRowThreshold} by: ${numFeatures - csvRowThreshold}.
          Please filter down your query and try again`, {
          ok: null
        })
      )
      return false
    }
    return true
  }

  async getFeatureCountDashBoardQuery(query: IQueryWRef, targetLayer: AppLayer): Promise<number> {
    let numFeatures = 0
    try {
        const layer = targetLayer
        const timeSeries = layer.timeSeriesSelection && layer.timeSeriesSelection.date_range ? layer.timeSeriesSelection : layer.renderedTimeSeriesSelection
        if (query.type === 'spatial' || query.type === 'filter') {
          if (!this._queryList.length) {
            await this.GetQueries()
          }
          const qList: IQueryWRef[] = this._queryList.filter(q => q.applied && q.layer_id === query.layer_id && q.query_id !== query.query_id && q.type === 'spatial')
          const combined = OlUtil.combineWkts(qList.map(q => OlUtil.featureToWkt(q.geometry, 6)))
          const body: IPNumEntriesCheck = {
            valid_from: timeSeries?.date_range ? timeSeries?.date_range.from : undefined,
            valid_to: timeSeries?.date_range ? timeSeries?.date_range.to : undefined,
            layer_preset: layer.preset as Db.Vip.LayerPreset,
            wkt_geometry: combined,
            layer_id: layer.id,
            attribute_query: [query.query],
            csv: true
          }
          const numEntries = await this._api.orm.Products().Fred().getNumEntries(body).run()
          numFeatures += numEntries.count
          if (numFeatures === 0) {
            body.wkt_geometry = undefined
            const numEntries = await this._api.orm.Products().Fred().getNumEntries(body).run()
            numFeatures += numEntries.count
          }
          return numFeatures
        }
      return query.layer.features.length
    } catch (error: any) {
      handleError(error)
      return numFeatures
    }
  }

  async checkEntriesInTimeRange(layer: AppLayer, from: string, to: string, message?: string): Promise<boolean> {
    let numFeaturesAfterDelete = 0
    for (const q of this._queryList) {
      if (q.applied && q.layer_id === layer.id && q.type === 'spatial') {
        const wkt = OlUtil.featureToWkt(q.geometry, 6)
        const upload: IPNumEntriesCheck = {
          valid_from: from,
          valid_to: to,
          layer_preset: layer.preset as Db.Vip.LayerPreset,
          wkt_geometry: wkt,
          layer_id: layer.id
        }
        const numEntries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
        numFeaturesAfterDelete += numEntries.count
      }
    }
    if (numFeaturesAfterDelete === 0) {
      const upload: IPNumEntriesCheck = {
        valid_from: from,
        valid_to: to,
        layer_preset: layer.preset as Db.Vip.LayerPreset,
        layer_id: layer.id
      }
      const numEntries = await this._api.orm.Products().Fred().getNumEntries(upload).run()
      numFeaturesAfterDelete += numEntries.count
    }
    const displayed = message ? message : `Changing the time range will increase the number of entries for '${layer.title}' layer over system capacity`
    if (numFeaturesAfterDelete > clientDataThreshold) {
      this._trackDialog(
        this._promptService.prompt(`${displayed}. Value over threshold: ${numFeaturesAfterDelete - clientDataThreshold}.
          Please adjust the timeslider or adjust the spatial query to adjust the feature count`, {
          ok: null
        })
      )
      return false
    }
    if (numFeaturesAfterDelete > layerAlertThreshold) {
      this._trackDialog(
        this._promptService.prompt(`Please be patient when loading large layers and click 'wait' if the browser asks whether to cancel the operation`, {
          ok: null
        })
      )
    }
    return true
  }

  async checkSafeToToggle(query: IQueryWRef, forceDisable?: boolean): Promise<boolean> {
    const numFeaturesAfterDelete = await this.getFeatureCountWithQuery(query, forceDisable ? false : !query.applied)
    if (numFeaturesAfterDelete > clientDataThreshold) {
      this._trackDialog(
        this._promptService.prompt(`${forceDisable || query.applied ? 'Removing' : 'Adding'} this query will increase the number of features past the system capacity.
        Please adjust time range. Current value over threshold: ${numFeaturesAfterDelete - clientDataThreshold}`, {
          ok: null
        })
      )
      return false
    }
    return true
  }

  async getFeatureCountWithQuery(query: IQueryWRef, includeQuery: boolean = false): Promise<number> {
    let numFeaturesAfterDelete = 0
    try {
      if (query.targetRef instanceof AppLayer) {
        const layer = query.targetRef
        const timeSeries = layer.timeSeriesSelection && layer.timeSeriesSelection.date_range ? layer.timeSeriesSelection : layer.renderedTimeSeriesSelection
        if (query.type === 'spatial' || query.type === 'filter') {
          const qList: IQueryWRef[] = this._queryList.filter(q => q.applied && q.layer_id === query.layer_id && q.query_id !== query.query_id && q.type === 'spatial')
          const attrQList: Db.Helper.Prj.Query[] = this._queryList.filter(q => q.applied && q.layer_id === query.layer_id && q.query_id !== query.query_id && q.type === 'filter').map(q => q.query)
          if (includeQuery) qList.push(query)

          const combined = OlUtil.combineWkts(qList.map(q => OlUtil.featureToWkt(q.geometry, 6)))
          const body: IPNumEntriesCheck = {
            valid_from: timeSeries?.date_range.from,
            valid_to: timeSeries?.date_range.to,
            layer_preset: layer.preset as Db.Vip.LayerPreset,
            wkt_geometry: combined,
            layer_id: layer.id,
            attribute_query: attrQList
          }
          const numEntries = await this._api.orm.Products().Fred().getNumEntries(body).run()
          numFeaturesAfterDelete += numEntries.count
          if (numFeaturesAfterDelete === 0) {
            body.wkt_geometry = undefined
            const numEntries = await this._api.orm.Products().Fred().getNumEntries(body).run()
            numFeaturesAfterDelete += numEntries.count
          }
          return numFeaturesAfterDelete
        }
      }
      return query.targetRef.features.length
    } catch (error: any) {
      handleError(error)
      return numFeaturesAfterDelete
    }
  }

  async deleteQuery(query: IQueryWRef) {
    const safeQuery = await this.checkSafeToToggle(query, true)
    if (!safeQuery &&
    (query.targetRef instanceof AppLayer && query.targetRef.preset)) return

    if (query.applied) this.toggleQuery(query, false)
    await this._queriesApi.Query(query.query_id).delete(safeQuery).run()

    this._queryList.splice(this._queryList.indexOf(query), 1)
    this.OnQueryArrayChange()
    this.UpdateLayerFilteredFlag(query.targetRef)
  }

  async saveQuery(query: IQueryWRef, safeQuery?: boolean) {
    this.ParseQueryNumbers(query)
    let queryEntry: IQueryWRef | undefined = this._queryList.find(x => x === query)
    if (!queryEntry) {
      const body: IPNewQuery = {
        name: query.name,
        applied: !!query.applied,
        query: query.query,
        type: query.type,
        geometry: query.geometry ? OlUtil.featureToWkt(query.geometry) : undefined,
        safeQuery: safeQuery,
        query_metadata: query.query_metadata ? query.query_metadata: undefined
      } as any
      if (query.layer_id) body.layer_id = query.layer_id
      else if (query.layer_group_id) body.layer_group_id = query.layer_group_id

      const res = await this._queriesApi.create(body).run()

      queryEntry = {
        ...res,
        geometry: res.geometry ?
          OlUtil.wktToFeature(res.geometry).getGeometry() as olGeom.Polygon | olGeom.MultiPolygon :
          undefined,
        targetRef: query.targetRef,
        index: this._queryList.length,
        safeQuery: safeQuery,
        query_metadata: query.query_metadata ? query.query_metadata: undefined
      }

      this._queryList.push(queryEntry)
    } else {
      await this._queriesApi.Query(query.query_id).update({
        name: query.name,
        applied: !!query.applied,
        query: query.query,
        geometry: query.geometry ? OlUtil.featureToWkt(query.geometry) : undefined,
        safeQuery: safeQuery,
        query_metadata: query.query_metadata ? query.query_metadata: undefined
      }).run()
    }
    // TODO: Separate array change and single query change to two events?
    this.OnQueryArrayChange()
    return queryEntry
  }

  async saveQueries() {
    for (const query of this._queryList) {
      await this._queriesApi.Query(query.query_id).update({
        name: query.name,
        applied: !!query.applied,
        query: query.query,
        geometry: query.geometry
      }).run()
    }
  }

  refreshLayerQuery(layer: AppLayer) {
    const queries = this._queryList.filter(x => !!x.targetRef && x.targetRef.id === layer.id)
    for (const query of queries) {
      if (!query.applied) return
      this.toggleQuery(query, true)
    }
  }

  getQueryableTarget(id: number | string): AppLayer | AppLayerGroup | undefined {
    const matching = (l: AppLayer | AppLayerGroup) => l.id === id

    const matchLayer = (this.queryableLayers || []).reduce(
      (match, val) => {
        if (!match) {
          match = val instanceof AppLayer ? [val].find(matching) : val.targets.find(matching) as AppLayer
        }
        return match
      }, undefined as AppLayer | undefined
    )
    return matchLayer
  }

  getTargetQueries(target: AppLayer | AppLayerGroup | AppRasterLayer, types: ('style' | 'filter' | 'statistic' | 'spatial' | 'enhanced')[]) {
    return this._queryList.filter(query => {
      return types.includes(query.type) && (
        target instanceof AppLayer ? query.layer_id : query.layer_group_id
      ) === target.id
    })
  }

  getAppliedAssociationQueries = (q: IQueryWRef): string[] => {
    const floodIdsQuery: Db.Helper.Prj.SpatialSelectorMeta[] = []
    const floodIds: string[] = []
    let nonAssociationQuery = false

    if (q.type === 'spatial' &&
      q.query &&
      q.query.spatialSelector &&
      q.query.spatialSelector.length) {
      floodIdsQuery.push(...q.query.spatialSelector)
    } else if (q.type === 'spatial') {
      nonAssociationQuery = true
    }

    if (floodIdsQuery.length && !nonAssociationQuery) floodIdsQuery.map((f) => {
      if (f.featureId)
        floodIds.push(f.featureId.toString())
    })
    return floodIds
  }

  getCreditDetails = async (regNo: string) => {
    let res: ICreditDetails
    try {
      res = await this._api.orm.Credit().get(regNo).run()
    } catch (error: any) {
      handleError(error)
      throw error
    }
    return res
  }

  downloadCreditdPdf = async (compId: string) => {
    try {
      const download = await this._api.orm.Credit().getPdf(compId).run()
      return download
    } catch (error: any) {
      handleError(error)
      throw error
    }
  }
}

applyMixins(LayerQueriesService, [DialogCleanup])
