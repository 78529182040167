import { Component, Inject } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { cloneDeep } from 'lodash'

interface DialogData {
  datasets: (Db.Vip.Geo.IPresetDataset & { disabled?: boolean, disabledReason?: string })[],
  query?: NonNullable<Db.Vip.Geo.ILayer['parameters']>['preset_dataset_sequence']
}

@Component({
  selector: 'app-dataset-join-query-editor',
  templateUrl: './dataset-join-query-editor.component.html',
  styleUrls: ['./dataset-join-query-editor.component.scss']
})
export class DatasetJoinQueryEditorComponent {
  static open (dialog: MatDialog, data: DialogData):
  MatDialogRef<DatasetJoinQueryEditorComponent> {
    return dialog.open(
      DatasetJoinQueryEditorComponent, { data }
    )
  }

  datasetsMeta: (Db.Vip.Geo.IPresetDataset & {
    disabled?: boolean
    disabledReason?: string
    // Dataset can be joined just once
    used?: boolean
  })[] = []
  canAddNewBlock = true
  query: NonNullable<
    NonNullable<Db.Vip.Geo.ILayer['parameters']>['preset_dataset_sequence']
  >
  join = Db.Helper.Geo.DatasetJoin

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _dialogRef: MatDialogRef<DatasetJoinQueryEditorComponent>
  ) {
    this.query = cloneDeep(data.query || [])
    this.filterRemainingDatasets()
  }

  filterRemainingDatasets () {
    this.datasetsMeta = this.data.datasets.map(x => ({
      ...x,
      used: this.query.some(j =>
        j.dataset === x.preset_dataset_tag || j.sequence && j.sequence.some(k =>
          k.dataset === x.preset_dataset_tag
        )
      )
    }))

    const remainingUnused = this.datasetsMeta.reduce((sum, val) =>
      sum + ((val.used || val.disabled) ? 0 : 1), 0
    )

    const undefinedBlocks = this.query.reduce((sum, val) => {
      if (!val.dataset) sum++
      if (val.sequence) {
        sum = val.sequence.reduce((sum2, val2) => sum2 + (val2.dataset ? 0 : 1), sum)
      }
      return sum
    }, 0)

    this.canAddNewBlock = remainingUnused - undefinedBlocks > 0
  }

  saveQuery () {
    // Make sure first element has no join statement
    if (this.query[0]) this.query[0].join_as = undefined
    for (const query of this.query) {
      // Clean up object from empty values
      if (query.sequence && !query.sequence.length) query.sequence = undefined
    }
    this._dialogRef.close(this.query)
  }

  addJoin (join: Db.Helper.Geo.DatasetJoin, level1I?: number) {
    let sequence = this.query
    if (level1I !== undefined) {
      const block = this.query[level1I]
      if (!block.sequence) block.sequence = []
      sequence = block.sequence
    }

    sequence.push({
      dataset: undefined as any,
      join_as: join
    } as any)

    this.filterRemainingDatasets()
  }

  deleteBlock (level1I: number, level2I?: number) {
    if (level2I !== undefined) {
      if (level2I >= 0) {
        const block = this.query[level1I]
        if (block.sequence) block.sequence.splice(level2I, 1)
      } else {
         // If it's -1, means it's parent node
        const block = this.query[level1I]
         // If there are elements in this sequence, shift items
        if (block.sequence && block.sequence.length) {
          const first = block.sequence.shift() as any
          block.dataset = first.dataset
        } else {
           // Otherwise remove block
          this.query.splice(level1I, 1)
        }
      }
    } else {
      this.query.splice(level1I, 1)
    }

    this.filterRemainingDatasets()
  }

  resetQuery () {
    this.query = [{
      dataset: undefined,
      sequence: []
    }] as any

    this.filterRemainingDatasets()
  }
}
