<form [formGroup]="form">
  <mat-form-field>
    <mat-select disableOptionCentering formControlName="workspaceName"
      placeholder="Workspaces" (selectionChange)="workspaceChange($event.value)" matTooltip="Layer workspaces">
      <mat-option *ngFor="let wk of workSpaces" [value]="wk.workspaceName" class="st-capitalize">
        {{wk.workspaceDisplayName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="selectedWorkspace">
    <mat-select disableOptionCentering formControlName="presetName" placeholder="Existing Dataset"
      (selectionChange)="presetChange($event.value)">
      <mat-option *ngFor="let preset of layerPresets" [value]="preset.layer_preset_tag" class="st-capitalize"
        [matTooltip]="preset.description">
        {{preset.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field  *ngIf="staticPreset" matTooltip="Layers in workspace">
    <mat-select disableOptionCentering formControlName="layerName" placeholder="Layers"
      (selectionChange)="layerChange($event.value)">
      <mat-option *ngFor="let l of layers" [value]="l" class="st-capitalize">
        {{l}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<app-loader-backdrop *ngIf="fetchingData" loadingText="Fetching settings..."></app-loader-backdrop>
