import { Injectable } from '@angular/core'
import {
    HttpEvent, HttpInterceptor,
    HttpHandler, HttpRequest
} from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class ApplicationTypeInterceptor implements HttpInterceptor {

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const contentType = req.headers.get('content-type')
    const enctype = req.headers.get('enctype')

    if (!contentType && !enctype) {
      const headers = req.headers.set('content-type', 'application/json')
      const authReq = req.clone({ headers })

      return next.handle(authReq)
    }

    return next.handle(req.clone())
  }
}
