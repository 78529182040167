import { IVideoDetails, ITelemetry, IDronePosition } from '@core/types'

import Overlay from 'ol/Overlay'
import { OverlayPositioning }  from '@core/enum/ol/ol-overlay-positioning'

import VectorLayer from 'ol/layer/Vector'
import * as olExtent from 'ol/extent'
import * as _ from 'lodash'
import { BehaviorSubject, Subscription, fromEvent, Subject } from 'rxjs'
import { MatDialogRef } from '@angular/material/dialog'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DroneConvertUtil } from '@core/utils/convert/drone-convert.util'
import { CommonUtil, ColourUtil } from '@core/utils/index'
import { PromptService } from '@services/core/prompt/prompt.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AlertService } from '@services/core/alert/alert.service'
import { WorkspaceMapService } from '@services/workspace'
import { IPDroneSource } from '@vip-shared/interfaces/api/api-body-types'
import AppError, { handleError } from '@core/models/app-error'
import CanvasUtil from '@core/utils/canvas/canvas.util'
import { throttleTime } from 'rxjs/operators'
import { AppDroneLayer } from '@core/models/layer/app-drone-layer'
import NewLayerUtil from '@core/models/layer/utils/new-layer.util'
import { Db } from '@vip-shared/models/db-definitions'
import MapBrowserEvent from 'ol/MapBrowserEvent'

export class SelectedDroneLayer {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  private _subscriptions: Subscription = new Subscription()
  private _loaded = false
  private _hasCameraAngle = false
  private _calibrationToggle: Subject<boolean> = new Subject()
  private _currentTimeIndex = 0
  olLayer: VectorLayer<any>
  private _telemetryStartingIndex = 0
  private _translatedTelemetryStartingIndex = 0
  private _temporalResolution!: number
  videoDetails!: IVideoDetails
  private _telemetry!: ITelemetry
  private _originalTelemetry: ITelemetry = {
    coordinate: [],
    gimbalYaw: [],
    time: []
  }

  private _timeInterval: number = 0
  private _intervalDifference: number = 0
  private _interpolationMode: boolean = true
  droneFOV: Overlay
  private _videoSeconds = 0
  videoPlaying = false

  private _calibrationEnabled = false
  private _clickCalibrationActive = false
  private _calibrationSaving = false
  private _setDroneColor = new Subject<{R: number, G: number, B: number} | 'auto'>()
  private _videoSecondsObservable: BehaviorSubject<{
    seconds: number
    update: boolean
  }> = new BehaviorSubject({
    seconds: 0,
    update: false as boolean
  })

  get calibrationSaving (): boolean {
    return this._calibrationSaving
  }

  get clickCalibrationActive (): boolean {
    return this._clickCalibrationActive
  }
  get calibrationEnabled (): boolean {
    return this._calibrationEnabled
  }

  get videoSeconds () {
    return this._videoSecondsObservable.asObservable()
  }

  get calibrationToggle () {
    return this._calibrationToggle.asObservable()
  }
  private _obs = new Subject()

  constructor (
    public layer: AppDroneLayer,
    private _droneSource: Db.Vip.Geo.IDroneSource,
    private _api: VipApiService,
    private _workspaceMapService: WorkspaceMapService,
    private _alertService: AlertService,
    private _promptService: PromptService,
    private _workspaceId: number
  ) {
    const isDroneLayer = layer.typeId === Db.Vip.SourceType.DRONE_VECTOR
    if (!isDroneLayer) throw new AppError(`Layer is not a 'Drone' layer.`)
    if (!layer.layer) throw new AppError(`Drone layer on a map was not found.`)
    this.olLayer = layer.layer as VectorLayer<any>

    this._telemetryStartingIndex = this._droneSource.telemetry_start_index

    this._originalTelemetry = DroneConvertUtil.readTelemetry(this._droneSource.point_telemetry_geojson)
    this._hasCameraAngle = this._originalTelemetry.gimbalYaw.some(x => typeof x === 'number')
    Object.freeze(this._originalTelemetry)

    this.droneFOV = this.CreateDroneFOV()

    this._subscriptions.add(this._workspaceMapService.mapClicked.subscribe(ev => {
      if (ev.empty || this.olLayer === ev.layer) this.ProcessClick(ev.evt)
    }))

    this._subscriptions.add(
      this._setDroneColor.pipe(throttleTime(500))
      .subscribe(color => this.SetDroneColor(color))
    )
  }

  async loadLayer () {
    await this.readDroneTelemetry()
    const signedURL = await this._api.orm.Workspaces()
    .Workspace(this._workspaceId).Layer(this.layer.id).DroneSources()
    .Source(this._droneSource.drone_source_id).getVideo().run()

    this.VideoSelectEvent()
    this.videoDetails = {
      name: this._droneSource.video_title,
      url: signedURL
    }

    this.ShowDroneFOV()
    this._loaded = true
  }

  canCalibrate (target: 'drone' | 'video', position: IDronePosition): boolean {
    if (target === 'drone') {
      const lastIndex = this._originalTelemetry.time.length - 1
      if (position === 'backward') {
        return this._telemetryStartingIndex > -lastIndex
      } else {
        return this._telemetryStartingIndex < lastIndex
      }
    } else {
      if (position === 'backward') {
        return this._currentTimeIndex > 0
      } else {
        return this._currentTimeIndex < this._telemetry.time.length - 1
      }
    }
  }

  async waitForLoad () {
    while (!this._loaded) {
      await CommonUtil.delay(100)
    }
  }

  updateLayer () {
    if (this.layer.layer) {
      const vector = this.layer.layer as VectorLayer<any>
      vector.setSource(
        NewLayerUtil.createDroneVectorSource(
          this._droneSource.line_geojson,
          this._droneSource.point_telemetry_geojson,
          this._telemetryStartingIndex,
          this._calibrationEnabled
        )
      )
    }
  }

  async readDroneTelemetry () {
    try {
      // This function creates the features that are inside our time-frame for the drone path
      this._telemetry = _.cloneDeep(this._originalTelemetry)
      let startingIndex = this._telemetryStartingIndex
      if (startingIndex < 0) {
        const abs = Math.abs(startingIndex)
        const firstCoord = this._telemetry.coordinate[0]
        const firstYaw = this._telemetry.gimbalYaw[0]
        this._telemetry.coordinate.splice(0, 0, ...Array(abs).fill([...firstCoord]))
        this._telemetry.gimbalYaw.splice(0, 0, ...Array(abs).fill(firstYaw))

        this._telemetry.coordinate.splice(this._telemetry.coordinate.length - abs)
        this._telemetry.gimbalYaw.splice(this._telemetry.gimbalYaw.length - abs)

        startingIndex = 0
      } else if (startingIndex > 0) {
        const lastCoord = this._telemetry.coordinate[this._telemetry.coordinate.length - 1]
        const latYaw = this._telemetry.gimbalYaw[this._telemetry.gimbalYaw.length - 1]
        this._telemetry.coordinate.push(...Array(startingIndex).fill([...lastCoord]))
        this._telemetry.gimbalYaw.push(...Array(startingIndex).fill(latYaw))
        this._telemetry.coordinate.splice(0, startingIndex)
        this._telemetry.gimbalYaw.splice(0, startingIndex)

        startingIndex = 0
      }
      this._translatedTelemetryStartingIndex = startingIndex
      // --- Compensating for any missing data ---

      // Finding interval size (smallest step over the first 40 elements)
      const timeChunk = this._telemetry.time.slice(0, 40)
      this._temporalResolution = Math.min(
        ...timeChunk.slice(1).map((val, i) => val - timeChunk[i])
      )

      // Defining the trigger point for a data point being missing (has to be almost 1 entire missing data point)
      const temporalResolutionMissingDataTrigger = this._temporalResolution + this._temporalResolution * 0.9
      // Filling in any missing data with interpolated positions
      // TODO: Cleanup
      let contiguousCount = 0
      let largestCount = 0
      let lastItem = 0
      for (let l = 0; l < this._telemetry.time.length - 1; l++) {
        const step = this._telemetry.time[l + 1] - this._telemetry.time[l]
        // Fill in time gaps and put in interpolation placeholders if step is too big
        if (step > temporalResolutionMissingDataTrigger) {
          if (lastItem > 0) {
            contiguousCount = l === lastItem + 1 ? contiguousCount + 1 : 0
            if (contiguousCount > largestCount) {
              largestCount = contiguousCount
            }
          }
          lastItem = l

          // Adding new time element
          this._telemetry.time.splice(l + 1, 0, +(this._telemetry.time[l] + this._temporalResolution).toFixed(3))
          // Adding placeholder coordinate (ready for interpolation)
          this._telemetry.coordinate.splice(l + 1, 0, [Infinity, Infinity])
          // Adding placeholder gimbal yaw (ready for interpolation)
          this._telemetry.gimbalYaw.splice(l + 1, 0, Infinity)
        }
      }

      // Finding the first good value so we can loop from there to fix data values
      let firstGoodValue = 0
      for (let i = 0; i < 50; i++) {
        firstGoodValue = i
        if (this._telemetry.coordinate[i][0] !== Infinity) break
      }

      // loop running through each coordinate
      for (let i = firstGoodValue; i < this._telemetry.coordinate.length - 1; i++) {
        // found a point requiring interpolation
        if (this._telemetry.coordinate[i][0] === Infinity) {
          // Finding first instance of interpolation required
          // Seeing how many intervals there are which need filling in this block
          let intervals = 1
          // largestCount contains the longest stretch of unknown data in the array.
          for (let j = 1; j < largestCount + 1; j++) {
            if (this._telemetry.coordinate[i + j][0] !== Infinity) break
            intervals = intervals + 1
          }

          let // Getting coordinates of each data point on either side of the missing data
            x1 = this._telemetry.coordinate[i - 1][0],
            x2 = this._telemetry.coordinate[i + intervals][0],
            y1 = this._telemetry.coordinate[i - 1][1],
            y2 = this._telemetry.coordinate[i + intervals][1],
            // Getting yaw values on either side of the missing data
            yaw1 = this._telemetry.gimbalYaw[i - 1],
            yaw2 = this._telemetry.gimbalYaw[i + intervals]

          // Looping through each missing element which needs an interpolated location and giving new values
          for (let j = 0; j < intervals; j++) {
            // Interpolated coordinate
            let xVal = x1 + (x2 - x1) * ((j + 1.0) / (intervals + 1)),
              yVal = y1 + (y2 - y1) * ((j + 1.0) / (intervals + 1))

            // Interpolated yaw (check to see if 0 to 359 or vice versa has occurred, if it has then don't interpolate yaw to avoid artifacts)
            let yawVal: number | null = 0
            if (!CommonUtil.isUndefined(yaw1) && !CommonUtil.isUndefined(yaw2)) {
              if (Math.abs(yaw1 - yaw2) < 200) {
                yawVal = yaw1 + (yaw2 - yaw1) * ((j + 1.0) / (intervals + 1))
              } else {
                yawVal = yaw1
              }
            } else {
              yawVal = null
            }

            // Updating the coordinates with the interpolated values
            const coordinateArray = [xVal, yVal] as [number, number]
            this._telemetry.coordinate[i + j] = coordinateArray // Setting interpolated coordinate

            this._telemetry.gimbalYaw[i + j] = yawVal // Setting interpolated value
          } // End of looping through each missing element which requires interpolation applying
        }
      }
      this.updateLayer()
    } catch (error: any) {
      handleError(error)
    }
  }

  destroy () {
    this._destroyDialogs()
    this._subscriptions.unsubscribe()
    this._videoSecondsObservable.complete()
    this._calibrationToggle.complete()
    this._workspaceMapService.map.removeOverlay(this.droneFOV)
  }

  toggleDroneFOV (show: boolean) {
    const el = this.droneFOV.getElement()
    if (el) el.style.display = show ? 'unset' : 'none'
  }

  async setVideoSeconds (seconds: number, updateVideo = false) {
    await this.waitForLoad()
    this._videoSeconds = seconds
    this._obs.next({})
    this._videoSecondsObservable.next({
      seconds: seconds,
      update: updateVideo
    })
    if (!updateVideo) this.SetDroneCoordinate()
  }

  enableCalibration () {
    if (this._calibrationEnabled) this.disableCalibration()
    this._calibrationEnabled = true
    this.updateLayer()
  }

  async disableCalibration () {
    const close = async () => {
      this._clickCalibrationActive = false
      this._telemetryStartingIndex = this._droneSource.telemetry_start_index
      await this.readDroneTelemetry()
      this.setVideoSeconds(this._videoSeconds)
      this._calibrationSaving = false
      this._calibrationEnabled = false
      this.updateLayer()
    }
    if (this._droneSource.telemetry_start_index !== this._telemetryStartingIndex) {
      this._trackDialog(
        this._promptService.prompt('It looks like there are unsaved calibration changes. If you close the calibration tool before saving, your changes will be lost.', {
          cancel: null,
          ok: close
        })
      )
    } else {
      close()
    }
  }

  async resetCalibration () {
    this._calibrationToggle.next(true)
    this._telemetryStartingIndex = 0
    this._currentTimeIndex = 0
    await this.readDroneTelemetry()
    this.SetDroneCoordinate()
    this.setVideoSeconds(this._telemetry.time[this._currentTimeIndex], true)
  }

  async move (target: 'drone' | 'video', position: IDronePosition) {
    if (!this.canCalibrate(target, position)) return
    this._calibrationToggle.next(true)
    const step = position === 'backward' ? -1 : 1
    if (target === 'drone') {
      this._telemetryStartingIndex += step
    } else {
      this._currentTimeIndex += step
      this._telemetryStartingIndex -= step
    }

    await this.readDroneTelemetry()

    if (target === 'drone') this.SetDroneCoordinate()
    else this.setVideoSeconds(this._telemetry.time[this._currentTimeIndex], true)
  }

  clickToAlignToggle (enabled?: boolean) {
    if (enabled === undefined) enabled = !this._clickCalibrationActive
    if (enabled === this._clickCalibrationActive) return
    this._clickCalibrationActive = enabled
    this.SetCursor(!this._clickCalibrationActive)
    this._calibrationToggle.next(this._clickCalibrationActive)
  }

  async saveCalibration () {
    if (this._calibrationSaving) throw new AppError('Calibration saving already in progress.')
    this._calibrationSaving = true

    const body: IPDroneSource = {
      telemetry_start_index: this._telemetryStartingIndex
    }

    await this._api.orm.Workspaces().Workspace(this._workspaceId).Layer(this.layer.id)
    .DroneSources().Source(this._droneSource.drone_source_id).update(body).run()
    this._droneSource.telemetry_start_index = this._telemetryStartingIndex
    this.disableCalibration()
  }

  private async ProcessClick (evt: MapBrowserEvent<any>) {
    const targetTelemetry = !this._clickCalibrationActive ? this._telemetry : this._originalTelemetry
    const coordinate = targetTelemetry.coordinate
    const mouseCoordinate = evt.coordinate
    const mag: number[] = []

    // Get distance from click and telemetry coordinates?
    for (let i = 0; i < coordinate.length; i++) {
      const f = Math.sqrt(
        Math.abs(
          Math.pow(mouseCoordinate[0] - coordinate[i][0], 2) +
          Math.pow(mouseCoordinate[1] - coordinate[i][1], 2)
        )
      )
      if (!isNaN(f)) {
        mag[i] = f
      } else {
        throw new AppError(`Telemetry contains invalid coordinate values. Cannot process click event.`)
      }
    }

    const closest = Math.min(...mag)
    const closestIndex = mag.indexOf(closest)

    const extent = this.olLayer.getSource().getExtent()
    const size = olExtent.getSize(extent)
    const diagonal = Math.sqrt(Math.pow(size[0], 2) + Math.pow(size[1], 2))
    // don't process the click if it happened further than 1/3 of the diagonal of the layer extent away
    if (closest > diagonal / 3) return

    const indexBeforeMove = this._currentTimeIndex
    this.SetDroneCoordinate(
      targetTelemetry.coordinate[closestIndex],
      targetTelemetry.gimbalYaw[closestIndex],
      true,
      undefined,
      closestIndex
    )

    if (!this._clickCalibrationActive) {
      this.setVideoSeconds(targetTelemetry.time[closestIndex], true)
    } else {
      this._telemetryStartingIndex = this._currentTimeIndex
      await this.readDroneTelemetry()

      this._currentTimeIndex = this._currentTimeIndex - indexBeforeMove
      this.clickToAlignToggle(false)

      this.setVideoSeconds(this._telemetry.time[0], true)
    }
  }

  private SetCursor (reset?: boolean) {
    const map = this._workspaceMapService.map.getTargetElement()
    if (map) {
      map.style.cursor = reset ? 'unset' : 'crosshair'
    }
  }

  private VideoSelectEvent () {
    if (!this._telemetry || this._telemetry.time.length === 0) throw new AppError('No data available.')
    // Testing the time interval for the drone data to test whether interpolation is actually possible
    // This finds the minimum time step between first 40 data points (minimum interval)
    const time = this._telemetry.time
    this._timeInterval = Infinity
    const offset = Math.abs(this._translatedTelemetryStartingIndex)

    for (let i = offset; i < offset + 40; i++) {
      const timeStep = time[i + 1] - time[i]
      if (this._timeInterval > timeStep) {
        this._timeInterval = timeStep
      }
    }
    // The average interval across the entire dataset
    const averageTimeInterval = time[time.length - 1] / time.length

    // The difference between the minimum time interval (over 40 data points), and the average over the whole dataset
    this._intervalDifference = (averageTimeInterval / this._timeInterval - 1.0) * time.length
    // This is just to provide a large buffer
    this._intervalDifference = this._intervalDifference * 2
    if (this._intervalDifference < 10) {
      this._intervalDifference = 20
    } // To prevent problems with small values
    if (this._intervalDifference >= time.length) {
      this._intervalDifference = time.length - 1
    } // In case it goes above the length of a very short video

    // Disable interpolation if the data already has a lower temporal resolution than 0.5 seconds

    if (this._timeInterval < 0.5) {
      this._interpolationMode = false
    } else {
      this._interpolationMode = true
    }
  }

  private CreateDroneFOV () {
    const objEl = document.createElement('object')
    objEl.data = `assets/icons/drone-layer/${
      this._hasCameraAngle ? '100' : '0'
    }_degree_view.svg`
    objEl.type = 'image/svg+xml'

    // Map zoom event handle (to resize drone FOV icon)
    const view: any = this._workspaceMapService.map.getView()
    this._subscriptions.add(
      fromEvent(view, 'propertychange')
      .subscribe((e: any) => {
        // Scaling the FOV drone icon when the user zooms
        if (e.key === 'resolution') this.ScaleDroneFOVIcon(objEl)
      })
    )

    // Setting drone FOV icon when it first appears
    this.ScaleDroneFOVIcon(objEl)

    return new Overlay({
      positioning: OverlayPositioning.CENTER_CENTER,
      element: objEl,
      stopEvent: false
    })
  }

  private ShowDroneFOV () {
    this._workspaceMapService.map.addOverlay(this.droneFOV)
    window.setTimeout(() => {
      if (this._telemetry) {
        this.SetDroneCoordinate(this._telemetry.coordinate[0], this._telemetry.gimbalYaw[0], true, undefined, 0)
      }
    }, 0)
  }

  private ScaleDroneFOVIcon (el: HTMLObjectElement) {
    // Defining icon size in pixels for the drones FOV
    let originalDroneFovImageSizeX = 50
    let originalDroneFovImageSizeY = 50

    let scaleValue = 1.0

    let zoomLevel = this._workspaceMapService.zoom

    if (zoomLevel >= 20) {
      scaleValue = 2.05
    }
    if (zoomLevel < 20 && zoomLevel >= 18) {
      scaleValue = 1.3
    }
    if (zoomLevel < 18 && zoomLevel >= 16) {
      scaleValue = 0.9
    }
    if (zoomLevel < 16 && zoomLevel >= 14) {
      scaleValue = 0.7
    }
    if (zoomLevel < 14) {
      scaleValue = 0.5
    }

    let newX = originalDroneFovImageSizeX * scaleValue,
      newY = originalDroneFovImageSizeY * scaleValue
    el.style.width = newX + 'px'
    el.style.height = newY + 'px'
  }

  updateDroneColor () {
    this._setDroneColor.next(this.layer.droneStyle as {
      R: number;
      G: number;
      B: number;
  } | 'auto')
  }

  private async SetDroneColor (rgb: {R: number, G: number, B: number} | 'auto') {
    if (!this.droneFOV) return

    // NOTE: Throws error 'Cannot read property 'querySelectorAll' of null' but works
    const obj = this.droneFOV.getElement() as HTMLObjectElement
    if (!obj || !obj.contentDocument) return
    const firstEl = obj.contentDocument.firstElementChild
    if (!firstEl || firstEl.localName !== 'svg') {
      // If not loaded yet
      setTimeout(() => this.updateDroneColor(), 500)
      return
    }

    // TODO: CLeanup
    if (rgb === 'auto') {
      const map = this._workspaceMapService.getCanvas()
      const bbox = this.GetRelativeBoundingRect('path')

      if (map && bbox) {
        const average = CanvasUtil.getAverageColor(
        map,
        { xmin: bbox.left, ymin: bbox.top, xmax: bbox.right, ymax: bbox.bottom }
      )
        const av = document.getElementById('_average')
        const dom = document.getElementById('_dom')
        let invAv = ColourUtil.invertRgb(average)

        let contrast = Math.max(ColourUtil.getContrastRating(average, invAv), ColourUtil.getContrastRating(invAv, average))

        if (av) {
          av.style.background = `rgb(${average.R}, ${average.G}, ${average.B})`
          av.style.border = `10px solid rgb(${invAv.R}, ${invAv.G}, ${invAv.B})`
        }

        while (contrast < 6) {
          const brighter = ColourUtil.colorLuminance({ ...invAv }, 0.1)
          const darker = ColourUtil.colorLuminance({ ...invAv }, -0.2)

          if (dom) {
            dom.style.background = `rgb(${darker.R}, ${darker.G}, ${darker.B})`
            dom.style.border = `20px solid rgb(${brighter.R}, ${brighter.G}, ${brighter.B})`
          }

          const bContrast = ColourUtil.getContrastRating(brighter, average)
          const dContrast = ColourUtil.getContrastRating(darker, average)

          if (bContrast > contrast && bContrast > dContrast) invAv = brighter
          else if (dContrast > contrast) invAv = darker

          contrast = ColourUtil.getContrastRating(average, invAv)

          if ((invAv.B === 255 && invAv.G === 255 && invAv.B === 255) || (invAv.B === 0 && invAv.G === 0 && invAv.B === 0)) {
            break
          }
        }

        rgb = invAv
      }
    }

    if (typeof rgb === 'string' || !rgb) return

    const svg: Document = obj.contentDocument as any
    const color1 = svg.getElementsByClassName('fill')
    const colors07 = svg.getElementsByClassName('stroke-07')
    const colors05 = svg.getElementsByClassName('stroke-05')

    for (const item of Array.from(color1)) {
      item.setAttributeNS(null, 'fill', `rgba(${rgb.R}, ${rgb.G}, ${rgb.B}, 1)`)
    }

    for (const item of Array.from(colors07)) {
      item.setAttributeNS(null, 'stroke', `rgba(${rgb.R}, ${rgb.G}, ${rgb.B}, 0.7)`)
    }

    for (const item of Array.from(colors05)) {
      item.setAttributeNS(null, 'stroke', `rgba(${rgb.R}, ${rgb.G}, ${rgb.B}, 0.5)`)
    }
  }

  private GetRelativeBoundingRect (sample: 'drone' | 'path'): ClientRect | undefined {
    const map = this._workspaceMapService.getCanvas()

    if (!map) return
    let mapRect = map.getBoundingClientRect()
    let rectangle
    if (sample === 'drone') {
      const obj = this.droneFOV.getElement() as HTMLObjectElement
      if (!obj) return

      const objRect = obj.getBoundingClientRect()
      rectangle = {
        top: objRect.top - mapRect.top,
        right: objRect.right - mapRect.left,
        bottom: objRect.bottom - mapRect.top,
        left: objRect.left - mapRect.left,
        width: objRect.width,
        height: objRect.height
      }
    } else {
      const e = (this.layer.layer as VectorLayer<any>).getSource().getExtent()
      const min = this._workspaceMapService.map.getPixelFromCoordinate([e[0], e[1]])
      const max = this._workspaceMapService.map.getPixelFromCoordinate([e[2], e[3]])

      rectangle = {
        top: min[1],
        left: min[0],
        bottom: max[1],
        right: max[0],
        width: max[0] - min[0],
        height: max[1] - min[1]
      }
    }

    return rectangle
  }

  private SetDroneCoordinate (
    coordinate?: [number, number],
    cameraAngle?: number | null,
    manualPosition: boolean = false,
    timeSize: number = 4023,
    closestIndex: number = 0
  ) {
    if (!this._telemetry) throw new AppError('No data available.')
    const mapAngle = this._workspaceMapService.map.getView().getRotation() * 57.2957796
    // Run this if we're running this using the video time to reference the drone's position on the map
    if (manualPosition === false) {
      // Calculating estimated index in the telemetry for the current video time
      const reversedIndex = this._telemetry.time
      // Get time difference
      .map(val => Math.abs(this._videoSeconds - val))
      // Reverse, so that if there are two same distances, we first get the time closer to end
      .reverse()
      // Find smallest difference in time
      .reduce((fi, val, i, arr) => fi === -1 || arr[fi] > val ? i : fi, -1)
      // Reverse back the index
      const calculatedIndex = this._telemetry.time.length - 1 - reversedIndex
      // To determine whether estimated time is ahead or behind actual video time
      let direction = this._videoSeconds - this._telemetry.time[calculatedIndex]
      // TODO: Cleanup code below
      const halfOfGap = this._videoSeconds - this._timeInterval / 2.0

      let i = 0

      if (this._intervalDifference + calculatedIndex >= timeSize) {
        this._intervalDifference = timeSize - calculatedIndex
      }

      let finalIndex
      if (direction > 0.0) {
        for (i = 0; i < this._intervalDifference; i++) {
          if (this._telemetry.time[calculatedIndex + i] > halfOfGap) {
            break
          }
        }
        finalIndex = calculatedIndex + i
      } else {
        // Look backwards instead
        for (i = 0; i < this._intervalDifference; i++) {
          if (calculatedIndex - i < 0.0) {
            break
          }
          if (this._telemetry.time[calculatedIndex - i] < halfOfGap) {
            break
          }
        }
        finalIndex = calculatedIndex - i + 1
      } // End of estimated time is further ahead than real time so we need to look backwards

      let interpolationCoordinate: [number, number] | undefined
      let interpolationAngle: number | undefined
      const coordinate = this._telemetry.coordinate
      if (this._interpolationMode) {
        if (calculatedIndex < i) {
          i = calculatedIndex
        } // To avoid a bug
        if (finalIndex === this._telemetry.time.length) {
          finalIndex = finalIndex - 1
        }

        direction = this._videoSeconds - this._telemetry.time[finalIndex] // To determine whether estimated time is ahead or behind actual video time

        let neighborPoint // Either forward or backward nearest neighbor for the interpolation to use
        if (direction > 0.0) {
          neighborPoint = coordinate[finalIndex + 1]
        }
        if (direction <= 0.0) {
          neighborPoint = coordinate[finalIndex - 1]
        }

        let neighborX: number
        let neighborY: number
        // Next position
        if (neighborPoint !== undefined) {
          // If the first value has been selected, then we might get null in neighbor point
          neighborX = neighborPoint[0]
          neighborY = neighborPoint[1]
        } else {
          neighborX = coordinate[finalIndex][0]
          neighborY = coordinate[finalIndex][1]
        }

        // Current position
        const currentX = coordinate[finalIndex][0]
        const currentY = coordinate[finalIndex][1]

        // Calculating delta positions
        const deltaX = (neighborX - currentX) * (Math.abs(direction) / this._timeInterval)
        const deltaY = (neighborY - currentY) * (Math.abs(direction) / this._timeInterval)

        // Apply the delta
        const interpolationX = currentX + deltaX
        const interpolationY = currentY + deltaY

        // Interpolated coordinate
        interpolationCoordinate = [interpolationX, interpolationY]

        let neighborAngle // Either forward or backward nearest neighbor for the interpolation to use

        if (direction > 0.0) {
          neighborAngle = this._telemetry.gimbalYaw[finalIndex + 1]
        }
        if (direction <= 0.0) {
          neighborAngle = this._telemetry.gimbalYaw[finalIndex - 1]
        }

        if (neighborPoint === null) {
          neighborAngle = this._telemetry.gimbalYaw[finalIndex]
        } // Required for the first value

        // The current (nearest match angle)
        const currentAngle = this._telemetry.gimbalYaw[finalIndex]

        if (!CommonUtil.isUndefined(currentAngle)) {
          // The delta angle
          let deltaAngle = (neighborAngle - currentAngle) * (Math.abs(direction) / this._timeInterval)

          // Applying interpolation angle
          interpolationAngle = currentAngle + deltaAngle

          // If angle bridges the 1-359 barrier, then we have to compensate as the delta is not that large
          if (neighborAngle - currentAngle > 180 && neighborAngle > 180) {
            deltaAngle = (neighborAngle - currentAngle - 360) * (Math.abs(direction) / this._timeInterval)
            interpolationAngle = currentAngle + deltaAngle + 360
          }

          // If angle bridges the 359-1 barrier, then we have to compensate as the delta is not that large
          if (neighborAngle - currentAngle < -180 && neighborAngle < 180) {
            deltaAngle = (neighborAngle - currentAngle + 360) * (Math.abs(direction) / this._timeInterval)
            interpolationAngle = currentAngle + deltaAngle - 360
          }
        }
      } // End of only run if interpolation mode is enabled

      // Setting GPS marker position
      if (this._interpolationMode === false) {
        if (finalIndex < coordinate.length) {
          this.droneFOV.setPosition(coordinate[finalIndex])
        }
        if (finalIndex >= coordinate.length) {
          this.droneFOV.setPosition(coordinate[coordinate.length - 1])
        }

        if (Number(this._telemetry.gimbalYaw[finalIndex])) {
          // MSF TODO: if you want the map to rotate
          // const radiansToRotate = this._telemetry.gimbalYaw[finalIndex] * Math.PI / 180

          // this._workspaceMapService.map.getView().animate({
          //   rotation: isNaN(radiansToRotate) ? 0 : radiansToRotate,
          //   duration: 0
          // })

          if (this.droneFOV) {
            let needed_transform = (this.droneFOV as any).rendered.transform_
            let rotation_string = `${needed_transform} rotate(${this._telemetry.gimbalYaw[finalIndex]}deg)`;
            (this.droneFOV as any).element.style.transform = rotation_string;
            (this.droneFOV as any).element.style['-webkit-transform'] = rotation_string;
            (this.droneFOV as any).element.style['-moz-transform'] = rotation_string
          }
        }
      } else {
        this.droneFOV.setPosition(interpolationCoordinate)

        if (Number(this._telemetry.gimbalYaw[finalIndex])) {
          const angle = Number(interpolationAngle) + mapAngle
          if (this.droneFOV) {
            let needed_transform = (this.droneFOV as any).rendered.transform_
            let rotation_string = `${needed_transform} rotate(${angle}deg)`;
            (this.droneFOV as any).element.style.transform = rotation_string;
            (this.droneFOV as any).element.style['-webkit-transform'] = rotation_string;
            (this.droneFOV as any).element.style['-moz-transform'] = rotation_string;
          }
        }
      }
      this._currentTimeIndex = finalIndex
    } else if (manualPosition === true) {
      // If this is a manual run, then we put the drone in the location defined by the parameters passed to the function (so it ignores the video time)
      if (!coordinate) {
        this._alertService.log('no coordinate specified for the manual position')
        return
      }
      // Setting GPS marker position
      this.droneFOV.setPosition(coordinate)

      // Setting rotation of the FOV icon based on the camera gimbal direction
      if (Number(cameraAngle)) {
        let needed_transform = (this.droneFOV as any).rendered.transform_
        let rotation_string = `${needed_transform} rotate(${Number(cameraAngle) + mapAngle}deg)`;
        (this.droneFOV as any).element.style.transform = rotation_string;
        (this.droneFOV as any).element.style['-webkit-transform'] = rotation_string;
        (this.droneFOV as any).element.style['-moz-transform'] = rotation_string;
      }
      this._currentTimeIndex = closestIndex
    } // End of only run if manual is true

    this.updateDroneColor()
  }
}

applyMixins(SelectedDroneLayer, [DialogCleanup])
