import { Injectable } from '@angular/core'
import { ICoordinates } from '@core/types'
import AppError from '@core/models/app-error'

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  private _geocoder = new google.maps.Geocoder()
  private _autocomplete = new google.maps.places.AutocompleteService()
  // Not sure why map is required, but create a mock one to usethe service
  private _places = new google.maps.places.PlacesService(document.createElement('div'))

  placeToArea (place: google.maps.places.AutocompletePrediction): Promise<{
    coords: ICoordinates,
    extent: google.maps.LatLngBounds
  }> {
    return new Promise((resolve, reject) => {
      this._geocoder.geocode({ placeId: place.place_id }, (result, status) => {
        if (status.toString() !== 'OK') {
          reject('Could not find exact location')
        }
        const geometry = result[0].geometry
        const coords = {
          lon: geometry.location.lng(),
          lat: geometry.location.lat()
        }

        resolve({
          coords,
          extent: geometry.viewport
        })
      })
    })
  }

  findPlaces (input: string): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((res, rej) => {
      this._autocomplete.getPlacePredictions({ input }, (result, status) => {
        if (status.toString() === 'OK') res(result)
        else if (status.toString() === 'ZERO_RESULTS') res([])
        else rej(new AppError(status.toString()))
      })
    })
  }

  getPlace (placeId: string): Promise<google.maps.places.PlaceResult | undefined> {
    return new Promise((res, rej) => {
      this._places.getDetails({
        placeId
      }, (result, status) => {
        if (status.toString() === 'OK') res(result)
        else if (status.toString() === 'ZERO_RESULTS') res(undefined)
        else rej(new AppError(status.toString()))
      })
    })
  }

  findByCoordinate (coords: {lat: number, lng: number}) {
    return new Promise((res, rej) => {
      this._geocoder.geocode({ 'location': coords }, (result, status) => {
        if (status.toString() === 'OK') res(result)
        else if (status.toString() === 'ZERO_RESULTS') res([])
        else rej(new AppError(status.toString()))
      })
    })
  }

}
