import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { HighlightService } from '@services/core/highlight/highlight.service'
import { Subscription } from 'rxjs'

@Directive({
  selector: '[highlight]'
})
export class HighlightDirective implements OnDestroy, OnInit {
  @Input() text?: string
  private _subscriptions = new Subscription()

  constructor (
    private _el: ElementRef<HTMLElement>,
    private _highlightService: HighlightService
  ) {}

  ngOnInit () {
    this._subscriptions.add(
      this._highlightService.textChanged.subscribe(exp => {
        const value = this.text || ''
        this._el.nativeElement.innerHTML = !exp
        ? `<span>${value}</span>`
        : this.buildHighlight(value, exp)
      })
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  buildHighlight (value: string, exp: string) {
    return `<span>${
      value.replace(new RegExp(exp, 'gi'), match => {
        return '<span class="st-highlight-text">' + match + '</span>'
      })
    }</span>`
  }
}
