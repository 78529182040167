<h2 mat-dialog-title>
  <mat-icon>warning</mat-icon>
  Alert
</h2>

<div mat-dialog-content>
  <p *ngFor="let data of dataRef">{{data.text}}</p>
</div>

<div class="st-button-row">
  <div *ngIf="!keep" class="countdown-wrapper">
    <div [@countdownTrigger]="animationOn ? 'stop' : 'start'" class="countdown"></div>
    <button color="primary" mat-button (click)="keepDialog()">Keep</button>
  </div>
  <button color="primary" mat-button [mat-dialog-close]>Close</button>
</div>
