import { MatDialogRef } from '@angular/material/dialog'

export class DialogCleanup {
  _dialogs?: MatDialogRef<any>[]

  _trackDialog (dialog: MatDialogRef<any>) {
    if (!this._dialogs) this._dialogs = []
    this._dialogs.push(dialog)
    dialog.afterClosed().subscribe(() => this._untrackDialog(dialog))
    return dialog
  }

  _untrackDialog (dialog: MatDialogRef<any>) {
    if (this._dialogs) this._dialogs.splice(this._dialogs.indexOf(dialog), 1)
    return dialog
  }

  _destroyDialogs () {
    if (this._dialogs) {
      for (const d of [...this._dialogs]) {
        d.close()
      }
    }
  }
}
