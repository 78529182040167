import { Component, Inject } from '@angular/core'
import { IUploadFileInfo } from '@core/types/upload-file-info'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NgxTableColumn } from '@core/types/ngx-table-column'
import { SettingsService } from '@services/core'
import { MatTableDataSource } from '@angular/material/table'

@Component({
  selector: 'app-upload-info-dialog',
  templateUrl: './upload-info-dialog.component.html',
  styleUrls: ['./upload-info-dialog.component.scss']
})
export class UploadInfoDialogComponent {

  dataSource = new MatTableDataSource<IUploadFileInfo['columns'][0]>([])
  displayedColumns = ['name', 'optional', 'type', 'description']
  stickyHeader = true

  static setup (info: IUploadFileInfo) {
    return {
      data: info
    }
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IUploadFileInfo,
    private _settingsService: SettingsService
  ) {
    this.stickyHeader = !this._settingsService.isIEorEdge(false)
    this.dataSource = new MatTableDataSource(data.columns)
  }
}
