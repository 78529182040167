import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { SolidColorPickerDialogComponent } from './solid-color-picker-dialog/solid-color-picker-dialog.component'

@Component({
  selector: 'app-solid-color-picker',
  templateUrl: './solid-color-picker.component.html',
  styleUrls: ['./solid-color-picker.component.scss']
})
export class SolidColorPickerComponent implements OnDestroy, DialogCleanup {
// DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog (dialog: MatDialogRef<any>): any {
    return
  }
  _untrackDialog (dialog: MatDialogRef<any>): any {
    return
  }
  _destroyDialogs (): any {
    return
  }

  @Input() color?: string
  @Output() colorChange: EventEmitter<any> = new EventEmitter()

  constructor (
  private _dialog: MatDialog
) {}

  ngOnDestroy () {
    this._destroyDialogs()
  }

  openDialog () {
    this._dialog.open(SolidColorPickerDialogComponent, SolidColorPickerDialogComponent.setOptions(this.color || 'transparent')
    ).afterClosed().subscribe(res => {
      if (res) {
        this.color = res
        this.colorChange.next(this.color)
      }
    })
  }
}

applyMixins(SolidColorPickerComponent, [DialogCleanup])
