
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { AppLayer } from '@core/models/layer'
import { NgxTableColumn } from '@core/types/ngx-table-column'
import { CommonUtil, ConvertUtil } from '@core/utils'
import { WidgetLayerService } from '@services/workspace/workspace-map/widget-layer.service'
import BaseLayer from 'ol/layer/Base'
import { Db } from '@vip-shared/models/db-definitions'
import { EmissionsJourneysService } from '../../services/journeys.service'
import { Subscription } from 'rxjs'

interface NgxTableRow {
  [key: string]: any
}

@Component({
  selector: 'app-emissions-table-widget',
  templateUrl: './emissions-table-widget.component.html',
  styleUrls: ['./emissions-table-widget.component.scss'],
  providers: [WidgetLayerService]
})

export class EmissionsTableWidgetComponent implements OnInit, OnDestroy {
  @Input()
  refreshData = () => {
    let x = 0
  }

  dataSource?: {
    columns: NgxTableColumn[]
    rows: NgxTableRow[]
  }

  journeys: Db.Vip.Emissions.IJourney[] = []
  selectedJourney!: Db.Vip.Emissions.IJourney

  layerId: string = 'default'
  targetLayer?: AppLayer
  private _subscriptions = new Subscription()

  get showTimeSlider() {
    // add group layer condition here
    return false
    // return !!this.targetLayer && this.targetLayer.isTimeSeries
  }

  constructor (public layerService: WidgetLayerService, public journeysService: EmissionsJourneysService) {
    this._subscriptions.add(
      this.layerService.newLayer.subscribe(async layer => {
        const targetId = this.layerId.toString()
        if (layer.layer instanceof BaseLayer && layer.id === targetId) {
          this.targetLayer = this.layerService.getById(`${layer.id}`)
        }
      })
    )
  }

  ngOnInit() {
    this._subscriptions.add(
      this.journeysService.journeysChanged.subscribe(journeys => {
        this.journeys = journeys
          this.LoadData()
      })
    )
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
  }

  private LoadData(): void {
    this.dataSource = {
      rows: [],
      columns: []
    }
    const rows: any = this.journeys
    const header = this.journeys.length ? Object.keys(this.journeys[0]) : []
    const columns = header.map(h => {
      return {name: h.charAt(0).toUpperCase() + h.slice(1), prop: h}
    })
    this.dataSource = {
      rows,
      columns
    }
  }

  export () {
    let csvContent = ''
    if (this.dataSource) {
      const columns = this.dataSource.columns

      const columnsNames = columns.map(column => column.name)
      const columnOgs = columns.map(column => column.prop)
      const dataToSave = this.dataSource.rows.map((row) => {
        return columnOgs.map(x => {
          let data = row[x]
          if (typeof data === 'string' && data.includes(',')) data = `\"${data}\"`
          return data
        })
      })
      ; [columnsNames, ...dataToSave].forEach((rowArray: any) => {
        const row = rowArray.join(',')
        csvContent += row + '\r\n'
      })
      CommonUtil.downloadBlob(csvContent, `Emissions - ${new Date().toLocaleDateString()}.csv`, 'csv')
    }
  }
}
