import { Component, OnInit } from '@angular/core'
import * as _ from 'lodash'
import 'chartjs-adapter-moment'
import { GeoJSON } from 'geojson'

import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { LayerService } from '@services/workspace'
import { IRasterChartOptions } from '@core/types/workspace/raster-chart-options'

@Component({
  selector: 'app-emissions-raster-chart-widget',
  templateUrl: './emissions-raster-chart-widget.component.html',
  styleUrls: ['./emissions-raster-chart-widget.component.scss']
})
export class EmissionsRasterChartWidgetComponent implements OnInit {

  layerId: string = 'default'
  queryId: string = 'default'
  layerRaster?: AppRasterLayer
  geojson?: GeoJSON
  prevGeom?: any
  options?: IRasterChartOptions

  constructor(private layerService: LayerService) {
  }

  ngOnInit() {
    this.load()
  }

  private async load() {
    this.options = {
      layerId: this.layerId,
      queryId: this.queryId,
      layerRaster: this.layerRaster,
      geojson: this.geojson,
      prevGeom: this.prevGeom,
      isDialogMode: false
    }
    if (this.layerRaster) return
    // Load layer raster if not provided
    const layer = await this.layerService.getById(this.layerId)
    this.options.layerRaster = layer as AppRasterLayer
  }

}
