<app-file-drag-drop type="button" (onFilesDropped)="sortGeometryFiles($event)"
[multiple]="true" [accept]="acceptFormats" [captureWindow]="captureWindow"
[buttonText]="buttonText">

</app-file-drag-drop>

<button mat-icon-button color="primary" [class.warning]="pendingCount" *ngIf="pendingCount" (click)="listUploadedFiles($event)">
    <mat-icon>list</mat-icon>
    <span class="pending-count" *ngIf="pendingCount"
      matTooltip="{{pendingCount}} missing file{{pendingCount > 1 ? 's' : ''}}">{{pendingCount}}</span>
</button>
