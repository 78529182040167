<div class="main-wrapper">
  <div
    class="indicator-wrapper"
    *ngIf="zoomedIn"
    [ngStyle]="{ 'width.px': indicatorWrapperWidth, 'height.px': indicatorWrapperHeight }"
  >
    <div
      class="indicator"
      [style.width.px]="indicatorWidth"
      [style.height.px]="indicatorHeight"
      [style.top.px]="indicatorPt"
      [style.left.px]="indicatorPl"
    ></div>
  </div>

  <img #preview (error)="onError()" (load)="renderCroppie(preview)" [src]="urlToLoad">

  <app-loader-backdrop loadingText="Loading image..." *ngIf="!loaded"></app-loader-backdrop>
</div>
