<div id="attribute-table-panel">
  <div id="table-toolbar">
    <mat-icon [matMenuTriggerFor]="actionMenu" matTooltip="Table options">more_vert</mat-icon>
  </div>
  <mat-menu #actionMenu="matMenu">
    <button mat-menu-item (click)="export()" matTooltip="Export visible table data">Export Table</button>
  </mat-menu>
  <ngx-datatable #table id="ngx-table" class="material st-compact-table"
          [headerHeight]="40" [footerHeight]="20" [rowHeight]="35" [scrollbarV]="true" columnMode="standard"
          [rows]="dataSource?.rows" [columns]="dataSource?.columns"
          [reorderable]="true"
          [cssClasses]="{sortAscending: 'material-icons arrow-up', sortDescending: 'material-icons arrow-down'}">
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
              <div class="paging-info" *ngIf="rowCount > 1">
                <span>Showing {{ (offset * pageSize)+1 | number:'.0-0'}} -
                  {{ (rowCount > (curPage * pageSize)) ? (curPage*pageSize) : (rowCount) | number:'.0-0' }} of {{ rowCount | number:'.0-0'}}</span>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
</div>

