import { Injectable } from '@angular/core'
import * as xml2js from 'xml2js'
import ImageLayer from 'ol/layer/Image'
import ImageWMS from 'ol/source/ImageWMS'
import * as moment from 'moment'

import { environment } from 'environments/environment'

import { ISsgpLayer } from '@core/types/'

import { ICoverageStore, ICoverageStoresResponse } from './interface/index'

import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AlertService } from '@services/core/alert/alert.service'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { GsiGeoserver } from '@vip-shared/models/const/geoserver'
@Injectable({
  providedIn: 'root'
})
export class AirLayersService {
  private _layersXmlUrl?: string
  private _regex?: RegExp
  sameDayRaster = true

  pollutionLayer = new ImageLayer({
    visible: true,
    opacity: 0.9,
    source: null as any
  })

  constructor (
        private _api: VipApiService,
        private _alertService: AlertService,
        private _workspaceService: WorkspaceService
    ) { }

  async loadPollutionLayerFor (routeDate: Date) {
    const region = this._workspaceService.regionName
    if (region && environment.UHL.xmlURL) {
      this._layersXmlUrl = `${GsiGeoserver.Placeholder}/rest/` +
            `workspaces/${(environment.UHL.xmlURL as any)[region]}/coveragestores.xml`

      if (region.toLowerCase() === 'bicester') {
        this._regex = new RegExp(`^NO2_BICESTER_SSGP_ES_v3_(\\d{4}_\\d{2}_\\d{2}_\\d{4})_WGS84$`)
      } else if (region.toLowerCase() === 'belfast') {
        this._regex = new RegExp(`^NO2_Belfast_ES_(\\d{4}_\\d{2}_\\d{2}_\\d{4})_WGS84$`)
      }
    }

    if (!this._layersXmlUrl) {
      return
    }
    this.sameDayRaster = true

    const date = moment(routeDate)
    const xmlText = await this._api.orm.Proxy(
      this._workspaceService.proxyAppendWsQuery(
        Base64.encodeURI(this._api.appendJwtQuery(`${this._layersXmlUrl}?`))
      )
    ).get().run({ responseType: 'text' })

    if (!xmlText) {
      this._alertService.log('Failed to retrieve pollution layers.')
      return
    }
    const xmlJson: ICoverageStoresResponse = await this.ParseXmlToJson(xmlText) as ICoverageStoresResponse
    const layers: (ISsgpLayer | undefined)[] = xmlJson.coverageStores.coverageStore.map((x: ICoverageStore) => {
      const name = x.name[0]
      if (!this._regex) { return undefined }
      const match = name.match(this._regex)
      if (!match) { return undefined }

      return {
        name,
        date: moment(match[1], 'YYYY_MM_DD_HHmm')
      }
    })
    let datedLayers = layers.filter(x => x) as ISsgpLayer[]
    datedLayers = datedLayers.sort((a, b) => a.date.isAfter(b.date) ? -1 : 1)
    let nearestLayer = this.GetNearest(datedLayers, date)
    if (!nearestLayer) {
      this.sameDayRaster = false
      const oldestDate = datedLayers[datedLayers.length - 1].date
      const olderDate = moment(date)

      while (!nearestLayer && olderDate.isSameOrAfter(oldestDate)) {
        nearestLayer = this.GetNearest(datedLayers, olderDate.subtract(1, 'w'))
      }
    }

    this.clearLayer()

    if (nearestLayer) {
      this.UpdateLayerSource(nearestLayer.name)
    } else {
      this._alertService.log('No pollution data found for selected time')
    }
  }

  clearLayer () {
    this.pollutionLayer.setSource(null as any)
  }

  private UpdateLayerSource (layerName: string) {
    const region = this._workspaceService.regionName
    if (region && environment.UHL.tiffDir) {
      this.pollutionLayer.setSource(new ImageWMS({
        url: (environment.UHL.tiffDir as any)[region],
        params: {
          LAYERS: `UHL:${layerName}`,
          TILED: true,
          VERSION: '1.1.0'
        },
        serverType: 'geoserver',
        projection: 'string',
        crossOrigin: 'anonymous',
        imageLoadFunction: async (image, src) => {
          const img = image.getImage() as any
          img.src = `${environment.VIPServer}${this._api.orm.Proxy(
            this._workspaceService.proxyAppendWsQuery(
              Base64.encodeURI(this._api.appendJwtQuery(`${src}&`))
            )
          ).endpoint}`
        }
      }))
    }
  }

  changeOpacity (opacity: number) {
    this.pollutionLayer.setOpacity(opacity)
  }

  private GetNearest (layers: any[], date: moment.Moment): any {
    const layerSameOrAfter = layers.find(l => l.date.diff(date, 'm') <= 60 && l.date.diff(date, 'm') >= 0)
    const layerSameOrBefore = layers.find(l => l.date.diff(date, 'm') >= -60 && l.date.diff(date, 'm') <= 0)

    let nearestLayer
    if (layerSameOrAfter) {
      nearestLayer = layerSameOrAfter
      if (layerSameOrBefore && Math.abs(layerSameOrBefore.date.diff(date)) <= layerSameOrAfter.date.diff(date)) {
        nearestLayer = layerSameOrBefore
      }
    } else if (layerSameOrBefore) {
      nearestLayer = layerSameOrBefore
    }
    return nearestLayer
  }

  private async ParseXmlToJson (text: string) {
    return new Promise((resolve, reject) => {
      xml2js.parseString(text, (err: any, result: any) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }
}
