<app-ol-map-timeslider
  *ngIf="showTimeSlider"
  [layerId]="layerId"
  [layerService]="layerService">
</app-ol-map-timeslider>
<div class="map-wrapper">
  <app-ol-map-widget
  [layerId]="layerId"
  [layerService]="layerService"
  [workspaceMapService]="workspaceMapService"
  [options]="options">
</app-ol-map-widget>
<app-ol-map-overlay
  [layerId]="layerId"
  [layerService]="layerService"
  [workspaceMapService]="workspaceMapService"
  [options]="options">
</app-ol-map-overlay>
</div>

