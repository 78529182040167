import { Injectable } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { IPNewCustomer, IPUpdateCustomer, IPCustomerMediaAssets } from '@vip-shared/interfaces/api/api-body-types'
import { ThemeService } from '@services/core/theme/theme.service'
import { AuthService } from '@services/core'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private activeCustomerObservable: BehaviorSubject<Db.Vip.Cst.ICustomer | undefined> = new BehaviorSubject<Db.Vip.Cst.ICustomer | undefined>(undefined)
  private _customersUpdated: Subject<void> = new Subject()

  get activeCustomer () {
    return this._authService.customer
  }

  get phone () {
    return (this.activeCustomer && this.activeCustomer.phone_number) || '016 7546 4410'
  }

  get email (): string {
    return (this.activeCustomer && this.activeCustomer.contact_email) || 'support@geospatial-insight.com'
  }

  get web (): string {
    return (this.activeCustomer && this.activeCustomer.website) || 'www.geospatial-insight.com'
  }

  get showPoweredBy (): boolean {
    return this.activeCustomer ? this.activeCustomer.gsi_branding : true
  }

  get showVipLogos (): boolean {
    return this.activeCustomer ? this.activeCustomer.vip_branding : true
  }

  get privacyPolicyUrl (): string {
    return (this.activeCustomer && this.activeCustomer.privacy_policy_link) ||
      'https://www.geospatial-insight.com/privacy-policy/'
  }
  get termsAndConditionsUrl (): string {
    return (this.activeCustomer && this.activeCustomer.terms_and_conditions_link) ||
      'https://www.geospatial-insight.com/terms-conditions/'
  }

  get userGuideUrl (): string | undefined {
    return this.activeCustomer && this.activeCustomer.user_guide
  }

  constructor (
    private _api: VipApiService,
    private _themeService: ThemeService,
    private _authService: AuthService
  ) {
    this._authService.sessionActive.subscribe(active => {
      if (this.activeCustomer) {
        this._themeService.applyTheme(this.activeCustomer.theme || this._themeService.defaultTheme)
        this._themeService.vipBranding = this.activeCustomer.vip_branding
        this._themeService.gsiBranding = this.activeCustomer.gsi_branding
        this._themeService.updateLogo(this.activeCustomer)
      } else {
        this._themeService.applyTheme('base')
      }
    })
  }

  get activeCustomerChange (): Observable<Db.Vip.Cst.ICustomer | undefined> {
    return this.activeCustomerObservable.asObservable()
  }

  get customersUpdated (): Observable<void> {
    return this._customersUpdated.asObservable()
  }

  async addCustomer (
    customer: IPNewCustomer,
    files?: IPCustomerMediaAssets
  ) {
    const result = await this._api.orm.Customers().add(customer, files).run()
    if (result) {
      this._customersUpdated.next()
      return result
    }
  }

  async updateCustomer (customerId: string, body: IPUpdateCustomer, files?: IPCustomerMediaAssets) {
    const result = await this._api.orm.Customers().Customer(customerId).update(body, files).run()
    if (result) {
      this._customersUpdated.next()
      return result
    }
  }
}
