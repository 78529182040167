<div class="header">
  <div class="session-info" *ngIf="!compact">
    <ng-container [ngTemplateOutlet]="sessionInfo"></ng-container>
  </div>

  <ng-container *ngIf="compact" [ngTemplateOutlet]="sessionInfo"></ng-container>

  <div [ngClass]="compact ? 'header-navigation header-navigation-compact' : 'header-navigation'">
    <ng-container *ngIf="!compact" [ngTemplateOutlet]="navigationOptions"></ng-container>
    <mat-icon *ngIf="compact" (click)="menuExpanded = !menuExpanded">{{menuExpanded ? 'menu_open' : 'menu'}}</mat-icon>
  </div>
</div>

<div class="warnings">
  <div *ngFor="let warn of warnings" [matTooltip]="warn.tooltip">
    <mat-icon>warning</mat-icon>
    <p>{{warn.name}}</p>
  </div>
</div>

<div class="dropdown-menu" *ngIf="menuExpanded">
  <ng-container [ngTemplateOutlet]="navigationOptions"></ng-container>
</div>


<ng-template #sessionInfo>
  <app-ratio-box [paddingTop]="logoHeight" [class.horizontal]="horizontalHeader">
      <app-logo *ngIf="companyName" class="box-content"
    logoSelector="header_bar"></app-logo>
  </app-ratio-box>
  <form [formGroup]="form">
  <mat-form-field  *ngIf="layout === 'default'" [ngClass]="compact ? 'search-bar search-bar-compact' : 'search-bar'" appearance="outline">
    <mat-icon>search</mat-icon>
    <input matInput formControlName="searchText" placeholder=" Search by title, tag or ID">
    <mat-icon *ngIf="form.value.searchText?.length" (click)="$event.stopPropagation(); form.controls['searchText'].setValue('')">clear</mat-icon>
  </mat-form-field>
  </form>
</ng-template>

<ng-template #navigationOptions>
  <ng-container *ngIf="compact">
    <button
    mat-menu-item
        *ngIf="layout === 'default'"
        matTooltip="Filter workspaces"
        (click)="openFilterDialog()">
       Filter Workspaces
      </button>
    <button
    mat-menu-item
        *ngIf="layout === 'default' && sysMaintainer"
        matTooltip="Upload and manage datasets"
        (click)="openDatasetManager()">
        Add PV Dataset
    </button>
    <button
    mat-menu-item
    *ngIf="layout === 'default' && canCreateWorkspace"
          matTooltip="Create new workspace"
          (click)="createNewWorkspace()">
        Create Workspace
    </button>
    <button mat-menu-item matTooltip="User menu" [matMenuTriggerFor]="menu">
        User menu
    </button>
  </ng-container>
  <ng-container *ngIf="!compact">
    <button
        *ngIf="layout === 'default'"
        mat-mini-fab
        color="primary"
        matTooltip="Filter workspaces"
        (click)="openFilterDialog()">
        <mat-icon aria-label="Open filter workspaces">filter_list</mat-icon>
      </button>
    <button
        *ngIf="layout === 'default' && sysMaintainer"
        mat-mini-fab
        color="accent"
        matTooltip="Upload and manage datasets"
        (click)="openDatasetManager()">
        <mat-icon aria-label="Dataset manager">post_add</mat-icon>
      </button>

      <button *ngIf="layout === 'default' && canCreateWorkspace"
          mat-mini-fab
          color="primary"
          matTooltip="Create new workspace"
          (click)="createNewWorkspace()">
        <mat-icon aria-label="New Workspace">add</mat-icon>
      </button>
      <button mat-mini-fab color="accent" matTooltip="User menu" [matMenuTriggerFor]="menu">
        <mat-icon aria-label="User menu">person_outline</mat-icon>
      </button>
  </ng-container>
    <mat-menu #menu="matMenu">
      <div class='name-item' mat-menu-item>{{fullName}}</div>
      <button *ngIf="layout === 'default'" mat-menu-item routerLink="/user">
        User area
      </button>
      <button *ngIf="layout === 'user-panel'" mat-menu-item routerLink="/explorer">
        Back
      </button>
      <button mat-menu-item (click)="openReportForm()">
        Report Bug
      </button>
      <button mat-menu-item routerLink="/guide">
        Guide
      </button>
      <button mat-menu-item routerLink="/guide/testing" *ngIf="staging">
        Testing Guide
      </button>

      <button mat-menu-item (click)="onLogout()">Logout</button>
    </mat-menu>
</ng-template>
