<div class="st-customer-background">
  <div class="auth-card-wrapper">
    <mat-card class="mat-elevation-z5">
      <app-powered-by>
        <app-logo logoSelector="auth_page"></app-logo>
      </app-powered-by>

      <mat-card-content>
        <div *ngIf="!submitted; else confirm">
          <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>
          <p>Please enter a new password.</p>

          <form [formGroup]="form">
            <mat-form-field>
              <input matInput type="password" formControlName="password" placeholder="New password">
            </mat-form-field>

            <mat-form-field>
              <input matInput type="password" formControlName="repeatPassword" placeholder="Repeat new password">
            </mat-form-field>
          </form>

          <div class="st-button-row">
            <app-submit-button text="Reset" [disabled]="!form.valid" [loading]="saving" (submit)="submit()">
            </app-submit-button>
          </div>
        </div>

        <ng-template #confirm>
          <h3>Thank you</h3>
          <div class="message">Your password has been changed.</div>
        </ng-template>

      </mat-card-content>

      <mat-card-footer class="st-wide">
        <ul>
          <li>
            <a routerLink="/login">Back</a>
          </li>
        </ul>
      </mat-card-footer>

    </mat-card>
    <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
  </div>
</div>
