import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})

export class DeleteButtonComponent {
  @Input() text = 'Delete'
  @Input() disabled = false
  @Input() loading = false
  @Output() delete = new EventEmitter()

  onClick () {
    if (!this.loading) {
      this.delete.emit()
    }
  }
}
