import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms'
import { Db } from '@vip-shared/models/db-definitions'
import { AlertService, VipApiService } from '@services/core'
import { IRPresetFilterOption } from '@vip-shared/interfaces'
import { handleError } from '@core/models/app-error'
import { Subscription, merge } from 'rxjs'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { auditTime, tap } from 'rxjs/operators'
import * as moment from 'moment'
import FormValidators from '@core/utils/form-validators/form-validators'
import { ScraperForm } from '@core/types/forms'
import { ScraperPresets } from '@vip-shared/models/const/scraper-presets'

type Form = TypedFormGroup<{
  presetName: TypedFormControl<string>
  filters: UntypedFormGroup
  mode?: TypedFormControl<Db.Vip.LayerMode>
  datasetsQuery: TypedFormControl<any>,
  datasetSelection: UntypedFormGroup,
  datetimeSelection: UntypedFormGroup
}>


@Component({
  selector: 'app-scraper-preset-config',
  templateUrl: './scraper-preset-config.component.html',
  styleUrls: ['./scraper-preset-config.component.scss']
})
export class ScraperPresetConfigComponent implements OnInit {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs(): any { return }

  private _subscriptions = new Subscription()
  private _scrapperFromSubscriptions = new Subscription()

  formControlKeys = ['presetName', 'filters', 'mode', 'datasetsQuery', 'datasetSelection', 'scraperForm']

  @Output() formChange = new EventEmitter<Form>()
  @Output() selectedPreset = new EventEmitter<Db.Vip.Geo.ILayerPreset>()
  @Input() productId!: number
  @Input() allowModes = true
  @Input() onlyPresets: Db.Vip.LayerPreset[] = []

  @Input() scraperForm?: ScraperForm
  timeIntervals: Db.Helper.Geo.TimeInterval[] = ['minute', 'hour', 'day', 'month']


  form: Form
  datasetSelectionForm: UntypedFormGroup
  filtersForm: UntypedFormGroup
  stickyHeader = true

  preset = Db.Vip.LayerPreset

  backDateDays: number = 0
  presetName: string = ''

  fetchingData = false
  layerPresets: Db.Vip.Geo.ILayerPreset[] = []
  filters: Db.Vip.Geo.IPresetFilter[] = []
  filterControls: {
    filterTag: string
    control: UntypedFormControl
    options: IRPresetFilterOption[]
    filteredOptions: IRPresetFilterOption[]
    name: string
    multiple: boolean
    visible: () => boolean
  }[] = []

  // Append disabled flag - to disallow using modes for which there are
  // no guides/algorithms
  modes: (Db.Vip.Geo.ILayerMode & { disabled?: boolean })[] = []
  presetDatasets: (Db.Vip.Geo.IPresetDataset & { disabled?: boolean, disabledReason?: string })[] = []

  private _mlDatasets?: Db.Vip.PV.IMlDataset[]
  filteredMlDatasets?: Db.Vip.PV.IMlDataset[]
  private _anotGuideSets?: Db.Vip.PV.IAnotGuideSet[]
  filteredAnotGuideSets?: {
    withMeasures?: Db.Vip.PV.IAnotGuideSet[]
    withoutMeasures?: Db.Vip.PV.IAnotGuideSet[]
  }
  get guideSetCount() {
    return this._anotGuideSets && this._anotGuideSets.length
  }

  requireAnnotationGuide = false
  requireMlDataset = false

  selectedQuery?: any

  get selectedMode(): 'read' | 'write' | undefined {
    return this.allowModes ? (
      this.form.controls.mode.value &&
      (this.form.controls.mode.value === Db.Vip.LayerMode.READ_ONLY ? 'read' : 'write')
    ) : 'read'
  }

  locationWarning?: string

  constructor(
    private _alertService: AlertService,
    private _api: VipApiService,
    private _dialog: MatDialog
  ) {
    this.form = new TypedFormGroup({
      presetName: new TypedFormControl<string>(undefined, [Validators.required]),
      filters: new UntypedFormGroup({}),
      datasetsQuery: new TypedFormControl(undefined),
      datasetSelection: new UntypedFormGroup({}),
      datetimeSelection: new UntypedFormGroup({})
    })

    this.datasetSelectionForm = this.form._controls.datasetSelection
    this.filtersForm = this.form._controls.filters

    this._subscriptions.add(
      merge(
        this.form.statusChanges,
        this.form.valueChanges,
        this.filtersForm.valueChanges,
        this.datasetSelectionForm.valueChanges
      ).pipe(auditTime(1000)).subscribe(() =>
        this.formChange.next(this.form)
      )
    )
  }

  ngOnInit() {
    if (this.allowModes) {
      this.form.addControl('mode', new TypedFormControl<Db.Vip.LayerMode>(
        Db.Vip.LayerMode.READ_ONLY,
        Validators.required
      ))
    }
    this.LoadPresets()
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
    this._destroyDialogs()
  }

  placeholder(title: string, items: number) {
    return `${title}${items ? '' : ' (None Available)'}`
  }


  private async LoadPresets() {
    this.fetchingData = true
    try {
      let presets = await this._api.orm.Products().Product(this.productId)
        .LayerPresets().get().run()

      this.layerPresets = (this.onlyPresets && this.onlyPresets.length) ?
        presets.filter(p => this.onlyPresets.includes(p.layer_preset_tag as any)) :
        presets

      if (this.layerPresets.length === 1) {
        this.form._controls.presetName.setValue(this.layerPresets[0].layer_preset_tag)
        this.form._controls.presetName.disable()
        this.presetChange(this.layerPresets[0].layer_preset_tag as Db.Vip.LayerPreset)
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    this.fetchingData = false
  }

  private UpdatePresetSpecificNotes(presetTag: Db.Vip.LayerPreset) {
    this.locationWarning = undefined

    switch (presetTag) {
      case Db.Vip.LayerPreset.GOOGLE_NEWS:
        this.locationWarning = [
          `Please note that Google News does not specify location of article. Therefore all results, returned for location,`,
          `will be assigned to selected location even if they did not originate there.`
        ].join(' ')
        break
      case Db.Vip.LayerPreset.TWITTER:
        this.locationWarning = [
          `Please note that not all Tweets have geolocation information. Therefore some results, returned for location,`,
          `will be assigned to selected location even if they did not originate there.`
        ].join(' ')
        break
    }
  }

  private UpdatePresetBackDate(presetTag: Db.Vip.LayerPreset) {
    switch (presetTag) {
      case Db.Vip.LayerPreset.GOOGLE_NEWS:
        this.backDateDays = 0
        break
      case Db.Vip.LayerPreset.TWITTER:
        // NOTE : Twitter Premium can only fetch results 30 days prior to the current date
        this.backDateDays = 30
        break
      default:
        break
    }
  }

  private UpdatePresetTimeInterval(presetTag: Db.Vip.LayerPreset) {
    switch (presetTag) {
      case Db.Vip.LayerPreset.GOOGLE_NEWS:
        break
      case Db.Vip.LayerPreset.TWITTER:
        // Remove minute interval for twitter
        this.timeIntervals.splice(0, 1)
        break
      default:
        break
    }
  }


  async presetChange(presetTag: Db.Vip.LayerPreset) {
    this.presetName = presetTag
    const selectedPreset = this.layerPresets.find(p => p.layer_preset_tag === presetTag)
    this.selectedPreset.next(selectedPreset as Db.Vip.Geo.ILayerPreset)
    const triggerLoad = !this.fetchingData
    if (triggerLoad) this.fetchingData = true
    try {
      for (const key in this.datasetSelectionForm.controls) {
        this.datasetSelectionForm.removeControl(key)
      }

      this.UpdatePresetSpecificNotes(presetTag)

      this.UpdatePresetBackDate(presetTag)

      this.UpdatePresetTimeInterval(presetTag)

      if (ScraperPresets.includes(presetTag)) {
        if (!this.scraperForm) {
          this.scraperForm = new TypedFormGroup({
            name: new TypedFormControl<string>(undefined, Validators.required),
            where_exact: new TypedFormControl<string[]>(undefined),
            where_has: new TypedFormControl<string[]>(undefined),
            where_exclude: new TypedFormControl<string[]>(undefined),
            locations: new TypedFormControl<Db.Helper.Ext.Location[]>(
              undefined, [
              Validators.required,
              FormValidators.minLength(1)
            ]
            ),
            dateRanges: new TypedFormControl<{
              from: moment.Moment
              to: moment.Moment
            }[]>(
              undefined, [
              Validators.required,
              FormValidators.minLength(1)
            ]
            )
          }, FormValidators.atLeastOneOf(['where_has', 'where_exact']))
        }
        this.form.addControl('scraperForm', this.scraperForm)
        this.formChange.next(this.form)

        this._scrapperFromSubscriptions.add(
          this.scraperForm._controls.dateRanges.valueChanges.subscribe(() => {
            if (!this.scraperForm) return
            const ranges = this.scraperForm._controls.dateRanges.value
            const now = moment()
            this.toggleIntervalField(!ranges || !ranges.length ? false : ranges.some(r =>
              r.to.isAfter(now)
            ))

            const timeFormGroup = this.form.controls.datetimeSelection as UntypedFormGroup
            const timeSeriesRanges = timeFormGroup.controls
            if (
              (!timeSeriesRanges.valid_from || !timeSeriesRanges.valid_from.value) &&
              (!timeSeriesRanges.valid_to || !timeSeriesRanges.valid_to.value) && ranges
            ) {
              let fromControl = timeSeriesRanges.valid_from
              if (!fromControl) {
                fromControl = new UntypedFormControl(undefined)
                timeFormGroup.addControl('valid_from', fromControl)
              }
              let toControl = timeSeriesRanges.valid_to
              if (!toControl) {
                toControl = new UntypedFormControl(undefined)
                timeFormGroup.addControl('valid_to', toControl)
              }

              fromControl.setValue(
                moment.min(...ranges.map(r => r.from)).toISOString()
              )

              toControl.setValue(
                moment.max(...ranges.map(r => r.to)).toISOString()
              )

              if (!this.formControlKeys.includes('datetimeSelection')) {
                this.formControlKeys.push('datetimeSelection')
              }
              this.formChange.next(this.form)
            }
          })
        )
      } else {
        this._scrapperFromSubscriptions.unsubscribe()
        this._scrapperFromSubscriptions = new Subscription()
        this.form.removeControl('scraperForm')
        this.formChange.next(this.form)
        this.scraperForm = undefined
        this.formControlKeys.splice(this.formControlKeys.indexOf('datetimeSelection'), 1)
      }

    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    if (triggerLoad) this.fetchingData = false
  }

  toggleIntervalField(enabled: boolean) {
    if (!this.scraperForm) return
    if (!enabled) {
      if (this.scraperForm._controls.intervalValue) this.scraperForm.removeControl('intervalValue')
      if (this.scraperForm._controls.interval){} this.scraperForm.removeControl('interval')
      this.formChange.next(this.form)
    } else {
      if (!this.scraperForm._controls.intervalValue) {
        switch (this.presetName) {
          case Db.Vip.LayerPreset.GOOGLE_NEWS:
            this.scraperForm.addControl('intervalValue', new TypedFormControl<number>(1, Validators.required))
            break
          case Db.Vip.LayerPreset.TWITTER:
            this.scraperForm.addControl('intervalValue', new TypedFormControl<number>(2, [Validators.required,Validators.min(2)]))
            break
          default:
            break
        }
      }
      if (!this.scraperForm._controls.interval) {
        this.scraperForm.addControl('interval', new TypedFormControl<Db.Helper.Geo.TimeInterval>('hour', Validators.required))
      }
      if (this.scraperForm._controls.interval && this.scraperForm._controls.intervalValue) {
        this.scraperForm._controls.interval.valueChanges.subscribe((value) => {
          if (this.presetName !== Db.Vip.LayerPreset.TWITTER) return
            if (value !== "hour" ) {
              if (!this.scraperForm) return
              this.scraperForm._controls.intervalValue?.setValidators(Validators.required)
              this.scraperForm._controls.intervalValue?.setValue(1)
            } else {
              if (!this.scraperForm ) return
              this.scraperForm._controls.intervalValue?.setValidators(Validators.compose([Validators.required,Validators.min(2)]))
              this.scraperForm._controls.intervalValue?.setValue(2)
            }
        })
      }
    }
  }
}
