import { Db } from '@vip-shared/models/db-definitions'
import { CommonUtil, ColourUtil, ConvertUtil } from '@core/utils/index'
import * as olStyle from 'ol/style'
import { LayerUtil } from './layer.util'
import * as Color from 'color'
import { LayerStyleParameters, StyleParameters } from '@core/types/workspace/layers/style/style-parameters'
import OlUtil from '@core/utils/ol/ol.util'
import { DefaultStyleParameters } from '@vip-shared/models/layer-config/default-style-parameters'
import * as olColor from 'ol/color'
import IconOrigin from 'ol/style/Icon'
import { AppFeature } from '../ol-extend/app-feature'
interface ICustomColorsActive {
  fill: boolean
  stroke: boolean
}

export class LayerStyleUtil {
  static getDefaultStyle (sourceTypeId: Db.Vip.SourceType, previousStyle?: LayerStyleParameters) {
    let style: LayerStyleParameters | undefined
    if (previousStyle) style = previousStyle

    // For now default style is only supported for vectors
    if (sourceTypeId === Db.Vip.SourceType.RASTER) {
      style = CommonUtil.mergeDeep({
        border: { R: 0, G: 0, B: 0, A: 0 },
        borderWidth: 1,
        opacity: 1
      }, style || {}) as NonNullable<typeof style>
    } else {
      style = CommonUtil.mergeDeep({
        borderWidth: 1,
        opacity: 1
      }, style || {}, DefaultStyleParameters.VectorStyle) as NonNullable<typeof style>
    }

    if (sourceTypeId === Db.Vip.SourceType.DRONE_VECTOR) {
      delete style.border
      delete style.borderWidth
    }

    return style
  }

  static getCustomColorState (style: LayerStyleParameters, features: AppFeature[]): ICustomColorsActive {
    const customColorsActive = {
      fill: false,
      stroke: false
    }

    if (style.gradient) {
      if (style.gradient.fill && style.gradient.fill.active) {
        customColorsActive.fill = true
      }

      if (style.gradient.border && style.gradient.border.active) {
        customColorsActive.stroke = true
      }
    }

    if (!customColorsActive.fill) {
      customColorsActive.fill = features.some(feature => {
        const properties = feature.getProperties()
        return properties.customColors && properties.customColors.fill.length
      })
    }

    if (!customColorsActive.stroke) {
      customColorsActive.stroke = features.some(feature => {
        const properties = feature.getProperties()
        return properties.customColors && properties.customColors.border.length
      })
    }
    return customColorsActive
  }

  static stylePointFeature (
    style: StyleParameters,
    customColorsActive: ICustomColorsActive,
    invertColors?: boolean
  ): (feature: AppFeature) => olStyle.Style | null | olStyle.Style[] {
   // TODO: This is temp solution to invert points in geometry collection for property view
    const color = (rgb: [number, number, number, number]): [number, number, number, number] => {
      if (!invertColors) return rgb
      const color = ColourUtil.invertRgb({
        R: rgb[0],
        G: rgb[1],
        B: rgb[2]
      })
      color['A'] = rgb[3]
      return Object.values(color) as [number, number, number, number]
    }
    const styleCache: any = {}
    const useStyleCaching = !(customColorsActive.fill || customColorsActive.stroke)
    // Required to set correct zIndex of text inside of a cluster
    let zIndex = 0

    const pointStyle = (feature: AppFeature): olStyle.Style | null | olStyle.Style [] => {
      zIndex++
      let isSubstation = false

      const props = feature.getProperties()
      const features = OlUtil.deClusterFeatures([feature])
      if (features) {
        for (const f of features) {
          if (f.get('substation')) {
            isSubstation = true
            if (features.length === 1 && OlUtil.featureFilteredOut(features[0])) return null
            break
          }
        }
      }

      const size = LayerUtil.getFeaturesSize(feature)
      let pStyle: olStyle.Style | undefined = styleCache[size]

      if (pStyle) {
        const clone = pStyle.clone()
        clone.setZIndex(zIndex)
        return clone
      }

      if (Array.isArray(props.features) && size === 0) return null

      let fill: [number, number, number, number] = Object.values(DefaultStyleParameters.Fill) as any

      let image: olStyle.Icon | olStyle.Circle
      if (!isSubstation) {
        // NOTE: Fill values can contain 'NaN' - this means that they should be styled black
        // and not included in any colour calculations - like taking average colour for
        // fill of a cluster
        const styles = features.map(f => this.GetFeatureStyle(style, f, customColorsActive))

        const averageFill = ColourUtil.colorsAverage(styles.map(s => s.fill as any))
        const averageStroke = ColourUtil.colorsAverage(styles.map(s => s.stroke as any))

        const timeSeries = features.map(f => f.timeSeries)
        if (timeSeries.length) {
          const entityChange: {
            [key: string]: number
          } = {}

          for (const entry of timeSeries) {
            // If first item does not have change, others won't too
            if (!entry || CommonUtil.isUndefined(entry.change)) break
            entityChange[entry.entity] = entry.change
          }
          const entities = Object.keys(entityChange)
          if (entities.length > 0) {
            const change = Math.round(
              entities.reduce((sum, e) => sum + +entityChange[e], 0) / entities.length
            )
            const changeRateStyle = this.ChangeRateArrowStyle(change, style.point_radius)
            const gaugePointStyle = this.gaugeClusterStyle(style, color(averageFill as any), color(averageStroke as any), `${change}`)
            if (changeRateStyle) changeRateStyle.setZIndex(zIndex)
            gaugePointStyle.setZIndex(zIndex)
            return changeRateStyle ? [changeRateStyle, gaugePointStyle] : gaugePointStyle
          }
        }

        image = this.GetClusterImage(size, style, color(averageFill as any), color(averageStroke as any))
        fill = image.getFill().getColor() as any

      } else {
        const defaultColor: [number, number, number, number] = [255, 255, 0, 0]
        fill = style.fill ? [style.fill.R, style.fill.G, style.fill.B, style.fill.A] : defaultColor

        image = new olStyle.Icon({
          anchor: [0.5, 0],
          anchorOrigin: 'bottom-left',
          src: 'assets/icons/substation.png',
          color: color(fill),
          opacity: fill[3]
        })
      }

      if (size > 0) {
          // Clustering enabled
        const text = LayerUtil.getClusterText(size, false)
        pStyle = new olStyle.Style({
          image,
          text: new olStyle.Text({
            text: text,
            fill: new olStyle.Fill({
              color: ColourUtil.getContrastBWTextHex(color(fill))
            })
          })
        })

        if (useStyleCaching) {
          styleCache[size] = pStyle
        }
      } else {
        pStyle = new olStyle.Style({ image })

        if (useStyleCaching) {
          styleCache[0] = pStyle.clone()
        }
      }

      if (isSubstation) {
        pStyle.getImage().setScale(0.2)
      }
      pStyle.setZIndex(zIndex)
      return pStyle
    }

    return pointStyle
  }

  private static GetFeatureStyle (
    style: StyleParameters,
    feature: AppFeature,
    customColorsActive: ICustomColorsActive
  ): {
    fill: number[],
    stroke: number[]
  } {
    let fillOverride
    let strokeOverride

    if (customColorsActive.fill || customColorsActive.stroke) {
      let properties = feature.getProperties()
      if (properties.features) {
        properties = properties.features[0].getProperties()
      }
      if (properties.customColors) {
        const customColors = properties.customColors
        const fills = customColors.fill
        const strokes = customColors.border
        fillOverride = this.FindLastActiveOverride(fills).color
        strokeOverride = this.FindLastActiveOverride(strokes).color
      }
    }

    let fill: number[] = Object.values(fillOverride || style.fill || DefaultStyleParameters.Fill)
    let stroke: number[] = Object.values(strokeOverride || style.border || DefaultStyleParameters.Border)

    if (feature.invalidateFill) fill = [NaN, NaN, NaN, 1]
    if (feature.invalidateBorder) stroke = [NaN, NaN, NaN, 1]

    return { fill, stroke }
  }
  // POINT STYLE -->
  private static GetClusterImage (
    size: number,
    style: StyleParameters,
    fill: number[],
    stroke: number[]
  ): olStyle.Circle {
    const radius = LayerUtil.getClusterRadius(size, style.point_radius)

    const fillOptions = ConvertUtil.layerStyleToFillOptions({
      R: fill[0], G: fill[1], B: fill[2], A: fill[3]
    })
    const strokeOptions = ConvertUtil.layerStyleToStrokeOptions(
      {
        R: stroke[0], G: stroke[1], B: stroke[2], A: stroke[3]
      },
        Math.min(radius, style.borderWidth || 2)
      )

    const clusterImage = new olStyle.Circle({
      radius,
      stroke: new olStyle.Stroke(strokeOptions),
      fill: new olStyle.Fill(fillOptions)
    })

    return clusterImage
  }

  // TODO: Refactor to get average; Define types
  private static FindLastActiveOverride (overrides) {
    let activeOverride
    if (overrides && Array.isArray(overrides) && overrides.length) {
      for (let i = overrides.length - 1; i >= 0; i -= 1) {
        if (overrides[i].active) {
          activeOverride = overrides[i]
          break
        }
      }
    }

    return activeOverride || {}
  }
  // <-- POINT STYLE

  static styleLineFeature (
    style: StyleParameters,
    customColorsActive: ICustomColorsActive
  ): (feature: AppFeature) => olStyle.Style | null {

    const fillOptions = ConvertUtil.layerStyleToStrokeOptions(style.fill || DefaultStyleParameters.Fill, style.lineWidth)

    const stroke = new olStyle.Stroke(fillOptions)
    const lineStyle = new olStyle.Style({ stroke })

    const lineStyleFunction = (feature: AppFeature): olStyle.Style | null => {
      if (OlUtil.featureFilteredOut(feature)) return null

      const properties = feature.getProperties()
      if (customColorsActive.fill) {
        if (properties.customColors) {
          const fills = properties.customColors.fill
          const cColor = this.FindLastActiveOverride(fills).color
          if (customColorsActive.fill && cColor) {
            lineStyle.setStroke(new olStyle.Stroke(ConvertUtil.layerStyleToStrokeOptions(cColor, style.lineWidth)))
            if (feature.zIndex !== undefined) lineStyle.setZIndex(feature.zIndex)
            return lineStyle
          }
        }
        lineStyle.setStroke(new olStyle.Stroke(fillOptions))
      }
      if (feature.zIndex !== undefined) lineStyle.setZIndex(feature.zIndex)
      return lineStyle
    }

    return lineStyleFunction
  }

  static stylePolygonFeature (
    style: StyleParameters,
    customColorsActive: ICustomColorsActive
  ): (feature: AppFeature) => olStyle.Style | null {
    const fillOptions = ConvertUtil.layerStyleToFillOptions(style.fill || DefaultStyleParameters.Fill)
    const strokeOptions = ConvertUtil.layerStyleToStrokeOptions(style.border || DefaultStyleParameters.Border, style.borderWidth)

    const fill = new olStyle.Fill(fillOptions)
    const stroke = new olStyle.Stroke(strokeOptions)

    const polygonStyle = new olStyle.Style({
      fill,
      stroke
    })

    const polygonStyleFunction = (feature: AppFeature): olStyle.Style | null => {
      if (OlUtil.featureFilteredOut(feature)) return null
      const properties = feature.getProperties()
      if (customColorsActive.fill || customColorsActive.stroke) {
        if (properties.customColors) {
          const fills = properties.customColors.fill
          let cColor = this.FindLastActiveOverride(fills).color
          if (customColorsActive.fill && cColor) {
            polygonStyle.setFill(new olStyle.Fill(ConvertUtil.layerStyleToFillOptions(cColor)))
          } else {
            polygonStyle.setFill(new olStyle.Fill(fillOptions))
          }

          const strokes = properties.customColors.border
          cColor = this.FindLastActiveOverride(strokes).color
          if (customColorsActive.stroke && cColor) {
            polygonStyle.setStroke(new olStyle.Stroke(ConvertUtil.layerStyleToStrokeOptions(cColor, style.borderWidth)))
          } else {
            polygonStyle.setStroke(new olStyle.Stroke(strokeOptions))
          }
        } else {
          polygonStyle.setFill(new olStyle.Fill(fillOptions))
          polygonStyle.setStroke(new olStyle.Stroke(strokeOptions))
        }
      }

      if (feature.zIndex !== undefined) polygonStyle.setZIndex(feature.zIndex)
      return polygonStyle
    }

    return polygonStyleFunction
  }

  static getMeansOfTransportStyle (meansOfTransport: string, colorOverride?: olColor.Color): olStyle.Style {
    let style: olStyle.Style = new olStyle.Style()
    switch (meansOfTransport.toLowerCase()) {
      case 'car':
        style = new olStyle.Style({
          stroke: new olStyle.Stroke({
            width: 6,
            color: colorOverride || ColourUtil.defaultTransportColour.car
          })
        })
        break
      case 'foot':
        style = new olStyle.Style({
          stroke: new olStyle.Stroke({
            width: 6,
            color: colorOverride || ColourUtil.defaultTransportColour.foot
          })
        })
        break
      case 'cycle':
        style = new olStyle.Style({
          stroke: new olStyle.Stroke({
            width: 6,
            color: colorOverride || ColourUtil.defaultTransportColour.cycle
          })
        })
        break
    }

    return style
  }

  static getColorFromGradient (percent: number, handles: {
    percentage: number
    color: string
  }[]) {
    let colorToSet
    let handle1 = handles[0]
    let handle2 = handles[1]

    const hl = handles.length
    handles.forEach((handle, hi) => {
      if (handle.percentage < percent) {
        handle1 = handle
        handle2 = hi < hl - 1 ? handles[hi + 1] : handle1
      }
    })

    if (handle1.percentage === handle2.percentage) {
      colorToSet = Color(handle1.color).hex()
    } else {
      const weight = (percent - handle1.percentage) / (handle2.percentage - handle1.percentage)
      const c1 = Color(handle1.color)
      const c2 = Color(handle2.color)
      colorToSet = c1.mix(c2, weight).hex()
    }

    return ConvertUtil.hexToRgb(colorToSet)
  }

  static getHighlightStyle (geomType: 'point' | 'polygon', opacity: number, radius = 10): olStyle.Style {
    if (geomType === 'point') {
      return new olStyle.Style({
        image: new olStyle.Circle({
          radius,
          stroke: new olStyle.Stroke({
            color: 'rgba(255, 255, 255, ' + opacity + ')',
            width: 0.25 + opacity
          }),
          fill: new olStyle.Fill({
            color: 'rgba(255, 255, 255, ' + opacity + ')'
          })
        })
      })
    } else {
      return new olStyle.Style({
        fill: new olStyle.Fill({
          color: 'rgba(255, 255, 255, ' + opacity + ')'
        }),
        stroke: new olStyle.Stroke({
          color: 'rgba(255, 255, 255, ' + opacity + ')',
          width: 6
        })
      })
    }
  }

  private static ChangeRateArrowStyle (changeRate?: number, radius?: number): olStyle.Style | undefined {
    if (!changeRate) return
    let anchorV = radius ? 0.65 - radius / 20 : -0.2
    let scale = 1
    if (radius && radius > 10) {
      scale = 1
    } else {
      scale = 0.6
      anchorV -= 0.4
    }
    let arrow
    const changeRateDirection = Math.sign(changeRate)
    if (changeRateDirection > 0) {
      arrow = { color: '#FF0000', rotationRadians: 0 }
    } else {
      arrow = { color: '#32CD32', rotationRadians: -3.14159 }
    }
    const arrowStyle = new olStyle.Style({
      image: new olStyle.Icon({
        anchor: [0.5, anchorV],
        anchorOrigin: 'bottom-left',
        color: arrow.color,
        crossOrigin: 'anonymous',
        imgSize: [30, 30],
        scale: scale,
        rotation: arrow.rotationRadians,
        src: 'assets/icons/arrow.svg'
      })
    })
    return arrowStyle
  }

  static gaugeClusterStyle (
    style: StyleParameters,
    fill: number[],
    stroke: number[],
    text: string
  ): olStyle.Style {
    const radius = style.point_radius ? style.point_radius : 18
    const fontSize = style.point_radius && style.point_radius < 15 ? 8 : 12
    const fillOptions = ConvertUtil.layerStyleToFillOptions({
      R: fill[0], G: fill[1], B: fill[2], A: fill[3]
    })
    const strokeOptions = ConvertUtil.layerStyleToStrokeOptions(
      {
        R: stroke[0], G: stroke[1], B: stroke[2], A: stroke[3]
      },
        Math.min(radius, style.borderWidth || 2)
      )

    const clusterImage = new olStyle.Circle({
      radius,
      stroke: new olStyle.Stroke(strokeOptions),
      fill: new olStyle.Fill(fillOptions)
    })

    const textLabel = radius > 10 ?
      new olStyle.Text({
        font: `${fontSize}px sans-serif`,
        text: text ? text + '%' : '0%',
        offsetY: 1,
        fill: new olStyle.Fill({
          color: '#FFFFFF'
        })
      }) : undefined

    return new olStyle.Style({
      image: clusterImage,
      text: textLabel
    })
  }

}
