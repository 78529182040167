import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-upload-list-dialog',
  templateUrl: './upload-list-dialog.component.html',
  styleUrls: ['./upload-list-dialog.component.scss']
})
export class UploadListDialogComponent {

  constructor (
    @Inject(MAT_DIALOG_DATA) public files: {
      uploaded: {name: string}[],
      pending: {name: string, missing?: string[], uploaded?: string[]}[]
    }
  ) { }

  formatExtensions (extensions: string[]) {
    return extensions.map(x => `*.${x}`).join(', ')
  }
}
