<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 240" width="100%">
  <rect class="header-bar" x="0" y="0" height="10" width="100%"></rect>
  <rect class="header-bar" x="0" y="10" height="230" width="60"></rect>

  <rect class="search-bar" x="3" y="13" width="54" height="8"></rect>

  <rect class="foreground" x="3" y="23" width="30" height="5"></rect>
  <rect class="foreground" x="3" y="30" width="40" height="5"></rect>
  <rect class="foreground" x="10" y="37" width="35" height="5"></rect>
  <rect class="foreground" x="10" y="44" width="25" height="5"></rect>
  <rect class="foreground" x="10" y="51" width="35" height="5"></rect>
  <rect class="foreground" x="3" y="58" width="20" height="5"></rect>


  <rect class="background" x="60" y="10" height="230" width="320"></rect>

  <rect class="header-bar" x="63" y="14" height="7" width="15"></rect>
  <rect class="primary" x="78" y="14" height="7" width="15"></rect>

  <defs>
    <pattern id="pattern-rows"
            x="63" y="-6" width="311" height="15"
            patternUnits="userSpaceOnUse" >

        <line x1="0" y1="0" x2="311" y2="0" stroke-width="0.5" stroke="rgba(255, 255, 255, 0.2)"/>
        <rect class="foreground" x="5" y="5" width="70" height="5"></rect>
        <rect class="foreground" x="90" y="5" width="30" height="5"></rect>
    </pattern>
  </defs>

  <rect class="table" x="63" y="24" height="210" width="311"></rect>
  <rect fill="url(#pattern-rows)" x="63" y="24" height="209" width="311"></rect>
</svg>
