import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-ws-placeholder-uhl',
  templateUrl: './ws-placeholder-uhl.component.html',
  styleUrls: ['./ws-placeholder-uhl.component.scss', '../placeholders.scss']
})
export class WsPlaceholderUhlComponent {
  @Input() workspaceId?: number
}
