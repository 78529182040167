import { Component, Input, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core'
import { ThemeService } from '@services/core/theme/theme.service'
import OlUtil from '@core/utils/ol/ol.util'
import * as Color from 'color'
import { ColourUtil } from '@core/utils/index'
import * as olGeom from 'ol/geom'
import GeometryCollection from 'ol/geom/GeometryCollection'
import { Subject, Subscription } from 'rxjs'
import { auditTime } from 'rxjs/operators'
import { GeometryType } from '@core/enum/ol/ol-geom-type'

interface IGeometryIcon {
  type: GeometryType
  fill?: string
  stroke?: string
}

@Component({
  selector: 'app-geometry-icon[geom]',
  templateUrl: './geometry-icon.component.html',
  styleUrls: ['./geometry-icon.component.scss']
})
// TODO: Make this responsive to parent size, now it's fixed to 15px
export class GeometryIconComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription()
  @Input() geom!: olGeom.Geometry
  // TEMP: define single fill and stroke, later need to derive it from feature/geometry
  // or some other way
  private _fill: string = 'transparent'
  @Input()
  get fill () {
    return this._fill
  }
  set fill (val: string) {
    this._fill = val
    this._styleUpdate.next({})
  }

  private _stroke: string = 'transparent'
  @Input()
  get stroke () {
    return this._stroke
  }
  set stroke (val: string) {
    this._stroke = val
    this._styleUpdate.next({})
  }

  // TEMP: For property view layer invert the style of point, this is hard-coded case
  // and should be fixed once colour is derived from feature/geometry
  @Input() invertPointStyle = false

  private _styleUpdate = new Subject()

  types: IGeometryIcon[] = []
  transform3D?: {
    typeSpacing: {
      left: string
      top: string
    }[]
  }
  constructor (
    private _theme: ThemeService
  ) {}

  ngOnInit () {
    this.RenderIcon()

    this._subscriptions.add(
      this._styleUpdate.pipe(
        auditTime(200)
      ).subscribe(() => this.RenderIcon())
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  private RenderIcon () {
    if (!this.stroke) {
      this.stroke = this._theme.getColor('map-controls-foreground')
    }
    const uniqueTypes: Set<GeometryType> = new Set()

    const features = OlUtil.expandFeature(this.geom)

    for (const f of features) {
      // TEMP: for now only support 1D array
      if (Array.isArray(f)) continue
      const geom = f.getGeometry()
      // TEMP: Ignore geometry collection for now
      if (geom instanceof GeometryCollection || !geom) continue
      uniqueTypes.add(geom.getType() as GeometryType)
    }

    this.types = Array.from(uniqueTypes).map((t => ({
      type: t,
      fill: !(this.invertPointStyle && t === 'Point') ? this.fill : this.InvertColor(this.fill),
      stroke: !(this.invertPointStyle && t === 'Point') ? this.stroke : this.InvertColor(this.stroke)
    })))

    if (this.types.length < 2) return
    const spacing = 15 * 0.3
    this.transform3D = {
      typeSpacing: this.types.map((el, i) => ({
        left: `${spacing * i}px`,
        top: `-${spacing * i * 2}px`
      }))
    }
  }

  private InvertColor (color: any) {
    if (color === 'transparent' || !color) return 'transparent'

    const rgb = Color(color).rgb().array()
    const a = Color(color).alpha()

    const inv = ColourUtil.invertRgb({
      R: rgb[0],
      G: rgb[1],
      B: rgb[2]
    })

    return `rgba(${inv.R}, ${inv.G}, ${inv.B}, ${a})`
  }
}
