import { AppLayerBase } from './app-layer-base'
import { IRWorkspaceLayer, IPNewExtent, IPVectorTableRow, IPNewVectorColumn, IRVectorColumn, IPNewPVGeometry, IPModifyPVGeometry, IRVectorColumnType, IPNewVectorRow, IGeometryType, IPUpdateLayer, ILayerCsv } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService, PromptService } from '@services/core'
import AppError, { handleError } from '@core/models/app-error'
import OlUtil from '@core/utils/ol/ol.util'
import { CommonUtil, ConvertUtil, GenerateAttributes } from '@core/utils/index'
import * as moment from 'moment'
import { interval, Subject, Subscription } from 'rxjs'
import { LayerUtil, LayerStyleUtil } from './utils'
import * as olLayer from 'ol/layer'
import * as olSource from 'ol/source'
import GeometryCollection from 'ol/geom/GeometryCollection'
import * as olGeom from 'ol/geom'
import * as olExtent from 'ol/extent'
import * as olProj from 'ol/proj'
import OlGeojson from 'ol/format/GeoJSON'
import Feature from 'ol/Feature'
import * as olLoadingstrategy from 'ol/loadingstrategy'
import TileGrid from 'ol/tilegrid/TileGrid'
import VectorLayer from 'ol/layer/Vector'
import { debounceTime, map, auditTime, buffer } from 'rxjs/operators'
import { AppLayerGeneric } from './app-layer-generic'
import { cloneDeep, isEqual } from 'lodash'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { ColumnsDialogComponent } from '@core/page-components/columns-dialog/columns-dialog.component'
import { LayerStyleParameters, StyleParameters } from '@core/types/workspace/layers/style/style-parameters'
import { IUpdatePvAnot, IUpdatePvMl, IModifyPvAnot, IModifyPvMl } from '@vip-shared/interfaces/property-view/edit-properties'
import { PVGeojsonProperties } from '@vip-shared/models/layer-config/property-view/pv-geojson-properties'
import { IFeatureCustomColor, IFeatureSystemProperties, MergedAttributesMeta } from '@core/types'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { Features } from './utils/features'
import TimeUtil from '@core/utils/time/time.util'
import { MomentPipe } from '../../pipes/moment.pipe'
import VectorSource from 'ol/source/Vector'
import { AppFeature } from './ol-extend/app-feature'
import { WorkspaceService } from '../../../services/workspace'
import { API } from '@vip-shared/interfaces/api-helper'
import { Geojson } from '@vip-shared/interfaces/geojson'
import { v4 } from 'uuid'

type ImplementedLayer = olLayer.Tile<any> | olLayer.Vector<any>

// NOTE: AppLayer is generic layer class
// When there will be more need for it - it can be split into:
// * class AppVectorLayer extends AppLayer
//   * class AppDroneLayer extends AppVectorLayer (currently: extends AppLayer)
//   * class AppVectorPresetLayer extends AppVectorLayer
export class AppLayer extends AppLayerBase implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  protected _reloaded = new Subject<undefined | AppFeature[]>()
  protected _removed = new Subject<Feature[]>()

  readonly type = 'layer'

  readonly vector: boolean
  readonly preset?: Db.Vip.LayerPreset
  readonly readOnlyException: boolean

  readonly focalPoint?: string
  readonly layerType: string
  readonly typeId: Db.Vip.SourceType
  readonly workspaceId: number
  readonly createdAt?: string
  readonly updatedAt?: string
  readonly updateUser?: string
  protected _viewId: number

  protected readonly _layerMode?: Db.Vip.LayerMode
  get layerMode () {
    return this._layerMode
  }

  idKey: string

  extentUpdated?: boolean = false

  fVectorMissingAssets?: boolean
  fEditingGeometry?: boolean
  zoomRange?: any

  protected _loadingError?: string
  protected _geometryTypes?: IGeometryType[]

  layer?: ImplementedLayer
  // Should be mandatory when separated into app-vector-layer.ts
  // openlayers mixes up order of features, and we don't want to sort them every time
  // so have to maintain array here
  protected _features?: Features

  get features () {
    return this._features ? [...this._features.array] : []
  }

  // Features not filtered out by any active query except for time series
  get notFilteredOutFts () {
    return this.filtered ?
      this.features.filter(f => !OlUtil.featureFilteredOut(f)) :
      this.features
  }

  get visibleFeatures () {
    const features = this._features ? [...this._features.renderedArray] : []
    return this.filtered ?
    features.filter(f => !OlUtil.featureFilteredOut(f)) :
    features
  }

  parameters: Db.Helper.Geo.SourceParameters

  protected _getLatestExtent = new Subject()
  protected _getLatestGeomTypes = new Subject()

  protected _updateClustering = new Subject()

  protected _reloadCount = 0
  private _updateLoading?: ReturnType<typeof setTimeout>
  private get reloadCount () {
    return this._reloadCount
  }
  private set reloadCount (val: number) {
    this._reloadCount = val
    if (this._updateLoading) {
      clearTimeout(this._updateLoading)
      this._updateLoading = undefined
    }

    if (this._loading) {
      this._loading = this._reloadCount > 0
    } else {
      // If reload was triggered by small/short functions,
      // then don't show loading, if it takes < 2.5s
      this._updateLoading = setTimeout(() => {
        this._loading = this._reloadCount > 0
      }, 2_500)
    }
  }

  register = {
    delete: (cb: () => Promise<void>) => this._register.delete(cb)
  }

  anotGuideSet?: Db.Vip.PV.IAnotGuideSet
  mlDataset?: Partial<Db.Vip.PV.IMlDataset>

  private _mobileCompatible: boolean
  get mobileCompatible () {
    return this._mobileCompatible
  }

  private _realTimeDataRefreshed = new Subject()
  get realTimeDataRefreshed () {
    return this._realTimeDataRefreshed.asObservable()
  }

  protected _isTimeSeries = false
  get isTimeSeries () {
    return this._isTimeSeries
  }

  // Old implementation of time series =>
  get timeSeries () {
    return !!this._timeSeriesGroup
  }

  private _timeSeriesGroup?: string
  get timeSeriesGroup () {
    return this._timeSeriesGroup
  }
  set timeSeriesGroup (groupId: string | undefined) {
    this._timeSeriesGroup = groupId
  }
  // Used to check if layer depends on other layers and
  // avoid circular dependency injection
  getLinkedTimeSeries?: () => AppLayer[]
  // <= Old implementation of time series

  private _timeSeriesSelection?: Db.Helper.Geo.Timeseries
  get timeSeriesSelection () {
    return this._timeSeriesSelection && cloneDeep(this._timeSeriesSelection)
  }

  protected _renderedTimeSeriesSelection?: Db.Helper.Geo.Timeseries
  get renderedTimeSeriesSelection () {
    return this._renderedTimeSeriesSelection && cloneDeep(this._renderedTimeSeriesSelection)
  }

  private _timeSeriesRealTime?: Subscription

  private _timeSeriesSaved = true
  get timeSeriesSaved () {
    return this._timeSeriesSaved
  }

  private _sampled: boolean = false
  get sampled () {
    return this._sampled
  }

  private _vectorTimeSeriesConf?: Db.Helper.Geo.VectorTimeSeriesConf
  get vectorTimeSeriesConf () {
    return this._vectorTimeSeriesConf && cloneDeep(this._vectorTimeSeriesConf)
  }

  get removed () {
    let value: 'all' | AppFeature[] | undefined
    // If at least one global removed is emitted - then
    // emit undefined object causing listeners to removed all
    // layer data they reference. If only features were removed,
    // then emit accumulated array.
    // Clear state/accumulated array on event emit
    return this._removed.asObservable()
    .pipe(
      map(val => {
        if (!val) {
          value = 'all'
        } else if (val && value !== 'all') {
          if (!value) value = []
          for (const v of val) !value.includes(v) && value.push(v)
        }
      }),
      auditTime(500),
      map(() => {
        const val = value
        value = undefined
        return val === 'all' ? undefined : val
      })
    )
  }

  get reloaded () {
    let value: 'all' | AppFeature[] | undefined
    // If at least one global reload is emitted - then
    // emit undefined object causing listeners to reload all
    // layer data they reference. If only features were reloaded,
    // then emit accumulated array.
    // Clear state/accumulated array on event emit
    return this._reloaded.asObservable()
    .pipe(
      map(val => {
        if (!val) {
          value = 'all'
        } else if (val && value !== 'all') {
          if (!value) value = []
          for (const v of val) !value.includes(v) && value.push(v)
        }
      }),
      auditTime(500),
      map(() => {
        const val = value
        value = undefined
        return val === 'all' ? undefined : val
      })
    )
  }

  get error (): string | undefined {
    return this._loadingError
  }

  private _loading = false
  get loading (): boolean {
    return this._loading
  }

  get geometryTypes (): IGeometryType[] {
    return this._geometryTypes ? [...this._geometryTypes] : []
  }

  get extent (): Db.Vip.Geo.ILayer['extent'] {
    return this._extent
  }

  protected _selectChanged = new Subject<boolean>()
  get selectChanged () {
    return this._selectChanged.asObservable()
  }

  get baseSource (): olSource.Vector | undefined {
    if (!(this.layer instanceof olLayer.Vector)) return
    let source = this.layer.getSource()
    if (source instanceof olSource.Cluster) source = source.getSource()
    return source
  }

  get expectingSpatialQuery (): boolean {
    return this._waitForSpatialSelect
  }

  protected _selected = false
  get selected (): boolean {
    return this._selected
  }
  set selected (select: boolean) {
    if (this._selected !== select) {
      if (select ? !this._canSelect() : !this._canDeselect()) return

      this._selected = select
      this.layer && this.layer.set('selected', this._selected)
      if (!this._selected) this.focused = false

      this._selectChanged.next(this._selected)
    }
  }

  protected _focusedChange = new Subject<boolean>()
  get focusedChange () {
    return this._focusedChange.asObservable()
  }

  protected _focused = false
  get focused (): boolean {
    return this._focused
  }
  set focused (focus: boolean) {
    if (this._focused !== focus) {
      this._focused = focus
      this.layer && this.layer.set('focused', this._focused)
      this._focusedChange.next(this._focused)
    }
  }

  protected _filtered = false
  get filtered (): boolean {
    return this._filtered
  }
  set filtered (val: boolean) {
    this._filtered = val
    this.layer && this.layer.set('filtered', val)
  }

  protected _columnsChanged = new Subject()
  get columnsChanged () {
    return this._columnsChanged.asObservable()
    .pipe(
      debounceTime(100)
    )
  }

  // This does not indicate that there has been a change like _columnsChanged.
  // This is mainly used to refresh UI components, as _columnsChanged usage would trigger
  // infinite loop, as parent group is listening on _columnsChanged event (if present)
  protected _columnsRefreshed = new Subject()
  get columnsRefreshed () {
    return this._columnsRefreshed.asObservable()
    .pipe(
      debounceTime(100)
    )
  }

  protected _styleSynced = new Subject()
  get styleSynced () {
    return this._styleSynced.asObservable()
    .pipe(
      debounceTime(100)
    )
  }

  get geometryCount () {
    return this._features ? this._features.geometryCount : 0
  }

  get renderedGeometryCount () {
    return this._features ? this._features.renderedGeometryCount : 0
  }

  protected get _sourceApi () {
    return this._api.orm.Workspaces().Workspace(this.workspaceId).Layer(this.id)
  }

  protected get _sourceStyleApi () {
    return this._api.orm.Workspaces().Workspace(this.workspaceId).Views().View(this._viewId).Layer(this.id)
  }

  protected _attributeColumns?: Db.Helper.Geo.AttributeColumn[]
  get attributeColumns (): Db.Helper.Geo.AttributeColumn[] {
    return (this._attributeColumns && cloneDeep(this._attributeColumns)) || []
  }
  set attributeColumns (val: Db.Helper.Geo.AttributeColumn[]) {
    this._attributeColumns = this.appendDefaultColumns(val)
    this._columnsChanged.next({})
    this._columnsRefreshed.next({})
    this.SaveAttributeColumns()
    this.MergeColumnMeta()
  }

  protected _columnMeta?: Db.Helper.Geo.ColumnMeta[]
  get columnMeta (): Db.Helper.Geo.ColumnMeta[] {
    return (this._columnMeta && cloneDeep(this._columnMeta)) || []
  }
  set columnMeta (val: Db.Helper.Geo.ColumnMeta[]) {
    this._columnMeta = val
    this.SaveColumnMeta()
    this.MergeColumnMeta()
  }

  protected _tableColumns?: IRVectorColumn[]
  get tableColumns (): IRVectorColumn[] {
    return (this._tableColumns && cloneDeep(this._tableColumns)) || []
  }
  set tableColumns (val: IRVectorColumn[]) {
    if (!val) return
    this._tableColumns = val
    this.MergeColumnMeta()
  }

  protected _mergedColumnMeta: MergedAttributesMeta = {}
  get mergedColumnMeta () {
    return cloneDeep(this._mergedColumnMeta)
  }

  protected _style: LayerStyleParameters
  get style (): LayerStyleParameters {
    return cloneDeep(this._style)
  }
  set style (val: LayerStyleParameters) {
    this._style = val
    this._attributeChange.next({})
    this._styleChanged.next({})
  }

  get clusterDistance (): number | null {
    const source = this.layer instanceof VectorLayer &&
    this.layer.getSource()

    return source instanceof olSource.Cluster ? source.getDistance() : null
  }

  get isClustered (): boolean {
    const distance = this.clusterDistance
    return distance === null ? false : distance > 0
  }

  get inEditMode (): boolean {
    switch (this.preset) {
      case Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS:
        // NOTE: For now every layerMode is meant for editing, however if non-edit mode is added,
        // do add a <modesList>.includes(this._layerMode) check
        return !!this._layerMode
      default:
        return false
    }
  }

  get isPropertyView (): boolean {
    return this.preset === Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS
  }

  private _opacity: number = 1
  private get opacity () {
    return this._opacity
  }
  private set opacity (val: number) {
    this._opacity = val
    this.layer && this.layer.setOpacity(val)
  }

  get vectorExtent (): [number, number, number, number] {
    if (this.layer instanceof VectorLayer) {
      return this.layer.getSource().getExtent() as [number, number, number, number]
    } else {
      return [NaN, NaN, NaN, NaN] // (this._extent && this._extent.extent) ||
    }
  }

  get readOnly (): boolean {
    return this.layerMode === Db.Vip.LayerMode.READ_ONLY
  }

  private _syncStyleDebounce = new Subject<boolean>()

  constructor (
    protected _allElements: () => AppLayerGeneric[],
    protected _allLayers: () => AppLayer[],
    protected _maxIndex: () => number,
    protected _canSelect: () => boolean,
    protected _canDeselect: () => boolean,
    protected _momentPipe: MomentPipe,
    dialog: MatDialog,
    protected prompt: PromptService,
    api: VipApiService,
    workspaceService: WorkspaceService,
    source: IRWorkspaceLayer,
    viewId: number,
    theme?: Db.Vip.Geo.ILayerAttribute,
    init: boolean = true,
    error?: string
  ) {
    super(dialog, prompt, api, workspaceService, {
      id: source.layer_id,
      title: (theme && theme.name_override) || source.name || '',
      visible: (theme && theme.visible) || source.visible || false,
      createUser: source.create_user
    })
    // TODO: Remove once debugging in production environment is complete
    window[`layer_${this.id}_${this.title.replace(/ /g, '_')}`] = this
    this._mobileCompatible = source.mobile_compatible
    this._layerMode = source.layer_mode_tag
    this.workspaceId = source.workspace_id
    this._viewId = viewId
    this.vector = source.source_type_name.toLowerCase().includes('vector')
    this.preset = source.layer_preset_tag
    this.readOnlyException = source.read_only_exception
    this.layerType = source.name[0].toUpperCase() + source.name.slice(1).toLowerCase()
    this.typeId = source.source_type_id
    this.zoomRange = source.parameters?.zoom_range
    this._sampled = source.sampled

    if (this.preset) {
      this.idKey = Columns.PresetId[this.preset]
    } else {
      this.idKey = Columns.VectorFid
    }

    this._style = (theme && theme.style_parameters) || LayerStyleUtil.getDefaultStyle(this.typeId)

    if (this.typeId === Db.Vip.SourceType.RASTER) {
      // All rasters should have black border, but in some layers it seems to be missing?
      this._style.border = { R: 0, G: 0, B: 0, A: 1 }
    }
    this.parameters = source.parameters as any
    this._extent = source.extent

    if (theme) {
      this.createdAt = theme.created_at
      this.updateUser = theme.update_user
      this.updatedAt = theme.updated_at
    }

    this._opacity = this._style.opacity === undefined ? 1 : this._style.opacity

    this._order = (theme && theme.order_override) ?
      theme.order_override :
      source.workspace_source_order

    this._attributeColumns = theme && theme.attribute_columns ? this.appendDefaultColumns(theme.attribute_columns) : []
    this._columnMeta = source.column_meta

    if (error) {
      this._loadingError = error
      return
    }

    this._vectorTimeSeriesConf = source.vector_time_series
    this._isTimeSeries = source.is_time_series

    this._timeSeriesSelection = theme && theme.time_series
    this._renderedTimeSeriesSelection = this.timeSeriesSelection
    this.DefaultRenderedTimeSeries()

    //

    if (init) this.LoadAsync(source, theme)
    this.MergeColumnMeta()
    this._subscriptions.add(
      this._updateClustering.pipe(
        debounceTime(500)
      ).subscribe(() => this.UpdateLocalClustering())
    )

    this._subscriptions.add(
      this.reloaded.subscribe(() => {
        this.syncStyle(true)
      })
    )

    this._subscriptions.add(
      this._getLatestExtent.pipe(
        debounceTime(500)
      ).subscribe(() => this.updateLayerExtent())
    )

    this._subscriptions.add(
      this._getLatestGeomTypes.pipe(
        debounceTime(500)
      ).subscribe(() => this.GetGeometryTypes())
    )

    this._subscriptions.add(
      this.visibleChange.subscribe(() => this.syncStyle(true, true))
    )

    this._subscriptions.add(
      this._columnsChanged.pipe(
        debounceTime(1000)
      ).subscribe(
        () => this.SaveAttributeColumns()
      )
    )

    this._subscriptions.add(
      this._syncStyleDebounce.pipe(
        buffer(
          this._syncStyleDebounce.pipe(debounceTime(300))
        )
      ).subscribe(values => {
        this.SyncStyle(values.includes(true))
      })
    )
  }

  destroy () {
    this._destroyDialogs()
    this._subscriptions.unsubscribe()
  }

  private MergeColumnMeta () {
    this._mergedColumnMeta = {}
    const availableCols = this._attributeColumns || []
    for (const col of availableCols) {
      const metaMatch: Db.Helper.Geo.ColumnMeta = (this._columnMeta || []).find(m => m.prop === col.prop) || {} as Db.Helper.Geo.ColumnMeta
      const vectorCol: IRVectorColumn = (this._tableColumns || []).find(c => c.name === col.prop) || {} as IRVectorColumn
      this._mergedColumnMeta[col.prop] = {
        ...col,
        ...metaMatch,
        ...vectorCol
      }

      let pipeFormat: string | undefined

      if (!this.preset) {
        if ([Columns.UpdatedAt].includes(col.prop)) {
          pipeFormat = `=>YYYY-MM-DD`
        }
      } else {
        switch (this.preset) {
          case Db.Vip.LayerPreset.FLOOD_WARNING_AREAS:
            if ([
              Db.Fred.Flood.FloodWarning.DATE_TIME_CHANGED, Db.Fred.Flood.FloodWarning.DATE_TIME_RAISED
            ].includes(col.prop)) {
              pipeFormat = `=>YYYY-MM-DD HH:mm:ss`
            }
            break
          case Db.Vip.LayerPreset.RIVER_GAUGE_READINGS:
            if (Db.Fred.Station.RiverGaugeReading.DATE_TIME === col.prop) {
              pipeFormat = `=>YYYY-MM-DD HH:mm:ss`
            }
            break
          case Db.Vip.LayerPreset.FLOODRE_EXPOSURE:
            if ([
              Db.FloodRe.Exposure.POLICY_TERM_EFFECTIVE_START_DATE, Db.FloodRe.Exposure.POLICY_TERM_EFFECTIVE_END_DATE
            ].includes(col.prop)) {
              pipeFormat = `=>YYYY-MM-DD`
            }
            break
          case Db.Vip.LayerPreset.FLOODRE_CLAIMS:
            if ([
              Db.FloodRe.Claim.DATE_OF_LOSS
            ].includes(col.prop)) {
              pipeFormat = `=>YYYY-MM-DD`
            }
            break
          case Db.Vip.LayerPreset.EUROTEMPEST_CLAIMS:
            if ([
              Db.FloodRe.Eurotempest.SUBMIT_DATE_TIME
            ].includes(col.prop)) {
              pipeFormat = `=>YYYY-MM-DD HH:mm:ss`
            } else if ([
              Db.FloodRe.Eurotempest.EVENT_DATE
            ].includes(col.prop)) {
              pipeFormat = `=>YYYY-MM-DD`
            }
            break
          default:
            break
        }
      }

      if (pipeFormat) {
        this._mergedColumnMeta[col.prop].transformPipe = {
          transform: (val: any) => this._momentPipe.transform(val, pipeFormat as string)
        }
      }
    }
  }

  trackSubscription (s: Subscription) {
    this._subscriptions.add(s)
  }

  async download () {
    if (!this.vector || this.typeId === Db.Vip.SourceType.DRONE_VECTOR) return

    try {
      const res = await this.GetGeojson()

      const geojson = res.layer
      let geoms: undefined | API.Res.Layer.Ext.Geom[]
      let idKeys: string[] = []

      if (res.extensions && res.extensions.geometries) {
        geoms = res.extensions.geometries
        // Every geom must have same id columns
        idKeys = Object.keys(geoms[0].id)
      }

      // Allow max size of 500MB per file, higher than that will result in out of memory error
      // 'Invalid string length'
      const maxLength = 500 * Math.pow(1024, 2)
      const parts: string[] = []
      const strStart = `{"type":"FeatureCollection",` +
      `"crs":{"type":"name","properties":{"name":"urn:ogc:def:crs:EPSG::4326"}},` +
      `"features":[`
      const strEnd = ']}'

      let str = strStart
      let strLength = str.length
      let ftStr = ''
      let f: Geojson.Feature | undefined = geojson.features.pop()

      while (f) {
        const props = f.properties
        if (!props) continue

        if (geoms) {
          const match = geoms.find(g =>
            idKeys.every(k =>
              g.id[k] === props[k]
            )
          )

          if (!match) continue
          f.geometry = match.geometry
        }

        // TODO: Assets is column returned by api server for some layers
        // to append assets. This should now be moved to 'extensions' in GET geojson
        // response
        delete props._assets

        ftStr = JSON.stringify(f)

        if ((strLength + ftStr.length + strEnd.length) >= maxLength) {
          const lastChar = str.slice(-1)
          // If last char was not ',' then add it back in
          if (lastChar !== ',') str += lastChar
          str += strEnd
          parts.push(str)

          str = strStart
          strLength = str.length
        }

        str += ftStr
        strLength += ftStr.length

        f = geojson.features.pop()

        if (f) str += ','
      }

      str += strEnd
      parts.push(str)

      const count = parts.length
      for (let i = 0; i < count; i++) {
        const part = parts[i]
        const partIndicator = count < 2 ? '' : `(${i + 1} out of ${count})`
        CommonUtil.downloadBlob(
          part,
          `${this.id}_${this.title}${partIndicator}.geojson`,
          'json'
        )
      }
    } catch (error: any) {
      handleError(error)
      const outOfMem = error.message.includes('Invalid string length')

      let message = `Failed to download geojson for layer '${this.title}'.`
      if (outOfMem) message += ` Geojson is too large to download in browser.`
      throw new AppError(message)
    }
  }

  async createLayerCsv (columns: string[]): Promise<string> {
    const res = await this.GetLayerCsv(columns)
    return res
  }

  async downloadCsvFile  (filePath: string) {
    const res = await this.DownLoadCsvfromServer(filePath)
    return res
  }

  protected DefaultRenderedTimeSeries () {
    if (!this.isTimeSeries) return
    const now = moment()
    const startTime = moment().startOf('day')
    const defaultRange: Db.Helper.Geo.DateRange = {
      to: now.toISOString(),
      from: startTime.toISOString(),
      focal: startTime.add(
        (now.toDate().getTime() - startTime.toDate().getTime()) / 2, 'ms'
      ).toISOString()
    }
    if (!this._renderedTimeSeriesSelection) {
      this._renderedTimeSeriesSelection = {
        date_range: defaultRange,
        locked: false,
        real_time: false,
        focal_accuracy: {
          value: 15,
          interval: 'minute'
        }
      }

      if (this.vectorTimeSeriesConf && this.vectorTimeSeriesConf.change_column) {
        this._renderedTimeSeriesSelection.change_period = {
          value: 1,
          interval: 'hour'
        }
      }
    }
    if (!this._renderedTimeSeriesSelection.date_range) {
      this._renderedTimeSeriesSelection.date_range = defaultRange
    } else {
      if (!this._renderedTimeSeriesSelection.date_range.to) {
        this._renderedTimeSeriesSelection.date_range.to = defaultRange.to
      }

      if (!this._renderedTimeSeriesSelection.date_range.from) {
        this._renderedTimeSeriesSelection.date_range.from = moment(
          this._renderedTimeSeriesSelection.date_range.to
        ).startOf('day').toISOString()
      }

      if (!this._renderedTimeSeriesSelection.date_range.focal) {
        const to = moment(
          this._renderedTimeSeriesSelection.date_range.to
        )
        const from = moment(
          this._renderedTimeSeriesSelection.date_range.from
        )
        this._renderedTimeSeriesSelection.date_range.focal = from.add(
          (to.toDate().getTime() - from.toDate().getTime()) / 2, 'ms'
        ).toISOString()
      }

      if (
        !this._renderedTimeSeriesSelection.change_period &&
        this.vectorTimeSeriesConf && this.vectorTimeSeriesConf.change_column
      ) {
        this._renderedTimeSeriesSelection.change_period = {
          value: 1,
          interval: 'hour'
        }
      }

      if (!this._renderedTimeSeriesSelection.focal_accuracy) {
        this._renderedTimeSeriesSelection.focal_accuracy = {
          value: 15,
          interval: 'minute'
        }
      }
    }

    this.CheckTimeSeriesState()
  }

  protected CheckTimeSeriesState () {
    this._timeSeriesSaved = isEqual(this._timeSeriesSelection, this._renderedTimeSeriesSelection)
    const realTimeOn = this._renderedTimeSeriesSelection && this._renderedTimeSeriesSelection.real_time

    const clearSub = () => {
      if (!this._timeSeriesRealTime) return
      this._subscriptions.remove(this._timeSeriesRealTime)
      this._timeSeriesRealTime.unsubscribe()
      this._timeSeriesRealTime = undefined
    }
    if (realTimeOn && !this._timeSeriesRealTime) {
      this._timeSeriesRealTime = interval(60_000).subscribe(() => {
        const realTimeOn = this._renderedTimeSeriesSelection && this._renderedTimeSeriesSelection.real_time
        if (!realTimeOn || !this._renderedTimeSeriesSelection) {
          clearSub()
          return
        }

        const ts = cloneDeep(this._renderedTimeSeriesSelection)
        const date = moment(ts.date_range.to)
        if (date.isBefore(moment())) {
          const oldDate = moment(ts.date_range.to)
          const newDate = moment().add(30, 'seconds')
          ts.date_range.to = newDate.toISOString()
          if (this._renderedTimeSeriesSelection.locked) {
            const diff = moment(oldDate).diff(ts.date_range.from, 's')
            const focalDiff = moment(oldDate).diff(ts.date_range.focal, 's')
            ts.date_range.from = moment(newDate).subtract(diff, 's').toISOString()
            ts.date_range.focal = moment(newDate).subtract(focalDiff, 's').toISOString()
          }

          this.setRenderedTimeSeries(ts, true)
          this._realTimeDataRefreshed.next({})
        }
      })
      this._subscriptions.add(this._timeSeriesRealTime)
    } else if (!realTimeOn && this._timeSeriesRealTime) {
      clearSub()
    }
  }

  getLinkedTimeSeriesFeatures (f: AppFeature) {
    if (!this._features || !this._features.timeSeriesPartition) return [f]
    const key = this._features.formatEntityKey(f)
    const partition = this._features.timeSeriesPartition[key]
    return !partition ? [f] : partition.features
  }

  protected async GetGeometryTypes () {
    if (!this.vector) return
    this._geometryTypes = await this._api.orm.Workspaces().Workspace(this.workspaceId).Layer(this.id)
    .getGeometryTypes().run()

    this.AdjustSource()
  }

  protected async LoadAsync (source: IRWorkspaceLayer, theme?: Db.Vip.Geo.ILayerAttribute) {
    try {

      this._waitForSpatialSelect = !!source.spatial_query_required

      if (this._waitForSpatialSelect) {
        // Create empty layer so that other features would not break
        const source = new VectorSource({})
        this.layer = new VectorLayer({
          className: v4(),
          source
        })
        if (this._features) {
          this._features.destroy()
          this._features.clear()
        }
        this._features = new Features(
          this.idKey, source, {
            timeSeries: this._vectorTimeSeriesConf ? {
              conf: this._vectorTimeSeriesConf,
              selection: this._renderedTimeSeriesSelection
            } : undefined,
            setZIndex: this.GetFeatureZIndexFn()
          }
        )
      } else {
        if (this.isPropertyView) {
          if (this.parameters.preset_measure_set && this.parameters.preset_measure_set.anot_guide_set_id) {
            this.anotGuideSet = await this._api.orm.Products().PropertyView()
            .Guides().Sets().Set(this.parameters.preset_measure_set.anot_guide_set_id).get().run()
          }
          if (this.parameters.preset_measure_set && this.parameters.preset_measure_set.ml_dataset_id) {
            this.mlDataset = await this._api.orm.Products().PropertyView()
            .MlDatasets().Dataset(this.parameters.preset_measure_set.ml_dataset_id).get().run()
          }
        } else {
          this._tableColumns = await this._sourceApi.getColumns().run()
          await this.GetGeometryTypes()
        }
        const layer = await this.RenderLayer(source)
        if (this.layer) {
          this.layer.setSource(layer.getSource() as any)
        } else {
          this.layer = layer
        }
      }

      source.visible = (theme && theme.visible) || !!source.visible
      this.layer.setVisible(source.visible)
      this.layer.setProperties({
        sourceId: source.layer_id,
        name: source.name,
        // NOTE: don't rename to 'extent', as that will stop layers from loading
        extentDetails: source.extent,
        interactive: true
      })

    } catch (error: any) {
      console.error(error)
      this._loadingError = error.message
    }
    this._features && this._features.recalculateGeometries()
    this._loaded = true

    if (!this._attributeColumns && this.typeId === Db.Vip.SourceType.VECTOR) {
      this._attributeChange.next({})
    }
  }

  async deleteColumn (column: string) {
    if (!this.timeSeriesGroup) {
      await this._sourceApi.deleteColumn(column).run()
    } else {
      await this._api.orm.Workspaces().Workspace(this.workspaceId).Views().View(this._viewId).LayerGroups()
      .Group(this.timeSeriesGroup).deleteColumn(column).run()
    }

    this.RemoveLayerPropertyKey(column)
    await this.refreshColumns()
    this._reloaded.next(undefined)
  }

  private RemoveLayerPropertyKey (key: string) {
    if (!this.layer || !this.vector) return
    if (Columns.System.includes(key)) {
      throw new AppError(`Deletion of columns required by system is not allowed.`)
    }
    const features = this.features
    if (this.attributeColumns) {
      const arr = this.attributeColumns
      arr.splice(
        this.attributeColumns.findIndex(x => x.prop === key), 1
      )
      this.attributeColumns = arr
    }
    for (const f of features) {
      const props = f.getProperties()
      delete props[key]
      f.setProperties(props)
    }
  }

  async refreshColumns () {
    if (this.typeId !== Db.Vip.SourceType.VECTOR) return
    this._attributeColumns = this.appendDefaultColumns(await this._sourceApi.getAttributeColumns().run())
    if (this._attributeColumns && this._attributeColumns.length) this._attributeColumns = this.appendDefaultColumns(this._attributeColumns)
    this._tableColumns = (await this._sourceApi.getColumns().run()).filter(c => !Columns.System.includes(c.name))
    this._columnsRefreshed.next({})
  }

  async waitForLayerLoaded (timeoutS = 60): Promise<boolean> {
    await this.waitForLoaded()
    if (!this.layer) {
      throw new AppError(`Layer not found. Cannot await for load end.`)
    }
    const olLayer: any = this.layer

    const source = this.baseSource

    const isLoaded = (): boolean => !(olLayer instanceof VectorLayer) || olLayer.get('loaded') || (source && source.get('loaded'))

    const end = moment().add(timeoutS, 's')
    while (!isLoaded() || moment().isAfter(end)) {
      await CommonUtil.delay(200)
    }

    return isLoaded()
  }

  async changeView (viewId: number) {
    if (this._viewId === viewId) return
    this._viewId = viewId
    await this.reloadStyle()
  }

  updateStyle (style: Partial<LayerStyleParameters>) {
    this.style = CommonUtil.mergeDeep(this.style, style)
  }

  async reload (): Promise<boolean> {
    this.reloadCount++
    this._loading = true
    let reloaded = true
    try {
      const source = await this._sourceApi.get().run()

      this._sampled = source.sampled
      this.parameters = source.parameters as any
      this._extent = source.extent

      await this.LoadAsync(source)
      await this.reloadStyle(source.workspace_source_order)
      this._reloaded.next(undefined)
      this.syncStyle(true, true)
    } catch (error: any) {
      handleError(error)
      this._loadingError = error.message
      reloaded = false
    }

    this.reloadCount--
    return reloaded
  }

  protected async reloadStyle (originalOrder?: number) {
    const theme = await this._sourceStyleApi.getAttributes().run()
    this.visible = !!theme.visible
    this._style = (theme && theme.style_parameters as any) || LayerStyleUtil.getDefaultStyle(this.typeId)
    this._order = (theme && theme.order_override) ?
      theme.order_override :
      originalOrder
    this._attributeColumns = theme && theme.attribute_columns
  }

  async syncStyle (propagate?: boolean, immediate?: boolean) {
    if (immediate) await this.SyncStyle(propagate)
    else this._syncStyleDebounce.next(propagate as boolean)
  }

  private async SyncStyle (propagate?: boolean) {
    let ready: boolean
    do {
      ready = true
      const style = this.getAppliedStyle()
      await this.waitForLayerLoaded()
      const appliedStyle = await this.applyStyle(style.style)
      if (!style.visible) {
        break
      }

      if (!appliedStyle) {
        ready = false
        await CommonUtil.delay(100)
      } else if (this.layer) {
        this.layer.setZIndex(CommonUtil.invertIndex(this._maxIndex(), this.order))
        if (propagate) {
          this.checkBaseGradient()
          this._styleSynced.next({})
        }
      }
    } while (!ready)
  }

  getAppliedStyle (): {
    override: boolean
    visible: boolean
    style: Partial<LayerStyleParameters>
  } {
    const groupStyle = this.getGroupStyle()
    if (!groupStyle) {
      return { override: false, visible: this.visible, style: cloneDeep(this._style) }
    } else {
      let style: any

      if (groupStyle.override) {
        style = cloneDeep(groupStyle.style)
      } else {
        style = cloneDeep(this._style)
      }

      if (isNaN(+(this._style.opacity as number))) this.updateStyle({ opacity: 1 })
      if (isNaN(+(groupStyle.style.opacity as number))) groupStyle.style.opacity = 1
      style.opacity = Math.max(0, (this._style.opacity as number) - (1 - (groupStyle.style.opacity as number)))

      return {
        visible: groupStyle.visible ? this.visible : false,
        override: groupStyle.override,
        style
      }
    }
  }

  getGroupStyle (): {
    override: boolean
    visible: boolean
    style: Partial<StyleParameters>
  } | undefined {
    if (this._allElements().includes(this)) return undefined
    for (const el of this._allElements()) {
      if (el instanceof AppLayer) continue
      if (el.containsLayer(this.id)) {
        return { visible: el.visible, style: el.style, override: el.styleApplied }
      }
    }
  }

  async applyStyle (style?: StyleParameters): Promise<boolean> {
    const obj = this.getAppliedStyle()
    const groupOverride = obj.override
    const targetStyle: LayerStyleParameters = groupOverride ? obj.style : (style || obj.style)

    this.opacity = targetStyle.opacity || 0
    if (this.layer) this.layer.setVisible(!!obj.visible)

    if (!(this.layer instanceof VectorLayer)) return true
    await this.waitForLayerLoaded()

    const features = this.features

    const customColorsActive = LayerStyleUtil.getCustomColorState(targetStyle, features)

    // TODO: This is a temporary solution to invert/contrast style of point, so that we could see difference between building and point
    // data, as they are stored as one geometry
    const pointStyle = LayerStyleUtil.stylePointFeature(
      targetStyle, customColorsActive, this.isPropertyView
    )

    // Configure clustering
    const source = this.layer.getSource()
    if (source instanceof olSource.Cluster) {
      // use as any so linter would not complain about null style which is valid
      this.layer.setStyle(f => pointStyle(f as AppFeature) as any)
      this.UpdateLocalClustering()
    } else {

      const lineStyle = LayerStyleUtil.styleLineFeature(targetStyle, customColorsActive)
      const polyStyle = LayerStyleUtil.stylePolygonFeature(targetStyle, customColorsActive)

      const getStyles = (f: AppFeature, geom?: olGeom.Geometry) => {
        if (!(f instanceof Feature)) return null

        // Return null if filtered here, because GeometryCollection would break whole layer if
        // it had null in style array
        if (OlUtil.featureFilteredOut(f)) return null

        if (!geom) return null
        switch (geom.getType()) {
          case 'LineString':
          case 'MultiLineString':
            return lineStyle(f)
          case 'Polygon':
          case 'MultiPolygon':
            return polyStyle(f)
          case 'Point':
          case 'MultiPoint':
            return pointStyle(f)
          case 'GeometryCollection':
            const collection = geom as GeometryCollection
            return collection.getGeometries().map(g => getStyles(f, g))
          default:
            return null
        }
      }
      this.layer.setStyle(f => {
        if (!(f instanceof Feature)) return null as any
        return getStyles(f, f.getGeometry())
      })
    }

    return true
  }

  checkBaseGradient () {
    if (!this.vector || !this.layer) return
    if (this._style.gradient && !this.getAppliedStyle().override) {
      for (const attribute in this._style.gradient) {
        const gradient = this._style.gradient[attribute]
        if (gradient.active) {
          this.applyGradient(
            gradient.column,
            gradient.steps,
            attribute as 'fill' | 'border',
            this.features,
            `base_${this.id}`,
            -1,
            this._style[attribute].A,
            gradient.range
          )
        }
      }
    }
  }

  applyGradient (
    selectedColumn: string,
    handles: {
      percentage: number
      color: string
    }[],
    styleAttribute: 'fill' | 'border',
    features: AppFeature[],
    queryId: string,
    order?: number,
    opacity = 1,
    range?: Db.Helper.Geo.GradientRange
  ) {
    if (!features || !features.length) return
    let { distinctClasses, sorted } = LayerUtil.getDistinctFeatures(features, selectedColumn)
    // TODO: Requires handles and gradient style refactor. Handles should contain a label
    // and map value between labels, not by percentage alone
    sorted.forEach((el, index) => distinctClasses[el].color = LayerStyleUtil.getColorFromGradient(
      ConvertUtil.mapRange(index + 1, 1, sorted.length, 1, 100), handles
    ))

    if (this._features) {
      if (range) {
        const cols: string[] = []
        if (range.min && range.min.columnName) cols.push(range.min.columnName)
        if (range.max && range.max.columnName) cols.push(range.max.columnName)

        this._features.setStyleCondition({
          [styleAttribute]: { rangeColumns: cols.length ? cols : undefined }
        })
      } else {
        this._features.setStyleCondition({})
      }
    }
    for (const f of features) {
      const properties = f.getProperties() as IFeatureSystemProperties
      if (!properties.customColors) {
        properties.customColors = {
          fill: [],
          border: []
        }
      }
      if (range) {
        const min = !range.min ? sorted[0] : range.min.value || range.min.value === 0 ? range.min.value : properties[range.min.columnName as string]
        const max = !range.max ?
          sorted.filter(x => x !== 'null').slice(-1)[0] :
          range.max.value ?
            range.max.value :
            properties[range.max.columnName as string]

        let percent = ConvertUtil.mapRange(parseInt(properties[selectedColumn], 10), min, max, 1, 100)
        if (isNaN(percent)) {
          percent = (
            !CommonUtil.isUndefined(max) && CommonUtil.isUndefined(min)
          ) ? 100 : 1
        }
        distinctClasses[properties[selectedColumn]].color = LayerStyleUtil.getColorFromGradient(
          percent, handles
        )
      }
      const mappedStyle = distinctClasses[properties[selectedColumn]] || distinctClasses[Object.keys(distinctClasses)[0]]
      if (!properties.customColors[styleAttribute]) properties.customColors[styleAttribute] = []
      const selectedCstColors = properties.customColors[styleAttribute] as IFeatureCustomColor[]
      const styleWithId: IFeatureCustomColor | undefined = selectedCstColors.find((cC: any) => `${cC.id}` === queryId)

      if (!styleWithId) {
        const styleObj: IFeatureCustomColor = {
          id: queryId,
          color: mappedStyle.color,
          active: true,
          order
        }

        styleObj.color.A = opacity

        selectedCstColors.push(styleObj)
        properties.customColors[styleAttribute] = selectedCstColors.sort((a, b) => (a.order || -1) > (b.order || -1) ? 1 : -1)
      } else {
        styleWithId.color = mappedStyle.color
        styleWithId.color.A = opacity
        styleWithId.active = true
      }
      f.set('customColors', properties.customColors)
    }

    setTimeout(() => this.syncStyle(), 0)
  }

  async updateLayerExtent (obj?: IPNewExtent) {
    if (!obj && this.layer instanceof VectorLayer) {
      obj = {
        extent: this.vectorExtent,
        projection: 'EPSG:3857'
      }
    }

    if (obj === undefined) return

    if (obj.extent && !LayerUtil.extentValid(obj.extent)) obj = { extent: null }

    if (obj && this.extent && this.extent.extent === obj.extent) return

    await this._api.orm.Workspaces().Workspace(this.workspaceId)
    .Layer(this.id)
    .Extent()
    .save(obj).run()

    this._extent = obj.extent ? obj : undefined
    this.extentUpdated = true
    if (this.layer) this.layer.setProperties({ extentDetails: this.extent })
  }

  async removeFeaturesGeometry (id: number | string) {
    this.reloadCount++

    try {
      await this._sourceApi.Features().Feature(id).deleteGeometry().run()
      const feature = this.features.find(x => x.get(this.idKey) === id)
      if (feature) feature.setGeometry(undefined)
    } catch (error: any) {
      throw error
    } finally {
      this.reloadCount--
    }
  }

  async removeFeatures (ids: number[], geometryOnly: boolean = false) {
    if (!this.layer) return
    if (!(this.baseSource instanceof olSource.Vector)) return
    this.reloadCount++

    const queue = [...ids]
    while (queue.length) {
      const chunk = queue.splice(0, 10)
      if (!geometryOnly) {
        await Promise.all(chunk.map(x => this._sourceApi.Features().Feature(x).delete().run()))
      } else {
        await Promise.all(chunk.map(x => this._sourceApi.Features().Feature(x).deleteGeometry().run()))
      }

      this.RemoveFeaturesFromLocalLayer(chunk, geometryOnly)
    }

    this.reloadCount--
    this._getLatestExtent.next({})
    this._getLatestGeomTypes.next({})

    this._reloaded.next(undefined)
  }

  private RemoveFeaturesFromLocalLayer (features: (number | string | AppFeature)[], geometryOnly: boolean = false): number[] {
    if (!this._features) return []
    const removed: number[] = []
    const allFeatures = this._features ? this._features.array : []

    for (let i = 0; i < allFeatures.length; i++) {
      const existing = allFeatures[i]
      const id = existing.get(this.idKey)
      for (let y = 0; y < features.length; y++) {
        const delFeature = features[y]
        const match = delFeature instanceof Feature ? delFeature === existing : id === delFeature
        if (!match) continue

        removed.push(i)
        features.splice(y, 1)
        y--

        if (geometryOnly) {
          existing.setGeometry(null as any)
        } else {
          this._removed.next([existing])
          this._features.remove(existing, i)
          i--
        }

        break
      }
    }

    return removed
  }

  async updateVectorRows (rows: IPVectorTableRow[], geomChange: boolean) {
    const features = await this._sourceApi.Features().update(rows).run()

    this.loadFeatures(features.rows, {
      geomChange,
      with: features.extensions && {
        geometries: features.extensions.geometries
      }
    })
  }

  async reloadFeatures (ids: (string | number)[]) {
    // TODO: For now this only reloads existing features, need to complete logic
    // to add new rows if they match dataset filters and remove deleted rows
    const existingFeatures = this.features.filter(x => ids.includes(x.get(this.idKey)))
    if (!existingFeatures.length) return

    const updatedFeatures = await this._sourceApi.Features()
    .get(existingFeatures.map(x => x.get(this.idKey))).run()

    const reloadedFeatures = this.loadFeatures(updatedFeatures.rows, {
      with: updatedFeatures.extensions && {
        geometries: updatedFeatures.extensions.geometries
      }
    })

    if (reloadedFeatures) this._reloaded.next(reloadedFeatures)
  }

  protected ReloadSharedFeatures (current: AppFeature[], old: AppFeature[] = []) {
    const relatedLayers: AppLayer[] = []
    // Also reload 'old' ids if defined, because related layers might have them (but not the new ids), which might
    // or might not change after dataset reload.
    // In property view case we do not return origin id to client side, so can't refresh based on it
    const ids: (string | number)[] = [
      ...current, ...old
    ].map(f => f.get(this.idKey))

    if (this.preset) {
      relatedLayers.push(...this._allLayers().filter(l => l.preset === this.preset && l.id !== this.id))
    }

    if (!ids.length || !relatedLayers.length) return

    for (const layer of relatedLayers) {
      layer.reloadFeatures(ids)
    }
  }

  async savePropertyViewRows (rows: (IUpdatePvAnot | IUpdatePvMl)[], reloadRelated = true) {
    if (!this.layer) return
    const source = this.baseSource
    if (!(source instanceof olSource.Vector)) return

    let updatedRows: API.Res.Layer.Rows
    if (this._layerMode === Db.Vip.LayerMode.ANOT) {
      updatedRows = await this._sourceApi.PropertyView().Annotation().save(rows as IUpdatePvAnot[]).run()
    } else if (this._layerMode === Db.Vip.LayerMode.ANOT_QA) {
      updatedRows = await this._sourceApi.PropertyView().AnnotationQa().save(rows as IUpdatePvAnot[]).run()
    } else if (this._layerMode === Db.Vip.LayerMode.ML_HE) {
      updatedRows = await this._sourceApi.PropertyView().MlHe().save(rows as IUpdatePvMl[]).run()
    } else if (this._layerMode === Db.Vip.LayerMode.ML_QA) {
      updatedRows = await this._sourceApi.PropertyView().MlQa().save(rows as IUpdatePvMl[]).run()
    } else {
      throw new AppError(`Cannot save property view data. ${this._layerMode ?
        `Layer mode ${this._layerMode} is not meant for editing.` :
        'Layer mode is not defined for layer.'
      }`)
    }
    const updatedFeatures = this.loadFeatures(updatedRows.rows, {
      with: updatedRows.extensions && {
        geometries: updatedRows.extensions.geometries
      }
    })
    this.syncStyle(true, true)
    if (reloadRelated && updatedFeatures) this.ReloadSharedFeatures(updatedFeatures)
  }

  async resetPropertyViewRows (rows: (IModifyPvAnot | IModifyPvMl)[]) {

    if (!this.layer) return
    const source = this.baseSource
    if (!(source instanceof olSource.Vector)) return

    let updatedRows: API.Res.Layer.Rows
    if (this._layerMode === Db.Vip.LayerMode.ANOT_QA) {
      updatedRows = await this._sourceApi.PropertyView().AnnotationQa().reset(rows as IModifyPvAnot[]).run()
    } else if (this._layerMode === Db.Vip.LayerMode.ML_HE) {
      updatedRows = await this._sourceApi.PropertyView().MlHe().reset(rows as IModifyPvMl[]).run()
    } else if (this._layerMode === Db.Vip.LayerMode.ML_QA) {
      updatedRows = await this._sourceApi.PropertyView().MlQa().reset(rows as IModifyPvMl[]).run()
    } else {
      throw new AppError(`Cannot reset property view data. ${this._layerMode ?
        `Layer mode ${this._layerMode} data cannot be reverted.` :
        'Layer mode is not defined for layer.'
      }`)
    }

    const updatedFeatures = this.loadFeatures(updatedRows.rows, {
      with: updatedRows.extensions && {
        geometries: updatedRows.extensions.geometries
      }
    })

    this.syncStyle(true)
    if (updatedFeatures) this.ReloadSharedFeatures(updatedFeatures)
  }

  async replaceFeaturesList (featureIds: (string | number)[], rows: Partial<IPVectorTableRow>[]) {
    this.reloadCount++
    const res = await this._sourceApi.Features().replace(featureIds, rows).run()
    this.RemoveFeaturesFromLocalLayer(featureIds)

    this.loadFeatures(res.rows, {
      with: res.extensions && {
        geometries: res.extensions.geometries
      }
    })
    this.reloadCount--
  }

  async overrideExceptionGeometry (feature: AppFeature, geometry: string) {
    if (!this.readOnlyException) {
      console.warn(`Cannot perform action, this action can only be performed on read-only layers that allow geometry override by system maintainers.`)
      return
    }

    this.reloadCount++
    try {
      switch (this.preset) {
        case Db.Vip.LayerPreset.FLOOD_WARNING_AREAS:
          const floodGeomId = feature.get(Db.Fred.Flood.FloodArea.FLOOD_GEOMETRY_ID)
          const floodAreaId = feature.get(Db.Fred.Flood.FloodArea.FLOOD_AREA_ID)
          const partition = this._features && this._features.timeSeriesPartition[floodAreaId]
          const affectedFeatures = partition && partition.features

          await this._api.orm.Layers().Presets().Preset(this.preset)
          .Features().Feature(floodGeomId).updateGeometry({
            wkt_geometry: geometry
          }).run()

          if (affectedFeatures) this.reloadFeatures(affectedFeatures.map(f => f.get(this.idKey)))
          break
        default:
          throw new AppError(`Cannot perform action, geometry update not implemented.`)
      }
    } catch (error: any) {
      throw error
    } finally {
      this._reloadCount--
    }
  }

  async separateMultiGeom (f: AppFeature) {
    const columns = this.attributeColumns || []
    const modifiedRows: Partial<IPVectorTableRow>[] = []

    let geoms: olGeom.Geometry[] = []
    const geom = f.getGeometry()
    if (geom instanceof olGeom.MultiPolygon) {
      geoms = geom.getPolygons()
    } else if (geom instanceof olGeom.MultiLineString) {
      geoms = geom.getLineStrings()
    } else if (geom instanceof olGeom.MultiPoint) {
      geoms = geom.getPoints()
    }

    if (!geoms.length) return

    for (const geom of geoms) {
      const row: Partial<IPVectorTableRow> = {
        // If it's new feature, then do not append SRID as it will get added in
        // new row dialog after form is submitted
        wkt_geometry: OlUtil.featureToWkt(new Feature(geom))
      }

      for (const col of columns) {
        if (Columns.System.includes(col.prop)) continue
        if (row[col.prop] === undefined) row[col.prop] = f.get(col.prop)
      }
      modifiedRows.push(row)
    }

    if (modifiedRows.length === 1) {
      const row = modifiedRows[0]
      row[this.idKey] = f.get(this.idKey)
      await this.updateVectorRows([row] as any, true)

    } else if (modifiedRows.length > 1) {
      const proceed = await new Promise(res => {
        let message = `Multi-geometry will be split into '${modifiedRows.length}' separate geometries/rows.`
        if (modifiedRows.length > 500) message += ` This could take a while.`
        message += ' Proceed with separating nested geometries?'
        this._trackDialog(
          this._prompt.prompt(message, {
            yes: () => res(true),
            no: () => res(false)
          })
        )
      })
      if (!proceed) return

      await this.replaceFeaturesList(
        [f.get(this.idKey)],
        modifiedRows
      )
    }
  }

  async insertFeatures (rows: IPNewVectorRow[]) {
    const items = await Promise.all(
      rows.map(r => this._sourceApi.Features().add(r).run())
    )
    let features: Geojson.Feature[] = []
    let geomExt: API.Res.Layer.Ext.Geom[] = []
    for (const item of items) {
      features.push(item.row)
      if (item.extensions) {
        if (item.extensions.geometries) {
          geomExt.push(...item.extensions.geometries)
        }
      }
    }

    this.loadFeatures(features)
  }

  loadFeatures (
    fts: (Geojson.Feature | AppFeature)[],
    opts: {
      geomChange?: boolean
      atPosition?: number
      dataProjection?: string
      with?: {
        geometries?: API.Res.Layer.Ext.Geom[]
      }
    } = {}
  ) {
    if (!this.layer || !this._features) return

    if (opts.geomChange === undefined) opts.geomChange = true

    const source = this.baseSource
    if (!(source instanceof olSource.Vector)) return
    this.reloadCount++

    const features = fts.map(f => {
      f = f instanceof Feature ? f : new OlGeojson().readFeature(f, {
        dataProjection: opts.dataProjection,
        featureProjection: 'EPSG:3857'
      })

      return f
    })

    // Use Feature.values_ for faster access of idKey
    const reloadFeatures: AppFeature[] = []
    const allFeatures = this._features ? this._features.array : []
    for (let i = 0; i < allFeatures.length; i++) {
      const existing = allFeatures[i]
      const id = (existing as any).values_[this.idKey]
      for (let y = 0; y < features.length; y++) {
        const newF = features[y]
        if ((newF as any).values_[this.idKey] !== id) continue

        existing.setProperties(CommonUtil.mergeDeep(
          existing.getProperties(), newF.getProperties()
        ))
        existing.setGeometry(newF.getGeometry())
        features.splice(y, 1)
        reloadFeatures.push(existing)
        y--
        break
      }
    }

    this._features.add(features, opts.atPosition)
    reloadFeatures.push(...features)
    if (opts.with && opts.with.geometries) {
      this._features.applyGeometryExt(opts.with.geometries)
    }

    this._getLatestGeomTypes.next({})
    const isCluster = this.layer && this.layer.getSource() instanceof olSource.Cluster
    const onlyPoints = !features.some(f => {
      const geom = f.getGeometry()
      return geom && !geom.getType().toLowerCase().includes('point')
    })
    if (isCluster && !onlyPoints) {
      this.layer.setSource(source as any)
    }

    this.reloadCount--
    if (opts.geomChange) this._getLatestExtent.next({})

    this._features.recalculateGeometries()
    this._reloaded.next(reloadFeatures)
    // NOTE : Added a columns changed to force a dataset reload
    // sometimes the reload rows function does not
    // reload the dataset correctly
    this._columnsChanged.next({})
    return reloadFeatures
  }

  getRenderedFeature (f: AppFeature) {
    // Return cluster if source is clustered
    const source = this.layer && this.layer.getSource()
    if (!(source instanceof olSource.Cluster)) return f

    return source.getFeatures().find(cluster => cluster.get('features').includes(f)) || f
  }

  private AdjustSource () {
    if (!this.vector || !this.layer) return
    const source = this.layer && this.layer.getSource()
    const allPoints = this.geometryTypes.every(x => x.includes('POINT'))
    if (source instanceof olSource.Cluster && !allPoints) {
      this.layer.setSource(source.getSource() as any)
    } else if (source instanceof olSource.Vector && allPoints) {
      this.layer.setSource(
        new olSource.Cluster({
          source,
          geometryFunction: (feature) => {
            const geom = feature.getGeometry()
            return (geom && geom.getType() === 'Point') ? geom : null as any
          }
        }) as any
      )
    }
  }

  async getLayerExtent (): Promise<olExtent.Extent> {
    let extent: olExtent.Extent
    if (this._extent && this._extent.extent) {
      extent = this._extent.extent
      if (this._extent.projection !== 'EPSG:3857') {
        extent = olProj.transformExtent(
          extent,
          this._extent.projection || 'EPSG:4326',
          'EPSG:3857'
        )
      }
      return extent
    }

    if (!this._features || !this._features.array.length) {
      throw new AppError(`Cannot zoom to a layer with 0 geometries.`)
    }
    const allExtent = this._features.extent
    if (!allExtent) throw new AppError('Layer extent is empty.')
    const renderedExtent = this._features.renderedExtent
    extent = renderedExtent || allExtent

    if (!LayerUtil.extentValid(extent as any)) throw new AppError('Layer extent is invalid.')
    this.updateLayerExtent({ extent: allExtent as any, projection: 'EPSG:3857' })

    return extent
  }

  protected async RenderLayer (source: IRWorkspaceLayer): Promise<ImplementedLayer> {
    let layer: ImplementedLayer

    layer = (!source.parameters || !source.parameters.vector_tiles) ?
    await this.NewVectorLayerFromServer(source.layer_id) :
    await this.NewVectorTileLayer(source)

    return layer
  }

  protected async NewVectorLayerFromServer (
    sourceId: string
  ): Promise<olLayer.Vector<any>> {
    const geojson = await this.GetGeojson()
    const geometryTypes = await this._api.orm
    .Workspaces().Workspace(this.workspaceId).Layer(sourceId)
    .getGeometryTypes().run()

    let layerSource = new olSource.Vector({})
    // 29/08/2018 NOTE: Some vector layers seem to call change only when it's added before
    // loading features, some only after adding features. So we have one event listener
    // in beginning, on in end. Cause of this - unknown, maybe a race condition?
    layerSource.once('change', e => {
      layerSource.setProperties({ loaded: true })
    })

    if (this._features) this._features.destroy()
    this._features = new Features(
      this.idKey,
      layerSource, {
        timeSeries: this._vectorTimeSeriesConf ? {
          conf: this._vectorTimeSeriesConf,
          selection: this._renderedTimeSeriesSelection
        } : undefined,
        setZIndex: this.GetFeatureZIndexFn()
      }
    )

    const features = new OlGeojson().readFeatures(geojson.layer, {
      dataProjection: undefined,
      featureProjection: 'EPSG:3857'
    })

    this._features.add(features)

    if (geojson.extensions && geojson.extensions.geometries) {
      this._features.applyGeometryExt(geojson.extensions.geometries)
    }

    if (geometryTypes.length === 1 && geometryTypes[0] === 'POINT') {
      layerSource = new olSource.Cluster({
        source: layerSource,
        geometryFunction: (feature) => {
          const geom = feature.getGeometry()
          return (geom && geom.getType() === 'Point') ? geom : null as any
        }
      })
    }

    const vectorLayer = new olLayer.Vector<any>({
      className: v4(),
      source: layerSource
    })

    if (this.zoomRange) {
      if (this.zoomRange.min_zoom) vectorLayer.setProperties({ minZoom: this.zoomRange.min_zoom })
      if (this.zoomRange.max_zoom) vectorLayer.setProperties({ maxZoom: this.zoomRange.max_zoom })
    }

    vectorLayer.getSource().once('change', e => {
      vectorLayer.setProperties({ loaded: true })
    })

    return vectorLayer
  }

  protected async NewVectorTileLayer (
    source: IRWorkspaceLayer
  ): Promise<olLayer.Vector<any>> {
    const projection = olProj.get('EPSG:3857')
    if (!projection) throw new AppError('Projection not found: EPSG:3857')
    const tileGrid = new TileGrid({
      extent: projection.getExtent(),
      resolutions: OlUtil.getResolution(),
      tileSize: 512
    })

    const geometryTypes = await this._api.orm
    .Workspaces().Workspace(this.workspaceId).Layer(source.layer_id)
    .getGeometryTypes().run()

    // NOTE: Time series support might not be ideal
    const vectorSource = new olSource.Vector({
      format: new OlGeojson({
        dataProjection: 'EPSG:3857',
        featureProjection: 'EPSG:3857'
      }),
      strategy: olLoadingstrategy.tile(tileGrid)
    })
    if (this._features) this._features.destroy()
    this._features = new Features(
      this.idKey, vectorSource, {
        timeSeries: this._vectorTimeSeriesConf ? {
          conf: this._vectorTimeSeriesConf,
          selection: this._renderedTimeSeriesSelection
        } : undefined,
        setZIndex: this.GetFeatureZIndexFn()
      }
    )
    vectorSource.setLoader(async (extent, resolution, projection) => {
      // const tileCoord = tileGrid.getTileCoordForCoordAndResolution(
      //   OlExtent.getCenter(extent), resolution
      // )
      // const [z, x, y] = [
      //   tileCoord[0],
      //   Math.pow(2, tileCoord[0]) + tileCoord[1],
      //   -tileCoord[2] - 1
      // ]
      const wgs84 = olProj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326') as [number, number, number, number]
      const res = await this._sourceApi.getVectorGeojsonFromExtent(wgs84).run()

      if (!res) return
      // NOTE: Temporary fix to exclude already existing features
      // TODO: Fix tile loading
      res.features = res.features.filter(x => {
        const id = x && x.properties ? x.properties[this.idKey] : undefined
        const isNew = !vectorSource.getFeatureById(id)
        return isNew
      })

      const features = new OlGeojson({
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(JSON.stringify(res)).map(x => {
        x.setId(x.get(this.idKey))
        return x
      })

      this._features && this._features.add(features)
    })

    // 29/08/2018 NOTE: Some vector layers seem to call change only when it's added before
    // loading features, some only after adding features. So we have one event listener
    // in beginning, on in end. Cause of this - unknown, maybe a race condition?
    vectorSource.once('change', e => {
      vectorSource.setProperties({ loaded: true })
    })

    let clusterSource
    if (geometryTypes.length === 1 && geometryTypes[0] === 'POINT') {
      clusterSource = new olSource.Cluster({
        source: vectorSource,
        geometryFunction: (feature) => {
          const geom = feature.getGeometry()
          return (geom && geom.getType() === 'Point') ? geom : null as any
        }
      })
    }

    const vectorLayer = new olLayer.Vector<any>({
      className: v4(),
      source: clusterSource || vectorSource
    })

    vectorLayer.once('change', e => {
      vectorLayer.setProperties({ loaded: true })
    })

    vectorLayer.set('tiled', true)

    return vectorLayer
  }

  async getFeaturesInExtent (extent?: number[]) {
    let geomExtensions: API.Res.Layer.Ext.Geom[] = []
    let newFeatures: AppFeature[] = []
    this._loading = true
    let reloaded = true
    try {
      const res = await this.GetGeojson(undefined, extent)
      newFeatures = new OlGeojson().readFeatures(
          res.layer,
          { featureProjection: 'EPSG:3857' }
        )
      if (res.extensions && res.extensions.geometries) {
        geomExtensions.push(...res.extensions.geometries)
      }

      if (newFeatures) {
        this.loadFeatures(newFeatures, {
          with: geomExtensions.length ? {
            geometries: geomExtensions
          } : undefined
        })
      }

      this._reloaded.next(newFeatures)
    } catch (error: any) {
      handleError(error)
      this._loadingError = error.message
      reloaded = false
    }
  }

  async setRenderedTimeSeries (timeSeries: Db.Helper.Geo.Timeseries, realTime: boolean = false) {
    if (!this._features) return
    const prevRendered = this._renderedTimeSeriesSelection
    const prevRange = prevRendered && prevRendered.date_range

    this._renderedTimeSeriesSelection = cloneDeep(timeSeries)
    this.DefaultRenderedTimeSeries()
    const renderedRange = this._renderedTimeSeriesSelection.date_range

    const rangeChanged = !prevRange ? true : (
      renderedRange.from !== prevRange.from || renderedRange.to !== prevRange.to
    )

    if (rangeChanged && !this._waitForSpatialSelect) {
      this.reloadCount++
      try {
        let geomExtensions: API.Res.Layer.Ext.Geom[] = []
        let newFeatures: AppFeature[] = []
        let delFeatures: AppFeature[] = []

        if (prevRange) {
          const changes = realTime ? [{
            from: moment(renderedRange.from),
            to: moment(renderedRange.to),
            type: 'add'
          }] : TimeUtil.getRangeDifference(
            moment(prevRange.from),
            moment(prevRange.to),
            moment(renderedRange.from),
            moment(renderedRange.to)
          )

          let clear = false
          const dateRanges: { from: string, to: string }[] = []
          for (const change of changes) {
            if (change.type === 'full-del') {
              clear = true
              continue
            }

            if (change.type === 'add') {
              dateRanges.push({
                from: change.from.toISOString(),
                to: change.to.toISOString()
              })
              continue
            }

            if (change.type === 'partial-del') {
              const prevBlock = changes[changes.indexOf(change) - 1]
              const nextBloc = changes[changes.indexOf(change) + 1]
              delFeatures = delFeatures.concat(
                this._features.getRemovableByDateRange(
                  change.from, change.to,
                  prevBlock && ['add', 'same'].includes(prevBlock.type),
                  nextBloc && ['add', 'same'].includes(nextBloc.type)
                )
              )
            }
          }

          if (dateRanges.length) {
            const res = await this.GetGeojson(dateRanges)
            newFeatures = new OlGeojson().readFeatures(
              res.layer,
              { featureProjection: 'EPSG:3857' }
            )
            if (res.extensions && res.extensions.geometries) {
              geomExtensions.push(...res.extensions.geometries)
            }
          }

          if (clear) {
            this._features.clear()
            this._removed.next([])
          } else if (delFeatures.length) {
            this.RemoveFeaturesFromLocalLayer(delFeatures)
          }

          if (newFeatures) {
            this.loadFeatures(newFeatures, {
              with: geomExtensions.length ? {
                geometries: geomExtensions
              } : undefined
            })
          }

        } else {
          const res = await this.GetGeojson()
          newFeatures = new OlGeojson().readFeatures(
            res.layer,
            { featureProjection: 'EPSG:3857' }
          )
          if (res.extensions && res.extensions.geometries) {
            geomExtensions.push(...res.extensions.geometries)
          }
          this.ReplaceAllFeatures(newFeatures, geomExtensions)
        }
      } catch (error: any) {
        handleError(error)
        throw new AppError(`Failed to update layer based on time series change. ${error.message}`)
      } finally {
        this.reloadCount--
      }
    }

    if (this._features) {
      this._features.setTimeSeries(cloneDeep(this._renderedTimeSeriesSelection))
      this.syncStyle(true)
    }
    this.CheckTimeSeriesState()
  }

  async saveTimeSeries (timeSeries: Db.Helper.Geo.Timeseries) {
    this._timeSeriesSelection = timeSeries
    this.setRenderedTimeSeries(timeSeries)
    this.SaveAttributeTimeline()
    this._timeSeriesSaved = true
  }

  private async GetGeojson (dateRanges?: {from: string, to: string}[], extent?: number[]): Promise<API.Res.Layer.Geojson> {
    if (this.isTimeSeries && !dateRanges && this._renderedTimeSeriesSelection) {
      dateRanges = [{
        from: this._renderedTimeSeriesSelection.date_range.from,
        to: this._renderedTimeSeriesSelection.date_range.to
      }]
    }
    return this._sourceApi.getGeojson({
      appendAssets: true, dateRanges, extent
    }).run()
  }

  private async GetLayerCsv (columns?: string[]): Promise<string> {
    let dateRanges: ILayerCsv = {}
    if (this.isTimeSeries && this._renderedTimeSeriesSelection) {
      dateRanges.dateRanges = {
        from: this._renderedTimeSeriesSelection.date_range.from,
        to: this._renderedTimeSeriesSelection.date_range.to
      }
    }

    if (columns) dateRanges.columns = columns

    return this._sourceApi.createLayerCsv(dateRanges).run()
  }

  async calculateSampledLayerQueryResult (queryId: string): Promise<any> {
    let body: any = {}
    if (this.isTimeSeries && this._renderedTimeSeriesSelection) {
      body.dateRanges = {
        from: this._renderedTimeSeriesSelection.date_range.from,
        to: this._renderedTimeSeriesSelection.date_range.to
      }
    }
    body.queryId = queryId

    return await this._sourceApi.calculateSampledStatQueryResult(body).run()
  }

  private async DownLoadCsvfromServer (filePath: string)  {
    return this._sourceApi.downloadCsv(filePath).run()
  }

  protected UpdateLocalClustering () {
    if (!(this.layer instanceof VectorLayer)) return
    const enabled = this._style.cluster && this._style.cluster.enabled

    const source = this.layer.getSource()

    if (!enabled && source instanceof olSource.Cluster) {
      source.setDistance(0)
      return
    } else if (enabled && !(source instanceof olSource.Cluster)) {
      // TODO: Should not happen; Implement if on cluster set to 0 - source is changed to vector source
      console.warn('Cluster enabled but source is not Cluster!')
    }

    if (enabled) {
      const cluster = source as olSource.Cluster
      const style = this._style.cluster || {}
      let range = style.range
      if (!range || range < 10) {
        range = !range ? 20 : 10
        style.range = range

        this._attributeChange.next({})
        this._styleChanged.next({})
      }

      cluster.setDistance(range)
    }
  }

  toggleClustering (enabled?: boolean) {
    if (!(this.layer instanceof VectorLayer)) return

    if (enabled === undefined) enabled = !this.isClustered

    if (!this._style.cluster) this._style.cluster = {}
    this._style.cluster.enabled = enabled
    if (enabled && !this._style.cluster.range) {
      this._style.cluster.range = 20
    }
    this._updateClustering.next({})
    this._attributeChange.next({})
    this._styleChanged.next({})
  }

  setClusterDistance (distance: number) {
    if (this._style.cluster && this._style.cluster.range === distance) return

    if (!this._style.cluster) this._style.cluster = {}
    this._style.cluster.enabled = true
    this._style.cluster.range = distance >= 10 ? distance : 10

    this._updateClustering.next({})
    this._attributeChange.next({})
    this._styleChanged.next({})
  }

  editColumnNames () {
    if (!this.layer) throw new AppError(`Cannot edit columns. Layer is not loaded properly.`)

    const columns: any[] = this.attributeColumns as any[]

    const beforeEdit = JSON.stringify(columns)

    this._trackDialog(
        this._dialog
        .open(ColumnsDialogComponent, {
          data: {
            columns,
            layer: this,
            attributes: GenerateAttributes.getVectorLayerAttributes(this.layer)
          }
        })
      )
      .afterClosed()
      .subscribe(async result => {
        if (result && result.length) {
          if (JSON.stringify(result) !== beforeEdit) {
            // NOTE: This is done here manually, instead of using setter of
            // attributeColumns, because we want to make sure columns
            // are saved before layer is reloaded
            this._attributeColumns = this.appendDefaultColumns(result)
            this._columnsChanged.next({})
            this._columnsRefreshed.next({})
            await this.SaveAttributeColumns()
            await this.reload()
          }
        }
      })
  }

  async createColumns (columns: IPNewVectorColumn[]) {
    if (!this.timeSeriesGroup) {
      await this._sourceApi.addColumns(columns).run()
    } else {
      await this._api.orm.Workspaces().Workspace(this.workspaceId).Views().View(this._viewId).LayerGroups()
      .Group(this.timeSeriesGroup).addColumns(columns).run()
    }

    await this.refreshColumns()
    this._columnsChanged.next({})
  }

  protected async SaveAttributes () {
    await this._sourceStyleApi.update({
      order_override: this.order,
      visible: this.visible,
      style_parameters: this._style
    }).run()
  }

  protected async SaveName () {
    await this._sourceStyleApi.update({
      name_override: this.title,
    }).run()
  }

  private async SaveAttributeTimeline () {
    await this._sourceStyleApi.update({
      time_series: this._timeSeriesSelection
    }).run()
  }

  async createPVGeometry (geom: IPNewPVGeometry) {
    if (
      !this.layerMode || this.layerMode === Db.Vip.LayerMode.READ_ONLY ||
      this.layerMode === Db.Vip.LayerMode.ANOT
    ) {
      console.warn(`Invalid action for PV layer mode '${this.layerMode}'`)
      return
    }
    geom = {
      ...geom,
      ...this.GetPvGeomModifyContext()
    }

    const res = await this._sourceApi.PropertyView().GeometriesMod(this.layerMode)
    .create(geom).run()

    const features = this.loadFeatures([res.row], {
      with: res.extensions && {
        geometries: res.extensions.geometries
      }
    })
    if (features && features.length) await this.UpdatePVFeaturesArea(features)

    return res.row
  }

  private UpdatePVFeaturesArea = async (features: AppFeature[], old: AppFeature[] = []) => {
    const updateRows: (IUpdatePvAnot | IUpdatePvMl)[] = []
    for (const feature of features) {
      const geomCol = feature.getGeometry() as GeometryCollection
      const polygon: olGeom.Polygon | undefined = geomCol.getGeometries().find(x => x.getType() === 'Polygon') as any
      if (!polygon) continue

      const row: IUpdatePvAnot | IUpdatePvMl = this.preparePvGeomsForModification([{
        geom_id: feature.getProperties()[this.idKey],
        image_configuration_id: feature.getProperties()[PVGeojsonProperties.IMAGE_CONFIGURATION_ID]
      }])[0]

      row.area = +ConvertUtil.geometryToMeasureString(polygon, 3, false)
      updateRows.push(row)
    }

    if (updateRows.length) await this.savePropertyViewRows(updateRows, false)

    this.ReloadSharedFeatures(features, old)
  }

  private GetPvGeomModifyContext (): IPModifyPVGeometry {
    if (Db.Vip.LayerMode.ANOT_QA === this.layerMode) {
      const setId = this.parameters.preset_measure_set && this.parameters.preset_measure_set.anot_guide_set_id
      if (!setId) throw new AppError(`Context for annotation modification is not set. Please contact GSI support.`)

      return {
        anot_guide_set_id: setId
      }
    } else if ([Db.Vip.LayerMode.ML_HE, Db.Vip.LayerMode.ML_QA].includes(this.layerMode as any)) {
      const setId = this.parameters.preset_measure_set && this.parameters.preset_measure_set.ml_dataset_id
      if (!setId) throw new AppError(`Context for machine learning output modification is not set. Please contact GSI support.`)

      return {
        ml_dataset_id: setId
      }
    } else {
      throw new AppError(`Cannot modify Property View geometry. Editing mode is invalid.`)
    }
  }

  async deletePVGeometry (geomId: string) {
    if (
      !this.layerMode || this.layerMode === Db.Vip.LayerMode.READ_ONLY ||
      this.layerMode === Db.Vip.LayerMode.ANOT
    ) {
      console.warn(`Invalid action for PV layer mode '${this.layerMode}'`)
      return
    }

    await this._sourceApi.PropertyView().GeometriesMod(this.layerMode)
    .Geometry(geomId).delete(
      this.GetPvGeomModifyContext()
    ).run()

    const feature = this.features.find(x => x.get(this.idKey) === geomId)
    this.RemoveFeaturesFromLocalLayer([geomId])

    if (feature) this.ReloadSharedFeatures([], [feature])

    this._reloaded.next(undefined)
  }

  async replacePVGeometry (geomId: string, geoms: IPNewPVGeometry[]) {
    if (
      !this.layerMode || this.layerMode === Db.Vip.LayerMode.READ_ONLY ||
      this.layerMode === Db.Vip.LayerMode.ANOT
    ) {
      console.warn(`Invalid action for PV layer mode '${this.layerMode}'`)
      return
    }

    const modifyContext = this.GetPvGeomModifyContext()
    geoms = geoms.map(x => ({
      ...x,
      ...modifyContext
    }))
    const res = await this._sourceApi.PropertyView().GeometriesMod(this.layerMode)
    .Geometry(geomId).replace(geoms).run()

    const originFeature = this.features.find(f => f.get(this.idKey) === geomId)
    const [oldIndex] = this.RemoveFeaturesFromLocalLayer([geomId])
    // Reverse because when we reload workspace, it's most likely
    // last row in this array will be at the top of table,
    // so we want to replace them in the same order they are likely to render
    // after refresh
    const features = this.loadFeatures(res.rows.reverse(), {
      atPosition: oldIndex,
      with: res.extensions && {
        geometries: res.extensions.geometries
      }
    })

    if (features && features.length) {
      await this.UpdatePVFeaturesArea(features)
      this.ReloadSharedFeatures(features, originFeature ? [originFeature] : [])
    }

    return res.rows
  }

  protected async SaveAttributeColumns () {
    if (!this._attributeColumns) return
    if (!this.timeSeriesGroup) {
      await this._sourceStyleApi.update({
        attribute_columns: this._attributeColumns
      }).run()
    } else {
      await this._api.orm.Workspaces().Workspace(this.workspaceId).Views().View(this._viewId).LayerGroups()
      .Group(this.timeSeriesGroup).updateColumns(this._attributeColumns).run()

      this.getLinkedTimeSeries && this.getLinkedTimeSeries().forEach(l => l._attributeColumns = cloneDeep(this._attributeColumns))
    }
  }

  applyColumnMeta (newColMeta: Db.Helper.Geo.ColumnMeta[]) {
    const existingColMeta = this.columnMeta
    for (const col of newColMeta) {
      let colMeta = existingColMeta.find(meta => meta.prop === col.prop)
      if (!colMeta) {
        existingColMeta.push(col)
        continue
      }
      if (!colMeta.composite) colMeta.composite = {}
      if (col.composite && col.composite.aggregation) {
        colMeta.composite.aggregation = col.composite.aggregation
      }

      if (!colMeta.composite.alias) colMeta.composite.alias = []
      const alias = col.composite && col.composite.alias
      if (!alias || !alias.length) continue

      for (const a of alias) {
        colMeta.composite.alias.splice(colMeta.composite.alias.indexOf(a), 1)
        colMeta.composite.alias.splice(0, 0, a)
      }
    }
    this.columnMeta = existingColMeta
  }

  protected async SaveColumnMeta () {
    if (!this._columnMeta) return
    try {
      await this._sourceApi.updateColumnMeta(this._columnMeta).run()
    } catch (error: any) {
      console.error(`Failed to update col meta`)
    }
  }

  async alterIfLinked () {
    if (!this.getLinkedTimeSeries) return true
    const linkedTo = this.getLinkedTimeSeries()
    if (!linkedTo.length) return true

    return new Promise((res) => {
      this._trackDialog(
        this.prompt.prompt(`Layer '${this.title}' is linked to ${linkedTo.length} other layer(s). Changes will be applied to all of the layers, proceed?`, {
          yes: () => res(true),
          no: () => res(false)
        })
      )
    })
  }

  removeStyleQueries (styleIds: string[]) {
    this.features.forEach(feature => {
      const props = (feature as any).values_
      if (!props.customColors) return
      for (const key in props.customColors) {
        const customColors = props.customColors[key]
        const matches = customColors.filter((x: any) => x.id === undefined || styleIds.includes(x.id))
        // TODO: undefined because only the base styles wouldn't have an id - however this might not longer be true
        // undefined still found, just not sure where it gets inserted, all should be `base_*`
        if (matches && matches.length) {
          for (const match of matches) {
            match.active = false
          }
        }
      }
    })
  }

  getColumnMetadata (columnName: string): Partial<
    Db.Helper.Geo.AttributeColumn & IRVectorColumn & {
      chartAvailable: boolean
    }
  > {
    let meta: any = {}
    if (this._tableColumns) {
      const match = this._tableColumns.find(x => x.name === columnName)
      if (match) meta = match
    }

    if (this._attributeColumns) {
      const match = this._attributeColumns.find(x => x.prop === columnName)
      if (match) meta = Object.assign(meta, match)
    }

    if (meta.name && !meta.prop) meta.prop = meta.name

    meta.chartAvailable = ['integer', 'float'].includes(meta.type as IRVectorColumnType)
    return meta
  }

  preparePvGeomsForModification (rows: {geom_id: string, image_configuration_id?: string}[]): (IModifyPvAnot | IModifyPvMl)[] {
    if (!this.layerMode || this.layerMode === Db.Vip.LayerMode.READ_ONLY) return []

    if ([Db.Vip.LayerMode.ANOT, Db.Vip.LayerMode.ANOT_QA].includes(this.layerMode)) {
      const anotSetId: any = this.anotGuideSet && this.anotGuideSet.anot_guide_set_id
      // Should not happen, but check just in case
      if (!anotSetId) {
        throw new AppError(`Cannot save annotation modifications. No dataset has been selected for the layer.`)
      }

      return rows.map(r => ({
        geom_id: r.geom_id,
        anot_guide_set_id: anotSetId
      } as IModifyPvAnot))
    } else {
      const mlDatasetId = this.mlDataset && this.mlDataset.ml_dataset_id

      // Should not happen, but check just in case
      if (!mlDatasetId) {
        throw new AppError(`Cannot save machine learning modifications. No dataset has been selected for the layer.`)
      }

      return rows.map(r => ({
        geom_id: r.geom_id,
        ml_dataset_id: mlDatasetId,
        image_configuration_id: r.image_configuration_id
      } as IModifyPvMl))
    }
  }

  async updateSpatialqueryRequired (val: boolean) {
    const body: IPUpdateLayer = {
      spatial_query_required: val
    }
    await this._api.orm.Workspaces().Workspace(this.workspaceId).Layer(this.id)
    .updateLayer(body).run()
  }

  private ReplaceAllFeatures (newFeatures: AppFeature[], geomExt?: API.Res.Layer.Ext.Geom[]) {
    this.reloadCount++
    // remove features that are not included in the new features
    const forRemoval: (number | string)[] = []
    const features = this.features

    for (const existingFeature of features) {
      const existingId = existingFeature.get(this.idKey)

      if (!newFeatures.some(f => f.get(this.idKey) === existingId)) {
        forRemoval.push(existingId)
      }
    }
    this.RemoveFeaturesFromLocalLayer(forRemoval)

    // add/update existing features from new features
    this.loadFeatures(newFeatures, {
      dataProjection: 'EPSG:4326',
      with: {
        geometries: geomExt
      }
    })

    this.reloadCount--
  }

  private GetFeatureZIndexFn () {
    if (this.preset === Db.Vip.LayerPreset.FLOOD_WARNING_AREAS) {
      return (f: AppFeature) => {
        const severity: Db.Fred.Severity = f.get(Db.Fred.Flood.FloodWarning.SEVERITY_LEVEL)

        switch (severity) {
          case Db.Fred.Severity.WARNING_NO_LONGER_IN_FORCE:
            f.zIndex = 1
            break
          case Db.Fred.Severity.FLOOD_ALERT:
            f.zIndex = 2
            break
          case Db.Fred.Severity.FLOOD_WARNING:
            f.zIndex = 3
            break
          case Db.Fred.Severity.SEVERE_FLOOD_WARNING:
            f.zIndex = 4
            break
        }
      }
    }
  }

  async waitForLoad () {
    let timer = 0
    while (!this._loaded) {
      // Wait for two mins then break
      if (timer === 120000) break
      await CommonUtil.delay(100)
      timer += 100

    }
  }

  appendDefaultColumns (columns?: Db.Helper.Geo.AttributeColumn[]): Db.Helper.Geo.AttributeColumn[] {
    if (!columns) return []

    const defaultcolumns: Db.Helper.Geo.AttributeColumn[] = [{
      prop: 'update_user',
      override: 'Updated by User',
      display: true,
      display_attribute_table: false
    }, {
      prop: 'updated_at',
      override: 'Updated at',
      display: true,
      display_attribute_table: false
    }]

    // NOTE: Only append columns if they are not included already.
    if (columns.find(c => c.prop === 'update_user' || c.prop === 'update_at') || this.preset) {
      return columns
    }
    return columns.concat(defaultcolumns)
  }

}

applyMixins(AppLayer, [DialogCleanup])
