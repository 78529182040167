import { ITelemetry } from '@core/types'
import { IJson } from '@vip-shared/interfaces/json'
import GeoJSON from 'ol/format/GeoJSON'
import * as olProj from 'ol/proj'
import { cloneDeep } from 'lodash'
export class DroneConvertUtil {
  static readTelemetry (geojson: IJson): ITelemetry {
    const features = new GeoJSON().readFeatures(geojson, {
      dataProjection: undefined,
      featureProjection: 'EPSG:3857'
    })
    const telemetry: ITelemetry = {
      time: [],
      gimbalYaw: [],
      coordinate: []
    }

    for (let feature of features) {
      const {
        latitude, longitude,
        time, camera_angle
      } = feature.getProperties()

      telemetry.time.push(time)
      telemetry.gimbalYaw.push(camera_angle)
      telemetry.coordinate.push(olProj.transform(
        [ longitude, latitude ], 'EPSG:4326', 'EPSG:3857'
      ) as [number, number])
    }

    return telemetry
  }

  static trimFeatures (telemetry: ITelemetry, lineGeojson: IJson, startingIndex: number = 0) {
    const lineCoordinates = lineGeojson.features[0].geometry.coordinates
    if (lineCoordinates.length !== telemetry.time.length) console.warn('Line json and telemetry mismatch.', lineCoordinates.length, telemetry.time.length)
    const trimmed: [number, number][] = []
    const hiddenStart: [number, number][] = []
    const hiddenEnd: [number, number][] = []

    const timeStart = +telemetry.time[startingIndex < 0 ? 0 : startingIndex]
    const lastIndex = telemetry.time.length - 1
    const endIndex = Math.min(startingIndex + lastIndex, lastIndex)
    const timeEnd = +telemetry.time[endIndex]
    for (let i in telemetry.time) {
      const time = telemetry.time[+i]
      if (time >= timeStart && time <= timeEnd) {
        trimmed.push(lineCoordinates[i])
      }

      if (time <= timeStart) {
        hiddenStart.push(lineCoordinates[i])
      }

      if (time >= timeEnd) {
        hiddenEnd.push(lineCoordinates[i])
      }
    }
    const geojson = cloneDeep(lineGeojson)
    const hiddenGeojson = cloneDeep(lineGeojson)

    geojson.features[0].geometry.coordinates = trimmed
    hiddenGeojson.features[0].geometry.type = 'MultiLineString'
    hiddenGeojson.features[0].geometry.coordinates = [hiddenStart, hiddenEnd]
    return { geojson, hiddenGeojson }
  }
}
