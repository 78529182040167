import { environment } from 'environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http'
import { ErrorHandlerService } from './error-handler/error-handler.service'
import { HttpMethod } from '@core/enum/http-method'
import { JwtService } from '@services/core/jwt/jwt.service'
import * as FormData from 'form-data'
import { ApiRoute, Api, RequestOptions } from '@vip-shared/models/api-orm'
import { retry, map, tap, catchError } from 'rxjs/operators'
import { IApiResponse } from '@vip-shared/interfaces/api/response'
import { Observable, firstValueFrom, of } from 'rxjs'
import { reqQueryParam } from '@vip-shared/models/static-defs'
import AppError from '@core/models/app-error'

interface HttpRequestOptions extends RequestOptions {
  endpoint?: string
  body?: any,
  form?: FormData
  headers?: any
}

@Injectable({
  providedIn: 'root'
})
export class VipApiService {
  orm = new Api(this)

  constructor (
    private _http: HttpClient,
    private _errorService: ErrorHandlerService,
    private _jwtService: JwtService
  ) {}

  getHeaders (formHeaders?: any): HttpHeaders {
    const jwtToken = this._jwtService.getBearerToken() as string
    const headers = { ...formHeaders } || {}

    if (jwtToken) {
      headers['authorization'] = jwtToken
    }
    if (!formHeaders) {
      headers['content-type'] = 'application/json'
    }
    return new HttpHeaders(headers)
  }

  appendJwtQuery (url: string) {
    const endUrl = url.split('/').pop()
    const join = !endUrl ? '' :
      !endUrl.includes('?') ? '?' :
      !endUrl.endsWith('&') ? '&' : ''

    return `${url}${join}${reqQueryParam.VIP_API_TOKEN}=${encodeURIComponent(this._jwtService.getBearerToken() || '')}`
  }

  async handleRoute (route: ApiRoute<any>, options: RequestOptions = {}): Promise<any> {
    if (!Object.values(HttpMethod).includes(route.method as HttpMethod)) {
      throw new AppError(`Unimplemented method '${route.method}'`)
    }
    return this.Request(route.method as HttpMethod, {
      body: route.body,
      endpoint: route.endpoint,
      responseType: options.responseType,
      loop: options.loop,
      form: route.form,
      headers: route.customHeaders,
      progressCb: options.progressCb,
      cancelCb: options.cancelCb
    })
  }

  private async Request (
    method: HttpMethod, reqOptions: HttpRequestOptions = {}
  ): Promise<any> {
    if (!reqOptions.loop) reqOptions.loop = 0
    if (!reqOptions.endpoint) reqOptions.endpoint = ''

    const url = `${environment.VIPServer}${reqOptions.endpoint}`

    if (url.includes('/undefined')) throw new AppError(`Invalid URL detected '${url}'. Cannot make a request.`)

    const options: any = {
      headers: this.getHeaders(reqOptions.headers)
    }
    if (reqOptions.progressCb) {
      options.reportProgress = true
      options.observe = 'events'
    }
    const payloadParse = !reqOptions.responseType && !reqOptions.endpoint.startsWith('/proxy')
    const mapObservable = (o: Observable<any>) => {
      // TODO: Cleanup, need a cleaner way of capturing upload progress
      if (options.reportProgress || reqOptions.cancelCb) {
        return new Promise((res, rej) => {
          const sub = o.pipe(
            retry(reqOptions.loop),
            catchError(err => of(err))
          ).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
              if (reqOptions.progressCb) reqOptions.progressCb(event)
            } else if (event.type === HttpEventType.Response) {
              res(event.body && event.body.payload)
            } else if (event instanceof HttpErrorResponse) {
              rej(event)
            }
          })
          if (reqOptions.cancelCb) {
            reqOptions.cancelCb(() => {
              if (!sub.closed) sub.unsubscribe()
            })
          }
        })
      } else {
        return firstValueFrom(o.pipe(
          retry(reqOptions.loop),
          // If response is to API, return payload
          // Otherwise it's a proxy and don't alter response
          payloadParse ? map((response: IApiResponse) => response && response.payload) : tap()
        ))
      }
    }
    let result
    try {
      if (reqOptions.form && method !== HttpMethod.GET) {
        if (reqOptions.body) reqOptions.form.append('body', JSON.stringify(reqOptions.body))
        options.body = reqOptions.form
        result = await mapObservable(this._http.request(method, url, options))
      } else {
        if (reqOptions.responseType) options.responseType = reqOptions.responseType
        if (reqOptions.body) options.body = reqOptions.body
        result = await mapObservable(this._http.request(method, url, options))

      }
      return result
    } catch (error: any) {
      return this._errorService.handle(
        error,
        reqOptions.endpoint.startsWith('/proxy'),
        reqOptions.endpoint !== this.orm.Root().ping().endpoint ? this.orm.Root().ping() : undefined
      )
    }
  }
}
