import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, Subject, merge } from 'rxjs'

import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { v4 } from 'uuid'
import { IUploadMonitoringTarget } from '@core/types'
import { AuthService, JwtService } from '@services/core'
import { auditTime } from 'rxjs/operators'
import * as moment from 'moment'
import { cloneDeep } from 'lodash'
import { WorkspaceService } from '@services/workspace/workspace.service'

@Injectable({
  providedIn: 'root'
})
export class LayerUploadStateService {
  private _monitoringTargets: IUploadMonitoringTarget[] = []
  get monitoringTargets () {
    return cloneDeep(this._monitoringTargets)
  }

  // Uploads that are only tracked client side
  // Consume them together with the ones tracked on server
  // in order to avoid having 'loading layer 0/1' and getting loaded layer
  // while upload state still says 'adding 1 layer'
  localUploads: {
    upload: IUploadMonitoringTarget['uploads'][0],
    id: string
  }[] = []

  private _updateStates = new Subject()

  private _layerUploaded = new Subject<string>()
  get layerUploaded () {
    return this._layerUploaded.asObservable()
  }

  private _statesUpdated = new Subject()
  get statesUpdated () {
    return this._statesUpdated.asObservable()
  }

  private _globalCount = {
    uploading: 0,
    errored: 0,
    total: 0,
    processing: 0
  }
  get globalCount () {
    return this._globalCount
  }

  constructor (
    private _api: VipApiService,
    private _authService: AuthService,
    private _workspaceService: WorkspaceService
  ) {
    this._authService.sessionActive.subscribe(val => {
      if (!val) this.Cleanup()
    })
    this._workspaceService.onExit.subscribe(this.Cleanup.bind(this))

    this._updateStates.pipe(auditTime(10000))
    .subscribe(() => this.fetchUploadStates())

    this._updateStates.next({})
  }

  private Cleanup () {
    this._monitoringTargets.splice(0)
    this._globalCount = {
      uploading: 0,
      errored: 0,
      total: 0,
      processing: 0
    }
  }

  monitorWorkspace (target: IUploadMonitoringTarget['target']) {
    let existing = this._monitoringTargets.find(x =>
      +x.target.workspaceId === +target.workspaceId &&
      +x.target.productId === +target.productId
    )
    if (!existing) {
      existing = {
        target,
        uploads: []
      }
      this._monitoringTargets.push(existing)
    }

    return existing
  }

  async setUploadAsSeen (localId: string) {
    let upload: IUploadMonitoringTarget['uploads'][0] | undefined
    for (const t of this._monitoringTargets) {
      upload = t.uploads.find(x => x.localId === localId)
      if (upload) break
    }
    if (!upload) return
    if (!upload.error && !upload.sourceId) return

    upload.seen = true
    // Mark as seen only rows that are created by this session
    if (upload.uploadId) {
      await this._api.orm.Layers().LayerUploads()
      .disableRow(upload.uploadId).run()
    }

    this.CountStates()
  }

  monitorWorkspaceSource (
    target: IUploadMonitoringTarget['target'],
    layerName: string,
    progress: () => string
  ): IUploadMonitoringTarget['uploads'][0] {
    const monitoring = this.monitorWorkspace(target)
    const uploadObject = {
      localId: v4(),
      name: layerName,
      state: 'Uploading',
      ownerSession: true,
      createdAt: moment().toISOString(),
      progress
    }

    monitoring.uploads.push(uploadObject)
    this.CountStates()

    this._updateStates.next({})
    return uploadObject
  }

  async fetchUploadStates () {
    for (const x of this._monitoringTargets) {
      const t = x.target
      const uploads = await this._api.orm.Layers()
      .LayerUploads().get(t.productId, t.workspaceId)
      .run()
      this.UpdateTargetUploadStates(x, uploads)
    }

    while (this.localUploads.length) {
      const upload = this.localUploads.splice(0, 1)[0]
      upload.upload.sourceId = upload.id
      upload.upload.seen = false
      this._layerUploaded.next(upload.id)
    }

    this.CountStates()
    if (this._globalCount.uploading || this._globalCount.processing) {
      this._updateStates.next({})
    }
  }

  private UpdateTargetUploadStates (
    target: IUploadMonitoringTarget,
    uploads: Db.Vip.Geo.ISourceUpload[]
  ) {
    for (const upload of uploads) {
      // Find already matched upload which will have uploadId
      let match = target.uploads.find(x =>
        x.uploadId && x.uploadId === upload.source_upload_id
      )
      // Fallback: Matching by name, from unmatched uploads
      if (!match) {
        match = target.uploads.find(x =>
          !x.uploadId && x.name === upload.layer_display_name
        )
      }
      // Fallback: This is a new upload from different session
      if (!match) {
        target.uploads.push({
          localId: v4(),
          name: upload.layer_display_name,
          uploadId: upload.source_upload_id,
          ownerSession: false,
          sourceId: upload.layer_id,
          error: upload.error_message,
          state: upload.state_message,
          createdAt: upload.created_at as string,
          // If upload has a final state, then set as false so we can mark it as seen
          seen: upload.error_message || upload.layer_id ? false : undefined
        })

        continue
      }

      // If state has changed to complete or errored
      if (
        (upload.layer_id && !match.sourceId) ||
        (upload.error_message && !match.error)
      ) {
        match.seen = false
      }

      // Layer uploaded - load it
      if (upload.layer_id && !match.sourceId) {
        match.sourceId = upload.layer_id
        this._layerUploaded.next(upload.layer_id)
      }

      match.uploadId = upload.source_upload_id
      match.state = upload.state_message
      match.error = upload.error_message
    }
  }

  private CountStates () {
    for (const x of this._monitoringTargets) {
      x.uploads = x.uploads.filter(x => !x.seen)

      x.count = {
        total: x.uploads.length,
        errored: x.uploads.reduce((sum, y) => sum + (y.error ? 1 : 0), 0),
        uploading: x.uploads.reduce((sum, y) => sum + ((!y.error && (!y.uploadId && !y.sourceId)) ? 1 : 0), 0),
        processing: x.uploads.reduce((sum, y) => sum + ((!y.error && y.uploadId && !y.sourceId) ? 1 : 0), 0)
      }
    }

    this._globalCount = this._monitoringTargets.reduce((count, x) => {
      if (x.count) {
        count.uploading += x.count.uploading
        count.total += x.count.total
        count.errored += x.count.errored
        count.processing += x.count.processing
      }
      return count
    }, {
      uploading: 0,
      errored: 0,
      total: 0,
      processing: 0
    })

    this._statesUpdated.next({})
  }
}
