import { Component, Input, OnDestroy, ViewChild } from '@angular/core'
import * as _ from 'lodash'
import { EmissionsJourneysService } from '../../services/journeys.service'
import { BaseChartDirective } from 'ng2-charts'
import { Db } from '@vip-shared/models/db-definitions'
import { Subscription } from 'rxjs'
import { ThemeService } from '@services/core/theme/theme.service'

interface EmissionJourneyClass extends Db.Vip.Emissions.IJourney { class: string }
interface EmmissionsGroup {
  [key: string]: EmissionJourneyClass[]
}
@Component({
  selector: 'app-emissions-graph-overview',
  templateUrl: './emissions-graph.component.html',
  styleUrls: ['./emissions-graph.component.scss']
})
export class EmissionsGraphComponent implements OnDestroy {
  @ViewChild(BaseChartDirective, { static: true }) chart!: BaseChartDirective

  private journeys: Db.Vip.Emissions.IJourney[] = []
  private vessels: Db.Vip.Emissions.IVessel[] = []

  public chartData: Array<any> = []
  public chartLabels: Array<any> = []
  public chartOptions: any = {
    responsive: true
  }

  public chartLegend: boolean = true
  public columns: string[] = ['power', 'energy', 'fc', 'nox', 'pm', 'co2','co2_from_fc']
  public column: string = 'nox'
  public chartType: string = 'bar'
  private _subscriptions = new Subscription()


  constructor(
    private emissionsService: EmissionsJourneysService,
    private _themeService: ThemeService
  ) {
    this._subscriptions.add(
        this.emissionsService.vesselsChanged.subscribe(vessels => {
        this.vessels = vessels
      })
    )

    this._subscriptions.add(
      this.emissionsService.journeysChanged.subscribe(journeys => {
        this.journeys = journeys
        this.UpdateChart()
      })
    )
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
  }


  private GroupBy(xs, key): EmmissionsGroup {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public columnChange() {
    this.UpdateChart()
  }

  private RoundNumber = (num:number) => {
    return Math.round(num * 100) / 100
  }

  private GetScale() {
    return {
      responsive: true,
      scales: {
        y:{
          display: true,
          scaleLabel: {
           display: true,
           labelString: `${this.column} ${this.emissionsService.getMetric(this.column)}`,
          },
          ticks: {
            color: this._themeService.getColor('foreground')
          },
          grid: {
            color: this._themeService.getColor('foreground-20')
          },
         },
        x: {
          display: true,
          ticks: {
            color: this._themeService.getColor('foreground')
          },
          grid: {
            color: this._themeService.getColor('foreground-20')
          },
        }
      },
      plugins: {
        legend: {
          labels: {
            color: this._themeService.getColor('foreground')
          }
        }
      }
    }
  }

  private UpdateChart() {
    this.chartOptions = this.GetScale()
    const journeyClass = this.journeys.map((ob) => {
      let vessel = this.vessels.find(v => v.vessel_id === ob.vessel_id)
      if (vessel) ob['vessel_type'] = vessel.vessel_type
      return ob as EmissionJourneyClass
    })

    const journeyGrouped = this.GroupBy(journeyClass, 'vessel_type')
    delete journeyGrouped.undefined

    const labels = Object.keys(journeyGrouped)
    this.chartLabels = labels

    const values = labels.map(key => {
      return journeyGrouped[key].reduce((prev, cur) => {
        return this.RoundNumber(prev) + this.RoundNumber(cur[this.column] as any)
      }, 0)
    })

    this.chartData = [
      { data: values, label: this.column }
    ]
  }
}
