import { Component, OnInit, ViewChild , ElementRef, EventEmitter, Input } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import { ThemeService } from '@services/core/theme/theme.service'
import { FloodAlertsService } from '@services/workspace/products/fred/flood-alerts/flood-alerts.service'
import { Chart, ChartConfiguration } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'

interface DataSet {
  borderColor: string
  fill: boolean
  label: string
  data: number[]
}

@Component({
  selector: 'app-flood-alerts-graph',
  templateUrl: './flood-alerts-graph.component.html',
  styleUrls: ['./flood-alerts-graph.component.scss']
})
export class FloodAlertsGraphComponent implements OnInit {
  @Input() isDashboardWidget: boolean = false;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>
  error?: string
  primaryColor = '#FFC107'
  lineChartData: any[] = [
    { borderColor: 'rgb(168, 212, 0)', backgroundColor: 'rgb(168, 212, 0)', fill: false, label: 'Alerts', data: [] },
    { borderColor: 'rgb(255, 165, 0)', backgroundColor: 'rgb(255, 165, 0)', fill: false, label: 'Warnings', data: [] },
    { borderColor: 'rgb(255, 0 , 0)', backgroundColor: 'rgb(255, 0 , 0)', fill: false, label: 'Severe', data: [] }
  ]

  protected _chart?: Chart

  samplingSizes: number[] = [1, 2, 6, 12]
  timePeriods: number[] = [1, 2, 3, 4]
  disable: boolean = true
  max: number = 40
  labelIndex: any[] = []
  dragZoom: boolean = false
  maxDataPoints: number = 0

  manualRefresh: EventEmitter<void> = new EventEmitter<void>()
  minValue = 0
  maxValue = this.maxDataPoints
  loading: boolean = false

  get excludeSepa (): boolean {
    return this._floodAlertsService.excludeSepa
  }

  constructor (
    protected _floodAlertsService: FloodAlertsService,
    private _themeService: ThemeService
  ) {
    this._floodAlertsService.clearArrays()
  }

  get lineChartLabels (): string[] {
    return this._floodAlertsService.labels
  }

  get timePeriod (): number | undefined {
    return this._floodAlertsService.timePeriod
  }

  set timePeriod (value: number | undefined) {
    this._floodAlertsService.timePeriod = value
  }

  get sampleSize (): number | undefined {
    return this._floodAlertsService.sampleSize
  }

  set sampleSize (value: number | undefined) {
    this._floodAlertsService.sampleSize = value
  }

  ngOnInit () {
    this.LoadData()
  }

  private async LoadData () {
    try {
      this.ChartConfig()
      if (!this.sampleSize) this.sampleSize = 2
      if (!this.timePeriod) this.timePeriod = 2
      this.reloadData()
    } catch (error: any) {
      console.log(error)
    }
  }

  changeSamplePeriod (e: MatSelectChange) {
    this._floodAlertsService.sampleSize = e.value
    this.reloadData()
  }

  changeTimePeriod (e: MatSelectChange) {
    this._floodAlertsService.timePeriod = e.value
    this.reloadData()
  }

  async reloadData () {
    this.loading = true
    setTimeout(async () => {
      if (!this.sampleSize || !this.timePeriod) return
      await this. _floodAlertsService.getGraphWarnings(this.sampleSize, this.timePeriod)
      this.ChartConfig()
      this.loading = false
    }, 500)
  }

  private ChartConfig () {
    const maxHeight = this._floodAlertsService.calculateMax()
    this.maxDataPoints = this._floodAlertsService.labels.length

    if (this._chart) this._chart.destroy()
    this._chart = new Chart(this.canvasRef.nativeElement, {
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
      plugins: [zoomPlugin],
      options: {
        responsive: true,
        spanGaps: true,
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0
          }
        },
        maintainAspectRatio: this.isDashboardWidget ? false : true,
        aspectRatio: this.isDashboardWidget ? 2 : 3,
        scales : {
          y: {
            ticks: {
              color: 'white',
              max : maxHeight,
              min: 0,
            },
            grid: {
              color: this._themeService.getColor('foreground-20')
            }
          },
          x: {
            beginAtZero: false,
            ticks: {
              color: 'white',
              stepSize: 1,
              callback: (label: number) => {
                return this.lineChartLabels && this.lineChartLabels[label]
              }
            },
            grid: {
              color: this._themeService.getColor('foreground-20')
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: 'white',
              font: {
                size: 14
              }
            },
            position: 'bottom'
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.05
              },
              mode: 'x',
            }
          }
        },
        tooltips: {
          enabled: false
        }
      }
    } as ChartConfiguration)

    this.labelIndex = this.lineChartLabels.map(x => this.lineChartLabels.indexOf(x).toString())
    if (!this._chart || !this.lineChartData || !this._chart.data.labels || !this._chart.data.datasets) return

    this._chart.data.labels.length = 0
    this._chart.data.labels.push(...this.labelIndex)
    this._chart.data.datasets.push(...this.lineChartData)

    for (let i = 0; this.lineChartData.length > i; i++) {
      if (!this.lineChartData[i].data || !this.lineChartData) return
      switch (this.lineChartData[i].label) {
        case 'Alerts':
          this.lineChartData[i].data.length = 0
          this.lineChartData[i].data.push(...this._floodAlertsService.alertsCount)
          break
        case 'Warnings':
          this.lineChartData[i].data.length = 0
          this.lineChartData[i].data.push(...this._floodAlertsService.warningsCount)
          break
        case 'Severe':
          this.lineChartData[i].data.length = 0
          this.lineChartData[i].data.push(...this._floodAlertsService.severeCount)
          break
        default:
          break
      }
    }
    this._chart.update()
  }

  async excludeSepaChanged (event: any) {
    this._floodAlertsService.toggleExcludeSepa()
    await this.reloadData()
  }

}
