<div mat-dialog-content>
    <p class="st-large" [innerHTML]="dataRef.content"></p>

    <div>
        <mat-form-field *ngFor="let el of selections">
            <mat-label>{{el.displayName}}</mat-label>
            <mat-select [(value)]="el.value">
              <mat-option *ngFor="let option of el.options" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
</div>

<div class="st-button-row">
    <div>
        <button color="primary" mat-button [mat-dialog-close] *ngIf="dataRef.dismissEnabled">Dismiss</button>
    </div>
    <div>
        <button color="primary" mat-button *ngFor="let action of buttonNames" (click)="callAction(action)">{{action}}</button>
    </div>
</div>
