/**
 * Overlay position: 'bottom-left', 'bottom-center',  'bottom-right',
 * 'center-left', 'center-center', 'center-right', 'top-left',
 * 'top-center', 'top-right'
 */
export enum OverlayPositioning {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right',
  CENTER_LEFT = 'center-left',
  CENTER_CENTER = 'center-center',
  CENTER_RIGHT = 'center-right',
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right'
}
