<div #recTable [ngClass]="!isDashboardWidget ? 'st-table-overflow records-table' : 'st-table-overflow records-table-widget'">
  <div *ngIf="isDashboardWidget" class="widget-controls">
    <mat-icon class="icon-align" [matTooltip]="updatedDatesTooltip">info</mat-icon>
    <mat-icon class="icon-align" [matMenuTriggerFor]="menu">tune</mat-icon>
    <mat-menu #menu="matMenu">
      <div class="menu-content">
        <div style="width:100%">
          <mat-slide-toggle
            class="v-align-middle"
            [(ngModel)]="excludeSepa"
            (change)="setDataSource()"
            matTooltip="If toggled on, the count is calculated by excluding information from SEPA areas"
            color="primary">
          </mat-slide-toggle>
          <label mat-label class="label">Exclude SEPA Areas</label>
        </div>
      </div>
    </mat-menu>
  </div>
  <mat-table [ngClass]="isDashboardWidget ? 'table-widget' : ''" [dataSource]="dataSource" matSortDisabled>
    <ng-container matColumnDef="severity">
      <mat-header-cell  [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" *matHeaderCellDef>Severity</mat-header-cell>
      <mat-cell *matCellDef="let record">
        <div [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" [ngClass]="fontStyle(record)" class="st-ellipsis">
          <span appOverflowAnimate>{{ getSeverityName(record) }}</span>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="fw_count">
      <mat-header-cell  [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" *matHeaderCellDef>Count</mat-header-cell>
      <mat-cell *matCellDef="let record">
        <span [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" [ngClass]="fontStyle(record)">{{ record.flood_warning_count }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="claims">
      <mat-header-cell [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" *matHeaderCellDef>Claims</mat-header-cell>
      <mat-cell *matCellDef="let record">
        <span [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" [ngClass]="fontStyle(record)">{{ record.claims }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="exposure">
      <mat-header-cell  [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" *matHeaderCellDef>Exposure</mat-header-cell>
      <mat-cell *matCellDef="let record">
        <span [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" [ngClass]="fontStyle(record)">{{ record.exposure }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pdh">
      <mat-header-cell  [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" *matHeaderCellDef>PDH</mat-header-cell>
      <mat-cell *matCellDef="let record">
        <span [ngStyle]="isDashboardWidget ? {'font-size': fontSize} : ''" [ngClass]="fontStyle(record)">{{ record.pdh_properties }}</span>
      </mat-cell>
    </ng-container>

    <mat-header-row [ngClass]="isDashboardWidget ? 'table-widget-header' : ''" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row [ngClass]="isDashboardWidget ? 'table-widget-rows' : ''" *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-divider></mat-divider>

  <div *ngIf="!isDashboardWidget" class="footer">
    <span>
      <mat-icon class="info v-align-middle" [matTooltip]="updatedDatesTooltip">info</mat-icon>
    </span>

    <label mat-label class="label">Exclude SEPA Areas</label>
    <mat-slide-toggle
      class="v-align-middle"
      [(ngModel)]="excludeSepa"
      (change)="setDataSource()"
      matTooltip="If toggled on, the count is calculated by excluding information from SEPA areas"
      color="primary">
    </mat-slide-toggle>
  </div>
</div>
