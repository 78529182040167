<div class="toolbox-header">
    <h4>Theme Preview</h4>
    <div class="options">
        <mat-icon class="help"
        matTooltip="Click on palette or edit button to change the theme.\n
        In order to save theme, go to 'User Area' and open theme editor for company. In there you will find 'Apply Preview' option.">
        help</mat-icon>
        <mat-icon (click)="editTheme()">edit</mat-icon>
        <mat-icon (click)="cancelPreview()">close</mat-icon>
    </div>
</div>

<app-theme-palette-preview
(click)="editTheme()"
*ngIf="previewTheme?.colors"
[theme]="previewTheme"
[previewButton]="false"></app-theme-palette-preview>
