import { Component } from "@angular/core";

@Component({
  selector: 'app-floodwarning-timeseries-widget',
  templateUrl: './floodwarning-timeseries-widget.component.html',
  styleUrls: ['./floodwarning-timeseries-widget.component.scss']
})
export class FloodWarningTimeseriesComponent {


}
