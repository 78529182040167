import { Injectable } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { IRCustomerUser } from '@vip-shared/interfaces'
import { handleError } from '@core/models/app-error'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor (
    private _api: VipApiService
  ) {}

  async getUsers (): Promise<IRCustomerUser[]> {
    try {
      return this._api.orm.Customers().Users().get().run()
    } catch (error: any) {
      handleError(error)
      return []
    }
  }

  async getRoles (roleType: Db.Vip.Cst.IRole['permissions_type']) {
    try {
      return this._api.orm.Users().getRoles(roleType).run()
    } catch (error: any) {
      handleError(error)
      return []
    }
  }

  async getProducts () {
    try {
      return this._api.orm.Products().get().run()
    } catch (error: any) {
      handleError(error)
      return []
    }
  }
}
