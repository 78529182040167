import { Component, Inject, ChangeDetectorRef, OnInit, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'
import { AlertService } from '@services/core'
import {
  QueryBlock,
  QueryStatement,
  IQueryWRef
} from '@core/models/query-object'

import { AppLayer, AppLayerGroup } from '@core/models/layer'
import AppError, { handleError } from '@core/models/app-error'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { DefaultStyleParameters } from '@vip-shared/models/layer-config/default-style-parameters'
import LayerQueryUtil from '@core/utils/layer-query/layer-query.util'
import { LayerService } from '@services/workspace'
import { CommonUtil } from '@core/utils'

interface DialogData {
  query: IQueryWRef | null
  target?: AppLayer | AppLayerGroup
  styleBuilder?: boolean
}

type attributeOperator = {
  label: string,
  value: string,
  disabled: boolean
}
@Component({
  selector: 'app-query-builder-dialog',
  templateUrl: './query-builder-dialog.component.html',
  styleUrls: ['./query-builder-dialog.component.scss']
})
export class QueryBuilderDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('queryValue', { static: false }) queryValue?: ElementRef
  @Input() layerId
  @Input() isDashboardWidget: boolean = false

  availableColumns?: AppLayer['attributeColumns']
  availableOperators: attributeOperator[] = [
    {
      label: 'Greater than',
      value: 'greater',
      disabled: false
    },
    {
      label: 'Less than',
      value: 'less',
      disabled: false
    },
    {
      label: 'Equal',
      value: 'equal',
      disabled: false
    },
    {
      label: 'Not',
      value: 'not',
      disabled: false
    },
    {
      label: 'Not Empty',
      value: 'notEmpty',
      disabled: false
    },
    {
      label: 'Is Empty',
      value: 'isEmpty',
      disabled: false
    },
    {
      label: 'Starts With',
      value: 'startsWith',
      disabled: false
    },
    {
      label: 'Ends With',
      value: 'endsWith',
      disabled: false
    },
    {
      label: 'Contains',
      value: 'contains',
      disabled: false
    }
  ]
  queryTarget?: IQueryWRef['targetRef']
  styleBuilder?: boolean

  queryName: string = ''
  queryObj?: IQueryWRef
  defaultTitle: string = ''

  private _saving = false

  get layers() {
    return this._layerQueriesService.queryableLayers
  }

  static setOptions(data: DialogData) {
    const options: MatDialogConfig = {
      width: '798px',
      hasBackdrop: true,
      position: {
        top: '72px'
      },
      autoFocus: false,
      data
    }
    return options
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private _dialogRef: MatDialogRef<QueryBuilderDialogComponent, any>,
    private _layerQueriesService: LayerQueriesService,
    private _changeDetector: ChangeDetectorRef,
    private _alertService: AlertService,
    private _layerService: LayerService
  ) {
    if (this.data) {

      if (this.data.query) {
        this.queryObj = this.data.query
        this.queryTarget = this.queryObj.targetRef
      }
      if (!this.queryTarget && this.data.target) {
        this.queryTarget = this.data.target
      }

      if (this.data.styleBuilder) this.styleBuilder = true

      this.queryObj = (this.data && this.data.query) ? this.data.query : undefined
      this.defaultTitle = `Untitled ${this.styleBuilder ? 'style' : 'query'}`
      this.queryName = this.queryObj ? this.queryObj.name : `Untitled ${this.styleBuilder ? 'style' : 'query'}`
      this.availableOperators = this.styleBuilder ? this.availableOperators.slice(0, 6) : this.availableOperators
    }
  }

 async  ngOnInit() {
    if (this.layerId && this.isDashboardWidget) {
      this.queryName = `DashBoard Query - ${CommonUtil.getUuid()}`
      this.queryTarget = await this.setLayerTarget()
    }
    if (this.queryTarget) this.generateFilters(this.queryTarget)
  }

  ngAfterViewInit() {
    if (!this.queryValue) return

    const handle = this.queryValue.nativeElement
    handle.addEventListener('focusin', () => {
      if (this.queryName === this.defaultTitle) {
        this.queryName = ''
      }
    })
    handle.addEventListener('focusout', () => {
      if (this.queryName === '') {
        this.queryName = this.defaultTitle
      }
    })

  }

  async setLayerTarget() {
    return await this._layerService.getById(this.layerId)
  }

  getOptionName(target: AppLayerGroup | AppLayer) {
    // For now name 'time series' because we only have time series linked layers
    return target instanceof AppLayerGroup ? `Time Series of '${target.title}'` : target.title
  }

  async generateFilters(layer: AppLayer | AppLayerGroup) {
    if (this.layers || this.queryTarget) {
      const queryableTarget = this.queryTarget ? this.queryTarget : this._layerQueriesService.getQueryableTarget(layer.id)
      if (queryableTarget && (queryableTarget instanceof AppLayerGroup || queryableTarget.layer)) {
        if (!this.queryObj) {
          this.queryObj = this.NewEmptyQuery(this.queryName, queryableTarget, this.styleBuilder)
        } else {
          this.queryObj.targetRef = queryableTarget
        }

        try {
          const columns = queryableTarget.attributeColumns
          if (!columns || !columns.length) {
            throw new AppError('No columns available for this layer')
          }
          this.availableColumns = columns
        } catch (error: any) {
          handleError(error)
          this.availableColumns = []
          this._alertService.log('Could not generate available filters. ' + error.message)
          this._dialogRef.close()
        }
      } else {
        this.availableColumns = []
      }

      this._changeDetector.detectChanges()
    }
  }

  private NewEmptyQuery(name: string, layer: AppLayer | AppLayerGroup, styleQuery = false): IQueryWRef {
    const newQuery: IQueryWRef = {
      type: styleQuery ? 'style' : 'filter',
      applied: false,
      name,
      targetRef: layer,
      query: {
        blocks: [new QueryBlock(undefined, [new QueryStatement('', '', '')])],
        operator: ''
      },
      index: undefined as any,
      query_id: undefined as any,
      view_id: undefined as any,
      workspace_id: undefined as any
    }

    if (layer instanceof AppLayer) newQuery.layer_id = layer.id
    else newQuery.layer_group_id = layer.id

    if (styleQuery) {
      // TODO: Review query builder / styling logic
      newQuery.query.customColor = {
        fill: {
          color: {
            active: true,
            value: DefaultStyleParameters.VectorStyle.fill
          },
          opacity: DefaultStyleParameters.VectorStyle.fill ? DefaultStyleParameters.VectorStyle.fill.A : 0,
          generalColor: Object.assign({}, DefaultStyleParameters.VectorStyle.fill, { A: 1 })
        },
        border: {
          color: {
            active: true,
            value: DefaultStyleParameters.VectorStyle.border
          },
          opacity: DefaultStyleParameters.VectorStyle.border ? DefaultStyleParameters.VectorStyle.border.A : 0,
          generalColor: Object.assign({}, DefaultStyleParameters.VectorStyle.border, { A: 1 }) // Set by styler, however not registered in interface? Also breaks without it
        }
      }
    }

    newQuery.index = this._layerQueriesService.queries.length
    return newQuery
  }

  addBlock(operator?: string) {
    if (!this.queryObj) return
    if (operator === 'AND' || operator === 'OR') {
      this.queryObj.query.operator = operator
    }
    this.queryObj.query.blocks.push(new QueryBlock(undefined, [new QueryStatement('', '', '')]))
  }

  checkAttributeType(attribute: string) {
    if (this.queryObj) {
      const features = this.queryObj.targetRef.features
      const attributeVal = features[0].getProperties()[attribute]
      this.availableOperators.map((operator, idx) => {
        if (typeof attributeVal === 'number') operator.disabled = idx > 4 ? true : false
        if (typeof attributeVal === 'boolean') operator.disabled = idx < 2 || idx > 3 ? true : false
        if (typeof attributeVal === 'string') operator.disabled = idx < 2 ? true : false
      })
    }
  }

  addStatement(block: number, operator?: string) {
    if (!this.queryObj) return
    if (operator === 'AND' || operator === 'OR') {
      this.queryObj.query.blocks[block].operator = operator
    }
    this.queryObj.query.blocks[block].statements.push(new QueryStatement('', '', ''))
  }

  deleteBlock(index: number) {
    if (!this.queryObj) return
    this.queryObj.query.blocks.splice(index, 1)
  }

  deleteStatement(block: number, index: number) {
    if (!this.queryObj) return
    this.queryObj.query.blocks[block].statements.splice(index, 1)
  }

  async saveQuery() {
    if (!this.queryObj || this._saving) return
    this.queryObj.name = this.queryName

    this._saving = true

    try {
      if (this.styleBuilder) {
        // After save query will be reapplied, setting style to active where applicable
        // TODO: QUERY: Layer should select correct style during style application;
        // should not require clearing layer first
        LayerQueryUtil.clearLayerFromQueryStyle(this.queryObj)
      }

      const enable = this.isDashboardWidget ? false : (this.styleBuilder || !this.queryObj.query_id) ? true : this.queryObj.applied
      const queryId = await this._layerQueriesService.toggleQuery(this.queryObj, enable, true)

      if (!this.styleBuilder) {
        this._layerQueriesService.editMode(false)
      }

      if (!this.isDashboardWidget) this._dialogRef.close()
      return queryId
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  isCreateSaveDisabled(queryObj?: IQueryWRef): boolean {
    return !!
      (
        !queryObj ||
        (
          queryObj.query.blocks.length &&
          !this.AreBlocksValid(queryObj.query.blocks)
        )
      )
  }

  private AreBlocksValid(blocks: QueryBlock[]): boolean {
    for (const block of blocks) {
      if (!this.AreStatementsValid(block.statements as QueryStatement[])) {
        return false
      }
    }
    return true
  }
  private AreStatementsValid(statements: QueryStatement[]): boolean {
    for (const statement of statements) {
      if (!statement.attribute || !statement.operator || !statement.value) {
        if (statement.attribute && (statement.operator === 'notEmpty' || statement.operator === 'isEmpty')) continue
        return false
      }
    }
    return true
  }
}
