<h2 mat-dialog-title>
  <mat-icon>build</mat-icon>
  Dataset Builder
</h2>

<div mat-dialog-content>
  <div class="query-body">
    <div class="query-block" *ngFor="let block of query; let i = index">
      <div class="block-icon" [class.first]="i === 0">
        <mat-icon *ngIf="i === 0" class="icon"
        [matMenuTriggerFor]="deleteMenu"[matMenuTriggerData]="{i1: i}">
          list_alt
        </mat-icon>

        <app-dataset-join-logo *ngIf="i > 0" class="icon" [joinType]="block.join_as"
        [matMenuTriggerFor]="deleteMenu" [matMenuTriggerData]="{i1: i}">
        </app-dataset-join-logo>

        <div class="connector" *ngIf="i !== query.length - 1 || canAddNewBlock"></div>
      </div>

      <div class="block-content">
        <div class="statement" [class.last]="(i === query.length - 1 && !block.sequence?.length) && !canAddNewBlock">
          <mat-icon class="icon" [matMenuTriggerFor]="deleteMenu" [matMenuTriggerData]="{i1: i, i2: -1}">
            table_chart
          </mat-icon>

          <div class="fields">
            <mat-form-field floatLabel="never">
              <mat-select disableOptionCentering [(ngModel)]="block.dataset" placeholder="Dataset"
              (selectionChange)="filterRemainingDatasets()">
                <mat-option [disabled]="dataset.used || dataset.disabled" *ngFor="let dataset of datasetsMeta" [value]="dataset.preset_dataset_tag"
                [matTooltip]="dataset.used ? 'Dataset already selected.' : (
                  dataset.disabled ? dataset.disabledReason : ''
                )">
                  {{ dataset.display_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
        <div class="statement" *ngFor="let subblock of block.sequence; let k = index"
        [class.last]="(i === query.length - 1 && k === block.sequence?.length - 1) && !canAddNewBlock">
          <app-dataset-join-logo class="icon" [joinType]="subblock.join_as"
          [matMenuTriggerFor]="deleteMenu" [matMenuTriggerData]="{i1: i, i2: k}">
          </app-dataset-join-logo>

          <div class="fields">
            <mat-form-field floatLabel="never">
              <mat-select disableOptionCentering [(ngModel)]="subblock.dataset" placeholder="Dataset"
              (selectionChange)="filterRemainingDatasets()">
                <mat-option [disabled]="dataset.used || dataset.disabled" *ngFor="let dataset of datasetsMeta" [value]="dataset.preset_dataset_tag"
                [matTooltip]="dataset.used ? 'Dataset already selected.' : (
                  dataset.disabled ? dataset.disabledReason : ''
                )">
                  {{ dataset.display_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="statement add" *ngIf="canAddNewBlock">
          <mat-icon class="icon" [matMenuTriggerFor]="newNode" [matMenuTriggerData]="{i: i}">add</mat-icon>
        </div>
      </div>
    </div>

    <div class="query-block add" *ngIf="canAddNewBlock">
      <mat-icon class="icon" [matMenuTriggerFor]="newNode">add</mat-icon>
    </div>
  </div>
</div>

<div class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>Cancel</button>
    <button color="primary" mat-button (click)="resetQuery()">Reset</button>
  </div>
  <div class="right">
    <button color="primary" mat-raised-button (click)="saveQuery()">Save</button>
  </div>
</div>

<mat-menu #deleteMenu="matMenu" xPosition="after">
  <ng-template matMenuContent let-i1="i1" let-i2="i2">
    <button mat-menu-item (click)="deleteBlock(i1, i2)">
      Remove {{i2 ? 'node' : 'block'}}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #newNode="matMenu" xPosition="after" class="query-join-menu">
  <ng-template matMenuContent let-i="i">
    <button mat-menu-item (click)="addJoin(join.INNER, i)">
      <app-dataset-join-logo class="icon" [joinType]="join.INNER">
      </app-dataset-join-logo>
      Inner Join
    </button>

    <button mat-menu-item (click)="addJoin(join.INNER_LEFT, i)">
      <app-dataset-join-logo class="icon" [joinType]="join.INNER_LEFT">
      </app-dataset-join-logo>
      Inner Left Join
    </button>

    <button mat-menu-item (click)="addJoin(join.INNER_RIGHT, i)">
      <app-dataset-join-logo class="icon" [joinType]="join.INNER_RIGHT">
      </app-dataset-join-logo>
      Inner Right Join
    </button>

    <button mat-menu-item (click)="addJoin(join.OUTER, i)">
      <app-dataset-join-logo class="icon" [joinType]="join.OUTER">
      </app-dataset-join-logo>
      Outer Join
    </button>

    <button mat-menu-item (click)="addJoin(join.OUTER_LEFT, i)">
      <app-dataset-join-logo class="icon" [joinType]="join.OUTER_LEFT">
      </app-dataset-join-logo>
      Outer Left Join
    </button>

    <button mat-menu-item (click)="addJoin(join.OUTER_RIGHT, i)">
      <app-dataset-join-logo class="icon" [joinType]="join.OUTER_RIGHT">
      </app-dataset-join-logo>
      Outer Right Join
    </button>
  </ng-template>
</mat-menu>
