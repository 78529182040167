<h2 [ngClass]="isDashboardChart?'mat-dialog-title mat-dashboard-title':'mat-dialog-title'">
  <mat-icon> show_chart </mat-icon>
  {{isDashboardChart ? chartName: 'Attributes Chart'}}
</h2>

<div [ngClass]="isDashboardChart ? 'dashboard-chart' : 'mat-dialog-content'">
  <form class="filters" [formGroup]="form">
    <div *ngIf="!isDashboardChart">
      <mat-form-field>
        <input matInput placeholder="Chart name" formControlName="name"/>
      </mat-form-field>

      <mat-form-field *ngIf="!timeScaleLayer">
        <mat-label>X Axis</mat-label>
        <mat-select (selectionChange)="labelChange($event)" [value]="labelColumn">
          <mat-option *ngIf="canResetLabel">
            - Default -
          </mat-option>
          <mat-option *ngFor="let column of labelColumns" [value]="column">
            {{column.override || column.prop}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!isDashboardChart">
      <mat-form-field>
        <mat-label>Dataset Comparison</mat-label>
        <mat-select (selectionChange)="comparisonChange($event)" [value]="selectedComparisonType" [disabled]="!canBeMultiLayer && !canBeTimeChart">
          <ng-container *ngFor="let config of charts">
            <mat-option *ngIf="config.show()"  [value]="config.id">
              {{config.title}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="multiColumns && !timeScaleLayer">
        <mat-label>Y Axis</mat-label>
        <mat-select multiple (selectionChange)="columnSelectionChange($event)" [value]="selectedColumns">
          <mat-option *ngFor="let column of numericColumns" [value]="column">
            {{column.override || column.prop}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="!multiColumns">
        <mat-label>Column</mat-label>
        <mat-select (selectionChange)="columnSelectionChange($event)" [value]="selectedColumns[0]">
          <mat-option *ngFor="let column of numericColumns" [value]="column">
            {{column.override || column.prop}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="toggle-selection">
      <ng-container *ngIf="!isDashboardChart">
        <mat-label mat-tool-tip="Choose the type of chart in which the data is to be displayed">Chart Type</mat-label>
        <mat-button-toggle-group name="chartType" #group="matButtonToggleGroup" aria-label="Choose Chart Type" [value]="selectedChart" (change)="onChartTypeChange(group.value)">
          <mat-button-toggle *ngFor="let chartType of chartTypes" [value]="chartType.value"> {{chartType.displayName}} </mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>

      <button *ngIf="isDashboardChart" color="primary" mat-button (click)="resetZoom()"> Reset Zoom</button>

      <mat-checkbox [(ngModel)]="dragZoom" [ngModelOptions]="{standalone: true}" (change)="onDragZoomChanged($event)"
        matTooltip="Drag on the chart to zoom in to the selected area">Drag Zoom</mat-checkbox>
      <mat-checkbox [(ngModel)]="logEnabled" [ngModelOptions]="{standalone: true}" (change)="onScaleTypeChanged($event)"
        matTooltip="Can be linear or logarithmic">Log scale</mat-checkbox>
      <div *ngIf="sliderOptions" class="chart-slider" matTooltip="Indicates the area currently shown in the chart">
        <ng5-slider
          [(value)]="minValue" [(highValue)]="maxValue"
          [manualRefresh]="manualRefresh"
          [options]="sliderOptions" (userChange)="sliderValueChange($event)">
        </ng5-slider>

      </div>

    </div>
  </form>
  <div class="canvas-wrapper">
    <canvas #canvas></canvas>
  </div>


  <span class="mat-tooltip legendTooltip" [ngClass]="{'legendHover' : legendHover}" #legendTooltip></span>
  <div class="chart-footer">
    <h6 matTooltip="*Single point in the graph represents {{chunkSize}} {{chunkSize === 1 ? 'point' : 'points'}}">Chunk Size: {{chunkSize}}</h6>
  </div>


</div>

<div *ngIf="!isDashboardChart" class="st-button-row">
  <app-delete-button *ngIf="saved" text="Delete" (delete)="deleteChart()" [disabled]="saving"></app-delete-button>
  <button color="primary" mat-button (click)="resetZoom()"> Reset Zoom</button>
  <button color="primary" mat-button mat-dialog-close>Close</button>
  <app-submit-button  text="DOWNLOAD CSV" (click)="downloadCSV()"></app-submit-button>
  <app-submit-button  text="DOWNLOAD PNG" (click)="downloadCanvas()"></app-submit-button>
  <app-submit-button text="save" [loading]="saving" (submit)="saveChart()" [disabled]="!form.valid || saving"></app-submit-button>
</div>
