import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'

@Component({
  selector: 'app-dataset-join-logo',
  templateUrl: './dataset-join-logo.component.html',
  styleUrls: ['./dataset-join-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatasetJoinLogoComponent implements OnInit {
  @Input() joinType?: Db.Helper.Geo.DatasetJoin
  logo?: string
  joinTooltip?: string

  ngOnInit () {
    switch (this.joinType) {
      case Db.Helper.Geo.DatasetJoin.INNER:
        this.logo = 'inner_join'
        this.joinTooltip = 'Inner Join - Returns records that have matching values in dataset above and selected dataset'
        break
      case Db.Helper.Geo.DatasetJoin.INNER_LEFT:
        this.logo = 'inner_left_join'
        this.joinTooltip = 'Inner Left Join - Returns all records from dataset above, where there is no match in records of selected dataset'
        break
      case Db.Helper.Geo.DatasetJoin.INNER_RIGHT:
        this.logo = 'inner_right_join'
        this.joinTooltip = 'Inner Right Join - Returns all records from selected dataset, where there is no match in records of dataset above'
        break
      case Db.Helper.Geo.DatasetJoin.OUTER:
        this.logo = 'outer_join'
        this.joinTooltip = 'Outer Join - Returns all records from dataset above and selected dataset'
        break
      case Db.Helper.Geo.DatasetJoin.OUTER_LEFT:
        this.logo = 'outer_inner_left_join'
        this.joinTooltip = 'Outer Left Join - Returns all records from dataset above, and the matched records of selected dataset'
        break
      case Db.Helper.Geo.DatasetJoin.OUTER_RIGHT:
        this.logo = 'outer_inner_right_join'
        this.joinTooltip = 'Outer Right Join - Returns all records from selected dataset, and the matched records of dataset above'
        break
    }
  }
}
