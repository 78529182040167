<h2 mat-dialog-title class="st-single-row-form">Filter workspaces</h2>

<div mat-dialog-content id="#workspace-filters">
  <form [formGroup]="form" class="st-single-row-form">
    <mat-form-field class="st-mat-field-clear" *ngIf="sysMaintainer">
      <mat-select disableOptionCentering formControlName="customers" multiple placeholder="Customers">
        <mat-option *ngFor="let customer of customers" [value]="customer.customer_id">
          {{customer.customer_name}}
        </mat-option>
      </mat-select>

      <mat-icon *ngIf="form.value.customers?.length" (click)="$event.stopPropagation(); form.controls['customers'].setValue([])">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="st-mat-field-clear" *ngIf="sysMaintainer || custAdmin">
      <mat-select disableOptionCentering formControlName="users" multiple placeholder="Users">
        <ng-container *ngFor="let user of users">
          <mat-option [value]="user.user_id" *ngIf="!form.value.customers?.length || form.value.customers?.includes(user.customer_id)">
            {{user.user_forename}} {{user.user_surname}}
          </mat-option>
        </ng-container>

      </mat-select>

      <mat-icon *ngIf="form.value.users?.length" (click)="$event.stopPropagation(); form.controls['users'].setValue([])">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="st-mat-field-clear" *ngIf="products.length">
      <mat-select disableOptionCentering formControlName="products" multiple placeholder="Products">
        <mat-option *ngFor="let prod of products" [value]="prod.product_id">
          {{prod.product_name}}
        </mat-option>
      </mat-select>

      <mat-icon *ngIf="form.value.products?.length" (click)="$event.stopPropagation(); form.controls['products'].setValue([])">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="st-mat-field-clear">
      <div class="time-picker-row" [owlDateTimeTrigger]="dta">
        <input matInput [owlDateTime]="dta" placeholder="Created after" formControlName="createdAfter" [min]="startAt"
          [max]="form.value.createdBefore || endAt">
        <mat-icon [owlDateTimeTrigger]="dta">access_time</mat-icon>
      </div>
      <owl-date-time #dta pickerType="calendar"></owl-date-time>
      <mat-icon *ngIf="form.value.createdAfter" (click)="$event.stopPropagation(); form.controls['createdAfter'].setValue('')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="st-mat-field-clear">
      <div class="time-picker-row" [owlDateTimeTrigger]="dtb">
        <input matInput [owlDateTime]="dtb" placeholder="Created before" formControlName="createdBefore" [min]="form.value.createdAfter || startAt"
          [max]="endAt">
        <mat-icon [owlDateTimeTrigger]="dtb">access_time</mat-icon>
      </div>
      <owl-date-time #dtb pickerType="calendar"></owl-date-time>

      <mat-icon *ngIf="form.value.createdBefore" (click)="$event.stopPropagation(); form.controls['createdBefore'].setValue('')">clear</mat-icon>
    </mat-form-field>



    <mat-form-field class="st-mat-field-clear">
      <mat-select disableOptionCentering formControlName="has_dashboard" multiple placeholder="has_dashboard">
        <mat-option>
          Dashboards Only
        </mat-option>
        
      </mat-select>

      <mat-icon *ngIf="form.value.products?.length" (click)="$event.stopPropagation(); form.controls['products'].setValue([])">clear</mat-icon>
    </mat-form-field>

    <mat-button-toggle-group name="Visibility" formControlName="visibility" color="primary" class="visibility-filter">
      <mat-button-toggle value="all">All</mat-button-toggle>
      <mat-button-toggle value="visible">Visible</mat-button-toggle>
      <mat-button-toggle value="hidden">Hidden</mat-button-toggle>
    </mat-button-toggle-group>
  </form>
</div>

<div class="st-button-row">
  <button color="primary" type="button" mat-button [mat-dialog-close] [disabled]="saving">Close</button>
  <app-submit-button
    (submit)="submit()"
    [loading]="saving"
    [disabled]="form?.invalid || saving"
    [text]="'Apply'"
  ></app-submit-button>
</div>

