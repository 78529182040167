import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import {
  IRouteDestination,
  IRouteResult,
  IRouteBreakdownRoute,
  IRoute,
  IRouteLayerBreakdown,
  IExposureInfo
} from '@core/types/'
import * as moment from 'moment'
import { RouteRowType, RouteMeansOfTransportType } from '@core/enum/air'

import { IAddress, ICoordinates } from '@core/types'

import Style from 'ol/style/Style'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { ColourUtil, CommonUtil, ShapesUtil } from '@core/utils/index'
import { AirRouteFinder } from '@core/models/air-route-finder'
import { AirRouteCalculateService } from '../route-calculate/air.route.calculate.service'
import { AirLayersService } from '../layers/air.layers.service'
import { RoutingMiddlewareService } from '../routing-middleware/routing-middleware.service'
import { AlertService } from '@services/core'
import AppError from '@core/models/app-error'
import LayerStyleUhlUtil from '@core/models/layer/utils/layer-style-uhl.utils'
import { WorkspaceMapService } from '@services/workspace/workspace-map/workspace-map.service'

@Injectable({
  providedIn: 'root'
})
export class AirRouteFinderService {
  private _airRouteFinder?: AirRouteFinder

  private _routeList: IRouteDestination[] = []
  private _activeRoutes = new BehaviorSubject<IRouteResult[]>([])
  private _balanceObservable = new BehaviorSubject<number>(0)
  // TEMP: Set date to know date with available routing, as it's been a while since last usable date.
  // We no longer have data for latest dates in order to provide routes for that
  private _timeObservable = new BehaviorSubject<Date>(moment().year(2018).month(9).date(10).toDate())
  private _numberOfOptions = 6
  private _subscriptions = new Subscription()
  private _navLayer = new VectorLayer({
    source: new VectorSource()
  })

  private _layers: VectorLayer<any>[] = []

  private _userBalance = 0
  get activeRoutes (): Observable<IRouteResult[]> {
    return this._activeRoutes.asObservable()
  }

  get routeList (): IRouteDestination[] {
    return this._routeList
  }

  get fullRouteInformation (): AirRouteFinder {
    if (!this._airRouteFinder) throw new AppError(`Route information is undefined.`)
    return this._airRouteFinder
  }

  get meansOfTransport (): RouteMeansOfTransportType {
    return this.fullRouteInformation.meansOfTransport
  }
  set meansOfTransport (value: RouteMeansOfTransportType) {
    this.fullRouteInformation.meansOfTransport = value
  }

  get balance (): number {
    return this.fullRouteInformation.balance
  }

  get balanceObservable (): Observable<number> {
    return this._balanceObservable.asObservable()
  }

  set balance (value: number) {
    this.fullRouteInformation.balance = value
  }

  get calculating (): boolean {
    return this.fullRouteInformation.calculating
  }

  get startDateTime (): Date {
    return this.fullRouteInformation.dateTime
  }
  set startDateTime (value: Date) {
    this.fullRouteInformation.dateTime = value
  }

  get timeObservable () {
    return this._timeObservable.asObservable()
  }

  get numberOfOptions () {
    return this._numberOfOptions
  }

  constructor (
    private _airCalculateService: AirRouteCalculateService,
    private _workspaceMapService: WorkspaceMapService,
    private _airLayerService: AirLayersService,
    private _routingMiddlewareService: RoutingMiddlewareService,
    private _alertService: AlertService
  ) {}

  initService () {
    this._workspaceMapService.addMapLayer(this._navLayer)
    this._airRouteFinder = new AirRouteFinder()

    this.SetupDefaultWaypoints()

    this.drawPoints()

    this._subscriptions.add(
      this._airCalculateService.tryAgain.subscribe(() => {
        this.recalculate()
      })
    )
  }

  cleanupService () {
    this._workspaceMapService.removeMapLayer(this._navLayer)
    this._navLayer.setSource(new VectorSource())
    this._routeList.splice(0)

    this._subscriptions.unsubscribe()
    this._subscriptions = new Subscription()

    this._activeRoutes.next([])
    for (const layer of this._layers) {
      this._workspaceMapService.removeMapLayer(layer)
    }
    this._layers.splice(0)
    this._numberOfOptions = 6
    this._userBalance = 0
  }

  updateActiveRoutes (newRoutes: IRouteResult[]) {
    this._activeRoutes.next(newRoutes)
  }

  async calculateRoute (): Promise<VectorLayer<any>[]> {
    if (!this._airRouteFinder) throw new AppError(`Route information is undefined.`)
    let layers: VectorLayer<any>[] = []

    this.CopyWaypointsToFoundFinder()
    if (this.waypointsValid(this._airRouteFinder.waypoints)) {
      this._airRouteFinder.calculating = true

      this._airCalculateService.fullRouteInformation = this.fullRouteInformation

      switch (this._airRouteFinder.meansOfTransport) {
        case RouteMeansOfTransportType.Bike:
          layers = [await this._airCalculateService.showOnlyCycling()]
          break

        case RouteMeansOfTransportType.Car:
          layers = [await this._airCalculateService.showOnlyCar()]
          break

        case RouteMeansOfTransportType.Walking:
          layers = [await this._airCalculateService.showOnlyWalking()]
          break

        default:
          layers = await this._airCalculateService.showAll()
          break
      }

      this._airRouteFinder.calculating = false
    }

    return layers
  }

  insertWaypoint (index: number) {
    if (index < this.routeList.length || index === 0) {
      const lastDestination: IRouteDestination = this.routeList[index]

      const newWayPoint: IRouteDestination = {
        rowType: RouteRowType.Waypoint,
        placeholder: 'Waypoint',
        text: lastDestination.text,
        coords: lastDestination.coords
      }

      this._routeList.splice(index + 1, 0, newWayPoint)
      this.drawPoints()
    }
  }

  editWayPoint (index: number, destination: google.maps.places.AutocompletePrediction, coords: ICoordinates) {
    this._routeList[index].text = destination.description
    this._routeList[index].coords = coords
    this.drawPoints()
  }

  deleteWaypoint (index: number) {
    if (index > -1) {
      this.routeList.splice(index, 1)

      if (index === 0) {
        const firstRoute = this.routeList[0]
        firstRoute.rowType = RouteRowType.Start
        firstRoute.placeholder = 'Starting Point'
      } else if (index === this.routeList.length) {
        const lastRoute = this.routeList[this.routeList.length - 1]
        lastRoute.rowType = RouteRowType.End
        lastRoute.placeholder = 'Destination'
      }

      this.drawPoints()
    }
  }

  clearWaypoints () {
    this.SetupDefaultWaypoints()
    this.drawPoints()
  }

  setOrigin (address: IAddress) {
    this._routeList[0].text = address.name
    this._routeList[0].coords = address.coords
    this.drawPoints()
  }

  setWaypoint (address: IAddress) {
    const lastDestination = this._routeList.pop()

    const newWayPoint: IRouteDestination = this.NewRouteDestination({
      rowType: RouteRowType.Waypoint,
      text: address.name,
      placeholder: 'Waypoint',
      coords: address.coords
    })

    this._routeList.push(newWayPoint)

    if (lastDestination) {
      this._routeList.push(lastDestination)
    }

    this.drawPoints()
  }

  setDestination (address: IAddress) {
    this._routeList[this._routeList.length - 1].text = address.name
    this._routeList[this._routeList.length - 1].coords = address.coords
    this.drawPoints()
  }

  changeType (location: IRouteDestination, changeTo: RouteRowType) {
    let foundIndex: number | undefined
    const toChange = this._routeList.find((eachLocation, index) => {
      foundIndex = index
      return eachLocation.text === location.text
    })

    if (toChange && foundIndex !== undefined && toChange.rowType !== changeTo) {
      toChange.rowType = changeTo

      const changeToStart = changeTo === RouteRowType.Start
      const changeToEnd = changeTo === RouteRowType.End
      if (changeToStart || changeToEnd) {
        const insertBack = this.routeList.splice(foundIndex, 1)
        if (changeToStart) {
          this._routeList[0].rowType = RouteRowType.Waypoint
          this._routeList.unshift(insertBack[0])
        } else if (changeToEnd) {
          this._routeList[this._routeList.length - 1].rowType = RouteRowType.Waypoint
          this._routeList.push(insertBack[0])
        }
      }
      this._routeList[0].rowType = RouteRowType.Start
      this._routeList[this._routeList.length - 1].rowType = RouteRowType.End
      this.drawPoints()
    }
  }

  reorderLocations () {
    this._routeList.forEach(location => (location.rowType = RouteRowType.Waypoint))
    this._routeList[0].rowType = RouteRowType.Start
    this._routeList[0].placeholder = 'Starting Point'
    this._routeList[this._routeList.length - 1].rowType = RouteRowType.End
    this._routeList[this._routeList.length - 1].placeholder = 'Destination'
    this.drawPoints()
  }

  drawPoints () {
    const navLayerSource = this._navLayer.getSource()
    if(!navLayerSource) return
    navLayerSource
      .getFeatures()
      .forEach(feature => {
        navLayerSource.removeFeature(feature)
      })

    this._routeList.forEach((location, index) => {
      if (this.waypointValid(location.coords)) {
        const toAdd = ShapesUtil.newPoint({
          lng: location.coords.lon,
          lat: location.coords.lat
        })

        const iconStyle: Style | undefined = LayerStyleUhlUtil.getIconStyle(location.rowType, index + 1)

        if (iconStyle) {
          toAdd.setStyle(iconStyle)
          toAdd.setProperties({
            type: 'route-point',
            referencedLocation: location
          })
        }

        navLayerSource.addFeature(toAdd)
      }
    })
  }

  resetRoute () {
    if (this._airRouteFinder) this._airRouteFinder.id = 0
  }

  async recalculate (route?: IRoute) {
    this.removeLastRoutes()

    if (route) {
      this._timeObservable.next(route.routeResult.time)
      this.meansOfTransport = route.icon as RouteMeansOfTransportType
      this.balance = route.routeResult.balance
      this._routeList = route.routeResult.waypoints
      this.startDateTime = route.routeResult.time
      const radios = document.getElementsByClassName('air-transport-radio')
      ; (Array.from(radios).filter(radio => radio.innerHTML.includes(route.icon))[0] as HTMLElement).click()
      this._balanceObservable.next(this.balance)
    }

    await this.CalculateAndRender()

    return
  }

  private async CalculateAndRender () {
    this._userBalance = this.fullRouteInformation.balance

    this.resetRoute()
    this.drawPoints()

    this._airLayerService.loadPollutionLayerFor(this.fullRouteInformation.dateTime)
    await this._routingMiddlewareService.startServers(moment(this.fullRouteInformation.dateTime))
    if (!this._routingMiddlewareService.serversRunning) {
      this._alertService.log('Failed to recalculate route. Cannot start routing engine for selected date.')
    }
    let routes: IRouteResult[] = []

    const meansOfTransport: RouteMeansOfTransportType = this.fullRouteInformation.meansOfTransport

    if (meansOfTransport === RouteMeansOfTransportType.All) {
      routes = await this.GetRouteForAllMeansOfTransport()
    } else {
      routes = await this.GetRouteForMeansOfTransport(meansOfTransport)
    }
    this.updateActiveRoutes(routes)

    this.fullRouteInformation.balance = this._userBalance
  }

  addToMap (routeLayers: VectorLayer<any>[], removeLastRoutes: boolean = true) {
    if (removeLastRoutes) {
      this.removeLastRoutes()
    }

    routeLayers.forEach((layer: VectorLayer<any>) => {
      layer.setProperties({
        isActiveRoute: true
      })
      this._workspaceMapService.addMapLayer(layer)
      this._layers.push(layer)
    })
  }

  removeLastRoutes () {
    this._layers
      .filter(l => l.getProperties().isActiveRoute)
      .forEach(layer => this._workspaceMapService.removeMapLayer(layer))
    this._activeRoutes.next([])
  }

  async getExposure (route: IRouteResult): Promise<IExposureInfo> {
    const coords: [number, number][] = this.routeList.map(x => [x.coords.lon, x.coords.lat] as [number, number])
    const exposure = await this._routingMiddlewareService.getExposure(coords, route.port as number)
    if (!exposure) {
      throw new AppError('Failed to get exposure.')
    }
    return exposure
  }

  waypointsValid (waypoints: ICoordinates[]): boolean {
    let valid = true

    waypoints.forEach((point: ICoordinates) => {
      valid = this.waypointValid(point)

      if (!valid) {
        return valid
      }
    })

    return valid
  }

  waypointValid (point: ICoordinates): boolean {
    const valid = point.lat !== 0 && point.lon !== 0

    return valid
  }

  private CopyWaypointsToFoundFinder () {
    this._airCalculateService.waypoints = []

    this._routeList.forEach(temp => {
      this._airCalculateService.waypoints.push(temp.coords)
    })

    this.fullRouteInformation.waypoints = this._airCalculateService.waypoints
  }

  private NewRouteDestination (destination: IRouteDestination): IRouteDestination {
    const newWayPoint: IRouteDestination = {
      rowType: RouteRowType.Waypoint,
      text: destination.text,
      placeholder: destination.placeholder,
      coords: destination.coords
    }

    return newWayPoint
  }

  private async GetBreakdown (balance: number, index?: number): Promise<IRouteLayerBreakdown | undefined> {
    this.balance = balance
    const layer: VectorLayer<any>[] = await this.calculateRoute()

    if (typeof index === 'number') {
      const style: Style = layer[0]
        .getSource()
        .getFeatures()[0]
        .getStyle() as Style

      if (style) {
        const newColor = ColourUtil.getAsNumberArray(index)
        style.getStroke().setColor(newColor)
        layer[0].setProperties({
          iconColour: style.getStroke().getColor()
        })
      }
    }

    this.addToMap(layer, false)

    this.balance = this._userBalance

    return {
      layer,
      breakdown: layer[0].getProperties().routeBreakdownDisplay
    }
  }

  private async ConvertToRouteResult (
    route: IRouteBreakdownRoute,
    title: string,
    meansOfTransport: string,
    port: number
  ): Promise<IRouteResult> {
    if (!this._airRouteFinder) throw new AppError(`Route information is undefined.`)
    const result: IRouteResult = {
      id: 0,
      title: title,
      time: this._airRouteFinder.dateTime,
      duration: CommonUtil.durationTimeFormat(route.duration),
      value: -1,
      distance: route.distance,
      icon: meansOfTransport,
      balance: this.balance,
      visible: true,
      waypoints: this.routeList,
      port
    }

    return result
  }

  private async GetRouteForAllMeansOfTransport (): Promise<IRouteResult[]> {
    const currentTransport: RouteMeansOfTransportType = this.meansOfTransport

    this.removeLastRoutes()

    const routes: IRouteResult[] = []
    let route: IRouteResult | undefined

    this.meansOfTransport = RouteMeansOfTransportType.Walking
    route = await this.GetRoute(RouteMeansOfTransportType.Walking, 'Walk')
    if (route) {
      routes.push(route)
    }

    this.meansOfTransport = RouteMeansOfTransportType.Bike
    route = await this.GetRoute(RouteMeansOfTransportType.Bike, 'Bike')
    if (route) {
      routes.push(route)
    }

    this.meansOfTransport = RouteMeansOfTransportType.Car
    route = await this.GetRoute(RouteMeansOfTransportType.Car, 'Car')
    if (route) {
      routes.push(route)
    }

    this.meansOfTransport = currentTransport

    return routes
  }
  private async GetRouteForMeansOfTransport (meansOfTransport: RouteMeansOfTransportType): Promise<IRouteResult[]> {
    let promises: Promise<any>[] = []
    const breakdownsAsync: {
      result: IRouteLayerBreakdown
      i: number
    }[] = []

    for (let i = 0; i < this._numberOfOptions; i += 1) {
      promises.push(
        this.GetBreakdown(i, i).then(result => {
          if (result) {
            breakdownsAsync.push({
              result,
              i
            })
          }
        })
      )
    }

    await Promise.all(promises)
    const breakdowns: IRouteLayerBreakdown[] = breakdownsAsync.sort((a, b) => a.i - b.i).map(x => x.result)

    promises = []
    const results: IRouteResult[] = []

    breakdowns.forEach((breakdown, index) =>
      promises.push(
        this.ConvertToRouteResult(
          breakdown.breakdown,
          index === 0 ? 'Fastest' : `Alternative`,
          meansOfTransport,
          breakdown.layer[0].getProperties().port
        ).then(result => results.push(result))
      )
    )

    await Promise.all(promises)

    const checkDuplicates: any = {}

    return results
      .map((result, index) => {
        const breakdown = breakdowns[index]
        const olVector = breakdown.layer[0]
        olVector.setProperties({
          meansOfTransport: meansOfTransport,
          weight: breakdown.breakdown.weight,
          distance: breakdown.breakdown.distance
        })

        return {
          ...result,
          olVector
        }
      })
      .filter(eachResult => {
        // filter out same distance and duration as they're most likely to be duplicates
        const pseudoUID = `${eachResult.duration} - ${eachResult.distance}`
        if (!checkDuplicates[pseudoUID]) {
          checkDuplicates[pseudoUID] = true
          return true
        } else {
          eachResult.olVector.setVisible(false)
          return false
        }
      })
  }

  private async GetRoute (
    meansOfTransport: RouteMeansOfTransportType,
    textToShow: string
  ): Promise<IRouteResult | undefined> {
    this.meansOfTransport = meansOfTransport

    const layer: VectorLayer<any>[] = await this.calculateRoute()

    if (layer.length) {
      this.addToMap(layer, false)

      const result = await this.ConvertToRouteResult(
        layer[0].getProperties().routeBreakdownDisplay,
        textToShow,
        meansOfTransport,
        layer[0].getProperties().port
      )

      return {
        ...result,
        olVector: layer[0]
      }
    }
  }

  private SetupDefaultWaypoints () {
    this._routeList = [
      { rowType: RouteRowType.Start, placeholder: 'Starting Point', text: '', coords: { lon: 0, lat: 0 } },
      { rowType: RouteRowType.End, placeholder: 'Destination', text: '', coords: { lon: 0, lat: 0 } }
    ]
  }
}
