<div class="st-customer-background">
    <div class="auth-card-wrapper">
        <mat-card class="mat-elevation-z5">
            <app-powered-by>
                <app-logo logoSelector="auth_page"></app-logo>
            </app-powered-by>

            <mat-card-content>
              <div *ngIf="!submitted; else confirm">

                <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>
                <p>Provide us your email and we will send you a password reset link.</p>

                <form [formGroup]="form">
                    <mat-form-field>
                        <input matInput type="email" placeholder="Email" formControlName="email">
                    </mat-form-field>
                </form>

                <div class="st-button-row">
                    <app-submit-button text="Send" [disabled]="!form.valid" [loading]="saving"
                    (submit)="submit()">
                    </app-submit-button>
                </div>
              </div>

              <ng-template #confirm>
                <h3>Thank you</h3>
                <p>You should receive an email soon with a password reset link.</p>
              </ng-template>

            </mat-card-content>

            <mat-card-footer class="st-wide">
                <ul>
                    <li>
                      <a routerLink="/login">Back</a>
                    </li>
                </ul>
            </mat-card-footer>

        </mat-card>

        <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
      </div>

</div>
