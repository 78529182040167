import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { FormTemplate } from '@core/models/form-template'
import { VipApiService, AuthService } from '@services/core'
import { WorkspacesService } from '@services/explorer'
import { IRCustomerUser } from 'vip-shared/dist/interfaces'
import moment from 'moment'
import { Subscription } from 'rxjs'
import { API } from 'vip-shared/dist/interfaces/api-helper'
import { debounceTime } from 'rxjs/operators'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'


@Component({
  selector: 'app-workspace-filter-dialog',
  templateUrl: './workspace-filter-dialog.component.html',
  styleUrls: ['./workspace-filter-dialog.component.scss']
})
export class WorkspaceFilterDialogComponent extends FormTemplate<UntypedFormGroup> implements AfterViewInit, OnDestroy {
  private _subscriptions = new Subscription()
  errorMessage?: string
  title = ''
  customers: Db.Vip.Cst.ICustomer[] = []
  users: IRCustomerUser[] = []
  products: Db.Vip.Gsi.IProduct[] = []

  startAt = moment('2017', 'YYYY')
  endAt = moment()

  get isLoading (): boolean {
    return this._workspacesService.loading
  }

  get sysMaintainer () {
    return this._authService.isSysMaintainer
  }

  get custAdmin (): boolean {
    return this._authService.permissionsLevel <= Db.Vip.Role.CUST_ADMIN_BASIC
  }

  get userCustomerIds (): string[] {
    return (
      this.form.value.users &&
      this.form.value.users.map(x =>
        (this.users.find(u => u.user_id === x) as IRCustomerUser).customer_id
      )
    ) || []
  }

  get filters (): API.Qry.WorkspaceFilters {
    const filters: API.Qry.WorkspaceFilters = {}
    if (this.form.value.customers) filters.customer = this.form.value.customers
    if (this.form.value.users) filters.user = this.form.value.users
    if (this.form.value.products) filters.product = this.form.value.products
    
    if (this.form.value.visibility) {
      filters.visible = this.form.value.visibility === 'visible' ? true :
        this.form.value.visibility === 'hidden' ? false : undefined
    }
    if (this.form.value.createdAfter) filters.created_after = moment(this.form.value.createdAfter).toISOString()
    if (this.form.value.createdBefore) filters.created_before = moment(this.form.value.createdBefore).toISOString()
    if (this.form.value.has_dashboard === undefined){
      filters.has_dashboard = false}
      else{
        filters.has_dashboard = true 
      } 
    return filters
  }

  constructor (
        protected _dialogRef: MatDialogRef < WorkspaceFilterDialogComponent >,
        private _api: VipApiService,
        private _workspacesService: WorkspacesService,
        private _authService: AuthService
    ) {
    super(new UntypedFormGroup({
      customers: new UntypedFormControl([]),
      users: new UntypedFormControl([]),
      products: new UntypedFormControl([]),
      createdAfter: new UntypedFormControl(),
      createdBefore: new UntypedFormControl(),
      has_dashboard: new UntypedFormControl(false),
      visibility: new UntypedFormControl('visible')
    }))

    this._subscriptions.add(
      this._workspacesService.updated.subscribe(() => {
        if (!this._workspacesService.lastFilter || !Object.keys(this._workspacesService.lastFilter).length) {
          this.form.reset()
          this.form.controls.visibility.setValue('visible')
        }
      })
    )
  }

  ngAfterViewInit () {
    // Timeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    // TODO: Remove when better loading logic in place
    setTimeout(async () => {
      await this.GetWorkspacesInfo()
    }, 100)
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  private async GetWorkspacesInfo () {
    this.form.controls.customers.setValue(this._workspacesService.lastFilter?.customer)
    this.form.controls.users.setValue(this._workspacesService.lastFilter?.user)
    this.form.controls.products.setValue(this._workspacesService.lastFilter?.product)
    this.form.controls.has_dashboard.setValue(this._workspacesService.lastFilter?.has_dashboard)
    this.form.controls.createdAfter.setValue(this._workspacesService.lastFilter?.created_after)
    this.form.controls.createdBefore.setValue(this._workspacesService.lastFilter?.created_before)
    const products = await this._api.orm.Products().get().run()
    if (products.length > 1) {
      this.products = products
    }

    if (this.sysMaintainer) {
      this.customers = await this._api.orm.Customers().get().run()
    }

    if (this.sysMaintainer || this.custAdmin) {
      this.users = await this._api.orm.Customers().Users().get().run()
    }
  }

  async submit () {
    this._workspacesService.loadWorkspaces(this.filters)
  }
}
