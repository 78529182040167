import { Component, Input, HostBinding } from '@angular/core'
import { ThemeService } from '@services/core/theme/theme.service'

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent {
  private _color?: string

  @HostBinding('class') class = 'mat-elevation-z5'

  @HostBinding('style.color')
  @Input() get color () {
    if (!this._color) this._color = this._themeService.getColor('foreground')
    return this._color
  }

  set color (val: string) {
    if (val === 'warn') {
      this._color = this._themeService.getColor('warn')
    } else if (val === 'error') {
      this._color = this._themeService.getColor('error')
    } else {
      this._color = this._themeService.getColor('foreground')
    }
  }

  constructor (
    private _themeService: ThemeService
  ) {}

}
