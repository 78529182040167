<mat-checkbox [(ngModel)]="updateExisting"
  matTooltip="Please use with care - if checked, this will update and replace existing geometry related data.\nIf not checked, error will be thrown for duplicate data.">
  Update existing entries</mat-checkbox>

<mat-list>
    <app-note color="warn" *ngIf="updateExisting">
        Be careful, this will update and replace matching geometry data in database permanently. This will affect geometries and depending on specified
        columns in file: geometry to project link, annotations and annotation QA.
      </app-note>

  <mat-list-item>
    <app-file-drag-drop type="content" contentClass="pv-item-container" (onFilesDropped)="uploadFiles('project-tag', $event)" [accept]="accept.csv">
      <div class="item-left">
        <mat-icon (click)="showTooltip($event, infoOnProjectTag)">help_outline</mat-icon>
        <span class='item-name'>
          Project tag (csv)
        </span>
      </div>
      <div class="item-actions">
        <button mat-icon-button color="primary" (click)="openTableManager($event, 'project-tag')">
          <mat-icon>table_chart</mat-icon>
        </button>
        <button mat-icon-button color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>
    </app-file-drag-drop>
  </mat-list-item>

  <mat-list-item>
    <app-file-drag-drop type="content" contentClass="pv-item-container" (onFilesDropped)="uploadFiles('guide', $event)" [accept]="accept.csv">
      <div class="item-left">
        <mat-icon (click)="showTooltip($event, infoOnGuide)">
          help_outline</mat-icon>
        <span class='item-name'>
          Guide (csv)
        </span>
      </div>
      <div class="item-actions">
        <!-- Need to first add option to delete guide sets in here -->
        <!-- <button mat-icon-button color="primary" (click)="openTableManager($event, 'guide')">
          <mat-icon>table_chart</mat-icon>
        </button> -->
        <button mat-icon-button color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>
    </app-file-drag-drop>
  </mat-list-item>

  <mat-list-item>
    <app-file-drag-drop type="content" contentClass="pv-item-container" (onFilesDropped)="uploadGeometry($event)" [multiple]="true" [accept]="accept.geometry">
      <div class="item-left">
        <mat-icon (click)="showTooltip($event, infoOnGeometry)">help_outline</mat-icon>
        <span class='item-name'>
          Vector File (shp, geojson)
        </span>
      </div>

      <div class="item-actions">
        <button mat-icon-button color="primary" [class.warning]="pendingCount" *ngIf="uploadsCount" (click)="listUploadedFiles($event)">
          <mat-icon>list</mat-icon>
          <span class="pending-count" *ngIf="pendingCount"
            matTooltip="{{pendingCount}} missing file{{pendingCount > 1 ? 's' : ''}}">{{pendingCount}}</span>
        </button>
        <button mat-icon-button color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>

    </app-file-drag-drop>
  </mat-list-item>

  <mat-list-item>
    <app-file-drag-drop type="content" contentClass="pv-item-container" (onFilesDropped)="uploadFiles('roof-type', $event)" [accept]="accept.csv">
      <div class="item-left">
        <mat-icon (click)="showTooltip($event, infoOnRoofTypes)">help_outline</mat-icon>
        <span class='item-name'>
          Roof type (csv)
        </span>
      </div>

      <div class="item-actions">
        <button mat-icon-button color="primary" (click)="openTableManager($event, 'roof-type')">
          <mat-icon>table_chart</mat-icon>
        </button>
        <button mat-icon-button color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>
    </app-file-drag-drop>
  </mat-list-item>

  <mat-list-item>
    <app-file-drag-drop type="content" contentClass="pv-item-container" (onFilesDropped)="uploadFiles('roof-material', $event)" [accept]="accept.csv">
      <div class="item-left">
        <mat-icon (click)="showTooltip($event, infoOnRoofMaterial)">help_outline</mat-icon>

        <span class='item-name'>
          Roof material (csv)
        </span>
      </div>
      <div class="item-actions">
        <button mat-icon-button color="primary" (click)="openTableManager($event, 'roof-material')">
          <mat-icon>table_chart</mat-icon>
        </button>
        <button mat-icon-button color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>
    </app-file-drag-drop>
  </mat-list-item>

  <mat-list-item>
    <app-file-drag-drop type="content" contentClass="pv-item-container" (onFilesDropped)="uploadFiles('constr-class', $event)" [accept]="accept.csv">
      <div class="item-left">
        <mat-icon (click)="showTooltip($event, infoOnConstrClass)">help_outline</mat-icon>

        <span class='item-name'>
          Construction class (csv)
        </span>
      </div>
      <div class="item-actions">
        <button mat-icon-button color="primary" (click)="openTableManager($event, 'constr-class')">
          <mat-icon>table_chart</mat-icon>
        </button>
        <button mat-icon-button color="primary">
          <mat-icon>note_add</mat-icon>
        </button>
      </div>
    </app-file-drag-drop>
  </mat-list-item>

  <mat-list-item class="no-action">
    <div class="pv-item-container">
      <div class="item-left">
        <span class='item-name'>
          Machine Learning Datasets
        </span>
      </div>

      <div class="item-actions">
        <button mat-icon-button color="primary" (click)="openTableManager($event, 'ml-dataset')">
          <mat-icon>table_chart</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>

</mat-list>
<app-loader-backdrop *ngIf="parsing || uploads.length" [loadingText]="uploads.length ? uploadText : 'Reading files...'"></app-loader-backdrop>
