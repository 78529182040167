import { Component, Inject, Input, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'

import {
  StatisticsQuery,
  IQueryWRef
} from '@core/models/query-object'

import { AppLayer, AppLayerGroup } from '@core/models/layer'
import AppError, { handleError } from '@core/models/app-error'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { FormTemplate } from '@core/models/form-template'
import { IRVectorColumn } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { CommonUtil } from '@core/utils'
import { LayerService } from '@services/workspace'


interface DialogData {
  query?: IQueryWRef | null
  target: AppLayer
  column?: string
  columnName?: string
}

@Component({
  selector: 'app-statistic-query-builder-dialog',
  templateUrl: './statistic-query-builder-dialog.component.html',
  styleUrls: ['./statistic-query-builder-dialog.component.scss']
})
export class StatisticQueryBuilderDialogComponent extends FormTemplate<UntypedFormGroup> implements OnInit {
  columns: IRVectorColumn[] = []
  operators = Object.values(Db.Helper.Prj.Aggregation)
  queryTarget?: AppLayer | null
  queryObj?: IQueryWRef | null
  queryName: string = ''

  @Input() layerId
  @Input() isDashboardWidget: boolean = false

  get layers () {
    return this._layerQueriesService.queryableLayers
  }

  static setOptions (data: DialogData): MatDialogConfig {
    const options: MatDialogConfig = {
      width: '598px',
      hasBackdrop: true,
      position: {
        top: '72px'
      },
      autoFocus: false,
      data
    }
    return options
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public dataRef: DialogData,
    protected _dialogRef: MatDialogRef<StatisticQueryBuilderDialogComponent, any>,
    private _layerQueriesService: LayerQueriesService,
    private _layerService: LayerService
  ) {
    super(
      new UntypedFormGroup({
        name: new UntypedFormControl( dataRef ? dataRef.columnName || dataRef.column || '' : '', Validators.required),
        layer: new UntypedFormControl({ value:dataRef ? dataRef.target : '', disabled: true }, Validators.required),
        column: new UntypedFormControl( dataRef ? dataRef.column || '' : '' , Validators.required),
        operator: new UntypedFormControl('', Validators.required)
      }), _dialogRef
    )
  }

  async ngOnInit () {
    if (this.dataRef) {
      if (this.dataRef.target) {
        this.queryTarget = this.dataRef.target
        this.queryObj = this.dataRef.query ? this.dataRef.query : this.NewEmptyQuery('', this.dataRef.target)
      }

      if (this.dataRef.query && this.dataRef.query.query.statisticQuery) {
        const statQuery = this.dataRef.query.query.statisticQuery
        this.queryName = this.dataRef.query.name
        this.form.controls['name'].setValue(this.dataRef.query.name)
        this.form.controls['column'].setValue(statQuery.attribute)
        this.form.controls['operator'].setValue(statQuery.operator)
      }
    }

    if (this.layerId && this.isDashboardWidget) {
      this.form.controls.name.setValue(`DashBoard Query - ${CommonUtil.getUuid()}`)
      this.queryTarget = await this.setLayerTarget()
    }


    if (this.queryTarget) this.generateFilters(this.queryTarget)
  }

  async generateFilters (layer: AppLayer) {
    if (!this.queryObj) {
      this.queryObj = this.NewEmptyQuery(this.queryName, layer)
    } else {
      this.queryObj.targetRef = layer
    }
    this.columns = layer.tableColumns.filter(column => ['integer', 'float'].includes(column.type))
    if (!this.columns || !this.columns.length) {
      throw new AppError('No columns available for this layer')
    }
    if (this.columns.findIndex(c => c.name === this.dataRef.column) === -1) {
      this.columns.push({
        name: this.dataRef.column || 'untitled',
        nullable: false,
        type: 'string'
      })
    }
  }

  getOptionName (target: AppLayerGroup | AppLayer) {
    return target instanceof AppLayerGroup ? `Time Series of '${target.title}'` : target.title
  }

  private NewEmptyQuery (name: string, layer: AppLayer): IQueryWRef {
    const newQuery: IQueryWRef = {
      type: 'statistic',
      applied: true,
      name,
      targetRef: layer,
      query: {
        blocks: [],
        statisticQuery: new StatisticsQuery('', ''),
        operator: ''
      },
      index: undefined as any,
      query_id: undefined as any,
      view_id: undefined as any,
      workspace_id: undefined as any,
      layer_id: layer.id
    }

    newQuery.index = this._layerQueriesService.queries.length
    return newQuery
  }

  async setLayerTarget() {
    return await this._layerService.getById(this.layerId)
  }

  async saveQuery () {
    if (this.saving) return
    this.errorMessage = undefined
    this.saving = true
    let queryId

    try {
      const controls = this.form.controls
      if (this.queryObj) {
        this.queryObj.name = controls.name.value
        this.queryObj.query.statisticQuery = new StatisticsQuery(controls.operator.value, controls.column.value)
        queryId = await this._layerQueriesService.toggleQuery(this.queryObj, true, true)

      }
      if (!this.isDashboardWidget) this._dialogRef.close()
      return queryId
    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    }

    this.saving = false
  }

}
