import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'
import { IRVectorColumn, IRVectorColumnType, IPNewVectorColumn } from '@vip-shared/interfaces'
import { UntypedFormControl, Validators } from '@angular/forms'
import { AlertService } from '@services/core'
import FormValidators from '@core/utils/form-validators/form-validators'

type IVectorColumnExtended = Partial<IRVectorColumn> & { canEdit: boolean, nameControl?: UntypedFormControl }
interface DialogData {
  columns: IRVectorColumn[]
}
@Component({
  selector: 'app-new-attribute-column-dialog',
  templateUrl: './new-attribute-column-dialog.component.html',
  styleUrls: ['./new-attribute-column-dialog.component.scss']
})
export class NewAttributeColumnDialogComponent implements OnInit {
  @ViewChild('columnList', { static: false }) columnListRef!: ElementRef<HTMLElement>
  columnMetadata: IVectorColumnExtended[] = []
  canCreate = false
  availableTypes: IRVectorColumnType[] = ['string', 'integer', 'float', 'boolean', 'date']
  saving = false

  get hasInvalidNames (): boolean {
    return this.columnMetadata.some(x => !!x.nameControl && !x.nameControl.valid && !!x.nameControl.value)
  }

  static setup (data: DialogData) {
    return {
      data
    } as MatDialogConfig
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _dialogRef: MatDialogRef<NewAttributeColumnDialogComponent>,
    private _alertService: AlertService
  ) { }

  ngOnInit () {
    this.columnMetadata = this.data.columns.map(x => Object.assign(x, { canEdit: false })) || []

    this.createColumn()
  }

  createColumn () {
    this.columnMetadata.push({
      canEdit: true,
      nullable: true,
      type: 'string',
      nameControl: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('[a-z0-9_]+'),
        FormValidators.notSystemColumn
      ])
    })
    setTimeout(() => {
      const last = this.columnListRef.nativeElement.lastChild as HTMLElement

      if (last) {
        last.scrollIntoView({ behavior: 'smooth' })
        const input = last.firstElementChild && last.firstElementChild.getElementsByTagName('input').item(0) as HTMLInputElement
        if (input) input.focus()
      }
    }, 10)

    this.checkForNewColumns()
  }

  checkForNewColumns () {
    this.canCreate = this.columnMetadata.some(this.ValidNewColumn) && this.columnMetadata.every(x => this.ValidColumn(x))
  }

  private ValidNewColumn (col: IVectorColumnExtended) {
    return !!(col.canEdit && col.nameControl && col.nameControl.valid && col.type)
  }

  private ValidColumn (col: IVectorColumnExtended) {
    return !col.canEdit || this.ValidNewColumn(col)
  }

  async createColumns () {
    if (this.saving || !this.canCreate) return
    try {
      this.saving = true
      const validColumns = this.columnMetadata.filter(this.ValidNewColumn)
      const newColumns: IPNewVectorColumn[] = validColumns.map(x => {
        const col: IPNewVectorColumn = {
          name: (x.nameControl as UntypedFormControl).value,
          type: x.type as IPNewVectorColumn['type'],
          nullable: !!x.nullable
        }
        if (x.default) col.default = x.default
        return col
      })

      this._dialogRef.close(newColumns)
    } catch (error: any) {
      this._alertService.log(error.message)
    } finally {
      this.saving = false
    }
  }
}
