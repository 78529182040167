import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ContextMenuDialogComponent } from '@components/context-menu-dialog/context-menu-dialog.component'
import { CtxUIMenu } from '@core/types/workspace/map/ws-ctx-menu-conf'
@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  private _dialogRef ?: MatDialogRef < ContextMenuDialogComponent >
  constructor (
        private _dialog: MatDialog
  ) {}

  openMenu (options: CtxUIMenu, click: {x: number, y: number}): MatDialogRef< ContextMenuDialogComponent > {
    this.closeMenu()
    this._dialogRef = this._dialog.open(ContextMenuDialogComponent, ContextMenuDialogComponent.setOptions(options, click))
    return this._dialogRef
  }

  closeMenu () {
    if (this._dialogRef) this._dialogRef.close()
  }
}
