import { Component, OnDestroy } from '@angular/core'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { LayerSequencesDatasetComponent } from './layer-sequences-dataset/layer-sequences-dataset.component'

@Component({
  selector: 'app-general-dataset-manager',
  templateUrl: './general-dataset-manager.component.html',
  styleUrls: ['./general-dataset-manager.component.scss']
})
export class GeneralDatasetManagerComponent implements OnDestroy, DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs (): any { return }

  constructor (
    private _dialog: MatDialog
  ) {}

  ngOnDestroy () {
    this._destroyDialogs()
  }

  openLayerSequences () {
    this._trackDialog(
      this._dialog.open(
        LayerSequencesDatasetComponent
      )
    )
  }

}

applyMixins(GeneralDatasetManagerComponent, [DialogCleanup])
