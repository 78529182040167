<h2 mat-dialog-title>Edit columns</h2>

<app-note *ngIf="!validWhenUniqueRequired">
  Please select at least one column to identify every row as unique across the dataset.
</app-note>

<div mat-dialog-content>
  <div class="error-list">
    <p *ngFor="let error of errors" class="st-error">
      {{error}}
    </p>
  </div>

  <button id="add-column" *ngIf="canEditColumns" mat-icon-button color="primary" (click)="addColumn()" matTooltip="Add new column.">
      <mat-icon aria-label="New Column">add</mat-icon>
  </button>

  <div class="header">
    <div>
      <p>Original</p>
    </div>
    <div>
      <p>User defined</p>
    </div>
    <div>
      <p>Media</p>
      <mat-icon matTooltip="Select media type, to render media, instead of displaying text value">help_outline</mat-icon>
    </div>
    <div>
      <p>Unique</p>
      <mat-icon matTooltip="Selected columns identify a row as unique across the dataset.">help_outline</mat-icon>
    </div>
  </div>
  <div class="sortable-columns">
    <div *ngFor="let column of columns; let i = index" class="row">
      <mat-icon class="drag-handle">drag_indicator</mat-icon>

      <mat-form-field class="disabled" *ngIf="column.propOverride === undefined; else overrideOg">
        <input matInput disabled value="{{column.prop}}" />
      </mat-form-field>

      <ng-template #overrideOg >
        <mat-form-field class="conflict-column" [class.invalid]="column.prop === column.propOverride || !column.propOverride"
        [matTooltip]="getColumnTooltip(column.prop)">
          <input matInput [(ngModel)]="column.propOverride" (input)="checkValid()" maxlength="63"/>
        </mat-form-field>
      </ng-template>

      <mat-form-field>
        <input matInput [(ngModel)]="column.override" value="{{column.override}}" (input)="checkValid()" />
      </mat-form-field>

      <mat-form-field>
        <mat-select disableOptionCentering (selectionChange)="columnMediaChange(column, $event)" [value]="column.media_type">
          <mat-option [value]="undefined">n/a</mat-option>
          <mat-option *ngFor="let type of mediaTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="fixed-length">
        <span *ngIf="!data.uniqueKeySettable">{{column.uniqueRowKey ? 'YES' : '-'}}</span>

        <mat-checkbox class="fixed-length" *ngIf="data.uniqueKeySettable"
        [disabled]="!data.uniqueKeySettable" [(ngModel)]="column.uniqueRowKey">
        </mat-checkbox>
      </div>

      <button class="small-icon" mat-icon-button
      [ngClass]="{ inactive: !column.display_attribute_table }"
      (click)="column.display_attribute_table = !column.display_attribute_table"
      matTooltip="{{column.display_attribute_table ? 'hide' : 'show'}} column" [matTooltipPosition]="'below'">
          <mat-icon>{{ column.display_attribute_table ? "visibility" : "visibility_off" }}</mat-icon>
      </button>

      <span *ngIf="canEditColumns" [matTooltip]="systemVectorCols.includes(column.prop) ? 'System reserved columns cannot be deleted.' : undefined">
        <button class="small-icon" mat-icon-button (click)="deleteColumn(column)" [class.disabled]="systemVectorCols.includes(column.prop)" matTooltip="Delete column">
          <mat-icon aria-label="delete">delete</mat-icon>
      </button>
      </span>
    </div>
  </div>
</div>

<div class="st-button-row">
  <button color="primary" mat-button mat-dialog-close>CANCEL</button>
  <button color="primary" mat-button cdkFocusInitial [disabled]="invalid || !validWhenUniqueRequired" (click)="returnColumnNames()">
      SAVE
  </button>
</div>
<app-loader-backdrop *ngIf="applyingChanges" loadingText="Applying changes..."></app-loader-backdrop>
