import AppError from '@core/models/app-error'
import * as moment from 'moment'

interface TimeChange {
  from: moment.Moment
  to: moment.Moment
  type: 'partial-del' | 'full-del' | 'add' | 'same'
}

export default class TimeUtil {
  static readonly momentFormatTooltip = [
    `YYYY     - 4 digit year`,
    'YY       - 2 digit year',
    'Q        - 1..4 quarter of year',
    'M MM     - 1..12 1 digit or 2 digit month number',
    `MMM MMMM -	Jan..December	month name`,
    'D DD     -	1..31	Day of month',
    'Do	      - 1st..31st	Day of month with ordinal',
    'DDD DDDD	- 1..365	Day of year',
    'H HH	    - 0..23	Hours (24 hour time)',
    'h hh	    - 1..12	Hours (12 hour time used with a A.)',
    'a A	    - am..pm Post or ante meridiem (Note the one character a p are also considered valid)',
    'm mm     -	0..59	Minutes',
    's ss	    - 0..59	Seconds',
    'S SS ... SSSSSSSSS -	0..999999999	Fractional seconds',
    'Z ZZ	    - +12:00	Offset from UTC as +-HH:mm, +-HHmm, or Z'
  ].join('\n')

  static rangesOverlap (
    range1: {
      start: moment.Moment,
      end: moment.Moment
    },
    range2: {
      start: moment.Moment,
      end: moment.Moment
    },
    allowSameDate = true
  ): boolean {
    return allowSameDate ?
      range1.start.isSameOrBefore(range2.end) && range1.end.isSameOrAfter(range2.start) :
      range1.start.isBefore(range2.end) && range1.end.isAfter(range2.start)
  }

  static getRangeDifference (
    fromDate1: moment.Moment,
    toDate1: moment.Moment,
    fromDate2: moment.Moment,
    toDate2: moment.Moment
  ): TimeChange[] {
    const fromDiff = fromDate1.diff(fromDate2)
    const toDiff = toDate1.diff(toDate2)

    if (fromDate1.isSameOrAfter(toDate1) || fromDate2.isSameOrAfter(toDate2)) {
      throw new AppError(`Date 'from' cannot be after 'to' date.`)
    }

    const changes: TimeChange[] = []
    if (!fromDiff && !toDiff) {
      changes.push({
        from: fromDate1,
        to: toDate1,
        type: 'same'
      })
    }

    const dates = [fromDate1, toDate1, fromDate2, toDate2].sort((a, b) => a.isAfter(b) ? 1 : -1)

    if (dates[0] === fromDate1 ? dates[1] === toDate1 : dates[1] === toDate2) {
      // Dates do not overlap fromN-toN-fromM+toM
      changes.push({
        from: fromDate1,
        to: toDate1,
        type: 'full-del'
      })

      changes.push({
        from: fromDate2,
        to: toDate2,
        type: 'add'
      })
    } else if (dates[0] === fromDate1) {
      // If overlap is from1-from2 ...
      if (fromDiff) {
        changes.push({
          from: fromDate1,
          to: fromDate2,
          type: 'partial-del'
        })
      }
      if (dates[2] === toDate1) {
        // If overlap is from1-from2+to1+to2
        changes.push({
          from: fromDate2,
          to: toDate1,
          type: 'same'
        })
        if (toDiff) {
          changes.push({
            from: toDate1,
            to: toDate2,
            type: 'add'
          })
        }
      } else {
        // If overlap is from1-from2+to2-to1
        changes.push({
          from: fromDate2,
          to: toDate2,
          type: 'same'
        })
        if (toDiff) {
          changes.push({
            from: toDate2,
            to: toDate1,
            type: 'partial-del'
          })
        }
      }
    } else if (dates[0] === fromDate2) {
      // If overlap is from2+from1 ...
      if (fromDiff) {
        changes.push({
          from: fromDate2,
          to: fromDate1,
          type: 'add'
        })
      }
      if (dates[2] === toDate1) {
        // If overlap is from2+from1+to1+to2
        changes.push({
          from: fromDate1,
          to: toDate1,
          type: 'same'
        })
        if (toDiff) {
          changes.push({
            from: toDate1,
            to: toDate2,
            type: 'add'
          })
        }
      } else {
        // If overlap is from2+from1+to2-to1
        changes.push({
          from: fromDate1,
          to: toDate2,
          type: 'same'
        })
        if (toDiff) {
          changes.push({
            from: toDate2,
            to: toDate1,
            type: 'partial-del'
          })
        }
      }
    }
    return changes
  }
}
