<h2 mat-dialog-title>Dataset Manager</h2>

<div mat-dialog-content>
  <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>

  <mat-horizontal-stepper #stepper [linear]="true">
    <mat-step [editable]="true">
      <ng-template matStepLabel>Product</ng-template>

      <span>Select product to edit it's dataset:</span>

      <mat-list class="mat-elevation-z5">
        <mat-list-item (click)="selectProduct(generalProduct)">
          <span>General</span>
        </mat-list-item>
        <mat-list-item *ngFor="let product of products" (click)="selectProduct(product)">
          <span>{{product.product_name}}</span>
        </mat-list-item>
      </mat-list>
    </mat-step>

    <mat-step *ngIf="selectedProduct" [ngSwitch]="selectedProduct?.product_id">
      <ng-template matStepLabel>{{selectedProduct?.product_name || 'Dataset'}}</ng-template>

      <ng-container *ngSwitchCase="7">
        <app-property-view-dataset-manager></app-property-view-dataset-manager>
      </ng-container>

      <ng-container *ngSwitchCase="'general'">
        <app-general-dataset-manager></app-general-dataset-manager>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <app-note color="warn">
          Dataset management of product '{{selectedProduct?.product_name}}' is not implemented.
        </app-note>
      </ng-container>

    </mat-step>
  </mat-horizontal-stepper>
</div>


<app-loader-backdrop *ngIf="loading" loadingText="Retrieving information.."></app-loader-backdrop>
