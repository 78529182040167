import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import * as moment from 'moment'
import { trigger, state, style, transition, animate } from '@angular/animations'

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  animations: [
    trigger('countdownTrigger', [
      state('start', style({
        width: '100%'
      })),
      state('stop', style({
        width: '0%'
      })),
      transition('start => stop', [animate('7s')]),
      transition('stop => start', [animate('0s')])
    ])
  ]
})

  export class AlertDialogComponent implements OnInit {
  autoCloseTimeout?: ReturnType<typeof setTimeout>
  keep = false
  animationOn = false
  readonly closeTimeout = 7000
  constructor (
        @Inject(MAT_DIALOG_DATA) public dataRef: {text: string, time: moment.Moment, keep?: boolean}[],
        private _dialogRef: MatDialogRef<AlertDialogComponent>
      ) {}

  static setOptions (alertReference: {text: string, time: moment.Moment, keep?: boolean}[]): MatDialogConfig {
    const options = {
      data: alertReference,
      hasBackdrop: false,
      position: {
        bottom: '40px'
      }
    }

    return options
  }

  ngOnInit () {
    this.updateAlert()
    if (this.dataRef && this.dataRef[0] && this.dataRef[0].keep) {
      this.keepDialog()
    }
  }

  updateAlert () {
    if (!this.keep) {
      if (this.autoCloseTimeout) {
        clearTimeout(this.autoCloseTimeout)
      }
      this.ResetAnimation()
      this.autoCloseTimeout = setTimeout(() => {
        this._dialogRef.close()
      }, this.closeTimeout)
    }
  }

  keepDialog () {
    this.keep = true
    if (this.autoCloseTimeout) {
      clearTimeout(this.autoCloseTimeout)
    }
  }

  private ResetAnimation () {
    if (this.animationOn) {
      setTimeout(() => {
        this.animationOn = false
      }, 10)
    }
    setTimeout(() => {
      this.animationOn = true
    }, 20)
  }
}
