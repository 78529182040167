import { Sortable } from '@shopify/draggable/lib/es5/draggable.bundle.legacy'
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Db } from '@vip-shared/models/db-definitions'
import { MatSelectChange } from '@angular/material/select'
import { CommonUtil } from '@core/utils/index'
import { IFeatureKnownProperties } from '@core/types'
import { AppLayer } from '@core/models/layer'
import { AlertService, PromptService } from '@services/core'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { NewAttributeColumnDialogComponent } from './new-attribute-column-dialog/new-attribute-column-dialog.component'
import { cloneDeep } from 'lodash'
import AppError, { handleError } from '@core/models/app-error'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { IPNewVectorColumn } from '@vip-shared/interfaces'

type IAttributeColumn = Db.Helper.Geo.AttributeColumn & {
  propOverride?: string
}

interface DialogData {
  columns: IAttributeColumn[]
  attributes: IFeatureKnownProperties[]
  layer?: AppLayer
  uniqueKeyRequired?: boolean
  uniqueKeySettable?: boolean
}

@Component({
  selector: 'app-columns-dialog',
  templateUrl: './columns-dialog.component.html',
  styleUrls: ['./columns-dialog.component.scss']
})
// Used in workspace.module only, but imported in core modules, because
// it's used by a service injected in 'root' so component must be in root as well

export class ColumnsDialogComponent implements OnInit, DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  columns?: IAttributeColumn[]
  tooltipText?: string
  invalid = false
  mediaTypes = ['image', 'video']
  applyingChanges = false
  errors: string[] = []

  readonly systemVectorCols = Columns.System

  get canEditColumns (): boolean {
    return !!this.data.layer && !this.data.layer.preset
  }

  get validWhenUniqueRequired (): boolean {
    return !this.data.uniqueKeyRequired || (
      !!this.columns && this.columns.some(c => c.uniqueRowKey)
    )
  }

  static setup (data: DialogData) {
    return {
      data,
      disableClose: true
    } as MatDialogConfig
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _dialogRef: MatDialogRef<ColumnsDialogComponent>,
    private _alertService: AlertService,
    private _promptService: PromptService,
    private _dialog: MatDialog
  ) {}

  ngOnInit () {
    if (this.data.layer) this.data.layer.alterIfLinked().then(alter => !alter && this._dialogRef.close())
    // loose the references
    this.columns = this.data.layer ? this.data.layer.attributeColumns : cloneDeep(this.data.columns)
    if (this.columns && !this.data.layer) {
      for (const col of this.columns) {
        if (Columns.System.includes(col.prop)) col.propOverride = `${col.prop}_2`
        else if (col.prop.length > 63) col.propOverride = col.prop.slice(0, 63)
      }
    }
    this.InitSorting()

    if (this.data.layer) {
      const layer = this.data.layer
      layer.columnsRefreshed.subscribe(() => {
        this.columns = layer.attributeColumns
      })
    }

    this.checkValid()
  }

  checkValid () {
    if (this.columns) {
      this.errors = []
      const nullOverrides = this.columns.some(column => {
        return !column.override || !column.override.length
      })

      this.invalid = nullOverrides
      if (nullOverrides) this.errors.push('All column names are required.')

      if (this.columns) {
        if (!this.data.layer) {
          const conflictColumnsValid = this.columns
          .every(x => x.propOverride !== undefined ? x.propOverride.length && x.prop !== x.propOverride : true)

          if (!conflictColumnsValid) {
            this.errors.push('There are columns conflicting with system reserved names.')
            this.invalid = true
          }
        }

        const maxLength = this.columns.some(x => (x.propOverride || x.prop).length > 63)
        if (maxLength) {
          this.errors.push('There are columns exceeding max length of 63 characters.')
          this.invalid = true
        }

        const trimmed = this.columns.some(x => x.prop.length > 63)
        const unique = Array.from(new Set(this.columns.map(x => x.propOverride || x.prop)))
        const duplicates = unique.length !== this.columns.length
        if (duplicates) {
          const duplicateColumns = unique.map(val => ({
            column: val,
            count: (this.columns as IAttributeColumn[]).reduce((sum, c) => (c.propOverride || c.prop) === val ? sum + 1 : sum, 0)
          })).filter(x => x.count > 1).map(x => x.column)
          this.errors.push(`There are duplicate columns ${duplicateColumns.join(', ')}.${trimmed ? ' This could be a result of clipping column names where they were longer than 63 characters.' : ''}`)
          this.invalid = true
        }
      }

      if (this.invalid) {
        this.tooltipText = this.errors.join(' ')
      }
    }
  }

  returnColumnNames () {
    this._dialogRef.close(this.columns)
  }

  private InitSorting () {
    const sortable = new Sortable(document.querySelectorAll('.sortable-columns'), {
      draggable: '.row',
      handle: '.drag-handle',
      mirror: {
        constrainDimensions: true
      }
    })

    sortable.on('sortable:stop', (evt: any) => {
      const data = evt.data
      if (this.columns) {
        const spliced = this.columns.splice(data.oldIndex, 1)
        this.columns.splice(data.newIndex, 0, spliced[0])
      }
    })
  }

  columnMediaChange (column: IAttributeColumn, event: MatSelectChange) {
    const value = event.value
    if (!value) {
      delete column.media_type
      return
    }
    try {
      if (value === 'image') {
        if (Columns.System.includes(column.prop)) throw new AppError(`System columns ${Columns.System.join(', ')} cannot be set as image type.`)
        this.ValidateImageValues(column.prop)
      }
      Object.assign(column, { media_type: value })
    } catch (error: any) {
      this._alertService.log(error.message)
      event.source.writeValue(undefined)
    }
  }

  private ValidateImageValues (colName: string) {
    let notImageWarning = 0
    for (const attr of this.data.attributes) {
      const val = CommonUtil.isUndefined(attr[colName]) ? '' : `${attr[colName]}`
      if (!val) continue
      const valid = CommonUtil.isValidLink(val) ||
      (CommonUtil.isSupportedHtmlImage(val) && !val.includes('/') && !val.includes('\\'))
      if (CommonUtil.isValidLink(val) && !CommonUtil.isSupportedHtmlImage(val)) notImageWarning++

      if (!valid) throw new AppError(`Column '${colName}' contains value '${val}' that is not a valid url or valid image name/url. Cannot set media type to 'image'.`)
    }
    if (notImageWarning > 0) {
      this._alertService.log(`Column '${colName}' has ${notImageWarning} url(s) that do not seem to contain an image. If uploaded, it's likely that no image will not be rendered.`)
    }
  }

  addColumn () {
    const layer = this.data.layer
    if (!layer) return

    this._trackDialog(
      this._dialog.open(NewAttributeColumnDialogComponent, NewAttributeColumnDialogComponent.setup({
        columns: layer.tableColumns
      }))
    )
    .afterClosed().subscribe(async (columns: IPNewVectorColumn[]) => {
      if (columns) {
        try {
          await layer.createColumns(columns)
        } catch (error: any) {
          handleError(error)
          this._alertService.log(error.message)
        }
      }
    })
  }

  deleteColumn (column: IAttributeColumn) {
    const layer = this.data.layer
    if (!layer) return
    this._trackDialog(
      this._promptService.prompt(
        `Are you sure you want to permanently delete '${column.prop}' column\
        from layer '${layer.title}'?`, {
          yes: () => this.DeleteColumn(column.prop),
          no: null
        })
    )
  }

  private async DeleteColumn (columnName: string) {
    if (!this.data.layer) return
    try {
      this.applyingChanges = true

      await this.data.layer.deleteColumn(columnName)
      this.columns = this.data.layer.attributeColumns
    } catch (error: any) {
      this._alertService.log(error.message)
    } finally {
      this.applyingChanges = false
    }
  }

  getColumnTooltip (og: string) {
    return og.length > 62 ?
     `Column '${og}' length is exceeding maximum length of 63 characters.` :
     `Column '${og}' is reserved by the system and cannot be used in upload.`
  }
}

applyMixins(ColumnsDialogComponent, [DialogCleanup])
