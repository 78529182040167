<div id="widget-container">
  <mat-form-field appearance="fill">
    <mat-label>Chart type</mat-label>
    <mat-select [(value)]="chartType" (selectionChange)="updateChart()">
      <mat-option *ngFor="let type of chartTypes" [value]="type">{{type}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="canvas-wrapper">
    <canvas baseChart
              [datasets]="pieChartData"
              [labels]="pieChartLabels"
              [options]="pieChartOptions"
              [legend]="chartLegend"
              [type]="chartType"></canvas>
  </div>
</div>
