import { Component, Inject, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AlertService, SettingsService, VipApiService } from '@services/core'
import { LayerService, WorkspaceService } from '@services/workspace'
import { FormTemplate } from '@core/models/form-template'
import { AppLayer } from '@core/models/layer'
import { TypedFormControl, TypedFormGroup } from '@core/models/typed-form-control'
import FormValidators from '@core/utils/form-validators/form-validators'
import { Db } from '@vip-shared/models/db-definitions'
import { IEntries, IPNumEntriesCheck, IPPermanentArea } from '@vip-shared/interfaces'
import { CommonUtil, ConvertUtil, DomUtil } from '@core/utils/index'
import { handleError } from '@core/models/app-error'
import { IQueryWRef } from '@core/models/query-object'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import OlUtil from '@core/utils/ol/ol.util'
import { clientDataThreshold, layerAlertThreshold } from '@vip-shared/models/const/data-limits'
import { domain } from 'process'

interface DialogData {
  layers: AppLayer[]
  query?: IQueryWRef
  initQuery: boolean
  compositeSelector?: {
    layer?: AppLayer,
    selector: Db.Helper.Prj.SpatialSelectorMeta[]
  }[]
}

type Form = TypedFormGroup<{
  name: TypedFormControl<string>
  fileName: TypedFormControl<string>
  wktGeometry: TypedFormControl<string>
  viewGeometry: TypedFormControl<string>
  entries: TypedFormControl<number>
}>

@Component({
  selector: 'app-postcode-area-dialog.component',
  templateUrl: './postcode-area-dialog.component.html',
  styleUrls: ['./postcode-area-dialog.component.scss']
})
export class PostcodeAreaDialogComponent extends FormTemplate<Form> implements OnInit {
  static setup (data: DialogData) {
    return {
      data
    }
  }
  acceptFormat = '.csv'
  file: File | undefined
  defaultTitle = 'Untitled Spatial Query'
  partialPostcodesMatched = false
  noPostcodesMatched = false
  numEntriesLoaded: boolean = false
  numEntriesLoading: boolean = false
  numEntries: IEntries = {count: 0, sampled_count: undefined}
  entriesMsg : string = ''
  dataCapWarning: boolean = false
  layerAlert: boolean = false
  existingEntries: number = 0
  singleLayerFlag: boolean = false

  get usePostcodeMatch () {
    if (this.singleLayerFlag) {
      const layer = this.data.layers[0]
      if (!layer.preset) return false
      return [Db.Vip.LayerPreset.FLOODRE_CLAIMS, Db.Vip.LayerPreset.FLOODRE_EXPOSURE, Db.Vip.LayerPreset.PROPERTY_DATA_HUB].includes(layer.preset)
    }
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected _dialogRef: MatDialogRef<PostcodeAreaDialogComponent>,
    private _apiService: VipApiService,
    private _alertService: AlertService,
    private _settingsService: SettingsService,
    private _workspaceService: WorkspaceService,
    private _layerQueriesService: LayerQueriesService,
    private _layerService: LayerService
  ) {
    super(new TypedFormGroup({
      name: new TypedFormControl<string>(undefined, Validators.required),
      wktGeometry: new TypedFormControl<string>(undefined, [
        Validators.required,
        FormValidators.wktValid(['Polygon', 'MultiPolygon'])
      ]),
      viewGeometry: new TypedFormControl<string>(undefined, [
        Validators.required,
        FormValidators.wktValid(['Polygon', 'MultiPolygon'])
      ]),
      fileName: new TypedFormControl<string>(undefined),
      entries: new TypedFormControl<number>(undefined, Validators.required)
    }))
    this.singleLayerFlag = this.data.layers.length === 1
    if (this.data.query) {
      this.form._controls.name.setValue(this.data.query.name)
      const savedGeom = OlUtil.featureToWkt(this.data.query.geometry, 6)
      this.form._controls.wktGeometry.setValue(savedGeom)
      this.form._controls.wktGeometry.markAllAsTouched()
    } else {
      if (this.singleLayerFlag) {
        const layer = this.data.layers[0]
        const spatialQueriesLength = this._layerQueriesService.getTargetQueries(layer, ['spatial']).length
        this.existingEntries = spatialQueriesLength ? layer.features.length : 0
      }
    }
  }

  async ngOnInit () {
    if (this.singleLayerFlag) {
      const layer = this.data.layers[0]
      const spatialQueriesLength = this._layerQueriesService.getTargetQueries(layer, ['spatial']).length
      if (this.data.query && spatialQueriesLength !== 1) this.existingEntries = await this._layerQueriesService.getFeatureCountWithQuery(this.data.query)
    }
  }

  onTitleFocus () {
    if (this.form._controls.name.value === this.defaultTitle) {
      this.form._controls.name.setValue('')
    }
  }

  onTitleBlur () {
    if (!this.form._controls.name.value) {
      this.form._controls.name.setValue(this.defaultTitle)
    }
  }

  async fileChanged (event: Event | File[], controlName: string) {
    let files: File[] | undefined
    if (Array.isArray(event)) {
      files = event
    } else if ((event.target as HTMLInputElement).files) {
      files = Array.from((event.target as HTMLInputElement).files as any)
    }
    try {
      if (files && files[0]) {
        let file = files[0]
        this.numEntriesLoaded = false
        this.numEntries =  {count: 0, sampled_count: undefined}
        this.form._controls.entries.setValue(undefined)

        CommonUtil.validateFile(file, this.acceptFormat, this._settingsService.maxApiFileSize)

        // Attempt to overwrite file type based on extension name, as there have been cases where for example
        // .csv has excel mimetype, and therefore failed server side.
        const mimetype = CommonUtil.getDefaultMimeType(file.name)
        if (mimetype) file = ConvertUtil.blobToFile(file, file.name, mimetype)
        if (this.form && this.form.controls[controlName]) {
          this.form.controls[controlName].setValue(file.name)
        }

        this.file = file
        const loadedCsv = await CommonUtil.loadCsv(file)
        const uniquePostcodes = new Set(loadedCsv.split(/\n\r?/g).filter(item => item))
        const numDataRows = uniquePostcodes.size
        const result = await this._apiService.orm.Products().Fred().getWktPostcodeGeometry(file).run()
        if (result.usedGeometries) {
          this.form._controls.wktGeometry.setValue('SRID=4326;' + result.geometry)
          this.form._controls.viewGeometry.setValue('SRID=4326;' + result.viewGeometry)
          this.partialPostcodesMatched = numDataRows !== result.usedGeometries ? true : false
          this.noPostcodesMatched = false
          if (this.singleLayerFlag) {
            await this.getNumEntriesInArea(this.data.layers[0], this.usePostcodeMatch ? [...uniquePostcodes] : [])
          } else {
            this.data.layers.forEach(async (l) => {
              await this.getNumEntriesInArea(l, this.usePostcodeMatch ? [...uniquePostcodes] : [])
            })
          }

        } else {
          this.noPostcodesMatched = true
          this.partialPostcodesMatched = false
        }
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  async save () {
    if (this.saving) return
    this.saving = true
    if (this.singleLayerFlag) {
      await this.SaveLayerQuery(this.data.layers[0])
    } else {
      this.data.layers.forEach(async (l) => {
        await this.SaveLayerQuery(l)
      })
    }
    this.saving = false
    this._dialogRef.close(true)
  }

  async SaveLayerQuery (layer: AppLayer) {
    try {
      let query: IQueryWRef

      if (this.data.query) {
        query = this.data.query
      } else {
        query = {
          applied: true,
          query: {
            blocks: [],
            operator: '',
            spatialSelector: []
          },
          targetRef: layer,
          view_id: this._workspaceService.viewId as number,
          workspace_id: this._workspaceService.workspaceId as number,
          type: 'spatial',
          layer_id: layer.id
        } as Partial<IQueryWRef> as IQueryWRef
      }

      query.geometry = OlUtil.wktToFeature(this.form.controls.wktGeometry.value).getGeometry()
      query.name = this.form.controls.name.value

      await this._layerQueriesService.toggleQuery(query, !!query.applied, true)
    } catch (error: any) {
      this._alertService.log(error.message)
    }
  }

  async getNumEntriesInArea (layer: AppLayer, postcodes?: string[]) {
    if (this.numEntriesLoaded || this.numEntriesLoading) return
    const timeSeries = layer.timeSeriesSelection && layer.timeSeriesSelection.date_range ? layer.timeSeriesSelection : layer.renderedTimeSeriesSelection
    let upload: IPNumEntriesCheck
    if (timeSeries && layer.vectorTimeSeriesConf) {
      upload = {
        valid_from: timeSeries.date_range.from,
        valid_to: timeSeries.date_range.to,
        layer_preset: layer.preset as Db.Vip.LayerPreset,
        wkt_geometry: this.form.controls.wktGeometry.value,
        postcode_list: postcodes || []
      }
    } else {
      upload = {
        layer_id: layer.id,
        layer_preset: layer.preset as Db.Vip.LayerPreset,
        wkt_geometry: this.form.controls.wktGeometry.value,
        postcode_list: postcodes || []
      }
    }
    this.numEntriesLoading = true
    this.numEntries = await this._apiService.orm.Products().Fred().getNumEntries(upload).run()
    this.entriesMsg = DomUtil.entriesDialogMessage(this.numEntries, true)
    this.numEntriesLoaded = true
    this.numEntriesLoading = false

    const entries = (+this.numEntries.count + (+this.existingEntries))
    this.dataCapWarning = entries > clientDataThreshold
    this.layerAlert = (entries < clientDataThreshold && entries > layerAlertThreshold)

    if (!this.dataCapWarning) this.form._controls.entries.setValue(entries)
  }

}
