<div class="title-wrapper">
  <h1 mat-dialog-title>Uploads list</h1>
</div>
<div mat-dialog-content>
  <mat-list>
    <ng-container *ngIf="files.uploaded.length">
      <h3 *ngIf="files.uploaded.length" mat-subheader>Uploaded ({{files.uploaded.length}})</h3>
      <mat-list-item *ngFor="let file of files.uploaded">
        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
        <h4 mat-line>{{file.name}}</h4>
      </mat-list-item>
      <mat-divider *ngIf="files.pending.length"></mat-divider>
    </ng-container>

    <ng-container>
      <h3 *ngIf="files.pending.length" mat-subheader>Queued ({{files.pending.length}})</h3>
      <mat-list-item *ngFor="let file of files.pending">
        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
        <h4 mat-line>{{file.name}}</h4>
        <p mat-line *ngIf="file.uploaded?.length"> Selected: {{formatExtensions(file.uploaded)}} </p>
        <p mat-line *ngIf="file.missing?.length" class="warning"> Missing: {{formatExtensions(file.missing)}} </p>
      </mat-list-item>
    </ng-container>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button color="primary" type="button" mat-button [mat-dialog-close]>Close</button>
</div>
