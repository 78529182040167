import { Injectable } from '@angular/core'
import { Router, NavigationEnd, NavigationStart, GuardsCheckStart, GuardsCheckEnd, RouteConfigLoadStart, NavigationCancel, NavigationError, RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router'
import { handleError } from '@core/models/app-error'
import { RouteData } from '@core/types/route-data'
import { Subscriber, Subject } from 'rxjs'
import { auditTime } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class NgRouterService {
  private _history: string[] = []
  private readonly _storageKey = 'navigation_history'

  get history (): string[] {
    return [...this._history]
  }

  get previousUrl (): string {
    return this.history[this.history.length - 1] || '/'
  }

  private _navigating = false
  get navigating () {
    return this._navigating
  }

  private _navigationText?: string
  get navigationText () {
    return this._navigationText || 'Loading page...'
  }

  private _loadScreenToggle = new Subject<boolean>()

  private _allPaths: string[] = []

  get previousRoute () {
    let last = this._allPaths[this._allPaths.length - 1]
    if (last === window.location.pathname) {
      last = this._allPaths[this._allPaths.length - 2]
    }
    return last
  }

  constructor (private _router: Router) {
    const prevHistory = localStorage.getItem(this._storageKey)
    if (prevHistory) {
      try {
        this._history = JSON.parse(prevHistory)
      } catch (err) {
        handleError(err)
      }
    }

    this._loadScreenToggle
    .pipe(
      // We use time audit to avoid loading screen if it's loaded quickly
      auditTime(500)
    ).subscribe(enabled => {
      this._navigating = enabled
    })

    _router.events
    .subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        this._allPaths.push(e.url)
      }
    })
  }

  init () {
    this._router.events
    .subscribe(e => {
      if (e instanceof NavigationStart) {
        this._history.push(e.url)
        localStorage.setItem(this._storageKey, JSON.stringify(this._history))
      }

      if (e instanceof RoutesRecognized) {
        const getData = (snp?: {firstChild?: any, data?: RouteData}) => snp && (snp.firstChild ? getData(snp.firstChild) : snp.data)
        const data: null | RouteData = getData(e.state.root)
        if (data && data.loadingScreen) {
          this._loadScreenToggle.next(true)
          this._navigationText = data.loadingScreen.text
        }
      } else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        // This next won't change state but will cancel out the 'true' event
        this._loadScreenToggle.next(false)
        this._navigating = false
        this._navigationText = undefined
      }
    })
  }
}
