import { Injectable } from '@angular/core'
import { RouteMeansOfTransportType, AirQualityValues } from '@core/enum/air'

import { ICoordinates } from '@core/types'

import { environment } from 'environments/environment'

import * as olColor from 'ol/color'
import * as olGeom from 'ol/geom'
import Polyline from 'ol/format/Polyline'
import Feature from 'ol/Feature'

import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'

import Style from 'ol/style/Style'
import { Subject } from 'rxjs'
import { AirRouteFinder } from '@core/models/air-route-finder'
import { IRouteBreakdownRoute, IRouteBreakdown } from '@core/types/'
import { RoutingMiddlewareService } from '../routing-middleware/routing-middleware.service'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AlertService } from '@services/core/alert/alert.service'
import AppError, { handleError } from '@core/models/app-error'
import { LayerStyleUtil } from '@core/models/layer/utils'

@Injectable({
  providedIn: 'root'
})
export class AirRouteCalculateService {

  private _waypoints: ICoordinates[] = [
        { lon: 0, lat: 0 },
        { lon: -1, lat: -1 }
  ]

  private _fullRouteInformation = new AirRouteFinder()
  private _tryAgain = new Subject()

  get waypoints (): ICoordinates[] {
    return this._waypoints
  }
  set waypoints (value: ICoordinates[]) {
    this._waypoints = value
  }

  get tryAgain () {
    return this._tryAgain.asObservable()
  }

  set fullRouteInformation (value: AirRouteFinder) {
    this._fullRouteInformation = value
  }

  constructor (
        private _routingMiddlewareService: RoutingMiddlewareService,
        private _workspaceService: WorkspaceService,
        private _api: VipApiService,
        private _alertService: AlertService
    ) {}

  async showOnlyWalking (color?: olColor.Color, fullBreakdown?: IRouteBreakdownRoute): Promise<VectorLayer<any>> {

    const layers: VectorLayer<any>[] = await this.getRoute(
            RouteMeansOfTransportType.Walking,
            this.waypoints,
            this._fullRouteInformation,
            color,
            fullBreakdown
        )
    return layers[0]
  }

  async showOnlyCar (color?: olColor.Color, fullBreakdown?: IRouteBreakdownRoute): Promise<VectorLayer<any>> {

    const layers: VectorLayer<any>[] = await this.getRoute(
            RouteMeansOfTransportType.Car,
            this.waypoints,
            this._fullRouteInformation,
            color,
            fullBreakdown
        )
    return layers[0]
  }

  async showOnlyCycling (color?: olColor.Color, fullBreakdown?: IRouteBreakdownRoute): Promise<VectorLayer<any>> {

    const layers: VectorLayer<any>[] = await this.getRoute(
            RouteMeansOfTransportType.Bike,
            this.waypoints,
            this._fullRouteInformation,
            color,
            fullBreakdown
        )
    return layers[0]
  }

  async showAll (color?: olColor.Color, fullBreakdown?: IRouteBreakdownRoute): Promise<VectorLayer<any>[]> {

    const layers: VectorLayer<any>[] = await this.getRoute(
            RouteMeansOfTransportType.All,
            this.waypoints,
            this.fullRouteInformation,
            color,
            fullBreakdown
        )
    return layers
  }

  async getRoute (
        meansOfTransport: RouteMeansOfTransportType,
        waypoints: ICoordinates[],
        routeInfo = this._fullRouteInformation,
        color?: olColor.Color,
        fullBreakdown?: IRouteBreakdownRoute
    ): Promise<VectorLayer<any>[]> {

    const routeLayers: VectorLayer<any>[] = []

    if (meansOfTransport === RouteMeansOfTransportType.All) {

      await Promise.all([

        this.showOnlyWalking(color, fullBreakdown).then((layer: VectorLayer<any>) => {
          routeLayers.push(layer)
          return true
        }),
        this.showOnlyCar(color, fullBreakdown).then((layer: VectorLayer<any>) => {
          routeLayers.push(layer)
          return true
        }),
        this.showOnlyCycling(color, fullBreakdown).then((layer: VectorLayer<any>) => {
          routeLayers.push(layer)
          return true
        })
      ])

    } else {
      const vectorLayer: VectorLayer<any> | undefined =
                await this.RetrieveRoute(meansOfTransport, waypoints, routeInfo, color, fullBreakdown)

      if (vectorLayer) {
        routeLayers.push(vectorLayer)
      }
    }

    return routeLayers
  }

  roundRouteScore (score: number): string {
    let displayScore = '> 9999'

    if (score < 9999) {

      displayScore = Math.round(score).toFixed(0)
    }

    return displayScore
  }

  calculateColour (score: number): string {

    let className

    if (score <= AirQualityValues.Low) {
      className = 'air-quality-low'

    } else if (score < AirQualityValues.High) {
      className = 'air-quality-medium'

    } else {
      className = 'air-quality-high'

    }

    return className
  }

  private async RetrieveRoute (
        meansOfTransport: string,
        waypoints: ICoordinates[],
        routeInfo: AirRouteFinder,
        color?: olColor.Color,
        fullBreakdown?: IRouteBreakdownRoute
    ): Promise<VectorLayer<any> | undefined> {

    let data: IRouteBreakdownRoute | undefined
    let urlDetails

    if (fullBreakdown) {
      data = fullBreakdown
    } else {
      urlDetails = this.GetUrlDetails(meansOfTransport, waypoints, routeInfo)
      const url = urlDetails.url
      data = await this.GetRouteSourceFromUrl(url)
    }

    if (data) {
      const routeBreakdownDisplay = this.ConvertDataToDisplayData(data)

      const route = new Polyline().readGeometry(data.geometry, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      })

      const style: Style = LayerStyleUtil.getMeansOfTransportStyle(meansOfTransport, color)

      const routeLayer: VectorLayer<any> = this.CreateRouteLayer(route, style)
      routeLayer.setProperties({
        meansOfTransport,
        iconColour: style.getStroke().getColor(),
        port: urlDetails ? urlDetails.port : undefined,
        routeBreakdownDisplay: routeBreakdownDisplay,
        routeBreakdown: data
      })

      return routeLayer
    }
  }

  private GetUrlDetails (meansOfTransport: string, waypoints: ICoordinates[], routeInfo: AirRouteFinder): {url: string, port: number} {

    const waypointUrl: string = this.GenerateWaypointUrl(waypoints)
    const region = this._workspaceService.regionName
    const port: number = this.CalculatePort(meansOfTransport, routeInfo)
    const url = `${(environment.UHL.airRoutingEngineUrl as any)[region]}:${port}`
                    + `/route/v1/driving/${waypointUrl}?overview=full&alternatives=0`

    return { url, port }
  }

  private async GetRouteSourceFromUrl (url: string): Promise<IRouteBreakdownRoute | undefined> {
    try {
      const response: IRouteBreakdown = await this._api.orm.Proxy(
        Base64.encodeURI(url)
      ).get().run({ loop: 5 })
      return response.routes[0]
    } catch (error: any) {
      handleError(error)
      this._alertService.log('Failed to find a route, please try again')
      throw new AppError('Failed to reach the port')
    }
  }

  private CreateRouteLayer (route: olGeom.Geometry, style: Style): VectorLayer<any> {

    const feature = new Feature({
      type: 'route',
      geometry: route
    })

    feature.setStyle(style)

    const routeSource = new VectorSource()
    routeSource.addFeature(feature)

    const routeLayer = new VectorLayer({
      source: routeSource
    })

    return routeLayer
  }

  private GenerateWaypointUrl (waypoints: ICoordinates[]): string {

    let waypointUrl = ''

    waypoints.forEach((waypoint: ICoordinates) => {
      waypointUrl += waypoint.lon + ',' + waypoint.lat + ';'
    })

    waypointUrl = waypointUrl.substr(0, waypointUrl.length - 1)

    return waypointUrl
  }

  private ConvertDataToDisplayData (data: IRouteBreakdownRoute): IRouteBreakdownRoute {

    data.distance = +(data.distance / 1000).toFixed(2)
    data.duration = data.duration / 60

    return data
  }

  private CalculatePort (meansOfTransport: string, routeInfo?: AirRouteFinder): number {

    let port = this._routingMiddlewareService.basePort
    if (!port) {
      throw new AppError('No base port found. Please start up OSRM servers')
    }
        // Ports ranges

        // car (0-5)
        // bicycle (6-11)
        // pedestrian (12-17)

    switch (meansOfTransport) {
      case RouteMeansOfTransportType.Car: port = port; break
      case RouteMeansOfTransportType.Bike: port = port + 6; break
      case RouteMeansOfTransportType.Walking: port = port + 12; break
    }

    if (routeInfo !== undefined) {
      port += routeInfo.balance
    }

    return port
  }
}
