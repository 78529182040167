/// <reference types="@types/googlemaps" />
// Reference google types here to make it available over all app,
// without this there will be ts errors when using google.

import { Component } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { WorkspaceMapService } from '@services/workspace'
import { NgRouterService } from '@services/core/ng-router/ng-router.service'
import { DomainService } from '@services/core/domain/domain.service'
import { KeyboardService } from '@services/core/keyboard/keyboard.service'
import { ThemeService } from '@services/core/theme/theme.service'
import { DocumentService } from '@services/core/document/document.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  get previewActive () {
    return !!this._themeService.previewTheme
  }

  constructor (
      private _matIconRegistry: MatIconRegistry,
      private _domSanitizer: DomSanitizer,
      // Initialize on app load, as if it's redirected to after /login page
      // the map may sometimes fail to load
      workspaceMapService: WorkspaceMapService,
      public routerService: NgRouterService,
      domainService: DomainService,
      shortcutsService: KeyboardService,
      documentService: DocumentService,
      private _themeService: ThemeService
    ) {
    routerService.init()
    domainService.init()
    shortcutsService.init()
    documentService.init()

    this.RegisterCustomIcons([
      ['icon_line_select', 'icons/custom__line-select.svg'],
      ['icon_line_select_teal', 'icons/custom__line-select-teal.svg'],

      ['icon_poly_select', 'icons/custom__poly-select.svg'],
      ['icon_poly_select_teal', 'icons/custom__poly-select-teal.svg'],

      ['layer_plus', 'icons/material/layers-plus.svg'],
      ['slice', 'icons/material/custom/slice.svg'],
      ['mouse_crossed', 'icons/material/custom/mouse-crossed.svg'],
      ['table_chart_crossed', 'icons/material/custom/table-chart-crossed.svg'],
      ['straighten_crossed', 'icons/material/custom/straighten-crossed.svg'],
      ['legend_toggle_crossed', 'icons/material/custom/legend-toggle-crossed.svg'],
      ['table_chart', 'icons/material/custom/table-chart.svg'],
      ['straighten', 'icons/material/custom/straighten.svg'],
      ['legend_toggle', 'icons/material/custom/legend-toggle.svg'],

      ['pin', 'icons/material/pin.svg'],
      ['pinned', 'icons/material/pinned.svg'],

      ['web_mode', 'icons/material/mode-switch-2.svg'],

      ['inner_join', 'icons/material/custom/sql-inner-join.svg'],
      ['inner_left_join', 'icons/material/custom/sql-inner-left-join.svg'],
      ['inner_right_join', 'icons/material/custom/sql-inner-right-join.svg'],
      ['outer_join', 'icons/material/custom/sql-outer-join.svg'],
      ['outer_inner_left_join', 'icons/material/custom/sql-outer-left-join.svg'],
      ['outer_inner_right_join', 'icons/material/custom/sql-outer-right-join.svg'],

      ['multi_touch_app', 'icons/material/custom/multi_touch_app.svg']
    ])
    _matIconRegistry.addSvgIconSet(_domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mdi.svg'))
  }

  private RegisterCustomIcons (icons: [string, string][]) {
    for (const icon of icons) {
      const [label, path] = icon
      this._matIconRegistry.addSvgIcon(
        label,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/${path}`
        )
      )
    }
  }
}
