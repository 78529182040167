import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core'

@Directive({
  selector: '[appHoldDown]'
})
export class HoldDownDirective {
  @Output() mouseHold = new EventEmitter()
  private _interval?: ReturnType<typeof setTimeout>
  constructor (private el: ElementRef) { }

  @HostListener('mouseleave') onMouseLeave () {
    if (this._interval) clearInterval(this._interval)
  }

  @HostListener('mouseup') onMouseUp () {
    if (this._interval) clearInterval(this._interval)
  }

  @HostListener('mousedown') onMouseDown () {
    this._interval = setInterval(() => {
      this.mouseHold.emit()
    }, 10)
  }
}
