import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'

export interface DialogData {
  title?: string
  titleIcon?: string
  content: string
  actions: any
  dismissEnabled?: boolean
  onDismiss?: () => void
}

@Component({
  selector: 'app-dynamic-alert-dialog',
  templateUrl: './dynamic-alert-dialog.component.html',
  styleUrls: ['./dynamic-alert-dialog.component.scss']
})
export class DynamicAlertDialogComponent {
  buttonNames: string[]

  static setOptions (data: DialogData): MatDialogConfig {
    const options: MatDialogConfig = {
      data,
      hasBackdrop: false,
      position: {
        bottom: '40px'
      }
    }

    return options
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _dialogRef: MatDialogRef<DynamicAlertDialogComponent>
  ) {
    this.buttonNames = Object.keys(data.actions).filter(k => data.actions[k] instanceof Function || data.actions[k] === null)
  }

  callAction (action: string) {
    const cb = this.data.actions[action]
    if (cb) cb()
    this._dialogRef.close()
  }
}
