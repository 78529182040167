<app-ol-map-timeslider
  *ngIf="showTimeSlider"
  [layerId]="layerId"
  [layerService]="layerService">
</app-ol-map-timeslider>
<app-ol-map-widget
  [layerId]="layerId"
  [layerService]="layerService"
  [workspaceMapService]="workspaceMapService">
</app-ol-map-widget>
<div class="journey-popup" *ngIf="selectedJourney?.imo">
  <div class="journey-line"><span class="journey-label">IMO</span><span class="journey-value">{{selectedJourney.imo}}</span></div>
  <div class="journey-line"><span class="journey-label">Date</span><span class="journey-value">{{selectedJourney.when | date}}</span></div>
  <div class="journey-line"><span class="journey-label">Speed</span><span class="journey-value">{{selectedJourney.speed}}</span></div>
  <div class="journey-line"><span class="journey-label">Power</span><span class="journey-value">{{selectedJourney.power}}</span></div>
  <div class="journey-line"><span class="journey-label">Energy</span><span class="journey-value">{{selectedJourney.energy}}</span></div>
  <div class="journey-line"><span class="journey-label">FC</span><span class="journey-value">{{selectedJourney.FC}}</span></div>
  <div class="journey-line"><span class="journey-label">NOx</span><span class="journey-value">{{selectedJourney.NOx}}</span></div>
  <div class="journey-line"><span class="journey-label">Heading</span><span class="journey-value">{{selectedJourney.heading}}</span></div>
  <div class="journey-line"><span class="journey-label">Status</span><span class="journey-value">{{selectedJourney.status}}</span></div>
</div>

