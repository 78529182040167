import { IRouteResult } from '@core/types/'

export class AirDownloadUtil {
  static createDownloadText (result: IRouteResult) {
    const header = 'Breathe Clean Export - ' + new Date().toLocaleString()
    let text = header
    text += '\r\nMode of transport: ' + result.icon
    text += '\r\nWaypoints: '
    result.waypoints.forEach((wp: any, index: number) => {
      text += '\r\n\t' + index + ' ' + wp.text
    })
    text += '\r\nDistance: ' + result.distance + ' km'
    const split = result.duration.split(':')
    const hh = split[0] || '0'
    const mm = split[1] || '0'
    text += '\r\nDuration: ' + `${hh} hr : ${mm} min`
    text += '\r\nAir Pollution Exposure Level: ' + result.value

    const fileType = 'txt'
    const fileName = header + '.txt'
    const blob = new Blob([text], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = URL.createObjectURL(blob)
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setTimeout(function () { URL.revokeObjectURL(a.href) }, 1500)
  }
}
