import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog'

import { IRasterChartOptions } from '@core/types/workspace/raster-chart-options'
import { VipApiService } from '@services/core'

@Component({
  selector: 'app-raster-chart-dialog',
  templateUrl: './raster-chart-dialog.component.html',
  styleUrls: ['./raster-chart-dialog.component.scss']
})
export class RasterChartDialogComponent {

  static setOptions(data: IRasterChartOptions) {
    data.isDialogMode = true
    const options: MatDialogConfig = {
      data
    }
    return options
  }

  constructor(
    protected _api: VipApiService,
    @Inject(MAT_DIALOG_DATA) public options: IRasterChartOptions
  ) { }

}
