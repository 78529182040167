<div id="widget-container">
  <div class="widget-controls">
    <mat-icon class="icon-align" [matTooltip]="infoTooltip">info</mat-icon>
  </div>
  <div class="st-ellipsis chart-title">
    <span appOverflowAnimate>{{chartTitle}}</span>
  </div>
  <div class="canvas-wrapper">
      <canvas baseChart
      [datasets]="chartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [type]="chartType"
              ></canvas>
  </div>
</div>
