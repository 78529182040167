import { Component } from '@angular/core'
import { ClipboardService } from 'ngx-clipboard'

@Component({
  selector: 'app-copy-p',
  templateUrl: './copy-p.component.html',
  styleUrls: ['./copy-p.component.scss']
})
export class CopyPComponent {

  constructor (
    private _clipboardService: ClipboardService
  ) {}

  copyToClipboard (p: HTMLParagraphElement) {
    this._clipboardService.copyFromContent(p.innerText)
  }

}
