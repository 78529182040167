import { Component, Input } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'

@Component({
  selector: 'app-ws-placeholder',
  templateUrl: './ws-placeholder.component.html',
  styleUrls: ['./ws-placeholder.component.scss']
})
export class WsPlaceholderComponent {
  @Input() productType: Db.Vip.Product = 0
  @Input() extent?: [number, number, number, number]
  @Input() workspaceId?: number

  readonly workspaceType = Db.Vip.Product
}
