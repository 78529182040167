
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AppLayer } from '@core/models/layer'
import { LayerStyleUtil } from '@core/models/layer/utils'
import { ConvertUtil } from '@core/utils'
import { WidgetLayerService } from '@services/workspace/workspace-map/widget-layer.service'
import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
import BaseLayer from 'ol/layer/Base'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Cluster from 'ol/source/Cluster'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { Db } from '@vip-shared/models/db-definitions'
import { EmissionsJourneysService } from '../../services/journeys.service'
import { State }  from '@core/enum/ol/ol-source-state'
import { Subscription } from 'rxjs'
import { WidgetMapService } from '@services/workspace/workspace-map/widget-map.service'
import { WorkspaceService } from '@services/workspace'
import { AlertService } from '@services/core'
import { ContextMenuService } from '@services/core/context-menu/context-menu.service'
import { DashboardLayoutService } from '@services/dashboard/dashboard-layout.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-emissions-map-widget',
  templateUrl: './emissions-map-widget.component.html',
  styleUrls: ['./emissions-map-widget.component.scss'],
  providers: [WidgetLayerService]
})

export class EmissionsMapWidgetComponent implements OnInit, OnDestroy {
  workspaceMapService?: WidgetMapService
  @Input()
  refreshData = () => {
    let x = 0
  }

  vectorLayer!: VectorLayer<any>

  journeys: Db.Vip.Emissions.IJourney[] = []
  selectedJourney!: Db.Vip.Emissions.IJourney

  layerId: string = 'default'
  targetLayer?: AppLayer
  private _subscriptions = new Subscription()

  get showTimeSlider() {
    // add group layer condition here
    return false
    // return !!this.targetLayer && this.targetLayer.isTimeSeries
  }

  constructor(
    public layerService: WidgetLayerService,
    public journeysService: EmissionsJourneysService,
    private _alertService: AlertService,
    private _workspaceService: WorkspaceService,
    private _contextMenu: ContextMenuService,
    private _dashboardLayoutService: DashboardLayoutService,
    private _dialog: MatDialog
    ) {
    this.workspaceMapService = new WidgetMapService(this._alertService, this.layerService as any, this._workspaceService, this._contextMenu, this._dashboardLayoutService, this._dialog)
    this._subscriptions.add(
      this.layerService.newLayer.subscribe(async layer => {
        const targetId = this.layerId.toString()
        if (layer.layer instanceof BaseLayer && layer.id === targetId) {
          this.targetLayer = this.layerService.getById(`${layer.id}`)
        }
      })
    )
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
  }

  ngOnInit(): void {
    setTimeout(_ => {
      if (this.workspaceMapService) {
        this.workspaceMapService.zoomToExtent([-664135.9308780638, 7284634.403054667, -649211.601105383, 7292287.66051172])
        this._subscriptions.add(
          this.workspaceMapService.viewportChanged.subscribe(view => {
            const x = view
          })
        )
        this.CreateLayer()
      }
    }, 50)
    this._subscriptions.add(
      this.journeysService.journeysChanged.subscribe(journeys => {
        this.journeys = journeys
        this.CreateLayer()
      })
    )
  }

  private CreateLayer() {

    const styleHandles = [{percentage:0, color:'#B3FCA6'},
                          {percentage:3.33, color:'#7CFA4D'},
                          {percentage:10, color:'#67CB3D'},
                          {percentage:16.6, color:'#FDFE54'},
                          {percentage:23.3, color:'#F4CF46'},
                          {percentage:30, color:'#ED9D38'},
                          {percentage:36.6, color:'#E8662B'},
                          {percentage:46.6, color:'#E53923'},
                          {percentage:66, color:'#8D1F11'},
                          {percentage:100, color:'#5C102B'}]

    let features: Feature[] = []
    this.journeys.forEach(journey => {
        const converted = ConvertUtil.lngLatToE3857([Number(journey.lon), Number(journey.lat)])
        const feature = new Feature({
          type: journey.journey_id,
          geometry: new Point(converted)
        })
        feature.setProperties(journey)

        features.push(feature)
    })

    if (this.workspaceMapService) {

      if (this.vectorLayer) {
        this.workspaceMapService.removeMapLayer(this.vectorLayer)
      }

      if (features.length > 0) {
        this.vectorLayer = new VectorLayer({
          source : new Cluster({
            distance: 5,
            source: new VectorSource({
              features: features
            })}),
            style: function (feature) {
              const props = feature.get('features')
              const NOx = props.map(prop => prop.get('nox'))
              const averageVal = NOx.reduce((a, b) => a + b) / NOx.length
              const color = LayerStyleUtil.getColorFromGradient(
                averageVal, styleHandles
              )
              const style = new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({ color: `rgb(${color.R},${color.G},${color.B})` }),
                  stroke: new Stroke({
                    color: 'white',
                    width: 1
                  })
                })
              })
              return style
            }
        })

        this.workspaceMapService.addMapLayer(this.vectorLayer)
        let centerInterval = setInterval(()=>{
          if(this.vectorLayer.getSource().getState() == State.READY && this.workspaceMapService){
            this.workspaceMapService.zoomToExtent(this.vectorLayer.getSource().getExtent())
            clearInterval(centerInterval)
          }
        }, 1000)

        this.workspaceMapService.map.on('pointermove', e => {

          e.map.forEachFeatureAtPixel(e.pixel, f => {
            this.selectedJourney = f.getProperties() as any
            return true
          })
        })
      }
    }
  }
}
