import { Component, OnInit, Inject, ViewChild, ElementRef, EventEmitter, Optional } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog'
import { Chart, ChartDataset, ChartConfiguration, LegendItem } from 'chart.js'
import { LayerService, WorkspaceService } from '@services/workspace'
import zoomPlugin from 'chartjs-plugin-zoom'
import { AppLayer } from '@core/models/layer'
import { Db } from '@vip-shared/models/db-definitions'
import { MatSelectChange } from '@angular/material/select'
import { ThemeService } from '@services/core/theme/theme.service'
import { CommonUtil } from '@core/utils/index'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { IChartWRef } from '@core/models/chart-object'
import { ChartsService } from '@services/workspace/layer/charts/charts.service'
import AppError, { handleError } from '@core/models/app-error'
import { AlertService, PromptService } from '@services/core'
import { IPUpdateChart } from '@vip-shared/interfaces'
import { FormTemplate } from '@core/models/form-template'
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { SolidColorPickerDialogComponent } from '@core/components/solid-color-picker/solid-color-picker-dialog/solid-color-picker-dialog.component'
import * as convert from 'color-convert'
import { Options, LabelType } from 'ng5-slider'
import * as moment from 'moment'
import { FREDGaugeMeasureGeojsonProperties } from '@vip-shared/models/layer-config/fred/fred-geojson-properties'
import { MergedAttributeMeta } from '@core/types'
import { Coordinate } from 'ol/coordinate'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { DashboardApiService } from '@services/dashboard/dashboard-api.service'
import * as Papa from 'papaparse'

interface PixelValue {
  label?: string
  value?: number
}
interface PixelChart {
  coordinates: number[]
  resolution: number
  focused_value: string
  hue: number
  pixel_values: PixelValue[]
}

interface DialogData {
  layer: AppLayer
  layers?: AppLayer[]
  chart?: IChartWRef
  rowId?: string
  rowIds?: (string)[]
  column?: string[]
  pixelChart?: PixelChart
}
interface ChartCustomDataset {
  // This has to be {id:number|string,label:string}, it's needed so that we could
  // place vertical annotation in correct place, yet display string labels which might not be
  // unique
  labels: string[]
  datasets: ChartDataset[]
  focusedValue: string | number | undefined
  min: number
  max: number
}

interface IAttributeChartConfig {
  title: string
  id: Db.Helper.Prj.ChartComparisonType
  show: () => boolean
}

interface XLabelMeta {
  index: number
  title: string
  value?: string
  id: string | number
}

interface ISelectedChartColumn extends Db.Helper.Geo.AttributeColumn {
  hue?: number
}

interface ESliderValueChange {
  highValue: number
  pointerType: number
  value: number
}

const MAX_POINTS = 25

@Component({
  selector: 'app-attribute-chart',
  templateUrl: './attribute-chart.component.html',
  styleUrls: ['./attribute-chart.component.scss']
})
export class AttributeChartComponent extends FormTemplate<UntypedFormGroup> implements OnInit {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>
  @ViewChild('legendTooltip', { static: true }) tooltip!: ElementRef<HTMLElement>
  _dialogs?: MatDialogRef<any>[]
  _trackDialog (dialog: MatDialogRef<any>): any { return }
  _untrackDialog (dialog: MatDialogRef<any>): any { return }
  _destroyDialogs (): any { return }
  private _chart?: Chart

  numericColumns: MergedAttributeMeta[] = []
  labelColumns: MergedAttributeMeta[] = []

  selectedColumns: ISelectedChartColumn[] = []
  labelColumn?: MergedAttributeMeta

  canBeMultiLayer = false
  canBeTimeChart = false

  charts: IAttributeChartConfig[] = [{
    title: 'Single layer - Column vs Rows', // single layer multi column for every geom
    id: Db.Helper.Prj.ChartComparisonType.LAYER_COLUMN_VS_ROWS,
    show: () => true
  }, {
    title: 'Multiple layers - Column vs Row', // multi layer single column for every layer
    id: Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROW,
    show: () => this.canBeMultiLayer && this._dialogData.rowId !== undefined
  }, {
    title: 'Multiple layers - Column vs Rows', // multi layer multi column for every geom
    id: Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROWS,
    show: () => this.canBeMultiLayer
  }, {
    title: 'Multiple layers - Time vs Value',
    id: Db.Helper.Prj.ChartComparisonType.LAYERS_TIME_VS_ROWS,
    show: () => this.canBeTimeChart
  }]
  selectedComparisonType: Db.Helper.Prj.ChartComparisonType = Db.Helper.Prj.ChartComparisonType.LAYER_COLUMN_VS_ROWS
  selectedChart: Db.Helper.Prj.ChartType = Db.Helper.Prj.ChartType.LINE

  timeScaleLayer?: AppRasterLayer

  chartTypes = [
    { value: Db.Helper.Prj.ChartType.LINE, displayName: 'Line' },
    { value: Db.Helper.Prj.ChartType.BAR, displayName: 'Bar' },
    { value: Db.Helper.Prj.ChartType.SCATTER, displayName: 'Scatter' }
  ]
  private _atMaxZoom = false

  private _startHue = 220
  private _lastHue = this._startHue

  private _dataset?: ChartCustomDataset
  maxDataPoints: number = 0
  chunkSize: number = 1

  _subscriptions = new Subscription()
  private _reloadPage = new Subject<boolean>()
  get reloadPage () {
    return this._reloadPage.asObservable()
    .pipe(
      debounceTime(450)
    )
  }

  private forceUpdateChart = false
  private _currentChartStat: {
    minLabel?: number
    maxLabel?: number
  } = {}

  manualRefresh: EventEmitter<void> = new EventEmitter<void>()
  sliderOptions?: Options
  minValue = 0
  maxValue = this.maxDataPoints
  minDate = 0
  maxDate = 0
  private _colorSelectionDialogRef?: MatDialogRef < SolidColorPickerDialogComponent >
  legendHover = false
  dragZoom = false
  logEnabled = false
  yScaleType = 'linear'

  displayTitleYAxis = false
  yAxisTitle = ''
  dashboardChartId: string = 'default'
  chartName: string = 'Untitled chart'

  get multiColumns () {
    // Allow multi columns only if it's single row or single layer chart
    // otherwise we would need 3D chart
    return this.selectedComparisonType !== Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROWS
  }

  private _labelMap: {[index: string]: XLabelMeta} = {}
  get canResetLabel () {
    return this.selectedComparisonType === Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROW
  }

  static setup (data: DialogData): MatDialogConfig {
    return {
      data
    }
  }

  get saved () {
    if (!this._dialogData) return
    return !!this._dialogData.chart
  }

  get isScatterChart () {
    return this.selectedChart === Db.Helper.Prj.ChartType.SCATTER
  }

  get isBarChart () {
    return this.selectedChart === Db.Helper.Prj.ChartType.BAR
  }

  get isDashboardChart () {
    return this.dashboardChartId !== 'default'
  }

  constructor (
    @Optional()@Inject(MAT_DIALOG_DATA) public _dialogData: DialogData,
    @Optional() protected _dialogRef: MatDialogRef<AttributeChartComponent>,
    private _dashboardService: DashboardApiService,
    private _layerService: LayerService,
    private _workspaceSerice: WorkspaceService,
    private _themeService: ThemeService,
    private _chartsService: ChartsService,
    private _alertService: AlertService,
    private _dialog: MatDialog,
    private _promptService: PromptService
  ) {
    super(new UntypedFormGroup({
      name: new UntypedFormControl(undefined, Validators.required)
    }), _dialogRef)
    if (!this.saved) this.form.controls.name.setValue(this.GenerateDefaultChartName())

    this._subscriptions.add(this.reloadPage.subscribe(value =>
      this.renderScatterPage(value)
    ))

    this.AddDefaultPresetColumns()
  }

  async ngOnInit () {
    if (this.dashboardChartId !== 'default') {
      const chart: Db.Vip.Prj.IChart | undefined = this._dashboardService.availableCharts.find(c => c.chart_id === this.dashboardChartId)
      let layer: AppLayer | undefined = undefined
      if (chart) {
        if (!this._workspaceSerice.workspaceId) {
          await this._workspaceSerice.loadWorkspace(this._dashboardService.workspaceId)
          await this._workspaceSerice.waitForWorkspaceAndView()
        }
        if (!this._layerService.groups.length) await this._layerService.loadWorkspaceLayers()

        layer = await this._layerService.loadSource(chart.layer_id)
        if (layer) await layer.waitForLoad()
        else this._alertService.log('No layer for chart')
        if (chart.name) this.chartName = chart.name
      }

      if (layer && chart) {
        const dataobj: DialogData = ({ 'layer': layer, 'chart' : chart as IChartWRef })
        this._dialogData = dataobj
      } else {
        this._alertService.log('No chart object')
      }
    }

    let labelColumn: string | undefined

    this.timeScaleLayer = (this._dialogData.layer instanceof AppRasterLayer &&
    this._dialogData.layer.isTimeSeries) ?
    this._dialogData.layer : undefined

    if (this.timeScaleLayer) {
      this.yAxisTitle = (this.timeScaleLayer.renderedSource && this.timeScaleLayer.renderedSource.band_meta &&
        this.timeScaleLayer.renderedSource.band_meta[0].units) ?
        this.timeScaleLayer.renderedSource.band_meta[0].units :
        ''
      if (this.yAxisTitle) this.displayTitleYAxis = true
    }

    if (this._dialogData.chart) {
      const chart = this._dialogData.chart
      if (this._dialogData.chart.comparison_type === Db.Helper.Prj.ChartComparisonType.LAYERS_TIME_VS_ROWS) {
        const groups = this._layerService.groups
        const layersLoaded = groups.map(async g => {
          const res = g.layers.find(l => l.id === this._dialogData.chart?.layer_id)
          if (res) {
            await g.waitForLayersLoaded()
            this._dialogData.layers = g.layers
          }
        })
        await Promise.all(layersLoaded)
        this.selectedComparisonType = this._dialogData.chart.comparison_type
      }
      labelColumn = chart.label_col
      this.selectedChart = chart.type
      this.form.controls.name.setValue(chart.name)
      this._dialogData.rowId = chart.geom_id
      this._dialogData.rowIds = chart.geom_ids
      if (chart.zoom_range) {
        this._currentChartStat = {
          minLabel: chart.zoom_range.min,
          maxLabel: chart.zoom_range.max
        }
        this.minValue = chart.zoom_range.min
        this.maxValue = chart.zoom_range.max
      }
      if (chart.y_title) {
        this.yAxisTitle = chart.y_title
        if (this.yAxisTitle) this.displayTitleYAxis = true
      }
      if (chart.y_axis_type) {
        this.yScaleType = chart.y_axis_type
        if (this.yScaleType === 'logarithmic') this.logEnabled = true
      }
      if (this._dialogData.chart.pixel && !this._dialogData.pixelChart) {
        if (this._dialogData.layer) {
          const res = await this._chartsService.getChartPixelData(this._dialogData.layer, this._dialogData.chart)
          if (res) this._dialogData.pixelChart = this._chartsService.returnPixelObject(res, this._dialogData.chart)
        }
      }
    }

    const conf = this._chartsService.lastConfiguration
    // Allow selecting only numeric columns, no support for strings yet
    let numericColumn: string[] = []
    if (this._dialogData.layers) {
      this._dialogData.layers.forEach(l => {
        const numeric = l.tableColumns.filter(x => ['integer', 'float'].includes(x.type)).map(x => x.name) as any
        numericColumn = numericColumn.concat(numeric)
      })
    } else {
      numericColumn = this._dialogData.layer.tableColumns.filter(x => ['integer', 'float'].includes(x.type)).map(x => x.name)
    }
    let layersMergedColumns = {}
    this._dialogData.layers?.forEach(l => {
      layersMergedColumns = Object.assign({}, layersMergedColumns, l.mergedColumnMeta)
    })
    const mergedColumnsMeta = this._dialogData.layers ?
    layersMergedColumns : this._dialogData.layer.mergedColumnMeta
    this.labelColumns = []
    this.numericColumns = []

    let config: any
    for (config of Object.values(mergedColumnsMeta)) {
      if (config.prop === Columns.OlGeometry) continue

      this.labelColumns.push(config)
      if (numericColumn.includes(config.prop)) this.numericColumns.push(config)
    }

    const columns: string[] = []
    if (this._dialogData.chart) {
      columns.push(...this._dialogData.chart.selected_cols.map(a => a.name ? a.name : JSON.parse(`${a}`).name))
    } else if (this._dialogData.column) {
      if (this._dialogData.column.length) this._dialogData.column.forEach(x => columns.push(x))
    } else if (conf && !this.timeScaleLayer) {
      columns.push(...conf.selectedColumns.map(x => x.prop))
    }
    this.selectedColumns = this.numericColumns.filter(c => columns.includes(c.prop))

    this.selectedColumns.forEach(column => {
      if (this._dialogData.chart) {
        const col = this._dialogData.chart.selected_cols.find(c => {
          const name = c.name ? c.name : JSON.parse(`${c}`).name
          return name === column.prop
        })
        column.hue = col ? col.hue ? col.hue : JSON.parse(`${col}`).hue : -1
      } else if (this._dialogData.layer.preset === Db.Vip.LayerPreset.RIVER_GAUGE_READINGS) {
        // TODO: This will not work for saved charts at the moment need to find a better method
        const ids = this._dialogData.rowIds ? this._dialogData.rowIds : this._dialogData.rowId ? [this._dialogData.rowId] : []
        const features = this._dialogData.layer.notFilteredOutFts.filter(x => ids.includes(`${x.get(this._dialogData.layer.idKey)}`))
        const measureUnit = features[0]

        if (measureUnit) {
          this.displayTitleYAxis = true
          this.yAxisTitle = `River level (${measureUnit.get(Db.Fred.Station.RiverGaugeReading.MEASURE_UNIT)})`
        }

        switch (column.prop) {
          case Db.Fred.Station.RiverGauge.TYPICAL_HIGH_VALUE:
            column.hue = 28
            break
          case Db.Fred.Station.RiverGauge.TYPICAL_LOW_VALUE:
            column.hue = 0
            break
          case Db.Fred.Station.RiverGauge.MIN_VALUE:
            column.hue = 0
            break
          case Db.Fred.Station.RiverGauge.MAX_VALUE:
            column.hue = 347
            break
          default:
            break
        }
      }
    })

    // If columns list is empty, select first available
    if (!this.selectedColumns.length) {
      this.selectedColumns = [this.numericColumns[0]]
    }

    if (this.timeScaleLayer) {
      this.selectedColumns = [ {
        override: 'Pixel values',
        prop: 'Pixel values',
        hue: this._dialogData.pixelChart ? this._dialogData.pixelChart.hue : this._startHue
      }]
    }

    this.canBeMultiLayer = !!this._dialogData.layer.timeSeriesGroup
    this.canBeTimeChart = this._dialogData.layers ? !!this._dialogData.layers.length : false

    if (conf) {
      if (!labelColumn) labelColumn = conf.labelColumn && conf.labelColumn.prop
      const type = this.charts.find(x => x.id === conf.comparisonType)
      if (type && type.show()) this.selectedComparisonType = conf.comparisonType
    }

    if (labelColumn) {
      const match = this.labelColumns.find(x => x.prop === labelColumn)
      if (match) this.labelColumn = match
    } else if (this._dialogData.layer.vectorTimeSeriesConf && !this._dialogData.layer.vectorTimeSeriesConf.end_column) {
      // If it's time series with single date - default X axis to date column
      const prop = this._dialogData.layer.vectorTimeSeriesConf.column.prop
      const match = this.labelColumns.find(x => x.prop === prop)
      if (match) this.labelColumn = match
    }

    this.sliderOptions = {
      floor: this.timeScaleLayer ? this.minDate : 0,
      ceil: this.timeScaleLayer ? this.maxDate : this.maxDataPoints,
      translate: (value: number, l: LabelType): string => {
        if (isNaN(value)) return ''
        if (this.timeScaleLayer) {
          return this.calculateTimeScales(value)
        }
        const label = this._labelMap[value]
        if (isNaN(+label.title)) {
          const max = this._dataset ? this._dataset.labels.length - 1 : this.maxDataPoints
          const id = (label.index * 100 / max).toFixed(0)
          return `${id}%`
        }
        return `${label.title}`
      }
    }

    if (this._currentChartStat.maxLabel) this.renderChart(true)
    else this.renderChart()
  }

  private AddDefaultPresetColumns () {
    if (!this._dialogData) return
    if (!this._dialogData.layer.preset || !this._dialogData.column) return
    switch (this._dialogData.layer.preset) {
      case Db.Vip.LayerPreset.RIVER_GAUGE_READINGS:
        if (
          this._dialogData.column.length === 1 ||
          this._dialogData.column[0] === Db.Fred.Station.RiverGaugeReading.VALUE
        ) {
          const featuresMissingTypicalMaxMin = this.GetAttributes(this._dialogData.layer).filter(x =>
            !x[FREDGaugeMeasureGeojsonProperties.TYPICAL_LOW] ||
            !x[FREDGaugeMeasureGeojsonProperties.TYPICAL_HIGH]
          )

          const featuresMissingMaxMin = this.GetAttributes(this._dialogData.layer).filter(x =>
            !x[FREDGaugeMeasureGeojsonProperties.MIN] ||
            !x[FREDGaugeMeasureGeojsonProperties.MAX]
          )

          if (!featuresMissingTypicalMaxMin.length) {
            this._dialogData.column.push(
              FREDGaugeMeasureGeojsonProperties.TYPICAL_LOW,
              FREDGaugeMeasureGeojsonProperties.TYPICAL_HIGH
            )
          }

          if (!featuresMissingMaxMin.length) {
            this._dialogData.column.push(
              FREDGaugeMeasureGeojsonProperties.MIN,
              FREDGaugeMeasureGeojsonProperties.MAX
            )
          }
        }
        break
      default:
        break
    }
  }

  private GenerateDefaultChartName () {
    let defaultName = 'Untitled chart'
    if (!this._dialogData) return defaultName
    if (!this._dialogData.layer.vectorTimeSeriesConf) return defaultName

    const group = this._dialogData.layer.vectorTimeSeriesConf.group_composite_id_cols
    const attributes = this.GetAttributes(this._dialogData.layer)
    const first = attributes[0]
    const uniqueGroup = group.map(c => first[c])
    const allInSameGroup = !attributes.some(a => {
      for (let i = 0; i < group.length; i++) {
        if (uniqueGroup[i] !== a[group[i]]) return true
      }
    })

    if (!allInSameGroup) return defaultName

    switch (this._dialogData.layer.preset) {
      case Db.Vip.LayerPreset.RIVER_GAUGE_READINGS:
        return `Station ID: ${first[Db.Fred.Station.RiverGauge.RIVER_GAUGE_ID]}, Station name: ${first[Db.Fred.Station.RiverGauge.NAME]}, Town: ${first[Db.Fred.Station.RiverGauge.TOWN]}`
      default:
        break
    }

    const name = uniqueGroup.filter(v => v || v === 0).join(', ')
    return name.trim() || defaultName

  }

  renderChart (preserveZoom: boolean = false) {
    this._chartsService.lastConfiguration = {
      comparisonType: this.selectedComparisonType,
      type: this.selectedChart,
      selectedColumns: this.selectedColumns,
      labelColumn: this.labelColumn
    }

    Chart.register({
      id: 'testId',
      beforeDraw: function (chart: Chart) {
        const chartHeight = chart.height
        const setChart: any = chart
        const maxTicks = 5
        if (chartHeight && chartHeight < 220) {
          if (setChart.scales[chart.config.options?.scatter ? 'y-axis-1' :  'y-axis-0']) {
            setChart.scales[chart.config.options?.scatter ? 'y-axis-1' :  'y-axis-0'].options.ticks.maxTicksLimit = maxTicks
          }
        } else {
          if (setChart.scales[chart.config.options?.scatter ? 'y-axis-1' :  'y-axis-0']) {
            setChart.scales[chart.config.options?.scatter ? 'y-axis-1' :  'y-axis-0'].options.ticks.maxTicksLimit = undefined
          }
        }
        if (!chart.config.options?.bar || !chart.legend?.legendItems) return
        const regex = /1\)/gi
        chart.legend.legendItems.forEach(item => {
          if (item.strokeStyle)
          item.fillStyle = item.strokeStyle.toString().replace(regex, '0.5)')
        })
      }
    })

    const tooltips = (text) => `Click to change colour of ${text}`
    this._dataset = this.GenerateChartData(preserveZoom)
    this.maxDataPoints = this._dataset.labels.length

    if (this.maxDataPoints < 2) {
      this._trackDialog(
        this._promptService.prompt(`Selected chart cannot be plotted as it has less than 2 points.`, {
          ok: null
        })
      )
      this._dialogRef.close()
    }
    const newOptions: Options = Object.assign({}, this.sliderOptions)

    if (this.timeScaleLayer) {
      newOptions.floor = this.minDate
      newOptions.ceil = this.maxDate
      this.sliderOptions = newOptions
    } else {
      newOptions.ceil = this.maxDataPoints - 1
      this.sliderOptions = newOptions
    }

    setTimeout(() => {
      this.manualRefresh.emit()
    }, 400)

    if (!this.labelColumn) this.labelColumn = this.numericColumns.find(x => x.prop === this._dialogData.layer.idKey)

    if (this._chart) this._chart.destroy()
    this._chart = new Chart(this.canvasRef.nativeElement, {
      type: this.selectedChart,
      data: {
        labels: [],
        datasets: []
      },
      plugins: [zoomPlugin],
      options: {
        responsive: true,
        spanGaps: true,
        elements: {
          line: {
            tension: 0
          }
        },
        maintainAspectRatio: this.isDashboardChart ? false : true,
        aspectRatio: 3,
        plugins: {
          legend: {
            labels: {
              color: this._themeService.getColor('foreground'),
              font: {
                size: 14
              }
            },
            position: this.isDashboardChart ? 'right' : 'bottom',
            onHover: (event, legendItem) => {
              if (this.legendHover) {
                return
              }
              this.legendHover = true
              if (!this.tooltip) return
              this.tooltip.nativeElement.innerHTML = tooltips(legendItem.text)
              this.tooltip.nativeElement.style.left = event.x + 'px'
              this.tooltip.nativeElement.style.top = event.y + 'px'
            },
            onLeave: () => {
              this.legendHover = false
            },
            onClick: (e, legendItem) => {
              if (this._colorSelectionDialogRef) return
              this.legendItemClicked(legendItem)

            }
          },
          zoom: {
            limits: {
              x: {min: 0, max: this.maxDataPoints},
            },
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.05,
              },
              mode: 'x',
              drag: {
                enabled: this.dragZoom
              },
              onZoomComplete: this.getVisibleValues.bind(this)
            },
            pan: {
              enabled: !this.dragZoom,
              mode: 'x',
              onPanComplete: this.onChartPan.bind(this)
            }
          }
        },
        scales: {
          x: {
            grid: {
              color: this._themeService.getColor('foreground-5')
            },
            ticks: {
              color: this._themeService.getColor('foreground'),
              font: {
                size: 14
              },
              stepSize: 1,
              callback: (label: string) => {
                const conditionalLabels = this._chart?.data.labels
                if (this.isScatterChart || !conditionalLabels?.length) {
                  return this._labelMap && this._labelMap[label] && this._labelMap[label].title
                }
                else if (conditionalLabels?.length) {
                  const id = conditionalLabels[label]
                  return this._labelMap && this._labelMap[id] && this._labelMap[id].title
                }
              }
            },
            beginAtZero: true
          },
          y: {
            beginAtZero: true,
            grid: {
              color: this._themeService.getColor('foreground-5')
            },
            ticks: {
              color: this._themeService.getColor('foreground'),
              font: {
                size: 14
              },
            },
            min: this._dataset.min,
            max: this._dataset.max,
            title: {
              color: this._themeService.getColor('foreground'),
              font: {
                size: 12
              },
              display: this.displayTitleYAxis,
              text: this.yAxisTitle
            },
            type: this.yScaleType
          }
        },
        tooltips: {
          callbacks: {
            title: items => {
              return items.map(i => i.label && this._labelMap[i.label].title)
            },
            label: (tooltipItem, data) => {
              let label = ''
              if (data.datasets && tooltipItem.datasetIndex !== undefined) {
                label = data.datasets[tooltipItem.datasetIndex].label || ''
              }
              return label + ': ' + tooltipItem.yLabel
            }
          }
        }
      }
    } as ChartConfiguration)
    if (this.timeScaleLayer) this.setTimeAxis()
    this._reloadPage.next(preserveZoom)
  }

  renderScatterPage (zoomed: boolean = false) {
    if (!this._dataset || !this._chart || !this._chart.config.options?.scales) return

    if (this.maxDataPoints > MAX_POINTS && !this.timeScaleLayer) {
      let xAxis = this._chart.config.options.scales.x
      if (zoomed && xAxis) {
        const minLabelIdx = this._currentChartStat.minLabel || 0
        const maxLabelIdx = this._currentChartStat.maxLabel !== undefined ? this._currentChartStat.maxLabel : this._dataset.labels.length

        this.minValue = minLabelIdx
        this.maxValue = maxLabelIdx
        this.maxDataPoints = maxLabelIdx - minLabelIdx
        xAxis.min = `${minLabelIdx}`
        xAxis.max = `${maxLabelIdx}`
      }

      this.chunkSize = Math.floor(this.maxDataPoints / MAX_POINTS) || 1
      if (this.chunkSize === 1) {
        if (this._atMaxZoom) {
          if (this.forceUpdateChart) {
            this.setAllDataAndUpdateChart(zoomed)
          }
          this.forceUpdateChart = false
          return
        }
        this._atMaxZoom = true
      } else {
        this.forceUpdateChart = false
        this._atMaxZoom = false
      }
      const labels: string[] = this.getSamplingData(this._dataset.labels.map(x => +x)).map(x => `${x}`)
      let datasets = this._dataset.datasets.map(d => ({
        ...d,
        data: d.data ? this.getSamplingData(d.data) : []
      }))
      if (this.isScatterChart) {
        datasets = datasets.map(d => ({
          ...d,
          data: d.data ? this.getScatterFormatData(d.data, labels) : []
        }))
      }

      this._chart.data = {
        labels,
        datasets
      }
      if (zoomed && xAxis) {
         // find nearest min
        const minLabelIndex = this._currentChartStat.minLabel === undefined ? -1 : this._currentChartStat.minLabel
        if (!labels.includes(`${this._currentChartStat.minLabel}`)) {
          const newMin = this.findNearestMin(labels, minLabelIndex)
          xAxis.min = newMin
          this.minValue = +newMin
        }
         // find nearest max
        const maxLabelIndex = this._currentChartStat.maxLabel === undefined ? this._dataset.labels.length : this._currentChartStat.maxLabel
        if (!labels.includes(`${this._currentChartStat.maxLabel}`)) {
          const newMax = this.findNearestMax(labels, maxLabelIndex)
          xAxis.max = newMax
          this.maxValue = +newMax
        }
      }

      this._chart.update()
      return
    }

    this.setAllDataAndUpdateChart(zoomed)
  }

  private setAllDataAndUpdateChart (zoomed) {
    if (!this._dataset || !this._chart) return
    let datasets = this._dataset.datasets
    if (this.isScatterChart) {
      datasets = datasets.map(d => ({
        ...d,
        data: d.data ? this.getScatterFormatData(d.data, this._dataset ? this._dataset.labels : []) : []
      }))
    }
    this._chart.data = {
      labels: this._dataset.labels,
      datasets
    }
    if (zoomed) {
      this.panChartToPoints(this._dataset.labels)
    } else {
      this.minValue = this.timeScaleLayer ? this.minDate : 0
      this.maxValue = this.timeScaleLayer ? this.maxDate : this._dataset.labels.length
    }
    this._chart.update()
  }

  private GetAttributes (l: AppLayer) {
    let attributes = l.notFilteredOutFts.map(f => f.getProperties())

    if (this._dialogData.rowIds && this._dialogData.rowIds.length) {
      const ids = this._dialogData.rowIds
      attributes = attributes.filter(x => ids.includes(`${x[this._dialogData.layer.idKey]}`))
    }

    return attributes
  }

  private GenerateChartData (zoomed = false): ChartCustomDataset {
    this._lastHue = this._startHue
    this._labelMap = {}
    let labels: {
      id: number | string
      label: string
    }[] = []
    let datasets: ChartDataset[] = []
    let focusedId: string | number | undefined
    let min = 0
    let max = 1

    const rowLabel = this.labelColumn ? this.labelColumn.prop : this._dialogData.layer.idKey
    let dateRowLabels: string[] = []

    let attributes: any = []
    if (this._dialogData.layers) {
      this._dialogData.layers.forEach(l => {
        const layerAttributes = this.GetAttributes(l)
        const singleRow = layerAttributes[0]
        for (let key in singleRow) {
          const dateColumn = typeof singleRow[key] === 'string' && singleRow[key].match(/^\d{4}[-\/.]\d{1,2}[-\/.]\d{1,2}$/)
          if (dateColumn) dateRowLabels.push(key)
        }
        attributes = attributes.concat(layerAttributes)
      })
    } else {
      attributes = this.GetAttributes(this._dialogData.layer)
    }

    const attribute = attributes.find(a => `${a[this._dialogData.layer.idKey]}` === this._dialogData.rowId)

    if (this.selectedComparisonType === Db.Helper.Prj.ChartComparisonType.LAYER_COLUMN_VS_ROWS) {
      if (this.timeScaleLayer) {
        const pixelValues = this._dialogData.pixelChart && this._dialogData.pixelChart.pixel_values

        labels = pixelValues ? pixelValues.map((p, i) => ({
          id: i,
          label: p.label ? p.label : ''
        })) : []

        let focusValue = this._dialogData.pixelChart && this._dialogData.pixelChart.focused_value
        labels.map(x => {
          if (x.label === focusValue) focusedId = x.id
        })

        const data = pixelValues ? pixelValues.map(p => p.value || 0) : [0]
        const dataset: ChartDataset | undefined = {
          label: this.selectedColumns[0].prop,
          ...this.GetLineStyle((label) => {
            const labelmap = this._labelMap
            let foucusedLabelId
            Object.keys(this._labelMap).forEach(function (key) {
              if (labelmap[key].title === label) {
                foucusedLabelId = labelmap[key].title
              }
            })
            return !!label && this._labelMap[foucusedLabelId].id === focusedId
          }, this.selectedColumns[0].hue),
          data
        }

        min = Math.floor(Math.min(...data) / 10) * 10
        max = Math.ceil(Math.max(max, ...data) / 10) * 10
        if(dataset) datasets.push(dataset)
      } else {
        focusedId = attribute && attribute[this._dialogData.layer.idKey]
        labels = attributes.map(c => ({
          id: c[this._dialogData.layer.idKey],
          label: c[rowLabel] || '-'
        }))

        for (const column of this.selectedColumns) {
          const data = attributes.map(c => c[column.prop] || 0)
          const dataset: ChartDataset = {
            label: column.override || column.prop,
            ...this.GetLineStyle(label => !!label && this._labelMap[label].id === focusedId, column.hue),
            data
          }

          min = Math.min(min, ...data)
          max = Math.max(max, ...data)
          datasets.push(dataset)
        }
      }
    } else if (this.selectedComparisonType === Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROW) {
      focusedId = this._dialogData.layer.title

      const group = this._layerService.groups.find(x => x.id === this._dialogData.layer.timeSeriesGroup)
      if (group) {
        labels = []
        const columnsData: {
          [key: string]: number[]
        } = {}
        const layers = group.timeSeriesLayers.map(l => ({
          layer: l,
          attributes: l === this._dialogData.layer ? attributes : this.GetAttributes(l)
        }))

        for (const layer of layers) {
          const match = layer.attributes.find(x => `${x[this._dialogData.layer.idKey]}` === this._dialogData.rowId)

          for (const column of this.selectedColumns) {
            if (!columnsData[column.prop]) columnsData[column.prop] = []
            columnsData[column.prop].push((match && match[column.prop]) || 0)
          }

          labels.push({
            id: layer.layer.title,
            label: this.labelColumn ? (match && match[this.labelColumn.prop]) || '-' : layer.layer.title
          })
        }

        for (const column of this.selectedColumns) {
          const data = columnsData[column.prop]
          const dataset: ChartDataset = {
            label: column.override || column.prop,
            ...this.GetLineStyle(label => !!label && this._labelMap[label].id === focusedId, column.hue),
            data
          }

          min = Math.min(min, ...data)
          max = Math.max(max, ...data)
          datasets.push(dataset)
        }
      }
    } else if (this.selectedComparisonType === Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROWS) {
      focusedId = attribute && attribute[this._dialogData.layer.idKey]

      const group = this._layerService.groups.find(x => x.id === this._dialogData.layer.timeSeriesGroup)
      if (group) {
        const layers = group.timeSeriesLayers.map(l => ({
          layer: l,
          attributes: l === this._dialogData.layer ? attributes : this.GetAttributes(l)
        }))

        if (this._dialogData.rowIds) {
          if (!this.labelColumn) {
            labels = this._dialogData.rowIds.map(id => ({ id, label: id }))
          } else {
            labels = this._dialogData.rowIds.map(id => attributes.find(a => `${a[this._dialogData.layer.idKey]}` === id))
            .filter(l => !!l).map((a: any) => ({
              id: a[this._dialogData.layer.idKey],
              label: a[rowLabel] || '-'
            }))
          }
        } else {
          for (const l of layers) {
            for (const a of l.attributes) {
              if (labels.find(u => u.id === a[this._dialogData.layer.idKey])) continue
              labels.push({
                id: a[this._dialogData.layer.idKey],
                label: a[rowLabel] || '-'
              })
            }
          }
        }

        for (const layer of layers) {
          const data = labels.map(u => {
            const match = layer.attributes.find(a => a[this._dialogData.layer.idKey] === u.id)
            return match && match[this.selectedColumns[0].prop] || 0
          })
          const dataset: ChartDataset = {
            label: layer.layer.title,
            ...this.GetLineStyle(label => !!label && this._labelMap[label].id === focusedId, this.selectedColumns[0].hue),
            data
          }

          min = Math.min(min, ...data)
          max = Math.max(max, ...data)
          datasets.push(dataset)
        }

      }
    } else if (this.selectedComparisonType === Db.Helper.Prj.ChartComparisonType.LAYERS_TIME_VS_ROWS) {
      focusedId = attribute && attribute[this._dialogData.layer.idKey]
      labels = attributes.map(c => {
        const label = dateRowLabels.filter(label => c[label])[0]
        return {
          id: c[this._dialogData.layer.idKey],
          label: c[label] || '-'
        }
      })

      for (const column of this.selectedColumns) {
        let dateDict = {}
        attributes.forEach(c => {
          const label = dateRowLabels.filter(label => c[label])[0]
          const value = c[column.prop] || 0
          if (dateDict[c[label]]) {
            dateDict[c[label]] = dateDict[c[label]] + value
          } else {
            dateDict[c[label]] = 0.0001
          }
        })
        const data: number[] = Object.values(dateDict)
        labels = Object.keys(dateDict).map(k => {
          return {
            id: k,
            label: k
          }
        })
        const dataset: ChartDataset = {
          label: column.override || column.prop,
          ...this.GetLineStyle(label => !!label && this._labelMap[label].id === focusedId, column.hue),
          data
        }

        min = Math.min(min, ...data)
        max = Math.max(max, ...data)
        datasets.push(dataset)
      }
    }

    let focusedValue: string | undefined
    const xLabels: string[] = []
    for (const l of labels) {
      let title = l.label
      let value = l.label
      if (this.labelColumn && this.labelColumn.transformPipe) {
        title = this.labelColumn.transformPipe.transform(title)
      } else if (this.isDateColumn(l.label)) {
        title = moment.parseZone(l.label).format('DD-MM-YY HH:mm')
      } else if (this.timeScaleLayer) {
        title = l.label
      } else if (l.label.length > 10) {
        title = `${l.label.slice(0, 9)}..`
      }
      const xLabel = this.timeScaleLayer ? l.label : `${labels.indexOf(l)}`

      if (focusedId !== undefined && focusedId === l.id) focusedValue = xLabel
      xLabels.push(xLabel)

      this._labelMap[xLabel] = {
        index: this.timeScaleLayer ? +l.id : +xLabel,
        title,
        value,
        id: l.id
      }
    }
    if (this.timeScaleLayer) {
      this.minDate = moment(xLabels[0]).toDate().getTime()
      this.maxDate = moment(xLabels[xLabels.length - 1]).toDate().getTime()
    }

    if (!zoomed) {
      this.minValue = this.timeScaleLayer ? this.minDate : 0
      this.maxValue = this.timeScaleLayer ? this.maxDate : labels.length
    }
    return {
      labels: xLabels,
      datasets,
      focusedValue,
      min,
      max: this.timeScaleLayer ? max : CommonUtil.roundToNearest(max)
    }
  }

  private GetLineStyle (selected: (label?: string) => boolean, selectedColor: number = -1) {
    const hue = selectedColor > -1 ? selectedColor : this._lastHue
    this._lastHue -= 30
    if (this._lastHue < 30) this._lastHue = 340

    const lightness = hue === 0 ? 100 : 50
    const saturation = hue === 0 ? 0 : 100

    const color = (context: any, type: 'border' | 'fill') => {
      const pointLightness = lightness === 100 ? lightness : 70

      return `hsla(${hue}, ${saturation}%, ${pointLightness}%, 0.5)`
    }

    const pointRadius = (context: any): number => {
      let radius: number = 5
      const chartType = context.chart.config.type
      const preset = this._dialogData.layer.preset
      const changePointRadius = (preset === Db.Vip.LayerPreset.RIVER_GAUGE_READINGS && chartType !== 'scatter')
      if (changePointRadius) {
        if (context.dataset.label !== 'Water level') radius = 0
      }
      if (this.timeScaleLayer && this.maxDataPoints > MAX_POINTS) {
        if (chartType !== 'scatter') radius = 3
      }
      return radius
    }

    const style: any = {
      borderColor: `hsla(${hue}, ${saturation}%, ${lightness}%, 1)`,
      pointBorderColor: (context) => color(context, 'border'),
      backgroundColor: this.isBarChart ? (context) => color(context, 'fill') : 'rgba(0, 0, 0, 0.05)',
      pointBackgroundColor: (context) => color(context, 'fill'),
      pointHoverRadius: 9,
      pointRadius: (context) => pointRadius(context),
      borderWidth: 1,
      hoverBorderWidth: 2
    }

    return style
  }

  columnSelectionChange (e: MatSelectChange) {
    const columns: Db.Helper.Geo.AttributeColumn[] = Array.isArray(e.value) ? e.value : [e.value]

    this.selectedColumns = columns.map(x =>
      this.numericColumns.find(y => y.prop === x.prop)
    ).filter(x => !!x) as Db.Helper.Geo.AttributeColumn[]

    this.forceUpdateChart = true
    this.renderChart(true)
  }

  comparisonChange (e: MatSelectChange) {
    if (
      e.value === Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROWS ||
      this.selectedComparisonType === Db.Helper.Prj.ChartComparisonType.LAYERS_COLUMN_VS_ROWS
    ) {
      // If x axis values will change - reset pagination, so that it would get recalculated

      // TODO: zoom to be handled in case of multiple layers
      this.maxDataPoints = this._dataset ? this._dataset.labels.length : this.maxDataPoints
      this._reloadPage.next(false)
    }
    this.selectedComparisonType = e.value

    this.renderChart()
  }

  labelChange (e: MatSelectChange) {
    this.labelColumn = e.value
    this.forceUpdateChart = true
    this.renderChart(true)
  }

  saving = false
  async saveChart () {
    if (this.saving) return

    this.saving = true

    const selectedCols: Db.Helper.Prj.SelectedColumn[] = this.selectedColumns[0] ?
    this.selectedColumns.map(x => {
      return { name: x.prop, hue: x.hue || -1 }
    }) : []
    const zoomRange: Db.Helper.Prj.ZoomRange | undefined = this._currentChartStat.maxLabel ? {
      min: this._currentChartStat.minLabel ? this._currentChartStat.minLabel : 0,
      max: this._currentChartStat.maxLabel ? this._currentChartStat.maxLabel : this.maxDataPoints
    }
    : undefined

    const chartObj = this.getchartObject(selectedCols, zoomRange)

    try {

      if (!this.form.valid) throw new AppError(`Form invalid`)

      if (this._dialogData.chart) {
        Object.assign(this._dialogData.chart, chartObj)

        await this._chartsService.orm.Chart(this._dialogData.chart.chart_id)
        .update(chartObj).run()
      } else {
        this._dialogData.chart = await this._chartsService.createChart({
          layer_id: this._dialogData.layer.id,
          ...chartObj as Required<IPUpdateChart>
        })
      }

    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }

    this.saving = false
  }

  async deleteChart () {
    if (!this._dialogData.chart) return
    if (this.saving) return

    this.saving = true

    try {
      await this._chartsService.deleteChart(this._dialogData.chart)
      this._dialogRef.close()
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }

    this.saving = false
  }

  getSamplingData (data: any[]) {

    const precision = Math.max(
      ...CommonUtil.randomArraySample(data, 100)
      .map(value => CommonUtil.countDecimals(value))
    )
    const res = this.splitToChunks(data)
    const type = typeof data.find(x => x || x === 0)
    switch (type) {
      case 'number':
        return res.map(a => (a.reduce((a, b) => a + b, 0) / a.length).toFixed(precision))
      case 'string':
      default:
        return res.map(a => a[Math.floor((a.length + 1) / 2)] || a[0])
    }
  }

  splitToChunks (array: any[]) {

    const result: any[] = []
    for (let i = 0, len = array.length; i < len; i += this.chunkSize) {
      result.push(array.slice(i, i + this.chunkSize))
    }
    return result
  }

  resetZoom () {
    if (!this._chart) return
    this._chart['resetZoom']()
    this.maxDataPoints = this._dataset ? this._dataset.labels.length : this.maxDataPoints
    this.minValue = this.timeScaleLayer ? this.minDate : 0
    this.maxValue = this.timeScaleLayer ? this.maxDate : this.maxDataPoints
    this._currentChartStat = { minLabel: this.timeScaleLayer ? this.minDate : 0, maxLabel: this.timeScaleLayer ? this.maxDate : this.maxDataPoints }
    this.renderChart()
  }

  getVisibleValues ({ chart }) {
    const xScaleKey = Object.keys(chart.scales).find(k => k.startsWith('x'))
    const x = xScaleKey && chart.scales[xScaleKey]

    if (this.timeScaleLayer) {
      this._currentChartStat = { minLabel: x.min, maxLabel: x.max }
      this.minValue = x.min
      this.maxValue = x.max
    } else {
      const maxLabel = this._labelMap[Math.round(x.options.ticks.max)] || this._labelMap[Math.round(x.max)]
      const minLabel = this._labelMap[Math.round(x.options.ticks.min)] || this._labelMap[Math.round(x.min)]

      this._currentChartStat = { minLabel: minLabel.index, maxLabel: maxLabel.index }

      if (this.isScatterChart && minLabel.index === maxLabel.index) {

      // prevent zooming in too much
        x.options.ticks.min = this.findNearestMin(chart.data.labels, minLabel.index)

        x.options.ticks.max = this.findNearestMax(chart.data.labels, maxLabel.index)
        chart.update()
        return
      }

      this.maxDataPoints = (chart.data.labels && chart.data.labels.length) ?
      chart.data.labels.length
      :
      this.maxDataPoints
      this._reloadPage.next(true)
    }

  }

  onChartTypeChange (val: Db.Helper.Prj.ChartType) {
    this.selectedChart = val
    this.maxDataPoints = this._dataset ? this._dataset.labels.length : this.maxDataPoints
    this.forceUpdateChart = true
    this.renderChart(true)
  }

  onDragZoomChanged (e) {
    this.forceUpdateChart = true
    this.renderChart(true)
  }

  onScaleTypeChanged (e) {
    this.yScaleType = this.logEnabled ? 'logarithmic' : 'linear'
    this.forceUpdateChart = true
    this.renderChart(true)
  }

  getScatterFormatData (data: any[], labels: string[]) {
    if (!data) return []
    const result: any[] = []
    data.forEach((a, idx) => {
      // TODO: Initial implementation was '+this._labelMap[labels[idx]].id'
      // which is incorrect as id values won't always be numbers,
      // however latest fix, while being correct - breaks 'label.index/title' access in this file
      if (labels[idx]) {
        result.push({ x: this.timeScaleLayer ? moment(this._labelMap[labels[idx]].title).toDate().getTime() : +this._labelMap[labels[idx]].index, y: a })
      }
    })
    return result
  }

  legendItemClicked (legendItem: LegendItem) {
    // open color palette
    const idx = this.selectedColumns.findIndex(column => column.override === legendItem.text) || 0
    const currentHue = this.selectedColumns[idx].hue as number > -1 ? this.selectedColumns[idx].hue as number : this._startHue
    const currentColor = `#${convert.hsv.hex([currentHue, 100, 70])}`
    this._colorSelectionDialogRef = this._dialog.open(SolidColorPickerDialogComponent, SolidColorPickerDialogComponent.setOptions(currentColor))
    this._colorSelectionDialogRef.afterClosed().subscribe(res => {
      this._colorSelectionDialogRef = undefined
      if (res) {
        const hue = convert.hex.hsv(res.replace('#', ''))[0]
        const index = legendItem.datasetIndex as number
        if (this._dataset && this._dataset.datasets) {
          this._dataset.datasets[index] = {
            ...this._dataset.datasets[index],
            ...this.GetLineStyle(label => label === legendItem.text, hue) // ?
          }
          this.forceUpdateChart = true
          this._reloadPage.next(true)
        }
        this.selectedColumns[idx].hue = hue
      }
    })
  }

  onChartPan ({ chart }) {
    const xScaleKey = Object.keys(chart.scales).find(k => k.startsWith('x'))
    const x = xScaleKey && chart.scales[xScaleKey]
    if (this.timeScaleLayer) {
      this._currentChartStat = { minLabel: x.min, maxLabel: x.max }
      this.minValue = x.min
      this.maxValue = x.max
    } else {
      const maxLabel = this._labelMap[Math.round(x.max)]
      const minLabel = this._labelMap[Math.round(x.min)]

      this._currentChartStat = { minLabel: minLabel.index, maxLabel: maxLabel.index }
      this.minValue = minLabel.index
      this.maxValue = maxLabel.index
    }
  }

  sliderValueChange (e: ESliderValueChange) {
    this.panChartToPoints([], e.value, e.highValue)
    if (this.chunkSize === 1) this.forceUpdateChart = true
    this._reloadPage.next(true)
  }

  panChartToPoints (labels: any[], min ?: number, max ?: number) {

    if (!this._chart || !this._chart.data || !this._chart.data.labels || !this._chart.options.scales) return
    labels = labels.length ? labels : this._chart.data.labels

    const x = this._chart.options.scales.x

    if (this.timeScaleLayer && this._chart.options
       && this._chart.options.scales
       && this._chart.options.scales.x
       && this._chart.options.scales.x[0].ticks) {
      this._chart.options.scales.x[0].ticks.min = min ? min : this._currentChartStat.minLabel
      this._chart.options.scales.x[0].ticks.max = max ? max : this._currentChartStat.maxLabel
      this.minValue = min ? min : this.minValue
      this.maxValue = max ? max : this.maxValue
      this._currentChartStat.minLabel = min ? min : this._currentChartStat.minLabel
      this._currentChartStat.maxLabel = max ? max : this._currentChartStat.maxLabel
    } else {
      if (!x) return
      const idxMin = typeof min === 'number' ? min : Math.floor(this._currentChartStat.minLabel as number)
      const newMin = this.findNearestMin(labels, idxMin)

      const idxMax = typeof max === 'number' ? max : Math.floor(this._currentChartStat.maxLabel as number)
      const newMax = this.findNearestMax(labels, idxMax)

      x.min = newMin
      x.max = newMax

      this._currentChartStat.minLabel = newMin
      this._currentChartStat.maxLabel = newMax

      this.minValue = idxMin
      this.maxValue = idxMax

      this.maxDataPoints = labels.length
    }
  }

  findNearestMin (labels: any[], idx: number) {
    return labels.find(label => {
      return this._labelMap[label].index >= idx
    }) || labels[0] // OR case should not occur
  }

  findNearestMax (labels: any[], idx: number) {
    return CommonUtil.find<string>(
      labels, label => {
        return this._labelMap[label].index <= idx
      }, true
    ) || labels[labels.length - 1] // OR case should not occur
  }

  isDateColumn (dateString: string): Boolean {
    if (!this.labelColumn || !this.labelColumn.prop) return false
    if (!this.labelColumn.prop.toLowerCase().includes('date') || !moment(dateString).isValid()) return false
    return true
  }

  getchartObject (selectedCols: Db.Helper.Prj.SelectedColumn[], zoomRange: Db.Helper.Prj.ZoomRange | undefined): IPUpdateChart {
    let chartObj: IPUpdateChart = {
      label_col: this.labelColumn && this.labelColumn.prop,
      comparison_type: this.selectedComparisonType,
      selected_cols: selectedCols,
      type: this.selectedChart,
      name: this.form.controls.name.value,
      geom_id: this._dialogData.rowId ? this._dialogData.rowId : undefined,
      geom_ids: this._dialogData.rowIds,
      zoom_range: zoomRange,
      y_title: this.displayTitleYAxis ? this.yAxisTitle : undefined,
      y_axis_type : this.yScaleType ? this.yScaleType : 'linear'
    }
    if (this.timeScaleLayer && this._dialogData.pixelChart) {
      chartObj.pixel = this.setPixelChartObject()
    }
    return chartObj
  }

  setPixelChartObject (): Db.Helper.Prj.Pixel | undefined {
    if (!this._dialogData.pixelChart) return
    const pixelChartData = {
      coordinates : this._dialogData.pixelChart.coordinates,
      resolution: this._dialogData.pixelChart.resolution,
      focused_value: this._dialogData.pixelChart.focused_value,
      hue : this.selectedColumns[0].hue ? this.selectedColumns[0].hue : this._startHue
    }
    return pixelChartData
  }

  setTimeAxis () {
    if (!this._dataset) return
    const labels = this._dataset.labels
    const minDate = moment(labels[0]).toDate().getTime()
    const maxDate = moment(labels[labels.length - 1]).toDate().getTime()
    if (this._chart && this._chart.options.scales) {
      let chart: any = this._chart
      chart.options.scales.x = [{
        type:  'time',
        time: {
          displayFormats: {
            day: 'll',
            month: 'MMM YYYY'
          }
        },
        grid: {
          color: this._themeService.getColor('foreground-5')
        },
        ticks: {
          min: minDate,
          max: maxDate,
          color: this._themeService.getColor('foreground'),
          font: {
            size: 12
          },
          source: 'auto'
        }
      }]
      chart.options.pan = {
        enabled: !this.dragZoom,
        mode: 'x',
        speed: 50,
        rangeMax: {
          x: maxDate
        },
        rangeMin: {
          x: minDate
        },
        onPanComplete: this.onChartPan.bind(this)
      }
      chart.options.zoom = {
        enabled: true,
        mode: 'x',
        speed: 0.05,
        drag: this.dragZoom,
        rangeMax: {
          x: maxDate
        },
        rangeMin: {
          x: minDate
        },
        onZoomComplete: this.getVisibleValues.bind(this)
      }
    }
  }

  calculateTimeScales (value: number): string {
    const timeRange = this.maxDate - this.minDate
    switch (value) {
      case this.minDate :
        return '0%'
      case this.maxDate :
        return '100%'
      case this.minValue :
        return `${((this.minValue - this.minDate) / timeRange * 100).toFixed(0)}%`
      case this.maxValue :
        return `${((this.maxValue - this.minDate) / timeRange * 100).toFixed(0)}%`
      default:
        return '0%'
    }
  }

  downloadCanvas() {
    const canvasElement = this.canvasRef.nativeElement
    const dataUrl = canvasElement.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = dataUrl
    const title  = this.form.controls.name.value ? this.form.controls.name.value : 'Attributes Chart'
    link.download = `${title}.png`

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  convertChartDataToCSV(chartData: ChartDataset[]) {
    const csvData:any[] = []
    const labels:string[] = []
    const labelmap = this._labelMap
    Object.keys(this._labelMap).forEach(function (key) {
      if (labelmap[key].title) {
        // Check if the value length is greater than 10,
        // and then use the value instead of the title because the title is trimmed for display.
        const value = labelmap[key].value
        const title = labelmap[key].title

        let label = title

        if (value && value.length > 10) label = value
        labels.push(label)
      }
    })
    const rowHeader = ['Label', ...labels]
    csvData.push(rowHeader)

    chartData.forEach(dataset => {
      const row = [dataset.label, ...dataset.data]
      csvData.push(row)
    })

    return Papa.unparse(csvData)
  }

  downloadCSV() {
    const chartData = this._dataset
    if(!chartData || !chartData.datasets || !chartData.labels) return
    let csv = this.convertChartDataToCSV(chartData.datasets)
    const filename = this.form.controls.name.value ? `${this.form.controls.name.value}.csv` : 'chart-data.csv'
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    const data = encodeURI(csv)
    const link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

}
