<mat-form-field class="st-mat-field-clear" [class.invalid]="!valid">
  <mat-label *ngIf="title" [owlDateTimeTrigger]="owl">{{title}}</mat-label>
  <div class="time-picker-row" [owlDateTimeTrigger]="owl" [class.expired-date]="dateInactive"
  [matTooltip]="dateInactive ? expiryNote : undefined">
    <input matInput [owlDateTime]="owl" [disabled]="disabled" [(ngModel)]="value" [min]="min" [max]="max">
    <mat-icon *ngIf="showTrigger" [owlDateTimeTrigger]="owl">access_time</mat-icon>
  </div>
  <owl-date-time #owl [disabled]="disabled"></owl-date-time>
  <mat-icon *ngIf="value && !disabled" (click)="$event.stopPropagation(); value = undefined">clear</mat-icon>
</mat-form-field>
