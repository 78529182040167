import { Component, Input } from '@angular/core'
import { Db } from '@vip-shared/models/db-definitions'
import { ThemeService } from '@services/core/theme/theme.service'

@Component({
  selector: 'app-theme-palette-preview',
  templateUrl: './theme-palette-preview.component.html',
  styleUrls: ['./theme-palette-preview.component.scss']
})
export class ThemePalettePreviewComponent {
  @Input() theme: Db.Vip.Cst.ICustomer['theme']
  @Input() previewButton = true
  get bkPrimary () {
    return this.theme && this.theme.colors && this.theme.colors['background-primary']
  }

  get bkSecondary () {
    return this.theme && this.theme.colors && this.theme.colors['background-secondary']
  }

  get bkTertiary () {
    return this.theme && this.theme.colors && this.theme.colors['background-tertiary']
  }

  constructor (
    private _themeService: ThemeService
  ) {}

  preview () {
    if (this.theme) this._themeService.applyTheme(this.theme, true)
  }
}
