<h2 [ngClass]="isDashboardChart?'mat-dialog-title mat-dashboard-title':'mat-dialog-title'">
  <mat-icon> show_chart </mat-icon>
  {{title}}
</h2>
<div [ngClass]="isDashboardChart ? 'dashboard-chart' : 'mat-dialog-content'">
  <div class="filters">
    <div *ngIf="!isDashboardChart && !staticLayer" class="selected-range">
      <button mat-button (click)="openDateSelection('selected')">
        {{selectedTimeRange[0].toDate() | date:'dd MMM yyyy HH:mm'}} - {{selectedTimeRange[1].toDate() | date:'dd MMM yyyy HH:mm'}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <mat-checkbox [(ngModel)]="logEnabled" [ngModelOptions]="{standalone: true}" (change)="onScaleTypeChanged($event)"
      matTooltip="Can be linear or logarithmic">Log scale</mat-checkbox>
  </div>
  <div class="canvas-wrapper">
    <canvas #canvas>
    </canvas>
  </div>
</div>

<div *ngIf="!isDashboardChart" class="st-button-row">
  <button color="primary" mat-button mat-dialog-close>Close</button>
  <!-- Will need to add the png download when api is worked on -->
  <app-submit-button text="DOWNLOAD CSV" (click)="downloadCSV()"></app-submit-button>
  <app-submit-button text="DOWNLOAD PNG" (click)="downloadCanvas()"></app-submit-button>
</div>

<app-loader-backdrop *ngIf="loading" [loadingText]="'Fetching chart data'"></app-loader-backdrop>
