import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { HTTP_CODE } from '@vip-shared/models/static-defs'
import { Router } from '@angular/router'
import { JwtService } from '@services/core/jwt/jwt.service'
import { AlertService } from '@services/core/alert/alert.service'
import { IApiResponse } from '@vip-shared/interfaces/api/response'
import { ApiRoute } from '@vip-shared/models/api-orm'
import AppError, { handleError } from '@core/models/app-error'

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private _429ErrDisplayed = false
  private _sessionExpiredDisplayed = false
  private _0ErrDisplayed = false
  private _connTimeoutDisplayed = false
  constructor (
        private _router: Router,
        private _jwtService: JwtService,
        private _alertService: AlertService
    ) {
    _alertService.alertClosed.subscribe(() => {
      this._429ErrDisplayed = false
      this._sessionExpiredDisplayed = false
      this._0ErrDisplayed = false
      this._connTimeoutDisplayed = false
    })
  }

  async handle <T extends Error> (error: HttpErrorResponse | T, proxy?: boolean, pingRoute?: ApiRoute<string>): Promise<IApiResponse> {
    if (!(error instanceof HttpErrorResponse)) {
      const message = error.message.toLowerCase().includes('timeout') ? 'HTTP request timeout. Server took to long to respond.' : 'Internal browser error during HTTP request.'
      console.error('Client side error during HTTP request: ', error)
      return Promise.reject(new AppError(message))
    }
    const serverError = error.error
    const allowRedirect = !proxy && (serverError.redirectOnError !== undefined ? serverError.redirectOnError : true)

    switch (error.status) {
      case HTTP_CODE.UNAUTHORIZED:
        this._jwtService.clearJWT()
        if (allowRedirect) this._router.navigate(['/login'])
        if (!this._sessionExpiredDisplayed) {
          this._sessionExpiredDisplayed = true
          this._alertService.log(error.error.message || 'Unauthorized access or session expired.')
        }
        break
      case HTTP_CODE.TOO_MANY_REQUESTS:
        if (!this._429ErrDisplayed) {
          this._429ErrDisplayed = true
          this._alertService.log('Too many requests. Try again later.')
        }
        break
      case HTTP_CODE.FORBIDDEN:
      case HTTP_CODE.NOT_FOUND:
        if (allowRedirect) this._router.navigate(['/error/not-found'])
        break
      case 0:
        let serverLive = false
        if (pingRoute) {
          try {
            await pingRoute.run()
            serverLive = true
          } catch (error: any) {
            serverLive = false
          }
        }

        if (!serverLive) {
          if (allowRedirect) {
            this._jwtService.clearJWT()
            this._router.navigate(['/login'])
          }

          if (!this._connTimeoutDisplayed) {
            this._connTimeoutDisplayed = true
            this._alertService.log('Connection to server timeout.')
          }
        } else if (!this._0ErrDisplayed) {
          this._jwtService.clearJWT()
          this._0ErrDisplayed = true
          if (allowRedirect) this._router.navigate(['/login'])
          this._alertService.log('Server not found or connection refused.')
        }
        break
    }

    if (serverError instanceof ProgressEvent) {
      handleError(serverError)
      return Promise.reject(new AppError('Server error.'))
    } else if (serverError instanceof Blob) {
      // TODO: check if this logic need refactoring
      // This will be hit if ng http responseType is set to 'blob'
      const error = await new Promise<IApiResponse>((res, rej) => {
        const reader = new FileReader()
        reader.onload = () => {
          if (reader.result && serverError.type === 'application/json') {
            rej(JSON.parse(reader.result.toString()) || { message: 'Failed to get error.' })
          } else {
            rej({ message: 'Failed to get error.' })
          }
        }
        reader.readAsText(serverError)
      })
      handleError(error)
      return Promise.reject(new AppError(error.message as string || 'Unknown error.'))
    } else if (typeof serverError === 'object') {
      const error = serverError.error ? serverError.error : serverError
      return Promise.reject(new AppError(error.message || 'Unknown error.', undefined, error.status))
    }
    return Promise.reject(new AppError(serverError, undefined, error.status))
  }

}
