import * as olProj from 'ol/proj'
import * as olGeom from 'ol/geom'
import * as olSphere from 'ol/sphere'
import * as olColor from 'ol/color'
import { Options as FillOptions } from 'ol/style/Fill'
import { Options as StrokeOptions } from 'ol/style/Stroke'
import { IRgba } from '@core/types'
import * as Color from 'color'
import { handleError } from '@core/models/app-error'
import { IJson } from '@vip-shared/interfaces'
import { parseString as parseXmlString } from 'xml2js'

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }

export class ConvertUtil {
  private static readonly metersInMile = 1609.344

  // Because Edge does not support new File()
  static blobToFile (source: Blob | File, fileName: string, type: string): File {
    if (!navigator.msSaveBlob) {
      return new File([source], fileName, { type })
    } else {
      const blob: any = new Blob([source], { type })
      blob.lastModifiedDate = new Date()
      blob.name = fileName
      return blob as File
    }
  }

  static geojsonToFile (json: IJson, name: string) {
    return ConvertUtil.blobToFile(
      new Blob([
        JSON.stringify(json)
      ])
    , name, 'application/vnd.geo+json')
  }

  static fileToBase64 (file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  static dataURLtoFile (dataUrl: string, filename: string) {

    const arr = dataUrl.split(',')
    const match = arr[0].match(/:(.*?);/)
    const mime = match && match[1] as any
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  static json2File (json: IJson, name: string): File {
    return ConvertUtil.blobToFile(
      new Blob([
        JSON.stringify(json)
      ])
      , name, 'application/vnd.geo+json'
    )
  }

  static mapRange = (value, inMin, inMax, outMin, outMax) => {
    if (inMax === inMin) return outMax
    return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
  }

  static invertIndex (arrayLength: number, index: number) {
    return arrayLength - index + 1
  }

  private static SanitizeRgb (color: IRgba) {
    const sanitized = { ...color }
    if (isNaN(color.R)) sanitized.R = 0
    if (isNaN(color.G)) sanitized.R = 0
    if (isNaN(color.B)) sanitized.R = 0
    return sanitized
  }

  static layerStyleToFillOptions (color: IRgba): FillOptions {
    return {
      color: Object.values(ConvertUtil.SanitizeRgb(color))
    }
  }

  static layerStyleToStrokeOptions (
    color: IRgba,
    width?: number
  ): StrokeOptions {

    const strokeOptions: StrokeOptions = {
      color: Object.values(ConvertUtil.SanitizeRgb(color))
    }
    if (width || width === 0) {
      strokeOptions.width = width
    }
    return strokeOptions
  }

  static flipIndex (index: number, arrayLength: number): number {
    let newIndex = index - (arrayLength - 1)
    newIndex *= -1

    return newIndex
  }

  static lngLatToE3857 (degrees: [number, number]): [number, number] {
    return olProj.transform(degrees, 'EPSG:4326', 'EPSG:3857') as [number, number]
  }

  static E3857ToLngLat (latlng: [number, number]): [number, number] {
    return olProj.transform(latlng, 'EPSG:3857', 'EPSG:4326') as [number, number]
  }

  static metersToMiles (meters: number, precision: number = 2): number {
    const miles = meters / this.metersInMile

    return +miles.toFixed(precision)
  }

  static milesToMeters (miles: number, precision: number = 2): number {
    const meters = miles * this.metersInMile

    return +meters.toFixed(precision)
  }

  static geometryToMeasureString (geom: olGeom.Polygon | olGeom.LineString | olGeom.MultiLineString | olGeom.MultiPolygon, precision: number, units = true) {
    let result = ''
    const polygonCalc = (geom: olGeom.Polygon) => +olSphere.getArea(geom).toFixed(precision)
    const lineCalc = (geom: olGeom.LineString) => +olSphere.getLength(geom).toFixed(precision)

    const roundArea = (area: number) => {
      let output: any
      if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100
      } else {
        output = Math.round(area * 100) / 100
      }

      return output + (units ? (area > 10000 ? ' km' : ' m') : '')
    }

    const roundLength = (length: number) => {
      let output: any
      if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100
      } else {
        output = Math.round(length * 100) / 100
      }

      return output + (units ? (length > 100 ? ' km' : ' m') : '')
    }

    if (geom instanceof olGeom.Polygon) {
      result = roundArea(polygonCalc(geom))
    } else if (geom instanceof olGeom.MultiPolygon) {
      result = roundArea(geom.getPolygons().reduce((sum, g) => sum + polygonCalc(g), 0))
    } else if (geom instanceof olGeom.LineString) {
      result = roundLength(lineCalc(geom))
    } else if (geom instanceof olGeom.MultiLineString) {
      result = roundLength(geom.getLineStrings().reduce((sum, g) => sum + lineCalc(g), 0))
    }

    return result
  }

  static degreesToRadians (angle: number): number {
    const radians: number = angle / (180 / Math.PI)

    return radians
  }

  static hexToRgb (hex: string, opacity = false, type: 'object' | 'array' = 'object') {
    try {
      const color = Color(hex).rgb()

      const rgba: any = {
        R: color.red(),
        G: color.green(),
        B: color.blue()
      }
      if (opacity) rgba.A = color.alpha()

      if (type === 'object') return rgba
      else return Object.values(rgba)
    } catch (error: any) {
      handleError(error)
      return
    }
  }

  static rgbToHex (rgb: IRgba | number[]) {
    try {
      const color = Color(Array.isArray(rgb) ? rgb : ConvertUtil.objectLowerCaseKeys(rgb))
      return color.hex()
    } catch (error: any) {
      handleError(error)
      return
    }
  }

  static objectLowerCaseKeys (obj: IJson) {
    const lowerCased: any = {}
    for (const k in obj) {
      lowerCased[k.toLowerCase()] = obj[k]
    }
    return lowerCased
  }

  static generateXML (
    layerName: string,
    steps: {
      color: string
      quantity: number
    }[]
  ) {
    let stepsString = ''

    steps.forEach(
      step =>
        (stepsString += `<ColorMapEntry color="${step.color}" quantity="${
          step.quantity
        }" opacity="1"/> \n`)
    )

    const xmlString = `
      <?xml version="1.0" encoding="UTF-8"?>
      <StyledLayerDescriptor xmlns="http://www.opengis.net/sld"
        xmlns:ogc="http://www.opengis.net/ogc"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld
        http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd" version="1.0.0">
        <NamedLayer>
          <Name>${layerName}</Name>
          <UserStyle>
            <Title>${layerName}</Title>
            <FeatureTypeStyle>
              <Rule>
                <RasterSymbolizer>
                  <Opacity>1.0</Opacity>
                  <ColorMap>
                    ${stepsString}
                  </ColorMap>
                </RasterSymbolizer>
              </Rule>
            </FeatureTypeStyle>
          </UserStyle>
        </NamedLayer>
      </StyledLayerDescriptor>
    `.replace(/\s{2,}/g, ' ').trim()

    return xmlString
  }

  static flatArrayTo2D (source: (number | string | boolean | null | undefined)[], count: number) {
    const old = [...source]
    const target: any[] = []
    while (old.length) target.push(old.splice(0, count))

    return target
  }

  static async xmlToJson (text: string) {
    return new Promise((resolve, reject) => {
      parseXmlString(text, (err: any, result: any) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }
}
