<h3>{{data.title}}</h3>

<div mat-dialog-content>
  <p>{{data.description}}</p>

  <app-note color="warn" *ngIf="data.sideEffect">{{data.sideEffect}}</app-note>

  <div class="st-table-overflow mat-elevation-z5">
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="optional">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Optional</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox [checked]="element.optional" [disabled]="true"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
  </div>

</div>
<div class="st-button-row">
  <button mat-raised-button color="primary" mat-dialog-close>Ok</button>
</div>
