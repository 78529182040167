import { Directive, HostListener, Output, EventEmitter, HostBinding, Input, OnInit, ElementRef } from '@angular/core'
import { Subscription, fromEvent } from 'rxjs'

@Directive({
  selector: '[appFilesDragDrop]'
})
export class FilesDragDropDirective implements OnInit {
  private _subscriptions = new Subscription()
  @Input() captureWindow: boolean = false
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilesDropped = new EventEmitter<File[]>()
  dropEventTimerSubscription?: ReturnType<typeof setTimeout>
  @HostBinding('style.opacity') private opacity = '1'
  @HostBinding('style.border') private border = '2px dashed'
  @HostBinding('style.border-color') private borderColor = 'transparent'
  @HostBinding('style.transition') private transition = 'border 0.2s, opacity 0.2s'

  constructor (private _el: ElementRef) {}
  ngOnInit () {
    const target = this.captureWindow ? window : this._el.nativeElement

    this._subscriptions.add(
      fromEvent(target, 'dragover').subscribe((evt: any) => {
        evt.preventDefault()
        evt.stopPropagation()
        this.borderColor = 'white'
        this.opacity = '0.8'
      })
    )

    this._subscriptions.add(
      fromEvent(target, 'dragleave').subscribe((evt: any) => {
        evt.preventDefault()
        evt.stopPropagation()
        this.borderColor = 'transparent'
        this.opacity = '1'
      })
    )

    this._subscriptions.add(
      fromEvent(target, 'drop').subscribe((evt: any) => {
        evt.preventDefault()
        evt.stopPropagation()
        this.borderColor = 'transparent'
        this.opacity = '1'
        if (evt.dataTransfer) {
          let files = evt.dataTransfer.files

          const filesWContent: File[] = []
          for (let i = 0; i < files.length; i++) {
            const file = files.item(i)
            // If not file, skip, or if size is 0 it's most likely a directory
            // Filesystem api not used due to lack of browser support
            // In case it improves: https://github.com/georgipeltekov/ngx-file-drop has the implementation
            if (!file || !file.size) continue
            filesWContent.push(file)
          }
          if (filesWContent.length > 0) this.onFilesDropped.emit(filesWContent)
        }
      })
    )
  }
}
