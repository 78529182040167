import { Inject, Injectable, InjectionToken } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { timeout, catchError } from 'rxjs/operators'
import { API } from '@vip-shared/interfaces/api-helper'

// eslint-disable-next-line
export const DEFAULT_TIMEOUT_TOKEN = new InjectionToken<number>('DEFAULT_TIMEOUT_TOKEN')

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor (@Inject(DEFAULT_TIMEOUT_TOKEN) protected defaultTimeout: number | null) {
  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = Number(req.headers.get(API.Headers.TIMEOUT)) || this.defaultTimeout
    if (!timeoutValue) {
      return next.handle(req)
    } else {
      return next.handle(req).pipe(timeout(timeoutValue))
    }
  }
}
