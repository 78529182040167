import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-ws-placeholder-solar',
  templateUrl: './ws-placeholder-solar.component.html',
  styleUrls: ['./ws-placeholder-solar.component.scss', '../placeholders.scss']
})
export class WsPlaceholderSolarComponent {
  @Input() extent?: [number, number, number, number]
  @Input() workspaceId?: number
}
