import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-button-toggle-field',
  templateUrl: './button-toggle-field.component.html',
  styleUrls: ['./button-toggle-field.component.scss']
})
export class ButtonToggleFieldComponent {
  @Input() label?: string

}
