<div class="st-customer-background">
  <div class="auth-card-wrapper wide">
    <mat-card class="mat-elevation-z5">
      <app-powered-by>
        <app-logo logoSelector="auth_page"></app-logo>
      </app-powered-by>

      <mat-card-content>
        <p>
          You can make your account more secure by setting up <strong>two-step verification</strong>.
          This is where we take an additional step to confirm your identity.
        </p>

        <ol style="line-height: 24px;">
          <li>Install an authenticator app, such as Google Authenticator for <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share">Android</a></li>
          <li>Scan the QR code below</li>
          <li>Enter the 6-digit code from the app into the box below, and press Confirm</li>
        </ol>

        <p *ngIf="canOptOutMFA">Alternatively, if you don't want to do this, click <strong>Opt-out</strong> below.</p>

        <img [src]="MFAQRCode" [alt]="QRCodeAltMessage" style="display: block; margin: auto;" />

        <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>

        <app-otp-input (onOtpChange)="onOtpChange($event)"></app-otp-input>

        <div class="st-button-row">
          <button
            mat-flat-button
            color="primary"
            *ngIf="canOptOutMFA"
            [disabled]="submitting"
            (click)="optoutMFA()"
          >Opt-out</button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="!canSubmit || submitting"
            (click)="submit()"
          >Confirm</button>
        </div>
      </mat-card-content>


    </mat-card>

    <app-note *ngIf="isIEOrEdge()">
      <p>
        We notice you're using Edge or IE, we recommend using
        <span class="st-highlight">Chrome</span>
        as performance will be much improved.<br>
        Using Edge or IE may result in unexpected component behavior.
      </p>
    </app-note>

    <p *ngIf="showCopyright" class="st-copyright">&#169; Geospatial Insight</p>
  </div>
</div>
