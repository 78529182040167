import { IJson } from '@vip-shared/interfaces'
import * as turf from '@turf/turf'
import { IClusterProperties, IFeatureSystemProperties } from '@core/types'
import { CommonUtil } from '@core/utils/common/common.util'
import * as olProj from 'ol/proj'
import * as olExtent from 'ol/extent'
import Feature from 'ol/Feature'
import { WKT, WKB } from 'ol/format'
import * as olGeom from 'ol/geom'
import Geojson from 'ol/format/GeoJSON'
import AppError from '@core/models/app-error'
import { fromCircle } from 'ol/geom/Polygon'
import GeometryCollection from 'ol/geom/GeometryCollection'
import { AppFeature } from '@core/models/layer/ol-extend/app-feature'
import { Geometry } from 'geojson'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { getArea } from 'ol/sphere'
// eslint-disable-next-line
import Polygon from 'ol/geom/Polygon'
export default class OlUtil {
  static readonly tileSize = 256
  static readonly maxZoomTo = 18
  static readonly maxZoomResolution = 0.1
  static readonly supportedGeometryTypes: string[] = [
    'Point',
    'MultiPoint',
    'LineString',
    'MultiLineString',
    'Polygon',
    'MultiPolygon'
  ]
  private static _wkt = new WKT()

  static featureToWkt = (f: AppFeature | olGeom.Geometry, decimals?: number, fromProj = 'EPSG:3857', toProj = 'EPSG:4326') => {
    const geom = f instanceof Feature ? f.getGeometry() : f
    if (!geom) return ''
    return `SRID=4326;${OlUtil._wkt.writeGeometry(geom, {
      dataProjection: toProj,
      featureProjection: fromProj,
      decimals
    })
      }`
  }

  static wktToFeature = (wkt: string, dataProjection = 'EPSG:4326') => {
    if (wkt.startsWith('SRID') && wkt.includes(';')) {
      const [proj, geom] = wkt.split(';')
      wkt = geom
      dataProjection = `EPSG:${proj.split('=')[1]}`
    }

    return new WKT().readFeature(
      wkt, {
      dataProjection,
      featureProjection: 'EPSG:3857'
    }
    )
  }

  static featuresToCollection = (features: turf.Feature[], projection = 'EPSG:4326') => {
    const collection = turf.featureCollection(features)
    projection = projection.replace(':', '::')
    collection['crs'] = {
      'type': 'name', 'properties': { 'name': `urn:ogc:def:crs:${projection}` }
    }
    return collection
  }

  static featureToGeoJSON = (f: AppFeature, fromProj = 'EPSG:3857', toProj = 'EPSG:4326', preserveProps = true) => {
    let geom = f.getGeometry()
    if (!geom) return
    geom = geom.clone()
    if (fromProj !== toProj) geom.transform(fromProj, toProj)

    const props = f.getProperties()
    delete props.geometry

    return turf.feature(
      turf.geometry(geom.getType(), geom['getCoordinates']()),
      preserveProps ? props : undefined
    )
  }

  static geojsonGeomToWkt = (geojson: Geometry, fromProj = 'EPSG:4326', toProj = 'EPSG:4326') => {
    const geometry = OlUtil.geojsonGeomToOlFt(geojson, fromProj, toProj)
    return OlUtil.featureToWkt(new Feature(geometry))
  }

  static combineWkts = (wkts: string[]) => {
    if (!wkts.length) return ''
    let combined = new MultiPolygon([])
    for (const wkt of wkts) {
      const polys = OlUtil._wkt.readGeometry(wkt.replace('SRID=4326;', ''))
      if (polys instanceof MultiPolygon) {
        const polyArray = polys.getPolygons()
        polyArray.map(p => {
          combined.appendPolygon(p)
        })
      } else if (polys instanceof Polygon) {
        combined.appendPolygon(polys)
      }
    }
    return `SRID=4326;${OlUtil._wkt.writeGeometry(combined)
      }`
  }

  static getResolution(): number[] {
    const projection = olProj.get('EPSG:3857')
    if (!projection) throw new AppError('Projection not found')
    const extent = projection.getExtent()
    const startRes = olExtent.getWidth(extent) / this.tileSize
    // Calculation of resolutions that match zoom levels 1, 3, 5, 7, 9, 11, 13, 15.
    const resolutions: number[] = []
    for (let i = 0; startRes / Math.pow(2, i) > this.maxZoomResolution; ++i) {
      resolutions.push(startRes / Math.pow(2, i))
    }
    return resolutions
  }

  static isClusterFeature(obj: AppFeature | IJson): boolean {
    const props: Partial<IClusterProperties> = obj instanceof Feature ? obj.getProperties() : obj
    return !CommonUtil.isUndefined(props.geometry) && Array.isArray(props.features)
  }

  static canSliceGeometry(g?: olGeom.Geometry | string) {
    if (!g) return false
    const type = g instanceof Object ? g.getType() : g
    // TODO: Check if GeometryCollection has sliceable geometries (line/polygon)
    if (g instanceof GeometryCollection) {
      // NOTE: This does not handle every case
      const pols = g.getGeometries().filter(g => g instanceof olGeom.Polygon)
      return !pols.length || pols.every(g => (g as olGeom.Polygon).getCoordinates().length === 1)
    }
    if (type === 'GeometryCollection') return true
    // TODO: Implement full Polygon support
    if (g instanceof olGeom.Polygon) return g.getCoordinates().length === 1
    return /LINE|POLYGON/g.test(type.toUpperCase()) && !type.startsWith('Multi')
  }

  // NOTE: Split functions are not flexible and only work with 'single split', this is due to time limits
  // and should be replaced by a flexible solution (allowing multi intersection multi polygon splitting)
  static splitLine(target: [number, number][], splitLine: [number, number][]): [number, number][][] {
    const tLine = turf.lineString(target)
    const split = turf.lineString(splitLine)

    if (!tLine.geometry) return []

    const intersections = turf.lineIntersect(tLine, split).features.map(x => {
      return x.geometry && x.geometry.coordinates
    }).filter(x => !!x)

    if (intersections.length === 0) return [target]
    if (intersections.length !== 1) {
      throw new AppError(`Line can only be split with one intersection at a time. Found '${intersections.length}' intersections.`)
    }

    const splitLines: [number, number][][] = []
    const coords = tLine.geometry.coordinates as [number, number][]
    const inter = intersections[0] as [number, number]
    let lineBuild: [number, number][] = []

    for (let i = 0; i < coords.length; i++) {
      const coord = coords[i]
      const next = coords[i + 1]
      if (!next) {
        lineBuild.push(coord)
        break
      }

      const segment = turf.lineString([coord, next])
      if (turf.lineIntersect(split, segment)) {
        lineBuild.push(coord, inter)
        splitLines.push(lineBuild)
        lineBuild = []
        lineBuild.push(inter)
      } else {
        lineBuild.push(coord)
      }
    }
    if (lineBuild.length > 0) {
      splitLines.push(lineBuild)
    }

    return splitLines
  }

  static splitPolygon(target: [number, number][][], splitLine: [number, number][]): [number, number][][][] {
    const tLine = turf.polygon(target)
    const split = turf.lineString(splitLine)
    if (!tLine.geometry) return []

    const polygonSegments = turf.lineSegment(tLine).features.map(x => x.geometry && x.geometry.coordinates) as [number, number][][]
    const lineSegments = turf.lineSegment(split).features.map(x => x.geometry && x.geometry.coordinates) as [number, number][][]

    // Get intersections manually because turf intersections on line-polygon are not accurate
    const intersections: {
      polygonSegment: [number, number][],
      lineSegment: [number, number][],
      point: [number, number]
    }[] = []

    // TODO: Check for line self-intersect
    for (const pSeg of polygonSegments) {
      for (const lSeg of lineSegments) {
        const segmentIntersection = turf.lineIntersect(turf.lineString(pSeg), turf.lineString(lSeg))
        if (segmentIntersection.features.length && segmentIntersection.features[0].geometry) {
          intersections.push({
            polygonSegment: pSeg,
            lineSegment: lSeg,
            point: segmentIntersection.features[0].geometry.coordinates as [number, number]
          })
        }
      }
    }

    if (intersections.length < 2) return [target]
    if (intersections.length !== 2) {
      throw new AppError(`Polygon can only be split with two intersection at a time. Found '${intersections.length}' intersections.`)
    }

    // Clip drawn line to polygon, but preserve original segments/points
    const adjustedLine: [number, number][] = []

    for (const coord of splitLine) {
      const next = splitLine[splitLine.indexOf(coord) + 1]
      if (!next) continue

      const lineIntersections = intersections.filter(x =>
        JSON.stringify(x.lineSegment) === JSON.stringify([coord, next])
      )
      if (lineIntersections.length === 2) {
        const distance1 = turf.distance(turf.point(coord), turf.point(lineIntersections[0].point))
        const distance2 = turf.distance(turf.point(coord), turf.point(lineIntersections[1].point))

        if (distance1 < distance2) {
          adjustedLine.push(lineIntersections[0].point, lineIntersections[1].point)
        } else {
          adjustedLine.push(lineIntersections[1].point, lineIntersections[0].point)
        }
      } else {
        if (adjustedLine.length === 0) {
          if (lineIntersections[0]) {
            adjustedLine.push(lineIntersections[0].point, next)
          }
        } else {
          if (lineIntersections[0]) {
            adjustedLine.push(lineIntersections[0].point)
          } else {
            adjustedLine.push(next)
          }
        }
      }
    }

    // Split polygon
    const polygonsOutput: [number, number][][][] = []
    const coords = tLine.geometry.coordinates as [number, number][][]
    for (const polCoords of coords) {
      for (const inter of intersections) {
        const startInter = inter
        const endInter = inter === intersections[0] ? intersections[1] : intersections[0]
        const polyOutput: [number, number][] = []
        const coords = [...polCoords]
        if (coords[0][0] === coords[coords.length - 1][0] && coords[0][1] === coords[coords.length - 1][1]) {
          coords.pop()
        }

        for (let i = 0; i < coords.length; i++) {
          const coord = coords[i]
          const next = coords[i + 1] || coords[0]
          if (i === coords.length - 1) i = -1

          if (polyOutput.length === 0) {
            const start = JSON.stringify(startInter.polygonSegment) === JSON.stringify([coord, next])
            if (start) {
              polyOutput.push(startInter.point, next)
            }
          } else {
            const end = JSON.stringify(endInter.polygonSegment) === JSON.stringify([coord, next])
            if (!end) {
              polyOutput.push(next)
            } else {
              const endLine = [...adjustedLine]
              if (endLine[0][0] !== endInter.point[0] && endLine[0][1] !== endInter.point[1]) {
                endLine.reverse()
              }

              polyOutput.push(...endLine)
              break
            }
          }
        }

        // TODO: Refactor drawing tool, sometimes it fails to close polygon
        // but this quick fix works well enough for now
        const first = polyOutput[0]
        const last = polyOutput[polyOutput.length - 1]
        if (JSON.stringify(first) !== JSON.stringify(last)) {
          polyOutput.push(first)
        }

        polygonsOutput.push([polyOutput])
      }
    }
    return polygonsOutput
  }

  static geojsonFeatureToOl = (geojson: turf.helpers.Feature, originalFeature?: AppFeature) => {
    const feature = originalFeature ? originalFeature.clone() : new Feature()
    feature.setGeometry(
      new Geojson({
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:3857'
      }).readFeature(geojson).getGeometry()
    )
    return feature
  }

  static geojsonGeomToOlGeom = (geojson: Geometry, fromProj = 'EPSG:4326', toProj = 'EPSG:3857') => {
    return new Geojson({
      dataProjection: fromProj,
      featureProjection: toProj
    }).readGeometry(geojson)
  }

  static geojsonGeomToOlFt = (geojson: Geometry, fromProj = 'EPSG:4326', toProj = 'EPSG:3857') => {
    return new Feature(OlUtil.geojsonGeomToOlGeom(geojson, fromProj, toProj))
  }

  static circleToPolygon = (circle: olGeom.Circle) => {
    // Transform to polygon as wkt does not support circles
    return new olGeom.Polygon(
      fromCircle(circle, 32).getCoordinates()
    )
  }

  static validateGeojson = (json: any) => {
    if (typeof json !== 'object') throw new AppError(`Cannot validate geojson. Expected json object but got '${typeof json}'.`)

    if (!json.type || json.type !== 'FeatureCollection') throw new AppError(`Geojson format unsupported. Expected geojson with type 'FeatureCollection'.`)
    const geojson = json as turf.FeatureCollection

    if (!geojson.features || !geojson.features.length) throw new AppError(`Expected at least one feature. Geojson has 0 features.`)

    for (const f of geojson.features) {
      if (f.type !== 'Feature') throw new AppError(`Geojson format unsupported. Feature list can only contain items with type 'Feature'`)

      if (f.geometry && !OlUtil.supportedGeometryTypes.includes(f.geometry.type)) {
        throw new AppError(`Geojson format unsupported. Found unsupported geometry type '${f.geometry.type}'. Supported types are: ${OlUtil.supportedGeometryTypes.join(', ')
          }.`)
      }
    }
  }

  static featureFilteredOut = (f: AppFeature): boolean => {
    const sysProps = f.get('_system') as Required<IFeatureSystemProperties['_system']> | undefined
    return !!(sysProps && sysProps.filteredOutBy && sysProps.filteredOutBy.filter(x => !!x).length)
  }

  static getGeometriesAtCoordinate = (f: AppFeature, coord: [number, number]): olGeom.Geometry[] => {
    const geom = f.getGeometry()
    const geoms: olGeom.Geometry[] = []
    if (geom instanceof olGeom.MultiPoint) {
      const points = geom.getPoints()
      for (const point of points) {
        if (point.intersectsCoordinate(coord)) geoms.push(point)
      }
    } else if (geom instanceof olGeom.MultiLineString) {
      const lines = geom.getLineStrings()
      for (const line of lines) {
        if (line.intersectsCoordinate(coord)) geoms.push(line)
      }
    } else if (geom instanceof olGeom.MultiPolygon) {
      const polys = geom.getPolygons()
      for (const poly of polys) {
        if (poly.intersectsCoordinate(coord)) geoms.push(poly)
      }
    } else {
      return [geom as olGeom.Geometry]
    }
    return geoms
  }

  // TODO: Append position for every item?
  static expandFeature = (item: AppFeature | olGeom.Geometry): (AppFeature | AppFeature[])[] => {
    const geom = (item instanceof Feature ? item.getGeometry() : item) as olGeom.Geometry
    const type = geom.getType()
    if (type === 'GeometryCollection') {
      const collection = geom as GeometryCollection
      return collection.getGeometries().map(x => {
        const features = OlUtil.expandFeature(x)
        if (features.length === 1) return features[0] as AppFeature
        return features as AppFeature[]
      })
    } else {
      return [new Feature(geom)]
    }
  }

  static getFeatureSysProps = (f: AppFeature): NonNullable<IFeatureSystemProperties['_system']> => {
    return f.get('_system') || {}
  }

  static setFeatureSysProps = (f: AppFeature, props?: NonNullable<IFeatureSystemProperties['_system']>) => {
    return f.set('_system', props)
  }

  static deClusterFeatures = (fts: AppFeature[]) => {
    let arr: AppFeature[] = []
    for (const ft of fts) {
      // TS TODO: For some reason cluster gets inside of another cluster???
      if (!OlUtil.isClusterFeature(ft)) arr.push(ft)
      else arr = arr.concat(...OlUtil.deClusterFeatures(ft.get('features')))
    }

    return arr
  }

  static areGeometriesEqual(geometry1: Polygon | MultiPolygon, geometry2: Polygon | MultiPolygon): boolean {
    // Check if both geometries are of the same type
    if (geometry1.getType() !== geometry2.getType()) {
      return false
    }
    // Extract the coordinates from the geometries
    const coords1 = geometry1.getCoordinates()
    const coords2 = geometry2.getCoordinates()

    const coordinates1 = coords1.sort((a, b) => a[0].length - b[0].length)
    const coordinates2 = coords2.sort((a, b) => a[0].length - b[0].length)

    // Check if the number of components (Polygons) is the same
    if (coordinates1.length !== coordinates2.length) {
      return false
    }

    // Check each component (Polygon) for equality
    for (let i = 0; i < coordinates1.length; i++) {
      const polygon1 = coordinates1[i]
      const polygon2 = coordinates2[i]

      // Check if the number of rings is the same
      if (polygon1.length !== polygon2.length) {
        return false
      }

      // Check each ring for equality
      for (let j = 0; j < polygon1.length; j++) {
        const ring1 = polygon1[j]
        const ring2 = polygon2[j]

        // Check if the number of vertices is the same
        if (ring1.length !== ring2.length) {
          return false
        }

        // Check each vertex for equality
        for (let k = 0; k < ring1.length; k++) {
          const vertex1 = ring1[k]
          const vertex2 = ring2[k]

          // Check if the coordinates are the same
          if (vertex1[0] !== vertex2[0] || vertex1[1] !== vertex2[1]) {
            return false
          }
        }
      }
    }

    // Geometries are equal
    return true
  }

  static isAreaOverThreshold(geometry: Polygon | MultiPolygon, thresholdSquareKm: number): boolean {
    // Convert the area from square meters to square kilometers
    const areaSquareKm = getArea(geometry) / 1e6

    // Check if the area is over the threshold
    return areaSquareKm > thresholdSquareKm
  }

  static splitMultiPolygonWKT(wkt: string): string[] {
    if (!wkt.includes("MULTIPOLYGON")) {
      throw new Error("Not a valid MULTIPOLYGON WKT")
    }

    const srid = 'SRID=4326;'
    const polygonString = 'POLYGON'

    const content = wkt.replace("MULTIPOLYGON(", "").slice(0, -1)

    let polygons: string[] = []
    let depth = 0
    let start = 0

    for (let i = 0; i < content.length; i++) {
      if (content[i] === '(') {
        if (depth === 0) start = i
        depth++
      } else if (content[i] === ')') {
        depth--
        if (depth === 0) {
          polygons.push(srid + polygonString + content.slice(start, i + 1))
        }
      }
    }
    return polygons
  }

  static wkbHexFromWkt(wktString: string): string | ArrayBuffer {
    // Create an OpenLayers geometry from the WKT string
    let wkt = wktString.replace('SRID=4326;','')
    const format = new WKT()
    const geometry: olGeom.Geometry = format.readGeometry(wkt)

    // Convert the geometry to a WKB hex string
    const wkbFormat = new WKB()
    const wkbHex: string | ArrayBuffer = wkbFormat.writeGeometry(geometry)

    return wkbHex
  }

  static wkbAsGeomtery(wkb: string): olGeom.MultiPolygon | olGeom.Polygon {
    const format = new WKB()
    const geometry = format.readGeometry(wkb) as olGeom.MultiPolygon | olGeom.Polygon
    return geometry
  }

}
