<div class="input-row">
  <mat-form-field>
    <input matInput [(ngModel)]="searchText" (input)="onTextChange()"
    [matAutocomplete]="auto" #input [placeholder]="placeholder">

    <mat-autocomplete #auto="matAutocomplete"
    (optionSelected)="add($event)" [displayWith]="formatAutocomplete()">
      <mat-option *ngIf="searchText.length && !options.length" [value]="undefined" [disabled]="true">
        No locations found, please enter a valid location name
      </mat-option>
      <mat-option *ngFor="let option of options" [value]="option">
        {{option.description}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-icon matTooltip="Input location name and select available option from the dropdown.">
    help_outline
  </mat-icon>
</div>

<div class="value-list">
  <div class="value" *ngFor="let location of locations">
    <span>{{location.formatted_address}}</span>
    <mat-icon (click)="remove(location)">clear</mat-icon>
  </div>
</div>
