import { IEnvironment } from './IEnvironment'
import { version } from '../../package.json'
import { GsiGeoserver } from '@vip-shared/models/const/geoserver'

export const environment: IEnvironment = {
  production: true,
  environment: 'prod',
  version: '1.0.0',
  VIPServer: 'https://api.gsi-vip.com',
  bingKey: 'p3gs30l6Jt7fn5M5xau9~E2t4fPplukqidojeoGQ9oQ~ArqSvOIxrTGgVKaXlZFRUsct-Aeo3w3OfCJo4muvlhMSp1waqSxrzm-OSnR_KCAy',
  gmapsStreetViewKey: 'AIzaSyDIns51NWpWgG82KllVjYy5AFKnKDz3nEc',
  UHL: {
    airRoutingEngineUrl: {
      bicester: 'http://35.242.148.176',
      belfast: 'http://35.230.145.89'
    },
    routingMiddlewareApi: {
      bicester: 'https://uhl.gsi-vip.com/api',
      belfast: 'https://uhl-belfast.gsi-vip.com/api'
    },
    tiffDir: {
      bicester: `${GsiGeoserver.Placeholder}/UHL/wms`,
      belfast: `${GsiGeoserver.Placeholder}/UHL-Belfast/wms`
    },
    xmlURL: {
      bicester: 'UHL',
      belfast: 'UHL-Belfast'
    }
  }
}
