<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'area'">
      <div class="drop-area" (click)="fileInput.click()"
      appFilesDragDrop (onFilesDropped)="onFilesDropped.emit($event)" [captureWindow]="captureWindow"
      [ngClass]="[contentClass || '']">
        <div class="drop-label">
          <mat-icon>move_to_inbox</mat-icon>
          <span>Drag and drop files here or click to select</span>
        </div>
      </div>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
      <button mat-raised-button color="primary" (click)="fileInput.click()"
      appFilesDragDrop (onFilesDropped)="onFilesDropped.emit($event)" [captureWindow]="captureWindow"
      [ngClass]="[contentClass || '']">
          {{buttonText}}
      </button>
  </ng-container>

  <ng-container *ngSwitchCase="'content'">
    <div [ngClass]="[contentClass || '']" appFilesDragDrop (onFilesDropped)="onFilesDropped.emit($event)" [captureWindow]="captureWindow" (click)="fileInput.click()">
        <ng-content></ng-content>
    </div>
  </ng-container>
</ng-container>

<input hidden #fileInput type="file" [accept]="accept" (change)="filesSelected($event)" [multiple]="multiple">
