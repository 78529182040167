import { Component, Inject, ElementRef, AfterViewInit, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'
import { CtxUIMenu, CtxUIMenuItem } from '@core/types/workspace/map/ws-ctx-menu-conf'
import { ActionOutletFactory, ActionGroup } from '@ng-action-outlet/core'
import { fromEvent, Subscription } from 'rxjs'
import { CommonUtil } from '@core/utils/index'

@Component({
  selector: 'app-context-menu-dialog',
  templateUrl: './context-menu-dialog.component.html',
  styleUrls: ['./context-menu-dialog.component.scss']
})
export class ContextMenuDialogComponent implements AfterViewInit, OnDestroy {
  private _subscriptions = new Subscription()

  group: ActionGroup
  hasSubMenus = false

  constructor (
    @Inject(MAT_DIALOG_DATA) public options: CtxUIMenu,
    private _dialogRef: MatDialogRef<ContextMenuDialogComponent>,
    private _actionOutlet: ActionOutletFactory,
    private _elRef: ElementRef<HTMLElement>
  ) {
    this.group = this._actionOutlet.createGroup().enableDropdown()

    this.CreateCtx(this.group, this.options)
  }

  async ngAfterViewInit () {
    const actionMenu = this._elRef.nativeElement.firstElementChild
    if (!actionMenu) return

    const mainButton = actionMenu.getElementsByTagName('button').item(0)

    await CommonUtil.delay(10)
    mainButton && mainButton.click()

    // TODO: Add logic to allow leaving one menu to child menu, without it closing,
    // in other words close only when mouse is not hovering any of the menus
    const menus = Array.from(document.getElementsByClassName('mat-menu-panel'))
    if (menus.length) {
      for (const menu of menus) {
        this._subscriptions.add(
          fromEvent(menu, 'mouseleave').subscribe(() => {
            if (!this.hasSubMenus) this.Close()
            // Otherwise have to click to dismiss
          })
        )
      }
    } else {
      console.warn(`Can't close menu on hover. Failed to find menu element.`)
    }
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  private Close () {
    this._dialogRef.close()
    this.group.destroy()
  }

  private CreateCtx (parentGroup: ActionGroup, menu: CtxUIMenu | undefined) {
    if (Array.isArray(menu) && menu){
      for (let item of menu) {
        const keys = Object.keys(item)
        if (!keys.length) continue
        const group = parentGroup.createGroup()

        for (let key of keys) {
          const option = item[key]
          if (option instanceof Function) {
            group.createButton({
              title: key,
              callback: () => {
                option()
                this.Close()
              }
            })
          } else {
            this.hasSubMenus = true
            group.setTitle(key).enableDropdown()
            this.CreateCtx(group, option)
          }
        }
      }
    }
  }

  static setOptions (opts: CtxUIMenu, click: {x: number, y: number}): MatDialogConfig {
    const items = opts.length
    const height = items * 32
    const width = 90
    if (click.y + height > window.innerHeight) {
      click.y = window.innerHeight - height
    }
    if (click.x + width > window.innerWidth) {
      click.x = window.innerWidth - width
    }

    const options: MatDialogConfig = {
      data: opts,
      hasBackdrop: false,
      position: {
        top: `${click.y}px`,
        left: `${click.x}px`
      },
      panelClass: ['st-no-dialog-padding', 'app-context-menu-dialog']
    }

    return options
  }

  getKeys (obj: any) {
    return Object.keys(obj)
  }

  callAction (options: CtxUIMenuItem, action: string) {
    const cb = options[action]
    if (cb instanceof Function) cb()
    this.Close()
  }
}
