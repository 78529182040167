<ng-container *ngIf="theme">
  <div *ngIf="previewButton">
    <mat-icon (click)="preview()" matTooltip="Preview Theme">search</mat-icon>
  </div>
  <div matTooltip="Primary" [style.background]="theme?.colors?.primary"></div>
  <div matTooltip="Secondary" [style.background]="theme?.colors?.secondary"></div>
  <div matTooltip="Foreground" [style.background]="theme?.colors?.foreground"></div>
  <div matTooltip="Background (Primary)" [style.background]="bkPrimary"></div>
  <div matTooltip="Background (Secondary)" [style.background]="bkSecondary"></div>
  <div matTooltip="Background (Tertiary)" [style.background]="bkTertiary"></div>
</ng-container>
