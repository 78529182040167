import { IRWorkspaceLayer } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { AppLayer } from './app-layer'
import { VipApiService, PromptService } from '@services/core'
import { CommonUtil } from '@core/utils/index'
import { AppLayerGeneric } from './app-layer-generic'
import { MatDialog } from '@angular/material/dialog'
import { LayerStyleParameters } from '@core/types/workspace/layers/style/style-parameters'
import NewLayerUtil from './utils/new-layer.util'
import VectorLayer from 'ol/layer/Vector'
import { MomentPipe } from '@core/pipes/moment.pipe'
import AppError from '../app-error'
import * as moment from 'moment'
import VectorSource from 'ol/source/Vector'
import { cloneDeep, isEqual } from 'lodash'
import { Subject } from 'rxjs'
import * as olExtent from 'ol/extent'
import * as olProj from 'ol/proj'
import { LayerUtil } from './utils'
import { WorkspaceService } from '@services/workspace'
import TileSource from 'ol/source/Tile'
import { Geometry } from 'ol/geom'

type ImplementedLayer = VectorLayer<any>
export class AppDroneLayer extends AppLayer {
  private _sources!: Db.Vip.Geo.IDroneSource[]
  private _renderedSource?: Db.Vip.Geo.IDroneSource
  get renderedSource () {
    return this._renderedSource
  }

  private _sourceChanged = new Subject<Db.Vip.Geo.IDroneSource | undefined>()
  get sourceChanged () {
    return this._sourceChanged.asObservable()
  }

  get droneStyle (): LayerStyleParameters['droneColor'] | 'auto' {
    if (
      CommonUtil.isUndefined(this.style.droneAuto) &&
      CommonUtil.isUndefined(this.style.droneColor)
    ) return { R: 0, G: 0, B: 0 }

    const obj = this.getAppliedStyle()
    if (obj.style.droneAuto) return 'auto'

    return obj.style.droneColor ?
      { ...obj.style.droneColor } :
      { R: 0, G: 0, B: 0 }
  }

  constructor (
    allElements: () => AppLayerGeneric[],
    allLayers: () => AppLayer[],
    maxIndex: () => number,
    canSelect: () => boolean,
    canDeselect: () => boolean,
    momentPipe: MomentPipe,
    dialog: MatDialog,
    prompt: PromptService,
    api: VipApiService,
    workspaceService: WorkspaceService,
    source: IRWorkspaceLayer,
    viewId: number,
    theme?: Db.Vip.Geo.ILayerAttribute
  ) {
    super(
      allElements, allLayers, maxIndex, canSelect, canDeselect,
      momentPipe , dialog, prompt, api,
      workspaceService,
      source, viewId, theme, false
    )

    if (this.typeId !== Db.Vip.SourceType.DRONE_VECTOR) {
      this._loadingError = `Layer is not a Drone Vector layer.`
    }

    this.LoadAsync(source, theme)
  }

  async getLayerExtent (): Promise<olExtent.Extent> {
    let extent: olExtent.Extent
    if (this.extent && this.extent.extent && !this.vector) {
      extent = this.extent.extent
      if (this.extent.projection !== 'EPSG:3857') {
        extent = olProj.transformExtent(
          extent,
          this.extent.projection || 'EPSG:4326',
          'EPSG:3857'
        )
      }
      return extent
    }

    const layerExtent = this.baseSource && this.baseSource.getExtent()
    if (!layerExtent) throw new AppError('Layer extent is empty.')
    extent = layerExtent

    if (!LayerUtil.extentValid(extent as any)) throw new AppError('Layer extent is invalid.')
    this.updateLayerExtent({ extent: layerExtent as any, projection: 'EPSG:3857' })

    return extent
  }

  protected async LoadAsync (source: IRWorkspaceLayer, theme?: Db.Vip.Geo.ILayerAttribute) {
    try {
      this._sources = await this._sourceApi.DroneSources().get().run()
      if (!this._sources.length) throw new AppError(`Drone source(s) is missing.`)

      await this.GetGeometryTypes()
      const layer = await this.RenderLayer(source)
      if (this.layer) {
        this.layer.setSource(layer.getSource() as VectorSource<Geometry> & TileSource)
      } else {
        this.layer = layer
      }
    } catch (error: any) {
      this._loadingError = error.message
    }

    this._loaded = true
  }

  private async GetSource (src?: Db.Vip.Geo.IDroneSource) {
    this._renderedSource = src
    let source: VectorSource
    if (!src) {
      source = new VectorSource({})
      this._sourceChanged.next(undefined)
      return source
    }
    source = NewLayerUtil.createDroneVectorSource(
      src.line_geojson, src.point_telemetry_geojson,
      src.telemetry_start_index
    )

    this._sourceChanged.next(this._renderedSource)
    return source
  }

  protected async RenderLayer (layer: IRWorkspaceLayer): Promise<ImplementedLayer> {
    const mapLayer = new VectorLayer({
      visible: this.visible
    })

    mapLayer.setProperties({
      sourceId: layer.layer_id,
      name: layer.name,
        // NOTE: don't rename to 'extent', as that will stop layers from loading
      extentDetails: layer.extent,
      interactive: true
    })

    if (this.isTimeSeries) {
      mapLayer.setSource(await this.GetSource(this.GetFocalSource()))
    } else {
      mapLayer.setSource(await this.GetSource(this._sources && this._sources[0]))
    }

    const source = mapLayer.getSource()
    if (source) {
      source.once('change', e => {
        this.layer && this.layer.setProperties({ loaded: true })
      })
    }

    return mapLayer
  }

  async setRenderedTimeSeries (timeSeries: Db.Helper.Geo.Timeseries) {
    const prevRendered = this._renderedTimeSeriesSelection
    const prevRange = prevRendered && prevRendered.date_range

    this._renderedTimeSeriesSelection = cloneDeep(timeSeries)
    const renderedRange = this._renderedTimeSeriesSelection.date_range

    let rangeChanged = false
    let focalChanged = false
    let focalAccChange = false

    if (!prevRange) {
      rangeChanged = focalChanged = focalAccChange = true
    } else {
      focalChanged = renderedRange.focal !== prevRange.focal
      rangeChanged = renderedRange.from !== prevRange.from || renderedRange.to !== prevRange.to
      focalAccChange = !isEqual(prevRendered && prevRendered.focal_accuracy, timeSeries.focal_accuracy)
    }

    if (rangeChanged) {
      // NOTE: As drone layers don't fetch much data, just few rows of data entries, we can fetch
      // them all, if this ever causes performance issues, we can pull only rows that match time range.
      // Date range matching entries will also need to be fetched if and when we will have any 'comparison'/'change'
      // data for rasters, as currently we don't have any
    } else if (focalChanged || focalAccChange) {
      this.layer && this.layer.setSource(await this.GetSource(this.GetFocalSource()) as TileSource & VectorSource<Geometry>)
      this.syncStyle(true)
    }

    this.CheckTimeSeriesState()
  }

  private GetFocalSource (): Db.Vip.Geo.IDroneSource | undefined {
    if (!this._renderedTimeSeriesSelection || !this._sources) return

    const focal = moment(this._renderedTimeSeriesSelection.date_range.focal)

    const minFocal = moment(focal)
    const maxFocal = moment(focal)

    const focalAccPeriod = this._renderedTimeSeriesSelection.focal_accuracy
    if (focalAccPeriod) {
      minFocal.subtract(focalAccPeriod.value, focalAccPeriod.interval)
      maxFocal.add(focalAccPeriod.value, focalAccPeriod.interval)
    } else {
      minFocal.subtract(15, 'm')
      maxFocal.add(15, 'm')
    }

    let nearestBefore: {
      date: moment.Moment
      distance: number
      s: Db.Vip.Geo.IDroneSource
    } | undefined

    let nearestAfter: {
      date: moment.Moment
      distance: number
      s: Db.Vip.Geo.IDroneSource
    } | undefined

    const setDate = (date: moment.Moment, s: Db.Vip.Geo.IDroneSource) => {
      const distance = date.diff(focal)
      if (distance === 0) {
        nearestBefore = { date, distance, s }
        nearestAfter = { date, distance, s }
      } else if (distance > 0 && (!nearestAfter || distance < nearestAfter.distance)) {
        nearestAfter = { date, distance, s }
      } else if (distance < 0 && (!nearestBefore || distance > nearestBefore.distance)) {
        nearestBefore = { date, distance, s }
      }
      return distance
    }

    // TODO: Move to util class, as this implementation appears in other layer files too
    const processArray = (sources: Db.Vip.Geo.IDroneSource[]) => {
      const [firstHalf, secondHalf] = CommonUtil.arrayIntoChunks(sources, 2)
      const firstSource = firstHalf[firstHalf.length - 1]
      const secondSource = secondHalf[0]

      const firstDate = firstSource && firstSource.date_time && moment(firstSource.date_time)
      const secondDate = secondSource && secondSource.date_time && moment(secondSource.date_time)

      if (!firstDate && !secondDate) {
        return
      } else if (!firstDate) {
        if (secondHalf.length === 1) {
          setDate(secondDate as moment.Moment, secondSource)
          return
        }
        return processArray(secondHalf)
      } else if (!secondDate) {
        if (firstHalf.length === 1) {
          setDate(firstDate, firstSource)
          return
        }
        return processArray(firstHalf)
      } else if (firstHalf.length === 1 && secondHalf.length === 1) {
        setDate(firstDate, firstSource)
        setDate(secondDate, secondSource)
      }

      // For timeseries with active range per row - focal will match nearest start in change period
      const firstDiff = firstDate.diff(focal)
      const secondDiff = secondDate.diff(focal)

      const sameSign = CommonUtil.sameSign(firstDiff, secondDiff)
      if (sameSign) {
        // If positive
        if (firstDiff >= 0) {
          if (firstDiff < secondDiff) {
            return processArray(firstHalf)
          } else if (secondDiff < firstDiff) {
            return processArray(secondHalf)
          }
        } else {
          if (firstDiff > secondDiff) {
            return processArray(firstHalf)
          } else if (secondDiff > firstDiff) {
            return processArray(secondHalf)
          }
        }

        if (focal.isBefore(firstDate)) {
          return processArray(firstHalf)
        } else if (focal.isAfter(secondDate)) {
          return processArray(secondHalf)
        } else {
          setDate(firstDate, firstSource)
          setDate(secondDate, secondSource)
          return
        }
      }

      // NOTE: Can improve by checking if one of array had lower 'edge' distance, then
      // wont need to iterate 2 arrays
      processArray(firstHalf)
      processArray(secondHalf)
    }

    processArray(this._sources)

    const beforeInRange = !!nearestBefore && nearestBefore.date.isSameOrAfter(minFocal) &&
      nearestBefore.date.isSameOrBefore(maxFocal)

    const afterInRange = !!nearestAfter && nearestAfter.date.isSameOrAfter(minFocal) &&
      nearestAfter.date.isSameOrBefore(maxFocal)

    if (nearestBefore && beforeInRange && !afterInRange) {
      return nearestBefore.s
    } else if (nearestAfter && afterInRange && !beforeInRange) {
      return nearestAfter.s
    } else if (!afterInRange && !beforeInRange) {
      return
    }

    const beforeIsSame = nearestBefore && nearestBefore.date.isSame(focal, 'day')
    const afterIsSame = nearestAfter && nearestAfter.date.isSame(focal, 'day')

    if (nearestAfter && nearestBefore && beforeIsSame && afterIsSame) {
      return nearestAfter.distance <= nearestBefore.distance ? nearestAfter.s : nearestBefore.s
    } else if (nearestAfter && afterIsSame) {
      return nearestAfter.s
    } else if (nearestBefore && beforeIsSame) {
      return nearestBefore.s
    }
  }
}
