import { trigger, style, transition, animate } from '@angular/animations'

export const ifFade = trigger('ifFade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('500ms', style({ opacity: 0 }))
  ])
])
