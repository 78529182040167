<h2 mat-dialog-title>Workspace access</h2>

<div mat-dialog-content>
  <div>
    <mat-tab-group *ngIf="sysMaintainer" [(selectedIndex)]="selectedIndex" [animationDuration]="animate ? 500 : 0">
      <mat-tab label="Customers">
        <app-workspace-customer-table (close)="close()" [productId]="data.productId" [workspaceId]="data.workspaceId"
          (usersFilter)="showCustomerUsers($event)" (accessChanged)="reloadUsers()">
        </app-workspace-customer-table>
      </mat-tab>
      <mat-tab label="Users">
        <app-workspace-user-table (close)="close()" #userTable [workspaceId]="data.workspaceId">
        </app-workspace-user-table>
      </mat-tab>
    </mat-tab-group>

    <app-workspace-user-table (close)="close()" #userTable *ngIf="!sysMaintainer" [workspaceId]="data.workspaceId">
    </app-workspace-user-table>
  </div>

  <div class="st-button-row">
    <button color="primary" type="button" mat-button [mat-dialog-close] cdkFocusInitial>
      Close
    </button>
  </div>
</div>
