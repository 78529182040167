<div class="dialog-wrapper">
  <div id="row-wrapper" [style.width]="maxWidth">
    <div class="row-cell" *ngFor="let cell of tooltip.content" [style.width]="cellWidth">
      <div class="cell-header">{{cell.name}}</div>

      <div *ngIf="cell.value !== undefined && cell.value !== null" class="cell-value">
        <div *ngIf="cell.numberStyling && cell.value < 0" class="negative">
          <mat-icon>arrow_drop_down</mat-icon>
          <span class="st-ellipsis">{{cell.formatted || cell.value}}</span>
        </div>

        <div *ngIf="cell.numberStyling && cell.value >= 0" class="positive">
          <mat-icon>arrow_drop_up</mat-icon>
          <span>{{cell.formatted || cell.value}}</span>
        </div>

        <span *ngIf="!cell.numberStyling">{{cell.formatted || cell.value}}</span>
      </div>

      <div *ngIf="cell.value === undefined || cell.value === null" class="cell-value">
        -
      </div>

    </div>
  </div>
</div>
