<h2 mat-dialog-title>
  <mat-icon svgIcon="vector-polygon"></mat-icon>
  <span [innerHTML]="spatialQueryInnerHtml"></span>
</h2>

<div mat-dialog-content>
  <app-note *ngIf="data.warning">{{data.warning}}</app-note>
  <p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>

  <form [formGroup]="form" class="st-single-row-form">
    <mat-form-field>
      <input matInput placeholder="Query name" formControlName="name" (focus)="onTitleFocus()" (blur)="onTitleBlur()" />
    </mat-form-field>

    <!-- TODO: IMPLEMENT ST_INTERSECTS ON THE BACKEND -->
    <!-- <app-button-toggle-field label="Include Geometries on Edge">
      <mat-button-toggle-group formControlName="includeEdge">
        <mat-button-toggle [value]="true"
        matTooltip="Geometries that cross the outline on edge will be included.">
          Yes
        </mat-button-toggle>
        <mat-button-toggle [value]="false"
        matTooltip="Geometries that cross the outline on edge will be excluded.">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </app-button-toggle-field> -->
    <div *ngIf="numEntriesLoading" class="data-loading">
      <span>Please wait for the number of records in the area </span>
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <ngTemplate *ngIf="!numEntriesLoading && singleLayerFlag">
      <div>{{entriesMsg}}</div>
      <div>Total number of records in the layer: {{+numEntries.count + existingEntries}}</div>
    </ngTemplate>
    <div *ngIf="layerAlert">When loading large layers click 'wait' if the browser asks whether to cancel the operation
    </div>
    <div *ngIf="dataCapWarning"><span class="remove-layer">Number of records exceed system rendering limit</span></div>
    <div *ngIf="!isMultiPolygon" class="wkt-field">
      <mat-form-field>
        <textarea matInput formControlName="wktGeometry" cols="40" rows="3"
          placeholder="WGS84 'Well Known Text (WKT)' point geometry"></textarea>
        <mat-error *ngIf="form.controls.wktGeometry.hasError('wktValid')">
          {{form.controls.wktGeometry['errors'] && form.controls.wktGeometry['errors']['wktValid']['message']}}
        </mat-error>
      </mat-form-field>

      <app-wkt-preview #preview [wkt]="form.controls.wktGeometry.value"></app-wkt-preview>
    </div>
    <div *ngIf="splitPolygons.length">
      <div class="wkt-field">
        <mat-form-field>
          <textarea matInput formControlName="wktGeometry" cols="40" rows="3"
            placeholder="WGS84 'Well Known Text (WKT)' point geometry"></textarea>
          <mat-error *ngIf="form.controls.wktGeometry.hasError('wktValid')">
            {{form.controls.wktGeometry['errors'] && form.controls.wktGeometry['errors']['wktValid']['message']}}
          </mat-error>
        </mat-form-field>

        <app-wkt-preview #preview [wkt]="form.controls.wktGeometry.value"></app-wkt-preview>
      </div>
      <div *ngFor="let polygon of splitPolygons; let i = index" class="wkt-field">
        <mat-form-field>
          <input matInput placeholder="{{'polygon name:'+ i}}" formControlName="{{'polygon_name:'+ i}}"/>
        </mat-form-field>
        <app-wkt-preview #preview [wkt]="polygon.wkt"></app-wkt-preview>
      </div>
    </div>
  </form>
</div>

<div class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>CANCEL</button>
  </div>
  <div class="right">
    <button color="primary" mat-raised-button (click)="save()" [disabled]="!form.valid || saving"
      [innerHTML]="submitButtonInnerHtml"></button>
  </div>
</div>
