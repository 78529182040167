import { IMeasurement } from '@core/types'
import { LineString } from 'ol/geom'
import { formatNumber } from '@angular/common'
import { IJson } from '@vip-shared/interfaces'
import AppError, { handleError } from '@core/models/app-error'
import { ConvertUtil } from '@core/utils/convert/convert.util'
import { v4 } from 'uuid'

const Keys: {
  [key in Key]: {
    key: string[]
    keyCode: number
  }
} = {
  ctrl: {
    key: ['Control'],
    keyCode: 17
  },
  enter: {
    key: ['Enter'],
    keyCode: 13
  },
  s: {
    key: ['s', 'S'],
    keyCode: 83
  },
  a: {
    key: ['a', 'A'],
    keyCode: 65
  },
  d: {
    key: ['d', 'D'],
    keyCode: 68
  },
  e: {
    key: ['e', 'E'],
    keyCode: 69
  },
  z: {
    key: ['z', 'Z'],
    keyCode: 90
  },
  m: {
    key: ['m', 'M'],
    keyCode: 77
  },
  esc: {
    key: ['Escape'],
    keyCode: 27
  },
  down: {
    key: ['ArrowDown'],
    keyCode: 40
  },
  up: {
    key: ['ArrowUp'],
    keyCode: 38
  },
  right: {
    key: ['ArrowRight'],
    keyCode: 39
  },
  left: {
    key: ['ArrowLeft'],
    keyCode: 37
  }
}

type Key = 'ctrl' | 'enter' | 's' | 'a' | 'd' | 'e' | 'z' | 'm' | 'esc' | 'down' | 'up' | 'right' | 'left'

export class CommonUtil {
  static readonly extensions: {[key: string]: string} = {
    'text/plain': '.txt',
    'text/csv': '.csv',
    'image/tiff': '.tiff',
    'application/geo+json': '.geojson',
    'application/vnd.geo+json': '.geojson',
    'video/x-flv': '.flv',
    'video/mp4': '.mp4',
    'video/quicktime': '.mov',
    'video/x-msvideo': '.avi',
    'video/x-ms-wmv': '.wmv',
    'image/webp': '.webp',
    'image/png': '.png',
    'image/jpg': '.jpg',
    'image/jpeg': '.jpeg'
  }

  static getUuid () {
    return v4()
  }

  static mergeDeep (target: IJson, ...sources: IJson[]) {
    const isObject = (item) => {
      return (item && typeof item === 'object' && !Array.isArray(item))
    }

    if (!sources.length) return target
    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} })
          CommonUtil.mergeDeep(target[key], source[key])
        } else {
          Object.assign(target, { [key]: source[key] })
        }
      }
    }

    return CommonUtil.mergeDeep(target, ...sources)
  }

  static getDefaultMimeType (filename: string) {
    let ext = filename.split('.').pop()
    if (!ext) return
    ext = `.${ext.toLowerCase()}`
    return Object.keys(this.extensions).find(e => this.extensions[e] === ext)
  }

  static isUndefined <T> (value: T | null | undefined): value is null | undefined {
    return value === null || value === undefined
  }

  static isSupportedHtmlImage (name: string) {
    const el = document.createElement('a')
    el.href = name.toLowerCase()
    return ['png', 'jpg', 'jpeg', 'gif', 'svg'].some(x => el.pathname.endsWith(x))
  }

  static isValidLink (val: string) {
    if (!val) return false
    const isUrl = val.startsWith('http://') || val.startsWith('https://')
    return isUrl
  }

  static delay (ms: number) {
    return new Promise(res => setTimeout(res, ms))
  }

  static isLineString (measureType: IMeasurement['measurement']['type']): boolean {
    return measureType instanceof LineString
  }

  static async getGeolocation (): Promise<{}> {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(resolve)
    })
  }

  static scrollTo (element: HTMLElement, to: number, duration: number): void {
    if (duration > 0) {
      const difference = to - element.scrollTop
      const perTick = difference / duration * 10

      setTimeout(() => {
        element.scrollTop = element.scrollTop + perTick
        if (element.scrollTop === to) {
          return
        } else {
          this.scrollTo(element, to, duration - 10)
        }
      }, 10)
    }
  }

  static durationTimeFormat (time: number): string {

    let hrs: number = Math.floor(time / 3600)
    let mins: number = Math.floor((time % 3600))

    if (mins > 59) {
      hrs += Math.floor(mins / 60)
      mins = Math.floor((mins % 60))
    }

    let minutes: string = mins.toString()

    if (mins < 10) {
      minutes = `0${mins}`
    }

    const formattedTime = `${hrs}h ${minutes}min`

    return formattedTime
  }

  static getCsvColumns (csv: string) {
    const rows = csv.split(/\n\r?/g)
    if (rows.length < 1) throw new AppError('Csv does not contain any rows.')
    return rows[0].split(',').map((str: string) => {
      let name = str.trim()
      if (name.startsWith('"') && name.endsWith('"')) {
        name = name.slice(1, name.length - 1)
      }
      return name
    })
  }

  static getCsvColumnData (csv: string, id: string) {
    const rows = csv.split(/\n\r?/g)
    if (rows.length < 1) throw new AppError('Csv does not contain any rows.')
    let idIdx: number = -1
    let dataArr: string[] = []
    for (let i = 0; i < rows.length; ++i) {
      const data = rows[i].split(',').map(r => r.replace(/['"]+/g, ''))
      if (i === 0) {
        idIdx = data.indexOf(id)
      } else {
        dataArr.push(data[idIdx])
      }
    }
    return dataArr
  }

  static loadCsv (file: File): Promise < string > {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        try {
          resolve(`${reader.result}`)
        } catch (error: any) {
          handleError(error)
          reject(new AppError('Uploaded csv format invalid.'))
        }
      }
      reader.readAsText(file)
    })
  }

  static downloadBlob (data: Blob | string, filename: string, extension: 'json' | 'csv' | 'xlsx' | 'png' | 'pdf' = 'xlsx') {
    let type
    if (extension === 'xlsx') type = 'application/vnd.ms-excel'
    else if (extension === 'json') type = 'application/json'
    else if (extension === 'pdf') type = 'application/pdf'
    else if (extension === 'png') type = 'mage/png'
    else type = 'text/csv'

    const blob = new Blob([data], { type })
    if (window.navigator.msSaveBlob) {
        // IE and Edge
      window.navigator.msSaveBlob(blob, filename)
    } else {
        // Chrome etc.
      // const hiddenElement = document.createElement('a')
      // const url = window.URL || (window as any).webkitURL
      // hiddenElement.setAttribute('type', 'hidden')
      // hiddenElement.href = url.createObjectURL(blob)
      // hiddenElement.target = '_blank'
      // hiddenElement.download = filename
      // document.body.appendChild(hiddenElement)
      // hiddenElement.click()
      // document.body.removeChild(hiddenElement)

      const encodedUri = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', filename)
      link.click()
    }
  }

  static numberFormat = (num: any) => formatNumber(Math.round(num), 'en')

  static rectangleContains = (rect: {top: number, left: number, bottom: number, right: number}, coords: {x: number, y: number}) => {
    return coords.x >= rect.left &&
           coords.x <= rect.right &&
           coords.y >= rect.top &&
           coords.y <= rect.bottom
  }

  static invertIndex = (arrayLength: number, index: number) => {
    return arrayLength - index + 1
  }

  static findLast = <T>(arr: T[], cb: (val: T, i?: number) => boolean): T | undefined => {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (cb(arr[i], i)) return arr[i]
    }
  }

  static validUrl (str: string) {
    const parser = document.createElement('a')
    parser.href = str
    return (parser.host && parser.host !== window.location.host)
  }

  static isKey (e: KeyboardEvent, key: Key) {
    const keyInfo = Keys[key]
    if (e.key) return keyInfo.key.includes(e.key)
    // Disable below due to deprecation error
    // eslint-disable-next-line
    else if (e.keyCode) return e.keyCode === keyInfo.keyCode
    return false
  }

  static validateFile (file: File, accept: string | string[], maxSize: number) {
    if (file.size > maxSize) {
      throw new AppError(`Maximum file size(${maxSize / Math.pow(1024, 3)}GB) is exceeded.`)
    }
    const allowedTypes: string[] = Array.isArray(accept) ? accept : (accept || '').split(',')

    const matchesType = CommonUtil.fileTypeMatch(file, accept)

    if (!matchesType) {
      throw new AppError(`Uploaded extension invalid. \
        ${allowedTypes.length > 0 ? `Only accepting files with extension/type ${allowedTypes.join(', ')}.` : 'No known extensions/types for selected layer type.'} `)
    }
  }

  static fileTypeMatch (file: File, accept: string | string[]) {
    const allowedTypes: string[] = Array.isArray(accept) ? accept : (accept || '').split(',')
    if (allowedTypes.includes('*')) return true

    const name = file.name.toLowerCase()

    return allowedTypes.some((type: string) =>
     type.includes('.') ? name.endsWith(type) : new RegExp(`^${type.replace(/\*/g, '.*')}$`).test(file.type)
    )
  }

  static isObject (item: any) {
    return item && typeof item === 'object' && !Array.isArray(item)
  }

  static assignDeep (target: IJson, ...sources: IJson[]) {
    if (!sources.length) return target
    const source = sources.shift()

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} })
          this.assignDeep(target[key], source[key])
        } else {
          Object.assign(target, { [key]: source[key] })
        }
      }
    }

    this.assignDeep(target, ...sources)
  }

  static arrayBuffer2String (buf: ArrayBuffer) {
    return String.fromCharCode(...new Uint8Array(buf))
  }

  static downloadImageCorsAnonymous (url: string, fileName: string): Promise<File> {
    return new Promise((res, rej) => {
      const image = new Image()
      image.crossOrigin = 'Anonymous'

      const imageReceived = () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d') as CanvasRenderingContext2D
        canvas.width = image.width
        canvas.height = image.height
        context.drawImage(image, 0, 0)
        try {
          const file = ConvertUtil.dataURLtoFile(
            canvas.toDataURL('image/png'), fileName
          )
          res(file)
        } catch (error: any) {
          rej(error)
        }
      }

      image.addEventListener('load', imageReceived, false)
      image.src = url
    })
  }

  static openLink (url: string) {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = url
    a.click()
  }

  static optimalPaginationSize (forLength: number, maxPerPage: number, minPerPage: number = 2) {
    const remainders: { pp: number, remainder: number}[] = []
    for (let i = maxPerPage; i > minPerPage; i--) {
      remainders.push({ pp: i, remainder: forLength % i })
    }
    const optimal = remainders.find(x => x.remainder === 0)
    return (optimal && optimal.pp) || maxPerPage
  }

  static roundToNearest (num: number) {
    const round = +`1${Array(`${Math.floor(num)}`.length - 1).fill(0).join('')}`
    return Math.ceil(num / round) * round
  }

  static countDecimals (value: number) {
    return (`${value}`.toString().split('.')[1] || '').length
  }

  static randomArraySample (arr: any[], size: number) {
    let len = arr.length
    if (size > len) return arr

    const result = new Array(size)
    const taken = new Array(len)

    while (size--) {
      let x = Math.floor(Math.random() * len)
      result[size] = arr[x in taken ? taken[x] : x]
      taken[x] = --len in taken ? taken[len] : len
    }
    return result
  }

  static find <T> (arr: T[], where: (item: T) => boolean, reverse = false) {
    const start = reverse ? arr.length - 1 : 0
    const increment = reverse ? -1 : 1

    for (let i = start; (reverse ? i >= 0 : i < arr.length); i += increment) {
      if (where(arr[i])) return arr[i]
    }
  }

  static filter <T> (arr: T[], where: (item: T) => boolean, reverse = false) {
    const start = reverse ? arr.length - 1 : 0
    const increment = reverse ? -1 : 1

    const filtered: T[] = []
    for (let i = start; (reverse ? i >= 0 : i < arr.length); i += increment) {
      if (where(arr[i])) {
        if (reverse) filtered.splice(0, 0, arr[i])
        else filtered.push(arr[i])
      }
    }
  }

  static arrayIntoChunks <T> (items: T[], chunks: number): T[][] {
    const itemsToChunk = [...items]
    const itemsPerChunk = Math.ceil(items.length / chunks)

    let arr = new Array(chunks)
    .fill([])

    if (itemsPerChunk > 0) arr = arr.map(_ => itemsToChunk.splice(0, itemsPerChunk))

    return arr
  }

  static sameSign (num1: number, num2: number) {
    return (num1 < 0 && num2 < 0) || (num1 >= 0 && num2 >= 0)
  }

  static generalArrayChunk(array: any[], size: number)  {
    const chunkedArr: any[] = []
    let index = 0
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index))
      index += size
    }
    return chunkedArr
  }

  static splitSortDictionary(dict: any): [any, any] {
    const items = Object.keys(dict).map((key: string) => {
      return [key, dict[key]];
    })

    // Sort the array based on the second element
    items.sort((first, second) => {
      return second[1] - first[1];
    })

    return [items.map(i=>i[0]), items.map(i=>i[1])]
  }
}
