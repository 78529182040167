import { Component, Inject, OnDestroy } from '@angular/core'
import { Validators } from '@angular/forms'
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import FormValidators from '@core/utils/form-validators/form-validators'
import { FormTemplate } from '@core/models/form-template'
import { AppLayer } from '@core/models/layer'
import { TypedFormControl, TypedFormGroup } from '@core/models/typed-form-control'
import { LayerService, WorkspaceService } from '@services/workspace'
import Feature from 'ol/Feature'
import { Subscription } from 'rxjs'
import AppError, { handleError } from '@core/models/app-error'
import { MatSelectChange } from '@angular/material/select'
import { MatTableDataSource } from '@angular/material/table'
import { AuthService, SettingsService, VipApiService } from '@services/core'
import { IPNewLayer, IPNewVectorColumn, IPNewVectorRow, IRVectorColumn, IRVectorColumnType } from '@vip-shared/interfaces'
import { Db } from '@vip-shared/models/db-definitions'
import { Columns } from '@vip-shared/models/const/system-vector-cols'
import { UnreachableCaseError } from '@vip-shared/generics/exhaustive-switch'
import OlUtil from '@core/utils/ol/ol.util'
import { ConvertUtil } from '@core/utils/index'

interface DialogData {
  geometry: string
  sources: {
    layer: AppLayer,
    features: Feature[]
  }[]
  warning?: string
}

type Form = TypedFormGroup<{
  geometry: TypedFormControl<string>
  layer: TypedFormControl<AppLayer>
  newLayer: TypedFormControl<string>
  replace: TypedFormControl<boolean>
}>

type ColumnInputs = {
  name: TypedFormControl<string>
  prop: TypedFormControl<string>
  type: TypedFormControl<IRVectorColumnType>
  aggregation?: TypedFormControl<Db.Helper.Geo.CompositeAggregation>
  value: TypedFormControl<string | number>
  [layerKey: string]: TypedFormControl<any> | undefined
}

interface CompositeSource {
  prop: string
  title: string
  columns: AppLayer['attributeColumns']
  features: Feature[]
}

@Component({
  selector: 'app-composite-geom-dialog',
  templateUrl: './composite-geom-dialog.component.html',
  styleUrls: ['./composite-geom-dialog.component.scss']
})
export class CompositeGeomDialogComponent extends FormTemplate<Form> implements OnDestroy {
  private _subscriptions = new Subscription()
  private _tableSubscriptions = new Subscription()

  static setup (data: DialogData) {
    return {
      data,
      disableClose: true
    } as MatDialogConfig
  }

  errors: string[] = []
  layers: AppLayer[] = []

  compositeSources: CompositeSource[]

  columns: ColumnInputs[] = []
  columnsMeta: IRVectorColumn[] = []

  columnData?: MatTableDataSource<ColumnInputs>
  columnDataVisibleCols: string[] = []
  stickyHeader = true

  aggregationOptions = Object.values(Db.Helper.Geo.CompositeAggregation)
  availableTypes: IRVectorColumnType[] = ['string', 'integer', 'float', 'boolean', 'date']

  columnValueOptions: {
    [prop: string]: string[]
  } = {}

  get valid () {
    return this.form.valid && this.columns.every(c =>
      Object.values(c).every(v => !v || v.disabled || v.valid)
    )
  }

  inputLayer = {
    title: 'New Layer',
    [FormValidators.matSelectInput]: true
  } as any as AppLayer

  canReplace = false

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected _dialogRef: MatDialogRef<CompositeGeomDialogComponent>,
    private _layerService: LayerService,
    private _settingsService: SettingsService,
    private _authService: AuthService,
    private _workspaceService: WorkspaceService,
    private _vipApiService: VipApiService
  ) {
    super(new TypedFormGroup({
      geometry: new TypedFormControl<string>(data.geometry, [Validators.required, FormValidators.wktValid(['Polygon', 'MultiPolygon'])]),
      layer: new TypedFormControl<AppLayer>(undefined, Validators.required),
      newLayer: new TypedFormControl<string>(undefined, FormValidators.customSelectOption('layer')),
      replace: new TypedFormControl<boolean>(false, Validators.required)
    }), _dialogRef)

    this.stickyHeader = !this._settingsService.isIEorEdge(false)

    this.compositeSources = data.sources.map(s => ({
      prop: s.layer.id,
      title: `Layer:\n${s.layer.title}`,
      columns: s.layer.attributeColumns.filter(c => !Columns.System.includes(c.prop)),
      features: s.features
    }))
    this.columnDataVisibleCols = [
      'name', 'prop', 'type',
      ...this.compositeSources.map(s => s.prop),
      'aggregation', 'value', 'actions'
    ]

    this.GetValidDestinations()
    this._subscriptions.add(
      this._layerService.layerArrayChanged.subscribe(
        this.GetValidDestinations.bind(this)
      )
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  private GetValidDestinations () {
    this.layers = this._layerService.allLayers.filter(l =>
      !l.preset && l.vector
    )

    this.layers.splice(0, 0, this.inputLayer)
  }

  setDestination (e: MatSelectChange) {
    this._tableSubscriptions.unsubscribe()
    this._tableSubscriptions = new Subscription()

    this.form._controls.newLayer.updateValueAndValidity()

    const layer: AppLayer = e.value
    if (layer === this.inputLayer) {
      // Don't remove existing column configuration as we don't have
      // predefined attribute columns and can use current setup
      for (const col of this.columns) {
        col.name.enable()
        col.prop.enable()
        col.type.enable()
      }
      this.canReplace = false
      this.form._controls.replace.setValue(false)
      return
    } else {
      const prevCols = this.columns
      this.columns = []
      this.columnsMeta = []
      const columnsToCalculate: ColumnInputs[] = []

      this.canReplace = !!this.data.sources.find(s => s.layer === layer && s.features.length > 0)

      for (const col of layer.attributeColumns) {
        if (Columns.System.includes(col.prop)) continue

        const colMeta = layer.columnMeta.find(c => c.prop === col.prop)
        const compositeConf = colMeta && colMeta.composite
        const colAliases = (compositeConf && compositeConf.alias) || []

        const oldInputs = prevCols.find(f => f.prop.value === col.prop)
        const agg = (compositeConf && compositeConf.aggregation) ||
          (oldInputs && oldInputs.aggregation && oldInputs.aggregation.value)
        const colTypeDef = layer.tableColumns.find(tCol => tCol.name === col.prop)

        const canAggregate = ['float' , 'integer'].includes((colTypeDef && colTypeDef.type) as string)
        const inputs: ColumnInputs = {
          name: new TypedFormControl<string>({
            value: col.override || col.prop,
            disabled: true
          }, Validators.required),
          type: new TypedFormControl<IRVectorColumnType>({
            value: (colTypeDef && colTypeDef.type) || 'any',
            disabled: true
          }, Validators.required),
          prop: new TypedFormControl<string>({
            value: col.prop,
            disabled: true
          }, [
            Validators.required,
            Validators.pattern('[a-z0-9_]+'),
            FormValidators.notSystemColumn
          ]),
          aggregation: !canAggregate ? undefined : new TypedFormControl<Db.Helper.Geo.CompositeAggregation>(
            agg ||
            Db.Helper.Geo.CompositeAggregation.AVERAGE, Validators.required
          ),
          value: new TypedFormControl<string | number>(
            undefined,
            // Required if not null according to column type definition
            (colTypeDef && !colTypeDef.nullable) ? Validators.required : undefined
          )
        }

        for (const src of this.compositeSources) {
          const props = src.columns.map(c => c.prop)
          const hasColumn = props.includes(col.prop)

          const ctrl = new TypedFormControl<any>(undefined)
          if (hasColumn) {
            columnsToCalculate.push(inputs)
            ctrl.setValue(col.prop)
          } else if (colMeta && colMeta.composite) {
            columnsToCalculate.push(inputs)
            const alias = colAliases.find(alias => props.includes(alias))
            ctrl.setValue(alias)
          }

          inputs[src.prop] = ctrl
        }

        this.columns.push(inputs)
      }

      this.columnData = new MatTableDataSource(this.columns)

      for (const col of columnsToCalculate) {
        this.compSelectionChange(col)
      }
    }
  }

  async save () {
    if (this.saving) return
    this.errors = []

    try {
      if (!this.form.valid) throw new AppError(`Form invalid.`)
      this.saving = true

      // Generate feature properties object
      const props = {}
      for (const col of this.columns) {
        props[col.prop.value as string] = col.value.value
      }

      let layer = this.form._controls.layer.value as AppLayer
      if (!layer) throw new AppError(`Target layer is not selected.`)

      // Get list of layer columns/properties which do not exist in layer yet
      const attrCols = layer.attributeColumns || []
      const newColumns = this.columns.filter(c =>
        !attrCols.find(ac => ac.prop === c.prop.value)
      )
      const newColTypeDefs: IPNewVectorColumn[] = newColumns.map(c => ({
        name: c.prop.value as any,
        type: c.type.value as IPNewVectorColumn['type'],
        nullable: true
      }))

      // Add new columns/properties to attribute column definition
      for (const col of newColumns) {
        attrCols.push({
          prop: col.prop.value as string,
          override: col.name.value,
          display: true,
          display_attribute_table: true
        })
      }

      // Get list of rendered column names for every layer (not columns in target layer)
      const layerCols = this.compositeSources.map(s => s.prop)

      const currentColMeta: Db.Helper.Geo.ColumnMeta[] = this.columns.map(c => {
        const alias = layerCols.map(x => {
          const ctrl = c[x]
          return ctrl && ctrl.value as string | undefined
        }).filter(x => !!x) as string[]
        return {
          prop: c.prop.value as string,
          composite: {
            alias,
            aggregation: c.aggregation && c.aggregation.value
          }
        }
      })

      if (layer === this.inputLayer) {
        // If it's new layer, turn feature to feature collection geojson and save
        // like 'vector file' upload
        const feature = OlUtil.featureToGeoJSON(
          OlUtil.wktToFeature(this.form._controls.geometry.value as string)
        )
        if (!feature) {
          throw new AppError(`Failed to create GeoJSON from WKT value.`)
        }
        feature.properties = props

        const vectorLayer: IPNewLayer = {
          customer_id: this._authService.customerId,
          workspace_id: this._workspaceService.workspaceId as number,
          product_id: this._workspaceService.product as number,
          name: this.form._controls.newLayer.value as string,
          source_type_id: Db.Vip.SourceType.VECTOR,
          visible: true,
          column_meta: currentColMeta,
          is_time_series: false,
          spatial_query_required: false
        }

        const id = await this._vipApiService.orm.Layers()
        .upload(vectorLayer, [
          ConvertUtil.geojsonToFile(OlUtil.featuresToCollection([feature]), 'composite.json')
        ])
        .run()
        if (!id) throw new AppError(`Failed to create new layer for composite geometry.`)

        const newLayer = await this._layerService.loadSource(id)
        if (!newLayer) throw new AppError(`Failed to load new layer.`)

        layer = newLayer
      } else {
        // If layer already exists, create new columns that are not yet in dataset
        if (newColTypeDefs.length) {
          await layer.createColumns(newColTypeDefs)
        }

        // Update attribute columns
        layer.attributeColumns = attrCols

        const compositeSrc = this.compositeSources.find(s => s.prop === layer.id)
        const features = compositeSrc ? compositeSrc.features : []

        // Create row entry for new geometry
        const row: IPNewVectorRow = {
          wkt_geometry: this.form._controls.geometry.value as string,
          ...props
        }

        if (features.length && this.form._controls.replace.value) {
          // Replace composite features included in this feature from target layer with this feature
          await layer.replaceFeaturesList(features.map(f => f.get(layer.idKey)), [row])
        } else {
          // Insert this feature into target layer
          await layer.insertFeatures([row])
        }

        // Update existing layers column metadata
        layer.applyColumnMeta(layer.columnMeta)
      }

      this.submitted = true
      this._dialogRef.close(true)
    } catch (error: any) {
      handleError(error)
      this.errors.push(error.message)
    }

    this.saving = false
  }

  addColumn () {
    const newCol: ColumnInputs = {
      name: new TypedFormControl<string>(undefined, Validators.required),
      prop: new TypedFormControl<string>(undefined, [
        Validators.required,
        Validators.pattern('[a-z0-9_]+'),
        FormValidators.notSystemColumn
      ]),
      type: new TypedFormControl<IRVectorColumnType>('string', Validators.required),
      aggregation: undefined,
      value: new TypedFormControl<string | number>()
    }
    for (const src of this.compositeSources) {
      const ctrl = new TypedFormControl<any>(undefined)
      // TODO: Or find alias
      newCol[src.prop] = ctrl
    }

    this.columns.push(newCol)
    this.columnData = new MatTableDataSource(this.columns)
  }

  removeColumn (col: ColumnInputs) {
    this.columns.splice(this.columns.indexOf(col), 1)
    this.columnData = new MatTableDataSource(this.columns)
  }

  setColValue (col: ColumnInputs, val: any) {
    col.value.setValue(val)
  }

  typeChange (col: ColumnInputs) {
    const canAggregate = ['float', 'integer'].includes(col.type.value as any)
    if (canAggregate && !col.aggregation) {
      let layer = this.form._controls.layer.value
      if (layer === this.inputLayer) layer = undefined

      const colMeta = layer && layer.columnMeta.find(c => c.prop === col.prop.value)
      const compositeConf = colMeta && colMeta.composite

      const agg = compositeConf && compositeConf.aggregation

      col.aggregation = new TypedFormControl<Db.Helper.Geo.CompositeAggregation>(
        agg ||
        Db.Helper.Geo.CompositeAggregation.AVERAGE, Validators.required
      )
    } else if (!canAggregate) {
      col.aggregation = undefined
    }

    this.compSelectionChange(col)
  }

  aggregationChange (col: ColumnInputs) {
    if (!col.aggregation) return
    this.CalculateNumericValue(col)
  }

  compSelectionChange (col: ColumnInputs) {
    if (col.aggregation) {
      delete this.columnValueOptions[col.prop.value as string]
      this.CalculateNumericValue(col)
      return
    }

    this.CalculateOtherValue(col)
  }

  private CalculateOtherValue (col: ColumnInputs) {
    const layerEntries = this.compositeSources.map(l => {
      const ctrl = col[l.prop]
      return {
        ...l,
        control: ctrl,
        values: ctrl && ctrl.value ? l.features.map(f => f.get(ctrl.value)) : []
      }
    })
    const valueSet = layerEntries.reduce((set, e) => {
      for (const val of e.values) {
        set.add(val)
      }
      return set
    }, new Set<any>())

    const key = col.prop.value as string
    this.columnValueOptions[key] = Array.from(valueSet)
    if (!col.value.value && this.columnValueOptions[key].length === 1) {
      col.value.setValue(this.columnValueOptions[key][0])
    }
  }

  private CalculateNumericValue (col: ColumnInputs) {
    if (!col.aggregation || !col.aggregation.value) {
      col.value.setValue(undefined)
      return
    }
    const layerEntries = this.compositeSources.map(l => {
      const ctrl = col[l.prop]
      return {
        ...l,
        control: ctrl,
        values: ctrl && ctrl.value ? l.features.map(f => f.get(ctrl.value)) : []
      }
    })
    const values = layerEntries.reduce((obj, e) => {
      obj.arr = obj.arr.concat(e.values)
      obj.sum += e.values.reduce((sum, val) => sum + val, 0)
      return obj
    }, {
      arr: [] as number[],
      sum: 0
    })

    if (!values.arr.length) {
      col.value.setValue(undefined)
      return
    }

    let value: number | undefined
    switch (col.aggregation.value) {
      case Db.Helper.Geo.CompositeAggregation.AVERAGE:
        value = values.sum / values.arr.length
        break
      case Db.Helper.Geo.CompositeAggregation.MAX:
        value = Math.max(...values.arr)
        break
      case Db.Helper.Geo.CompositeAggregation.MIN:
        value = Math.min(...values.arr)
        break
      case Db.Helper.Geo.CompositeAggregation.SUM:
        value = values.sum
        break
      default:
        throw new UnreachableCaseError(col.aggregation.value)
    }

    col.value.setValue(value)
  }

}
