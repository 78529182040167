import { Injectable } from '@angular/core'
import { AppLayer } from '@core/models/layer'
import { Subject, Observable, Subscription, merge } from 'rxjs'
import { WorkspaceService } from '../workspace.service'
import { LayerService } from '../layer/layer.service'
import { AlertService } from '@services/core/alert/alert.service'
import * as moment from 'moment'
import { CommonUtil } from '@core/utils/index'
import { debounceTime } from 'rxjs/operators'
import { AppDroneLayer } from '@core/models/layer/app-drone-layer'
import { AppRasterLayer } from '@core/models/layer/app-raster-layer'
import { WorkspaceMapService } from '../workspace-map/workspace-map.service'
import Feature from 'ol/Feature'
@Injectable({
  providedIn: 'root'
})
export class AttributeTableService {
  private _layerSubscriptions = new Subscription()

  private _enabled = false
  private _visible = true
  private _collapsed = true
  selectedLayer?: AppLayer
  locked: boolean = false

  private _selectItemsInTable = new Subject<Feature[]>()
  private _enabledChange = new Subject<boolean>()
  private _visibleChange = new Subject<boolean>()
  private _collapsedChange = new Subject<boolean>()
  private _layerChange = new Subject<boolean>()
  private _featuresColorChanged = new Subject()
  private _columnVisibilityChanged = new Subject<AppLayer>()
  private _setActiveLayer = new Subject<{layer: AppLayer, force: boolean}>()
  private _splitUpdated = new Subject()
  private _domTargetId?: string

  editMode = false
  get enabled (): boolean {
    return this._enabled
  }

  get visible (): boolean {
    return this._visible
  }

  get collapsed (): boolean {
    return this._collapsed
  }

  get enabledChange (): Observable<boolean> {
    return this._enabledChange.asObservable()
  }

  get visibleChange (): Observable<boolean> {
    return this._visibleChange.asObservable()
  }

  get columnVisibilityChange (): Observable<AppLayer> {
    return this._columnVisibilityChanged.asObservable()
  }

  get collapsedChange (): Observable<boolean> {
    return this._collapsedChange.asObservable()
  }

  get featuresColorChanged (): Observable<any> {
    return this._featuresColorChanged.asObservable()
  }

  get layerChanged (): Observable<any> {
    return this._layerChange.asObservable()
  }

  private _tableRefresh = new Subject<undefined>()
  get tableRefresh (): Observable<undefined> {
    return this._tableRefresh.asObservable()
  }

  private _rowsRefresh = new Subject<{
    layer: AppLayer
    features: Feature[]
  }>()
  get rowsRefresh () {
    return this._rowsRefresh.asObservable()
  }

  private _rowsRemoved = new Subject<{
    layer: AppLayer
    features: Feature[]
  }>()
  get rowsRemoved () {
    return this._rowsRemoved.asObservable()
  }

  get layerTitle (): string {
    return this.selectedLayer && this.selectedLayer.title || ''
  }

  get selectItemsInTable () {
    return this._selectItemsInTable.asObservable()
  }

  get splitUpdated () {
    return this._splitUpdated.asObservable()
  }

  constructor (
    workspaceService: WorkspaceService,
    layerService: LayerService,
    private _alertService: AlertService,
    workspaceMapService: WorkspaceMapService
  ) {
    workspaceService.onExit.subscribe(() => {
      this.toggleTable(false)
      this._collapsed = false
      this.ClearLayerSelection()
    })

    this._setActiveLayer.pipe(
      debounceTime(500)
    ).subscribe(async ev => this.SetActiveLayer(ev.layer))

    layerService.layerRemoved.subscribe(layer => {
      if (this.selectedLayer && layer.id === this.selectedLayer.id) {
        this.toggleTable(false)
        this.ClearLayerSelection()
      }
    })

    workspaceMapService.mapClicked.subscribe(e => {
      const selectedLayerClicked = () => e.layer === (this.selectedLayer && this.selectedLayer.layer)
      if (e.features) {
        setTimeout(() => {
          if (!selectedLayerClicked()) return
          if (!e.features || !e.features.length) return
          this._selectItemsInTable.next(e.features)
        }, this.visible && this.enabled && selectedLayerClicked() ? 0 : 1000)
      }
    })

    layerService.layerSelectionChange.subscribe(l => l && this.setActiveLayer(l))
  }

  setTarget (val: string | undefined) {
    this._domTargetId = val
  }

  setActiveLayer (layer: AppLayer, force: boolean = false) {
    this._setActiveLayer.next({ layer, force })
  }

  private async SetActiveLayer (layer: AppLayer) {
    if (!layer || this.locked) return

    let columns = layer.attributeColumns
    if (layer instanceof AppRasterLayer || layer instanceof AppDroneLayer) {
      this.toggleTable(false)
      return
    }

    if (!columns || !columns.length) {
      this._alertService.log('No attributes to display for this layer')
      this.toggleTable(false)
      return
    }

    if (this.selectedLayer !== layer) {
      if (this.selectedLayer) this.ClearLayerSelection()
      this.selectedLayer = layer

      this._layerSubscriptions.add(
        this.selectedLayer.reloaded.subscribe(fts => {
          if (fts) {
            this._rowsRefresh.next({
              features: fts,
              layer: layer
            })
          } else {
            this.refreshTable()
          }
        })
      )

      this._layerSubscriptions.add(
        this.selectedLayer.removed.subscribe(fts => {
          if (fts) {
            this._rowsRemoved.next({
              features: fts,
              layer: layer
            })
          } else {
            this.refreshTable()
          }
        })
      )

      this._layerSubscriptions.add(
        merge(
          this.selectedLayer.styleSynced,
          this.selectedLayer.styleChanged
        )
        .subscribe(() =>
        this.updateTableStylePreview())
      )

      this._layerSubscriptions.add(
        this.selectedLayer.columnsChanged.subscribe(() =>
          this.refreshTable()
        )
      )

      this._layerChange.next(false)
    }

    this.toggleTable(true, this.collapsed ? !this.selectedLayer.layerMode : false)
  }

  private ClearLayerSelection () {
    this._layerSubscriptions.unsubscribe()
    this._layerSubscriptions = new Subscription()
    this.selectedLayer = undefined
  }

  async attributeTableRendered (timeoutS: number = 1) {
    const start = moment()
    const rendered = () => {
      if (!this._domTargetId) return false
      const doc = document.getElementById(this._domTargetId)
      if (!doc) return false
      return doc.clientHeight > 0 && doc.clientWidth > 0
    }
    while (!rendered() && moment().diff(start, 's') <= timeoutS) {
      await CommonUtil.delay(100)
    }
    return rendered()
  }

  updateColumnsVisibility (forLayer: AppLayer) {
    this._columnVisibilityChanged.next(forLayer)
  }

  toggleTable (enabled?: boolean, collapse = false) {
    if (this._enabled !== enabled) {
      this._enabled = enabled !== undefined ? enabled : !this._enabled
      if (this._enabled && !collapse) this.toggleCollapse(false)
      this._enabledChange.next(this._enabled)
    }
  }

  toggleSelectionLock () {
    this.locked = !this.locked
  }

  refreshTable () {
    this._tableRefresh.next(undefined)
  }

  refreshTabs () {
    this._visibleChange.next(this._visible)
  }

  toggleVisibility (visible?: boolean) {
    if ((this._enabled || visible === false) && visible !== this._visible) {
      this._visible = visible !== undefined ? visible : !this._visible
      this._visibleChange.next(this._visible)
    }
  }

  toggleCollapse (collapsed?: boolean, force = false) {
    if (force || (this._enabled && this._collapsed !== collapsed)) {
      this._collapsed = collapsed !== undefined ? collapsed : !this._collapsed
      this._collapsedChange.next(this._collapsed)
    }
  }

  updateTableStylePreview () {
    this._featuresColorChanged.next({})
  }

  splitPositionUpdated () {
    this._splitUpdated.next({})
  }
}
