import { Component, Input, Output, EventEmitter } from '@angular/core'
import { AlertService, SettingsService, PromptService } from '@services/core'
import AppError, { handleError } from '@core/models/app-error'
import { CommonUtil, ConvertUtil } from '@core/utils/index'
import { ZipService } from '@services/core/zip/zip.service'
import { JSZipObject } from 'jszip'
import { FeatureCollection } from 'geojson'
import * as shapefile from 'shapefile'
import { IJson } from '@vip-shared/interfaces'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { UploadListDialogComponent } from '@core/page-components/upload-list-dialog/upload-list-dialog.component'

@Component({
  selector: 'app-vector-upload-button',
  templateUrl: './vector-upload-button.component.html',
  styleUrls: ['./vector-upload-button.component.scss']
})
export class VectorUploadButtonComponent implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }

  @Input() captureWindow = false
  @Input() buttonText = 'Browse or Drag Here'
  @Input() multiple = false
  @Output() filesSelected = new EventEmitter<File[]>()
  @Input() allowRepeating = true
  @Output() idleShapefilesChange = new EventEmitter<any>()
  @Output() clearSelected = new EventEmitter()

  shapefiles: {[key: string]: {
    dbf?: File | JSZipObject
    shp?: File | JSZipObject
    prj?: File | JSZipObject
    uploaded?: boolean
  }} = {}
  acceptFormats = ['.zip', '.rar', '.dbf', '.prj', '.shp', '.json', '.geojson']

  private _parsing = false

  private _pendingCount = 0
  get pendingCount (): number {
    return this._pendingCount
  }

  private _pendingShapefileNames: string[] = []
  get pendingShapefileNames (): string[] {
    return this._pendingShapefileNames
  }

  private UpdatePending () {
    this._pendingCount = Object.keys(this.shapefiles).map(x =>
      this.shapefiles[x].uploaded ? 0 : ['dbf', 'shp', 'prj'].filter(k => !this.shapefiles[x][k]).length
    ).reduce((sum, x) => sum + x, 0)

    this._pendingShapefileNames = Object.keys(this.shapefiles).filter(x =>
      this.shapefiles[x].uploaded ? false : ['dbf', 'shp', 'prj'].filter(k => !this.shapefiles[x][k]).length > 0
    )
  }

  constructor (
    private _alertService: AlertService,
    private _settingsService: SettingsService,
    private _zipService: ZipService,
    private _promptService: PromptService,
    private _dialog: MatDialog
  ) { }

  async sortGeometryFiles (files?: File[]) {
    if (this._parsing) return
    if (!files) return

    let geojsonFiles: File[] = []
    this.clearSelected.next({})
    try {
      for (const file of files) {
        if (['shx', 'qpj', 'cpg'].some(e => file.name.endsWith(`.${e}`))) continue

        CommonUtil.validateFile(file, this.acceptFormats, this._settingsService.maxApiFileSize)
        const name = file.name.split('.').slice(0, -1).join('.')
        if (this._zipService.isZipped(file)) {
          const { files: zipFilesObj } = await this._zipService.getEntries(file)
          const zipFiles = Object.values(zipFilesObj)
          const dbf = zipFiles.find(x => x.name.toLowerCase().endsWith('.dbf'))
          const shp = zipFiles.find(x => x.name.toLowerCase().endsWith('.shp'))
          const prj = zipFiles.find(x => x.name.toLowerCase().endsWith('.prj'))

          if (this.shapefiles[name]) throw new AppError(`Shapefile '${name}' data already selected from another file with same name.`)

          this.shapefiles[name] = { dbf, shp, prj }
        } else if (this.IsGeojson(file)) {
          geojsonFiles.push(file)
        } else if (this.IsPartOfShapefile(file)) {
          if (!this.shapefiles[name]) this.shapefiles[name] = {}
          if (this.shapefiles[name].uploaded) throw new AppError(`Shapefile '${name}' already uploaded.`)
          const ext = file.name.toLowerCase().split('.').pop()

          if (ext === 'dbf') this.shapefiles[name].dbf = file
          if (ext === 'shp') this.shapefiles[name].shp = file
          if (ext === 'prj') this.shapefiles[name].prj = file
        } else {
          console.warn(`File '${file.name}' is not recognised as geojson or a (part of) shapefile`)
        }
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(`Failed to read selected files. ${error.message}`)
    }

    try {
      geojsonFiles.push(
        ...(await this.ConvertShapefilesToGeojson()).map((x) => ConvertUtil.json2File(x.json, `${x.keyName}.json`))
      )
    } catch (error: any) {
      handleError(error)
      this._alertService.log(`Failed to transform shapefiles to geojson. ${error.message}`)
    }

    if (!this.multiple) {
      const layerKeys = Array.from(new Set([
        ...geojsonFiles.map(x => x.name),
        ...Object.keys(this.shapefiles)
      ]))

      if (layerKeys.length > 1) {
        const keepLayer = await new Promise((res) => {
          this._promptService.prompt(
            `Only single vector upload is allowed, but detected multiple vectors. Please select one.`
            , {
              layers: layerKeys,
              cancel: (): void => res(null),
              select: (option: {layers: string}) => res(option.layers)
            }, () => res(null)
          )
        })
        if (!keepLayer) return
        const layer = geojsonFiles.find(x => x.name === keepLayer) as any
        if (layer) {
          geojsonFiles = [layer]
          this.shapefiles = {}
        } else {
          geojsonFiles = []
          for (const k in this.shapefiles) {
            if (k !== keepLayer) {
              delete this.shapefiles[k]
            }
          }
        }
      }
    }
    this.filesSelected.next(geojsonFiles)
    this.UpdatePending()
  }

  listUploadedFiles (e: MouseEvent) {
    e.stopPropagation()
    const keys = Object.keys(this.shapefiles)
    const pending = keys.filter(x => !this.shapefiles[x].uploaded)

    this._trackDialog(
      this._dialog.open(UploadListDialogComponent, {
        data: {
          uploaded: [],
          pending: pending.map(x => ({
            name: x,
            missing: ['dbf', 'shp', 'prj'].filter(k => !this.shapefiles[x][k]),
            uploaded: ['dbf', 'shp', 'prj'].filter(k => this.shapefiles[x][k])
          }))
        }
      })
    )
  }

  private async ConvertShapefilesToGeojson (): Promise<{
    keyName: string,
    json: FeatureCollection
  }[]> {
    const geojsons: {
      keyName: string,
      json: FeatureCollection
    }[] = []

    for (const k of Object.keys(this.shapefiles)) {
      try {
        const { dbf, prj, shp } = this.shapefiles[k]
        if (!dbf || !prj || !shp) {
          continue
        }

        const dbfArr = await this.GetArrayBuffer(dbf)
        const prjArr = await this.GetArrayBuffer(prj)
        const shpArr = await this.GetArrayBuffer(shp)

        const features: any[] = []
        await shapefile.open(shpArr, dbfArr)
        .then(source => source.read()
          .then(function log (result) {
            if (result.done) return
            features.push(result.value)
            return source.read().then(log)
          })
        )

        geojsons.push({
          keyName: k,
          json: {
            crs: {
              type: 'name',
              properties: { name: CommonUtil.arrayBuffer2String(prjArr) }
            },
            type: 'FeatureCollection',
            features
          } as any
        })
        if (this.allowRepeating) delete this.shapefiles[k]
      } catch (error: any) {
        delete this.shapefiles[k]
        throw error
      }

    }

    return geojsons
  }

  private async GetArrayBuffer (file: File | JSZipObject): Promise<ArrayBuffer> {
    return file instanceof File ? this.ReadFileAsArrayBuffer(file) : file.async('arraybuffer')
  }

  private ReadFileAsArrayBuffer (file: File): Promise<ArrayBuffer> {
    return new Promise((res, rej) => {
      const reader = new FileReader()
      reader.addEventListener('loadend', function (e) {
        if (!reader.result) {
          rej(new AppError(`Web browser was unable to load the file '${file.name}'.`))
        } else {
          res(reader.result as ArrayBuffer)
        }
      })
      reader.readAsArrayBuffer(file)
    })
  }

  private IsGeojson (file: File) {
    if (!file.type || file.type === 'application/octet-stream') {
      const name = file.name.toLowerCase()
      return name.endsWith('.json') || name.endsWith('.geojson')
    }
    return ['application/json', 'application/geo+json', 'application/vnd.geo+json'].includes(file.type)
  }

  private IsPartOfShapefile (file: File) {
    const name = file.name.toLowerCase()
    return name.endsWith('.dbf') || name.endsWith('.prj') || name.endsWith('.shp')
  }
}

applyMixins(VectorUploadButtonComponent, [DialogCleanup])
