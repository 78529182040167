import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core'
import Map from 'ol/Map'
import * as olProj from 'ol/proj'
import View from 'ol/View'
import * as olLayer from 'ol/layer'
import * as olSource from 'ol/source'
import * as olExtent from 'ol/extent'
import Wkt from 'ol/format/WKT'
import OlUtil from '@core/utils/ol/ol.util'
import { ColourUtil } from '@core/utils/index'
import { Subscription, Subject } from 'rxjs'
import { debounceTime, auditTime } from 'rxjs/operators'
import { environment } from 'environments/environment'
import { ResizeSensor } from 'css-element-queries'
import { handleError } from '@core/models/app-error'
@Component({
  selector: 'app-wkt-preview',
  templateUrl: './wkt-preview.component.html',
  styleUrls: ['./wkt-preview.component.scss']
})
export class WktPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('miniMap', { static: true }) private _miniMap!: ElementRef<HTMLElement>

  private _wkt?: string
  @Input()
  get wkt () {
    return this._wkt
  }
  set wkt (val: string | undefined) {
    this._wkt = val
    if (this._preview) this._updateMap.next({})
  }

  private _contextList?: string[]
  @Input()
  get contextList () {
    return this._contextList
  }
  set contextList (val: string[] | undefined) {
    this._contextList = val
    if (this._preview) this._updateMap.next({})
  }

  private _map?: Map
  private _error?: string
  get error () {
    return this._error
  }

  private _preview!: olLayer.Vector<any>

  private _subscriptions = new Subscription()
  private _updateMap = new Subject()
  private _zoomMap = new Subject()
  private _lastExtent?: olExtent.Extent

  private _resizeSensor?: ResizeSensor

  ngOnInit () {
    this._subscriptions.add(
      this._updateMap.pipe(
        debounceTime(700)
      ).subscribe(() => this.LoadWktSource())
    )
    this._subscriptions.add(
      this._zoomMap.pipe(
        auditTime(100)
      ).subscribe(() => (this._map && this._lastExtent) && this._map.getView().fit(this._lastExtent))
    )

    // TODO: Known issue, if geometry is really large, then
    // base map fails to render with 'Uncaught ReferenceError: olc_20530 is not defined'
    const base = new olLayer.Tile({
      source: new olSource.BingMaps({
        key: environment.bingKey,
        imagerySet: 'AerialWithLabels',
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        maxZoom: 19
      })
    })

    this._preview = new olLayer.Vector<any>()
    this.LoadWktSource()
    this._map = new Map({
      layers: [base, this._preview],
      interactions: [],
      view: new View({
        center: olProj.fromLonLat([0, 0]),
        resolutions: OlUtil.getResolution(),
        extent: olProj.transformExtent([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857')
      }),
      target: this._miniMap.nativeElement
    })

    this._zoomMap.next({})

    this._resizeSensor = new ResizeSensor(
      this._miniMap.nativeElement,
      () => this._map && this._map.updateSize()
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
    if (this._resizeSensor) this._resizeSensor.detach()
  }

  updateMap () {
    this._map && this._map.updateSize()
  }

  private LoadWktSource () {
    this._error = undefined
    if (!this._wkt) {
      this._error = `No Geometry Available`
      this._preview.setSource(null as any)
      return
    }
    const format = new Wkt()

    try {
      const getFeature = (fromWkt: string) => {
        const [srid, wkt] = fromWkt.split(';')
        let [, epsg] = srid.split('=')
        epsg = `EPSG:${epsg || '4326'}`

        return format.readFeature(wkt, {
          dataProjection: epsg,
          featureProjection: 'EPSG:3857'
        })
      }

      const target = getFeature(this._wkt)
      target.setStyle(ColourUtil.defaultEditStyle.active)
      const source = new olSource.Vector({
        features: []
      })

      // If there are any other wkt values, which extent
      // should be included, try to parse them into features
      // in orders to retrieve total extent of target and other
      // wkt values
      if (this._contextList) {
        for (const wkt of this._contextList) {
          try {
            const feature = getFeature(wkt)
            feature.setStyle(ColourUtil.defaultOldPreviewStyle)
            if (wkt) source.addFeature(feature)
          } catch (error: any) {
            // silent error, if other geometries have invalid wkt,
            // we still want to get extent of valid ones
          }
        }
      }

      source.addFeature(target)
      this._lastExtent = source.getExtent()

      this._preview.setSource(source)
      this._zoomMap.next({})
    } catch (error: any) {
      this._error = `Invalid WKT Format`
      handleError(error)
    }
  }

}
