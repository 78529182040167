import { BrowserModule, Title } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http'
import { ClipboardModule } from 'ngx-clipboard'

import { AppComponent } from './app.component'

import { AppRoutingModule } from './app.routing'
import { AuthModule } from '@pages/auth/auth.module'

import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { ApplicationTypeInterceptor, TimeoutInterceptor, DEFAULT_TIMEOUT_TOKEN } from '@services/core'
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker'
import { CoreModule } from '@core/core.module'
import { environment } from 'environments/environment'
const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  hideDelay: 500,
  showDelay: 500,
  touchendHideDelay: 50,
  disableTooltipInteractivity: true,
}

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    AuthModule,
    ClipboardModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationTypeInterceptor,
      multi: true
    },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT_TOKEN, useValue: null }],
    [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }],
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
