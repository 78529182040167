import { Component, forwardRef, HostBinding, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { AlertService } from '@services/core'
import { handleError } from '@core/models/app-error'
import * as moment from 'moment'
import TimeUtil from '@core/utils/time/time.util'

interface DateRange {
  from: moment.Moment
  to: moment.Moment
}

@Component({
  selector: 'app-date-ranges-input',
  templateUrl: './date-ranges-input.component.html',
  styleUrls: ['./date-ranges-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangesInputComponent),
      multi: true
    }
  ]
})
export class DateRangesInputComponent implements ControlValueAccessor {
  @Input() disabled = false
  @Input() backDateLimit:number = 0

  @HostBinding('style.opacity')
  get opacity () {
    return this.disabled ? 0.25 : 1
  }

  errorMessage?: string
  ranges?: DateRange[]
  nextRange: Partial<DateRange> = {}
  nextRangeValid = false
  // eslint-disable-next-line
  onChange = (value?: DateRange[]) => {}
  // eslint-disable-next-line
  onTouched = () => {}

  get value (): DateRange[] | undefined {
    return this.ranges
  }

  constructor (
    private _alertService: AlertService
  ) {}

  writeValue (value?: DateRange | DateRange[]) {
    if (!value) {
      this.ranges = undefined
    } else {

      const insertValue = (value: DateRange) => {
        if (!this.ranges || !this.ranges.find(l =>
          l.from.isSame(value.from) && l.to.isSame(value.to)
        )) {
          if (!this.ranges) this.ranges = []
          this.ranges.push(value)
        }
      }

      if (Array.isArray(value)) value.forEach(v => insertValue(v))
      else insertValue(value)
    }

    this.onChange(this.value)
  }

  registerOnChange (fn: (value?: DateRange[]) => void) {
    this.onChange = fn
  }

  registerOnTouched (fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState (isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  validateRange () {
    this.errorMessage = undefined
    if (
      !(this.nextRange.from && this.nextRange.from.isValid()) ||
      !(this.nextRange.to && this.nextRange.to.isValid())
    ) {
      this.nextRangeValid = false
      return
    }

    if (this.nextRange.to.isSameOrBefore(this.nextRange.from)) {
      this.nextRangeValid = false
      this.errorMessage = `'To' date must be before 'From' date.`
      return
    }

    if (this.ranges) {
      for (const range of this.ranges) {
        if (TimeUtil.rangesOverlap(
          { start: this.nextRange.from, end: this.nextRange.to },
          { start: range.from, end: range.to },
          false
        )) {
          this.nextRangeValid = false
          this.errorMessage = `Overlapping dates are not allowed. Selected date overlaps with '${
            range.from.format('YYYY-MM-DD HH:mm')
          }' - '${
            range.to.format('YYYY-MM-DD HH:mm')
          }'`
          return
        }
      }
    }

    if (this.backDateLimit) {
      const  currentDate  = moment()
      const backDate = currentDate.subtract(this.backDateLimit, 'days')
      if (this.nextRange.from.isSameOrBefore(backDate)) {
        this.nextRangeValid = false
        this.errorMessage = `Cannot scrape data backdated more than ${this.backDateLimit} days' ${
          this.nextRange.from.format('YYYY-MM-DD HH:mm')
        }`
        return
      }
    }

    this.nextRangeValid = true

    // Allow event loop to process object/input changes before we clear them
    setTimeout(() => {
      this.add()
      this.nextRangeValid = false
    }, 1)
  }

  add () {
    if (!this.nextRangeValid) return

    if (this.disabled) return

    try {
      const value = { ...this.nextRange as DateRange }

      if (!this.disabled) {
        this.writeValue(value)
      }

      this.nextRange = {}
      this.nextRangeValid = false
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  remove (value: DateRange) {
    if (this.ranges) {
      this.ranges.splice(this.ranges.indexOf(value), 1)
      if (!this.ranges.length) this.ranges = undefined
    }

    this.onChange(this.value)
  }
}
