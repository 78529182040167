import VectorSource from 'ol/source/Vector'
import { Db } from '@vip-shared/models/db-definitions'
import * as moment from 'moment'
import OlUtil from '@core/utils/ol/ol.util'
import { isEqual } from 'lodash'
import { CommonUtil } from '@core/utils/index'
import TimeUtil from '@core/utils/time/time.util'
import { Subject, Subscription } from 'rxjs'
import { buffer, debounceTime } from 'rxjs/operators'
import { AppFeature } from '../ol-extend/app-feature'
import { Geometry } from 'ol/geom'
import { API } from '@vip-shared/interfaces/api-helper'
import { IJson } from '@vip-shared/interfaces'
import Feature from 'ol/Feature'

interface StyleCondition {
  // Key's fill and border must match 'Layer Style Parameters properties 'fill' and 'border''
  fill?: {
    // If at least one value is missing, cannot style fill
    rangeColumns?: string[]
  }
  border?: {
    // If at least one value is missing, cannot style stroke
    rangeColumns?: string[]
  }
}
// TODO: Remove features out of new date range, and pull only ones from new range
// TODO BUG: Not rendered features won't style, predefine feature style on add/style change

// Openlayers mixes up order of features, and we don't want to sort them every time
// so have to maintain array here.
// Also this way we can partition data and customise how the array works.
export class Features {
  private _subscriptions = new Subscription()

  timeSeriesPartition: {
    [entityKey: string]: {
      features: AppFeature[]
      // ISO representation of features, for timeseries insertion
      dates: string[]
      focalFeature?: AppFeature
      change?: number
      lastArrayInsertIndex?: number
      // This would be generated from API.Res.Layer.Ext.Geom
      // where geometries are returned in GET geojson res.extensions.geometries
      geometry?: Geometry
    }
  } = {}

  get renderedArray () {
    return this._source.getFeatures()
  }
  // For time series usage it's important that this array would be
  // ordered by ascending (start) date column
  array: AppFeature[] = []
  lastArrayInsertIndex?: number
  // ISO representation of features, for timeseries insertion
  // This is mainly needed for 'array' as it can have same_date, same_date hundreds of times,
  // so when we find nearest feature, if it's behind wanted date, we want to quickly select last
  // occurrence of it, and if it's after, we want to find first occurrence of the date.
  dates: string[] = []

  focalDate?: moment.Moment

  // Count of features which have non empty geometry
  protected _geometryCount = 0
  get geometryCount () {
    return this._geometryCount
  }

  protected _renderedGeometryCount = 0
  get renderedGeometryCount () {
    return this._renderedGeometryCount
  }

  private _recalculateTimeout?: ReturnType<typeof setTimeout>

  get hasTimeSeriesChange () {
    return this._options.timeSeries && this._options.timeSeries.conf.change_column
  }

  private _canStyleIf: StyleCondition = {}

  // We don't render this source but use to add all features, so that we
  // can extract data we need from VectorSource object for all features
  private _shadowSource = new VectorSource()
  get extent () {
    const nonEmpty = (arr: AppFeature[]) => arr.reduce((sum, f) => f.getGeometry() ? sum + 1 : sum, 0)
    this._geometryCount = nonEmpty(this.array)
    return this._geometryCount ? this._shadowSource.getExtent() : undefined
  }

  get renderedExtent () {
    return this._renderedGeometryCount ? this._source.getExtent() : undefined
  }

  private _updateEntity = new Subject<string>()

  constructor (
    private _idKey: string,
    private _source: VectorSource,
    private _options: {
      timeSeries?: {
        conf: Db.Helper.Geo.VectorTimeSeriesConf
        selection?: Db.Helper.Geo.Timeseries
      }
      // TODO: Was not sure where it's best to place this, handle logic in AppLayer and just
      // pass a function here, or pass AppLayer context to Feature and calculate Feature properties here,
      // for now added it as functions here
      setZIndex?: (f: AppFeature) => void
    } = {}
  ) {
    if (
      this._options.timeSeries &&
      this._options.timeSeries.selection &&
      this._options.timeSeries.selection.date_range
    ) this.setFocalDate(this._options.timeSeries.selection.date_range.focal)

    // TODO: Remove once debugging in production environment is complete
    window[`features_${_idKey}`] = this

    this._subscriptions.add(
      this._updateEntity.pipe(
        buffer(
          this._updateEntity.pipe(debounceTime(300))
        )
      ).subscribe(values => {
        const arr = Array.from(new Set(values))
        const featureArr: Feature[] = []
        for (const entity of arr) {
          const prevFocal = this.timeSeriesPartition[entity].focalFeature
          const focalFeature = this.GetSetFocalFeature(entity)

          this.CalculateChangeOverTime(entity)
          if (!focalFeature) {
            if (prevFocal) {
              if (this._source.hasFeature(prevFocal)) {
                this._source.removeFeature(prevFocal)
              }

              prevFocal.rendered = false
            }
          } else if (focalFeature !== prevFocal && !this._source.hasFeature(focalFeature)) {
            if (prevFocal) {
              if (this._source.hasFeature(prevFocal)) {
                this._source.removeFeature(prevFocal)
              }
              prevFocal.rendered = false
            }
            focalFeature.rendered = true
            featureArr.push(focalFeature)
          }
        }
        if (featureArr.length > 0) this._source.addFeatures(featureArr)
      })
    )
  }

  destroy () {
    this._subscriptions.unsubscribe()
  }

  // TEMP: Leave in for debugging in production
  datesInOrder () {
    if (!this._options.timeSeries) return false
    for (let i = 1; i < this.array.length; i++) {

      const prevDate = moment(this.array[i - 1].get(this._options.timeSeries.conf.column.prop))
      const date = moment(this.array[i].get(this._options.timeSeries.conf.column.prop))

      if (prevDate.isAfter(date)) console.info(`Feature at ${i - 1} is after ${i}`)
    }

    for (const key of Object.keys(this.timeSeriesPartition)) {
      for (let i = 1; i < this.timeSeriesPartition[key].features.length; i++) {

        const prevDate = moment(this.timeSeriesPartition[key].features[i - 1].get(this._options.timeSeries.conf.column.prop))
        const date = moment(this.timeSeriesPartition[key].features[i].get(this._options.timeSeries.conf.column.prop))

        if (prevDate.isAfter(date)) console.info(`${key}: Feature at ${i - 1} is after ${i}`)
      }
    }
  }

  applyGeometryExt (geoms: API.Res.Layer.Ext.Geom[]) {
    // For now this only applies to timeseries layers
    if (!this.timeSeriesPartition) return
    for (const geom of geoms) {
      const entityKey = this.formatEntityKey(geom.id)

      const partition = this.timeSeriesPartition[entityKey]
      if (partition) {
        partition.geometry = OlUtil.geojsonGeomToOlGeom(geom.geometry)
        for (const feature of partition.features) {
          feature.setGeometry(partition.geometry)
        }
      }
    }
  }

  add (fts: AppFeature[], atPosition?: number) {
    const set = new Set<string>()

    for (const f of fts) {
      f.setId(f.get(this._idKey))

      if (this._options.setZIndex) this._options.setZIndex(f)

      this.CheckIfCantStyleFeature(f)

      // TODO: For non time series - insert in right position by creation time?
      this.lastArrayInsertIndex = this.InsertFeatureToArray(
        this.array, this.dates, f,
        atPosition, this.lastArrayInsertIndex
      )

      this._shadowSource.addFeature(f)

      if (!this._options.timeSeries) {
        this._source.addFeature(f)
        f.rendered = true
      } else {
        const entityKey = this.formatEntityKey(f)
        if (!this.timeSeriesPartition[entityKey]) {
          this.timeSeriesPartition[entityKey] = {
            features: [],
            dates: []
          }
        }

        const partition = this.timeSeriesPartition[entityKey]
        partition.lastArrayInsertIndex = this.InsertFeatureToArray(
          partition.features, partition.dates, f,
          undefined, partition.lastArrayInsertIndex
        )
        if (partition.geometry) f.setGeometry(partition.geometry)
        set.add(entityKey)
      }
    }

    Array.from(set).forEach(k => {
      this._updateEntity.next(k)
      this.timeSeriesPartition[k].lastArrayInsertIndex = undefined
    })
    this.recalculateGeometries()
    this.lastArrayInsertIndex = undefined
  }

  remove (f: AppFeature, index?: number): number {
    if (index === undefined || this.array[index] !== f) {
      index = this.array.indexOf(f)
    }
    this.array.splice(index, 1)
    this.dates.splice(index, 1)

    if (this._shadowSource.hasFeature(f)) {
      this._shadowSource.removeFeature(f)
    }
    if (!this._options.timeSeries) {
      if (this._source.hasFeature(f)) {
        this._source.removeFeature(f)
        f.rendered = false
      }
    } else if (index >= 0) {
      const entityKey = this.formatEntityKey(f)
      if (this.timeSeriesPartition[entityKey]) {
        const partition = this.timeSeriesPartition[entityKey]
        const index = partition.features.indexOf(f)
        partition.features.splice(index, 1)
        partition.dates.splice(index, 1)

        if (this._source.hasFeature(f)) {
          this._source.removeFeature(f)
          f.rendered = false
        }

        this._updateEntity.next(entityKey)
      }
    }

    this.recalculateGeometries()
    return index
  }

  private InsertFeatureToArray (arr: AppFeature[], dates: string[], f: AppFeature, atPosition?: number, lastInsIndex?: number) {
    if (!this._options.timeSeries) {
      if (atPosition !== undefined && atPosition >= 0) {
        arr.splice(atPosition, 0, f)
        return atPosition
      } else {
        arr.push(f)
        return arr.length - 1
      }
    }

    const options = this._options.timeSeries.conf
    const dateOf = (i: number) => arr[i] ? moment(
      (arr[i] as any).values_[options.column.prop],
      options.column.format
    ) : undefined

    const newDate = moment(
      (f as any).values_[this._options.timeSeries.conf.column.prop],
      this._options.timeSeries.conf.column.format
    )

    const iso = newDate.toISOString()

    if (atPosition !== undefined && atPosition >= 0) {
      arr.splice(atPosition, 0, f)
      dates.splice(atPosition, 0, iso)
      return atPosition
    }

    const lastDate = dateOf(arr.length - 1)
    if (lastDate && newDate.isSameOrAfter(lastDate)) {
      arr.push(f)
      dates.push(iso)
      return arr.length - 1
    }

    const firstDate = dateOf(0)
    if (firstDate && newDate.isSameOrBefore(firstDate)) {
      arr.splice(0, 0, f)
      dates.splice(0, 0, iso)
      return 0
    }

    let i: number | undefined
    if (lastInsIndex !== undefined) {
      const prevInsertD = dateOf(lastInsIndex)

      if (newDate.isSame(prevInsertD)) {
        i = lastInsIndex + 1
      } else if (newDate.isAfter(prevInsertD)) {
        const nextD = dateOf(lastInsIndex + 1)
        if (newDate.isBefore(nextD)) {
          i = lastInsIndex + 1
        }
      } else if (newDate.isBefore(prevInsertD)) {
        const prevD = dateOf(lastInsIndex - 1)
        if (newDate.isAfter(prevD)) {
          i = lastInsIndex - 1
        }
      }
    }

    if (i === undefined) {
      i = this.GetIndexForDate(newDate, arr, dates)
    }
    arr.splice(i, 0 , f)
    dates.splice(i, 0, iso)
    return i
  }

  recalculateGeometries () {
    if (this._recalculateTimeout) clearTimeout(this._recalculateTimeout)
    const nonEmpty = (arr: AppFeature[]) => arr.reduce((sum, f) => f.getGeometry() ? sum + 1 : sum, 0)
    this._recalculateTimeout = setTimeout(() => {
      this._geometryCount = nonEmpty(this.array)
      this._renderedGeometryCount = nonEmpty(this._source.getFeatures())
    }, 500)
  }

  setSource (source: VectorSource) {
    this._source = source
  }

  setTimeSeries (timeSeries: Db.Helper.Geo.Timeseries) {
    if (!this._options.timeSeries) return
    const prevRendered = this._options.timeSeries.selection
    const changePeriodChange = !isEqual(prevRendered && prevRendered.change_period, timeSeries.change_period)
    const focalAccuracyChange = !isEqual(prevRendered && prevRendered.focal_accuracy, timeSeries.focal_accuracy)
    const focalPointChange = !isEqual(prevRendered && prevRendered.date_range.focal, timeSeries.date_range.focal)

    this._options.timeSeries.selection = timeSeries
    this.setFocalDate(timeSeries.date_range.focal, focalAccuracyChange)

    if (changePeriodChange || focalPointChange) {
      for (const key of Object.keys(this.timeSeriesPartition)) {
        this.CalculateChangeOverTime(key)
      }
    }
  }

  setFocalDate (date: string, force = false) {
    const newFocal = moment(date)
    if (!force && this.focalDate && newFocal.isSame(this.focalDate)) return

    this.focalDate = moment(date)

    if (!this.timeSeriesPartition) return

    const features: AppFeature[] = []
    for (const key of Object.keys(this.timeSeriesPartition)) {
      const feature = this.GetSetFocalFeature(key)
      if (feature) features.push(feature)
    }

    this._source.getFeatures().forEach((f: AppFeature) => f.rendered = false)
    this._source.clear()

    this._source.addFeatures(features)
    features.forEach(f => f.rendered = true)
    this.recalculateGeometries()
  }

  renderAll () {
    this._source.getFeatures().forEach((f: AppFeature) => f.rendered = false)
    this._source.clear()

    this._source.addFeatures(this.array)
    this.array.forEach(f => f.rendered = true)
  }

  formatEntityKey (feature: AppFeature | IJson) {
    if (!this._options.timeSeries) {
      throw new Error(`Feature list is not compatible with timeseries. Cannot generate an entity key.`)
    }

    const key = this._options.timeSeries.conf.group_composite_id_cols
    .map(col => feature instanceof Feature ? feature.get(col) : feature[col]).join('.')

    if (!feature.timeSeries) {
      feature.timeSeries = {
        entity: key,
        change: undefined
      }
    }

    return key
  }

  private CalculateChangeOverTime (entityKey: string) {
    if (!this._options.timeSeries || !this._options.timeSeries.conf.change_column || !this._options.timeSeries.selection) return
    if (!this.timeSeriesPartition[entityKey]) return

    const partition = this.timeSeriesPartition[entityKey]
    const features = partition.features

    if (!features.length) {
      partition.change = undefined
      return
    }
    // This assumes that features are ordered by date
    let fromIndex = -1
    let change: number = 0

    if (this._options.timeSeries.selection.change_period && partition.focalFeature) {
      const change = this._options.timeSeries.selection.change_period
      // NOTE: Currently only 'non range' datasets have change, so we ignore endDate
      const { date } = this.GetFeatureDates(partition.focalFeature)
      if (date) {
        const fromDate = date.subtract(change.value, change.interval)
        fromIndex = Math.min(this.GetIndexForDate(fromDate, features, partition.dates), features.length - 1)
      }
    }
    if (fromIndex !== -1 && partition.focalFeature) {
      if (!features[fromIndex]) {
        throw new Error(`Index ${fromIndex} does not exist in array ${features.length} for ${entityKey}`)
      }

      const fromDateValue = +features[fromIndex].get(this._options.timeSeries.conf.change_column)
      const toDateValue = +partition.focalFeature.get(this._options.timeSeries.conf.change_column)

      let differenceValue = Math.abs(toDateValue - fromDateValue)
      if (toDateValue < fromDateValue) differenceValue = -Math.abs(differenceValue)
      const value = fromDateValue ? (differenceValue / Math.abs(fromDateValue)) : differenceValue
      change = Math.round((value * 100 * 100) / 100)

    }

    this.timeSeriesPartition[entityKey].change = change
    for (const f of this.timeSeriesPartition[entityKey].features) {
      f.timeSeries = {
        entity: entityKey,
        change: change
      }
    }
  }

  private GetFeatureDates (feature: AppFeature): ({
    date?: moment.Moment
    endDate?: moment.Moment
  }) {
    if (!this._options.timeSeries) return {}
    const date: moment.Moment | undefined = feature.get(this._options.timeSeries.conf.column.prop) &&
    moment(feature.get(this._options.timeSeries.conf.column.prop), this._options.timeSeries.conf.column.format)

    const endDate: moment.Moment | undefined = this._options.timeSeries.conf.end_column &&
      feature.get(this._options.timeSeries.conf.end_column.prop) &&
      moment(
        feature.get(this._options.timeSeries.conf.end_column.prop),
        this._options.timeSeries.conf.end_column.format
      )

    return { date, endDate }
  }

  clear () {
    this._source.getFeatures().forEach((f: AppFeature) => f.rendered = false)
    this._source.clear()
    this.array = []
    this.timeSeriesPartition = {}
    this.recalculateGeometries()
  }

  private GetIndexForDate (newDate: moment.Moment, array: AppFeature[], dates: string[]) {
    let i = array.length - 1
    if (!this._options.timeSeries || !this._options.timeSeries.selection) return i

    let nearestBefore: {
      date: moment.Moment
      distance: number
      f: AppFeature
    } | undefined

    let nearestAfter: {
      date: moment.Moment
      distance: number
      f: AppFeature
    } | undefined

    const setDate = (f: AppFeature, date: moment.Moment, endDate?: moment.Moment) => {
      const distance = date.diff(newDate)
      if (distance === 0) {
        nearestBefore = { date, distance, f }
        nearestAfter = { date, distance, f }
      } else if (distance > 0 && (!nearestAfter || distance < nearestAfter.distance)) {
        nearestAfter = { date, distance, f }
      } else if (distance < 0 && (!nearestBefore || distance > nearestBefore.distance)) {
        nearestBefore = { date, distance, f }
      }
      return distance
    }

    this.ProcessArray(array, newDate, setDate)

    if (nearestBefore) {
      i = dates.lastIndexOf(nearestBefore.date.toISOString())
      if (!nearestBefore.date.isSame(newDate)) i += 1
    } else if (nearestAfter) {
      i = dates.indexOf(nearestAfter.date.toISOString())
    }

    return i
  }
  // TODO: Move to util class, as this implementation appears in other layer files too
  private ProcessArray (fts: AppFeature[], date: moment.Moment, setDate: (f: AppFeature, date: moment.Moment, endDate?: moment.Moment) => void) {
    const [firstHalf, secondHalf] = CommonUtil.arrayIntoChunks(fts, 2)
    const firstFeature = firstHalf[firstHalf.length - 1]
    const secondFeature = secondHalf[0]

    const first = firstFeature ?
      this.GetFeatureDates(firstFeature) :
      { date: undefined, endDate: undefined }

    const second = secondFeature ?
      this.GetFeatureDates(secondFeature) :
      { date: undefined, endDate: undefined }

    if (!first.date && !second.date) {
      return
    } else if (!first.date) {
      if (secondHalf.length === 1) {
        setDate(secondFeature, second.date as moment.Moment, second.endDate)
        return
      }
      return this.ProcessArray(secondHalf, date, setDate)
    } else if (!second.date) {
      if (firstHalf.length === 1) {
        setDate(firstFeature, first.date, first.endDate)
        return
      }
      return this.ProcessArray(firstHalf, date, setDate)
    } else if (firstHalf.length === 1 && secondHalf.length === 1) {
      setDate(firstFeature, first.date, first.endDate)
      setDate(secondFeature, second.date, second.endDate)
      return
    }

    // For timeseries with active range per row - focal will match nearest start in change period
    const firstDiff = first.date.diff(date)
    const secondDiff = second.date.diff(date)

    const sameSign = CommonUtil.sameSign(firstDiff, secondDiff)
    if (sameSign) {
      // If positive
      if (firstDiff >= 0) {
        if (firstDiff < secondDiff) {
          return this.ProcessArray(firstHalf, date, setDate)
        } else if (secondDiff < firstDiff) {
          return this.ProcessArray(secondHalf, date, setDate)
        }
      } else {
        if (firstDiff > secondDiff) {
          return this.ProcessArray(firstHalf, date, setDate)
        } else if (secondDiff > firstDiff) {
          return this.ProcessArray(secondHalf, date, setDate)
        }
      }

      if (date.isBefore(first.date)) {
        return this.ProcessArray(firstHalf, date, setDate)
      } else if (date.isAfter(second.date)) {
        return this.ProcessArray(secondHalf, date, setDate)
      } else {
        setDate(firstFeature, first.date, first.endDate)
        setDate(secondFeature, second.date, second.endDate)
        return
      }
    }

    // NOTE: Can improve by checking if one of array had lower 'edge' distance, then
    // wont need to iterate 2 arrays
    this.ProcessArray(firstHalf, date, setDate)
    this.ProcessArray(secondHalf, date, setDate)
  }

  private GetSetFocalFeature (entityKey: string) {
    if (!this._options.timeSeries || !this._options.timeSeries.selection || !this.focalDate) return

    const focal = this.focalDate
    const partition = this.timeSeriesPartition[entityKey]
    if (!partition) return

    const minFocal = moment(this.focalDate)
    const maxFocal = moment(this.focalDate)

    if (this._options.timeSeries.selection.focal_accuracy) {
      minFocal.subtract(this._options.timeSeries.selection.focal_accuracy.value, this._options.timeSeries.selection.focal_accuracy.interval)
      maxFocal.add(this._options.timeSeries.selection.focal_accuracy.value, this._options.timeSeries.selection.focal_accuracy.interval)
    } else {
      minFocal.subtract(15, 'm')
      maxFocal.add(15, 'm')
    }

    let nearestBefore: {
      date: moment.Moment
      endDate?: moment.Moment
      distance: number
      f: AppFeature
    } | undefined

    let nearestAfter: {
      date: moment.Moment
      endDate?: moment.Moment
      distance: number
      f: AppFeature
    } | undefined

    const endDateRequired = !!this._options.timeSeries.conf.end_column
    // If end date is not defined yet and end date column is defined,
    // then select any future time. We can safely assume that no one will have
    // VIP open for 1 week
    const inFuture = moment().add(1, 'week')

    const setDate = (f: AppFeature, date: moment.Moment, endDate?: moment.Moment) => {
      const distance = date.diff(focal)

      if (endDateRequired) {
        if (!endDate) endDate = moment(inFuture)
        // Do not allow setting date as nearest if it it's range and does not overlap with min/max focal
        const overlap = endDate && TimeUtil.rangesOverlap(
          { start: date, end: endDate },
          { start: minFocal, end: maxFocal }
        )
        if (!overlap) return
      }

      if (distance === 0) {
        nearestBefore = { date, endDate, distance, f }
        nearestAfter = { date, endDate, distance, f }
      } else if (distance > 0 && (!nearestAfter || distance < nearestAfter.distance)) {
        nearestAfter = { date, endDate, distance, f }
      } else if (distance < 0 && (!nearestBefore || distance > nearestBefore.distance)) {
        nearestBefore = { date, endDate, distance, f }
      }
    }

    this.ProcessArray(partition.features, focal, setDate)

    const beforeValid = !!nearestBefore && TimeUtil.rangesOverlap(
      { start: nearestBefore.date, end: endDateRequired ? nearestBefore.endDate as any : nearestBefore.date },
      { start: minFocal, end: maxFocal }
    )
    const afterValid = !!nearestAfter && TimeUtil.rangesOverlap(
      { start: nearestAfter.date, end: endDateRequired ? nearestAfter.endDate as any : nearestAfter.date },
      { start: minFocal, end: maxFocal }
    )

    if (nearestBefore && beforeValid && !afterValid) {
      partition.focalFeature = nearestBefore.f
      return partition.focalFeature
    } else if (nearestAfter && afterValid && !beforeValid) {
      partition.focalFeature = nearestAfter.f
      return partition.focalFeature
    } else if (!afterValid && !beforeValid) {
      partition.focalFeature = undefined
      return
    }

    if (endDateRequired) {
      if (nearestAfter && nearestBefore) {
        partition.focalFeature = nearestAfter.distance <= nearestBefore.distance ? nearestAfter.f : nearestBefore.f
      } else {
        partition.focalFeature = undefined
      }
    } else {
      const beforeIsSame = nearestBefore && nearestBefore.date.isSame(focal, 'day')
      const afterIsSame = nearestAfter && nearestAfter.date.isSame(focal, 'day')

      if (nearestAfter && nearestBefore && beforeIsSame && afterIsSame) {
        partition.focalFeature = nearestAfter.distance <= nearestBefore.distance ? nearestAfter.f : nearestBefore.f
      } else if (nearestAfter && afterIsSame) {
        partition.focalFeature = nearestAfter.f
      } else if (nearestBefore && beforeIsSame) {
        partition.focalFeature = nearestBefore.f
      }
    }
    return partition.focalFeature
  }

  getRemovableByDateRange (from: moment.Moment, to: moment.Moment, hasDataBefore: boolean, hasDataAfter: boolean) {
    if (!this._options.timeSeries) return []
    const col = this._options.timeSeries.conf.column

    if (!this._options.timeSeries.conf.end_column) {
      return this.array.filter(f => {
        const val = f.get(col.prop)
        const date = val && moment(val, col.format)
        return (
          hasDataBefore ? date.isAfter(from) : date.isSameOrAfter(from)
        ) && (
          hasDataAfter ? date.isBefore(to) : date.isSameOrBefore(to)
        )
      })
    }

    const endCol = this._options.timeSeries.conf.end_column
    return this.array.filter(f => {
      const val = f.get(col.prop)
      const endVal = f.get(endCol.prop)

      const date = val && moment(val, col.format)
      const endDate = endVal && moment(endVal, endCol.format)

      if (hasDataAfter && !hasDataBefore) {
        return endDate ? endDate.isBefore(to) : true
      } else if (hasDataBefore && !hasDataAfter) {
        return date.isAfter(from)
      } else if (!hasDataAfter && !hasDataBefore) {
        // In theory - it should not reach this point
        return true
      } else {
        return date.isAfter(from) && endDate.isBefore(to)
      }
    })
  }

  setStyleCondition (condition: StyleCondition) {
    if (isEqual(this._canStyleIf, condition)) return
    this._canStyleIf = condition
    for (const f of this.array) {
      this.CheckIfCantStyleFeature(f)
    }
  }

  private CheckIfCantStyleFeature (f: AppFeature) {
    let dontStyleFill = false
    let dontStyleBorder = false

    if (this._canStyleIf.fill && this._canStyleIf.fill.rangeColumns) {
      dontStyleFill = this._canStyleIf.fill.rangeColumns.some(c => !f.get(c))
    }
    if (this._canStyleIf.border && this._canStyleIf.border.rangeColumns) {
      dontStyleBorder = this._canStyleIf.border.rangeColumns.some(c => !f.get(c))
    }

    f.invalidateFill = dontStyleFill || undefined
    f.invalidateBorder = dontStyleBorder || undefined
  }
}
