<div [ngClass]="isDashboardWidget ? 'graph_content' : undefined">
  <h4 *ngIf="isDashboardWidget" id="graph_title">Flood warning graph</h4>
  <h4>Period: <ngTemplate *ngIf="!loading">{{lineChartLabels[0]}} - {{lineChartLabels[lineChartLabels.length-1]}}
    </ngTemplate>
    <mat-spinner *ngIf="loading" [diameter]="25" color="primary" class="spinner"></mat-spinner>
  </h4>
  <mat-icon *ngIf="isDashboardWidget" class="widget-filter" [matMenuTriggerFor]="menu">tune</mat-icon>
  <mat-menu #menu="matMenu">
    <div class="menu-content">
      <div class="menu-title">Filters</div>
      <mat-form-field>
        <mat-label>Time period {{timePeriod}} wk</mat-label>
        <mat-select (selectionChange)="changeTimePeriod($event)" [(value)]="timePeriod">
          <mat-option *ngFor="let period of timePeriods" [value]="period">
            {{period}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sample size {{sampleSize}} hr</mat-label>
        <mat-select (selectionChange)="changeSamplePeriod($event)" [(value)]="sampleSize">
          <mat-option *ngFor="let period of samplingSizes" [value]="period">
            {{period}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div style="width:100%">
        <mat-slide-toggle [checked]="excludeSepa" (change)="excludeSepaChanged($event)"
          matTooltip="If toggled on, the graph is plotted without flood information from SEPA areas" color="primary">
        </mat-slide-toggle>
        <label mat-label> Exclude SEPA Areas</label>
      </div>
    </div>
  </mat-menu>
  <ngTemplate *ngIf="!isDashboardWidget">
    <div class="filters">
      <div>
        <mat-form-field>
          <mat-label>Time period {{timePeriod}} wk</mat-label>
          <mat-select (selectionChange)="changeTimePeriod($event)" [(value)]="timePeriod">
            <mat-option *ngFor="let period of timePeriods" [value]="period">
              {{period}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Sample size {{sampleSize}} hr</mat-label>
          <mat-select (selectionChange)="changeSamplePeriod($event)" [(value)]="sampleSize">
            <mat-option *ngFor="let period of samplingSizes" [value]="period">
              {{period}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="filters">
      <div>
        <label mat-label>Exclude SEPA Areas</label>
        <mat-slide-toggle [checked]="excludeSepa" (change)="excludeSepaChanged($event)"
          matTooltip="If toggled on, the graph is plotted without flood information from SEPA areas" color="primary">
        </mat-slide-toggle>
      </div>
    </div>
  </ngTemplate>
  <div [ngClass]="!isDashboardWidget ? 'canvas-wrapper' : 'canvas-widget-wrapper'">
    <canvas #canvas></canvas>
  </div>
</div>
