
<h2 mat-dialog-title>
  All Pie Chart Elements
</h2>

<div mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="title">
      <mat-header-cell mat-header-cell *matHeaderCellDef> {{data.columnTitleLabel}} </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let item">
        <span>{{item.title}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="count">
      <mat-header-cell mat-header-cell *matHeaderCellDef> {{data.columnValueLabel}} </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let item">
        <span>{{item.count}}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>

<div class="st-button-row">
  <button mat-stroked-button color="primary"  (click)="downloadData()">Download</button>
  <button mat-stroked-button color="primary" [mat-dialog-close] cdkFocusInitial>Close</button>
</div>
