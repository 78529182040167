import { Injectable } from '@angular/core'
import { fromEvent, BehaviorSubject } from 'rxjs'
import { auditTime } from 'rxjs/operators'

const ViewportSizes = ['xxs', 'xs', 's', 'm', 'l', 'xl'] as const
type ViewportSize = typeof ViewportSizes[number]

const Breakpoints = {
  'xxs-min': 0,
  'xxs-max': 399,
  'xs-min': 400,
  'xs-max': 599,
  's-min': 600,
  's-max': 959,
  'm-min': 960,
  'm-max': 1279,
  'l-min': 1280,
  'l-max': 1919,
  'xl-min': 1920,
  'xl-max': 5000
}

@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  private _sizeChange = new BehaviorSubject<ViewportSize | undefined>(undefined)
  private _size?: ViewportSize

  get sizeChange () {
    return this._sizeChange
    .asObservable()
  }

  constructor () {
    fromEvent(window, 'resize')
    .pipe(
      auditTime(100)
    ).subscribe(() => this.CalculateType())
  }

  viewportIs (ltGt: 'lt' | 'gt', size: ViewportSize) {
    if (!this._size) this.CalculateType()

    if (ltGt === 'lt') {
      return window.innerWidth < Breakpoints[`${size}-min`]
    } else {
      return Breakpoints[`${size}-max`] < window.innerWidth
    }
  }

  private CalculateType () {
    const key = Object.keys(Breakpoints).reverse().find(k =>
      k.endsWith('-min') && Breakpoints[k] < window.innerWidth
    )
    if (!key) return

    const nextSize = key.split('-')[0] as ViewportSize
    if (this._size === nextSize) return

    this._size = nextSize
    this._sizeChange.next(this._size)
  }
}
