<app-group-header>Dataset query</app-group-header>

<div class="dataset-toolbox">
  <div class="setup-preview">
    <p *ngIf="!query?.length">Not configured</p>

    <ng-container *ngFor="let block of query">
      <app-dataset-join-logo class="icon" [joinType]="block.join_as" *ngIf="block.join_as">
      </app-dataset-join-logo>

      <span class="group-marker" *ngIf="block.sequence?.length">(</span>
      <p>{{getDatasetName(block.dataset)}}</p>

      <ng-container *ngFor="let subblock of block.sequence">
        <app-dataset-join-logo class="icon" [joinType]="subblock.join_as" *ngIf="subblock.join_as">
        </app-dataset-join-logo>

        <p>{{getDatasetName(subblock.dataset)}}</p>
      </ng-container>
      <span class="group-marker" *ngIf="block.sequence?.length">)</span>

    </ng-container>
  </div>

  <div class="actions">
    <button mat-icon-button color="primary" (click)="openDatasetQueryEditor()">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</div>
