import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { ActiveFloodWarning, IFloodReRecords } from '@vip-shared/interfaces/fred'
import { FloodAlertsService } from '@services/workspace/products/fred/flood-alerts/flood-alerts.service'
import { Subscription } from 'rxjs'
import { Db } from '@vip-shared/models/db-definitions'
import { ResizeSensor } from 'css-element-queries'
import moment from 'moment'

@Component({
  selector: 'app-flood-records-table',
  templateUrl: './flood-records-table.component.html',
  styleUrls: ['./flood-records-table.component.scss']
})
export class FloodRecordsTableComponent implements OnDestroy, OnInit {
  records: IFloodReRecords[] = []
  sepaExcludedRecords: IFloodReRecords[] = []
  dataSource?: MatTableDataSource<IFloodReRecords>
  innerWidth: number = 756
  private _subscriptions = new Subscription()
  private _resizeSensor?: ResizeSensor = {} as ResizeSensor
  private _interval: any
  displayedColumns: string[] = [
    'severity', 'fw_count', 'claims', 'exposure', 'pdh'
  ]
  updatedDatesTooltip: string = ''
  excludeSepa: boolean = false
  fontSize:string = '11px'

  @ViewChild('recTable', { static: true }) recTable!: ElementRef
  @Input() isDashboardWidget: boolean = false


  constructor (private _floodAlertsService: FloodAlertsService) {
    this._subscriptions.add(
      this._floodAlertsService.recordsChange.subscribe(numRecords => {
        this.records = numRecords
      })
    )

    this._subscriptions.add(
      this._floodAlertsService.sepaExcludedRecordsChange.subscribe(numRecords => {
        this.sepaExcludedRecords = numRecords
        this.UpdateFloodWarningCount([], true)
        this.setDataSource()
      })
    )

    this._subscriptions.add(
      this._floodAlertsService.alertsChange.subscribe(allAlerts => {
        this.UpdateFloodWarningCount(allAlerts)
        this.UpdateFloodWarningCount(allAlerts, true)
        this.setDataSource()
      })
    )

    this._subscriptions.add(
      this._floodAlertsService.associationUpdated.subscribe(updatedInfo => {
        this.updatedDatesTooltip = 'The counts are for the present time.\nTime filters will not affect this view.\n'
        for (const info of updatedInfo) {
          this.updatedDatesTooltip += `${info.name} last updated at: ${moment(info.updatedAt).format('DD MMM YYYY HH:mm')}\n`
        }
      })
    )

  }


  ngOnInit () {
    this.innerWidth = window.innerWidth
    this._resizeSensor = new ResizeSensor(
      this.recTable.nativeElement,
      () => this.resizefonts()
    )
    this.resetSensor(this._resizeSensor)
  }

  resetSensor (sensor: ResizeSensor) {
    const sen = sensor
    const reset = () => {
      if (this.recTable.nativeElement) sen.reset()
    }
    if (sen) {
      this._interval = setInterval(reset, 2000)
    }
  }

  resizefonts () {
      this.innerWidth = window.innerWidth
      const minFontSize = 11
      const tableWidth =  this.recTable.nativeElement.clientWidth

      //NOTE: divided innerwidth by two for larger font
      const ratio =  tableWidth/(this.innerWidth/2)

      let boxtext = Math.round(minFontSize * ratio)
      boxtext = boxtext < minFontSize ? minFontSize : boxtext
      this.fontSize = boxtext + 'px'
  }

  UpdateFloodWarningCount (allAlerts: ActiveFloodWarning[], excludeSEPA: boolean = false) {
    const alerts = this._floodAlertsService.numActiveWarningsFilter(allAlerts, Db.Fred.Severity.FLOOD_ALERT, excludeSEPA)
    const warnings = this._floodAlertsService.numActiveWarningsFilter(allAlerts , Db.Fred.Severity.FLOOD_WARNING, excludeSEPA)
    const severe = this._floodAlertsService.numActiveWarningsFilter(allAlerts, Db.Fred.Severity.SEVERE_FLOOD_WARNING, excludeSEPA)
    const recordsArray = excludeSEPA ? this.sepaExcludedRecords : this.records
    recordsArray.map(record => {
      switch (record.severity) {
        case(Db.Fred.Severity.SEVERE_FLOOD_WARNING): {
          record.flood_warning_count = severe
          break
        }
        case(Db.Fred.Severity.FLOOD_WARNING): {
          record.flood_warning_count = warnings
          break
        }
        case(Db.Fred.Severity.FLOOD_ALERT): {
          record.flood_warning_count = alerts
          break
        }
      }
    })
  }

  fontStyle (record: IFloodReRecords) {
    switch (record.severity) {
      case(Db.Fred.Severity.SEVERE_FLOOD_WARNING): {
        return 'count severe'
      }
      case(Db.Fred.Severity.FLOOD_WARNING): {
        return 'count warning'
      }
      case(Db.Fred.Severity.FLOOD_ALERT): {
        return 'count alert'
      }
    }
  }

  getSeverityName (record: IFloodReRecords) {
    switch (record.severity) {
      case(Db.Fred.Severity.SEVERE_FLOOD_WARNING): {
        return 'Severe warning'
      }
      case(Db.Fred.Severity.FLOOD_WARNING): {
        return 'Warning'
      }
      case(Db.Fred.Severity.FLOOD_ALERT): {
        return 'Alert'
      }
    }
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
    if (this._interval) clearInterval(this._interval)
    this._subscriptions.unsubscribe()
    if (this._resizeSensor) this._resizeSensor.detach()
  }

  setDataSource () {
    this.dataSource = this.excludeSepa ?
      new MatTableDataSource(this.sepaExcludedRecords)
      :
      new MatTableDataSource(this.records)
  }
}
