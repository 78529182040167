import { Pipe, PipeTransform } from '@angular/core'
import { CommonUtil } from '@core/utils/index'
@Pipe({
  name: 'linkTransform'
})
export class LinkPipe implements PipeTransform {

  transform (value: string, classList: string): any {
    if (CommonUtil.validUrl(value)) {
      const link = document.createElement('a')
      link.href = value.startsWith('http') ? value : `//${value}`
      link.text = value
      link.target = '_blank'
      if (classList) link.className = classList
      return link.outerHTML
    } else {
      return value
    }
  }
}
