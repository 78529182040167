import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import * as _ from 'lodash'
import { EmissionsJourneysService } from '../../services/journeys.service'
import { BaseChartDirective } from 'ng2-charts'
import { Subscription } from 'rxjs'
import { ThemeService } from '@services/core/theme/theme.service'
import 'chartjs-adapter-moment'


@Component({
  selector: 'app-emissions-time-series',
  templateUrl: './emissions-time-series.component.html',
  styleUrls: ['./emissions-time-series.component.scss']
})
export class EmissionsTimeSeriesComponent implements OnDestroy, OnInit {
  @ViewChild(BaseChartDirective, { static: true }) chart!: BaseChartDirective

  private aggregateJourneys: {} = {}
  chartData: any = []
  chartLabels: Array<any> = []
  emission: string = 'NOx'
  chartOptions: any = {}
  chartLegend = true
  chartType: string = 'line'
  private _subscriptions = new Subscription()

  constructor(
    private journeysService: EmissionsJourneysService,
    private _themeService: ThemeService
  ) {
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
  }

  ngOnInit() {
    this._subscriptions.add(
      this.journeysService.aggregateJourneysChanged.subscribe(aggregateJourneys => {
        this.aggregateJourneys = aggregateJourneys
        this.updateChart()
      })
    )
  }

  getScale () {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day'
          },
          ticks: {
            color: this._themeService.getColor('foreground')
          },
          grid: {
            color: this._themeService.getColor('foreground-20')
          },
        },
        y: {
           display: true,
           scaleLabel: {
            display: true,
            labelString: `Total ${this.journeysService.trackedEmissions} ${this.journeysService.getMetric(this.journeysService.trackedEmissions)}`,
           },
           ticks: {
            color: this._themeService.getColor('foreground')
          },
          grid: {
            color: this._themeService.getColor('foreground-20')
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: this._themeService.getColor('foreground')
          }
        }
      }
    }
  }

  updateChart() {
    this.chartOptions = this.getScale()
    let labels = Object.keys(this.aggregateJourneys).sort()
    if (this.chartType === 'line') {
      let vesselData:number[] = []
      for (let key of labels) {
        let sum  = 0
        for (let aggregateCls in this.aggregateJourneys[key]) {
          sum += this.aggregateJourneys[key][aggregateCls]
        }
        vesselData.push(sum)
      }
      this.chartData = [
        { data: vesselData, label: this.journeysService.trackedEmissions }
      ]
      this.chartLabels = Object.keys(this.aggregateJourneys).sort()
    }
  }
}
