import { ComponentFactoryResolver, ComponentRef, Injectable } from '@angular/core'
import { IPanelDetailKvP } from '@core/types'
import { MapWidgetComponent } from '@pages/dashboard/map-widget/map-widget.component'
import { AttributeChartComponent } from '@core/page-components/attribute-chart/attribute-chart.component'
import { IDashboardViewModel } from '@vip-shared/interfaces/dashboard/IDashboardViewModel'
import { Db } from '@vip-shared/models/db-definitions'
import { CompactType, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType } from 'angular-gridster2'
import { BehaviorSubject, Subject } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
import { DashboardApiService } from './dashboard-api.service'
import { AttributeTableWidgetComponent } from '@pages/dashboard/attr-table-widget/attr-table-widget.component'
import { EmissionsMapWidgetComponent } from '../../modules/emissions/components/emissions-map/emissions-map-widget.component'
import { EmissionsGraphComponent } from '../../modules/emissions/components/emissions-graph/emissions-graph.component'
import { EmissionsTableWidgetComponent } from '../../modules/emissions/components/emissions-table/emissions-table-widget.component'
import { EmissionsChartComponent } from '../../modules/emissions/components/emissions-chart/emissions-chart.component'
import { EmissionsTimeSeriesComponent } from '../../modules/emissions/components/emissions-time-series/emissions-time-series.component'
import { FloodRecordsWidgetComponent } from '@pages/dashboard/fred-widgets/flood-records-widget/flood-records-widget.component'
import { AlertQueryWidgetComponent } from '@pages/dashboard/fred-widgets/alert-query-widget/alert-query-widget.component'
import { AppLayer } from '@core/models/layer'
import { FloodWarningTimeseriesComponent } from '@pages/dashboard/floodwarning-timeseries-widget/floodwarning-timeseries-widget.component'
import { DynamicChartingWidgetComponent } from '@pages/dashboard/dynamic-charting-widget/dynamic-charting-widget.component'
import { EmissionsRasterChartWidgetComponent } from 'app/modules/emissions/components/emissions-raster-chart-widget/emissions-raster-chart-widget.component'

const componentCollection = {
  MapWidget: MapWidgetComponent,
  ChartWidget: AttributeChartComponent,
  AttributeTableWidget: AttributeTableWidgetComponent,
  DynamicChartingWidget: DynamicChartingWidgetComponent
}

const emissionCollection = {
  EmissionsMap: EmissionsMapWidgetComponent,
  EmissionsGraph: EmissionsGraphComponent,
  EmissionsTable: EmissionsTableWidgetComponent,
  EmissionsChart: EmissionsChartComponent,
  EmissionsTimeSeries: EmissionsTimeSeriesComponent,
  EmissionsRasterChart: EmissionsRasterChartWidgetComponent
}

const fredCollection = {
  RecordsTable: FloodRecordsWidgetComponent,
  FloodWarningTimeseries: FloodWarningTimeseriesComponent,
  AlertQuery: AlertQueryWidgetComponent
}

interface MenuItem {
  name: string,
  icon: string,
  position: string
}

export interface IComponent {
  id: string
  componentRef: string
  layerId?: number
  chartId?: string
  options?: { [key: string]: any; } | any[];
  page_number?: number
  queryId?: string
  // refreshData ()
  // updateData (newData: any)
}
@Injectable({
  providedIn: 'root'
})
export class DashboardLayoutService {
  private _panelsSubscription = new BehaviorSubject<IPanelDetailKvP | undefined>(undefined)
  private _showSubmenu = true
  showSubmenu = new BehaviorSubject<boolean>(this._showSubmenu)
  currentPage: number = 1
  private _baseMenuItems = [
    {
      name: 'logout',
      icon: 'exit_to_app',
      position: 'bottom'
    },
    {
      name: 'help',
      icon: 'help_outline',
      position: 'bottom'
    },
    {
      name: 'about',
      icon: 'info_outline',
      position: 'bottom'
    }
  ]

  private _menuItems: MenuItem[] = []

  get menuItems(): MenuItem[] {
    return this._menuItems
  }

  get dashboardLocked(): boolean {
    if (this.options && this.options.draggable && this.options.resizable) {
      return !(this.options.draggable.enabled && this.options.resizable)
    } else {
      return true
    }
  }

  menuItemsChanged = new Subject<MenuItem[]>()

  public options: GridsterConfig = {
    gridType: GridType.Fit,
    compactType: CompactType.None,
    margin: 10,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 640,
    minCols: 1,
    maxCols: 100,
    minRows: 1,
    maxRows: 100,
    maxItemCols: 100,
    minItemCols: 1,
    maxItemRows: 100,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    fixedColWidth: 105,
    fixedRowHeight: 105,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    enableOccupiedCellDrop: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    emptyCellDropCallback: this.onDrop,
    itemChangeCallback: this.itemChange.bind(this),
    itemResizeCallback: this.itemResize.bind(this),
    draggable: {
      enabled: false,
      ignoreContent: true,
      dropOverItems: true,
      dragHandleClass: 'drag-handler',
      ignoreContentClass: 'no-drag'
    },
    pushItems: true,
    swap: true,
    pushDirections: { north: true, east: true, south: true, west: true },
    resizable: {
      enabled: false
    },
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushResizeItems: false,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false
  }

  public layout: IDashboardViewModel[] = []
  public components: IComponent[] = []
  public viewContainerRefs = new Map()
  public componentInstances: ComponentRef<any>[] = []

  public dropId!: string
  constructor(private dashboardApiService: DashboardApiService) { }

  get dynamicComponents() {
    return this.dashboardApiService.isProductType([Db.Vip.Product.EMISSIONS]) ? Object.assign({}, emissionCollection, componentCollection) :
      this.dashboardApiService.isProductType([Db.Vip.Product.FRED]) ? Object.assign({}, fredCollection, componentCollection) : componentCollection
  }

  mapExentFts = new Subject<{
    layer: AppLayer
    extent: number[]
    reload: boolean
  }>()


  toggleLock(): void {
    const options: GridsterConfig = this.setOptions(this.options.api)
    if (options && options.draggable && options.resizable) {
      const lock = options.draggable.enabled && options.resizable.enabled
      if (lock) {
        this.save()
      }
      options.draggable = {
        enabled: !lock
      }

      options.resizable = {
        enabled: !lock
      }
      this.options = options
      if (options.api && options.api.optionsChanged) {
        options.api.optionsChanged()
      }
    }
  }

  addEmptyWidget(onPage?: number): void {
    const item: IDashboardViewModel = {
      cols: 5,
      id: uuidv4(),
      rows: 5,
      x: 0,
      y: 0,
      component: '',
      page_number: onPage ? onPage : this.currentPage
    }
    this.layout.push(item)
  }

  createWidgetofType(widgetType: string, layerIds?: number[], chartId?: string, queryId?: string, widgetOptions?: any) {

    if (layerIds) {
      if (layerIds.length > 1) {
        widgetOptions = {}
        widgetOptions['layers'] = layerIds
      }
    }

    const id = uuidv4()
    const widget: IDashboardViewModel = {
      cols: 5,
      id: id,
      rows: 5,
      x: 0,
      y: 0,
      component: widgetType,
      page_number: this.currentPage,
      enabled: true,
      workspace_id: this.dashboardApiService.workspaceId,
      layer_id: layerIds && layerIds.length === 1 ? layerIds[0] : undefined,
      chart_id: chartId,
      query_id: queryId,
      options: widgetOptions
    }
    const { components } = this
    const comp: IComponent | undefined = components.find(c => c.id === widget.id)
    const updateIdx: number = comp ? components.indexOf(comp) : components.length
    const componentItem: IComponent = {
      id: id,
      componentRef: widgetType,
      layerId:  layerIds && layerIds.length === 1 ? layerIds[0] : undefined,
      chartId: chartId,
      page_number: this.currentPage,
      queryId: queryId,
      options: widgetOptions
    }
    this.components = Object.assign([], this.components, { [updateIdx]: componentItem })
    this.layout.push(widget)
  }

  addWidgets(widgets: IDashboardViewModel[]) {
    widgets.forEach(widget => {
      widget.id = uuidv4()
      const { components } = this
      const comp: IComponent | undefined = components.find(c => c.id === widget.id)
      const updateIdx: number = comp ? components.indexOf(comp) : components.length
      const componentItem: IComponent = {
        id: widget.id,
        componentRef: widget.component,
        layerId: widget.layer_id,
        chartId: widget.chart_id,
        page_number: widget.page_number,
        options: widget.options,
        queryId: widget.query_id
      }
      this.components = Object.assign([], this.components, { [updateIdx]: componentItem })
      this.layout.push(widget)
    })
  }

  resetLayout() {
    this.components = []
    this.layout = []
  }

  deleteItem(id: string): void {
    const item = this.layout.find(d => d.id === id)
    if (item !== undefined) {
      this.layout.splice(this.layout.indexOf(item), 1)
    }

    const comp = this.components.find(c => c.id === id)
    if (comp !== undefined) {
      this.components.splice(this.components.indexOf(comp), 1)
    }
    const distPageNums = Array.from(new Set(this.layout.map(item => item.page_number)))
    let idxToChange: number = -1
    for (let i = 0; i < distPageNums.length - 1; ++i) {
      const curr = distPageNums[i]
      const next = distPageNums[i + 1]
      if (curr && next && (next - curr > 1)) {
        idxToChange = next
        break
      }
    }
    if (idxToChange > 0) {
      this.layout.map(item => {
        if (item.page_number === idxToChange) {
          item.page_number = idxToChange - 1
        }
      })
      this.components.map(component => {
        if (component.page_number === idxToChange) {
          component.page_number = idxToChange - 1
        }
      })
    }
  }

  setDropId(dropId: string): void {
    this.dropId = dropId
  }

  dropItem(dragId: string, componentFactoryResolver: ComponentFactoryResolver): void {
    const { components } = this
    const comp: IComponent | undefined = components.find(c => c.id === this.dropId)
    const updateIdx: number = comp ? components.indexOf(comp) : components.length
    const componentItem: IComponent = {
      id: this.dropId,
      componentRef: dragId
    }
    this.components = Object.assign([], this.components, { [updateIdx]: componentItem })
    const viewContainerRef = this.viewContainerRefs.get(this.dropId)
    viewContainerRef.clear()
    const component = this.dynamicComponents[dragId]
    const componentFactory = componentFactoryResolver.resolveComponentFactory(component)
    viewContainerRef.createComponent(componentFactory)

    const widget = this.layout.find(w => w.id === this.dropId)
    if (widget) {
      widget.component = dragId
      if (this.dashboardLocked) {
        this.toggleLock()
      }
    }
  }

  getComponentRef(id: string): string | null {
    const comp = this.components.find(c => c.id === id)
    return comp ? comp.componentRef : null
  }

  changeWidgetOptions(id: string, key: string, value: any) {
    const comp = this.layout.find(c => c.id === id)
    if (!comp?.options) return
    if (comp.options[key]) {
      comp.options[key] = value
    }
  }

  onDrop(ev) {
    // const componentType = ev.dataTransfer.getData("widgetIdentifier");
    // console.log(`onDrop ev=>`, ev)
  }

  async save() {
    const widgets: Array<Db.Vip.Prj.IWidget> = JSON.parse(JSON.stringify(this.layout))
    await this.dashboardApiService.saveDashboards(widgets)
  }

  itemChange(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
    let tmp = JSON.stringify(this.layout)
    let parsed: Array<Db.Vip.Prj.IWidget> = JSON.parse(tmp)
  }

  itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
    //  console.log(`resize item, itemcomponent`, item, itemComponent )
  }

  updatePanels(newPanels: IPanelDetailKvP) {
    this._panelsSubscription.next(newPanels)
  }

  loadMenuItems(): MenuItem[] {
    this._menuItems = []
    this._baseMenuItems.forEach(el => {
      this.menuItems.push(el)
    })
    return this._menuItems
  }

  toggleSubmenu(show: boolean) {
    this._showSubmenu = show
    this.showSubmenu.next(this._showSubmenu)
  }

  setOptions(api?): GridsterConfig {
    const options: GridsterConfig = {
      gridType: GridType.Fit,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 1,
      maxCols: 100,
      minRows: 1,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      emptyCellDropCallback: this.onDrop,
      itemChangeCallback: this.itemChange.bind(this),
      itemResizeCallback: this.itemResize.bind(this),
      draggable: {
        enabled: this.options.draggable ? this.options.draggable.enabled : false,
        ignoreContent: true,
        dropOverItems: true,
        dragHandleClass: 'drag-handler',
        ignoreContentClass: 'no-drag'
      },
      pushItems: true,
      swap: true,
      pushDirections: { north: true, east: true, south: true, west: true },
      resizable: {
        enabled: this.options.resizable ? this.options.resizable.enabled : false
      },
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushResizeItems: false,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false
    }
    if (api) options.api = api
    return options
  }
}
