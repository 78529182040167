import { AbstractControl, UntypedFormGroup, ValidatorFn, AsyncValidatorFn, AbstractControlOptions, UntypedFormControl } from '@angular/forms'
import AppError from './app-error'
import { Observable } from 'rxjs'

export class TypedFormGroup<T> extends UntypedFormGroup {
  get _controls (): T {
    return this.controls as any
  }

  constructor (
    controls: T,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
  ) {
    if (!(controls instanceof Object) || Object.values(controls).some(c => !(c instanceof AbstractControl))) {
      throw new AppError(`Controls object is invalid.`)
    }
    super(controls as any, validatorOrOpts, asyncValidator)
  }
}

export class TypedFormControl<T> extends UntypedFormControl {
  value!: T | undefined
  valueChanges!: Observable<T | undefined>
  setValue!: (value: T | undefined, options?: {
    onlySelf?: boolean
    emitEvent?: boolean
    emitModelToViewChange?: boolean
    emitViewToModelChange?: boolean
  }) => void

  constructor (
    formState?: T | {
      value: T
      disabled: boolean
    } | undefined,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
  ) {
    super(formState, validatorOrOpts, asyncValidator)
  }
}
