<h2 mat-dialog-title>Add columns</h2>
<p *ngIf="hasInvalidNames" class="st-error">
  One or more column name is invalid. Column name must consist of lower case characters, numbers and/or underscores. System reserved column names (ogc_fid, wkb_geometry, geometry) are not allowed.
</p>

<div mat-dialog-content>
  <button id="new-column" matTooltip="Add column" class="uppercase" mat-icon-button color="primary" (click)="createColumn()">
      <mat-icon>add</mat-icon>
  </button>
  <div class="definitions-header">
      <div>
        <p>Column Name</p>
      </div>
      <div>
        <p>Type</p>
      </div>
      <div class="short-field">
        <p>Nullable</p>
      </div>
      <div>
        <p>Default</p>
      </div>
  </div>
  <div id="column-list" #columnList>
      <div class="column-definition-row" *ngFor="let row of columnMetadata">
          <mat-form-field *ngIf="!row.nameControl">
              <input matInput [disabled]="!row.canEdit" [(value)]="row.name">
          </mat-form-field>
          <mat-form-field *ngIf="row.nameControl">
            <input matInput (input)="checkForNewColumns()" [formControl]="row.nameControl">
          </mat-form-field>

          <mat-form-field>
            <mat-select disableOptionCentering [(ngModel)]="row.type" [disabled]="!row.canEdit" (selectionChange)="checkForNewColumns()">
              <mat-option *ngFor="let type of availableTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="short-field">
            <mat-checkbox [(ngModel)]="row.nullable" [disabled]="!row.canEdit"></mat-checkbox>
          </div>

          <mat-form-field>
            <input matInput [(ngModel)]="row.default" [disabled]="!row.canEdit">
          </mat-form-field>
        </div>
  </div>
</div>

<div class="st-button-row">
  <button color="primary" type="button" mat-button mat-dialog-close>
    cancel
  </button>
  <button color="primary" type="submit" mat-button cdkFocusInitial [disabled]="!canCreate" (click)="createColumns()">
    create
  </button>
</div>
<app-loader-backdrop loadingText="Applying changes..." *ngIf="saving"></app-loader-backdrop>
