///<reference path="./typings.d.ts" />

class AppError extends Error {
  name: string
  context?: any
  redirect?: boolean
  constructor (public message: string, public silent = true, public status?: number) {
    super()
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }

    this.name = this.constructor.name
  }

  setContext = (data: any) => {
    this.context = data
  }
}

export function handleError (e: Error | AppError | any, ctx?: {action?: string, description?: string}) {
  if (!e.silent) console.error(e)
  // TODO: Add sentry logging?
}

export default AppError
