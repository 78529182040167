import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TypedFormControl, TypedFormGroup } from "@core/models/typed-form-control";
import { DialogCleanup } from "@core/utils/ng-mixin/mixins/dialog-cleanup";
import { DashboardApiService } from "@services/dashboard/dashboard-api.service";
import { DashboardLayoutService } from "@services/dashboard/dashboard-layout.service";
import { IRVectorColumn } from "vip-shared/dist/interfaces";
import { Db } from "vip-shared/dist/models/db-definitions";
import { QueryBuilderDialogComponent } from '@core/page-components/query-builder-dialog/query-builder-dialog.component'
import { StatisticQueryBuilderDialogComponent } from '@core/page-components/statistic-query-builder-dialog/statistic-query-builder-dialog.component'
import { LayerService, WorkspaceService } from "@services/workspace";
import { AppLayer } from "@core/models/layer";
import { AlertService } from "@services/core";
import { Subscription } from "rxjs";
import { ZoomPanelComponent } from "@pages/workspace/right-drawer/panels/info/components/zoom-panel/zoom-panel.component";
import { IQueryWRef } from "@core/models/query-object";

type Form = TypedFormGroup<{
  widgetType: TypedFormControl<string>
}>

@Component({
  selector: 'app-widget-menu-dialog',
  templateUrl: './widget-menu-dialog.component.html',
  styleUrls: ['./widget-menu-dialog.component.scss']
})
export class WidgetMenuDialogComponent implements DialogCleanup, OnDestroy {
  private _subscriptions = new Subscription()
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs(): any { return }
  form: Form
  queryTypes = ['filter', 'statistic']
  queryTypeSelected?: string = undefined
  targetLayer: AppLayer | undefined = undefined
  loadingLayer: boolean = false
  layerId: string = ''
  threshold?: number = undefined
  chartTypes: string[] = ['bar', 'line', 'pie']
  chartWithAxes: undefined | boolean = undefined
  availableLayerColumns: IRVectorColumn[] = []
  numericLayerColumns: IRVectorColumn[] = []
  widgetExampleImgs = {
    'AlertQuery': 'assets/images/alertquery.JPG',
    'RecordsTable': 'assets/images/floodrecords_table.JPG',
    'AttributeTableWidget': 'assets/images/attribute_table.JPG',
    'ChartWidget': 'assets/images/chartwidget.JPG',
    'DynamicChartingWidget': 'assets/images/dynamic_charting.JPG',
    'FloodWarningTimeseries': 'assets/images/floodwarning_timeseries.JPG',
    'MapWidget': 'assets/images/mapwidget.JPG'
  }

  @ViewChild(QueryBuilderDialogComponent) queryBuilder?: QueryBuilderDialogComponent
  @ViewChild(StatisticQueryBuilderDialogComponent) statQueryBuilder?: StatisticQueryBuilderDialogComponent

  constructor(
    private _dialogRef: MatDialogRef<WidgetMenuDialogComponent, any>,
    private _layoutService: DashboardLayoutService,
    private dashboardApiService: DashboardApiService,
    private _workspaceService: WorkspaceService,
    private _layerService: LayerService,
    private _alertService: AlertService,
    private _dialog: MatDialog
  ) {
    this.form = new TypedFormGroup({
      widgetType: new TypedFormControl<string>(undefined, [Validators.required])
    })
  }

  get dynamicComponents() {
    return this._layoutService.dynamicComponents
  }

  get availableLayers(): Db.Vip.Geo.ILayer[] {
    return this.dashboardApiService.availableLayers
  }

  get availableRasterSpacialQueries(): IQueryWRef[] {
    return this._layerService.allQueries.filter( (item) => item.type === "spatial" && item.raster_data && this.availableLayers.find(l => l.layer_id === item.layer_id))
  }

  get availableCharts(): Db.Vip.Prj.IChart[] {
    return this.dashboardApiService.availableCharts
  }

  get widgetType(): string | undefined {
    return this.form.controls.widgetType.value
  }

  get usesLayerDataSource(): boolean {
    return this.widgetType ? ['MapWidget', 'AttributeTableWidget', 'DynamicChartingWidget'].includes(this.widgetType) : false
  }

  get usesChartDataSource(): boolean {
    return this.widgetType ? ['ChartWidget'].includes(this.widgetType) : false
  }

  get usesQueryBuilder(): boolean {
    return this.widgetType ? ['AlertQuery'].includes(this.widgetType) : false
  }

  get usesRasterSpacialQueries(): boolean {
    return this.widgetType ? ['EmissionsRasterChart'].includes(this.widgetType): false
  }

  get canSelectMultiple(): boolean {
    return !!(this.widgetType && this.widgetType === 'MapWidget')
  }

  createWidget() {
    if (!this.widgetType) return
    let layerIds: number[] | undefined = this.layerIdToArray(this.form.controls.layerId?.value)
    const chartId = this.form.controls.chartId?.value
    let options: any
    if ('DynamicChartingWidget' === this.widgetType) {
      options = {
        chartType: this.form.controls.chartType?.value,
        xAxis: this.form.controls.xAxis?.value,
        yAxis: this.form.controls.yAxis?.value,
        aggColumn: this.form.controls.aggColumn?.value
      }
    }
    let queryId: string | undefined = this.form.controls.queryId?.value
    if ('EmissionsRasterChart' === this.widgetType) {
      const query = this.availableRasterSpacialQueries.find(item => item.query_id === queryId)
      if (query?.layer_id) layerIds = [+query.layer_id]
    }
    this._layoutService.createWidgetofType(this.widgetType, layerIds, chartId, queryId, options)
    this._dialogRef.close()
  }

  async createWidgetQuery() {
    if (!this.widgetType || (!this.queryBuilder && !this.statQueryBuilder)) return
    let queryId: string | undefined = undefined

    if (this.queryBuilder) {
      queryId = await this.queryBuilder.saveQuery()
    } else if (this.statQueryBuilder) {
      queryId = await this.statQueryBuilder.saveQuery()
    }

    if (!queryId) {
      this._alertService.log('Query cannot be created. Contact an admin.')
      return
    }

    const layerIds: number[] | undefined = this.layerIdToArray(this.form.controls.queryLayer?.value)
    const threshold = this.form.controls.thresholdInput?.value ? parseInt(this.form.controls.thresholdInput?.value) : undefined
    const queryType = this.form.controls.queryType?.value

    const options = {
      threshold: threshold,
      query_type: queryType
    }
    this._layoutService.createWidgetofType(this.widgetType, layerIds, undefined, queryId, options)
    this._dialogRef.close()
  }

  updateWidgetSource() {
    if (!this.widgetType) return
    const widgetType = this.form.controls.widgetType
    if (['MapWidget', 'AttributeTableWidget'].includes(this.widgetType)) {
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(undefined, [Validators.required]),
        layerId: new TypedFormControl<string | string[]>(undefined, [Validators.required])
      })
    } else if ('ChartWidget' === this.widgetType) {
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(undefined, [Validators.required]),
        chartId: new TypedFormControl<string>(undefined, [Validators.required])
      })
    } else if ('DynamicChartingWidget' === this.widgetType) {
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(undefined, [Validators.required]),
        chartType: new TypedFormControl<string>(undefined, [Validators.required]),
        layerId: new TypedFormControl<string>(undefined, [Validators.required])
      })
    } else if ('AlertQuery' === this.widgetType) {
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(undefined, [Validators.required]),
        chartType: new TypedFormControl<string>(undefined, [Validators.required]),
        layerId: new TypedFormControl<string>(undefined, [Validators.required]),
        queryLayer: new TypedFormControl<string>(undefined, [Validators.required]),
        queryType: new TypedFormControl<string>(undefined, [Validators.required]),
        thresholdInput: new TypedFormControl<number>(undefined, [Validators.required]),
      })
    } else if ('EmissionsRasterChart' === this.widgetType) {
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(undefined, [Validators.required]),
        queryId: new TypedFormControl<string>(undefined, [Validators.required])
      })
    } else {
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(undefined, [Validators.required])
      })
    }
    this.form.controls.widgetType = widgetType
  }

  updateChartType() {
    if (this.form.controls.chartType.value !== 'pie') {
      this.chartWithAxes = true
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(this.form.controls.widgetType?.value, [Validators.required]),
        chartType: new TypedFormControl<string>(this.form.controls.chartType?.value, [Validators.required]),
        layerId: new TypedFormControl<string>(this.form.controls.layerId?.value, [Validators.required]),
        xAxis: new TypedFormControl<string>(undefined, [Validators.required]),
        yAxis: new TypedFormControl<string>(undefined, [Validators.required])
      })
    } else {
      this.chartWithAxes = false
      this.form = new TypedFormGroup({
        widgetType: new TypedFormControl<string>(this.form.controls.widgetType?.value, [Validators.required]),
        chartType: new TypedFormControl<string>(this.form.controls.chartType?.value, [Validators.required]),
        layerId: new TypedFormControl<string>(this.form.controls.layerId?.value, [Validators.required]),
        aggColumn: new TypedFormControl<string>(undefined, [Validators.required])
      })
    }
  }

  async updateAvailableLayerColumns() {
    const layerId = this.form.controls?.layerId.value as string
    await this.loadLayer(layerId)
    if (!this.targetLayer) {
      this._alertService.log('Layer not loaded.')
      return
    }
    this.targetLayer.refreshColumns()
    this._subscriptions.add(this.targetLayer.columnsRefreshed.subscribe(async () => {
      this.availableLayerColumns = await this.dashboardApiService.availableColumnsForLayer(layerId)
      this.numericLayerColumns = await this.dashboardApiService.availableColumnsForLayer(layerId, true)
    }))
  }

  async loadLayer(layerId: string) {
    this.layerId = layerId
    const wsId = this.dashboardApiService.workspaceId
    this.loadingLayer = true
    if (!this._workspaceService.workspaceId) await this._workspaceService.loadWorkspace(wsId)
    this.targetLayer = await this._layerService.getById(layerId)
    this.loadingLayer = false
  }

  layerIdToArray(ids?: string | number[]): number[] | undefined {
    if (!ids) return

    if (Array.isArray(ids)) {
      return ids
    } else {
      return [+ids]
    }
  }

  openWidgetExampleDialog() {
    if (!this.widgetType) return
    const url = this.widgetExampleImgs[this.widgetType]
    const dialog = this._trackDialog(
      this._dialog
      .open(ZoomPanelComponent, ZoomPanelComponent.setup({assetUrl: url}))
    )
    dialog.afterClosed()
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
    this._destroyDialogs()
  }
}
