import { Pipe, PipeTransform } from '@angular/core'
@Pipe({
  name: 'object-placeholder'
})
export class ObjectPlaceholderPipe implements PipeTransform {

  transform (value: any): string {
    if (Array.isArray(value)) return 'Array [...]'
    else if (typeof value === 'object') return 'JSON {...}'
    else if (typeof value === 'function') return 'function(...)'
    return value
  }
}
