import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { DomainService } from '@services/core/domain/domain.service'
import { LogoSelector } from '@vip-shared/interfaces/logo-selector'
import { CustomerService } from '@services/admin'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { Db } from '@vip-shared/models/db-definitions'
import { WorkspaceService } from '@services/workspace'
import { AuthService } from '@services/core'

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnDestroy, OnInit {
  private _subscriptions = new Subscription()
  // Logo configuration selection based on parent component
  // location
  @Input() logoSelector?: LogoSelector

  private _logoColumns = [
    Db.Vip.Cst.Customer.CUSTOMER_LOGO,
    Db.Vip.Cst.Customer.CUSTOMER_LOGO_SECONDARY
  ]
  private _headerColumns = [
    Db.Vip.Cst.Customer.HEADER_LOGO,
    Db.Vip.Cst.Customer.HEADER_LOGO_SECONDARY
  ]

  @Input() src?: string
  private _defaultSrc = 'assets/logos/vip-logo-white.svg'
  get defaultSrc () {
    return this._customerService.showVipLogos ? this._defaultSrc : ''
  }

  constructor (
    private _domainService: DomainService,
    private _customerService: CustomerService,
    private _workspaceService: WorkspaceService,
    private _authService: AuthService
  ) {}

  ngOnInit () {
    if ([Db.Vip.Product.ELECTRIC_VEHICLES, Db.Vip.Product.SOLAR_PV].includes(this._workspaceService.product as any)) {
      this._defaultSrc = '/assets/logos/customer/energeo/logo-text.png'
    }

    this.src = this.GetUrl()

    this._subscriptions.add(
      this._authService.sessionActive
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.src = this.GetUrl()
      })
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  private GetUrl () {
    const customer = this._authService.customer
    if (!customer) {
      return this._domainService.logoUrl(this.logoSelector)
    }

    const theme = customer.theme

    // Default logo selection
    if (!this.logoSelector) this.logoSelector = 'auth_page'

    let selection = theme &&
        theme.logo_selection &&
        theme.logo_selection[this.logoSelector || 'auth_page']

    if (!selection || selection !== 'secondary') selection = 'primary'

      // List of columns, 1st being default one
      // and other columns should be used as fallback
    let columns: string[] = []
    const appendColumns = (...cols: string[]) => {
      columns.push(...selection === 'primary' ? cols : cols.reverse())
    }

      // For header components, prioritise header
      // specific logos
    if (this.logoSelector.includes('header')) {
      appendColumns(...this._headerColumns)
    }

    appendColumns(...this._logoColumns)

      // Find first defined/available column
    const urlKey = columns.find(x => customer[x])
    const url = urlKey && customer[urlKey] as string

    if (this.logoSelector === 'about' || this.logoSelector === 'workspace_page') {
      return this._customerService.showVipLogos ? this._defaultSrc : url
    }
    return url
  }
}
