import { Injectable } from '@angular/core'
import Feature from 'ol/Feature'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import * as olStyle from 'ol/style'
import * as olGeom from 'ol/geom'
import Geojson from 'ol/format/GeoJSON'

import { IMeasurement } from '@core/types'
import { ConvertUtil } from '@core/utils/index'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { WorkspaceService } from '../workspace.service'
import { WorkspaceMapService } from '../workspace-map/workspace-map.service'
import AppError, { handleError } from '@core/models/app-error'
import { Db } from '@vip-shared/models/db-definitions'
@Injectable({
  providedIn: 'root'
})
export class MeasureHistoryService {

  private _visible = false
  private _vectorSource = new VectorSource()
  private _measurementLayer = new VectorLayer({
    // keep on top of all layers except highlight layer
    zIndex: 999,
    source: this._vectorSource,
    style: [
      new olStyle.Style({
        fill: new olStyle.Fill({
          color: 'rgba(56, 182, 171, 0.3)'
        }),
        stroke: new olStyle.Stroke({
          color: 'rgba(56, 182, 171, 1)',
          width: 2
        })
      })
    ]
  })

  private _measurementHistory: IMeasurement[] = []

  get visible (): boolean {
    return this._visible
  }

  get measurementHistoryLayer () {
    return this._measurementLayer
  }

  get history () {
    return this._measurementHistory
  }

  constructor (
        private _api: VipApiService,
        private _workspaceService: WorkspaceService,
        private _workspaceMapService: WorkspaceMapService
    ) {
    this._measurementLayer.set('interactive', true)
    this._workspaceService.onExit.subscribe(this.clearMeasurements.bind(this))
  }

  toggleVisibility (visible?: boolean): void {
    if (this.history.length > 0) {
      this._visible = visible === undefined ? !this.visible : visible
    } else {
      this._visible = false
    }
  }

  // TODO: Refactor/cleanup all measurement logic
  async addMeasurement (feature: Feature, displayFormat: string, visible: boolean, geom?: olGeom.Polygon | olGeom.LineString | olGeom.MultiPolygon | olGeom.MultiLineString, save: boolean = true) {
    if (geom !== undefined && displayFormat && feature) {
      const newMeasurement = {
        displayFormat: displayFormat,
        type: geom
      }

      this._measurementHistory.push({
        measurement: newMeasurement,
        feature: feature,
        visible
      })

      if (save) await this.saveMeasurements()
    }
  }

  clearMeasurements () {
    for (const layer of this._measurementHistory) {
      this.RemoveFromLayer(layer.feature)
    }
    this._measurementHistory.splice(0, this._measurementHistory.length)
    this._visible = false
    this._workspaceMapService.removeMapLayer(this._measurementLayer)
    this._vectorSource.clear()
  }

  async removeMeasurement (index: number, measurement: Feature) {

    this.RemoveFromList(index)
    this.RemoveFromLayer(measurement)
    await this.saveMeasurements()
  }

  async loadMeasurements () {
    if (this._workspaceMapService.hasMapLayer(this._measurementLayer)) this.clearMeasurements()
    this._workspaceMapService.addMapLayer(this._measurementLayer)

    const measures = await this._api.orm.Measurements().WsMeasurement(
      this._workspaceService.workspaceId as number,
      this._workspaceService.viewId as number)
    .get().run()

    const features = new Geojson().readFeatures(measures)
    this.LoadFeatures(features)

    if (features.length) {
      this.toggleVisibility(true)
    }
  }

  private LoadFeatures (features: Feature[]) {
    this._measurementHistory = []
    const source = this.measurementHistoryLayer.getSource()
    if(!source) return
    source.clear()

    for (const f of features) {
      const geom = f.getGeometry()
      if (!(geom instanceof olGeom.LineString || geom instanceof olGeom.Polygon)) continue
      const displayFormat = ConvertUtil.geometryToMeasureString(geom, 2)
      if (!f.get(Db.Vip.Geo.Measurement.VISIBLE)) {
        f.setStyle(new olStyle.Style({}))
      }
      source.addFeature(f)
      this.addMeasurement(f, displayFormat, f.get(Db.Vip.Geo.Measurement.VISIBLE), geom, false)
    }
  }

  async saveMeasurements () {
    try {
      const source = this.measurementHistoryLayer.getSource()
      if (!source) throw new AppError('No source for measurement layer')
      await this._workspaceService.waitForWorkspaceAndView()
      const formatter = new Geojson()
      const geoJson = JSON.parse(
        formatter.writeFeatures(source.getFeatures().map(measurement => {
          measurement.setProperties({
            // If there is empty style override - then hidden
            visible: measurement.getStyle() ? false : true
          })
          return measurement
        }))
      )

      const saved = await this._api.orm.Measurements().WsMeasurement(
        this._workspaceService.workspaceId as number,
        this._workspaceService.viewId as number)
      .save(geoJson).run()

      this.LoadFeatures(formatter.readFeatures(saved))

      this.LoadFeatures(formatter.readFeatures(saved))

    } catch (error: any) {
      handleError(error)
      throw new AppError('Failed to save measurement')
    }
  }
  private RemoveFromList (index: number) {
    this._measurementHistory.splice(index, 1)

    if (!this._measurementHistory.length) {
      this._visible = false
    }
  }

  private RemoveFromLayer (measurement: Feature) {
    try {
      const source = this.measurementHistoryLayer.getSource()
      if (!source) throw new AppError('No source for measurement layer')
      if (source.getFeatures().includes(measurement)) {
        source.removeFeature(measurement)
      }
    } catch (error: any) {
      handleError(error)
    }
  }

}
