import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, OnDestroy } from '@angular/core'
import * as moment from 'moment'
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker'
import { interval, Subscription } from 'rxjs'

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent implements AfterViewInit, OnDestroy {
  protected _subscriptions = new Subscription()
  private _viewInit = false
  @Input() shouldBe?: 'inPast' | 'inFuture'
  @Input() expiryNote = 'Date is expired.'

  @Input() title?: string

  @Input() required = false
  @Input() disabled = false
  @Input() min?: moment.Moment
  @Input() max?: moment.Moment
  @Input() showTrigger = true
  private _value?: moment.Moment
  @Input()
  get value () {
    return this._value
  }
  set value (val: moment.Moment | any) {
    if (val && typeof val === 'string') val = moment(val)

    if (!this._viewInit) {
      this._value = val || undefined
    } else {
      const adapter = this.owlElement['dateTimeAdapter']
      if (!val || (adapter && this.owlElement.dateTimeChecker(val))) {
        this._value = val || undefined
        this.valueChange.emit(this._value)
      }

    }
  }

  get valid () {
    return !this.required || this._value
  }

  @Output() valueChange = new EventEmitter<moment.Moment | undefined>()
  @ViewChild('owl', { static: false }) owlElement!: OwlDateTimeComponent<any>

  dateInactive = false

  ngAfterViewInit () {
    this._viewInit = true
    this._subscriptions.add(
      interval(1000).subscribe(this.UpdateInactiveState.bind(this))
    )
  }

  ngOnDestroy () {
    this._subscriptions.unsubscribe()
  }

  private UpdateInactiveState () {
    if (!this._value) {
      this.dateInactive = false
      return
    }
    if (this.shouldBe === 'inPast') this.dateInactive = this._value.isAfter(moment())
    else if (this.shouldBe === 'inFuture') this.dateInactive = this._value.isBefore(moment())
  }
}
