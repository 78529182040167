import { Db } from '@vip-shared/models/db-definitions'
import { VipApiService, PromptService } from '@services/core'
import * as moment from 'moment'
import { MatDialog } from '@angular/material/dialog'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { WorkspaceService } from '@services/workspace'

export class AppLayerBase {
  protected _subscriptions = new Subscription()
  readonly type!: 'layer' | 'group'
  readonly id: string
  protected _name: string
  protected _visible: boolean
  protected _extent?: Db.Vip.Geo.ILayer['extent']
  protected _loaded: boolean = false
  readonly _createUser?: number | string

  protected _reloaded = new Subject<undefined | any>()
  protected _attributeChange = new Subject()
  protected _nameChange = new Subject()
  protected _visibleChange = new Subject<boolean>()
  protected _styleChanged = new Subject()

  fTitleEditing?: boolean

  protected _waitForSpatialSelect: boolean = false
  get waitingForSpatialSelect () {
    return this._waitForSpatialSelect
  }

  protected _deleteFn?: () => Promise<void>
  protected _register = {
    delete: (cb: () => Promise<void>) => {
      if (this._deleteFn) throw new Error('Element delete() already registered.')
      this._deleteFn = cb
    }
  }

  get loaded (): boolean {
    return this._loaded
  }

  get title (): string {
    return this._name
  }
  set title (val: string) {
    this._name = val
    this._nameChange.next({})
  }

  get visible (): boolean {
    return this._visible
  }
  set visible (val: boolean) {
    const prev = this._visible
    this._visible = val

    if (prev !== val) {
      this._visibleChange.next(this._visible)
      this._attributeChange.next({})
    }
  }

  get visibleChange () {
    return this._visibleChange.asObservable()
  }

  protected _order?: number
  get order (): number {
    return this._order || 0
  }
  set order (val: number) {
    if (val === this._order) return

    this._order = val
    this._attributeChange.next({})
    this._orderChange.next(this._order)
  }

  protected _orderChange = new Subject<number>()
  get orderChange () {
    return this._orderChange.pipe(
      // Give it some time to apply z index to layer on map
      debounceTime(50)
    )
  }

  get extent (): Db.Vip.Geo.ILayer['extent'] {
    return this._extent
  }

  get createUser (): number | undefined | string {
    return this._createUser
  }

  get reloaded () {
    return this._reloaded.asObservable()
    .pipe(
      debounceTime(100)
    )
  }

  get styleChanged () {
    return this._styleChanged.asObservable()
    .pipe(
      debounceTime(100)
    )
  }

  constructor (
    protected _dialog: MatDialog,
    protected _prompt: PromptService,
    protected _api: VipApiService,
    protected _workspaceService: WorkspaceService,
    { id, title, visible, order, createUser }: AppLayerBaseOptions
  ) {
    this.id = id
    this._name = title
    this._visible = visible
    this._order = order
    this._createUser = createUser

    this._subscriptions.add(
      this._attributeChange.pipe(
        debounceTime(1000)
      ).subscribe(
        () => this.SaveAttributes()
      )
    )

    this._subscriptions.add(
      this._nameChange.pipe(
        debounceTime(1000)
      ).subscribe(
        () => this.SaveName()
      )
    )
  }

  registerSubscription (sub: Subscription) {
    this._subscriptions.add(sub)
  }

  delete () {
    if (!this._deleteFn) console.warn(`Delete() for element ${this.id} is not registered.`)
    return this._deleteFn && this._deleteFn()
  }

  async waitForLoaded (timeoutS = 120) {
    const start = moment()
    while (!this._loaded && (timeoutS ? moment().diff(start, 's') <= timeoutS : true)) {
      await new Promise(res => setTimeout(res, 250))
    }

    if (!this._loaded) console.warn(`Wait for loaded timeout on layer '${this.title}'`)
    return this._loaded
  }

  protected async SaveAttributes () {
    console.warn(`SaveAttributes() for element ${this.id} is not defined.`)
  }

  protected async SaveName () {
    console.warn(`SaveName() for element ${this.id} is not defined.`)
  }

  async reload (): Promise<boolean | void> {
    console.warn(`reload() for element ${this.id} is not defined.`)
    return
  }

  saveAttributes () {
    this._attributeChange.next({})
  }
}

export interface AppLayerBaseOptions {
  createUser?: number | string
  id: string
  title: string
  visible: boolean
  order?: number
}
