import { CommonUtil } from '@core/utils/common/common.util'

export default class GoogleUtil {
  static openMapsUrl (lon: number, lat: number, zoom = 17) {
    CommonUtil.openLink(
      `http://www.google.com/maps/place/${lon},${lat}/@${zoom}z/data=!3m1!1e3`
    )
  }

  static openEarthUrl (lon: number, lat: number) {
    CommonUtil.openLink(
      `https://earth.google.com/web/search/${lon},${lat}/`
    )
  }
}
