import { Directive, HostBinding, Input } from '@angular/core'

@Directive({
  selector: '[appShow]'
})
export class ShowDirective {
  private _show = false
  @HostBinding('class') private _class: 'st-show' | 'st-hide' = 'st-hide'

  @Input()
  get appShow () {
    return this._show
  }
  set appShow (val: boolean) {
    this._show = val
    this._class = this._show ? 'st-show' : 'st-hide'
  }
}
