<div class="st-table-filters">
  <div class="st-filter-group">
    <mat-form-field>
      <input matInput (keyup)="applyFilter('name', $event.target.value)" [value]="filter.name" placeholder="Filter by name">
    </mat-form-field>
  </div>
  <mat-slide-toggle [checked]="filter.all" labelPosition="before"
  (change)="toggleWProductAccess($event)" color="primary">Include customers without product access</mat-slide-toggle>
</div>

<ng-template #nodata>
    <app-note>
        No customers found matching the filter
    </app-note>
  </ng-template>

<div class="mat-elevation-z5 st-table-overflow access-table" *ngIf="dataSource?.filteredData?.length; else nodata">
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="customer_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Customer</mat-header-cell>
      <mat-cell *matCellDef="let customer" [class.st-disabled]="!customer.productAccess">
        <span>{{ customer.customer_name }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="logo">
      <mat-header-cell class="st-narrow-cell" *matHeaderCellDef ></mat-header-cell>
      <mat-cell class="st-narrow-cell" *matCellDef="let customer" [class.st-disabled]="!customer.productAccess">
        <img [draggable]="false" *ngIf="customer.customer_logo" [src]="customer.customer_logo">
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="product_access">
      <mat-header-cell *matHeaderCellDef >Product Access</mat-header-cell>
      <mat-cell *matCellDef="let customer">
        <mat-checkbox class="cell-checkbox" *ngIf="customer.productAccess && !customer.hasTriggeredProductAccess" [checked]="true" [disabled]="true"></mat-checkbox>
        <button mat-raised-button *ngIf="!customer.productAccess || customer.hasTriggeredProductAccess"
          [color]="customer.hasTriggeredProductAccess ? 'amber' : 'primary'"
          [matTooltip]="customer.hasTriggeredProductAccess ? 'Revoke product access' : 'Customer has no access to workspace product. Click to grant access to the product.'"
          (click)="toggleCustomerProductAccess(customer, !customer.productAccess)">
          {{customer.hasTriggeredProductAccess ? 'Revoke' : 'Grant'}}
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valid_from">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Valid From</mat-header-cell>
      <mat-cell *matCellDef="let customer">
        <app-date-time-input shouldBe="inPast" expiryNote="Workspace access for customer is forbidden until after the date."
        [disabled]="isDisabled(customer)" [(value)]="customer.valid_from" (valueChange)="updatePermissions(customer)"
        [min]="today" [max]="customer.valid_to" [showTrigger]="false"></app-date-time-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valid_to">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Valid To</mat-header-cell>
      <mat-cell *matCellDef="let customer">
        <app-date-time-input shouldBe="inFuture" expiryNote="Workspace access for customer is expired."
        [disabled]="isDisabled(customer)" [(value)]="customer.valid_to" (valueChange)="updatePermissions(customer)"
        [min]="customer.valid_from || today" [showTrigger]="false"></app-date-time-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="allowed">
      <mat-header-cell class="st-narrow-cell" *matHeaderCellDef mat-sort-header>Access</mat-header-cell>
      <mat-cell class="st-narrow-cell" *matCellDef="let customer">
        <mat-slide-toggle [checked]="customer.allowed" color="primary" [disabled]="disableActions || !customer.productAccess"
          (change)="toggleCustomerAccess($event, customer)"></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="st-narrow-cell" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="st-narrow-cell" *matCellDef="let customer">
        <button mat-icon-button *ngIf="customer.allowed && customer.productAccess" matTooltip="Show customer users access."
          (click)="usersFilter.emit(customer.customer_name)" [disabled]="disableActions">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<app-loader-backdrop *ngIf="loading"></app-loader-backdrop>
