import { Injectable } from '@angular/core'
import Feature from 'ol/Feature'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import * as olStyle from 'ol/style'
import Point from 'ol/geom/Point'
import * as olExtent from 'ol/extent'
import * as olProj from 'ol/proj'
import { Subject } from 'rxjs'
import { ITankFilter } from '@core/types/'
import { IFeatureProperties, IOperatorSiteMeasureWChangeExtended } from '@core/types'
import { CommonUtil } from '@core/utils/index'
import * as moment from 'moment'
import { WorkspaceMapService } from '@services/workspace/workspace-map/workspace-map.service'
@Injectable({
  providedIn: 'root'
})

export class TankwatchService {
  readonly regionColorCode = {
    'OECD AMERICAS': '#4169e1',
    'OECD ASIA': '#32cd32',
    'OECD EUROPE': '#808080',
    'NON OECD AFRICA': '#ffa500',
    'NON OECD CHINA': '#ff0000',
    'OPEC': '#000000',
    'OECD OCEANIA': '#E91E63',
    'NON OECD OPEC': '#9C27B0',
    'NON OECD EUROPE': '#00BCD4',
    'NON OECD ASIA': '#FFEB3B',
    'NON OECD AMERICAS': '#795548'
  }

  private _searchFilter = ''
  latestFilter: ITankFilter = {}
  private _searchChange = new Subject<string[]>()
  private _filterChange = new Subject<any>()
  private _updateTimeout?:  ReturnType<typeof setTimeout>
  private _vectorLayers: VectorLayer<any>[] = []
  initialZoomComplete = false

  get searchFilter () {
    return this._searchFilter
  }
  set searchFilter (value: string) {
    this._searchFilter = value
    let filters = value.split(',')
    // Remove empty values and trim text from extra spacing
    filters = filters.map(x => x.trim()).filter(x => !!x)
    this.latestFilter.mixedSearch = filters.map(v => `%${v}%`)
    this._searchChange.next(filters)
    this.updateCriteria()
  }

  get searchChange () {
    return this._searchChange.asObservable()
  }

  get filterChange () {
    return this._filterChange.asObservable()
  }

  constructor (
        private _workspaceMapService: WorkspaceMapService
    ) {}

  getRegionColorCode (region: string) {
    return this.regionColorCode[region] || '#ffffff'
  }

  loadLayers (tankMeasures: IOperatorSiteMeasureWChangeExtended[]) {
    // Done?
    if (this._vectorLayers.length > 0) this.clearLayers()
    if (tankMeasures.length === 0) return

    const layerGroups: {[key: string]: IOperatorSiteMeasureWChangeExtended[]} = {}

    for (const measure of tankMeasures) {
      if (!layerGroups[measure.region]) {
        layerGroups[measure.region] = []
      }
      layerGroups[measure.region].push(measure)
    }

    for (const region in layerGroups) {
      const group = layerGroups[region]
      if (group.length > 0) {
        const color = this.getRegionColorCode(region)
        const layer = new VectorLayer({
          style: new olStyle.Style({
            image: new olStyle.Circle({
              radius: 7,
              fill: new olStyle.Fill({ color }),
              stroke: new olStyle.Stroke({ color: '#ffffff', width: 1 })
            })
          }),
          source: new VectorSource()
        })

        layer.setProperties({
          country_region: region,
          interactive: true
        })

        this._vectorLayers.push(layer)
        for (const item of group) {
          item.layer = layer
          const coords: [number, number] = [+item.longitude, +item.latitude]
          const feature = new Feature({
            geometry: new Point(olProj.transform(coords, 'EPSG:4326', 'EPSG:3857'))
          })
          item.feature = feature
          const ifDefinedGetFormatted = (num: number | null) => num || num === 0 ? CommonUtil.numberFormat(num) : '-'
          feature.setProperties({
            tooltip: {
              elementId: 'default-tooltip',
              // visible: false,
              content: [
                { name: 'Terminal/Operator', value: item.name },
                { name: 'Capacity / K.bbl', value: CommonUtil.numberFormat(+item.capacity_bbl) },
                { name: 'Current Stock / K.bbl', value: CommonUtil.numberFormat(+item.measure_bbl) },
                { name: 'Week Ending', value: moment(item.week_ending, 'YYYY-MM-DD').format('DD/MM/YY') },
                {
                  name: 'Previous Stock / K.bbl', value: item.prev_measure_bbl,
                  formatted: ifDefinedGetFormatted(item.prev_measure_bbl)
                },
                {
                  name: 'Change (Week) / K.bbl', value: item.prev_measure_change,
                  formatted: ifDefinedGetFormatted(item.prev_measure_change), numberStyling: true
                },
                {
                  name: 'Change (Year) / K.bbl', value: item.prev_year_measure_change,
                  formatted: ifDefinedGetFormatted(item.prev_year_measure_change), numberStyling: true
                },
                { name: 'Utilisation', value: item.operator_site_utilisation }
              ],
              grid: {
                cellWidth: 150,
                columns: 2
              }
            }
          } as IFeatureProperties)
          const layerSource = layer.getSource()
          if(!layerSource) throw new Error('Layer source is not defined')
          layerSource.addFeature(feature)
        }
      }
    }
    this.showLayers()
  }

  clearLayers () {
    for (const layer of this._vectorLayers) {
      this._workspaceMapService.removeMapLayer(layer)
    }
    this._vectorLayers.splice(0)
  }

  showLayers () {
    for (const layer of this._vectorLayers) {
      this._workspaceMapService.addMapLayer(layer)
    }
  }

  zoomToLayers () {
    if (this.initialZoomComplete) return
    let extent
    for (const layer of this._vectorLayers) {
      const layerExtent = layer.getSource().getExtent()

      extent = extent ? olExtent.extend(extent, layerExtent) : layerExtent
    }

    if (extent) {
      this._workspaceMapService.zoomToExtent(extent)
    }
  }

  filterByCountryName (names: string[]) {
    this.latestFilter.countryNames = names
    this.updateCriteria()
  }

  updateCriteria () {
    if (this._updateTimeout) clearTimeout(this._updateTimeout)

    this._updateTimeout = setTimeout(() => {
      this._filterChange.next({})
    }, 1000)
  }
}
