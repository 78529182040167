import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms'
import { Db } from '@vip-shared/models/db-definitions'
import { AlertService, VipApiService } from '@services/core'
import { IRPresetFilterOption } from '@vip-shared/interfaces'
import { handleError } from '@core/models/app-error'
import { Subscription, merge } from 'rxjs'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { GuideSetDialogComponent } from './guide-set-dialog/guide-set-dialog.component'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { auditTime, tap } from 'rxjs/operators'
import * as moment from 'moment'
import FormValidators from '@core/utils/form-validators/form-validators'
import { ScraperForm } from '@core/types/forms'
import { ScraperPresets } from '@vip-shared/models/const/scraper-presets'

type Form = TypedFormGroup<{
  presetName: TypedFormControl<string>
  filters: UntypedFormGroup
  mode?: TypedFormControl<Db.Vip.LayerMode>
  datasetsQuery: TypedFormControl<any>,
  datasetSelection: UntypedFormGroup,
  datetimeSelection: UntypedFormGroup
}>

// TODO: The preset filtering options were finished in a rush and could use a cleanup
@Component({
  selector: 'app-preset-config',
  templateUrl: './preset-config.component.html',
  styleUrls: ['./preset-config.component.scss']
})
export class PresetConfigComponent implements OnInit, OnDestroy, DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T>(dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs(): any { return }

  private _subscriptions = new Subscription()
  private _scrapperFromSubscriptions = new Subscription()

  formControlKeys = ['presetName', 'filters', 'mode', 'datasetsQuery', 'datasetSelection', 'scraperForm']

  @Output() formChange = new EventEmitter<Form>()
  @Output() selectedPreset = new EventEmitter<Db.Vip.Geo.ILayerPreset>()
  @Input() productId!: number
  @Input() allowModes = true
  @Input() onlyPresets: Db.Vip.LayerPreset[] = []

  @Input() scraperForm?: ScraperForm
  timeIntervals: Db.Helper.Geo.TimeInterval[] = ['minute', 'hour', 'day', 'month']


  form: Form
  datasetSelectionForm: UntypedFormGroup
  filtersForm: UntypedFormGroup
  stickyHeader = true

  preset = Db.Vip.LayerPreset

  backDateDays: number = 0
  presetName: string = ''

  fetchingData = false
  layerPresets: Db.Vip.Geo.ILayerPreset[] = []
  filters: Db.Vip.Geo.IPresetFilter[] = []
  filterControls: {
    filterTag: string
    control: UntypedFormControl
    options: IRPresetFilterOption[]
    filteredOptions: IRPresetFilterOption[]
    name: string
    multiple: boolean
    visible: () => boolean
  }[] = []

  // Append disabled flag - to disallow using modes for which there are
  // no guides/algorithms
  modes: (Db.Vip.Geo.ILayerMode & { disabled?: boolean })[] = []
  presetDatasets: (Db.Vip.Geo.IPresetDataset & { disabled?: boolean, disabledReason?: string })[] = []

  private _mlDatasets?: Db.Vip.PV.IMlDataset[]
  filteredMlDatasets?: Db.Vip.PV.IMlDataset[]
  private _anotGuideSets?: Db.Vip.PV.IAnotGuideSet[]
  filteredAnotGuideSets?: {
    withMeasures?: Db.Vip.PV.IAnotGuideSet[]
    withoutMeasures?: Db.Vip.PV.IAnotGuideSet[]
  }
  get guideSetCount() {
    return this._anotGuideSets && this._anotGuideSets.length
  }

  requireAnnotationGuide = false
  requireMlDataset = false

  private get _pvApi() {
    return this._api.orm.Products().PropertyView()
  }

  readonly defaultPresetQuery = {
    [Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS]: [{
      dataset: Db.Vip.PresetDataset.PV_ANOT,
      sequence: [{
        dataset: Db.Vip.PresetDataset.PV_ANOT_QA,
        join_as: Db.Helper.Geo.DatasetJoin.OUTER
      }]
    }, {
      dataset: Db.Vip.PresetDataset.PV_ML,
      join_as: Db.Helper.Geo.DatasetJoin.OUTER,
      sequence: [{
        dataset: Db.Vip.PresetDataset.PV_ML_HE,
        join_as: Db.Helper.Geo.DatasetJoin.OUTER
      }, {
        dataset: Db.Vip.PresetDataset.PV_ML_QA,
        join_as: Db.Helper.Geo.DatasetJoin.OUTER
      }]
    }]
  }
  selectedQuery?: any

  get selectedMode(): 'read' | 'write' | undefined {
    return this.allowModes ? (
      this.form.controls.mode.value &&
      (this.form.controls.mode.value === Db.Vip.LayerMode.READ_ONLY ? 'read' : 'write')
    ) : 'read'
  }

  locationWarning?: string

  constructor(
    private _alertService: AlertService,
    private _api: VipApiService,
    private _dialog: MatDialog
  ) {
    this.form = new TypedFormGroup({
      presetName: new TypedFormControl<string>(undefined, [Validators.required]),
      filters: new UntypedFormGroup({}),
      datasetsQuery: new TypedFormControl(undefined),
      datasetSelection: new UntypedFormGroup({}),
      datetimeSelection: new UntypedFormGroup({})
    })

    this.datasetSelectionForm = this.form._controls.datasetSelection
    this.filtersForm = this.form._controls.filters

    this._subscriptions.add(
      merge(
        this.form.statusChanges,
        this.form.valueChanges,
        this.filtersForm.valueChanges,
        this.datasetSelectionForm.valueChanges
      ).pipe(auditTime(1000)).subscribe(() =>
        this.formChange.next(this.form)
      )
    )
  }

  ngOnInit() {
    if (this.allowModes) {
      this.form.addControl('mode', new TypedFormControl<Db.Vip.LayerMode>(
        Db.Vip.LayerMode.READ_ONLY,
        Validators.required
      ))
    }
    this.LoadPresets()
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
    this._destroyDialogs()
  }

  placeholder(title: string, items: number) {
    return `${title}${items ? '' : ' (None Available)'}`
  }

  createNewGuideSet() {
    this._trackDialog(
      this._dialog.open(GuideSetDialogComponent)
    )
      .afterClosed().subscribe((set?: Db.Vip.PV.IAnotGuideSet) => {
        if (set) {
          if (!this._anotGuideSets) this._anotGuideSets = []
          this._anotGuideSets.push(set)

          this.datasetSelectionForm.controls.anot_guide_set_id.setValue(set.anot_guide_set_id)
          this.AdjustSelectionBasedOnProject()
        }
      })
  }

  editSet(set: Db.Vip.PV.IAnotGuideSet) {
    this._trackDialog(
      this._dialog.open(GuideSetDialogComponent, {
        data: set
      })
    )
      .afterClosed().subscribe((editedSet?: Db.Vip.PV.IAnotGuideSet) => {
        if (editedSet) set.name = editedSet.name
      })
  }

  modeChanged(mode: Db.Vip.LayerMode) {
    if (mode !== Db.Vip.LayerMode.READ_ONLY) {
      this.selectedQuery = this.form._controls.datasetsQuery.value
      this.form._controls.datasetsQuery.reset()
    } else {
      this.form._controls.datasetsQuery.setValue(
        this.selectedQuery || this.GetDefaultPVDatasetQuery()
      )
    }
    this.checkDatasetSelection()
  }

  private async LoadPresets() {
    this.fetchingData = true
    try {
      let presets = await this._api.orm.Products().Product(this.productId)
        .LayerPresets().get().run()

      this.layerPresets = (this.onlyPresets && this.onlyPresets.length) ?
        presets.filter(p => this.onlyPresets.includes(p.layer_preset_tag as any)) :
        presets

      if (this.layerPresets.length === 1) {
        this.form._controls.presetName.setValue(this.layerPresets[0].layer_preset_tag)
        this.form._controls.presetName.disable()
        this.presetChange(this.layerPresets[0].layer_preset_tag as Db.Vip.LayerPreset)
      }
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    this.fetchingData = false
  }

  private UpdatePresetSpecificNotes(presetTag: Db.Vip.LayerPreset) {
    this.locationWarning = undefined

    switch (presetTag) {
      case Db.Vip.LayerPreset.GOOGLE_NEWS:
        this.locationWarning = [
          `Please note that Google News does not specify location of article. Therefore all results, returned for location,`,
          `will be assigned to selected location even if they did not originate there.`
        ].join(' ')
        break
      case Db.Vip.LayerPreset.TWITTER:
        this.locationWarning = [
          `Please note that not all Tweets have geolocation information. Therefore some results, returned for location,`,
          `will be assigned to selected location even if they did not originate there.`
        ].join(' ')
        break
    }
  }

  private UpdatePresetBackDate(presetTag: Db.Vip.LayerPreset) {
    switch (presetTag) {
      case Db.Vip.LayerPreset.GOOGLE_NEWS:
        this.backDateDays = 0
        break
      case Db.Vip.LayerPreset.TWITTER:
        // NOTE : Twitter Premium can only fetch results 30 days prior to the current date
        this.backDateDays = 30
        break
      default:
        break
    }
  }

  private UpdatePresetTimeInterval(presetTag: Db.Vip.LayerPreset) {
    switch (presetTag) {
      case Db.Vip.LayerPreset.GOOGLE_NEWS:
        break
      case Db.Vip.LayerPreset.TWITTER:
        // Remove minute interval for twitter
        this.timeIntervals.splice(0, 1)
        break
      default:
        break
    }
  }


  async presetChange(presetTag: Db.Vip.LayerPreset) {
    this.presetName = presetTag
    const selectedPreset = this.layerPresets.find(p => p.layer_preset_tag === presetTag)
    this.selectedPreset.next(selectedPreset as Db.Vip.Geo.ILayerPreset)
    const triggerLoad = !this.fetchingData
    if (triggerLoad) this.fetchingData = true
    try {
      for (const key in this.datasetSelectionForm.controls) {
        this.datasetSelectionForm.removeControl(key)
      }

      switch (this.form._controls.presetName.value) {
        case Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS:
          const [anotSets, mlSets] = await Promise.all([
            this._pvApi.Guides().Sets().get().run(),
            this._pvApi.MlDatasets().get().run()
          ])

          this._anotGuideSets = anotSets
          this.filteredAnotGuideSets = {
            withMeasures: anotSets
          }

          this._mlDatasets = mlSets
          this.filteredMlDatasets = mlSets

          this.datasetSelectionForm.addControl(
            'anot_guide_set_id', new UntypedFormControl(
              anotSets.length === 1 ? anotSets[0].anot_guide_set_id : undefined,
              !anotSets.length ? undefined :
                (control: AbstractControl): ValidationErrors | null => {
                  if (control.value || !this.requireAnnotationGuide) return null

                  return {
                    annotationValidator: {
                      valid: false
                    }
                  }
                }
            )
          )
          this.datasetSelectionForm.addControl(
            'ml_dataset_id', new UntypedFormControl(mlSets.length === 1 ? mlSets[0].ml_dataset_id : undefined, !mlSets.length ? undefined :
              (control: AbstractControl): ValidationErrors | null => {
                if (control.value || !this.requireMlDataset) return null

                return {
                  mlValidator: {
                    valid: false
                  }
                }
              }
            )
          )
          break

      }

      this.UpdatePresetSpecificNotes(presetTag)

      this.UpdatePresetBackDate(presetTag)

      this.UpdatePresetTimeInterval(presetTag)

      await Promise.all([
        this.LoadFiltersForPreset(presetTag),
        this.LoadModesForPreset(presetTag),
        this.LoadDatasetsForPreset(presetTag)
      ])

      switch (this.form._controls.presetName.value) {
        case Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS:
          this.form._controls.datasetsQuery.setValue(
            this.GetDefaultPVDatasetQuery()
          )

          this.checkDatasetSelection()
          break
      }

      if (ScraperPresets.includes(presetTag)) {
        if (!this.scraperForm) {
          this.scraperForm = new TypedFormGroup({
            name: new TypedFormControl<string>(undefined, Validators.required),
            where_exact: new TypedFormControl<string[]>(undefined),
            where_has: new TypedFormControl<string[]>(undefined),
            where_exclude: new TypedFormControl<string[]>(undefined),
            locations: new TypedFormControl<Db.Helper.Ext.Location[]>(
              undefined, [
              Validators.required,
              FormValidators.minLength(1)
            ]
            ),
            dateRanges: new TypedFormControl<{
              from: moment.Moment
              to: moment.Moment
            }[]>(
              undefined, [
              Validators.required,
              FormValidators.minLength(1)
            ]
            )
          }, FormValidators.atLeastOneOf(['where_has', 'where_exact']))
        }
        this.form.addControl('scraperForm', this.scraperForm)
        this.formChange.next(this.form)

        this._scrapperFromSubscriptions.add(
          this.scraperForm._controls.dateRanges.valueChanges.subscribe(() => {
            if (!this.scraperForm) return
            const ranges = this.scraperForm._controls.dateRanges.value
            const now = moment()
            this.toggleIntervalField(!ranges || !ranges.length ? false : ranges.some(r =>
              r.to.isAfter(now)
            ))

            const timeFormGroup = this.form.controls.datetimeSelection as UntypedFormGroup
            const timeSeriesRanges = timeFormGroup.controls
            if (
              (!timeSeriesRanges.valid_from || !timeSeriesRanges.valid_from.value) &&
              (!timeSeriesRanges.valid_to || !timeSeriesRanges.valid_to.value) && ranges
            ) {
              let fromControl = timeSeriesRanges.valid_from
              if (!fromControl) {
                fromControl = new UntypedFormControl(undefined)
                timeFormGroup.addControl('valid_from', fromControl)
              }
              let toControl = timeSeriesRanges.valid_to
              if (!toControl) {
                toControl = new UntypedFormControl(undefined)
                timeFormGroup.addControl('valid_to', toControl)
              }

              fromControl.setValue(
                moment.min(...ranges.map(r => r.from)).toISOString()
              )

              toControl.setValue(
                moment.max(...ranges.map(r => r.to)).toISOString()
              )

              if (!this.formControlKeys.includes('datetimeSelection')) {
                this.formControlKeys.push('datetimeSelection')
              }
              this.formChange.next(this.form)
            }
          })
        )
      } else {
        this._scrapperFromSubscriptions.unsubscribe()
        this._scrapperFromSubscriptions = new Subscription()
        this.form.removeControl('scraperForm')
        this.formChange.next(this.form)
        this.scraperForm = undefined
        this.formControlKeys.splice(this.formControlKeys.indexOf('datetimeSelection'), 1)
      }

    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
    if (triggerLoad) this.fetchingData = false
  }

  private GetDefaultPVDatasetQuery() {
    const [anotQuery, mlQuery] = this.defaultPresetQuery[Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS]
    const anotSets = this.filteredAnotGuideSets && [
      ...this.filteredAnotGuideSets.withMeasures || [],
      ...this.filteredAnotGuideSets.withoutMeasures || []
    ]
    const finalQuery = [
      ...(anotSets && anotSets.length ? [anotQuery] : []),
      ...(this.filteredMlDatasets && this.filteredMlDatasets.length ? [mlQuery] : [])
    ]
    if (finalQuery[0]) finalQuery[0].join_as = undefined
    return finalQuery.length ? finalQuery : undefined
  }

  private async LoadModesForPreset(presetTag: string) {
    if (!this.form._controls.mode) return
    this.form._controls.mode.enable()

    this.modes = await this._api.orm.Layers()
      .Presets().Preset(presetTag).getModes().run()

    for (const mode of this.modes) {
      if (!(this._mlDatasets && this._mlDatasets.length) && mode.layer_mode_tag.includes('ml')) {
        mode.disabled = true
        mode.description += ' Disabled because there are no machine learning datasets available.'
      }
    }

    if (!this.modes.length) {
      this.form._controls.mode.setValue(undefined)
    } else if (this.modes.length === 1) {
      this.form._controls.mode.setValue(this.modes[0].layer_mode_tag as Db.Vip.LayerMode)
      this.form._controls.mode.disable()
    }
  }

  guideSetName(setId?: string) {
    const set = this._anotGuideSets && this._anotGuideSets.find(x => x.anot_guide_set_id === setId)
    return set ? set.name : ''
  }

  private async LoadDatasetsForPreset(presetTag: string) {
    this.presetDatasets = await this._api.orm.Layers()
      .Presets().Preset(presetTag).getDatasets().run()

    for (const ds of this.presetDatasets) {
      if (!(this._mlDatasets && this._mlDatasets.length) && ds.preset_dataset_tag.includes('ml')) {
        ds.disabled = true
        ds.disabledReason = 'Disabled because there are no machine learning datasets available.'
      }
    }

    if (!this.presetDatasets.length) this.form._controls.datasetsQuery.setValue(undefined)
  }

  private async LoadFiltersForPreset(presetTag: string) {
    for (const key in this.filtersForm.controls) {
      this.filtersForm.removeControl(key)
    }
    this.filterControls = []

    const filtersApi = this._api.orm.Layers()
      .Presets().Preset(presetTag).Filters()

    this.filters = await filtersApi.get().run()

    this.filters.sort((a, b) => a.order > b.order ? 1 : -1)

    for (const filter of this.filters) {
      const options = await filtersApi.Filter(filter.preset_filter_tag)
        .getOptions().run()
      if (options.length < 1) continue

      const control = new UntypedFormControl(undefined, filter.required ? Validators.required : undefined)
      this.filtersForm.addControl(filter.preset_filter_tag, control)

      const previousControls = [...this.filterControls]
      this.filterControls.push({
        filterTag: filter.preset_filter_tag,
        name: filter.display_name,
        control,
        options,
        filteredOptions: [...options],
        multiple: filter.multiple,
        visible: () => !previousControls.some(c => !c.control.valid)
      })

      if (filter.preset_filter_tag === Db.Vip.PresetFilter.PROJECT_TAG) {
        control.valueChanges.subscribe(() => this.AdjustSelectionBasedOnProject())
      }
    }
  }

  private AdjustSelectionBasedOnProject() {
    if (this.form._controls.presetName.value !== Db.Vip.LayerPreset.PROP_VIEW_BUILDINGS) return
    const project = this.filterControls.find(x => x.filterTag === Db.Vip.PresetFilter.PROJECT_TAG)
    const city = this.filterControls.find(x => x.filterTag === Db.Vip.PresetFilter.CITY)

    if (!project) return
    const option = project.options.find(x => x.value === project.control.value)
    const projectMeta = option && option.project_meta

    if (city) {
      city.control.enable()
      city.filteredOptions = !projectMeta ? city.options :
        city.options.filter(o => projectMeta.cities.includes(o.value as string))
      if (!city.filteredOptions.length) city.control.disable()
    }

    if (this._mlDatasets) {
      this.filteredMlDatasets = !projectMeta ? this._mlDatasets :
        this._mlDatasets.filter(d => projectMeta.ml_datasets.includes(d.ml_dataset_id))

      if (this.datasetSelectionForm.controls.ml_dataset_id) {
        this.datasetSelectionForm.controls.ml_dataset_id.enable()
        if (this.filteredMlDatasets.length === 1) {
          this.datasetSelectionForm.controls.ml_dataset_id.setValue(this.filteredMlDatasets[0].ml_dataset_id)
        } else if (!this.filteredMlDatasets.length) {
          this.datasetSelectionForm.controls.ml_dataset_id.setValue(undefined)
        }
      }
    }

    if (this._anotGuideSets) {
      const [withMeasures, withoutMeasures] = this._anotGuideSets.reduce((arr, x) => {
        if (!projectMeta || [
          Db.Vip.PV.AnotGuideSet.CONSTR_CLASS,
          Db.Vip.PV.AnotGuideSet.ROOF_MATERIAL,
          Db.Vip.PV.AnotGuideSet.ROOF_TYPE,
          Db.Vip.PV.AnotGuideSet.HEIGHT,
          Db.Vip.PV.AnotGuideSet.FOOTPRINT,
          Db.Vip.PV.AnotGuideSet.STOREY
        ].some(k => projectMeta.guides.includes(x[k] as string))) {
          arr[0].push(x)
        } else {
          arr[1].push(x)
        }
        return arr
      }, [[], []] as Db.Vip.PV.IAnotGuideSet[][])

      this.filteredAnotGuideSets = { withMeasures, withoutMeasures }
      if (this.datasetSelectionForm.controls.anot_guide_set_id) {
        if (this.filteredAnotGuideSets.withMeasures && this.filteredAnotGuideSets.withMeasures.length === 1) {
          this.datasetSelectionForm.controls.anot_guide_set_id.setValue(
            this.filteredAnotGuideSets.withMeasures[0].anot_guide_set_id
          )
        }
      }
    }

    const annot = this.datasetSelectionForm.controls.anot_guide_set_id.value
    const measures = this.filteredAnotGuideSets && this.filteredAnotGuideSets.withMeasures || []
    const notMeasured = this.filteredAnotGuideSets && this.filteredAnotGuideSets.withoutMeasures || []
    if (!measures.find(x => x.anot_guide_set_id === annot) && !notMeasured.find(x => x.anot_guide_set_id === annot)) {
      this.datasetSelectionForm.controls.anot_guide_set_id.setValue(undefined)
    }

    const ml = this.datasetSelectionForm.controls.ml_dataset_id.value
    const resetMl = this.filteredMlDatasets && !(this.filteredMlDatasets || []).find(x => x.ml_dataset_id === ml)
    if (resetMl) this.datasetSelectionForm.controls.ml_dataset_id.setValue(undefined)

    this.checkDatasetSelection()
  }

  checkDatasetSelection() {
    const mode = this.form._controls.mode && this.form._controls.mode.value
    if (mode && mode !== Db.Vip.LayerMode.READ_ONLY) {
      this.requireAnnotationGuide = [Db.Vip.LayerMode.ANOT, Db.Vip.LayerMode.ANOT_QA].includes(mode)
      this.requireMlDataset = [Db.Vip.LayerMode.ML_HE, Db.Vip.LayerMode.ML_QA].includes(mode)
    } else {
      const query: NonNullable<Db.Vip.Geo.ILayer['parameters']>['preset_dataset_sequence'] = this.form._controls.datasetsQuery.value
      const anotDataset = (dataset: Db.Vip.PresetDataset) => [Db.Vip.PresetDataset.PV_ANOT, Db.Vip.PresetDataset.PV_ANOT_QA].includes(dataset)
      this.requireAnnotationGuide = !!query && query.some(x =>
        anotDataset(x.dataset) || (x.sequence && x.sequence.some(y =>
          anotDataset(y.dataset)
        ))
      )

      const mlDataset = (dataset: Db.Vip.PresetDataset) => [
        Db.Vip.PresetDataset.PV_ML,
        Db.Vip.PresetDataset.PV_ML_HE,
        Db.Vip.PresetDataset.PV_ML_QA
      ].includes(dataset)
      this.requireMlDataset = !!query && query.some(x =>
        mlDataset(x.dataset) || (x.sequence && x.sequence.some(y =>
          mlDataset(y.dataset)
        ))
      )
    }

    this.datasetSelectionForm.updateValueAndValidity()
  }

  toggleIntervalField(enabled: boolean) {
    if (!this.scraperForm) return
    if (!enabled) {
      if (this.scraperForm._controls.intervalValue) this.scraperForm.removeControl('intervalValue')
      if (this.scraperForm._controls.interval){} this.scraperForm.removeControl('interval')
      this.formChange.next(this.form)
    } else {
      if (!this.scraperForm._controls.intervalValue) {
        switch (this.presetName) {
          case Db.Vip.LayerPreset.GOOGLE_NEWS:
            this.scraperForm.addControl('intervalValue', new TypedFormControl<number>(1, Validators.required))
            break
          case Db.Vip.LayerPreset.TWITTER:
            this.scraperForm.addControl('intervalValue', new TypedFormControl<number>(2, [Validators.required,Validators.min(2)]))
            break
          default:
            break
        }
      }
      if (!this.scraperForm._controls.interval) {
        this.scraperForm.addControl('interval', new TypedFormControl<Db.Helper.Geo.TimeInterval>('hour', Validators.required))
      }
      if (this.scraperForm._controls.interval && this.scraperForm._controls.intervalValue) {
        this.scraperForm._controls.interval.valueChanges.subscribe((value) => {
          if (this.presetName !== Db.Vip.LayerPreset.TWITTER) return
            if (value !== "hour" ) {
              if (!this.scraperForm) return
              this.scraperForm._controls.intervalValue?.setValidators(Validators.required)
              this.scraperForm._controls.intervalValue?.setValue(1)
            } else {
              if (!this.scraperForm ) return
              this.scraperForm._controls.intervalValue?.setValidators(Validators.compose([Validators.required,Validators.min(2)]))
              this.scraperForm._controls.intervalValue?.setValue(2)
            }
        })
      }
    }
  }
}

applyMixins(PresetConfigComponent, [DialogCleanup])
