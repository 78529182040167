<h2 mat-dialog-title>
  <mat-icon svgIcon="vector-polygon"></mat-icon>

  <ng-container *ngIf="data.initQuery">Initialisation</ng-container> Postcode Area
</h2>

<div mat-dialog-content>
  <app-note color="warn" *ngIf="partialPostcodesMatched">
    Not all uploaded postcodes found.
  </app-note>
  <app-note color="error" *ngIf="noPostcodesMatched">
    No postcodes matched.
  </app-note>
  <p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>
  <form [formGroup]="form" class="st-single-row-form">
    <mat-form-field>
      <input matInput placeholder="Query name" formControlName="name" (focus)="onTitleFocus()" (blur)="onTitleBlur()"/>
    </mat-form-field>

  <div class="upload-toolbar">
    <div class="file-input-wrapper">
      <app-file-drag-drop
      type="button"
      [accept]="acceptFormat"
      [captureWindow]="true"
      buttonText="Browse or Drag File"
      [multiple]="false"
      (onFilesDropped)="fileChanged($event, 'fileName')"
      >
      </app-file-drag-drop>
      <span class="selected-file">
        {{file ? "Selected: " + file.name : "No file selected"}}
      </span>
    </div>
  </div>
  <div *ngIf="numEntriesLoading" class="data-loading">
    <span>Please wait for the estimated number of records in the area </span>
    <mat-spinner diameter="20"></mat-spinner>
  </div>
  <div *ngIf="numEntriesLoaded">{{entriesMsg}}</div>
  <div *ngIf="numEntriesLoaded">Total estimated number of records in the layer: {{+numEntries.count + existingEntries}}</div>
  <div *ngIf="layerAlert && !numEntriesLoading">When loading large layers click 'wait' if the browser asks whether to cancel the operation</div>
  <div *ngIf="dataCapWarning"><span class="remove-layer">Estimated number of records exceed system rendering limit</span></div>
  <div class="wkt-field" *ngIf="form.valid">
    <mat-form-field>
      <textarea matInput formControlName="wktGeometry" cols="40" rows="3" placeholder="WGS84 'Well Known Text (WKT)' point geometry"></textarea>
      <mat-error *ngIf="form.controls.wktGeometry.hasError('wktValid')">
        {{form.controls.wktGeometry['errors'] && form.controls.wktGeometry['errors']['wktValid']['message']}}
      </mat-error>
    </mat-form-field>

    <app-wkt-preview #preview [wkt]="form.controls.viewGeometry.value"></app-wkt-preview>
  </div>
  </form>
</div>

<div class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>CANCEL</button>
  </div>
  <div class="right">
    <button color="primary" mat-raised-button (click)="save()" [disabled]="!form.valid || saving">SAVE</button>
  </div>
</div>
