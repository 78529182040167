import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot } from '@angular/router'
import { MeasureHistoryService, WorkspaceService, WorkspaceMapService } from '@services/workspace'
import { handleError } from '@core/models/app-error'
import { Subscription } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WorkspaceGuard implements Resolve<boolean> {
  protected _subscriptions = new Subscription()
  constructor (
        private _workspaceService: WorkspaceService,
        private _measureHistoryService: MeasureHistoryService,
        private _mapService: WorkspaceMapService
    ) {}

  async resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const workspaceId = route.paramMap.get('id')
      if (workspaceId) {
        await this._workspaceService.loadWorkspace(+workspaceId)
        const extent = this._workspaceService.viewExtent
        if (extent) {
          this._subscriptions.add(
            this._mapService.mapLoaded.subscribe(() => {
              this._mapService.map.getView().fit(extent)
              this._subscriptions.unsubscribe()
          }))
        }

        this._measureHistoryService.loadMeasurements()
        return true
      } else {
        return false
      }
    } catch (error: any) {
      handleError(error)
      return false
    }
  }
}
