import { Directive, Output, EventEmitter, OnDestroy } from '@angular/core'

@Directive({ selector: '[destroyed]' })
export class DestroyedDirective implements OnDestroy {
  @Output('destroyed')
    public after: EventEmitter<DestroyedDirective> = new EventEmitter()

  public ngOnDestroy (): void {
    this.after.next(this)
  }
}
