import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { SettingsService, PromptService, AlertService } from '@services/core'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import AppError, { handleError } from '@core/models/app-error'
import { MatCheckbox } from '@angular/material/checkbox'

interface DialogData<Row> {
  displayColumns: string[]
  rows: Row[]
  deletePrompt?: ((row: Row) => Promise<string | undefined>) | string
  delete?: (row: Row) => Promise<void>
  disablePrompt?: ((row: Row) => Promise<string | undefined>) | string
  toggleArchived?: (row: Row) => Promise<Row>
  update?: (row: Row) => Promise<void>
}

@Component({
  selector: 'app-dataset-table-manager-dialog',
  templateUrl: './dataset-table-manager-dialog.component.html',
  styleUrls: ['./dataset-table-manager-dialog.component.scss']
})
export class DatasetTableManagerDialogComponent<Row> implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs (): any { return }

  displayColumns: string[]
  formattedColumns: string[]
  dataSource: MatTableDataSource<Row>
  stickyHeader = true

  static setOptions<Row> (data: DialogData<Row>): MatDialogConfig {
    const options: MatDialogConfig = {
      data
    }

    if (!data.rows.length) throw new AppError(`Dataset has no entries.`)

    return options
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData<Row>,
    private _settingsService: SettingsService,
    private _promptService: PromptService,
    private _alertService: AlertService
  ) {
    this.stickyHeader = !this._settingsService.isIEorEdge(false)
    this.displayColumns = [...data.displayColumns]

    const disabledColumn = Object.keys(data.rows[0] || {}).indexOf('disabled')
    if (disabledColumn >= 0) {
      this.displayColumns.push('disabled')
    }

    this.formattedColumns = data.displayColumns.map(x =>
      x.split('_')
      .map(w => `${w[0].toUpperCase()}${w.slice(1)}`)
      .join(' ')
    )

    if (data.delete || data.update) this.displayColumns.push('actions')

    this.dataSource = new MatTableDataSource(data.rows)
  }

  async delete (row: Row) {
    if (!this.data.delete) return

    try {
      const disabledColumn = Object.keys(this.data.rows[0] || {}).indexOf('disabled')
      if (disabledColumn && !row['disabled']) {
        throw new AppError(`Deletion of enabled entry is forbidden. Please disable the entry before deleting it permanently.`)
      }

      const prompt = this.data.deletePrompt && (
        typeof this.data.deletePrompt === 'string' ? this.data.deletePrompt : await this.data.deletePrompt(row)
      )
      if (prompt) {
        const proceed = await new Promise(res => this._trackDialog(
          this._promptService.prompt(prompt, {
            yes: () => res(true),
            no: () => res(false)
          })
        ))
        if (!proceed) return
      }

      await this.data.delete(row)
      this.data.rows.splice(this.data.rows.indexOf(row), 1)
      this.dataSource.data = this.data.rows
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  async update (row: Row) {
    if (!this.data.update) return

    try {
      await this.data.update(row)
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }

  async toggleArchived (row: Row, checkbox: MatCheckbox) {
    if (!this.data.toggleArchived) return

    try {
      if (Object.keys(row).includes('disabled') && !row['disabled']) {
        // It will get disabled if executed, so prompt user if prompt is defined
        const prompt = this.data.disablePrompt && (
          typeof this.data.disablePrompt === 'string' ? this.data.disablePrompt : await this.data.disablePrompt(row)
        )
        if (prompt) {
          const proceed = await new Promise(res => this._trackDialog(
            this._promptService.prompt(prompt, {
              yes: () => res(true),
              no: () => res(false)
            })
          ))
          if (!proceed) {
            checkbox.checked = !!row['disabled']
            return
          }
        }
      }

      const updated = await this.data.toggleArchived(row)
      Object.apply(row, [updated])
    } catch (error: any) {
      handleError(error)
      this._alertService.log(error.message)
    }
  }
}

applyMixins(DatasetTableManagerDialogComponent, [DialogCleanup])
