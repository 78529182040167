import { Component, Optional, Inject } from '@angular/core'
import { FormTemplate } from '@core/models/form-template'
import { IRPVAvailableGuides, IRPVAvailableGuide } from '@vip-shared/interfaces'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { Db } from '@vip-shared/models/db-definitions'
import { NewPvGuideDialogComponent } from '@pages/workspace/components/new-pv-guide-dialog/new-pv-guide-dialog.component'
import { VipApiService, PromptService } from '@services/core'
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms'
import AppError, { handleError } from '@core/models/app-error'

@Component({
  selector: 'app-guide-set-dialog',
  templateUrl: './guide-set-dialog.component.html',
  styleUrls: ['./guide-set-dialog.component.scss']
})
export class GuideSetDialogComponent extends FormTemplate<UntypedFormGroup> implements DialogCleanup {
  // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return {} as MatDialogRef<T> }
  _destroyDialogs (): any { return }

  guides?: IRPVAvailableGuides
  guidesAvailable = false

  private get _pvApi () {
    return this._api.orm.Products().PropertyView()
  }

  constructor (
    protected _dialogRef: MatDialogRef<GuideSetDialogComponent>,
    private _dialog: MatDialog,
    private _api: VipApiService,
    private _promptService: PromptService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: Db.Vip.PV.IAnotGuideSet
  ) {
    super(new UntypedFormGroup({
      name: new UntypedFormControl(data && data.name, Validators.required)
    }), _dialogRef)
    this.Load()
  }

  private async Load () {
    const guides = await this._pvApi.Guides().getPerMeasure().run()
    this.guides = guides
    this.guidesAvailable = Object.keys(guides).some(x => guides[x].length)

    const nonEditable = !!(this.data && this.data.anot_guide_set_id)
    const existingSet = this.data || {} as Db.Vip.PV.IAnotGuideSet

    const newControl = (arr: IRPVAvailableGuide[], value?: string) => {
      const measuredArr = arr.filter(x => x.hasMeasures)
      return nonEditable ? new UntypedFormControl({
        value, disabled: true
      }) : new UntypedFormControl(
        measuredArr.length === 1 ? measuredArr[0]['guide_id'] : undefined,
        measuredArr.length ? Validators.required : undefined
      )
    }

    this.form.addControl('constr_class', newControl(this.guides.constr_class, existingSet.constr_class))
    this.form.addControl('roof_material', newControl(this.guides.roof_material, existingSet.roof_material))
    this.form.addControl('roof_type', newControl(this.guides.roof_type, existingSet.roof_type))
    this.form.addControl('storey', newControl(this.guides.storey, existingSet.storey))
    this.form.addControl('footprint', newControl(this.guides.footprint, existingSet.footprint))
    this.form.addControl('building_height', newControl(this.guides.building_height, existingSet.height))
  }

  async delete () {
    if (this.saving) return

    const proceed = await new Promise(res => {
      this._promptService.prompt(`Are you sure you want to permanently\
      delete annotation guide configuration '${this.data && this.data.name}'? This will also delete all geometry changes related to this dataset.`, {
        yes: () => res(true),
        no: () => res(false)
      })
    })

    if (!proceed) return

    this.saving = true
    this.errorMessage = undefined

    try {
      if (this.data && this.data.anot_guide_set_id) {
        await this._pvApi.Guides().Sets().Set(this.data.anot_guide_set_id)
        .delete().run()
      }

      this._dialogRef.close(true)
    } catch (error: any) {
      this.errorMessage = error.message
      handleError(error)
    }
    this.saving = false
  }
  async saveSet () {
    if (this.saving) return
    this.saving = true
    this.errorMessage = undefined

    try {
      if (!this.form.valid) throw new AppError(`Form invalid.`)

      if (this.data && this.data.anot_guide_set_id) {
        await this._pvApi.Guides().Sets().Set(this.data.anot_guide_set_id).update({
          name: this.form.controls.name.value
        }).run()
        this.data.name = this.form.controls.name.value
      } else {
        const set = await this._pvApi.Guides().Sets().add({
          name: this.form.controls.name.value,
          constr_class: this.form.controls.constr_class.value,
          roof_material: this.form.controls.roof_material.value,
          roof_type: this.form.controls.roof_type.value,
          storey: this.form.controls.storey.value,
          footprint: this.form.controls.footprint.value,
          height: this.form.controls.building_height.value
        }).run()

        this.data = set
      }

      this._dialogRef.close(this.data)
    } catch (error: any) {
      this.errorMessage = error.message
      handleError(error)
    }
    this.saving = false
  }

  placeholder (title: string, items: number) {
    return `${title}${items ? '' : ' (None Available)'}`
  }

  createNewGuide (key: string) {
    this._trackDialog(
      this._dialog.open(NewPvGuideDialogComponent)
    )
    .afterClosed().subscribe((guide?: Db.Vip.PV.IGuide) => {
      if (guide) {
        if (!this.guides) this.guides = {} as IRPVAvailableGuides

        if (!this.guides[key]) this.guides[key] = [guide]
        else this.guides[key].push(guide)

        this.form.controls[key].setValue(guide.guide_id)
      }
    })
  }
}

applyMixins(GuideSetDialogComponent, [DialogCleanup])
