import { Injectable } from '@angular/core'
import { fromEvent } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  lastActiveElement?: HTMLElement
  lastActivePath?: HTMLElement[]

  // Because some shortcuts are shared with attribute table
  // we need this so that they would get triggered when shortcut is triggered when map is active
  get mapFocused () {
    // !attributeTableFocused because app-attribute-table is inside app-map
    return !!this.lastActivePath && !this.attributeTableFocused && !!this.lastActivePath.find(el =>
      el.localName === 'app-map'
    )
  }

  // Because some shortcuts are shared with map
  // we need this so that they would get triggered when shortcut is triggered when table is active
  get attributeTableFocused () {
    return !!this.lastActivePath && !!this.lastActivePath.find(el =>
      el.localName === 'app-attribute-table'
    )
  }

  init () {
    fromEvent<MouseEvent>(document, 'click').subscribe((e) => this.ProcessMouseEvent(e))
    fromEvent<MouseEvent>(document, 'contextmenu').subscribe((e) => this.ProcessMouseEvent(e))
  }

  private ProcessMouseEvent (e: MouseEvent) {
    // Only process user mouse triggered events
    if (!e.isTrusted) return
    let path: any[] = []
    const fillPath = (el: Element) => {
      path = [el]
      while (el.parentElement) {
        path.push(el.parentElement)
        el = el.parentElement
      }
    }

    const elements = document.elementsFromPoint(e.clientX, e.clientY)
    const validEl = elements.find(e =>
      ['app-map', 'app-attribute-table'].includes(e.localName)
    )

    if (validEl) fillPath(validEl)
    else fillPath(elements[0])

    this.lastActivePath = path
    this.lastActiveElement = e.target as HTMLElement || undefined
  }
}
