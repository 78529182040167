import { Component } from '@angular/core'
import { Validators } from '@angular/forms'
import { AuthService } from '@services/core'
import { Title } from '@angular/platform-browser'
import AppError, { handleError } from '@core/models/app-error'
import { DomainService } from '@services/core/domain/domain.service'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { FormTemplate } from '@core/models/form-template'
import { ThemeService } from '@services/core/theme/theme.service'

type Form = TypedFormGroup<{
  email: TypedFormControl<string>
}>

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class ForgottenPasswordComponent extends FormTemplate<Form> {
  get showCopyright () {
    return this._domainService.showGSICopyright
  }

  constructor (
        private _authService: AuthService,
        private _domainService: DomainService,
        themeService: ThemeService
    ) {
    super(new TypedFormGroup({
      email: new TypedFormControl('', [
        Validators.email,
        Validators.required
      ])
    }))

    themeService.setTitle('Forgotten password')
  }

  async submit () {
    if (this.saving) return

    this.saving = true
    this.errorMessage = undefined
    try {
      if (!this.form.valid) {
        throw new AppError('Form invalid.')
      }

      await this._authService.getPasswordReset(this.form.value.email)
      this.submitted = true
    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    } finally {
      this.saving = false
    }
  }

}
