import { Component, OnDestroy, OnInit } from '@angular/core'
import { FloodRecordsTableComponent } from '@pages/workspace/map/map-overlays/map-controls/flood-alerts-list/flood-records-table/flood-records-table.component'



@Component({
  selector: 'app-flood-records-widget',
  templateUrl: './flood-records-widget.component.html',
  styleUrls: ['./flood-records-widget.component.scss']
})
export class FloodRecordsWidgetComponent {

  constructor () {

  }
}
