import { Component, Input, EventEmitter, Output, HostListener, AfterViewInit } from '@angular/core'
import { trigger, state, style, transition, animate } from '@angular/animations'

@Component({
  selector: 'app-input-toggle-number',
  templateUrl: './input-toggle-number.component.html',
  styleUrls: ['./input-toggle-number.component.scss'],
  animations: [
    trigger('sliderToggle', [
      state('on', style({
        left: '0',
        background: '#009688'
      })),
      state('off', style({
        left: '0%',
        background: '#c3c3c3'
      })),
      transition('on <=> off', [
        animate('0.25s')
      ])
    ])
  ]
})
export class InputToggleNumberComponent implements AfterViewInit {
  private _enabled = false
  private _value?: number
  private _rendered = false
  @Input() min?: number
  @Input() max?: number
  @Input() step = 1
  @Output() enabledChange = new EventEmitter()
  @Output() valueChange = new EventEmitter()

  @Input()
  get enabled () {
    return this._enabled
  }
  set enabled (val: boolean) {
    this._enabled = val
    if (this._rendered) this.enabledChange.emit(this._enabled)
  }

  @Input()
  get value (): number | undefined {
    return this._value
  }
  set value (val: number | undefined) {
    this._value = val
    if (this._rendered) this.valueChange.emit(this._value)
  }

  @HostListener('click', ['$event'])
  onClick () {
    this.enabled = !this.enabled
  }

  ngAfterViewInit () {
    this._rendered = true
  }

  changeValue (val: number) {
    this.value = val
  }
}
