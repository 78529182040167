import { handleError } from '@core/models/app-error'
import { ConvertUtil } from '@core/utils/convert/convert.util'

export default class CanvasUtil {

  static getAverageColor (canvas: HTMLCanvasElement, options: SelectionOptions = {}) {
    const rgb = { R: 0, G: 0, B: 0 }
    const ctx = canvas.getContext && canvas.getContext('2d')
    if (!ctx) {
      return rgb
    }
    let selection: Required<SelectionOptions> = {
      xmin: 0,
      xmax: canvas.width,
      ymin: 0,
      ymax: canvas.height
    }

    const mapWidth = (val: number) => ConvertUtil.mapRange(val, 0, canvas.clientWidth, 0, canvas.width)
    const mapHeight = (val: number) => ConvertUtil.mapRange(val, 0, canvas.clientHeight, 0, canvas.height)
    if (options.xmin) options.xmin = mapWidth(options.xmin)
    if (options.xmax) options.xmax = mapWidth(options.xmax)
    if (options.ymin) options.ymin = mapHeight(options.ymin)
    if (options.ymax) options.ymax = mapHeight(options.ymax)
    Object.assign(selection, options)

    let image: ImageData
    try {
      image = ctx.getImageData(
        selection.xmin, selection.ymin,
        selection.xmax - selection.xmin, selection.ymax - selection.ymin
      )
    } catch (error: any) {
      handleError(error)
      return rgb
    }

    const length = image.data.length
    // Only sample every x pixels
    const blockSize = 5
    let i = -4 + blockSize * 4
    let count = 0
    while (i < length) {
      ++count
      rgb.R += image.data[i]
      rgb.G += image.data[i + 1]
      rgb.B += image.data[i + 2]
      i += blockSize * 4
    }

    rgb.R = ~~(rgb.R / count)
    rgb.G = ~~(rgb.G / count)
    rgb.B = ~~(rgb.B / count)

    return rgb
  }

}

interface SelectionOptions {
  xmin?: number,
  ymin?: number,
  xmax?: number,
  ymax?: number
}
