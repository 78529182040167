import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes'
import { Component, forwardRef, Input, HostBinding } from '@angular/core'
import { MatChipInputEvent } from '@angular/material/chips'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

@Component({
  selector: 'app-chips-input',
  templateUrl: 'chips-input.component.html',
  styleUrls: ['chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true
    }
  ]
})

export class ChipsInputComponent implements ControlValueAccessor {
  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  private _type: 'phrase' | 'word' = 'phrase'
  @Input()
  get type () {
    return this._type
  }
  set type (val: 'phrase' | 'word') {
    this._type = val
    if (val === 'word' && !this.separatorKeysCodes.includes(SPACE)) {
      this.separatorKeysCodes.push(SPACE)
    } else if (val === 'phrase' && this.separatorKeysCodes.includes(SPACE)) {
      this.separatorKeysCodes.splice(this.separatorKeysCodes.indexOf(SPACE), 1)
    }
  }
  @Input() placeholder = 'Tags'
  @Input() disabled = false
  @HostBinding('style.opacity')
    get opacity () {
      return this.disabled ? 0.25 : 1
    }
  separatorKeysCodes: number[] = [ENTER, COMMA]
  tags?: string[]
  // eslint-disable-next-line
  onChange = (tags: string[] | undefined) => {}
  // eslint-disable-next-line
  onTouched = () => {}

  get value (): string[] | undefined {
    return this.tags
  }

  writeValue (tag: string | string[]) {
    if (Array.isArray(tag)) {
      if (!this.tags) this.tags = []
      this.tags.push(...tag)
    } else if (tag && tag.trim() && (!this.tags || !this.tags.includes(tag))) {
      if (!this.tags) this.tags = []
      this.tags.push(tag)
    }
    this.onChange(this.value)
  }

  registerOnChange (fn: (tags: string[] | undefined) => void) {
    this.onChange = fn
  }

  registerOnTouched (fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState (isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  add (event: MatChipInputEvent) {
    const input = event.input
    const value = event.value

    if (!this.disabled) {
      this.writeValue(value.trim())
    }

    if (input) {
      input.value = ''
    }
  }

  remove (tag: string) {
    if (this.tags) {
      const index = this.tags.indexOf(tag)

      if (index >= 0) {
        this.tags.splice(index, 1)
      }
      if (!this.tags.length) this.tags = undefined

      this.onChange(this.value)
    }
  }
}
