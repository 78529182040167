import { Component, OnDestroy } from '@angular/core'
import { Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { environment } from 'environments/environment'
import { AlertService, AuthService, SettingsService } from '@services/core'
import AppError, { handleError } from '@core/models/app-error'
import { DomainService } from '@services/core/domain/domain.service'
import { NgRouterService } from '@services/core/ng-router/ng-router.service'
import { TypedFormGroup, TypedFormControl } from '@core/models/typed-form-control'
import { FormTemplate } from '@core/models/form-template'
import { ThemeService } from '@services/core/theme/theme.service'
import { MFAStatus } from 'vip-shared/dist/interfaces'

type Form = TypedFormGroup<{
  email: TypedFormControl<string>
  password: TypedFormControl<string>
}>

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class LoginComponent extends FormTemplate<Form> {
  private _previousUrl?: string
  isIEOrEdge?: boolean

  get staging () {
    return this._settingsService.staging
  }

  get showCopyright () {
    return this._domainService.showGSICopyright
  }

  constructor (
    private _router: Router,
    private _authService: AuthService,
    private _settingsService: SettingsService,
    private _domainService: DomainService,
    private _alertService: AlertService,
    ngRouterService: NgRouterService,
    themeService: ThemeService
  ) {
    super(
      new TypedFormGroup({
        email: new TypedFormControl<string>(undefined, [
          Validators.required,
          Validators.email
        ]),
        password: new TypedFormControl<string>(undefined, Validators.required)
      })
    )

    themeService.setTitle('Login')

    if (!environment.production) {
      this.form._controls.email.setValue('johndoe.gsi@geospatial-insight.com')
      this.form._controls.password.setValue('myp8^%Tassword')
    }

    this._previousUrl = (
      ngRouterService.previousRoute &&
      (
        ngRouterService.previousRoute.startsWith('/workspace') ||
        ngRouterService.previousRoute.startsWith('/explorer') ||
        ngRouterService.previousRoute.startsWith('/user')
      )
    )
    ? ngRouterService.previousRoute : undefined

    this.isIEOrEdge = this._settingsService.isIEorEdge(false)
  }

  async submit () {
    this.errorMessage = undefined
    try {
      if (!this.form.valid) {
        throw new AppError('Form invalid.')
      }

      await this._authService.login(
        this.form._controls.email.value as string,
        this.form._controls.password.value as string
      )

      this.form.reset()
      this._alertService.closeAlertsOnLogin()

      switch (this._authService.MFAStatus) {
        case MFAStatus.PENDING:
          this._router.navigate(['/setup-mfa']);
          break;
        case MFAStatus.AUTHENTICATED:
        case MFAStatus.OPT_OUT:
          this._router.navigate([this._previousUrl || '/explorer']);
          break;
        default:
          this._router.navigate(['/enter-otp']);
          break;
      }

    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    }
  }
}
