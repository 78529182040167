<form [formGroup]="form">
  <mat-form-field>
    <mat-select disableOptionCentering formControlName="presetName" placeholder="Existing Dataset" (selectionChange)="presetChange($event.value)">
      <mat-option *ngFor="let preset of layerPresets" [value]="preset.layer_preset_tag" class="st-capitalize" [matTooltip]="preset.description">
        {{preset.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<app-loader-backdrop *ngIf="fetchingData" loadingText="Fetching settings..."></app-loader-backdrop>
