<form [formGroup]="form">
  <div class="st-mat-field-pair">
    <app-date-time-input shouldBe="inPast" expiryNote="Date is after current date"
      [(value)]="claimRange.valid_from" (valueChange)="updateClaimDate(claimRange)"
      [required]="!!claimRange.valid_to"
      [showTrigger]="false"
      [disabled]="isWholeViewMode"
      [matTooltip]="isWholeViewMode ? 'Valid From cannot be updated while All Layers is toggled on' : ''"
      title="Valid From"
      >
    </app-date-time-input>

    <app-date-time-input
      [(value)]="claimRange.valid_to" (valueChange)="updateClaimDate(claimRange)"
      [required]="!!claimRange.valid_from"
      [showTrigger]="false"
      [disabled]="isWholeViewMode"
      [matTooltip]="isWholeViewMode ? 'Valid To cannot be updated while All Layers is toggled on' : ''"
      title="Valid To">
    </app-date-time-input>

    <app-date-time-input
      [(value)]="claimRange.focal_point" (valueChange)="updateClaimDate(claimRange)"
      [required]="!!claimRange.valid_from && !!claimRange.valid_to"
      [showTrigger]="false"
      [disabled]="isWholeViewMode"
      [matTooltip]="isWholeViewMode ? 'Focal Point cannot be updated while All Layers is toggled on' : ''"
      [min]="claimRange.valid_from"
      [max]="claimRange.valid_to"
      title="Focal Point">
    </app-date-time-input>
  </div>
</form>

