import { IEntries } from "@vip-shared/interfaces";

export class DomUtil {
  static dropdownFocused () {
    return document.activeElement && document.activeElement.localName === 'mat-select'
  }

  static entriesDialogMessage (entries: IEntries, estimate: boolean = false) {
    const isEstimate = estimate ? 'Estimated number' : 'Number'
    if (entries.sampled_count) {
      return `${isEstimate} of sampled records in area: ${entries.count}. Total number of records queried ${entries.sampled_count} `
    } else {
      return `${isEstimate} of  records in area: ${entries.count}`
    }
  }
}
