import { Injectable } from '@angular/core'
import VectorLayer from 'ol/layer/Vector'
import * as olStyle from 'ol/style'
import * as olColor from 'ol/color'
import { Db } from '@vip-shared/models/db-definitions'
import { BehaviorSubject } from 'rxjs'
import { IRouteResult } from '@core/types/'
import { RouteMeansOfTransportType } from '@core/enum/air'
import { ColourUtil, ShapesUtil } from '@core/utils/index'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AlertService } from '@services/core/alert/alert.service'
import { AirRouteCalculateService } from '../route-calculate/air.route.calculate.service'
import { IPNewRoute, IPEditRoute } from '@vip-shared/interfaces/api/api-body-types'
import AppError from '@core/models/app-error'
import LayerStyleUhlUtil from '@core/models/layer/utils/layer-style-uhl.utils'
import { WorkspaceMapService } from '@services/workspace/workspace-map/workspace-map.service'
import { WorkspaceService } from '@services/workspace/workspace.service'
@Injectable({
  providedIn: 'root'
})
export class AirRoutesService {
  constructor (
    private _api: VipApiService,
    private _airRouteCalculateService: AirRouteCalculateService,
    private _workspaceMapService: WorkspaceMapService,
    private _alertService: AlertService,
    private _workspaceService: WorkspaceService
  ) {}

  private _colourPalette: any[] = ColourUtil.colourPalette.slice(4)
  private _routes = new BehaviorSubject<IRouteResult[]>([])
  private _drawnRoutes: VectorLayer<any>[] = []
  initService () {
    this.refreshRoutes()
  }

  cleanupService () {
    this._routes.next([])
    for (const layer of this._drawnRoutes) {
      this._workspaceMapService.removeMapLayer(layer)
    }
    this._drawnRoutes.splice(0)
  }

  get drawnRoutes () {
    return this._drawnRoutes
  }

  async getRoutes (): Promise<IRouteResult[]> {
    let routes: Db.Vip.Geo.IRoute[] = []
    routes = await this._api.orm.Routes().get(this._workspaceService.workspaceId as number).run() || []

    // MSF TODO: I know we're calling the map function twice but it needs to stay that way for now
    // I'll rework it when we have more time
    await this.DrawRoutes(routes.map(route => this.MapRoute(route)))
    return routes.map(route => this.MapRoute(route))
  }

  getSavedRoutes () {
    return this._routes.asObservable()
  }

  async refreshRoutes () {
    this._routes.next(await this.getRoutes())
  }

  async getRoute (routeId: number): Promise<IRouteResult> {
    const route: IRouteResult | undefined = await this._api.orm.Routes().Route(routeId).get().run() as any
    if (!route) throw new AppError('Route not found')

    return this.MapRoute(route)
  }

  async addRoute (route: IPNewRoute): Promise<number | void> {
    if ((route.route_attributes as any).value === -1) {
      this._alertService.log('Please wait until all data is loaded before saving')
      return
    }

    const id = await this._api.orm.Routes().add(route).run()
    this.refreshRoutes()
    return +id
  }

  private async DrawRoutes (routes: IRouteResult[]) {
    return new Promise(resolve => {
      routes.forEach(async (route, index) => {
        const colour: olColor.Color = Object.values(this._colourPalette[index % this._colourPalette.length].rgb)

        if (!this.AlreadyDrawn(route)) {
          const layers = await this._airRouteCalculateService.getRoute(
            route.icon as RouteMeansOfTransportType,
            route.waypoints.map(wp => wp.coords),
            undefined,
            colour,
            route.fullBreakdown
          )

          layers.forEach(layer => {
            layer.setProperties({
              routeId: route.id,
              isSavedRoute: true,
              meansOfTransport: route.icon
            })
            layer.setVisible(route.visible)

            this._workspaceMapService.addMapLayer(layer)

            this._drawnRoutes.push(layer)

            const wps = route.waypoints
            const wpsCount = wps.length

            wps.forEach((wp, i) => {
              const toAdd = ShapesUtil.newPoint({
                lng: wp.coords.lon,
                lat: wp.coords.lat
              })

              let iconStyle: olStyle.Style | undefined

              const displayNumber = i + 1
              if (i === 0) {
                iconStyle = LayerStyleUhlUtil.getIconStyle('start', displayNumber, colour)
              } else if (i < wpsCount - 1) {
                iconStyle = LayerStyleUhlUtil.getIconStyle('waypoint', displayNumber, colour)
              } else {
                iconStyle = LayerStyleUhlUtil.getIconStyle('end', displayNumber, colour)
              }

              if (iconStyle) {
                toAdd.setStyle(iconStyle)
              }

              layer.getSource().addFeature(toAdd)
            })
          })
        }
      })

      window.setTimeout(() => {
        resolve(null)
      }, 0)
    })
  }

  toggleVisibility (id: number, transportType: string) {
    const matches = this._drawnRoutes.filter(route => {
      const props = route.getProperties()
      return props.routeId === id && props.meansOfTransport === transportType
    })

    matches.forEach(match => match.setVisible(!match.getVisible()))
  }

  private AlreadyDrawn (route: IRouteResult): boolean {
    const matches = this._drawnRoutes.filter(dr => {
      const props = dr.getProperties()
      return props.routeId === route.id && props.meansOfTransport === route.icon
    })

    return matches.length > 0
  }

  async updateRoute (routeId: number, route: IPEditRoute, refresh = true): Promise<boolean> {
    await this._api.orm.Routes().Route(routeId).save(route).run()
    if (refresh) this.refreshRoutes()

    return true
  }

  async deleteRoute (routeId: number, olVector?: VectorLayer<any>): Promise<boolean> {
    if (olVector) {
      this._workspaceMapService.removeMapLayer(olVector)
    }

    await this._api.orm.Routes().Route(routeId).delete().run()
    this.refreshRoutes()

    return true
  }

  private MapRoute (route: any) {
    let returnObject: any
    if (route.route_attributes) {
      returnObject = {
        id: route.route_id,
        title: 'Saved',
        time: new Date(route.date_time),
        duration: route.route_attributes.duration,
        value: route.route_attributes.value,
        distance: route.route_attributes.distance,
        icon: route.transport_name,
        waypoints: route.waypoints,
        balance: route.balance,
        visible: route.visible,
        fullBreakdown: route.route_attributes.full_route_breakdown,
        exposureDetails: route.route_attributes.exposure
      }
    } else {
      returnObject = route
    }

    const match = this._drawnRoutes.find(dr => {
      return dr.getProperties().routeId === route.route_id
    })

    if (match) {
      return {
        ...returnObject,
        olVector: match
      }
    } else {
      return returnObject
    }
  }
}
