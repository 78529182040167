import AppError from '@core/models/app-error'
import OlUtil from '@core/utils/ol/ol.util'
import Feature from 'ol/Feature'
export class LayerUtil {
  static getMapName (url: string): string {

    const split = url.split('/')
    let name: string = url

    if (split.length >= 7) {
      name = '.' + split[8]
    }

    name = name.replace(/_/g, ' ')

    return name
  }

  static getFeaturesSize (feature: Feature): number {
    let size = 0

    const features = OlUtil.deClusterFeatures([feature])

    if (features.length > 0) {
      // TODO: Check is 'x.get ?' is needed, or does 'get' need to check for property?
      size = features.filter((x: Feature) => ((x as any).get ? !OlUtil.featureFilteredOut(x) : true))
      .length
    }

    return size
  }

  static getClusterRadius (size: number = 0, radius: number = 10): number {

    if (size > 9) {
      radius *= 1.2
    } else if (size > 19) {
      radius *= 1.3
    } else if (size > 29) {
      radius *= 1.4
    } else if (size > 39) {
      radius *= 1.5
    }

    return radius
  }

  static getClusterText (size: number, isMultiTypeCluster: boolean): string {
    let text = ''

    if (size && size > 1) {
      if (isMultiTypeCluster) {
        if (size) {
          if (size > 9) {
            text = '9+'
          } else {
            text = size.toString()
          }
        }
      } else {
        text = size.toString()
      }
    }

    return text
  }

  static getDistinctFeatures (features: Feature[], selectedColumn: string) {
    const distinctClasses = {}

    features.forEach(feature => {
      const value = (feature as any).values_[selectedColumn]
      const defined = value || value === 0
      if (defined && !(typeof value === 'number' || typeof value === 'string')) {
        throw new AppError(`Cannot compare the ${selectedColumn} as it's not a numerical or text value.`)
      }
      // return null or undefined also as they are valid keys
      distinctClasses[value] = {}
    })

    if (!Object.keys(distinctClasses).length) {
      throw new AppError(`No value present in the column '${selectedColumn}'`)
    }

    const sorted = Object.keys(distinctClasses)
    sorted.sort((a, b) => {
      return !isNaN(+a) ? +a - +b : a === b ? 0 : a > b ? 1 : -1
    })

    return {
      distinctClasses,
      sorted
    }
  }

  static extentValid (extent?: [any, any, any, any]) {
    return extent && !extent.some(i =>
        isNaN(+i) || [null, Infinity, -Infinity].includes(i)
    )
  }
}
