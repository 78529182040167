import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject, Observable, Subscription, merge } from 'rxjs'
import { IDronePosition, IVideoDetails } from '@core/types'
import { AppLayer } from '@core/models/layer'
import { SelectedDroneLayer } from './selected-drone-layer'
import { PromptService } from '@services/core/prompt/prompt.service'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { AlertService } from '@services/core/alert/alert.service'
import { ViewerService } from '@services/workspace/viewer/viewer.service'
import { LayerService } from '../../layer.service'
import { AppDroneLayer } from '@core/models/layer/app-drone-layer'
import { map, auditTime } from 'rxjs/operators'
import { WorkspaceMapService } from '@services/workspace/workspace-map/workspace-map.service'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { Db } from '@vip-shared/models/db-definitions'

@Injectable({
  providedIn: 'root'
})

export class DroneLayerService {
  private _selectedDroneLayer?: SelectedDroneLayer
  get hasSelectedDroneSource () {
    return !!this._selectedDroneLayer
  }

  private _lastRenderedSource?: Db.Vip.Geo.IDroneSource
  private _selectedLayerSubscriptions = new Subscription()
  private _isDroneVideoPinned = false
  private _videoFullscreen = false
  private _calibrationToggle = new Subject<boolean>()
  private _droneVideoChanged: Subject<void> = new Subject()
  private _videoSecondsObservable: BehaviorSubject<{
    seconds: number
    update: boolean
  }> = new BehaviorSubject({
    seconds: 0,
    update: false as boolean
  })

  get calibrationSaving (): boolean {
    return this._selectedDroneLayer ?
      this._selectedDroneLayer.calibrationSaving : false
  }

  get droneVideoChanged (): Observable<void> {
    return this._droneVideoChanged.asObservable()
  }

  get clickCalibrationActive (): boolean {
    return this._selectedDroneLayer ?
    this._selectedDroneLayer.clickCalibrationActive : false
  }
  get calibrationEnabled (): boolean {
    return this._selectedDroneLayer ?
    this._selectedDroneLayer.calibrationEnabled : false
  }

  get videoSeconds () {
    return this._videoSecondsObservable.asObservable()
  }

  get calibrationToggle () {
    return this._calibrationToggle.asObservable()
  }

  get videoDetails (): IVideoDetails | undefined {
    return this._selectedDroneLayer ?
    this._selectedDroneLayer.videoDetails : undefined
  }

  set videoPlaying (val: boolean) {
    if (this._selectedDroneLayer) {
      this._selectedDroneLayer.videoPlaying = val
    }
  }

  constructor (
    private _alertService: AlertService,
    private _workspaceMapService: WorkspaceMapService,
    private _api: VipApiService,
    private _viewerService: ViewerService,
    private _layerService: LayerService,
    private _promptService: PromptService,
    private _workspaceService: WorkspaceService
  ) {
    _workspaceService.onExit.subscribe(this.cleanup.bind(this))

    this._viewerService.panelsObservable.subscribe(panels => {
      if (panels && panels['dwvideo']) {
        this._isDroneVideoPinned = panels.dwvideo.pinned
        this._videoFullscreen = !!panels.dwvideo.fullScreen
      }
    })

    merge(
      this._workspaceMapService.mapClicked.pipe(map(x =>
        x.layer ? this._layerService.allLayers.find(layer => layer.layer === x.layer) : undefined
      )),
      this._layerService.layerSelectionChange
    ).pipe(
      auditTime(100)
    ).subscribe(l => {
      if (l && (!this._selectedDroneLayer || l.layer !== this._selectedDroneLayer.olLayer)) {
        this.setActiveDroneLayer(l)
      }
    })
  }

  cleanup () {
    this.ClearSelection()
    this._isDroneVideoPinned = false
  }

  canCalibrate (target: 'drone' | 'video', position: IDronePosition): boolean {
    if (!this._selectedDroneLayer) return false
    else return this._selectedDroneLayer.canCalibrate(target, position)
  }

  async setActiveDroneLayer (layer: AppLayer) {
    if (!this._isDroneVideoPinned && !this._videoFullscreen) {
      if (layer instanceof AppDroneLayer && !layer.error) {
        if (
          this._selectedDroneLayer &&
          this._selectedDroneLayer.layer.renderedSource === this._lastRenderedSource
        ) return
        this.ClearSelection()

        this._lastRenderedSource = layer.renderedSource
        if (layer.renderedSource) {
          this._videoSecondsObservable.next({ seconds: 0, update: true })
          this._selectedDroneLayer = new SelectedDroneLayer(
            layer,
            layer.renderedSource,
            this._api,
            this._workspaceMapService,
            this._alertService,
            this._promptService,
            this._workspaceService.workspaceId as number
          )
          await this._selectedDroneLayer.loadLayer()

          // Subscriptions cleared by calling complete in selected-drone-layer
          this._selectedDroneLayer.videoSeconds.subscribe(val => this._videoSecondsObservable.next(val))
          this._selectedDroneLayer.calibrationToggle.subscribe(val => this._calibrationToggle.next(val))

          this._selectedLayerSubscriptions.add(
            layer.selectChanged.subscribe(x => {
              if (!x) {
                this.disableCalibration()
                this.ClearSelection()
                panels.dwvideo.enabled = false
                panels.info.enabled = true
                this._viewerService.updatePanels(panels)
              }
            })
          )

          this._selectedLayerSubscriptions.add(
            layer.styleSynced.subscribe(this.updateDroneColor.bind(this))
          )
        }
        this._droneVideoChanged.next()

        this._selectedLayerSubscriptions.add(
          layer.sourceChanged.subscribe(src => {
            if (src === this._lastRenderedSource) return
            this.setActiveDroneLayer(layer)
          })
        )
      } else {
        if (this._selectedDroneLayer) this.ClearSelection()
      }

      const panels = this._viewerService.panels

      if (panels && panels.info && panels.dwvideo) {
        panels.dwvideo.enabled = layer instanceof AppDroneLayer
        panels.info.enabled = !panels.dwvideo.enabled
        this._viewerService.updatePanels(panels)
      }
    }
  }

  private ClearSelection () {
    this._selectedLayerSubscriptions.unsubscribe()
    this._selectedLayerSubscriptions = new Subscription()
    if (this._selectedDroneLayer) this._selectedDroneLayer.destroy()
    this._selectedDroneLayer = undefined
    this._videoSecondsObservable.next({ update: true, seconds: 0 })
    this._lastRenderedSource = undefined
  }
  enableCalibration () {
    if (!this._selectedDroneLayer) return
    this._selectedDroneLayer.enableCalibration()
  }

  disableCalibration () {
    if (!this._selectedDroneLayer) return
    this._selectedDroneLayer.disableCalibration()
  }

  async saveCalibration () {
    if (!this._selectedDroneLayer) return
    await this._selectedDroneLayer.saveCalibration()
  }

  resetCalibration () {
    if (!this._selectedDroneLayer) return
    this._selectedDroneLayer.resetCalibration()
  }

  moveDrone (position: 'forward' | 'backward') {
    if (!this._selectedDroneLayer) return
    try {
      this._selectedDroneLayer.move('drone', position)
    } catch (error: any) {
      this._alertService.log(error.message)
    }
  }

  moveVideo (position: 'forward' | 'backward') {
    if (!this._selectedDroneLayer) return
    try {
      this._selectedDroneLayer.move('video', position)
    } catch (error: any) {
      this._alertService.log(error.message)
    }
  }

  clickToAlignToggle (enabled?: boolean) {
    if (!this._selectedDroneLayer) return
    try {
      this._selectedDroneLayer.clickToAlignToggle(enabled)
    } catch (error: any) {
      this._alertService.log(error.message)
    }
  }

  centerDroneVector () {
    if (!this._selectedDroneLayer) return
    this._workspaceMapService.centerLayer(this._selectedDroneLayer.layer)
  }

  setVideoSeconds (seconds: number) {
    if (!this._selectedDroneLayer) return
    this._selectedDroneLayer.setVideoSeconds(seconds)
  }

  toggleDroneFOV (show: boolean) {
    if (this._selectedDroneLayer) {
      this._selectedDroneLayer.toggleDroneFOV(show)
    }
  }

  updateDroneColor () {
    this._selectedDroneLayer && this._selectedDroneLayer.updateDroneColor()
  }
}
