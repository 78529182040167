import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { Db } from '@vip-shared/models/db-definitions'
import { Subject } from 'rxjs'
import { VipApiService } from '@services/core/vip-api/vip-api.service'
import { WorkspaceMapService } from '../workspace-map/workspace-map.service'
import { WorkspaceService } from '../workspace.service'
@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  private _forecastDates: moment.Moment[] = []
  private _fetchedForecast: Db.Vip.Ext.IWeatherForecast[] = []
  private _irradianceObservable = new Subject<number>()
  private _tidyForecast: any = {}
  private _minDate = Infinity
  private _minTime = '0000'
  public startingDate = {
    date: '', // automatically sets current date
    format: 'YYYYMMDD'
  }
  readonly defaultWeatherStation = 'Eyemouth'
  private _siteName = 'EYEMOUTH'
  private _timeLineHours = [6, 9, 12, 13, 14, 15, 16, 17, 18, 21]
  private _forecastHours = this._timeLineHours.map(hour => {
    let hourString = ''
    if (String(hour).length === 1) {
      hourString += '0'
    }

    hourString += `${hour}00`

    return hourString
  })
  rangeChanged = new Subject<number>()
  forecastLoaded = new Subject<number>()

  get workspaceCompatible () {
    return [Db.Vip.Product.SOLAR_PV, Db.Vip.Product.DATAPITCH].includes(
      this._workspaceService.product as Db.Vip.Product
    )
  }
  constructor (
    private _api: VipApiService,
    private _workspaceMapService: WorkspaceMapService,
    private _workspaceService: WorkspaceService
  ) {
    this._workspaceMapService.selectedSubstation.subscribe(substation => {
      if (!this.workspaceCompatible) return
      this.UpdateSiteName(substation ? substation.substation : '')
    })

    this._workspaceService.workspaceLoaded.subscribe(loaded => {
      if (!loaded || !this.workspaceCompatible) return
      this.GenDates()
    })
  }

  get forecastDates () {
    return this._forecastDates
  }

  get fetchedForecast () {
    return this._fetchedForecast
  }

  get irradianceChanges () {
    return this._irradianceObservable.asObservable()
  }

  updateIrradiance (newIrradiance: number) {
    this._irradianceObservable.next(newIrradiance)
  }

  updateRange (value: number) {
    this.rangeChanged.next(value)
  }

  private UpdateSiteName (siteName: string) {
    // MSF TODO: this is quite hacky and doesn't scale well but it's the best we can do with the data and time we've got now
    const lower = siteName.toLowerCase()
    if (lower.includes('johnstown') || lower.includes('ruabon')) {
      this._siteName = 'RUABON'
    } else if (lower.includes('barnsley') || lower.includes('barugh')) {
      this._siteName = 'BARNSLEY'
    } else if (lower.includes('gosforth')) {
      this._siteName = 'NEWCASTLE UPON TYNE'
    } else if (lower.includes('eyemouth')) {
      this._siteName = 'EYEMOUTH'
    }

    this.FetchWeatherFor(
      this._siteName,
      this._forecastDates[0],
      this._forecastDates[this._forecastDates.length - 1]
    )
  }

  private async FetchWeatherFor (
    stationName: string,
    from?: moment.Moment,
    to?: moment.Moment,
    useTime: boolean = false
  ) {
    const weatherData: Db.Vip.Ext.IWeatherForecast[] = await this._api.orm
      .Weather().get(stationName, from, to, useTime).run() as any[]

    if (weatherData) {
      this._fetchedForecast = weatherData
      const tidyForecast: any = {}

      // sort forecast array by site_name, date and time for direct indexing
      weatherData.forEach(forecast => {
        // save forecasts taken only at specific times
        if (
          this._forecastHours.includes(forecast.hour)
        ) {
          if (!tidyForecast[forecast.site_name]) {
            tidyForecast[forecast.site_name] = {}
            this._siteName = forecast.site_name
          }
          if (!tidyForecast[forecast.site_name][forecast.date]) {
            tidyForecast[forecast.site_name][forecast.date] = {}
          }
          // if another entry has the same site_name, date and hour, only keeps the one with the most recent prod_date and prod_hour
          if (tidyForecast[forecast.site_name][forecast.date][forecast.hour]) {
            if (
              tidyForecast[forecast.site_name][forecast.date][forecast.hour]
                .prod_date > forecast.prod_date &&
              tidyForecast[forecast.site_name][forecast.date][forecast.hour]
                .prod_hour > forecast.prod_hour
            ) {
              tidyForecast[forecast.site_name][forecast.date][
                forecast.hour
              ] = forecast
            }
          } else {
            tidyForecast[forecast.site_name][forecast.date][
              forecast.hour
            ] = forecast
            if (+forecast.date < +this._minDate) {
              this._minDate = +forecast.date
            }
          }
        }
      })
      this._tidyForecast = tidyForecast
      const minDate = moment(+this._minDate, this.startingDate.format)
        .seconds(0)
        .minutes(0)
        .hour(6)
      this.forecastLoaded.next(+minDate.format('x'))
    }
  }

  // Using +01:00 timezone,as csv hour and date use +01:00
  private GenDates () {
    this._forecastDates = []
    let startingDate
    if (this.startingDate.date !== '') {
      startingDate = moment(this.startingDate.date, this.startingDate.format)
        .seconds(0)
        .minutes(0)
        .hour(0)
    } else {
      startingDate = moment()
        .seconds(0)
        .minutes(0)
        .hour(0)
    }
    const upToDate = moment(startingDate).add(15, 'd')
    for (
      const date = moment(startingDate);
      date.isBefore(upToDate);
      date.add(1, 'h')
    ) {
      this._forecastDates.push(moment(date))
    }

    this._forecastDates = this.forecastDates.filter(eachDate =>
      this._timeLineHours.includes(moment(eachDate).hour())
    )
    this.FetchWeatherFor(
      this._siteName,
      this._forecastDates[0],
      this._forecastDates[this._forecastDates.length - 1]
    )
  }

  private ClearDates () {
    this._forecastDates.splice(0, this._forecastDates.length)
    this._fetchedForecast = []
  }

  public getSingleForecast (utc?: number): Db.Vip.Ext.IWeatherForecast | undefined {

    let returnForecast
    let date

    const forecast = (date, time) => {
      const valueExists = this._tidyForecast[this._siteName] &&
        this._tidyForecast[this._siteName][date] &&
        this._tidyForecast[this._siteName][date][time]
      if (!valueExists) {
        console.warn(`Missing forecast value for site '${this._siteName}' date '${date}' time '${time}'.`)
        return
      }
      return this._tidyForecast[this._siteName][date][time]
    }
    if (utc) {
      date = moment(utc)

      const dateString = date.format('YYYYMMDD')
      const timeString = date.format('HHmm')

      returnForecast = forecast(dateString, timeString)
    } else {
      // if utc is not defined, use first measurement
      if (this._minDate === Infinity) {
        return undefined
      }
      returnForecast = forecast(this._minDate, this._minTime)
    }
    return returnForecast as Db.Vip.Ext.IWeatherForecast
  }
}
