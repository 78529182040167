import { Injectable } from '@angular/core'
import * as jszip from 'jszip'
import AppError from '@core/models/app-error'

@Injectable({
  providedIn: 'root'
})
export class ZipService {
  private readonly _mimeTypes = {
    rar: ['application/x-rar-compressed'],
    zip: ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip']
  }

  isZipped (file: File): boolean {
    if (file.type === 'application/octet-stream' || !file.type) {
      const name = file.name.toLowerCase()
      return name.endsWith('.rar') || name.endsWith('.zip') || name.endsWith('.7zip')
    }
    return Object.keys(this._mimeTypes).some(k => this._mimeTypes[k].includes(file.type))
  }

  getEntries (file: File): Promise<jszip> {
    if (!this.isZipped(file)) {
      throw new AppError(`File '${file.name}' is not a recognised rar/zip file.`)
    }
    return jszip.loadAsync(file)
  }
}
