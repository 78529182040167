import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loader = {
    map: false,
    global: false
  }
  text?: string

  get loading () {
    return Object.values(this.loader).includes(true)
  }

  enableLoading (type: 'global' | 'map', text?: string) {
    if (this.loading) this.disableLoading()
    if (text) this.text = text
    this.loader[type] = true
  }

  disableLoading () {
    for (const k in this.loader) {
      this.loader[k] = false
    }
    this.text = undefined
  }
}
