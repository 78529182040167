<input
        *ngIf="previewPosition === 'START'"
        [(min)]="min"
        [(max)]="max"
        [value]="getOpacityValue()"
        [disabled]= "disabled"
        (input)="validateInput($event)" />

<mat-slider [vertical]="vertical"
            [(tickInterval)]="tickInterval"
            [(min)]="min"
            [(max)]="max"
            [(step)]="step"
            [(value)]="value"
            [disabled]= "disabled"
            (window:mouseup)="changeOpacityReleaseEvent()"
            (input)="changeOpacityEvent($event)"></mat-slider>

<input
        *ngIf="previewPosition === 'END'"
        [(min)]="min"
        [(max)]="max"
        [value]="getOpacityValue()"
        [disabled]= "disabled"
        (input)="validateInput($event)" />
