import { Injectable } from '@angular/core'
import { Subject, BehaviorSubject, Observable } from 'rxjs'
import { AlertService, PromptService, AuthService, VipApiService } from '@services/core'
import { IPanelDetailKvP } from '@core/types'
import { WorkspaceService } from '../workspace.service'
import { SettingsService } from '@services/core/settings/settings.service'
import { Db } from '@vip-shared/models/db-definitions'
import { handleError } from '@core/models/app-error'

interface MenuItem {
  name: string,
  icon: string,
  position: string
}
@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  private _panelsSubscription = new BehaviorSubject<IPanelDetailKvP | undefined>(undefined)
  private _showRouteFinder = false
  private _showLayersAndInfo = false
  private _showSubmenu = true
  private _wholeViewTimeSeries?: Db.Helper.Geo.Timeseries

  private _panels: IPanelDetailKvP = {}

  private _baseMenuItems = [
    {
      name: 'search',
      icon: 'search',
      position: 'top'
    },
    {
      name: 'map',
      icon: 'map',
      position: 'top'
    },
    {
      name: 'views',
      icon: 'web',
      position: 'top'
    },
    {
      name: 'query_builder',
      icon: 'filter_list',
      position: 'top'
    },
    {
      name: 'air_routes',
      icon: 'bookmark',
      position: 'top'
    },
    {
      name: 'date_picker',
      icon: 'date_range',
      position: 'top'
    },
    {
      name: 'chart_list',
      icon: 'bar_chart',
      position: 'top'
    },
    {
      name: 'logout',
      icon: 'exit_to_app',
      position: 'bottom'
    },
    {
      name: 'help',
      icon: 'help_outline',
      position: 'bottom'
    },
    {
      name: 'about',
      icon: 'info_outline',
      position: 'bottom'
    }
  ]

  private _menuItems: MenuItem[] = []

  get menuItems (): MenuItem[] {
    return this._menuItems
  }

  showDrawer = true
  wholeViewMode = false
  wholeViewCounting = false
  drawerStatusChanged = new Subject<boolean>()
  menuItemsChanged = new Subject<MenuItem[]>()
  sizeRatioChanged = new Subject<void>()
  showSubmenu = new BehaviorSubject<boolean>(this._showSubmenu)

  get panels (): IPanelDetailKvP {
    return this._panels
  }

  get panelsObservable (): Observable<IPanelDetailKvP | undefined> {
    return this._panelsSubscription.asObservable()
  }

  get wholeViewTimeSeries (): Db.Helper.Geo.Timeseries | undefined {
    return this._wholeViewTimeSeries
  }
  get hasBasicAccess (): boolean {
    return [Db.Vip.Role.CUST_ADMIN_BASIC, Db.Vip.Role.CUST_USER_BASIC].includes(this._authService.permissionsLevel)
  }

  constructor (
    private _authService: AuthService,
    private _settingsService: SettingsService,
    private _workspaceService: WorkspaceService
  ) {
    this._workspaceService.workspaceLoaded.subscribe(() => {
      this._showRouteFinder = this._workspaceService.isAir()
      this._showLayersAndInfo = !this._workspaceService.isOneOf([
        Db.Vip.Product.TANKWATCH,
        Db.Vip.Product.AIR_POLLUTION
      ])
      this.initPanels()
      this.getViewInfo()
      this.getBaseMenu()
    })

    this._workspaceService.onExit.subscribe(() => {
      this.initPanels()
      this._showSubmenu = true
      this._workspaceService.setSafeMode(false)
      this._wholeViewTimeSeries = undefined
      this.wholeViewMode = false
      this.wholeViewCounting = false
    })
  }

  getBaseMenu () {
    if (this.hasBasicAccess) {
      this._baseMenuItems = [
        {
          name: 'search',
          icon: 'search',
          position: 'top'
        },
        {
          name: 'map',
          icon: 'map',
          position: 'top'
        },
        {
          name: 'views',
          icon: 'web',
          position: 'top'
        },
        {
          name: 'air_routes',
          icon: 'bookmark',
          position: 'top'
        },
        {
          name: 'date_picker',
          icon: 'date_range',
          position: 'top'
        },
        {
          name: 'logout',
          icon: 'exit_to_app',
          position: 'bottom'
        },
        {
          name: 'help',
          icon: 'help_outline',
          position: 'bottom'
        },
        {
          name: 'about',
          icon: 'info_outline',
          position: 'bottom'
        }
      ]
    }
  }

  initPanels () {
    this._panels = {
      info: {
        icon: 'message',
        title: 'Info',
        elementName: 'info',
        collapsed: false,
        pinned: false,
        enabled: this._showLayersAndInfo,
        haveInfoDialog: true
      },
      dwvideo: {
        icon: 'videocam',
        title: 'Drone Video',
        elementName: 'dwvideo',
        collapsed: false,
        pinned: false,
        enabled: false
      },
      layers: {
        icon: 'layers',
        title: 'Layers',
        elementName: 'layers',
        collapsed: false,
        pinned: false,
        enabled: this._showLayersAndInfo
      },
      routefinder: {
        icon: 'directions',
        title: 'Route Finder',
        elementName: 'routefinder',
        collapsed: false,
        pinned: false,
        enabled: this._showRouteFinder
      },
      streetview: {
        icon: 'directions',
        title: 'Street View',
        elementName: 'streetview',
        collapsed: false,
        pinned: false,
        enabled: false,
        lat: 42.345573,
        lon: -71.098326
      }
    }

    this._panelsSubscription.next(this._panels)
  }

  async getViewInfo () {
    const viewId = this._workspaceService.viewId
    if (!viewId) return
    try {
      if (!this._workspaceService.workspacePayload) return
      const view = this._workspaceService.workspacePayload.views.find(v => v.view_id === this._workspaceService.viewId)
      if (view && view.time_series) {
        this.wholeViewMode = true
        this._wholeViewTimeSeries = view.time_series as any
      }
    } catch (error: any) {
      handleError(error)
    }
  }

  toggleDrawer () {
    this.showDrawer = !this.showDrawer
    this.drawerStatusChanged.next(this.showDrawer)
    window.dispatchEvent(new Event('resize'))
  }

  toggleWholeView () {
    this.wholeViewMode = !this.wholeViewMode
    if (!this.wholeViewMode) this._wholeViewTimeSeries = undefined
  }

  toggleWholeViewCounting () {
    this.wholeViewCounting = !this.wholeViewCounting
  }

  setWholeViewTimeSeries (timeseries?: Db.Helper.Geo.Timeseries) {
    this._wholeViewTimeSeries = timeseries
  }

  togglePanel (panel: string, triggeredAutomatically = false) {
    this._panels[panel].triggeredAutomatically = triggeredAutomatically
    this._panels[panel].collapsed = !this._panels[panel].collapsed
    this.updatePanels(this._panels)
  }

  setFullscreen (panel: string, fullscreen: boolean) {
    this._panels[panel].fullScreen = fullscreen
    this.updatePanels(this._panels)
  }

  pinPanel (panel: string) {
    this._panels[panel].pinned = !this._panels[panel].pinned
    this.updatePanels(this._panels)
  }

  toggleAllPanels (visible: boolean) {
    for (const key in this._panels) {
      this._panels[key].triggeredAutomatically = false
      this._panels[key].collapsed = !visible
    }
    this.updatePanels(this._panels)
  }

  updatePanels (newPanels: IPanelDetailKvP) {
    this._panelsSubscription.next(newPanels)
  }

  loadMenuItems (): MenuItem[] {
    this._menuItems = []
    this._baseMenuItems.forEach(el => {
      if (this._settingsService.hasField(el.name)) {
        switch (el.name) {
          case 'air_routes':
            if (!this._workspaceService.isAir()) return
            break
          case 'query_builder':
            if (this._workspaceService.isAir() || this._workspaceService.isTankwatch()) return
            break
          case 'date_picker':
            if (!this._workspaceService.isDatapitch()) return
            break
        }
        this.menuItems.push(el)
      }
    })
    return this._menuItems
  }

  toggleSubmenu (show: boolean) {
    this._showSubmenu = show
    this.showSubmenu.next(this._showSubmenu)
  }
}
