import { Component, Input, AfterViewInit } from '@angular/core'
import Map from 'ol/Map'
import * as olSource from 'ol/source'
import * as olLayer from 'ol/layer'
import View from 'ol/View'
import * as olProj from 'ol/proj'
@Component({
  selector: 'app-ws-placeholder-map',
  templateUrl: './ws-placeholder-map.component.html',
  styleUrls: ['./ws-placeholder-map.component.scss']
})
export class WsPlaceholderMapComponent implements AfterViewInit {

  @Input() extent?: [number, number, number, number]
  @Input() workspaceId?: number

  mapId!: string

  private _tileLayerBase = new olLayer.Tile({
    source: new olSource.OSM({
      crossOrigin: 'anonymous'
    })
  })
  private _map!: Map

  ngAfterViewInit () {
    this.mapId = `map${this.workspaceId}`

    this._map = new Map({
      target: this.mapId,
      layers: [this._tileLayerBase],
      interactions: [],
      view: new View({
        center: olProj.fromLonLat([0, 0]),
        zoom: 1,
        minZoom: 1,
        maxZoom: 17,
        extent: olProj.transformExtent([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857')
      })
    })

    if (this.extent && this.extent.length === 4) {
      this.zoomToExtent(this.extent)
    }
  }

  async zoomToExtent (extent: [number, number, number, number]) {
    const view = this._map.getView()

    view.fit(extent, {
      padding: Array(4).fill(10),
      maxZoom: view.getMaxZoom()
    })

    const zoom = view.getZoom()
    const minZoom = view.getMinZoom()

    if (zoom && zoom < minZoom) view.setZoom(minZoom)
  }
}
