
<h2 mat-dialog-title>
  <mat-icon>build</mat-icon>
  Enhanced Query Builder
</h2>
<form #f2="ngForm">

<div mat-dialog-content>
    <div class="query-builder-form">
      <div class="row header">
        <mat-form-field>
          <input matInput placeholder="Query name" [(ngModel)]="queryName" name="query_name" #queryValue required/>
        </mat-form-field>
        <mat-form-field>
          <mat-select disableOptionCentering placeholder="Layer" name="query_layer" [(ngModel)]="queryTarget" [disabled]="styleBuilder"
            (selectionChange)="generateFilters($event.value)">
            <ng-container *ngFor="let layer of layers">
              <mat-optgroup *ngIf="layer.groupName; else optionTemp" [label]="layer.groupName">
                <mat-option *ngFor="let target of layer.targets" [value]="target" [disabled]="target.expectingSpatialQuery"
                  [matTooltip]="target.expectingSpatialQuery ?
                  'The attribute table has no data to query on currently. Please select a spatial query to populate the attribute table' : ''">
                  {{getOptionName(target)}}
                </mat-option>
              </mat-optgroup>
              <ng-template #optionTemp>
                <mat-option [value]="layer" [disabled]="layer.expectingSpatialQuery"
                  [matTooltip]="layer.expectingSpatialQuery ?
                  'The attribute table has no data to query on currently. Please select a spatial query to populate the attribute table' : ''">
                  {{ layer.title }}
                </mat-option>
              </ng-template>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="summary">
      <span [innerHTML]= "querySummary()" ></span>
    </div>

    <div *ngIf="queryObj?.targetRef">
      <div class="query-body">
        <div>

          <ng-container
            [ngTemplateOutlet]="containerTemplate"
            [ngTemplateOutletContext]="{
              $implicit: this.queryObj?.query.enhancedQuery,
              isRoot: true,
              sign: '0C'
            }">
          </ng-container>
        </div>

      </div>
    </div>
  </div>
<div class="st-button-row">
  <div class="left">
    <button color="primary" mat-button [mat-dialog-close]>CANCEL</button>
  </div>
  <div class="right">
    <button color="primary" mat-raised-button (click)="saveQuery()" [disabled]="isCreateSaveDisabled(queryObj)">{{queryObj?.query_id ? 'SAVE' : 'CREATE'}}</button>
  </div>
</div>

<ng-template #containerTemplate let-container let-isRoot="isRoot" let-sign="sign">
  <ng-container
    *ngIf="isRoot"
    [ngTemplateOutlet]="operatorTemplate"
    [ngTemplateOutletContext]="{
      index: 0,
      statement: container,
      sign: sign+'rootS'
    }">
  </ng-container>

  <div class="query-container">
    <div *ngFor="let statement of container.statements; let i=index">
      <ng-container
        class="margin-15"
        [ngTemplateOutlet]="operatorTemplate"
        [ngTemplateOutletContext]="{
          index: i,
          statement: statement,
          parent: container,
          sign: sign+i+'S'
        }">
      </ng-container>
      <div *ngIf="isStatement(statement)" class="statement">
        <ng-container
          [ngTemplateOutlet]="statementTemplate"
          [ngTemplateOutletContext]="{
            $implicit: statement,
            sign: sign+i+'S'
          }">
        </ng-container>
      </div>
      <div *ngIf="isContainer(statement)">
        <ng-container
          [ngTemplateOutlet]="containerTemplate"
          [ngTemplateOutletContext]="{
            $implicit: statement,
            sign: sign+i+'C'
          }">
        </ng-container>
      </div>


      <ng-container *ngIf="i===container.statements.length-1"
        [ngTemplateOutlet]="addStatementTemplate"
        [ngTemplateOutletContext]="{ $implicit: container,
          operatorType: container.statements[container.statements.length-1].operatorType
        }">
      </ng-container>
    </div>

    <ng-container *ngIf="container.caseBlocks.length"
      [ngTemplateOutlet]="caseBlocksOpTemplate"
      [ngTemplateOutletContext]="{
        parent: container,
        sign: sign+'B'
      }">
    </ng-container>

    <div *ngFor="let caseBlock of container.caseBlocks; let i=index" class="case-block">

      <mat-expansion-panel *ngIf="container.caseBlocks.length" class="st-result-block">
        <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight(caseBlock.conditionStatements.length)"
        [expandedHeight]="customCollapsedHeight(caseBlock.conditionStatements.length)">
          <mat-panel-title class="panel-title-container">
            <div *ngFor="let condStatement of caseBlock.conditionStatements; let j=index" class="case-block-query">
              <button mat-button *ngIf="j===0" class="option-button panel-title-button" color="primary"
                (click)="$event.stopPropagation()">If Result is:</button>
              <button mat-button *ngIf="j>0" class="option-button panel-title-button" [matMenuTriggerFor]="nodeQueryCtx" color="primary"
                (click)="$event.stopPropagation()">
                {{caseBlock.operators[j-1].symbol}}</button>
              <mat-menu #nodeQueryCtx="matMenu" xPosition="after">
                <button mat-menu-item (click)="deleteBlockQueryStmnt(caseBlock, condStatement, j); $event.stopPropagation()">
                  Remove node
                </button>
              </mat-menu>
              <mat-select disableOptionCentering placeholder="Operator" [name]="sign+'B'+i+j+'query_operator'" required
                [(ngModel)]="condStatement.operator" [compareWith]="matchOperator" class="panel-title panel-title-input"
                (click)="$event.stopPropagation()">
                <mat-option [value]="operator" *ngFor="let operator of decisionOperators"> {{operator.operation}}</mat-option>
              </mat-select>
              <input matInput type="text" placeholder="Case" [(ngModel)]="condStatement.value" class="panel-title-input"
                required [name]="sign+'B'+i+j+'caseColumnBlockValue'" (click)="$event.stopPropagation()"/>
            </div>
          </mat-panel-title>
          <mat-icon [matMenuTriggerFor]="newBlockQueryStmntAdd" matTooltip="Add statement node" class="icon panel-title-options"
            (click)="$event.stopPropagation()">add</mat-icon>
          <mat-menu #newBlockQueryStmntAdd="matMenu" xPosition="after" class="panel-title">
            <button mat-menu-item *ngFor="let operator of logicalOperators" (click)="addBlockQueryStatement(caseBlock, operator)">
              {{operator.operation}}
            </button>
          </mat-menu>
          <button mat-button matTooltip="Remove Case Block" color="primary" class="option-button panel-title-options" (click)="deleteCase(container, i)">
              Remove </button>
        </mat-expansion-panel-header>

        <ng-container
          [ngTemplateOutlet]="containerTemplate"
          [ngTemplateOutletContext]="{
            $implicit: caseBlock.block,
            isRoot: true,
            sign: sign+'B'+i
          }">
        </ng-container>
      </mat-expansion-panel>
    </div>
  </div>

</ng-template>

<ng-template #caseBlocksOpTemplate let-parent="parent" let-sign="sign">
  <button mat-button [matMenuTriggerFor]="caseCtx" [matTooltip]="parent.caseColumnName ? 'Case Blocks' : 'Click to Add a Column Name'"
    [color]="parent.caseColumnName ? 'primary': 'error'" class="option-button">
    Compare Results </button>

  <mat-chip *ngIf="parent.caseColumnName" class="chip"
    [matTooltip]="checkDuplicateName(parent.caseColumnName) ? 'Duplicate names, please rename the column' : 'Column Name'"
    [ngClass]="{'error': checkDuplicateName(parent.caseColumnName)}">
    {{parent.caseColumnName}} </mat-chip>

  <mat-menu #caseCtx="matMenu" xPosition="after">
    <button mat-menu-item (click)="deleteAllCases(parent)"> Remove cases </button>
    <mat-divider></mat-divider>
    <div (click)="$event.stopPropagation()" class="block-options">
      <input mat-menu-item matInput type="text" placeholder="Name" [(ngModel)]="parent.caseColumnName" required
      [name]="sign+'caseColumnBlockName'+parent.caseColumnName">
    </div>
    <button mat-menu-item (click)="createStatisticQuery(parent.caseColumnName)"> Create statistic query </button>
  </mat-menu>
</ng-template>

<ng-template #operatorTemplate let-k="index" let-statement="statement" let-parent="parent" let-sign="sign">
  <button mat-button *ngIf="k === 0" [matMenuTriggerFor]="nodeCtx" class="option-button"
    [matTooltip]="statement.createAttrColumn && !statement.attrColumnName ? 'Click to Add a Column Name' : 'Statements'"
    [color]="statement.createAttrColumn && !statement.attrColumnName ? 'error': 'primary'">
    Root</button>
  <button mat-button *ngIf="k > 0" [matMenuTriggerFor]="nodeCtx" matTooltip="Remove node" class="option-button"
    [matTooltip]="statement.createAttrColumn && !statement.attrColumnName ? 'Click to Add a Column Name' : 'Statements'"
    [color]="statement.createAttrColumn && !statement.attrColumnName ? 'error': 'primary'">
    {{parent.operators[k-1].symbol}}</button>

  <mat-chip *ngIf="statement.createAttrColumn && statement.attrColumnName" class="chip"
    [matTooltip]="checkDuplicateName(statement.attrColumnName) ? 'Duplicate names, please rename the column' : 'Column Name'"
    [ngClass]="{'error': !statement.attrColumnName || checkDuplicateName(statement.attrColumnName)}">
    {{ statement.attrColumnName}}</mat-chip>

  <ng-container *ngIf="isStatement(statement)"
    [ngTemplateOutlet]="statementOptions"
    [ngTemplateOutletContext]="{
      $implicit: statement,
      sign: sign+k+'ST'
    }">
  </ng-container>

  <mat-menu #nodeCtx="matMenu" xPosition="after">
    <button mat-menu-item *ngIf="parent && k>0" (click)="deleteStatement(statement, parent, k)">
      Remove node
    </button>
    <mat-divider *ngIf="parent && k>0"></mat-divider>
    <mat-slide-toggle (click)="$event.stopPropagation()" [(ngModel)]="statement.createAttrColumn" class="block-options"
      [name]="sign+k+'createAttrColumn'">
      Create Attribute Column</mat-slide-toggle>
    <div (click)="$event.stopPropagation()"
      *ngIf="statement.createAttrColumn" class="block-options">
      <input mat-menu-item matInput type="text" placeholder="Name" [required]="statement.createAttrColumn" [name]="sign+k+'attrColumnName'"
      [(ngModel)]="statement.attrColumnName" >
    </div>
    <mat-divider *ngIf="statement.createAttrColumn"></mat-divider>
    <button mat-menu-item (click)="createStatisticQuery(statement.attrColumnName)" *ngIf="statement.createAttrColumn"> Create statistic query </button>
  </mat-menu>

</ng-template>

<ng-template #statementTemplate let-statement let-sign="sign">
  <div class="fields" *ngIf="['default', 'statistic'].includes(statement.statementType)">
    <mat-form-field class="block-content" *ngIf="isStatementType(statement, 'default')" floatLabel="never">
      <mat-select disableOptionCentering placeholder="Attribute" [name]="sign+'query_attributeA'"
        [(ngModel)]="statement.attributeA" required>
          <ng-container *ngFor="let column of columns">
            <mat-option [value]="column"> {{ column}}</mat-option>
          </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="block-content" *ngIf="isStatementType(statement, 'statistic')" floatLabel="never">
      <mat-select disableOptionCentering placeholder="Statistic Query" [name]="sign+'query_attributeA'"
        [(ngModel)]="statement.attributeA" required>
          <ng-container *ngFor="let column of statisticQueries">
            <mat-option [value]="column.name"> {{ column.name}}</mat-option>
          </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="block-content" floatLabel="never">
      <mat-select disableOptionCentering placeholder="Operator" [name]="sign+'query_operator'" required
        [(ngModel)]="statement.operator" [compareWith]="matchOperator">
        <div *ngIf="statement.operatorType === 'math'">
          <mat-option [value]="operator" *ngFor="let operator of mathOperators"> {{operator.operation}}</mat-option>
        </div>
        <div *ngIf="statement.operatorType === 'comparator'">
          <mat-option [value]="operator" *ngFor="let operator of decisionOperators"> {{operator.operation}}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <div *ngIf="statement.operator && statement.operator.operands > 1" >
      <mat-form-field class="block-content" *ngIf="statement.attributeBType ==='attribute'" floatLabel="never">
        <mat-select disableOptionCentering placeholder="Attribute" [name]="sign+'query_attributeB'"
          [(ngModel)]="statement.attributeB" required>
            <ng-container *ngFor="let column of columns">
              <mat-option [value]="column"> {{column}}</mat-option>
            </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="statement.attributeBType ==='value'" floatLabel="never">
        <input matInput placeholder="Value" [(ngModel)]="statement.attributeB" [name]="sign+'query_attributeB'" required/>
      </mat-form-field>
    </div>

  </div>
  <div class="fields" *ngIf="!isStatementType(statement, 'default')">
    <mat-form-field class="block-content" *ngIf="isStatementType(statement, 'attribute')" floatLabel="never">
      <mat-select disableOptionCentering placeholder="Attribute" [name]="sign+'query_attributeB'"
        [(ngModel)]="statement.attributeB" required>
          <ng-container *ngFor="let column of columns">
            <mat-option [value]="column"> {{column}}</mat-option>
          </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="isStatementType(statement, 'value')" floatLabel="never">
      <input matInput placeholder="Value" [(ngModel)]="statement.attributeB" [name]="sign+'query_attributeB'" required/>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #addStatementTemplate let-parent let-operatorType="operatorType">
  <div class="add">
    <mat-icon class="icon" [matMenuTriggerFor]="newNodeCtxAdd" matTooltip="Add statement node">add</mat-icon>
    <mat-menu #newNodeCtxAdd="matMenu" xPosition="after">
      <button mat-menu-item [matMenuTriggerFor]="newStatementNodeCtx" [disabled]="operatorType === 'comparator'">
        Add Statement
      </button>
      <button mat-menu-item [matMenuTriggerFor]="newContainerNodeCtx">
        Add Container
      </button>
      <button mat-menu-item *ngIf="!parent.thenBlock" matTooltip="Add a then block" class="st-danger" (click)="addResultBlock(parent)">
        Add Case
      </button>
      <button mat-menu-item *ngIf="parent.thenBlock" matTooltip="Add a then block" class="st-danger" (click)="removeResultBlock(parent)">
        Remove Then
      </button>
    </mat-menu>
    <mat-menu #newStatementNodeCtx="matMenu" xPosition="after">
      <button mat-menu-item *ngFor="let operator of mathOperators" (click)="addStatement(parent, operator)">
        {{operator.operation}}
      </button>
    </mat-menu>
    <mat-menu #newContainerNodeCtx="matMenu" xPosition="after">
      <button mat-menu-item *ngFor="let operator of decisionOperators"
        (click)="addContainer(parent, operator)" [disabled]="operatorType === 'comparator'">
          {{operator.operation}}
      </button>
      <button mat-menu-item *ngFor="let operator of logicalOperators" (click)="addContainer(parent, operator)">
        {{operator.operation}}
      </button>
    </mat-menu>
  </div>
</ng-template>

<ng-template #statementOptions let-statement let-sign="sign">
  <mat-button-toggle-group [name]="sign+'statement_type'" class="statment-button-toggle" [(ngModel)]="statement.statementType"
    #statementTypeGroup="matButtonToggleGroup" (change)="onStatementTypeChange(statementTypeGroup.value, statement)">
    <mat-button-toggle value="default" matTooltip="Statement of type 'Attribute Operator Attribute|Value'">Default</mat-button-toggle>
    <mat-button-toggle value="attribute" matTooltip="Statement of type 'Attribute'">Attribute</mat-button-toggle>
    <mat-button-toggle value="value" matTooltip="Statement of type 'Value'">Value</mat-button-toggle>
    <mat-button-toggle value="statistic" matTooltip="Statement of type 'StatisticQuery Operator Attribute|Value'">Statistic</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-button-toggle-group  [disabled]="!['default', 'statistic'].includes(statement.statementType)" [name]="sign+'operator_type'" class="statment-button-toggle"
    [(ngModel)]="statement.operatorType"
    #operatorTypeGroup="matButtonToggleGroup" (change)="onOperatorTypeGroupChange(statement)">
    <mat-button-toggle value="math" matTooltip="Mathematical Operators">Math</mat-button-toggle>
    <mat-button-toggle value="comparator" matTooltip="Comparator Operators">Comparator</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-button-toggle-group [disabled]="!['default', 'statistic'].includes(statement.statementType) || (statement.operator && statement.operator.operands < 2)"
    [(ngModel)]="statement.attributeBType" class="statment-button-toggle" [name]="sign+'attributeBType'"
    #attributeBTypeGroup="matButtonToggleGroup" (change)="onAttributeBTypeGroupChange(statement)">
    <mat-button-toggle value="attribute" matTooltip="Second Operator will be an Attribute Column">Attribute</mat-button-toggle>
    <mat-button-toggle value="value" matTooltip="Second Operator will be a Value">Value</mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>
</form>
