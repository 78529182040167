<h2 mat-dialog-title class="st-single-row-form">Dataset Viewer</h2>

<div class="st-table-overflow mat-elevation-z5">
  <mat-table [dataSource]="dataSource">

    <ng-container *ngFor="let column of data.displayColumns; let i = index" [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef> {{formattedColumns[i]}} </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row[column]}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="disabled">
      <mat-header-cell class="actions" *matHeaderCellDef >
        <span>Disabled</span>
        <mat-icon matTooltip="Entries marked as disabled in database will be excluded from customer facing usage.">
          help_outlined
        </mat-icon>
      </mat-header-cell>
      <mat-cell class="actions" *matCellDef="let row">
        <mat-checkbox #checkbox [checked]="row.disabled" (click)="$event.stopPropagation(); toggleArchived(row, checkbox)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="actions">
        <mat-header-cell class="actions" *matHeaderCellDef ></mat-header-cell>
        <mat-cell class="actions" *matCellDef="let row">
          <button *ngIf="data.delete" mat-icon-button color="primary" (click)="delete(row)">
            <mat-icon> delete </mat-icon>
          </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: stickyHeader"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
  </mat-table>
</div>

<div class="st-button-row">
    <button color="primary" type="button" mat-button mat-dialog-close>Close</button>
</div>

