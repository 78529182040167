import * as olStyle from 'ol/style'
import * as olColor from 'ol/color'
import IconOrigin from 'ol/style/Icon'
import { ColourUtil } from '@core/utils/index'

export default class LayerStyleUhlUtil {

  static getMeansOfTransportStyle (meansOfTransport: string, colorOverride?: olColor.Color): olStyle.Style {
    let style = new olStyle.Style()
    switch (meansOfTransport.toLowerCase()) {
      case 'car':
        style = new olStyle.Style({
          stroke: new olStyle.Stroke({
            width: 6,
            color: colorOverride || ColourUtil.defaultTransportColour.car
          })
        })
        break
      case 'foot':
        style = new olStyle.Style({
          stroke: new olStyle.Stroke({
            width: 6,
            color: colorOverride || ColourUtil.defaultTransportColour.foot
          })
        })
        break
      case 'cycle':
        style = new olStyle.Style({
          stroke: new olStyle.Stroke({
            width: 6,
            color: colorOverride || ColourUtil.defaultTransportColour.cycle
          })
        })
        break
    }

    return style
  }

  static getIconStyle (location: string, index: number, color?: olColor.Color): olStyle.Style | undefined {
    let iconStyle: olStyle.Style | undefined

    const wpIndex = new olStyle.Text({
      text: index.toString(),
      scale: color ? 0.9 : 1,
      fill: new olStyle.Fill({
        color: '#FFFFFF'
      }),
      offsetY: color ? -10 : -15
    })

    if (location === 'start') {
      iconStyle = new olStyle.Style({
        image: new olStyle.Icon({
          anchor: [0.5, 0],
          anchorOrigin: 'bottom-left',
          src: 'assets/icons/start.png',
          color: color || 'white'
        }),
        text: wpIndex
      })
    } else if (location === 'waypoint') {
      iconStyle = new olStyle.Style({
        image: new olStyle.Icon({
          anchor: [0.5, 0],
          anchorOrigin: 'bottom-left',
          src: 'assets/icons/waypoint.png',
          color: color || 'white'
        }),
        text: wpIndex
      })
    } else if (location === 'end') {
      iconStyle = new olStyle.Style({
        image: new olStyle.Icon({
          anchor: [0.5, 0],
          anchorOrigin: 'bottom-left',
          src: 'assets/icons/end.png',
          color: color || 'white'
        }),
        text: wpIndex
      })
    }

    if (iconStyle) {
      iconStyle.getImage().setScale(color ? 0.12 : 0.17)
    }

    return iconStyle
  }
}
