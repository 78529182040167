import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTabChangeEvent } from '@angular/material/tabs'
import { PromptService } from '@services/core'
import { DialogCleanup } from '@core/utils/ng-mixin/mixins/dialog-cleanup'
import { applyMixins } from '@core/utils/ng-mixin/ng-mixin'
import { Db } from '@vip-shared/models/db-definitions'
import * as moment from 'moment'
import { Subject } from 'rxjs'
import { LayerQueriesService } from '@services/workspace/layer-queries/layer-queries.service'
import { LayerService } from '@services/workspace'

interface DataRef {
  selectedRange: Date[],
  focalPoint: Date,
  option: string,
  layerList?: LayerTimeseriesInfo[]
  locked?: boolean,
  constraint?: Date[],
}

interface LayerTimeseriesInfo {
  name: string,
  dateRange: Db.Helper.Geo.DateRange
}
@Component({
  selector: 'app-date-range-chooser-dialog',
  templateUrl: './date-range-chooser-dialog.component.html',
  styleUrls: ['./date-range-chooser-dialog.component.scss']
})
export class DateRangeChooserDialogComponent implements DialogCleanup {

  @Output() selectedRangeChange: EventEmitter<any> = new EventEmitter()
    // DialogCleanup mixins
  _dialogs?: MatDialogRef<any>[]
  _trackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _untrackDialog<T> (dialog: MatDialogRef<T>) { return dialog }
  _destroyDialogs (): any { return }
  public selectedTab: number = 0
  public selectedRange: moment.Moment[] = [moment(), moment()]
  public option: string
  public errorMessage?: string
  public today: moment.Moment = moment().add(1, 'minute')
  public locked: boolean
  public lockedRange: number = 1
  public layerList: LayerTimeseriesInfo[]
  public focalPoint: moment.Moment = moment()
  public loading: boolean = false

  private _dateChanged = new Subject<boolean>()
  private constraint: Date[]
  private _allowAnyRange: boolean = false
  get dateChanged () {
    return this._dateChanged.asObservable()
  }

  get showApply (): boolean {
    return this.option === 'total'
  }

  get showSideNav (): boolean {
    return this.option === 'selected'
  }

  get targetLayer () {
    return this._layerService.selected
  }

  static setOptions (data: DataRef) {
    return {
      selectedRange: data.selectedRange.map(d => moment(d)),
      focalPoint: moment(data.focalPoint),
      option: data.option,
      locked: data.locked,
      constraint: data.constraint || [],
      layerList: data.layerList || []
    }
  }

  constructor (@Inject(MAT_DIALOG_DATA) public dataRef,
    private _dialogRef: MatDialogRef<DateRangeChooserDialogComponent>,
    private _promptService: PromptService,
    private _layerQueriesService: LayerQueriesService,
    private _layerService: LayerService
  ) {
    this.selectedRange = dataRef.selectedRange
    this.focalPoint = dataRef.focalPoint
    this.option = dataRef.option
    this.locked = dataRef.locked
    this.constraint = dataRef.constraint
    this.layerList = dataRef.layerList
    if (this.locked) {
      this.lockedRange = moment(this.selectedRange[1]).diff(moment(this.selectedRange[0]), 'minutes')
    }
  }

  focalPointValidation () {
    if (!moment(this.focalPoint).isBetween(this.selectedRange[0], this.selectedRange[1])) {
      if (moment(this.focalPoint).diff(this.selectedRange[0]) < moment(this.selectedRange[1]).diff(this.focalPoint)) {
        this.focalPoint = this.selectedRange[0]
      } else {
        this.focalPoint = this.selectedRange[1]
      }
    }
  }

  onDateChange (e, changed: number) {
    const multiplier = changed === 1 ? -1 : 1
    if (this.locked) {
      const newDate = moment(this.selectedRange[changed]).add(multiplier * this.lockedRange, 'minutes')
      const today = moment().add(1, 'minute')
      this.selectedRange[1 - changed] = newDate.isSameOrAfter(today) ? today : newDate
      this.selectedRange = [...this.selectedRange]
    }
    if (this.constraint.length && !this._allowAnyRange) {
      if (this.selectedRange[0].valueOf() > this.constraint[0].valueOf() || this.selectedRange[1].valueOf() < this.constraint[1].valueOf()) {
        this._trackDialog(
          this._promptService.prompt(`Selecting this date will affect the current selected range.

          Are you sure you want to continue?`, {
            yes: () => {
              this._allowAnyRange = true
            },
            no: () => {
              this._allowAnyRange = false
            }
          })
        )
      } else {
        this.errorMessage = undefined
      }
    }
    this.focalPointValidation()
  }

  setLastWeek () {
    this.selectedRange = [
      moment().add(-7, 'days'),
      moment()
    ]
    this.focalPointValidation()
  }

  setLastMonth () {
    this.selectedRange = [
      moment().add(-1, 'months'),
      moment()
    ]
    this.focalPointValidation()
  }

  setLastQuarter () {
    this.selectedRange = [
      moment().add(-3, 'months'),
      moment()
    ]
    this.focalPointValidation()
  }

  setLayerPreset (dateRange: Db.Helper.Geo.DateRange) {
    this.selectedRange = [
      moment(dateRange.from),
      moment(dateRange.to)
    ]
    this.focalPoint = moment(dateRange.focal)
  }

  tabChanged (e: MatTabChangeEvent) {
    this.selectedTab = e.index
  }

  async applyDates () {
    if (!this.targetLayer || !this.targetLayer.selected) return
    this.loading = true
    const continueSelected = this.option === 'total' ? true : await this._layerQueriesService.checkEntriesInTimeRange(this.targetLayer, this.selectedRange[0].toISOString(), this.selectedRange[1].toISOString())
    this.loading = false
    if (continueSelected) {
      this.selectedRangeChange.emit({
        selectedRange: this.selectedRange,
        option: this.option,
        focalPoint: moment(this.focalPoint)
      })
      // This should return 'true' to apply time series after time range change for layer,
      // but return 'false' for total range change as that does not change time series
      // selection
      this._dialogRef.close(this.option === 'selected')
    } else {
      this.selectedRange = this.dataRef.selectedRange
      this.focalPoint = this.dataRef.focalPoint
    }
  }

}

applyMixins(DateRangeChooserDialogComponent, [DialogCleanup])
