import { Injectable } from '@angular/core'
import { Subject, fromEvent } from 'rxjs'
import { CommonUtil } from '@core/utils/index'
import { DomUtil } from '@core/utils/dom/dom.util'

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  private _shiftDown = false
  get shiftDown () {
    return this._shiftDown
  }

  private _ctrlDown = false
  get ctrlDown () {
    return this._ctrlDown
  }

  private _ctrlArrowDown = new Subject<KeyboardEvent>()
  get ctrlArrowDown () {
    return this._ctrlArrowDown.asObservable()
  }

  private _ctrlArrowUp = new Subject<KeyboardEvent>()
  get ctrlArrowUp () {
    return this._ctrlArrowUp.asObservable()
  }

  private _ctrlArrowRight = new Subject<KeyboardEvent>()
  get ctrlArrowRight () {
    return this._ctrlArrowRight.asObservable()
  }

  private _ctrlArrowLeft = new Subject<KeyboardEvent>()
  get ctrlArrowLeft () {
    return this._ctrlArrowLeft.asObservable()
  }

  private _arrowDown = new Subject<KeyboardEvent>()
  get arrowDown () {
    return this._arrowDown.asObservable()
  }

  private _arrowUp = new Subject<KeyboardEvent>()
  get arrowUp () {
    return this._arrowUp.asObservable()
  }

  private _arrowRight = new Subject<KeyboardEvent>()
  get arrowRight () {
    return this._arrowRight.asObservable()
  }

  private _arrowLeft = new Subject<KeyboardEvent>()
  get arrowLeft () {
    return this._arrowLeft.asObservable()
  }

  private _ctrlSave = new Subject<KeyboardEvent>()
  get ctrlSave () {
    return this._ctrlSave.asObservable()
  }

  private _esc = new Subject<KeyboardEvent>()
  get esc () {
    return this._esc.asObservable()
  }

  private _backspace = new Subject<KeyboardEvent>()
  get backspace () {
    return this._backspace.asObservable()
  }

  private _delete = new Subject<KeyboardEvent>()
  get delete () {
    return this._delete.asObservable()
  }

  private _tab = new Subject<KeyboardEvent>()
  get tab () {
    return this._tab.asObservable()
  }

  private _enter = new Subject<KeyboardEvent>()
  get enter () {
    return this._enter.asObservable()
  }

  private _ctrlEnter = new Subject<KeyboardEvent>()
  get ctrlEnter () {
    return this._ctrlEnter.asObservable()
  }

  private _ctrlZ = new Subject<KeyboardEvent>()
  get ctrlZ () {
    return this._ctrlZ.asObservable()
  }

  private _ctrlM = new Subject<KeyboardEvent>()
  get ctrlM () {
    return this._ctrlM.asObservable()
  }

  private _ctrlShiftS = new Subject<KeyboardEvent>()
  get ctrlShiftS () {
    return this._ctrlShiftS.asObservable()
  }

  private _ctrlShiftE = new Subject<KeyboardEvent>()
  get ctrlShiftE () {
    return this._ctrlShiftE.asObservable()
  }

  init () {
    fromEvent(window, 'keyup').subscribe(e => this.OnKeyUp(e as any))
    fromEvent(window, 'keydown').subscribe(e => this.OnKeyDown(e as any))
  }

  private OnKeyUp (e: KeyboardEvent) {
    this._shiftDown = e.shiftKey
    this._ctrlDown = e.ctrlKey
  }

  private OnKeyDown (e: KeyboardEvent) {
    this._shiftDown = e.shiftKey
    this._ctrlDown = e.ctrlKey

    if (this._ctrlDown && this._shiftDown) {
      if (CommonUtil.isKey(e, 's')) {
        e.preventDefault()
        this._ctrlShiftS.next(e)

      } else if (CommonUtil.isKey(e, 'e')) {
        this._ctrlShiftE.next(e)

      }
    } else if (this._ctrlDown) {
      if (CommonUtil.isKey(e, 's')) {
        e.preventDefault()
        this._ctrlSave.next(e)

      } else if (CommonUtil.isKey(e, 'z')) {
        e.preventDefault()
        this._ctrlZ.next(e)

      } else if (CommonUtil.isKey(e, 'm')) {
        e.preventDefault()
        this._ctrlM.next(e)
      } else if (CommonUtil.isKey(e, 'down')) {
        e.preventDefault()
        this._ctrlArrowDown.next(e)

      } else if (CommonUtil.isKey(e, 'up')) {
        e.preventDefault()
        this._ctrlArrowUp.next(e)

      } else if (CommonUtil.isKey(e, 'left')) {
        e.preventDefault()
        this._ctrlArrowLeft.next(e)

      } else if (CommonUtil.isKey(e, 'right')) {
        e.preventDefault()
        this._ctrlArrowRight.next(e)
      } else if (CommonUtil.isKey(e, 'enter')) {
        // If dropdown is active, do not fire enter event
        DomUtil.dropdownFocused() || this._ctrlEnter.next(e)
      }
    } else {
      if (CommonUtil.isKey(e, 'esc')) {
        this._esc.next(e)

      } else if (e.key === 'Delete') {
        this._delete.next(e)

      } else if (e.key === 'Backspace') {
        this._backspace.next(e)

      } else if (e.key === 'Tab') {
        this._tab.next(e)

      } else if (CommonUtil.isKey(e, 'enter')) {
        // If dropdown is active, do not fire enter event
        DomUtil.dropdownFocused() || this._enter.next(e)

      } else if (CommonUtil.isKey(e, 'down')) {
        // If dropdown is active, do not fire up/down events
        DomUtil.dropdownFocused() || this._arrowDown.next(e)

      } else if (CommonUtil.isKey(e, 'up')) {
        DomUtil.dropdownFocused() || this._arrowUp.next(e)

      } else if (CommonUtil.isKey(e, 'left')) {
        this._arrowLeft.next(e)

      } else if (CommonUtil.isKey(e, 'right')) {
        this._arrowRight.next(e)
      }
    }
  }
}
