import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})

export class SubmitButtonComponent {
  @Input() text = 'Submit'
  @Input() disabled = false
  @Input() loading = false
  @Output() submit = new EventEmitter()
  @Input() focus = true

  onClick () {
    if (!this.loading && !this.disabled) {
      this.submit.emit()
    }
  }
}
