<div id="attribute-table-panel" #attributeTable>
  <div id="table-toolbar">
    <div id="attr-table-target">
      <p class="st-ellipsis">{{ targetLayer?.title }}</p>
    </div>

    <div>
      <mat-icon (click)="sidenav.toggle()" matTooltip="Open sidenav menu">list</mat-icon>
      <mat-icon [matTooltip]="infoTooltip">info</mat-icon>
      <mat-icon [matMenuTriggerFor]="filterMenu" *ngIf="isAttributeTab" matTooltip="Column options">more_vert</mat-icon>
      <mat-icon [matMenuTriggerFor]="actionMenu" *ngIf="!editMode || targetLayer?.isPropertyView"
        matTooltip="Table options">download</mat-icon>
    </div>

  </div>
  <mat-menu #filterMenu="matMenu">
    <div class="menu-content">
      <div class="menu-title">Column settings</div>
      <mat-form-field matTooltip="Select mode for rendering contents of table in the map.">
        <mat-label>View Mode</mat-label>
        <mat-select disableOptionCentering [(value)]="selectedViewMode" (selectionChange)="viewModeChange($event)"
          color="primary">
          <mat-option [value]="mode" *ngFor="let mode of viewModes">
            {{ mode }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field matTooltip="Manage column visibility.">
        <mat-label>Columns</mat-label>
        <mat-select disableOptionCentering [placeholder]="displayedColumns" multiple="true"
          [(ngModel)]="displayedColumns" (selectionChange)="filterColumnsTrigger(true)" color="primary">
          <ng-container *ngFor="let column of availableColumns">
            <ng-container *ngIf="column.name">
            <mat-option [value]="column.prop">
              {{ column.name }}
            </mat-option>
          </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #actionMenu="matMenu">
    <button mat-menu-item *ngIf="sampled" (click)="downloadCsv(true)"
      matTooltip="Export all queried rows, if the layer is sampled it will include the complete dataset">Export Entire
      Layer</button>
    <button mat-menu-item *ngIf="hasData" (click)="export(false)"
      matTooltip="Export visible table data and all hidden columns, if the layer is sampled it will not include the entire layer data">{{sampled
      ? 'Export Full Sampled Table':'Export Full Table'}}</button>
    <button mat-menu-item *ngIf="hasData" (click)="export(true)"
      matTooltip="Export visible table data, if the layer is sampled it will not include the entire layer data">{{sampled
      ? 'Export Visible Sampled Table':'Export Visible Table'}}</button>
  </mat-menu>
  <mat-menu #deleteMenu="matMenu">
    <button mat-menu-item (click)="deleteSelectedRows()">Delete {{multiSelect ? 'rows' : 'row'}}</button>
    <button *ngIf="!readOnly" mat-menu-item (click)="deleteSelectedRowsGeometries()">Remove {{multiSelect ? "rows
      geometries" : "row's geometry"}}</button>
  </mat-menu>

  <mat-sidenav-container class="side-nav-container">
    <mat-sidenav #sidenav mode="side" [(opened)]="sideNavOpened" class="sidenav">
      <mat-nav-list>
        <ng-container *ngFor="let tabKey of availableTabs">
          <mat-list-item [ngClass]="{'active': selectedTab === availableTab[tabKey]}"
            (click)="onTabChange(availableTab[tabKey])">
            {{tabKey}}
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div id="attribute-table" [style.display]="isAttributeTab ? 'block' : 'none'">

        <div class="no-columns" *ngIf="!hasVisibleColumns">
          <span>Layer has no visible columns.</span>
        </div>
        <ngx-datatable #table id="ngx-table" class="material st-compact-table" [class.edit-mode]="editMode"
          [class.guide-bar-visible]="targetLayer?.layerMode && targetLayer?.anotGuideSet"
          [headerHeight]="40" [rowHeight]="35" [scrollbarV]="true" columnMode="standard"
          [displayCheck]="shouldDisplayCheckbox" [rows]="dataSource?.rows" [columns]="dataSource?.columns"
          [selected]="dataSource?.selected" (activate)="onRowActivate($event)" selectionType="checkbox"
          [reorderable]="false" (tableContextmenu)="onTableContextMenu($event)" (resize)="onColumnResize($event)"
          [cssClasses]="{sortAscending: 'material-icons arrow-up', sortDescending: 'material-icons arrow-down'}">

        </ngx-datatable>

        <app-loader-backdrop *ngIf="applyingChanges || reloading || lockTable"
          [loadingText]="applyingChanges ? 'Applying changes...' : (lockTable ? 'Loading...' : 'Refreshing...')">
        </app-loader-backdrop>
      </div>

      <div id="statistics-table" [style.display]="isStatisticsTab ? 'block' : 'none'">
        <div class="no-columns" *ngIf="!hasVisibleColumns">
          <span>Layer has no visible columns.</span>
        </div>
        <div class="no-columns" *ngIf="!querySource?.rows.length">
          <span>
            Layer has no statistic queries. To add a new statistic please use the Explorer view and go to the Attributes section of the layer,
            right-click on numeric column and select 'Create Statistic Query'.
          </span>
        </div>
        <div class="no-columns" *ngIf="!renderStatisticTable">
          <mat-spinner [diameter]="25" color="primary" class="spinner"></mat-spinner>
          <span>
            Statistic Queries Update in progress
          </span>
        </div>
        <ngx-datatable *ngIf="renderStatisticTable" class="material st-compact-table"
          [columns]="this.querySource?.columns" [headerHeight]="40" columnMode="force" [rows]="data" [rowHeight]="35"
          [scrollbarV]="true" [trackByProp]="'query'" (directiveDrop)="onDrop($event)">
        </ngx-datatable>

      </div>

    </mat-sidenav-content>

  </mat-sidenav-container>

  <ng-template #colorCellTemplate let-row="row" let-value="value" let-i="index">
    <div class="style-preview" *ngIf="row._geom">
      <app-geometry-icon [geom]="row._geom" [stroke]="row._style?.stroke || 'transparent'"
        [fill]="row._style?.fill || 'transparent'"
        [invertPointStyle]="!!targetLayer?.preset && targetLayer?.preset === 'prop_view_buildings'"></app-geometry-icon>
    </div>
  </ng-template>

  <ng-template #checkboxCellTemplate let-row="row" let-value="value" let-i="index">
    <mat-checkbox [checked]="row._selected" (change)="toggleRowSelection(row, $event)"></mat-checkbox>
  </ng-template>

  <ng-template #checkboxHeaderTemplate>
    <mat-checkbox [checked]="someChecked" (change)="toggleRowsSelection($event)"
      [matTooltip]="someChecked ? 'Deselect all' : 'Select all'"></mat-checkbox>
  </ng-template>
</div>
