<div class="st-table-filters">
    <div class="st-filter-group">
        <mat-form-field>
            <input matInput (keyup)="applyFilter('name', $event.target.value)" [value]="filter.name" placeholder="Filter by name">
        </mat-form-field>
        <mat-form-field>
            <input matInput (keyup)="applyFilter('surname', $event.target.value)" [value]="filter.surname" placeholder="Filter by surname">
        </mat-form-field>
        <mat-form-field *ngIf="sysMaintainer">
            <input matInput (keyup)="applyFilter('customer', $event.target.value)" [value]="filter.customer" placeholder="Filter by company">
        </mat-form-field>
    </div>

    <button mat-raised-button color="primary" (click)="toggleImmutable()">
      {{filter.immutable ? 'Hide' : 'Show'}} Immutable Roles
    </button>
</div>

<ng-template #nodata>
  <app-note>
      No users found matching the filter{{filter.immutable ? '' : '. Try viewing immutable roles.'}}
  </app-note>
</ng-template>

<div class="mat-elevation-z5 st-table-overflow access-table" *ngIf="dataSource?.filteredData?.length; else nodata">

  <mat-table [dataSource]="dataSource" matSort #table>
    <ng-container matColumnDef="user_forename">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span>{{ user.user_forename }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user_surname">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Surname</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span>{{ user.user_surname }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user_email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span> {{ user.user_email }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customer_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span> {{ user.customer_name }} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <span> {{ user.updated_at | moment:'=>DD/MMM/YY HH:mm'}} </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell class="role-column" *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
      <mat-cell  class="role-column" *matCellDef="let user">
        <mat-select disableOptionCentering [disabled]="cantModifyAccess(user)" placeholder="Workspace role" [(value)]="user.workspace_permissions_level"
          (selectionChange)="updatePermissions(user)">
          <mat-option [disabled]="!sysMaintainer && editorPermissions > role.permissions_level" *ngFor="let role of roles" [value]="role.permissions_level"
            [matTooltip]="role.role_desc">
            {{ role.role_display_name }}
          </mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valid_from">
      <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header>Valid From</mat-header-cell>
      <mat-cell class="date-column" *matCellDef="let user">
        <app-date-time-input shouldBe="inPast" expiryNote="Workspace access for user is forbidden until after the date."
        [disabled]="cantModifyAccess(user)" [(value)]="user.valid_from" (valueChange)="updatePermissions(user)"
        [min]="user.customer_valid_from" [max]="user.valid_to || user.customer_valid_to" [showTrigger]="false"></app-date-time-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valid_to">
      <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header>Valid To</mat-header-cell>
      <mat-cell class="date-column" *matCellDef="let user">
        <app-date-time-input shouldBe="inFuture" expiryNote="Workspace access for user is expired."
        [disabled]="cantModifyAccess(user)" [(value)]="user.valid_to" (valueChange)="updatePermissions(user)"
        [min]="user.valid_from || user.customer_valid_from" [max]="user.customer_valid_to" [showTrigger]="false"></app-date-time-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="st-narrow-cell" *matHeaderCellDef mat-sort-header>Access</mat-header-cell>
      <mat-cell class="st-narrow-cell" *matCellDef="let user">
        <mat-slide-toggle [disabled]="cantToggleAccess(user)" [checked]="user.workspace_permissions_level" color="primary"
          (change)="toggleUserAccess($event, user)"></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" [@tableRowHighlight]="row.highlight ? 'active' : 'inactive'" class="{{row.highlight}} {{row.user_id}}"></mat-row>
  </mat-table>
</div>

<app-loader-backdrop *ngIf="loading"></app-loader-backdrop>
