
<div class="container">
  <div class="flex-container">
      <div class="content">
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <span>From</span>
              <span>{{selectedRange[0].toDate() | date:'dd MMM yyyy HH:mm'}}</span>
            </ng-template>
              <owl-date-time-inline [(ngModel)]="selectedRange"
                [selectMode]="'rangeFrom'" [max]="selectedRange[1]"
                (ngModelChange)="onDateChange($event, 0)">
              </owl-date-time-inline>
              <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <span>To</span>
              <span>{{selectedRange[1].toDate() | date:'dd MMM yyyy HH:mm'}}</span>
            </ng-template>
            <owl-date-time-inline [(ngModel)]="selectedRange"
              [selectMode]="'rangeTo'" [max]="today" [min]="selectedRange[0]"
              (ngModelChange)="onDateChange($event, 1)">
            </owl-date-time-inline>
              <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>
          </mat-tab>
          <mat-tab *ngIf="option !== 'total'">
            <ng-template mat-tab-label>
              <span>Focal Point</span>
              <span>{{focalPoint.toDate() | date:'dd MMM yyyy HH:mm'}}</span>
            </ng-template>
            <owl-date-time-inline [(ngModel)]="focalPoint" #focal
               [max]="selectedRange[1]" [min]="selectedRange[0]">
            </owl-date-time-inline>
              <p class="st-error" *ngIf="errorMessage">{{errorMessage}}</p>
          </mat-tab>
        </mat-tab-group>
        <div class="bottom" *ngIf="showApply">
          <button mat-raised-button color="primary" (click)="applyDates()" [disabled]="errorMessage">Apply</button>
        </div>
      </div>
      <mat-divider [vertical]="true" *ngIf="showSideNav"></mat-divider>
      <div class="sidenav" *ngIf="showSideNav">
        <div class= "top">
          <button mat-raised-button color="primary" [matMenuTriggerFor]="rangePresetMenu">
            <label>Range Preset</label>
            <mat-icon class="nav-link-icon mat-icon">arrow_drop_down</mat-icon>
          </button>

          <div class="divider-container">
            <div class="line"><mat-divider></mat-divider></div>
            <div class="text mat-typography">Or</div>
            <div class="line"><mat-divider></mat-divider></div>
          </div>


          <button mat-raised-button color="primary" [matMenuTriggerFor]="layerPresetMenu" [disabled]="!layerList.length">
            <label>From Layer</label>
            <mat-icon class="nav-link-icon mat-icon">arrow_drop_down</mat-icon>
          </button>
        </div>


        <div class="bottom">
          <mat-divider></mat-divider>
          <div *ngIf="loading" class="spinner">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
          <button mat-raised-button color="primary" (click)="applyDates()" [disabled]="errorMessage" *ngIf="!loading">Apply</button>
        </div>

      </div>
    </div>
</div>


<mat-menu #rangePresetMenu="matMenu">
  <div mat-menu-item (click)="setLastWeek()">Last week</div>
  <div mat-menu-item (click)="setLastMonth()">Last month</div>
  <div mat-menu-item (click)="setLastQuarter()">Last quarter</div>
</mat-menu>

<mat-menu #layerPresetMenu="matMenu">
  <div mat-menu-item *ngFor="let layer of layerList" (click)="setLayerPreset(layer.dateRange)">
    {{ layer.name }}
  </div>
</mat-menu>

