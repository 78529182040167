<div class="value-input">
  <div class="st-mat-field-pair">
    <mat-form-field>
      <div class="time-picker-row" [owlDateTimeTrigger]="dta">
        <input matInput [owlDateTime]="dta" placeholder="From" [(ngModel)]="nextRange.from"
          [max]="nextRange.to" (dateTimeChange)="validateRange()">
        <mat-icon [owlDateTimeTrigger]="dta">access_time</mat-icon>
      </div>
      <owl-date-time #dta pickerType="calendar"></owl-date-time>
    </mat-form-field>

    <mat-form-field>
      <div class="time-picker-row" [owlDateTimeTrigger]="dta2">
        <input matInput [owlDateTime]="dta2" placeholder="To" [(ngModel)]="nextRange.to"
          [min]="nextRange.from" (dateTimeChange)="validateRange()">
        <mat-icon [owlDateTimeTrigger]="dta2">access_time</mat-icon>
      </div>
      <owl-date-time #dta2 pickerType="calendar"></owl-date-time>
    </mat-form-field>
  </div>
</div>

<p class="st-error" *ngIf="errorMessage">{{ errorMessage }}</p>

<div class="value-list">
  <div class="value" *ngFor="let range of ranges">
    <span>
      From <span>{{range.from | moment:'=>YYYY-MM-DD HH:mm'}}</span>
      To <span>{{range.to | moment:'=>YYYY-MM-DD HH:mm'}}</span>
    </span>
    <mat-icon (click)="remove(range)">clear</mat-icon>
  </div>
</div>
