import { IQueryWRef } from '@core/models/query-object'

export default class LayerQueryUtil {
  static getQueryStyleIds (query: IQueryWRef): number[] {
    const activeIds = new Set<number>()
    for (const key in query.query.customColor) {
      if (query.query.customColor[key]) {
        if (query.query.customColor[key].gradient && query.query.customColor[key].gradient.active) {
          activeIds.add(query.query.customColor[key].gradient.id)
        }

        if (query.query.customColor[key].color && query.query.customColor[key].color.active) {
          activeIds.add(query.query.customColor[key].color.id)
        }
      }
    }

    return Array.from(activeIds)
  }

  static clearLayerFromQueryStyle (query: IQueryWRef) {
    const allFeatures = query.targetRef.features
    const activeIds = LayerQueryUtil.getQueryStyleIds(query)

    allFeatures.forEach(feature => {
      const props = (feature as any).values_
      if (props.customColors) {
        for (const key in props.customColors) {
          props.customColors[key]
            .filter(x => activeIds.includes(x.id))
            .forEach(x => {
              x.active = false
            })
        }
      }
    })
  }
}
