import { Component, OnInit } from '@angular/core'
import { FormControl, Validators, FormGroup } from '@angular/forms'
import { TypedFormControl, TypedFormGroup } from '@core/models/typed-form-control'
import { FormTemplate } from '@core/models/form-template'
import { AuthService, VipApiService } from '@services/core'
import { IPBugReport } from '@vip-shared/interfaces'
import { Subject, Subscription } from 'rxjs'
import AppError, { handleError } from '@core/models/app-error'
import FormValidators from '@core/utils/form-validators/form-validators'
import * as FormData from 'form-data'

type Form = TypedFormGroup<{
  name: TypedFormControl<string>
  email: TypedFormControl<string>
  message: TypedFormControl<string>
  screenshot: TypedFormControl<any>
}>


@Component({
  selector: 'app-bug-report',
  templateUrl: './bug-report.component.html',
  styleUrls: ['./bug-report.component.scss']
})
export class BugReportComponent extends FormTemplate<Form> {
  constructor (private _api: VipApiService) {
    super(
      new TypedFormGroup({
        name: new TypedFormControl<string>(undefined, Validators.required),
        email: new TypedFormControl<string>(undefined, [Validators.required, Validators.email]),
        message: new TypedFormControl<string>(undefined, [Validators.required, FormValidators.specialcharValidator]),
        screenshot: new TypedFormControl<string>(undefined,undefined),
      }))

  }
  file = undefined as any
  onFileSelected(event){
   
   
    this.file = event.target.files[0]
  }
  async submit () {
    this.errorMessage = undefined
    try {
      if (!this.form.valid) throw new AppError('Form invalid.')
      this.submitted = false
      const controls = this.form.controls

      let formData  = new FormData();
      formData.append('screenshot',this.file,'upload.png')
      formData.append('name',controls.name.value)
      formData.append('email',controls.email.value)
      formData.append('message',controls.message.value)

      const bugreport : IPBugReport= 
      {
        'name':controls.name.value,
        'email':controls.email.value,
        'message':controls.message.value,
        'screenshot':this.file
      }
      await this._api.orm.Users().bugReport(bugreport).run()

      this.submitted = true
    } catch (error: any) {
      handleError(error)
      this.errorMessage = error.message
    }
  }
}
