import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { DynamicAlertDialogComponent, DialogData } from '@core/components/dynamic-alert-dialog/dynamic-alert-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class DynamicAlertService {
  private _dialogRef ?: MatDialogRef < DynamicAlertDialogComponent >

  constructor (
        private _dialog: MatDialog
  ) {}

  log (data: DialogData) {
    if (this._dialogRef) this._dialogRef.close()
    this._dialogRef = this._dialog.open(DynamicAlertDialogComponent, DynamicAlertDialogComponent.setOptions(data))

    return this._dialogRef
  }
}
