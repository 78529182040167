import { trigger, style, transition, animate, state } from '@angular/animations'

export const tableRowHighlight = trigger('tableRowHighlight', [
  state('active', style({
    background: 'rgba(255, 255, 255, 0.3)'
  })),
  state('inactive', style({
    background: 'initial'
  })),
  transition('active => inactive', [
    animate('500ms')
  ]),
  transition('inactive => active', [
    animate('500ms')
  ])
])
