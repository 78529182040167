import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { DomainService } from '@services/core/domain/domain.service'
import { ThemeService } from '@services/core/theme/theme.service'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class LogoutComponent {

  get showCopyright () {
    return this._domainService.showGSICopyright
  }

  constructor (
    private _domainService: DomainService,
    themeService: ThemeService
  ) {
    themeService.setTitle('Logout - VIP')
  }

}
