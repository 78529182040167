import {
    IFeatureKnownProperties, IGeojsonPoint
} from '@core/types'
import { IJson } from '@vip-shared/interfaces'
import Geojson from 'ol/format/GeoJSON'
import Feature from 'ol/Feature'
import VectorLayer from 'ol/layer/Vector'
import BaseLayer from 'ol/layer/Base'
import OlUtil from '@core/utils/ol/ol.util'
import AppError from '@core/models/app-error'
import { FeatureCollection } from '@turf/turf'
export class GenerateAttributes {

  static convertToAttributes (properties: any): IFeatureKnownProperties {
    // TODO: Must be refactored; this implementation is unreliable
    let attributes: IFeatureKnownProperties = properties
    delete (attributes as any).geometry
    if (properties.img_name) {
    // TODO: Should only be used for obliques, what if other layers have the same column name
      attributes = this.FromGeoPoint(properties)
    }
    if (properties.descriptio) {
    // TODO: There are 30 tables with this columns, remove when sure it's not needed
    // attributes = this.FromGeoMultiPolygon(properties as IGeojsonMultiPolygon)
    }
    if (properties.s3_url) {
    // TODO: There are 69 tables with this columns, remove when sure it's not needed
    // attributes = this.FromFeatureValues(properties as IGeojsonFeatureValues)
    }
  // TODO: remove usage below, we don't want this overwrite for all layers, need to figure out type of layers that depend on this
  // overwrite
    if (properties.legal) {
      attributes.name = properties.ownername
    }
    if (properties.station_id) {
      attributes.name = properties.station_id
    }

      // MSF TODO: this is a quick snippet to add properties for obliques (regardless of other parameters)
      // but this whole class will need to be reworked

    if (
          properties.elevation
          && properties.bearing !== null
          && properties.bearing !== undefined
          && properties.sensor_size_v
          && properties.sensor_size_h
          && properties.focal_length
          && properties.mount_angle
      ) {
      attributes = {
        ...attributes,
        elevation: properties.elevation,
        bearing: properties.bearing,
        sensor_size_v: properties.sensor_size_v,
        sensor_size_h: properties.sensor_size_h,
        focal_length: properties.focal_length,
        mount_angle: properties.mount_angle
      }
    }
    return attributes
  }

  private static FromGeoPoint (properties: IFeatureKnownProperties & IGeojsonPoint): IFeatureKnownProperties {
    let elevation = 300  // MSF TODO: get rid of this when we're confident all images have elevation
    if (properties && properties.acq_elev) {
      elevation = +properties.acq_elev.split(' meters')[0]
    }

    const attr: IFeatureKnownProperties = Object.assign(properties, { elevation })
  // TODO: remove when sure it's not needed anymore
  // {
  //   name: properties.img_name,
  //   image: properties.thmb_url,
  //   imageLarge: properties.img_url,
  //   date: properties.acq_date,
  //   time: properties.acq_time,
  //   elevation
  // }

    return attr
  }

  private static IsVideoFile (fileName: string): boolean {

    let isVideo = false

    const formats = ['.mov', '.mp4']
    const file = fileName.toLowerCase()

    for (let i = 0; i < formats.length; i++) {

      const ext = formats[i]
      isVideo = file.includes(ext)

      if (isVideo) {
        i = formats.length
      }
    }

    return isVideo
  }

  static getFeatureAttributes (feature: Feature, updateSubstationsCb?: (properties: any) => void): IFeatureKnownProperties[] {
    const attributes: IFeatureKnownProperties[] = []
    let properties: IFeatureKnownProperties[] = [feature.getProperties() as any]
    if (OlUtil.isClusterFeature(properties[0])) {
      properties = properties[0].features.map((f: Feature) => f.getProperties())
    }
    for (const prop of properties) {
      if (!prop || Object.keys(prop).length === 0) continue
      if (prop._system && prop._system.filteredOutBy && prop._system.filteredOutBy.length) continue

      attributes.push(
        GenerateAttributes.convertToAttributes(prop)
      )
      if (updateSubstationsCb && prop.substation) updateSubstationsCb(prop)
    }
    return attributes
  }

  static getGeojsonAttributes (geojson: IJson) {
    const features = new Geojson().readFeatures(geojson)
    const attributes: IFeatureKnownProperties[] = []
    for (const f of features) {
      attributes.push(...this.getFeatureAttributes(f))
    }
    return attributes
  }

  static getRawGeojsonAttributes (geojson: FeatureCollection) {
    if (!geojson || !Array.isArray(geojson.features)) return []
    return geojson.features.map(x => x.properties).filter(x => !!x)
  }

  static getVectorLayerAttributes (layer: BaseLayer) {
    const vector = layer as VectorLayer<any>
    if (!vector.getSource) throw new AppError(`Cannot get attributes from layer that does not have a source.`)
    const features = vector.getSource().getFeatures()
    const attributes: IFeatureKnownProperties[] = []
    for (const f of features) {
      attributes.push(...this.getFeatureAttributes(f))
    }
    return attributes
  }

  static processEmapsiteAttributes(properties: any) {
    let processed: any = {
      'Proprietor Name': undefined,
      'Company Number': undefined,
      'Tenure': undefined,
      'Property Address': undefined,
      'Proprietorship Category': undefined,
      'Title number': undefined,
      'Multiple Address Indicator': undefined,
      'Update Date': undefined
    }
    for (const [key, value] of Object.entries(properties)) {
      switch (key){
        case ('proprietor_name_1'): {
          processed['Proprietor Name'] = value
          break
        }
        case ('company_registration_no_1'): {
          processed['Company Number'] = value
          break
        }
        case ('tenure'): {
          processed['Tenure'] = value
          break
        }
        case ('property_address'): {
          processed['Property Address'] = value
          break
        }
        case ('proprietorship_category_1'): {
          processed['Proprietorship Category'] = value
          break
        }
        case ('title_number'): {
          processed['Title number'] = value
          break
        }
        case ('multiple_address_indicator'): {
          processed['Multiple Address Indicator'] = value
          break
        }
        case ('update_date'): {
          processed['Update Date'] = value
          break
        }
        default: break
      }
    }
    return processed
  }
}
