import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ColorPickerModule } from 'ngx-color-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker'
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter'
import { ActionOutletFactory, ActionOutletModule } from '@ng-action-outlet/core'
import { ActionMatModule, ICON_TYPE } from '@ng-action-outlet/material'

import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component'
import { ChipsInputComponent } from './components/chips-input/chips-input.component'
import { DeleteButtonComponent } from './components/delete-button/delete-button.component'
import { ComponentLoaderComponent } from './components/loader-backdrop/loader-backdrop.component'
import { PromptDialogComponent } from './components/prompt-dialog/prompt-dialog.component'
import { SubmitButtonComponent } from './components/submit-button/submit-button.component'
import { UserHeaderComponent } from './components/user-header/user-header.component'
import { UserFooterComponent } from './components/user-footer/user-footer.component'
import { LogoComponent } from './components/logo/logo.component'
import { SliderComponent } from './components/slider/slider.component'
import { MaterialModule } from './lib/material.module'
import { TableTooltipComponent } from './components/table-tooltip/table-tooltip.component'
import { HighlightDirective } from './directives/highlight.directive'
import { ContextMenuDialogComponent } from './components/context-menu-dialog/context-menu-dialog.component'
import { NewAttributesRowDialogComponent } from './page-components/new-attributes-row-dialog/new-attributes-row-dialog.component'
import { ColumnsDialogComponent } from './page-components/columns-dialog/columns-dialog.component'
import { UserAccessManagerComponent } from './page-components/user-access-manager/user-access-manager.component'
import { OptionListDialogComponent } from './components/option-list-dialog/option-list-dialog.component'
import { InputToggleNumberComponent } from './components/input-toggle-number/input-toggle-number.component'
import { NewAttributeColumnDialogComponent } from './page-components/columns-dialog/new-attribute-column-dialog/new-attribute-column-dialog.component'
import { FileDragDropComponent } from './components/file-drag-drop/file-drag-drop.component'
import { OverflowAnimateDirective } from './directives/overflow-animate.directive'
import { HoldDownDirective } from './directives/hold-down.directive'
import { GisProjectValidDirective } from './directives/gis-project-valid.directive'
import { FilesDragDropDirective } from './directives/files-drag-drop.directive'
import { AutoCompleteDirective } from './directives/auto-complete.directive'
import { NoteComponent } from './components/note/note.component'
import { MathFloorPipe } from './pipes/math/floor.pipe'
import { LinkPipe } from './pipes/link.pipe'
import { MomentPipe } from './pipes/moment.pipe'
import { ObjectPlaceholderPipe } from './pipes/object-placeholder.pipe'
import { WssnapshotPipe } from './pipes/wssnapshot.pipe'
import { HoverTimeDirective } from './directives/hover-time.directive'
import { VectorUploadButtonComponent } from './components/vector-upload-button/vector-upload-button.component'
import { UploadListDialogComponent } from './page-components/upload-list-dialog/upload-list-dialog.component'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { PoweredByComponent } from './components/powered-by/powered-by.component'
import { RenderedDirective } from './directives/rendered.directive'
import { ShowDirective } from './directives/show.directive'
import { WsPlaceholderDefaultComponent } from './placeholders/workspaces/ws-placeholder-default/ws-placeholder-default.component'
import { WsPlaceholderSolarComponent } from './placeholders/workspaces/ws-placeholder-solar/ws-placeholder-solar.component'
import { WsPlaceholderUhlComponent } from './placeholders/workspaces/ws-placeholder-uhl/ws-placeholder-uhl.component'
import { WsPlaceholderTankwatchComponent } from './placeholders/workspaces/ws-placeholder-tankwatch/ws-placeholder-tankwatch.component'
import { SolidColorPickerComponent } from './components/solid-color-picker/solid-color-picker.component'
import { SolidColorPickerDialogComponent } from './components/solid-color-picker/solid-color-picker-dialog/solid-color-picker-dialog.component'
import { ThemePreviewToolboxComponent } from './page-components/theme-preview-toolbox/theme-preview-toolbox.component'
import { GroupHeaderComponent } from './components/group-header/group-header.component'
import { ThemePalettePreviewComponent } from './page-components/theme-palette-preview/theme-palette-preview.component'
import { ThemeEditorDialogComponent } from './page-components/theme-editor-dialog/theme-editor-dialog.component'
import { QueryBuilderDialogComponent } from './page-components/query-builder-dialog/query-builder-dialog.component'
import { DestroyedDirective } from './directives/destroyed.directive'
import { GeometryIconComponent } from './page-components/geometry-icon/geometry-icon.component'
import { NewPvGeometryDialogComponent } from './page-components/new-pv-geometry-dialog/new-pv-geometry-dialog.component'
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component'
import { WktPreviewComponent } from './page-components/wkt-preview/wkt-preview.component'
import { PresetConfigComponent } from './page-components/preset-config/preset-config.component'
import { GuideSetDialogComponent } from './page-components/preset-config/guide-set-dialog/guide-set-dialog.component'
import { DatasetJoinQueryEditorComponent } from './page-components/dataset-join-query-preview/dataset-join-query-editor/dataset-join-query-editor.component'
import { DatasetJoinLogoComponent } from './page-components/dataset-join-query-preview/dataset-join-logo/dataset-join-logo.component'
import { DatasetJoinQueryPreviewComponent } from './page-components/dataset-join-query-preview/dataset-join-query-preview.component'
import { AttributeChartComponent } from './page-components/attribute-chart/attribute-chart.component'
import { ButtonToggleFieldComponent } from './components/button-toggle-field/button-toggle-field.component'
import { DynamicAlertDialogComponent } from './components/dynamic-alert-dialog/dynamic-alert-dialog.component'
import { CopyPComponent } from './components/copy-p/copy-p.component'
import { RatioBoxComponent } from './components/ratio-box/ratio-box.component'
import { WsPlaceholderComponent } from './placeholders/workspaces/ws-placeholder/ws-placeholder.component'
import { WsPlaceholderMapComponent } from './placeholders/workspaces/ws-placeholder-map/ws-placeholder-map.component'
import { StatisticQueryBuilderDialogComponent } from './page-components/statistic-query-builder-dialog/statistic-query-builder-dialog.component'
import { Ng5SliderModule } from 'ng5-slider'
import { NewSpatialQueryDialogComponent } from './page-components/new-spatial-query-dialog/new-spatial-query-dialog.component'
import { PostcodeAreaDialogComponent } from './page-components/postcode-area-dialog/postcode-area-dialog.component'
import { CompositeGeomDialogComponent } from './page-components/composite-geom-dialog/composite-geom-dialog.component'
import { ScraperDialogComponent } from './page-components/scraper-dialog/scraper-dialog.component'
import { LocationsInputComponent } from './components/locations-input/locations-input.component'
import { DateRangesInputComponent } from './components/date-ranges-input/date-ranges-input.component'
import { EnhancedQueryBuilderDialogComponent } from './page-components/enhanced-query-builder-dialog/enhanced-query-builder-dialog.component';
import { RasterPresetConfigComponent } from './page-components/raster-preset-config/raster-preset-config.component';
import { VectorPresetConfigComponent } from './page-components/vector-preset-config/vector-preset-config.component';
import { ScraperPresetConfigComponent } from './page-components/scraper-preset-config/scraper-preset-config.component'
import { FloodRecordsTableComponent } from '@pages/workspace/map/map-overlays/map-controls/flood-alerts-list/flood-records-table/flood-records-table.component'
import { MatDialogModule } from '@angular/material/dialog'
import { FloodAlertsGraphComponent } from '@pages/workspace/map/map-overlays/map-controls/flood-alerts-list/flood-alerts-graph/flood-alerts-graph.component'
import { WidgetMenuDialogComponent } from '@pages/dashboard/widget-menu-dialog/widget-menu-dialog.component'
import { OneClickDialogComponent } from '@pages/dashboard/one-click-dialog/one-click-dialog.component'
import { TimeseriesSelectionFormComponent } from '@pages/workspace/right-drawer/panels/layers/layers-toolbox/create-layer-dialog/timeseries-selection-form/timeseries-selection-form.component';
import { RasterChartComponent } from './page-components/raster-chart/raster-chart.component';
import { RasterChartDialogComponent } from './page-components/raster-chart-dialog/raster-chart-dialog.component';
import { VideoAnimationDialogComponent } from './page-components/video-animation-dialog/video-animation-dialog.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpInputComponent } from './components/otp-input/otp-input.component';

const declareExport = [
  HighlightDirective,
  AutoCompleteDirective,
  FilesDragDropDirective,
  GisProjectValidDirective,
  HoldDownDirective,
  HoverTimeDirective,
  OverflowAnimateDirective,
  InputToggleNumberComponent,
  ChipsInputComponent,
  DeleteButtonComponent,
  ComponentLoaderComponent,
  SubmitButtonComponent,
  UserHeaderComponent,
  UserFooterComponent,
  LogoComponent,
  SliderComponent,
  FileDragDropComponent,
  OtpInputComponent,
  NoteComponent,
  MathFloorPipe,
  LinkPipe,
  MomentPipe,
  ObjectPlaceholderPipe,
  WssnapshotPipe,
  VectorUploadButtonComponent,
  PoweredByComponent,
  RenderedDirective,
  DestroyedDirective,
  ShowDirective,
  WsPlaceholderDefaultComponent,
  WsPlaceholderSolarComponent,
  WsPlaceholderUhlComponent,
  WsPlaceholderTankwatchComponent,
  SolidColorPickerComponent,
  ThemePreviewToolboxComponent,
  GroupHeaderComponent,
  ThemePalettePreviewComponent,
  GeometryIconComponent,
  DateTimeInputComponent,
  WktPreviewComponent,
  PresetConfigComponent,
  RasterPresetConfigComponent,
  VectorPresetConfigComponent,
  ScraperPresetConfigComponent,
  DatasetJoinQueryPreviewComponent,
  DatasetJoinLogoComponent,
  ThemePalettePreviewComponent,
  ButtonToggleFieldComponent,
  CopyPComponent,
  RatioBoxComponent,
  WsPlaceholderComponent,
  WsPlaceholderMapComponent,
  FloodRecordsTableComponent,
  TimeseriesSelectionFormComponent,
  RasterChartComponent
]
const declareExportEntry = [
  NewAttributesRowDialogComponent,
  AlertDialogComponent,
  PromptDialogComponent,
  UserAccessManagerComponent,
  TableTooltipComponent,
  ColumnsDialogComponent,
  ContextMenuDialogComponent,
  OptionListDialogComponent,
  NewAttributeColumnDialogComponent,
  UploadListDialogComponent,
  SolidColorPickerDialogComponent,
  ThemeEditorDialogComponent,
  QueryBuilderDialogComponent,
  WidgetMenuDialogComponent,
  OneClickDialogComponent,
  NewPvGeometryDialogComponent,
  GuideSetDialogComponent,
  DatasetJoinQueryEditorComponent,
  AttributeChartComponent,
  DynamicAlertDialogComponent,
  StatisticQueryBuilderDialogComponent,
  NewSpatialQueryDialogComponent,
  PostcodeAreaDialogComponent,
  CompositeGeomDialogComponent,
  ScraperDialogComponent,
  EnhancedQueryBuilderDialogComponent,
  FloodAlertsGraphComponent,
]
const importExport = [
  OwlMomentDateTimeModule,
  MaterialModule,
  MatDialogModule,
  OwlDateTimeModule,
  NgxDatatableModule,
  FormsModule,
  ReactiveFormsModule,
  ActionOutletModule,
  NgOtpInputModule,
]
@NgModule({
    declarations: [
        ...declareExport,
        ...declareExportEntry,
        LocationsInputComponent,
        DateRangesInputComponent,
        RasterChartComponent,
        RasterChartDialogComponent,
        VideoAnimationDialogComponent
    ],
    imports: [
        ...importExport,
        CommonModule,
        CommonModule,
        ColorPickerModule,
        RouterModule,
        Ng5SliderModule,
        ActionMatModule.forRoot(ICON_TYPE.Font)
    ],
    providers: [
        ObjectPlaceholderPipe,
        MomentPipe,
        ActionOutletFactory
    ],
    exports: [
        ...declareExport,
        ...importExport,
        ...declareExportEntry
    ]
})
export class CoreModule { }
