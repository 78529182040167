import { Directive, ElementRef, OnInit, Renderer2, Input } from '@angular/core'
import { WorkspaceService } from '@services/workspace'

@Directive({
  selector: '[appGisProjectValid]'
})
export class GisProjectValidDirective implements OnInit {
  @Input() projectName?: string
  private element: HTMLElement

  constructor (
        private _renderer: Renderer2,
        private _el: ElementRef,
        private _workspaceService: WorkspaceService
    ) {
    this.element = this._el.nativeElement
  }

  async ngOnInit () {

    let valid: boolean
    if (this.projectName) {
      valid = await this._workspaceService.appNameIsWorkspaceProductName(this.projectName)
    } else {
      const split: string[] = this.element.tagName.split('-')
      const appName = split[1].toLowerCase()
      valid = await this._workspaceService.appNameIsWorkspaceProductName(appName)
    }

    if (!valid) {
      this._renderer.removeChild(this.element, this.element)
    }
  }
}
