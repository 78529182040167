import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { handleError } from '@core/models/app-error';
import { AlertService, AuthService, SettingsService } from '@services/core';
import { DomainService } from '@services/core/domain/domain.service';
import { ThemeService } from '@services/core/theme/theme.service';
import { MFAStatus } from 'vip-shared/dist/interfaces';

@Component({
  selector: 'app-setup-mfa',
  templateUrl: './setup-mfa.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class SetupMfaComponent implements AfterViewInit {

  otp = '';
  errorMessage = '';
  submitting = false;
  QRCodeAltMessage = 'QRcode failed to load.';

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _settingsService: SettingsService,
    private _domainService: DomainService,
    private _alertService: AlertService,
    themeService: ThemeService
  ) {
    themeService.setTitle('Set up Multifactor Authentication');
  }

  ngAfterViewInit(): void {
    const otpInputNodes = document.getElementsByClassName("otp-input");
    Array.from(otpInputNodes).forEach((otpInputNode) =>
      otpInputNode.addEventListener("keyup", (event) => {
        if ((event as KeyboardEvent).key === 'Enter') this.submit();
      })
    )
  }

  get staging() {
    return this._settingsService.staging
  }

  get showCopyright() {
    return this._domainService.showGSICopyright
  }

  get isIEOrEdge() {
    return this._settingsService.isIEorEdge;
  }

  get MFAQRCode() {
    return this._authService.MFAQRCode;
  }

  get canOptOutMFA() {
    return this._authService.canOptOutMFA;
  }

  get canSubmit() {
    return !this.submitting && /\d{6}/.test(this.otp);
  }

  async optoutMFA() {
    if (this.canOptOutMFA && !this.submitting) {
      this.errorMessage = '';
      this.submitting = true;
      this.otp = '';

      this._alertService.closeAlertsOnLogin();

      try {
        await this._authService.optoutMFA();
        this.redirect();
      } catch (error: any) {
        console.log(error);
        handleError(error);
        this.errorMessage = error.message;
      } finally {
        this.submitting = false;
      }
    }
  }

  onOtpChange(otp: string) {
    this.errorMessage = '';
    this.otp = otp;
  }

  async submit() {
    if (this.canSubmit) {
      this.errorMessage = '';
      this.submitting = true;
      try {
        await this._authService.verifyOTP(this.otp);
        this.redirect();
      } catch (error: any) {
        handleError(error);
        this.errorMessage = error.message;
      } finally {
        this.submitting = false;
      }
    }
  }

  private redirect() {
    switch (this._authService.MFAStatus) {
      case MFAStatus.PENDING:
        this._router.navigate(['/setup-mfa']);
        break;
      case MFAStatus.AUTHENTICATED:
      case MFAStatus.OPT_OUT:
        // todo pass obtain the previous url if possible
        this._router.navigate(['/explorer']);
        break;
      default:
        this._router.navigate(['/enter-otp']);
        break;
    }
  }

}
