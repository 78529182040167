import { Injectable } from '@angular/core'
import { Subscription, Observable, interval, BehaviorSubject } from 'rxjs'
import { VipApiService } from '@services/core'
import { MapControl } from '@core/models/service-templates/map-control'
import { WorkspaceService } from '@services/workspace/workspace.service'
import { ActiveFloodWarning, IFloodReRecords, WarningGraphData } from '@vip-shared/interfaces/fred'
import { Db } from '@vip-shared/models/db-definitions'
import moment from 'moment'
import { IPActiveFloodWarning, IPNumEntriesCheck, IRAssociationUpdatedDates } from '@vip-shared/interfaces'
import { CommonUtil } from '@core/utils'

@Injectable({
  providedIn: 'root'
})
export class FloodAlertsService extends MapControl {
  private _currentAlerts: ActiveFloodWarning[] = []
  private _excludeSepa = false
  private _sepaExcludedRecords: IFloodReRecords[] = []
  private _records: IFloodReRecords[] = []
  get records () {
    return this._records
  }

  alertsCount: number[] = []
  warningsCount: number[] = []
  severeCount: number[] = []
  labels: string[] = []

  graphWarnings: WarningGraphData[] = []
  updatedGraphWarnings: WarningGraphData[] = []
  max: number = 40

  timePeriod: number | undefined
  sampleSize: number | undefined

  private _alertsChange = new BehaviorSubject<ActiveFloodWarning[]>([])
  private _recordsChange = new BehaviorSubject<IFloodReRecords[]>([])
  get alertsChange (): Observable<ActiveFloodWarning[]> {
    return this._alertsChange.asObservable()
  }

  get recordsChange (): Observable<IFloodReRecords[]> {
    return this._recordsChange.asObservable()
  }

  get excludeSepa (): boolean {
    return this._excludeSepa
  }
  private _associationUpdated = new BehaviorSubject<IRAssociationUpdatedDates[]>([])
  get associationUpdated (): Observable<IRAssociationUpdatedDates[]> {
    return this._associationUpdated.asObservable()
  }

  get sepaExcludedRecords () {
    return this._sepaExcludedRecords
  }

  private _sepaExcludedRecordsChange = new BehaviorSubject<IFloodReRecords[]>([])
  get sepaExcludedRecordsChange (): Observable<IFloodReRecords[]> {
    return this._sepaExcludedRecordsChange.asObservable()
  }

  private _checkInterval?: Subscription

  constructor (
    private _api: VipApiService,
    private workspaceService: WorkspaceService
  ) {
    super()
    workspaceService.onExit.subscribe(() => this.Cleanup())

    workspaceService.workspaceLoaded.subscribe(async loaded => {
      if (!loaded || this._checkInterval) return
      if (workspaceService.product !== Db.Vip.Product.FRED) return

      this.toggleEnabled(true)
      await this.GetRecordCounts()
      this.GetAssociationUpdated()
      this.GetRecordCountsWithoutSepa()
      this.UpdateWarnings()
      this._checkInterval = interval(600_000).subscribe(async () => {
        await this.GetRecordCounts()
        this.GetAssociationUpdated()
        this.GetRecordCountsWithoutSepa()
        this.UpdateWarnings()
        if (this.graphWarnings.length && this.sampleSize && this.timePeriod) this.getGraphWarnings(this.sampleSize, this.timePeriod)
      })
    })
  }

  private Cleanup () {
    this.toggleEnabled(false)
    this._collapsed = false
    this._currentAlerts = []
    this._alertsChange.next([])
    this.graphWarnings = []
    this.timePeriod = undefined
    this.sampleSize = undefined
    this.clearArrays()
    if (this._checkInterval) {
      this._checkInterval.unsubscribe()
      this._checkInterval = undefined
    }
  }

  public async getGraphWarnings (sample: number, timeperiod: number) {
    const timeRange: IPActiveFloodWarning = {
      sample : sample,
      time_period: timeperiod,
      exclude_sepa: this._excludeSepa
    }
    this.graphWarnings = await this._api.orm.Products().Fred().getFloodAlerts(timeRange).run()
    this.setGraphWarningsData()
  }

  private async UpdateWarnings () {
    this._currentAlerts = await this._api.orm.Products().Fred().getActiveAlerts().run()
    this._alertsChange.next([...this._currentAlerts])
  }

  private async GetAssociationUpdated () {
    const lastUpdated = await this._api.orm.Products().Fred().getAssociationLastUpdated().run()
    this._associationUpdated.next([...lastUpdated])
  }

  private async GetRecordCounts () {
    this._records = await this._api.orm.Products().Fred().getFloodReNumRecords().run()
    this._recordsChange.next([...this._records])
  }

  private async GetRecordCountsWithoutSepa () {
    this._sepaExcludedRecords = await this._api.orm.Products().Fred().getFloodReSepaExcludedNumRecords().run()
    this._sepaExcludedRecordsChange.next([...this._sepaExcludedRecords])
  }

  activeWarningsFilter (alerts: ActiveFloodWarning[], severity: number) {
    return alerts.filter(x => x.severity_level === severity)
  }

  numActiveWarningsFilter (alerts: ActiveFloodWarning[], severity: number, excludeSepa: boolean = false) {
    if (alerts.length === 0) alerts = this._currentAlerts
    return alerts.filter(x => x.severity_level === severity && (!excludeSepa || ['EA', 'NRW'].includes(x.dataset_name))).length
  }

  async setGraphWarningsData () {
    this.clearArrays()

    const labelSet: string[] = []
    this.graphWarnings.map(g => {
      labelSet.unshift(g.time_group)
    })

    this.labels = [...new Set(labelSet)]

    this.labels.map(l => {
      const filteredGraphTime = this.graphWarnings.filter(f => f.time_group === l)

      let data: WarningGraphData | undefined
      let value: number
      for (const item in Db.Fred.Severity) {
        switch (+Db.Fred.Severity[item]) {
          case Db.Fred.Severity.SEVERE_FLOOD_WARNING :
            data = this.filterGraphSeverity(Db.Fred.Severity.SEVERE_FLOOD_WARNING, filteredGraphTime)
            value = data ? +(data.count) : 0
            this.severeCount.push(value)
            break
          case Db.Fred.Severity.FLOOD_WARNING:
            data = this.filterGraphSeverity(Db.Fred.Severity.FLOOD_WARNING, filteredGraphTime)
            value = data ? +(data.count) : 0
            this.warningsCount.push(value)
            break
          case Db.Fred.Severity.FLOOD_ALERT:
            data = this.filterGraphSeverity(Db.Fred.Severity.FLOOD_ALERT, filteredGraphTime)
            value = data ? +(data.count) : 0
            this.alertsCount.push(value)
            break
          default:
            break
        }
      }
    })

    this.labels = this.labels.map(l => moment(l).format('DD-MM-YY HH:mm'))

  }

  filterGraphSeverity (severity: number, filterArray: WarningGraphData[]) {
    return filterArray.find(fa => fa.severity === severity)
  }

  clearArrays () {
    this.severeCount = []
    this.warningsCount = []
    this.alertsCount = []
    this.labels = []
  }

  calculateMax () {
    const arrays = this.severeCount.concat(this.warningsCount, this.alertsCount)
    const max = Math.max(...arrays) ? Math.max(...arrays) : 0
    return CommonUtil.roundToNearest(max)
  }

  toggleExcludeSepa () {
    this._excludeSepa = !this._excludeSepa
  }
}
