<h2 mat-dialog-title class="st-single-row-form">Layer Sequences</h2>

<ng-template #nodata>
  <app-note>
    No layer sequences found
  </app-note>
</ng-template>

<div class="st-table-overflow mat-elevation-z5" *ngIf="dataSource?.data.length; else nodata">
  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell class="actions" *matHeaderCellDef ></mat-header-cell>
        <mat-cell class="actions" *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="deleteSequence(row)">
            <mat-icon> delete </mat-icon>
          </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: stickyHeader"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
  </mat-table>
</div>

<div class="st-button-row">
    <button color="primary" type="button" mat-button mat-dialog-close>Close</button>
</div>

