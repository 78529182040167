import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import PortBoundary from '../../../../assets/cache22/port_boundary.json'
import * as turf from '@turf/turf'
import * as moment from 'moment'
import { AlertService, VipApiService } from '@services/core'
import { IEmissionsConfig } from '@vip-shared/interfaces/emissions/IEmissionsConfigModel'
import { Db } from '@vip-shared/models/db-definitions'
import { DashboardApiService } from '@services/dashboard/dashboard-api.service'
import { handleError } from '@core/models/app-error'
import { WorkspaceService } from '@services/workspace'

@Injectable({
  providedIn: 'root'
})
export class EmissionsJourneysService {
  private journeyDict: any = {}
  private vessels: Db.Vip.Emissions.IVessel[] = []
  private filteringAreas: {} = {}
  private vesselClasses: {} = {}
  private _journeysChanged = new BehaviorSubject<Db.Vip.Emissions.IJourney[]>([])
  get journeysChanged () {
    return this._journeysChanged.asObservable()
  }
  private _vesselsChanged = new BehaviorSubject<Db.Vip.Emissions.IVessel[]>([])
  get vesselsChanged () {
    return this._vesselsChanged.asObservable()
  }
  private _categoricalChanged = new BehaviorSubject<{}>({})
  get categoricalChanged () {
    return this._categoricalChanged.asObservable()
  }
  private _aggregateJourneysChanged = new BehaviorSubject<{}>({})
  get aggregateJourneysChanged () {
    return this._aggregateJourneysChanged.asObservable()
  }
  private _configChanged = new BehaviorSubject<IEmissionsConfig>({})
  get configChanged () {
    return this._configChanged.asObservable()
  }
  get trackedEmissions () {
    return this.config.trackedEmissions ? this.config.trackedEmissions : 'nox'
  }
  set trackedEmissions (value: string) {
    this.config.trackedEmissions = value
  }
  config: IEmissionsConfig = {}
  set startDate (startDate: string) {
    this.config.startDate = startDate
  }
  set endDate (endDate: string) {
    this.config.endDate = endDate
  }
  set status (status: string) {
    this.config.status = status
  }
  set configVessels (vessels: any) {
    if (vessels.length) {
      this.config.vessels = vessels
    } else {
      delete this.config['vessels']
    }
  }
  set vesselTypes (types: string[]) {
    if (types.length) {
      this.config.vesselTypes = types
    } else {
      delete this.config['vesselTypes']
    }
  }
  set spatialFilters (areas: string[]) {
    if (areas.length) {
      this.config.spatialAreaNames = areas
    } else {
      delete this.config['spatialAreaNames']
    }
  }

  constructor (private _apiService: VipApiService,
              private _dashboardApiService: DashboardApiService,
              private _alertService: AlertService,
              private _workspaceService: WorkspaceService) {
    this._workspaceService.onExit.subscribe(() => {
      this.cleanupService()
    })
    this.BuildAreas()
    this.BuildVessels()
    this.BuildJourneys()
  }

  getSpatialFilters (): Observable<string[]> {
    this.BuildAreas()
    return of(Object.keys(this.filteringAreas))
  }

  getMetric (labelName) {
    const label = ['fc', 'nox', 'pm', 'co2', 'co2_from_fc'].includes(labelName) ? '(kG)' :
    ['power', 'energy'].includes(labelName) ? '(kWh)' : ''

    return label
  }

  private async BuildVessels () {
    // make db call for vessels
    this.vessels = await this._apiService.orm.Microservice().getVessels().run()
    this.vessels.forEach(v => {
      this.vesselClasses[v.vessel_type] = (this.vesselClasses[v.vessel_type] || 0) + 1
    })
    this._categoricalChanged.next(this.vesselClasses)
    this._vesselsChanged.next(this.vessels)
  }

  private async BuildJourneys () {
    const wsId = this._dashboardApiService.workspaceId
    const dbConfig = await this._apiService.orm.Microservice().getFilters(wsId).run()
    if (dbConfig) {
      this.config = dbConfig
      this._configChanged.next(dbConfig)
      this.applyFilters()
    }
  }

  private BuildJourneyDict (journeys: Db.Vip.Emissions.IJourney[]) {
    if (!journeys.length) return
    let localDict = {}
    const classes = Object.keys(this.vesselClasses)
    const dayDiff = moment(this.config.endDate).diff(this.config.startDate, 'd')
    const tableFormat = dayDiff > 3 ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH'
    for (const journey of journeys) {
      const vessel = this.vessels.filter(v => v.vessel_id === journey.vessel_id)[0]
      const date = moment(journey.journey_date).format(tableFormat).toString()
      if (vessel) {
        if (localDict[date]) {
          if (localDict[date][vessel.vessel_type]) {
            localDict[date][vessel.vessel_type] = localDict[date][vessel.vessel_type] + journey[this.trackedEmissions]
          }
        } else {
          localDict[date] = {}
          classes.forEach(vesselClass => localDict[date][vesselClass] = 0.001)
          localDict[date][vessel.vessel_type] = localDict[date][vessel.vessel_type] + journey[this.trackedEmissions]
        }
      }
    }
    this.journeyDict = localDict
  }

  private BuildAreas () {
    try {
      PortBoundary.features.forEach(feature => {
        let portBoundary = turf.polygon(feature.geometry.coordinates)
        this.filteringAreas[feature.properties.Name] = portBoundary
      })
    } catch (error: any) {
      handleError(error)
      this._alertService.log('Filtering areas could not be constructed')
    }
  }

  async applyFilters () {
    let journeys: Db.Vip.Emissions.IJourney[] = []
    let vesselClasses: {} = {}

    try {
      const wsId = this._dashboardApiService.workspaceId
      if (Object.keys(this.config).length !== 0) {
        journeys = await this._apiService.orm.Microservice().applyFilters(this.config, wsId).run()
      } else {
        this._alertService.log('Complete data pull is not available')
      }
      journeys = journeys.filter(j => {
        if (this.config.spatialAreaNames) {
          let inAreas = false
          if (this.config.spatialAreaNames.includes('Outside Port')) {
            const area = this.filteringAreas['Outside Port']
            if (area) {
              const point = turf.point([j.lon, j.lat])
              inAreas = !turf.booleanPointInPolygon(point, area)
            }
          } else {
            this.config.spatialAreaNames.forEach(areaName => {
              const area = this.filteringAreas[areaName]
              if (area) {
                const point = turf.point([j.lon, j.lat])
                inAreas = (inAreas || turf.booleanPointInPolygon(point, area))
              }
            })
          }
          return inAreas
        }
        return true
      })
      journeys.map(j => {
        let vessel = this.vessels.filter(v => v.vessel_id === j.vessel_id)[0]
        if (vessel) vesselClasses[vessel.vessel_type] = (vesselClasses[vessel.vessel_type] || 0) + j[this.trackedEmissions]
      })
    } catch (error: any) {
      this._alertService.log(error.message)
    }

    this.BuildJourneyDict(journeys)
    this._aggregateJourneysChanged.next(this.journeyDict)
    this._categoricalChanged.next(vesselClasses)
    this._journeysChanged.next(journeys)
  }

  private cleanupService () {}
}
