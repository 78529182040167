import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanLoad, CanActivateChild,
    RouterStateSnapshot, Router, Route, Resolve } from '@angular/router'
import { AuthService, JwtService } from '@services/core'
import { Observable } from 'rxjs'
import { handleError } from '@core/models/app-error'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild, Resolve<boolean> {
  constructor (
    private _router: Router,
    private _authService: AuthService,
    private _jwtService: JwtService
  ) {}

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (!this._authService.isLogged()) {
      this._jwtService.clearJWT()
      this._router.navigate(['/login'])
      return false
    }
    return true
  }

  canLoad (route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this._authService.isLogged()
  }

  canActivateChild (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this._authService.isLogged()
  }

  async resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      if (this._authService.isLogged()) {
        await this._authService.getSessionInfo()
      }
      return true
    } catch (error: any) {
      handleError(error)
      return false
    }
  }
}
