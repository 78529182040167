import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent {

  constructor() { }

  @Input() otp: string = '';
  @Output() onOtpChange = new EventEmitter<string>();

  handleOtpChange(event: string) {
    this.onOtpChange.emit(event);
  }

  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    containerStyles: {
      margin: '20px auto',
      display: 'flex',
      justifyContent: 'center',
    },
    inputStyles: {
      width: 20,
      height: 20,
    },
  }
}
