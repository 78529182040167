import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import * as _ from 'lodash'
import { Subscription } from 'rxjs'
import { EmissionsJourneysService } from '../../services/journeys.service'
import { BaseChartDirective } from 'ng2-charts'
import { ThemeService } from '@services/core/theme/theme.service'
import 'chartjs-adapter-moment'

@Component({
  selector: 'app-emissions-chart',
  templateUrl: './emissions-chart.component.html',
  styleUrls: ['./emissions-chart.component.scss']
})
export class EmissionsChartComponent implements OnDestroy, OnInit {
  @ViewChild(BaseChartDirective, { static: true }) chart!: BaseChartDirective

  private categories: {} = {}
  private aggregateJourneys: {} = {}
  chartTypes: Array<string> = ['pie', 'bar']
  pieChartData: any = []
  pieChartLabels: Array<any> = []
  pieChartOptions: any = {
    responsive: true
  }
  chartLegend = true
  chartType: string = 'pie'
  private _subscriptions = new Subscription()

  constructor(
    private journeysService: EmissionsJourneysService,
    private _themeService: ThemeService
  ) {

  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe()
  }

  ngOnInit() {
    this._subscriptions.add(
      this.journeysService.categoricalChanged.subscribe(categories => {
      this.categories = categories
      this.updateChart()
      })
    )
    this._subscriptions.add(
      this.journeysService.aggregateJourneysChanged.subscribe(aggregateJourneys => {
        this.aggregateJourneys = aggregateJourneys
        this.updateChart()
      })
    )
  }

  getChartOptions (type) {
    const scales = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day'
          },
          ticks: {
            color: this._themeService.getColor('foreground')
          },
          grid: {
            color: this._themeService.getColor('foreground-20')
          },
        },
        y: {
           display: true,
           scaleLabel: {
            display: true,
            labelString: `${this.journeysService.trackedEmissions} ${this.journeysService.getMetric(this.journeysService.trackedEmissions)}`,
           },
           ticks: {
            color: this._themeService.getColor('foreground')
          },
          grid: {
            color: this._themeService.getColor('foreground-20')
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: this._themeService.getColor('foreground')
          }
        }
      }
    }
    const title = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      plugins: {
        legend: {
          labels: {
            color: this._themeService.getColor('foreground')
          },
          position: 'right',
          align: 'start'
        },
        title: {
          text: `${this.journeysService.trackedEmissions} by vessel type`,
          color: this._themeService.getColor('foreground'),
          display: true
        },
      },
      elements: {
        arc: {
            borderWidth: 0
        }
      }
    }
    return this.chartType === 'bar'? scales: title
  }

  updateChart() {
    let labels = Object.keys(this.categories)
    if (this.chartType === 'bar' ) {
      let data: any = []
      let vesselDataObj = {}
      labels.forEach(vesselClass => vesselDataObj[vesselClass] = [])
      for (let key in this.aggregateJourneys) {
        for (let aggregateCls in this.aggregateJourneys[key]) {
          if (vesselDataObj[aggregateCls]) {
            vesselDataObj[aggregateCls].push(this.aggregateJourneys[key][aggregateCls])
          } else {
            vesselDataObj[aggregateCls] = []
            vesselDataObj[aggregateCls].push(this.aggregateJourneys[key][aggregateCls])
          }
        }
      }
      for (let key in vesselDataObj) {
        data.push({
          label: key as string,
          data: Object.values(vesselDataObj[key])
        })
      }
      this.pieChartLabels = Object.keys(this.aggregateJourneys).sort()
      this.pieChartOptions = this.getChartOptions(this.chartType)
      this.pieChartData = [...data]
    } else {
      this.pieChartLabels = labels
      let values = labels.map(key => {
        return this.categories[key];
      })
      this.pieChartData = [
        { data: values, label: 'Quantity' }
      ]
      this.pieChartOptions = this.getChartOptions(this.chartType)
    }
  }
}
