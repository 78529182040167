<h2 mat-dialog-title>Colour Picker</h2>
<div mat-dialog-content>
    <div class="picker">
        <span
          [style.background]="color"
          [cpToggle]="true"
          [cpDialogDisplay]="'inline'"
          [(colorPicker)]="color"
        ></span>
      </div>

      <div class="hex-input">
        <p>Hex:</p>
        <mat-form-field>
            <input matInput type="text" [(ngModel)]="hexColor" maxlength="7" />
        </mat-form-field>
      </div>

      <div class="style-history" *ngIf="styleHistory.length">
        <p>Recently selected</p>
        <div class="history-list">
          <div
          *ngFor="let style of styleHistory"
          [style.background]="historyStyleToStyle(style)"
          (click)="applyHistoricalStyle(style)"
          ></div>
        </div>
      </div>
</div>

<div class="st-button-row">
  <button mat-button color="primary" mat-dialog-close>CANCEL</button>
  <div>
    <button mat-button color="primary" (click)="close()" cdkFocusInitial>CONFIRM</button>
  </div>
</div>
