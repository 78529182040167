<div>
  <mat-form-field appearance="fill">
  <mat-label>Columns</mat-label>
  <mat-select [(value)]="column" (selectionChange)="columnChange()">
    <mat-option *ngFor="let type of columns" [value]="type">{{type}}</mat-option>
  </mat-select>
</mat-form-field>
  <canvas baseChart
              [datasets]="chartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [legend]="chartLegend"
              [type]="chartType"></canvas>
</div>
